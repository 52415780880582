import React from 'react';
import { Box, createStandaloneToast, Flex, Code } from '@chakra-ui/react';
import useProfileSlug from '../../../../../hooks/useProfileSlug';
import theme from '../../../../../library/theme';

const toast = createStandaloneToast({ theme: theme });

const linkStyle = {
	textDecoration: 'underline',
};

const AboutSection = () => {
	const profileSlug = useProfileSlug();
	// defined a state for now to test the UI when developer will not have access
	// trigging the generateDeveloperKey on developers page load

	return (
		<Flex
			direction="column"
			bg="white"
			border="1px solid"
			borderColor={'light.border.gray.200'}
			padding="20px"
		>
			<Flex textStyle="serif.lg" color="light.font.gray.400">
				Maya Research Preview
			</Flex>

			<Box color="light.font.gray.300" textStyle="sans.sm" fontWeight="400">
				<Box mt="16px">
					This is a research preview for <b>PAC-1</b>, a new autonomous
					system that programs itself to perform multi-step tasks across
					apps and APIs, in response to natural language instruction.
				</Box>
				<Box mt="12px">
					Since this is an early release, we recommend <b>not</b> using
					PAC-1 for any critical functions or data.{' '}
					<a style={linkStyle} href="https://mayalabs.io/docs">
						Here
					</a>{' '}
					is a full list of capabilities and limitations.
				</Box>

				<Box mt="12px">
					To get started, install our{' '}
					<a
						href="https://github.com/mayahq/mayalabs-sdk-python"
						style={linkStyle}
					>
						SDK
					</a>{' '}
					by running{' '}
					<Code colorScheme="purple"> pip install mayalabs </Code> and grab
					your API key from the{' '}
					<a href="/settings/developers" style={linkStyle}>
						Developers
					</a>{' '}
					section to try out some examples.
				</Box>
			</Box>
		</Flex>
	);
};

export default AboutSection;
