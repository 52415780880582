import React from 'react';
import { Box } from '@chakra-ui/layout';
import { HistoryWithSlug } from '../../util/History';

/**
 *
 * @param {import("./types/index").VerticalMenu} param0
 * @returns
 */

const VerticalMenu = ({ menuItemList, selectedItem, setSelected }) => {
	return (
		<Box display="flex" flexDirection="column">
			{menuItemList.map((item) => {
				return (
					<Box
						key={item.label}
						cursor="pointer"
						marginBottom="20px"
						textAlign="right"
						display="flex"
						flexDirection="column"
						alignItems="end"
						justifyContent="right"
					>
						<Box
							textStyle="sans.sm"
							color={
								item.label === selectedItem
									? 'light.font.gray.400'
									: 'light.font.gray.200'
							}
							onClick={() => {
								setSelected(item.label);
								HistoryWithSlug.push(item.redirect);
							}}
							transition="all 0.02s cubic-bezier(.08,.52,.52,1)"
							_hover={{
								color: 'light.font.gray.400',
							}}
						>
							{item.label}
						</Box>
						{item.label === selectedItem && (
							<Box
								height="2px"
								bg="light.theme.gray.400"
								width="14px"
								mt="-2px"
								borderRadius="1px"
							/>
						)}
					</Box>
				);
			})}
		</Box>
	);
};

export default VerticalMenu;
