import { Box, Button, createStandaloneToast, Flex } from '@chakra-ui/react';
import { ZipVisualizer, segregateFiles, getSkillData } from '@mayahq/ui';
import JSZip from 'jszip';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import shallow from 'zustand/shallow';
import skillTeach from '../../../../../functions/pac-engine/skillTeach';
import verifySkill from '../../../../../functions/pac-engine/verifySkill';
import MayaFilledButton from '../../../../../library/buttons/FilledButton';
import SectionHeader from '../../../../../library/text/SectionHeader';
import theme from '../../../../../library/theme';
import { useStore } from '../../../../Editor/zustand';
import FileUpload from '../../../../FileUpload/FileUpload';
import useStepperStore from '../../../StepperStore';
import { stepperFlowTypes } from '../../registry';
import { FiShoppingBag } from 'react-icons/fi';

const UploadZipStep = () => {
	const [file, setFile] = useState({
		name: '',
		type: 'zip',
		lastModified: 0,
		size: 0,
		content: '',
	});
	const [zipData, setZipData] = useState(null);
	const [skillData, setSkillData] = useState(null);

	const toast = createStandaloneToast({ theme: theme });
	const currentState = useStepperStore(
		(state) => state.steps.byId['teach.uploadSkillZip'].state,
		shallow
	);
	const addStep = useStepperStore((state) => state.addStep, shallow);
	const nextStep = useStepperStore((state) => state.nextStep, shallow);

	const showStoreOption = currentState.showStoreOption;

	function goToNextStep() {
		const nextStepState = {
			...currentState,
			...zipData,
			skillData: skillData,
			metadata: {
				source: 'manual',
			},
		};

		console.log('ret nss', nextStepState);

		const stepperFlowType = currentState.stepperFlowType;
		switch (stepperFlowType) {
			case stepperFlowTypes.teach.TEACH_SKILL: {
				addStep('teach.skill', nextStepState);
			}
		}

		nextStep();
	}

	async function handleUpload(file) {
		const fileContent = await JSZip.loadAsync(file[0]);
		const data = await segregateFiles(fileContent);

		const skillData = getSkillData('bruh', data);
		// console.log('segregated', getSkillData('bruh', data))
		setSkillData(skillData);
		setZipData({
			steps: data.structuredSteps,
			docs: data.structuredDocs,
			recipes: data.structuredRecipes,
			name: file[0].name,
		});
		setFile(file[0]);
	}

	return (
		<Flex height="100%" direction="column">
			<Box
				display="flex"
				flexDir="column"
				justifyContent="center"
				alignItems="center"
			>
				<Box paddingTop="20px" paddingBottom="20px">
					<SectionHeader
						heading="Teach new skill."
						subheading="Upload the zip file containing the recipes, steps and documents, or go to the store"
					/>
				</Box>
			</Box>
			<Flex
				display="flex"
				flexDir="column"
				justifyContent="center"
				alignItems="center"
				marginTop="3rem"
				// bg='green.400'
				flex="1 1 auto"
			>
				<Flex
					flex="1 1 auto"
					width="100%"
					justifyContent="center"
					// bg='blue.300'
				>
					<Flex
						flex="1 1 auto"
						height="100%"
						width="100%"
						// bg='pink'
						justifyContent="center"
					>
						<FileUpload
							height="100%"
							onChange={handleUpload}
							value={[file]}
							bg="#fff"
							fontSize="18px"
						/>
						{showStoreOption && (
							<>
								<Flex
									width="100px"
									height="100%"
									justifyContent="center"
									alignItems="center"
									textStyle="sans.md"
									color="light.font.gray.300"
									// bg='tomato'
								>
									or
								</Flex>
								<a
									href="/store"
									style={{
										flex: '1 1 auto',
										width: '100%',
										height: '100%',
									}}
								>
									<Flex
										flex="1 1 auto"
										width="100%"
										height="100%"
										justifyContent="center"
										alignItems="center"
										bg="#fff"
										// bg='green'

										borderColor="gray.300"
										borderStyle="dashed"
										borderWidth="2px"
										borderRadius="5px"
										color="light.font.gray.300"
										_hover={{
											color: 'light.font.gray.400',
										}}
									>
										<Flex
											leftIcon={<FiShoppingBag />}
											variant="ghost"
											colorScheme="blackAlpha"
											borderRadius="3px"
											textStyle="sans.xs"
											fontSize="18px"
											size="sm"
											direction="column"
											alignItems="center"
										>
											<Flex fontWeight="700" alignItems="center">
												<FiShoppingBag />
												<Box ml="6px">Go to store</Box>
											</Flex>
											<Flex fontWeight="400">
												to browse pre-built skills
											</Flex>
										</Flex>
									</Flex>
								</a>
							</>
						)}
					</Flex>
				</Flex>
				<Box mt="64px" mb="64px">
					<MayaFilledButton
						text="Continue"
						onClick={goToNextStep}
						isDisabled={zipData === null}
					/>
				</Box>
			</Flex>
		</Flex>
	);
};

const sectionObject = {
	heading: 'upload zip',
	component: <UploadZipStep />,
};

export default sectionObject;
