import React, { useEffect, useState } from 'react';
import { Flex, Box, Spinner, BoxProps } from '@chakra-ui/react';
import { ModuleDetails } from './ModuleDetails/ModuleDetails';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { MayaActionButton } from '../../buttons';
import { ModuleByIdResponse } from '../types/ModuleById';
import ModuleSectionContext from './Context';

export interface ModuleSectionProps {
	/** Module response object */
	module: ModuleByIdResponse;
	/** Trig on back btn click */
	onBackButtonClick: () => void;
	/** Trig on install btn click */
	onInstallButtonClick: () => void;
	/** Render custom config profiles section given the module object */
	onConfigProfilesSectionRender?: (args: {
		module: ModuleByIdResponse;
	}) => React.ReactNode;
	containerProps?: BoxProps;
}

/**
 * # ModuleSection
 *
 * Main component to render on the Module Page
 */
export const ModuleSection = ({
	module,
	onBackButtonClick,
	onInstallButtonClick,
	onConfigProfilesSectionRender,
	containerProps,
}: ModuleSectionProps) => {
	return (
		<ModuleSectionContext.Provider
			value={{
				module,
				onBackButtonClick,
				onInstallButtonClick,
				onConfigProfilesSectionRender,
			}}
		>
			<Flex flex="1" minHeight="0" direction="column" {...containerProps}>
				<Box>
					<MayaActionButton
						text={'BACK'}
						icon={<ChevronLeftIcon />}
						onClick={onBackButtonClick}
					/>
				</Box>
				<ModuleDetails module={module} />
			</Flex>
		</ModuleSectionContext.Provider>
	);
};
