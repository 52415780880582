import createVanilla from 'zustand/vanilla';
import createHook from 'zustand';

export const instructBarStore = createVanilla((set, get) => ({
	instructBarPrompt: '',
	recipePreview: '',
	previewPrompt: '',
	tempSessionId: '',
	setInstructBarPrompt: (value) => {
		set((state) => {
			state.instructBarPrompt = value;
		});
	},
	setRecipePreview: (value) => {
		set((state) => {
			state.recipePreview = value;
		});
	},
	setPreviewPrompt: (value) => {
		set((state) => {
			state.previewPrompt = value;
		});
	},
}));

export const useInstructBarStore = createHook(instructBarStore);
