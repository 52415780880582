import React, { useCallback } from 'react';
import _ from 'lodash';
import {
	Flex,
	Box,
	Button as ChakraButton,
	GridItem,
	FormLabel,
	Input,
	NumberInput,
	NumberInputField,
	NumberIncrementStepper,
	NumberDecrementStepper,
	NumberInputStepper,
	Button,
	Switch,
	Select,
	Textarea,
} from '@chakra-ui/react';
import { useWorkspaceDashboardStore } from '../zustand';
import { FileUpload } from '../../../../FileUpload/FileUpload';
// import { FileUpload } from './HelperComponents/FileUpload'

const validColorSchemes = [
	'blue',
	'gray',
	'green',
	'orange',
	'pink',
	'red',
	'teal',
	'yellow',
	'whiteAlpha',
	'facebook',
	// 'blackAlpha',
	// 'cyan',
	// 'linkedin',
	// 'messenger',
	// 'purple',
	// 'telegram',
	// 'twitter',
	// 'whatsapp',
];

function getSensibleDefaults(formConfig) {
	const vals = {};
	Object.keys(formConfig).forEach((fieldName) => {
		const defaultVal = formConfig[fieldName]?.default;
		switch (formConfig[fieldName].type) {
			case 'string': {
				vals[fieldName] = { type: 'string', value: defaultVal || '' };
				break;
			}
			case 'number': {
				vals[fieldName] = { type: 'number', value: defaultVal || 0 };
				break;
			}
			case 'boolean': {
				vals[fieldName] = {
					type: 'boolean',
					value: typeof defaultVal === 'boolean' ? defaultVal : false,
				};
				break;
			}
			case 'select': {
				vals[fieldName] = {
					type: 'select',
					value: defaultVal || formConfig[fieldName].options[0],
				};
				break;
			}
			case 'longtext': {
				vals[fieldName] = { type: 'select', value: defaultVal || '' };
				break;
			}
			case 'file': {
				vals[fieldName] = { type: 'file', value: [] };
			}
		}
	});

	return vals;
}

const FormComp = ({
	workspaceId,
	width,
	formConfig,
	submitButtonLabel,
	cancelButtonLabel,
	componentId,
	handleSubmit,
}) => {
	console.log('daform', formConfig);
	const formData = useWorkspaceDashboardStore(
		(state) => state.dashboardData[workspaceId][componentId] || {}
	);
	const handleFormFieldChange = useWorkspaceDashboardStore(
		(state) => (fieldName, value) =>
			state.handleFormFieldChange(workspaceId, componentId, fieldName, value)
	);
	const clearForm = useWorkspaceDashboardStore((state) => () => {
		const defaultData = getSensibleDefaults(formConfig);
		const formFieldValues = {};
		Object.keys(defaultData).forEach(
			(fieldName) =>
				(formFieldValues[fieldName] = defaultData[fieldName].value)
		);

		state.setFormData(workspaceId, componentId, formFieldValues);
	});

	// function createFormDataWithTypes(fieldValues) {
	//     const data = getSensibleDefaults(formConfig)

	//     Object.keys(formConfig).forEach(key => {
	//         let value = fieldValues[key]
	//         if (formConfig[key].type === 'file') {
	//             const files = formData[key]
	//             value = []
	//             if (files) {
	//                 Object.values(files).forEach(file => {
	//                     value.push({
	//                         name: file.name,
	//                         type: file.type,
	//                         lastModified: file.lastModified,
	//                         size: file.size,
	//                         content: file
	//                     })
	//                 })
	//             }
	//         }

	//         data[key] = {
	//             type: formConfig[key].type,
	//             value: value
	//         }
	//     })

	//     return data
	// }

	console.log('dashsock formdata', formData, formConfig);

	const formFieldComps = Object.keys(formConfig).map((fieldName) => {
		return (
			<Box mb="12px" textStyle="sans.sm" borderRadius="10px">
				<FormLabel
					ml="2px"
					mb="4px"
					color="light.font.gray.300"
					fontWeight="400"
					fontSize="14px"
				>
					{_.startCase(fieldName)}
				</FormLabel>
				{/* <input /> */}
				{formConfig[fieldName]?.type === 'string' && (
					<Input
						width="100% !important" // Fucking node-red css classes were overriding this. Pain in the ass even when you phase out the whole thing goddammit.
						placeholder={formConfig[fieldName]?.placeholder}
						onChange={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleFormFieldChange(fieldName, e.target.value);
						}}
						value={formData?.fields?.[fieldName]?.value?.toString()}
					/>
				)}

				{formConfig[fieldName]?.type === 'number' && (
					<NumberInput
						width="100% !important"
						onChange={(val) =>
							handleFormFieldChange(fieldName, parseFloat(val))
						}
						value={parseFloat(formData?.fields?.[fieldName]?.value) || 0}
					>
						<NumberInputField />
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					</NumberInput>
				)}

				{formConfig[fieldName]?.type === 'boolean' && (
					<Switch
						size="lg"
						onChange={(e) =>
							handleFormFieldChange(fieldName, e.target.checked)
						}
						isChecked={formData?.fields?.[fieldName]?.value || false}
					/>
				)}

				{formConfig[fieldName]?.type === 'select' && (
					<Select
						onChange={(e) =>
							handleFormFieldChange(fieldName, e.target.value)
						}
						value={formData?.fields?.[fieldName]?.value}
						placeholder={
							formConfig[fieldName].placeholder || 'Pick a value...'
						}
						color="light.font.gray.400"
					>
						{formConfig[fieldName].options.map((opt) => (
							<option value={opt}>{opt}</option>
						))}
					</Select>
				)}

				{formConfig[fieldName]?.type === 'longtext' && (
					<Textarea
						placeholder={formConfig[fieldName].placeholder || ''}
						value={formData?.fields?.[fieldName]?.value || ''}
						onChange={(e) =>
							handleFormFieldChange(fieldName, e.target.value)
						}
					/>
				)}

				{formConfig[fieldName]?.type === 'file' && (
					<FileUpload
						onChange={(files) => handleFormFieldChange(fieldName, files)}
						value={formData?.fields?.[fieldName]?.value}
					/>
				)}
			</Box>
		);
	});

	return (
		<GridItem colSpan={parseInt(width)} padding="2px">
			{formFieldComps}
			<Flex
				textStyle="sans.sm"
				justifyContent="flex-end"
				mt="28px"
				// fontWeight='300'
			>
				{cancelButtonLabel && (
					<Button
						colorScheme="button.secondary"
						variant="ghost"
						color="#666"
						mr="8px"
						onClick={clearForm}
						borderRadius="3px"
					>
						{cancelButtonLabel}
					</Button>
				)}
				<Button
					colorScheme={'button.primary'}
					onClick={() => handleSubmit(formData)}
					borderRadius="3px"
				>
					{submitButtonLabel || 'Submit'}
				</Button>
			</Flex>
		</GridItem>
	);
};

export const Form = React.memo(FormComp);
