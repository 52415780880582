import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { StoreApp } from './types/Apps';
import { StoreCategory } from './types/Categories';
import { Box, Flex, FlexProps } from '@chakra-ui/react';
import { itemTypes } from './itemTypes';
import Filters from './Filters';
import { StoreModule } from './types/Modules';
import StoreContext from './StoreContext';
import ModuleList from './ModuleList';
import { SearchBar } from './SearchBar';
import { StoreSkillPack } from './types/SkillPacks';
import useStoreSectionZustandStore, { StoreEntity } from './zustand';
import CollectionList from './CollectionList';

export interface StoreSectionProps {
	/** Gets the query param value for given key  */
	onQueryParamGet: (key: string) => string;
	/** Called to update query params  */
	onQueryParamUpdate: (params: { [key: string]: string }) => void;
	/** Called to get store apps */
	onStoreAppsGet: () => Promise<StoreApp[]>;
	/** Called to get store categories */
	onStoreCategoriesGet: () => Promise<StoreCategory[]>;
	/** Called to get store modules */
	onStoreModulesGet: (args: {
		categories?: StoreCategory[];
		apps?: StoreApp[];
		search?: string;
	}) => Promise<StoreModule[]>;
	onStoreSkillPacksGet: (args: {
		categories?: StoreCategory[];
		apps?: StoreApp[];
		query?: string;
	}) => Promise<any[]>;
	/** Customize the module card to be rendered */
	onModuleCardRender: (args: { module: StoreModule }) => React.ReactNode;
	/** Customize the collection card to be rendered */
	onCollectionCardRender: (args: {
		collection: StoreSkillPack;
	}) => React.ReactNode;
	containerProps?: FlexProps;
}

/**
 * # StoreSection
 *
 * Re-usable Maya store component.
 *
 * - Supply the props as they're the dependencies.
 * - Customize the render cards
 */
export const StoreSection = ({
	onQueryParamGet,
	onQueryParamUpdate,
	onStoreAppsGet,
	onStoreCategoriesGet,
	onStoreModulesGet,
	onStoreSkillPacksGet,
	onModuleCardRender,
	onCollectionCardRender,
	containerProps,
}: StoreSectionProps) => {

	const onInit = useStoreSectionZustandStore(
		useCallback(state => state.onInit, [])
	);
	const type = useStoreSectionZustandStore(
		useCallback(state => state.type, [])
	);
	const searchQuery = useStoreSectionZustandStore(
		useCallback(state => state.query, [])
	);
	const apps = useStoreSectionZustandStore(
		useCallback(state => state.apps, [])
	);
	const categories = useStoreSectionZustandStore(
		useCallback(state => state.categories, [])
	);
	const onQueryChange = useStoreSectionZustandStore(
		useCallback(state => state.onQueryChange, [])
	);
	const onTypeChange = useStoreSectionZustandStore(
		useCallback(state => state.onTypeChange, [])
	);
	const onCategoryToggle = useStoreSectionZustandStore(
		useCallback(state => state.onCategoryToggle, [])
	);
	const onAppsToggle = useStoreSectionZustandStore(
		useCallback(state => state.onAppsToggle, [])
	);

	const onQueryChangeDebounced = useCallback(
		debounce(query => {
			onQueryChange(query);
		}, 200),
		[]
	);

	useEffect(() => {
		Promise.all([
			onStoreAppsGet(),
			onStoreCategoriesGet()
		]).then(([apps, categories]) => {
			const appFilter = onQueryParamGet('appFilter') || ''
			const categoryFilter = onQueryParamGet('categoryFilter') || ''
			const query = onQueryParamGet('query')

			const selectedAppIds: { [key: string]: boolean } = {}
			appFilter.split(',').forEach(id => selectedAppIds[id] = true)

			const selectedCategoryIds: { [key: string]: boolean } = {}
			categoryFilter.split(',').forEach(id => selectedCategoryIds[id] = true)

			onInit({
				apps: apps.map(a => ({ ...a, _selected: !!selectedAppIds[a.id] })),
				categories: categories.map(c => ({ ...c, _selected: !!selectedCategoryIds[c.id] })),
				type: 'skills',
				query	
			})
		})
	}, [])


	useEffect(() => {
		// Update appFilter and categoryFilter in URL querystring
		const selectedAppIds = apps.ids
			.filter(a => apps.byId[a]?._selected)
			.join(',');
		const selectedCategoryIds = categories.ids
			.filter(c => categories.byId[c]?._selected)
			.join(',');
		onQueryParamUpdate({
			type: type ? type : '',
			query: searchQuery,
			appFilter: selectedAppIds,
			categoryFilter: selectedCategoryIds,
		});
	}, [type, apps, categories, searchQuery]);

	const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		onQueryChangeDebounced(e.target.value);
	};

	return (
		<StoreContext.Provider
			value={{
				onModuleCardRender,
				onQueryParamGet,
				onQueryParamUpdate,
				onStoreAppsGet,
				onStoreCategoriesGet,
				onStoreModulesGet,
				onStoreSkillPacksGet,
				onCollectionCardRender,
			}}
		>
			<Flex
				direction="column"
				height="100%"
				borderRadius="20px"
				{...containerProps}
			>
				<Box // Fixed top bar
					width="100%"
				>
					<SearchBar
						handleSearchInput={handleSearchInput}
						inputTextColor="#707070"
						query={searchQuery}
					/>
				</Box>
				<Flex // Container for rest of the storefront
					flex="1"
					minHeight="0"
					borderRadius="20px"
					mt="12px"
					flexWrap="wrap"
				>
					<Flex
						h={{ sm: '16%', md: 'full' }}
						w={{ sm: 'full', md: '12rem' }}
					>
						<Filters
							itemType={type}
							categories={categories}
							apps={apps}
							handleAppToggle={onAppsToggle}
							handleCategoryToggle={onCategoryToggle}
							handleTypeChange={onTypeChange}
						/>
					</Flex>
					<Flex // The main list of store items
						direction="column"
						ml={{ sm: 'none', md: '16px' }}
						w={{
							sm: 'full',
							md: 'unset',
						}}
						flex={{
							sm: 'unset',
							md: '1',
						}}
						h={{ sm: '84%', md: 'full' }}
					>
						<Flex
							direction="column"
							flex="1"
							minHeight="0"
							overflow="auto"
						>
							{type === itemTypes.MODULE ? (
								<ModuleList
									apps={apps}
									categories={categories}
									searchQuery={searchQuery}
								/>
							) : null}
							{type === itemTypes.SKILL ? (
								<CollectionList
									apps={apps}
									categories={categories}
									searchQuery={searchQuery}
								/>
							) : null}
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</StoreContext.Provider>
	);
};
