import produce from 'immer';
import _, { cloneDeep, indexOf } from 'lodash';
import lodashFlow from 'lodash/flow';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import create from 'zustand';
import { devtools, persist, subscribeWithSelector } from 'zustand/middleware';
import createVanilla from 'zustand/vanilla';
import {
	getFlowByTabs,
	getSelectedMayaFlow,
	newTabIdGenerator,
	parseMayaLangToReactFlow,
	parseReactFlowtoMayaLang,
	parseReactFlowToSubflowIO,
	parseSubfowIOToReactFlow,
} from '../Workspace/functions/FlowMethods';
import {
	debouncedGetMissingModules,
	findDashboardNodes,
	normalize,
	normalizeNew,
} from '../Workspace/functions/Misc';
// @ts-ignore
import {
	deployEditor,
	getCompleteFlow,
	getConfigNodes,
	getModuleList,
	getNodeById,
	getNodeByType,
	getNodeTypes,
} from '../Workspace/functions/NodeRed';
// @ts-ignore
// @ts-ignore
import semver from 'semver';
import { v4 as uuidV4 } from 'uuid';
import getBrainById from '../../../functions/brain/getBrainById';
import deploySkillAndUpdateRuntime from '../../../functions/editor/deploy';
import getMayaJson from '../../../functions/skillPack/getMayaJson';
import { updateBrainById } from '../../../redux/actions';
import { store } from '../../../redux/store';
import getRedInstance from '../Workspace/functions/getRedInstance';
import {
	toggleLeftNodePalette,
	toggleRightSideBar as toggleRightSidebarVisibility,
} from '../Workspace/functions/layout';
import {
	getInstanceSubflowDetails,
	getMainSubflowDetails,
	findMainTab,
} from '../Tools/GenerateDAG/utils';
import {
	compareFlows,
	compareRecipes,
	diffExists,
} from '../Tools/pac-engine/utils';
import { pacEngineStore } from '../Tools/pac-engine/zustand';
import changeSession from '../../../functions/pac-engine/changeSession';
import nlFeedback from '../../../functions/synthesis/nlFeedback';
import RED_EVENTS from '../Workspace/functions/redEventListeners/events';
import addNode from '../Workspace/functions/addNode';

// Log every time state is changed
const loggerMiddleware = (config) => (set, get, api) =>
	config(
		(args) => {
			set(args);
			if (process.env.NODE_ENV === 'development') {
				// console.log('Zustand new state', get());
			}
		},
		get,
		api
	);

/**
 * Immer zustand middleware
 * @param config
 * @returns
 */
export const immer = (config) => (set, get, api) =>
	config(
		(partial, replace) => {
			const nextState =
				typeof partial === 'function' ? produce(partial) : partial;
			return set(nextState, replace);
		},
		get,
		api
	);

const middleware = lodashFlow([
	// immer, // TODO Enable this later and remove individual produce fns in each action
	loggerMiddleware,
	subscribeWithSelector,
	immer,
	devtools,
	(fn) =>
		persist(fn, {
			name: 'editorMainStore',
			/**
			 *
			 * @param {import('./types').EditorStore} state
			 * @returns
			 */
			partialize: (state) => ({
				nodePaletteIsOpen: state.nodePaletteIsOpen,
				synthesisSidebarVisible: state.synthesisSidebarVisible,
				debugPanelOpen: state.debugPanelOpen,
				infoPanelOpen: state.infoPanelOpen,
				dashboardIsOpen: state.dashboardIsOpen,
			}),
		}),
]);

/**
 * @type {import('zustand').StoreApi<import('./types').EditorStore>}
 */
export const editorStore = createVanilla(
	middleware(
		/**
		 *
		 * @param {import('zustand').SetState<import('./types').EditorStore>} set
		 * @param {import('zustand').GetState<import('./types').EditorStore>} get
		 * @param {import('zustand').StoreApi<import('./types').EditorStore>} api
		 * @returns {import('./types').EditorStore}
		 */
		// @ts-ignore
		(set, get, api) => ({
			// STORE
			brainId: '',
			profileSlug: '',
			runtime: '', // String
			token: '', // TODO: to be removed
			mayaFlow: [], // temporary
			palette: {
				categories: [],
				moduleList: {},
			},
			nodeConfigs: {
				allTypes: [],
				byType: {},
			},
			tabs: {
				allIds: [],
				byId: {
					// mayaFlowByTab: []
					// reactFlowByTab: []
					// id: String
					// label: String
					// type: tab
				},
				activeTab: '',
			},
			nodeStatus: {
				allIds: [],
				byId: {},
			},
			configNodes: [],
			valid: true,
			dirty: false,
			interactionMode: 'normal',
			edgeDrag: {},
			doubleClickedNode: {},
			commands: {
				allIds: [],
				byId: {
					// id: string;
					// prompt: string;
					// nodes: [];
				},
			},
			centerFlow: {
				shouldCenter: false,
				x: 0,
				y: 0,
				zoom: 1,
			},
			nodesWithIssues: [],
			installedModules: [],
			missingModules: [],
			nodePaletteIsOpen: false,
			synthesisSidebarVisible: true,
			debugPanelOpen: false,
			infoPanelOpen: false,
			dashboardIsOpen: false,
			moduleSidebarIsOpen: false,
			issuesSidebarIsOpen: false,
			instructBarIsOpen: false,
			editorBlankoutError: false,
			autoDeploying: false,
			errorNodeId: '',
			dashboardNodes: [],
			dashboardOpened: false,
			userSubmittedInstruction: false,
			// outputReturnedByAPI: [],
			areModuleInstalling: false,
			isModuleInstallationDrawerVisible: false,
			selectedMissingModule: {},
			isModuleConfigured: false,
			mainSubflow: { nodeId: '', tabId: '' },
			instanceSubflows: {},
			teachingZipFile: {},
			focusedStepNodeIds: [],
			setFocusedStepNodeId: (nodeIds) => {
				set(
					produce((state) => {
						state.focusedStepNodeIds = nodeIds;
					})
				);
			},
			executingNodes: [],
			setTeachingZipFile: (file) => {
				set(
					produce((state) => {
						state.teachingZipFile = file;
					})
				);
			},
			setUserSubmittedInstruction: (value) => {
				set(
					produce((state) => {
						state.userSubmittedInstruction = value;
					})
				);
			},
			setDashboardNodes: (reactFlow) => {
				set(
					produce((state) => {
						state.dashboardNodes = findDashboardNodes(reactFlow);
					})
				);
			},
			setErrorNodeId: (nodeId) => {
				set(
					produce((state) => {
						state.errorNodeId = nodeId;
					})
				);
			},
			setAutoDeploying: (autoDeploying) => {
				set(
					produce((state) => {
						state.autoDeploying = autoDeploying;
					})
				);
			},
			setEditorBlankoutError: (isError) => {
				set(
					produce((state) => {
						state.editorBlankoutError = isError;
					})
				);
			},
			// ACTIONS
			toggleNodePalette: () => {
				set(
					produce((state) => {
						const current = get().nodePaletteIsOpen;
						state.nodePaletteIsOpen = !current;
						toggleLeftNodePalette(!current);
					})
				);
			},
			toggleRightSidebar: () => {
				set(
					produce((state) => {
						state.debugPanelOpen = true;
						state.infoPanelOpen = false;
						toggleRightSidebarVisibility(true, false);
					})
				);
			},
			toggleDebugPanel: () => {
				set(
					produce((state) => {
						const { debugPanelOpen: debug, infoPanelOpen: info } = get();
						state.debugPanelOpen = !debug;
						if (!debug && info) {
							state.infoPanelOpen = false;
						}
						toggleRightSidebarVisibility(!debug, false);
					})
				);
			},
			toggleInfoPanel: () => {
				set(
					produce((state) => {
						const { debugPanelOpen: debug, infoPanelOpen: info } = get();
						state.infoPanelOpen = !info;
						if (debug && !info) {
							state.debugPanelOpen = false;
						}
						toggleRightSidebarVisibility(false, !info);
					})
				);
			},
			toggleSynthSidebar: () => {
				set(
					produce((state) => {
						const current = get().synthesisSidebarVisible;
						state.synthesisSidebarVisible = !current;
					})
				);
			},
			toggleDashboard: () => {
				set(
					produce((state) => {
						const current = get().dashboardIsOpen;
						state.dashboardIsOpen = !current;
						if (state.dashboardIsOpen) {
							state.dashboardOpened = true;
						}
					})
				);
			},
			toggleModuleSidebar: () => {
				set(
					produce((state) => {
						const current = get().moduleSidebarIsOpen;
						state.moduleSidebarIsOpen = !current;
					})
				);
			},
			toggleIssuesSidebar: () => {
				set(
					produce((state) => {
						const current = get().issuesSidebarIsOpen;
						if (
							get().selectedMissingModule[
								Object.keys(get().selectedMissingModule)[0]
							]?.requiresConfig &&
							get().isModuleConfigured
						) {
							state.issuesSidebarIsOpen = !current;
						} else if (
							!get().selectedMissingModule[
								Object.keys(get().selectedMissingModule)[0]
							]?.requiresConfig
						) {
							state.issuesSidebarIsOpen = !current;
						}
					})
				);
			},
			toggleInstructBar: () => {
				const user = store.getState().user;
				if (!user?.info?.hasAccess) {
					return;
				}
				set(
					produce((state) => {
						const current = get().instructBarIsOpen;
						state.instructBarIsOpen = !current;
					})
				);
			},
			setInstructBarVisibility: (value) => {
				const user = store.getState().user;
				if (!user?.info?.hasAccess) {
					return;
				}
				set(
					produce((state) => {
						state.instructBarIsOpen = value;
					})
				);
			},
			onCommandFocus: ({ shouldCenter, x, y, zoom }) => {
				console.log('editorMainStore/onCommandFocus');
				set(
					produce((state) => {
						state.centerFlow.shouldCenter = shouldCenter;
						state.centerFlow.x = x;
						state.centerFlow.y = y;
						state.centerFlow.zoom = zoom;
					})
				);
			},
			onEditorInit: async ({
				brainId,
				sessionId,
				profileSlug,
				url,
				token,
			}) => {
				console.log('editorMainStore/onEditorInit');
				if (!(brainId && profileSlug && url && token))
					throw new Error(
						`Either/all of brainId/profileSlug/url/token missing or invalid!`
					);
				set(
					produce(
						/** @type {(state: import('./types').EditorStore)=> void} */
						(state) => {
							state.brainId = brainId;
							state.profileSlug = profileSlug;
							state.runtime = url;
							state.token = token;
						}
					)
				);
				// set flows
				const flows = getCompleteFlow();
				get().setMayaFlow(flows);
				// set tabs
				get().setTabs();
				//set active tab
				get().setActiveTab('');
				// set nodes
				get().setNodeConfigs();
				// set palette
				get().initPalette();
				get().setDirty(false); // set editor not dirty
			},
			onImportFlowsResolveConflictMerge: () => {
				console.log('editorMainStore/onImportFlowsResolveConflictMerge');
				// set flows
				const flows = getCompleteFlow();
				get().setMayaFlow(flows);
				// set tabs
				get().setTabs();
				const tabsLength = get().tabs.allIds.length;
				if (!(tabsLength > 0)) throw new Error('No tabs available!');
				const lastTabId = get().tabs.allIds[tabsLength - 1];
				if (!lastTabId) throw new Error(`last tab id is invalid!`);
				get().setActiveTab(lastTabId); // set last tabId to active
				// set nodes
				get().setNodeConfigs();
				// set palette
				get().initPalette();
				get().setDirty(true); // set editor dirty
			},
			nodeRedNodeTypeAdded: () => {
				console.log('editorMainStore/nodeRedNodeTypeAdded');
				// set nodes
				get().setNodeConfigs();
				// set palette
				get().initPalette();
				get().setDirty(true); // set editor dirty
			},
			onDeployComplete: async () => {
				console.log('editorMainStore/onDeployComplete');
				// set flows
				const flows = await getCompleteFlow();
				get().setMayaFlow(flows);
				const activeTabId = get().tabs.activeTab;
				const activeTabType = get().tabs.byId[activeTabId].type;
				// set tabs
				get().setTabs();
				// set active tab
				if (activeTabType === 'subflow')
					get().toggleSubflowEditingState(activeTabId, true);
				else get().setActiveTab(activeTabId);
				get().setDirty(false);
				const brainId = get().brainId;
				const profileSlug = get().profileSlug;
				if (!(brainId && profileSlug))
					throw new Error(`brainId/profileSlug invalid!`);
				const deploySkillResponse = await deploySkillAndUpdateRuntime({
					brainId,
					profileSlug,
				});
				// dispatching to REDUX store blanks out the editor.
				// Mostly because the createPortal comp re-render. Therefore
				// have memoised the top level Workspace comp and that has fixed the issue. Todo rigorous testing.
				store.dispatch(updateBrainById(brainId, deploySkillResponse));
			},
			/** Setting the mayaFlow array in the global state. */
			setMayaFlow: (mayaFlow) => {
				console.log('editorMainStore/setMayaFlow');
				set(produce((state) => void (state.mayaFlow = mayaFlow)));
			},
			/** Setting the nodeConfigs in the global state. */
			setNodeConfigs: () => {
				console.log('editorMainStore/setNodeConfigs');
				const allTypes = getNodeTypes();
				const byType = {};
				for (let type of allTypes) {
					byType[type] = getNodeByType(type);
				}
				set(
					produce(
						(state) =>
							void (state.nodeConfigs = {
								allTypes,
								byType,
							})
					)
				);
			},
			/** Setting the tabs object in the global state. */
			setTabs: async () => {
				console.log('editorMainStore/setTabs');
				const mayaFlow = get().mayaFlow;
				// get flow by tabs
				const tabs = getFlowByTabs(mayaFlow);

				// parse mayaflow into reactflow
				const parsedTabs = _.map(tabs, (tab) => {
					tab.reactFlowByTab = parseMayaLangToReactFlow(
						tab.mayaFlowByTab,
						1
					);
					tab['_id'] = tab['_id'] ? tab['_id'] : tab.id;
					if (tab.type === 'subflow') {
						const reactFlowIO = parseSubfowIOToReactFlow(tab);
						tab.reactFlowByTab.nodes = uniqBy(
							[
								...tab.reactFlowByTab.nodes,
								...reactFlowIO.nodes,
							].reverse(),
							'id'
						);
						tab.reactFlowByTab.edges = uniqBy(
							[
								...tab.reactFlowByTab.edges,
								...reactFlowIO.edges,
							].reverse(),
							'id'
						);
					}
					return tab;
				});
				set(
					produce((state) => {
						const normalizedTabs = normalizeNew(parsedTabs);
						// set the normalized tabs only, do not touch active tab here
						state.tabs.byId = normalizedTabs.byId;
						state.tabs.allIds = normalizedTabs.allIds;
						// const mainTab = findMainTab({ tabs: normalizedTabs });
						// const { nodeId, tabId } = getMainSubflowDetails({ mainTab });
						// state.mainSubflow = { nodeId, tabId };
						// state.instanceSubflows = getInstanceSubflowDetails({
						// 	mainTab,
						// 	mainSubflowNodeId: nodeId,
						// });
					})
				);

				const brain = await getBrainById({
					brainId: get().brainId,
					slug: get().profileSlug,
				});
				get().setInstalledModules(brain.modules);
				get().setMissingModules();
				get().setDashboardNodes(
					get().tabs.byId[get().tabs.activeTab].reactFlowByTab
				);
			},
			onTabAdd: () => {
				console.log('editorMainStore/onTabAdd');
				const tabId = newTabIdGenerator();
				set(
					produce(
						/** @param {import('./types').EditorStore} state */
						(state) => {
							state.tabs.allIds.push(tabId);
							state.tabs.byId[tabId] = {
								disabled: false,
								mayaFlowByTab: [],
								reactFlowByTab: { nodes: [], edges: [] },
								id: tabId,
								info: '',
								label: `Flow ${state.tabs.allIds.length}`,
								type: 'tab',
							};
							state.tabs.activeTab = tabId;
							state.dirty = true; // set editor dirty
						}
					)
				);
			},
			onTabDelete: (tabId) => {
				console.log('editorMainStore/onTabDelete');
				if (get().tabs.allIds.length === 1)
					throw new Error('Last tab cannot be deleted');
				set(
					produce(
						/** @param {import('./types').EditorStore} state */
						(state) => {
							const index = state.tabs.allIds.indexOf(tabId);
							if (index > -1) {
								state.tabs.allIds.splice(index, 1);
								delete state.tabs.byId[tabId];
								state.tabs.activeTab =
									state.tabs.allIds[state.tabs.allIds.length - 1];
							}
							state.dirty = true; // set editor dirty
						}
					)
				);
			},
			/** Renaming the tab. */
			renameTab: (tabId, newTabLabel) => {
				console.log('editorMainStore/renameTab');
				set(
					produce((state) => {
						if (state.tabs.byId[tabId])
							state.tabs.byId[tabId].label = newTabLabel;
					})
				);
			},
			/**
			 * Update tab metadata
			 */
			updateTab: (tabId, metadata) => {
				console.log('editorMainStore/updateTab');
				set(
					produce((state) => {
						if (state.tabs.byId[tabId])
							state.tabs.byId[tabId] = {
								...state.tabs.byId[tabId],
								...metadata,
								instances: [],
							};
						const tab = state.tabs.byId[tabId];
						if (tab.type === 'subflow') {
							state.tabs.byId[tabId].reactFlowByTab =
								parseMayaLangToReactFlow(tab.mayaFlowByTab, 1);

							const reactFlowIO = parseSubfowIOToReactFlow(tab);
							tab.reactFlowByTab.nodes = uniqBy(
								[
									...state.tabs.byId[tabId].reactFlowByTab.nodes,
									...reactFlowIO.nodes,
								].reverse(),
								'id'
							);
							tab.reactFlowByTab.edges = uniqBy(
								[
									...state.tabs.byId[tabId].reactFlowByTab.edges,
									...reactFlowIO.edges,
								].reverse(),
								'id'
							);
						}
					})
				);
			},
			/** Setting the reactFlowByTab and mayaFlowByTab of a particular tab in the global state. */
			setFlowToTab: (tabId, reactFlowByTab, skipSubflowUpdate = false) => {
				console.log('editorMainStore/setFlowToTab');
				set(
					produce((state) => {
						try {
							state.tabs.byId[tabId].reactFlowByTab = reactFlowByTab;
							const parsedMayaFlowByTab =
								// @ts-ignore
								parseReactFlowtoMayaLang(reactFlowByTab);
							state.tabs.byId[tabId].mayaFlowByTab = parsedMayaFlowByTab;
							if (!skipSubflowUpdate) {
								const parsedSubflowIO =
									// @ts-ignore
									parseReactFlowToSubflowIO(reactFlowByTab);
								state.tabs.byId[tabId].in = parsedSubflowIO.in;
								state.tabs.byId[tabId].out = parsedSubflowIO.out;
							}
						} catch (e) {} // No choice. Synth sidebar gotta work outside
					})
				);
			},
			/**
			 * Update node values for a tab
			 * @param {*} updatedNodes
			 * @param {*} tabId
			 */
			setNodesToTab: (updatedNodes, tabId) => {
				console.log('editorMainStore/setNodesToTab');
				set(
					produce((state) => {
						state.tabs.byId[tabId].reactFlowByTab.nodes = updatedNodes;
						state.tabs.byId[tabId].mayaFlowByTab =
							parseReactFlowtoMayaLang(
								state.tabs.byId[tabId].reactFlowByTab
							);
						const parsedSubflowIO = parseReactFlowToSubflowIO(
							state.tabs.byId[tabId].reactFlowByTab
						);
						state.tabs.byId[tabId].in = parsedSubflowIO.in;
						state.tabs.byId[tabId].out = parsedSubflowIO.out;
					})
				);
				const nodes = get().tabs.byId[tabId].reactFlowByTab.nodes;
				get().setNodesWithIssues(nodes);
				get().setMissingModules();
				get().setDirty(true);
			},
			/**
			 * Update edge values for a tab
			 * @param {*} updatedEdges
			 * @param {*} tabId
			 */
			setEdgesToTab: (updatedEdges, tabId) => {
				console.log('editorMainStore/setEdgesToTab');
				set(
					produce((state) => {
						state.tabs.byId[tabId].reactFlowByTab.edges = updatedEdges;
						state.tabs.byId[tabId].mayaFlowByTab =
							parseReactFlowtoMayaLang(
								state.tabs.byId[tabId].reactFlowByTab
							);
						const parsedSubflowIO = parseReactFlowToSubflowIO(
							state.tabs.byId[tabId].reactFlowByTab
						);
						state.tabs.byId[tabId].in = parsedSubflowIO.in;
						state.tabs.byId[tabId].out = parsedSubflowIO.out;
					})
				);
			},
			// TODO: Removes a set of nodes from tab
			removeNodesFromTab: (tabId, removedNodes) => {
				console.log('editorMainStore/removeNodesFromTab');
				set(
					produce((state) => {
						const { nodes, edges } =
							state.tabs.byId[tabId].reactFlowByTab;
						const updatedNodes = _.filter(
							nodes,
							(n) => !removedNodes.includes(n)
						); // TODO: verify
						const updatedEdges = _.filter(edges, (e) =>
							_.some(
								removedNodes,
								(rn) => rn.id !== e.source && rn.id !== e.target
							)
						); // TODO: verify
						state.tabs.byId[tabId].reactFlowByTab.nodes = updatedNodes;
						state.tabs.byId[tabId].reactFlowByTab.edges = updatedEdges;
						state.tabs.byId[tabId].mayaFlowByTab =
							parseReactFlowtoMayaLang(
								state.tabs.byId[tabId].reactFlowByTab
							);
					})
				);
			},
			getDeployableMayaFlow: () => {
				console.log('editorMainStore/getDeployableMayaFlow');
				/** Init exportedFlow with just config nodes (if avail) */
				const tabs = get().tabs;
				let exportedFlow = [...getConfigNodes()];
				const allMayaFlows = tabs.allIds.reduce((acc, tabId) => {
					const tabFlow = tabs.byId[tabId].reactFlowByTab;
					const mayaFlowByTab = parseReactFlowtoMayaLang(tabFlow);
					acc.push(...mayaFlowByTab);
					return acc;
				}, []);
				// adding all the flows of a tab
				for (let node of allMayaFlows) {
					exportedFlow.push(
						_.omit(node, ['_', '_def']) // solves serializable issue
						// node
					);
				}
				// adding tabs (tab Objects to exported flow)
				for (let tab in tabs.byId) {
					exportedFlow.push(
						_.omit(tabs.byId[tab], [
							'mayaFlowByTab',
							'reactFlowByTab',
							'editing',
							'credentials',
							'infoEditor',
							'_def', // exclude defaults object
						])
					); // adding the tab object
				}
				// Add temp config nodes to deploying flow
				const configNodes = get().configNodes;
				if (configNodes?.length)
					for (let i = 0; i < configNodes.length; i++) {
						exportedFlow.push(configNodes[i]);
					}
				// need to reverse the array before de-dup via uniqBy to ensure newer nodes replace older nodes
				exportedFlow = uniqBy(exportedFlow.reverse(), 'id'); // deduplication
				// if (!isFlowValid(exportedFlow)) {
				// 	set(
				// 		produce((state) => {
				// 			state.valid = false;
				// 		})
				// 	);
				// 	throw new Error('exportedFlow is invalid!');
				// }
				return exportedFlow;
			},
			onDeploy: async (sessionId) => {
				console.log('editorMainStore/onDeploy');
				const exportedFlow = get().getDeployableMayaFlow();
				get().setAutoDeploying(true);
				deployEditor(true, true, exportedFlow)
					.then(() => {
						setTimeout(() => {
							get().setAutoDeploying(false);
						}, 500);
					})
					.catch((e) => {
						setTimeout(() => {
							get().setAutoDeploying(false);
						}, 500);
					});
				const reactFlow =
					get().tabs.byId[get().tabs.activeTab].reactFlowByTab;
				get().setDashboardNodes(reactFlow);

				// Updating the session object in PAC Engine
				if (sessionId) {
					const tabId = get().tabs.activeTab;
					const recipeDiff = compareRecipes({
						recipe: pacEngineStore.getState().recipe,
						serverRecipe: pacEngineStore.getState().serverRecipe,
					});
					const flowDiff = compareFlows({
						flow: JSON.stringify([
							...editorStore.getState().tabs.byId[tabId].mayaFlowByTab,
							...window.RED.nodes
								.createCompleteNodeSet()
								.filter(
									(n) =>
										!n.x &&
										!n.y &&
										n.type !== 'tab' &&
										n.type !== 'subflow' &&
										n.type !== 'group'
								),
						]),
						serverFlow: JSON.stringify(
							pacEngineStore.getState().serverFlow
						),
					});
					/** @type {import ('../Tools/pac-engine/types').Diff} */
					const diff = {
						recipe: { steps: recipeDiff },
						flow: { nodes: flowDiff },
					};
					if (diffExists({ diff })) {
						// console.log('diff', diff);
						const sessionRes = await changeSession({ sessionId, diff });
						if (!sessionRes) {
							throw new Error('Request to changeSession failed');
						} else {
							pacEngineStore.getState().setServerRecipe({
								newRecipe: sessionRes.data?.response?.steps || {},
							});
							pacEngineStore.getState().setServerFlow({
								newFlow: sessionRes.data?.response?.stitched_flow || {},
							});
						}
					}
				}
			},
			setDoubleClickedNode: async (nodeObject) => {
				console.log('editorMainStore/setDoubleClickedNode');
				set(
					produce((state) => {
						state.doubleClickedNode = nodeObject;
						state.issuesSidebarIsOpen = false;
					})
				);
			},
			onFlowExport: async ({ profileSlug, brainId, mode }) => {
				console.log('editorMainStore/onFlowExport');
				/** Init exportedFlow with just config nodes (if avail) */
				const tabs = get().tabs;
				/**
				 * @type {import('./types').MayaFlowEntity[]}
				 */
				let exportedFlow = [...getConfigNodes()];
				switch (mode) {
					case 'all': {
						for (let tab in tabs.byId) {
							// adding all the flows of a tab
							for (let node of tabs.byId[tab].mayaFlowByTab) {
								exportedFlow.push(
									// node
									// @ts-ignore
									_.omit(node, ['_', '_def']) // solves serializable issue
								);
							}
							exportedFlow.push(
								// adding the tab object
								// @ts-ignore
								_.omit(tabs.byId[tab], [
									'mayaFlowByTab',
									'reactFlowByTab',
									'editing',
									'credentials',
									'infoEditor',
									'_def', // exclude defaults object
								])
							);
						}
						break;
					}
					case 'selected': {
						// Set exportedFlow to be empty (ie. without config nodes)
						// incase of selected flow export
						// exportedFlow = [];
						const allConfigNodes = exportedFlow;
						// exportedFlow = [];
						const activeTabId = get().tabs.activeTab;
						const tab = get().tabs.byId[activeTabId];
						const selectedFlow = getSelectedMayaFlow(tab.reactFlowByTab);

						for (let node of selectedFlow) {
							exportedFlow.push(
								// node
								// @ts-ignore
								_.omit(node, ['_', '_def']) // solves serializable issue
							);
						}
						exportedFlow.push(
							// adding the tab object
							// @ts-ignore
							_.omit(tab, [
								'mayaFlowByTab',
								'reactFlowByTab',
								'editing',
								'credentials',
								'infoEditor',
								'_def', // exclude defaults object
							])
						);
						// Include nodes from subflows --- start
						const subflowTabIds = exportedFlow
							.filter((node) => {
								if (node.type.startsWith('subflow:')) {
									return true;
								} else {
									return false;
								}
							})
							.map((node) => {
								const subflowTabId = node.type.split(':')[1];
								return subflowTabId;
							});
						subflowTabIds.forEach((tabId) => {
							const tab = get().tabs.byId[tabId];
							for (let node of tab.mayaFlowByTab) {
								exportedFlow.push(
									// node
									// @ts-ignore
									_.omit(node, ['_', '_def']) // solves serializable issue
								);
							}
							exportedFlow.push(
								// adding the tab object
								// @ts-ignore
								_.omit(tab, [
									'mayaFlowByTab',
									'reactFlowByTab',
									'editing',
									'credentials',
									'infoEditor',
									'_def', // exclude defaults object
								])
							);
						});
						// Include nodes from subflows --- end

						// logic to include all the config nodes that were used anywhere in the
						// selected set of nodes -- start
						let includedConfig = [];
						for (let index = 0; index < allConfigNodes.length; index++) {
							for (
								let index_inner = 0;
								index_inner < exportedFlow.length;
								index_inner++
							) {
								if (
									Object.values(exportedFlow[index_inner]).indexOf(
										allConfigNodes[index]['id']
									) > -1
								) {
									includedConfig.push(allConfigNodes[index]);
									break;
								}
							}
						}
						includedConfig = _.uniqBy(includedConfig, 'id');
						exportedFlow = [...exportedFlow, ...includedConfig];
						// -- config nodes inclusion end
						break;
					}
					case 'current': {
						const activeTabId = get().tabs.activeTab;
						const tab = get().tabs.byId[activeTabId];
						const allConfigNodes = exportedFlow;
						for (let node of tab.mayaFlowByTab) {
							exportedFlow.push(
								// node
								// @ts-ignore
								_.omit(node, ['_', '_def']) // solves serializable issue
							);
						}
						exportedFlow.push(
							// adding the tab object
							// @ts-ignore
							_.omit(tab, [
								'mayaFlowByTab',
								'reactFlowByTab',
								'editing',
								'credentials',
								'infoEditor',
								'_def', // exclude defaults object
							])
						);
						// Include nodes from subflows --- start
						const subflowTabIds = exportedFlow
							.filter((node) => {
								if (node.type.startsWith('subflow:')) {
									return true;
								} else {
									return false;
								}
							})
							.map((node) => {
								const subflowTabId = node.type.split(':')[1];
								return subflowTabId;
							});
						subflowTabIds.forEach((tabId) => {
							const tab = get().tabs.byId[tabId];
							for (let node of tab.mayaFlowByTab) {
								exportedFlow.push(
									// node
									// @ts-ignore
									_.omit(node, ['_', '_def']) // solves serializable issue
								);
							}
							exportedFlow.push(
								// adding the tab object
								// @ts-ignore
								_.omit(tab, [
									'mayaFlowByTab',
									'reactFlowByTab',
									'editing',
									'credentials',
									'infoEditor',
									'_def', // exclude defaults object
								])
							);
						});
						// Include nodes from subflows --- end

						// Include nodes from subflows --- end

						// logic to include all the config nodes that were used anywhere in the
						// selected set of nodes -- start
						let includedConfig = [];
						for (let index = 0; index < allConfigNodes.length; index++) {
							for (
								let index_inner = 0;
								index_inner < exportedFlow.length;
								index_inner++
							) {
								if (
									Object.values(exportedFlow[index_inner]).indexOf(
										allConfigNodes[index]['id']
									) > -1
								) {
									includedConfig.push(allConfigNodes[index]);
									break;
								}
							}
						}
						includedConfig = _.uniqBy(includedConfig, 'id');
						exportedFlow = [...exportedFlow, ...includedConfig];
						// -- config nodes inclusion end
						break;
					}
					default: {
						throw new Error(`mode not provided`);
					}
				}
				// need to reverse the array before de-dup via uniqBy to ensure newer nodes replace older nodes
				exportedFlow = uniqBy(exportedFlow.reverse(), 'id'); // deduplication
				// Throws an error if the flow is invalid
				// if (!isFlowValid(exportedFlow)) {
				// 	set(
				// 		produce((state) => {
				// 			state.valid = false;
				// 		})
				// 	);
				// 	throw new Error('exportedFlow is invalid!');
				// }
				const getMayaJsonRes = await getMayaJson(
					profileSlug,
					exportedFlow,
					brainId
				);
				const exportReadyJson = getMayaJsonRes?.data?.results;
				if (!exportReadyJson) throw new Error(`exportReadyJson invalid!`);
				if (mode === 'selected') {
					const flowWithoutTab = exportReadyJson.filter((node) => {
						return node.type !== 'tab';
					});
					return flowWithoutTab;
				} else {
					return exportReadyJson;
				}
			},
			/** Setting active tab */
			setActiveTab: (tabId) => {
				console.log('editorMainStore/setActiveTab');
				if (!tabId) {
					tabId = get().tabs.allIds[0];
				}
				const RED = getRedInstance();
				RED.workspaces.show(tabId); // set active tab in node RED
				set(produce((state) => void (state.tabs.activeTab = tabId)));
			},
			/** Initializing palette */
			initPalette: async () => {
				console.log('editorMainStore/initPalette');
				get().setPaletteModuleList();
				get().setPaletteCategories();
			},
			setPaletteModuleList: () => {
				console.log('editorMainStore/setPaletteModuleList');
				set(
					produce(
						(state) => void (state.palette.moduleList = getModuleList())
					)
				);
			},
			setPaletteCategories: () => {
				console.log('editorMainStore/setPaletteCategories');
				const categories = new Set();
				const nodeConfigs = get().nodeConfigs;
				for (let type of nodeConfigs.allTypes) {
					// @ts-ignore
					categories.add(nodeConfigs.byType[type].category);
				}
				set(
					produce(
						(state) =>
							// @ts-ignore
							void (state.palette.categories = [...categories])
					)
				);
			},
			// setModuleInstallState: (val) => (
			// 	set(produce(state => void (state.moduleInstallState = val)))
			// ),
			setValid: (val) => {
				console.log('editorMainStore/setValid');
				set(produce((state) => void (state.valid = val)));
			},
			setDirty: (val = true) => {
				console.log('editorMainStore/setDirty');
				set(produce((state) => void (state.dirty = val)));
			},
			setNodesWithIssues: (nodes) => {
				const result = [];
				nodes.forEach((n) => {
					if (
						!n?.data?.node?.valid &&
						n?.type !== 'transient' &&
						n?.data?.type !== 'subflowIO'
					)
						result.push(n);
				});
				set(produce((state) => void (state.nodesWithIssues = result)));
				if (result.length === 0 && get().missingModules.length === 0) {
					set(
						produce((state) => void (state.issuesSidebarIsOpen = false))
					);
				}
			},
			setInstalledModules: (installedModules) => {
				set(
					produce(
						(state) => void (state.installedModules = installedModules)
					)
				);
			},
			setMissingModules: async () => {
				console.log('editorMainStore/setMissingModules');
				const flow = get().getDeployableMayaFlow();
				await debouncedGetMissingModules(flow)?.then((res) => {
					if (res) {
						set(produce((state) => void (state.missingModules = res)));
						if (res.length === 0 && get().nodesWithIssues.length === 0) {
							set(
								produce(
									(state) => void (state.issuesSidebarIsOpen = false)
								)
							);
						}
					}
				});
			},
			addTransient: (mayaFlow, tabId) => {
				console.log('editorMainStore/addTransient');
				// @ts-ignore
				const filteredFlow = _.filter(mayaFlow, (n) => n.type !== 'tab');
				const reactFlowByTab = get().tabs.byId[tabId].reactFlowByTab;

				const { nodes, edges, configNodes } = parseMayaLangToReactFlow(
					filteredFlow,
					1,
					'transient'
				);

				get().updateConfigNodes(configNodes, tabId);
				get().setFlowToTab(tabId, {
					nodes: [...nodes, ...reactFlowByTab.nodes],
					edges: [...edges, ...reactFlowByTab.edges],
				});
			},
			removeTransient: (tabId) => {
				console.log('editorMainStore/removeTransient');
				const reactFlowByTab = get().tabs.byId[tabId].reactFlowByTab;
				const nodes = _.filter(
					reactFlowByTab.nodes,
					(n) => n.type !== 'transient'
				);
				const removedNodes = _.filter(
					reactFlowByTab.nodes,
					(n) => n.type === 'transient'
				);

				const edges = _.filter(
					reactFlowByTab.edges,
					(e) => !removedNodes.some((n) => n.id === e.source)
				);

				get().setFlowToTab(tabId, { nodes, edges });
			},
			removeSpecial: (tabId) => {
				console.log('editorMainStore/removeSpecial');
				const reactFlowByTab = get().tabs.byId[tabId].reactFlowByTab;
				const nodes = _.filter(
					reactFlowByTab.nodes,
					(n) => n.type !== 'special'
				);
				const removedNodes = _.filter(
					reactFlowByTab.nodes,
					(n) => n.type === 'special'
				);

				const edges = _.filter(
					reactFlowByTab.edges,
					(e) => !removedNodes.some((n) => n.id === e.source)
				);

				get().setFlowToTab(tabId, { nodes, edges });
			},
			transientToSpecialConvert: (tabId) => {
				console.log('editorMainStore/transientToSpecialConvert');
				const reactFlowByTab = get().tabs.byId[tabId].reactFlowByTab;
				const nodes = _.cloneDeep(reactFlowByTab.nodes);
				nodes.forEach((node) => {
					node.type = 'special';
				});
				const edges = reactFlowByTab.edges;
				get().setFlowToTab(tabId, { nodes, edges });
			},
			updateConfigNodes: (nodes, tabId) => {
				console.log('editorMainStore/updateConfigNodes');
				set(
					produce(
						/** @type {(state: import('./types').EditorStore)=> void} */
						(state) => {
							state.configNodes = nodes;
						}
					)
				);
			},
			updateSingleConfigNode: (configNode, tabId) => {
				set(
					produce((state) => {
						const configNodeId = configNode.id;
						const nodeIndex = state.configNodes.findIndex((c) => {
							return c.id === configNodeId;
						});
						if (nodeIndex > -1) {
							state.configNodes[nodeIndex] = {
								...state.configNodes[nodeIndex],
								...configNode,
							};
						} else {
							state.configNodes.push({ ...configNode });
						}
					})
				);
			},
			/**
			 * set subflow editing state
			 */
			toggleSubflowEditingState: (subflowId, editing) => {
				console.log(
					'editorMainStore/toggleSubflowEditingState | subflow:',
					subflowId
				);
				set(
					produce(
						(state) => void (state.tabs.byId[subflowId].editing = editing)
					)
				);
				get().setActiveTab(editing ? subflowId : undefined);
			},
			/**
			 * TODO: remove subflow (including instances)
			 */
			removeSubflow: ({ subflowId, sessionId }) => {
				console.log('editorMainStore/removeSubflow');
				// find all instances of subflow and delete them
				const tabs = get().tabs;
				for (let tabId of tabs.allIds) {
					const subflowInstances = _.filter(
						tabs.byId[tabId].reactFlowByTab.nodes,
						(n) =>
							// @ts-ignore
							n.type.substing(0, 8) === 'subflow:' &&
							// @ts-ignore
							n.type.substing(8) === subflowId
					);
					get().removeNodesFromTab(tabId, subflowInstances);
				}
				// delete subflow tab
				get().onTabDelete(subflowId);
				// change active tab
				// @ts-ignore
				get().setActiveTab();
				// refresh mayaflow
				get().onDeploy(sessionId);
			},
			/**
			 * Create new empty subflow
			 */
			createEmptySubflow: () => {
				console.log('editorMainStore/removeSubflow');
				// @ts-ignore
				window.RED.subflow.createSubflow();
				// @ts-ignore
				const history = window.RED.history.peek();
				if (history.t === 'createSubflow') {
					const subflow = history.subflow.subflow;
					set(
						produce((state) => {
							state.tabs.allIds.push(subflow.id);
							state.tabs.byId[subflow.id] = {
								...subflow,
								// _def: undefined,
								reactFlowByTab: [],
								mayaFlowByTab: [],
								editing: true,
							};
							state.tabs.activeTab = subflow.id;
						})
					);
				}
			},
			/**
			 * Create subflow from selection
			 */
			createSubflowFromSelection: (sessionId) => {
				console.log('editorMainStore/createSubflowFromSelection');
				const tabs = get().tabs;
				const nodeSet = tabs.byId[tabs.activeTab].reactFlowByTab.nodes;
				// @ts-ignore
				const selectedNodes = _.filter(nodeSet, (n) => n.selected);
				// @ts-ignore
				window.RED.view.movingSet.clear();

				selectedNodes.forEach((selectedNode) => {
					// @ts-ignore
					const node = getNodeById(selectedNode.id);
					// @ts-ignore
					window.RED.view.movingSet.add(node);
				});

				// @ts-ignore
				window.RED.subflow.convertToSubflow();

				// TODO: make this better
				// @ts-ignore

				get().onEditorInit({
					brainId: get().brainId,
					sessionId,
					profileSlug: get().profileSlug,
					url: get().runtime,
					token: get().token,
				});
			},
			/**
			 * Create subflow from the passed flow
			 */
			createSubflow: ({ flow, subflowNode, loc, isPasted }) => {
				console.log('editorMainStore/createSubflow');
				// @ts-ignore
				window.RED.view.movingSet.clear();
				// Creating subflow internal nodes
				flow.forEach((selectedNode) => {
					if (isPasted) {
						addNode(
							selectedNode.type,
							selectedNode.x,
							selectedNode.y,
							selectedNode.z,
							selectedNode.id,
							selectedNode.wires
						);
					}
				});

				// Adding edges to the previously created nodes
				flow.forEach((n) => {
					n.wires.forEach((outer) => {
						outer.forEach((targetId) => {
							const source = getNodeById(n.id);
							const target = getNodeById(targetId);
							if (source && target) {
								window.RED.nodes.addLink({
									source,
									target,
									sourceHandle: n.wires.indexOf(outer),
								});
							}
						});
					});
				});

				// Adding nodes to the moving set
				flow.forEach((node) => {
					// @ts-ignore
					window.RED.view.movingSet.add(node);
				});

				if (
					window.RED.view.selection().nodes &&
					window.RED.view.selection().nodes.length
				) {
					// const mainSubflowNodeId = get().mainSubflow.nodeId;
					// @ts-ignore
					window.RED.subflow.convertToSubflow(subflowNode, loc, isPasted);
				} else {
					throw new Error('nodes selection malformed');
				}

				// @ts-ignore
				get().onEditorInit({
					brainId: get().brainId,
					profileSlug: get().profileSlug,
					url: get().runtime,
					token: get().token,
				});

				const subflowTabId = subflowNode.id;
				const subflowIn = subflowNode.in;
				const subflowOut = subflowNode.out;

				set(
					produce((state) => {
						state.tabs.byId[subflowTabId].in = subflowIn;
						state.tabs.byId[subflowTabId].out = subflowOut;
					})
				);

				const { nodes, edges } = parseMayaLangToReactFlow(
					flow,
					1,
					'transient'
				);
				get().setFlowToTab(subflowTabId, { nodes, edges }, true);
			},
			/**
			 * Update if edge is being drawn
			 */
			// @ts-ignore
			onRfConnectStart: (e, nodeId) => {
				console.log('editorMainStore/onRfConnectStart');
				set(
					produce((state) => {
						state.interactionMode = 'edgeDrag';
						state.edgeDrag = nodeId;
					})
				);
			},
			onRfConnectStop: () => {
				console.log('editorMainStore/onRfConnectStop');
				set(
					produce((state) => {
						state.interactionMode = 'normal';
						state.edgeDrag = {};
					})
				);
			},
			setAreModuleInstalling: (areModuleInstalling) => {
				set((state) => {
					state.areModuleInstalling = areModuleInstalling;
				});
			},
			setIsModuleInstallationDrawerVisible: (
				isModuleInstallationDrawerVisible
			) => {
				set((state) => {
					state.isModuleInstallationDrawerVisible =
						isModuleInstallationDrawerVisible;
				});
			},
			setSelectedMissingModule: (selectedMissingModule) => {
				set((state) => {
					state.selectedMissingModule = selectedMissingModule;
				});
			},
			setIsModuleConfigured: (isModuleConfigured) => {
				set((state) => {
					state.isModuleConfigured = isModuleConfigured;
				});
			},
			getReactFlowByTab: ({ tabId }) => {
				return get().tabs.byId[tabId].reactFlowByTab;
			},

			getMayaFlowByTab: ({ tabId }) => {
				return get()?.tabs?.byId?.[tabId]?.mayaFlowByTab || [];
			},
			setMainSubflow: () => {},
			onPasteSubflow: ({ mayaFlow }) => {
				console.log('editorMainStore/onPasteSubflow');
				const RED = getRedInstance();
				const subflowNodes = mayaFlow.filter((node) =>
					node.type.startsWith('subflow:')
				);
				const subflowTabs = mayaFlow.filter(
					(node) => node.type === 'subflow'
				);
				const subflowTabIds = subflowTabs.map((tab) => tab.id);
				const originalSubflowTabId = subflowTabs.find(
					(tab) => tab.id === tab._id
				).id;
				const subflowInternalNodes = mayaFlow.filter((node) =>
					subflowTabIds.includes(node.z)
				);
				const completeSubflowInfo = subflowTabs.map((tab) => {
					const info = {};
					info.subflowTab = tab;
					info.subflowNode = subflowNodes.find(
						(node) => node.type.split(':')[1] === tab.id
					);
					info.nodes = subflowInternalNodes.filter(
						(node) => node.z === tab.id
					);
					return info;
				});

				RED.view.movingSet.clear();

				// Creating subflow on Node-RED
				for (const info of completeSubflowInfo) {
					RED.subflow.createSubflow(info.subflowTab, originalSubflowTabId);
				}

				get().onEditorInit({
					brainId: get().brainId,
					profileSlug: get().profileSlug,
					url: get().runtime,
					token: get().token,
				});

				const activeTab = get().tabs.activeTab;
				const currentReactFlow = get().getReactFlowByTab({
					tabId: activeTab,
				});
				let subflowNodesRF = structuredClone(currentReactFlow.nodes);
				let subflowEdgesRF = structuredClone(currentReactFlow.edges);
				for (const info of completeSubflowInfo) {
					const subflowTabId = info.subflowTab.id;
					const subflowIn = info.subflowTab.in;
					const subflowOut = info.subflowTab.out;

					set(
						produce((state) => {
							state.tabs.byId[subflowTabId].in = subflowIn;
							state.tabs.byId[subflowTabId].out = subflowOut;
						})
					);

					// Setting subflow node to the active tab
					const subflowNode = { ...info.subflowNode, z: activeTab };
					const { nodes: subflowNodeRF, edges: subflowEdgeRF } =
						parseMayaLangToReactFlow([subflowNode], 1, 'transient');

					if (subflowNodeRF?.[0]) {
						const clonedNode = structuredClone(subflowNodeRF?.[0]);
						subflowNodesRF = subflowNodesRF.concat(clonedNode);
					}
					if (subflowEdgeRF?.[0]) {
						const clonedEdge = structuredClone(subflowEdgeRF?.[0]);
						subflowEdgesRF = subflowEdgesRF.concat(clonedEdge);
					}
					get().setFlowToTab(
						activeTab,
						{
							nodes: subflowNodesRF,
							edges: subflowEdgesRF,
						},
						true
					);
					const { nodes, edges } = parseMayaLangToReactFlow(
						info.nodes,
						1,
						'transient'
					);
					get().setFlowToTab(subflowTabId, { nodes, edges }, true);
				}
			},
			onPasteFlow: ({ mayaFlow, tabId, loc }) => {
				console.log('editorMainStore/onPasteFlow');
				// Calling the subflow paste handler and returning early if flow contains a subflow
				if (mayaFlow.some((n) => n.type === 'subflow')) {
					get().onPasteSubflow({ mayaFlow });
					return;
				}

				const subflowIds = [];
				mayaFlow.forEach((n) => {
					if (n.type === 'subflow') {
						subflowIds.push(n.id);
					}
				});

				const subflowInternals = [];
				subflowIds.forEach((id) => {
					const subflowDetails = {
						flow: mayaFlow.filter((n) => n.z === id),
						subflowNode: mayaFlow.find((n) => n.id === id),
					};
					subflowInternals.push(subflowDetails);
				});

				const modifiedFlow = mayaFlow
					.filter((n) => n.type !== 'tab' && !n.type.includes('subflow:'))
					.map((n) => {
						return { ...n, z: subflowIds.includes(n.z) ? n.z : tabId };
					});

				let runningYCount = loc?.y;
				subflowInternals.forEach((subflow) => {
					runningYCount += 75;
					const newLoc = { x: loc?.x, y: runningYCount };
					get().createSubflow({
						flow: subflow.flow,
						subflowNode: subflow.subflowNode,
						loc: loc ? newLoc : null,
						isPasted: true,
					});
				});

				const newFlow = cloneDeep(
					modifiedFlow.filter((n) => n.z === tabId)
				);
				const { nodes, edges } = parseMayaLangToReactFlow(
					newFlow,
					1,
					'transient'
				);
				const newNodes = nodes.map((n) => ({ ...n, selected: true }));
				const newEdges = edges.map((e) => ({ ...e, selected: true }));
				const existingNodes = get().tabs.byId[tabId].reactFlowByTab.nodes;
				const existingEdges = get().tabs.byId[tabId].reactFlowByTab.edges;

				const allNodes = [...existingNodes, ...newNodes];
				const allEdges = [...existingEdges, ...newEdges];

				get().setFlowToTab(tabId, {
					nodes: allNodes,
					edges: allEdges,
				});
				pacEngineStore.getState().zoomInOnNodes({ nodes: newFlow });
			},
			onNodeStatusUpdate: (nodeStatus) => {
				console.log('editorMainStore/onNodeStatusUpdate');
				set(
					produce(
						/** @type {(state: import('./types').EditorStore)=> void} */
						(state) => {
							const id = nodeStatus.id;
							state.nodeStatus.byId[id] = nodeStatus;
							state.nodeStatus.allIds = uniq([
								...state.nodeStatus.allIds,
								id,
							]);
						}
					)
				);
			},
			removeNodeStatus: (id) => {
				set(
					produce(
						/** @type {(state: import('./types').EditorStore)=> void} */
						(state) => {
							if (!state.nodeStatus.byId?.[id]) {
								return;
							}

							delete state.nodeStatus.byId[id];
							state.nodeStatus.allIds = state.nodeStatus.allIds.filter(
								(nid) => nid !== id
							);
						}
					)
				);
			},
			onTempNodeStatusUpdate: (statusData) => {
				console.log('editorMainStore/onFakeNodeStatusUpdate', statusData);
				set(
					produce(
						/** @type {(state: import('./types').EditorStore)=> void} */
						(state) => {
							const nodeId = statusData.nodeId;

							let color, text;
							switch (statusData.status) {
								case 'running': {
									state.executingNodes =
										state.executingNodes.concat(nodeId);
									color = 'yellow';
									text = 'Processing';
									break;
								}
								case 'done': {
									state.executingNodes = state.executingNodes.filter(
										(nid) => nid !== nodeId
									);
									color = 'green';
									text = 'Done';
									break;
								}
								default: {
									color = 'red';
									text = 'Error';
								}
							}

							try {
								const steps = Object.values(
									pacEngineStore.getState().recipe
								);
								const lastStepId = steps[steps.length - 1].id;

								const flow = get().getMayaFlowByTab({
									tabId: get().tabs.activeTab,
								});
								const currentNode = flow.find((n) => n.id === nodeId);
								const currentStepId = currentNode?._step_id;

								// console.log('nenot current', currentStepId, 'last', lastStepId)
								if (currentStepId && currentStepId !== lastStepId) {
									pacEngineStore.setState({
										stepIdBeingRun: currentStepId,
									});
								} else if (
									currentStepId &&
									currentStepId === lastStepId
								) {
									const stepStillExecuting = flow
										.filter((n) => n._step_id === lastStepId)
										.some((n) => get().executingNodes.includes(n.id));

									if (stepStillExecuting && currentStepId) {
										pacEngineStore.setState({
											stepIdBeingRun: currentStepId,
										});
									} else {
										setTimeout(() => {
											pacEngineStore.setState({
												stepIdBeingRun: '',
											});
										}, 1000);
									}
								}
							} catch (e) {
								console.log('Unable to update stepIdBeingRun:', e);
							}

							if (
								state.nodeStatus.byId?.[nodeId] &&
								!state.nodeStatus.byId?.[nodeId]?.autoGenerated
							) {
								return;
							}

							state.nodeStatus.byId[nodeId] = {
								id: nodeId,
								msg: {
									text: text,
									shape: 'dot',
									fill: color,
								},
								autoGenerated: true,
							};

							state.nodeStatus.allIds = uniq([
								...state.nodeStatus.allIds,
								nodeId,
							]);

							if (statusData.status === 'done') {
								setTimeout(() => {
									get().removeNodeStatus(nodeId);
								}, 3000);
							}
						}
					)
				);
			},
		})
	)
);

/**
 * @type {import('zustand').UseBoundStore<import('./types').EditorStore>}
 */
export const useStore = create(editorStore);
