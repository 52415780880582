import React from 'react'
import {
    Flex
} from '@chakra-ui/react'

const DocumentSection = () => {
    return (
        <Flex>
            Documents
        </Flex>
    )
}

export default DocumentSection