import React from 'react';
import { Box } from '@chakra-ui/react';
import History from '../../../../../../util/History';
import { useLocation } from 'react-router-dom';
import { config } from '../../../../../../config';

const EntryPointHeadingLeft = () => {
	const [route, setRoute] = React.useState('workspaces');
	let location = useLocation();

	const { search } = location;

	React.useEffect(() => {
		setRoute(location.pathname.split('/')[1]);
	}, [location]);
	return (
		<Box
			height="50px"
			display="flex"
			flexDirection="row"
			alignItems="center"
			justifyContent="center"
		>
			<Box>
				<Box
					color={
						route === 'login'
							? 'light.font.gray.400'
							: 'light.font.gray.200'
					}
					textStyle="sans.sm"
					cursor="pointer"
					onClick={() => {
						History.push(`/login${search}`);
					}}
				>
					login
				</Box>
				{route === 'login' && (
					<Box
						height="2px"
						bg="light.theme.gray.400"
						width="14px"
						mt="-2px"
						borderRadius="1px"
					/>
				)}
			</Box>
			{!config.settings.waitlistMode && (
				<Box marginLeft="25px">
					<Box
						color={
							route === 'register'
								? 'light.font.gray.400'
								: 'light.font.gray.200'
						}
						textStyle="sans.sm"
						cursor="pointer"
						onClick={() => {
							History.push(`/register${search}`);
						}}
					>
						register
					</Box>
					{route === 'register' && (
						<Box
							height="2px"
							bg="light.theme.gray.400"
							width="14px"
							mt="-2px"
							borderRadius="1px"
						/>
					)}
				</Box>
			)}
		</Box>
	);
};

export default EntryPointHeadingLeft;
