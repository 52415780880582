import { v4 } from 'uuid';
import { config } from '../../config';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';
import axios from 'axios';

export const getSkills = async () => {
	const getSkillsFn = APIFunction({
		BASEURL: config.pacEngine.baseUrl,
		PATH_SEARCH: APIRoutes.apiService.getSkills.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.getSkills.PATH_METHOD,
	});

	try {
		const response = await getSkillsFn();
		return response.data['skill_list'];
	} catch (e) {
		console.error('Error fetching skills:', e);
		return [];
	}
};

export const deleteSkill = async (skillId) => {
	var formData = {
		skill_id: skillId,
	};
	const deleteSkillFn = APIFunction({
		BASEURL: config.pacEngine.baseUrl,
		PATH_SEARCH: APIRoutes.apiService.deleteSkill.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.deleteSkill.PATH_METHOD,
	});

	const response = await deleteSkillFn(formData);
	return response.data;
};

export const verifySkill = async ({ formData }) => {
	const request = {
		url:
			config.pacEngine.baseUrl +
			APIRoutes.apiService.verifySkill.PATH_SEARCH,
		method: APIRoutes.apiService.verifySkill.PATH_METHOD,
		data: formData,
		headers: {
			'content-type': 'multipart/form-data',
		},
	};

	// @ts-ignore
	const response = await axios(request);
	return response?.data?.reference;
};

export const getSkill = async (skillId) => {
	const getSkillFn = APIFunction({
		BASEURL: config.pacEngine.baseUrl,
		PATH_SEARCH: APIRoutes.apiService.getSkill.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.getSkill.PATH_METHOD,
		PATH_QUERY: skillId,
	});

	const response = await getSkillFn();
	if (response.data.status !== 'ok') {
		return null;
	}

	const { recipes, docs, steps } = response.data.details;

	/**
	 * @type {import('@mayahq/ui').RecipeData[]}
	 */
	const recipeData = [];
	if (recipes) {
		recipes.forEach((recipe) => {
			const recipeSpec = {
				id: recipe.id,
				public: recipe.public,
				description: recipe.description,
				samples: recipe.samples.map((sample) => ({
					id: v4(),
					public: true,
					content: `${sample.description}\n\n----\n\n${sample.recipe_sample}`,
				})),
			};
			recipeData.push(recipeSpec);
		});
	}

	const stepData = [];
	if (steps) {
		steps.forEach((step) => {
			const stepSpec = {
				id: step.id,
				public: step.public,
				description: step.step,
				samples: step.samples
					? step.samples.map((sample) => ({
							id: v4(),
							public: true,
							content: JSON.stringify(sample.nodes),
					  }))
					: [],
			};
			stepData.push(stepSpec);
		});
	}

	console.log('da docs', docs);
	const docData = [];
	if (docs) {
		docs.forEach((doc, idx) => {
			const docText = doc.text.replace('b"', '').replace(`b'`, '');
			let docDescription = docText;
			docDescription = docDescription.replace('----\\\n', '');

			const docSpec = {
				id: doc.id,
				public: true,
				profile: doc.profile,
				name: `Doc ${idx + 1}`,
				description: docDescription,
				content: docText,
			};
			docData.push(docSpec);
		});
	}

	return {
		recipes: recipeData,
		steps: stepData,
		docs: docData,
	};
};

export const updateSkill = async (skillId, updates) => {
	const updateSkillFn = APIFunction({
		BASEURL: config.pacEngine.baseUrl,
		PATH_SEARCH: APIRoutes.apiService.updateSkill.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.updateSkill.PATH_METHOD,
		PATH_QUERY: skillId,
	});

	const response = await updateSkillFn(updates);
	return response.data;
};
