import React from 'react';
import 'react-contexify/dist/ReactContexify.css';
import { BiPlay, BiPause, BiTrash } from 'react-icons/bi';

import {
	ContextMenu,
	ContextMenuItem,
	ContextMenuSeparator,
} from '../../../../../library/ContextMenu';

const SkillContextMenu = ({
	skillId,
	onDeleteClick,
	onShowClick,
	onRenameClick,
	onEditClick,
}) => {
	return (
		<ContextMenu id={skillId}>
			<ContextMenuItem label={'show'} onClick={onShowClick} />
			<ContextMenuItem label={'edit'} onClick={onEditClick} />
			<ContextMenuItem label={'rename'} onClick={onRenameClick} />
			<ContextMenuSeparator />
			<ContextMenuItem label={'delete'} onClick={onDeleteClick} />
		</ContextMenu>
	);
};

export default SkillContextMenu;
