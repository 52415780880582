import React from 'react';
import 'react-contexify/dist/ReactContexify.css';
import { BiPlay, BiPause, BiTrash } from 'react-icons/bi';

import {
	ContextMenu,
	ContextMenuItem,
	ContextMenuSeparator,
} from '../../../../../../library/ContextMenu';

const WorkspaceContextMenu = ({
	workspace,
	handleStartStopButtonClick,
	handleOpenWorkspaceClick,
	handleMoreDetailsClick,
	handleDeleteWorkspaceClick,
	handleRenameWorkspaceClick,
	handleResetWorkspaceClick
}) => {
	return (
		<ContextMenu id={workspace._id}>
			<ContextMenuItem
				label={workspace.status === 'STARTED' ? 'pause' : 'start'}
				icon={
					workspace.status === 'STARTED' ? (
						<BiPause color="#656161" />
					) : (
						<BiPlay color="#656161" />
					)
				}
				onClick={handleStartStopButtonClick}
			/>
			<ContextMenuItem label={'open'} onClick={handleOpenWorkspaceClick} />
			<ContextMenuItem label={'rename'} onClick={handleRenameWorkspaceClick} />
			<ContextMenuSeparator />
			<ContextMenuItem
				label={'delete'}
				onClick={handleDeleteWorkspaceClick}
			/>
			<ContextMenuItem
				label='reset'
				onClick={handleResetWorkspaceClick}
			/>
			<ContextMenuSeparator />
			<ContextMenuItem
				label={'more info'}
				onClick={handleMoreDetailsClick}
			/>
		</ContextMenu>
	);
};

export default WorkspaceContextMenu;
