import createVanilla from 'zustand/vanilla';
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { editorStore } from '../../../zustand';
import { calculateFocusArea } from '../../GenerateDAG/utils';
import { splitIntoThree } from '../../../Workspace/functions/Misc';
import produce from 'immer';
import createHook from 'zustand';
import { calculateWaitTimes, overwriteTabProperty } from '../utils';
import { cloneDeep, findKey, findLastKey, flow } from 'lodash';
import { parseMayaLangToReactFlow } from '../../../Workspace/functions/FlowMethods';
import { createStandaloneToast } from '@chakra-ui/react';
import theme from '../../../../../library/theme';

/**
 * Immer zustand middleware
 * @param config
 * @returns
 */
export const immer = (config) => (set, get, api) =>
	config(
		(partial, replace) => {
			const nextState =
				typeof partial === 'function' ? produce(partial) : partial;
			return set(nextState, replace);
		},
		get,
		api
	);

const middleware = flow([
	devtools,
	immer,
	(fn) =>
		persist(fn, {
			name: 'pacEngineStore',
			partialize: (state) => ({
				// @ts-ignore
				completePromptHistory: state.completePromptHistory,
				// @ts-ignore
				actionTakenOnceFromEditor: state.actionTakenOnceFromEditor,
				// @ts-ignore
				skillBeingEdited: state.skillBeingEdited,
			}),
		}),
]);

/**
 * @type {import('zustand').StoreApi<import('./types').ZustandPacEngineStore>}
 */
export const pacEngineStore = createVanilla(
	middleware(
		/**
		 * @param {import ('zustand').SetState<import('./types').ZustandPacEngineStore>} set
		 * @param {import ('zustand').GetState<import('./types').ZustandPacEngineStore>} get
		 * @returns {import('./types').ZustandPacEngineStore}
		 */
		(set, get) => ({
			recipe: {},
			serverRecipe: {},
			oldRecipe: {},
			serverFlow: {},
			isGenerateLoading: false,
			instructInProgress: false,
			stepIdBeingGenerated: '',
			stepIdBeingRun: '',
			completePromptHistory: {},
			userAction: 'recipe.edit.instruct',
			feedbackBoxVisible: false,
			instructGenerationId: '',
			actionTakenOnceFromEditor: {},
			skillBeingEdited: {},
			stepIdInFocus: '',
			setIsGenerateLoading: (isGenerateLoading) => {
				console.log('pacEngineStore/setIsGenerateLoading');
				set((state) => {
					state.isGenerateLoading = isGenerateLoading;
				});
			},
			setStepIdBeingGenerated: (stepId) => {
				console.log('pacEngineStore/setStepIdBeingGenerated');
				set((state) => {
					state.stepIdBeingGenerated = stepId;
				});
			},
			setRecipe: async ({ newRecipe, diff, renderIncrementally = true }) => {
				console.log('pacEngineStore/setRecipe');
				if (!renderIncrementally) {
					set((state) => {
						state.recipe = newRecipe;
					});
					return;
				}
				if (
					Object.keys(get().recipe).length === 1 &&
					get().recipe[Object.keys(get().recipe)[0]].text === '...'
				) {
					set((state) => {
						state.recipe = {};
					});
				}
				const oldRecipe = JSON.stringify(get().recipe);
				set((state) => {
					state.recipe = {};
				});
				const allIds = Object.keys(newRecipe);
				const stepsToRenderIncrementally = {};
				for (let i = 0; i < allIds.length; i++) {
					const stepId = allIds[i];
					const step = newRecipe[stepId];
					const stepWithEmptyText = { ...newRecipe[stepId], text: '' };

					const addedSteps = diff?.steps.added.map((s) => s.id);
					const changedSteps = diff?.steps.changed.map((s) => s.id);
					const stepAddedOrChanged =
						addedSteps?.includes(stepId) ||
						changedSteps?.includes(stepId);
					// Creating a step with empty text. This shows the step on the screen with the prefix
					set((state) => {
						state.recipe = {
							...state.recipe,
							[newRecipe[stepId].id]: stepWithEmptyText,
						};
					});
					const oldPrefix = JSON.parse(oldRecipe)[stepId]?.prefix;
					// Incrementally populating the text to the steps if stepId is in the changed or added diff. Else populating it immediately
					if (stepAddedOrChanged) {
						const updatedStep = {
							...stepWithEmptyText,
							text: '',
							prefix: oldPrefix
								? oldPrefix
								: step.prefix === '1.'
								? '1.'
								: '',
							backupText: step.text,
							backupPrefix: step.prefix,
							generated: oldPrefix
								? false
								: step.prefix === '1.'
								? false
								: true,
						};
						stepsToRenderIncrementally[stepId] = updatedStep;
						get().updateStep({ stepId, updatedStep });
					} else {
						get().updateStep({ stepId, updatedStep: step });
					}
				}

				const totalStepsToRender = Object.keys(
					stepsToRenderIncrementally
				).length;

				/**
				 * Each iteration of the nested for loop below 5 times longer to execute compare to the nested for loop in `incrementallyPopulateRecipePreview` in the `InstructBar` component. Thi s is because the former is working with objects which involves calling the `structuredClone` function which is a heavy operation. The latter works with strings, doesn't involve any heavy operation and is fast.
				 *
				 * The stepWaitTime and subStepWaitTimes values here ensure that each iteration below completes in around 400ms which is roughly the time taken by the loop in `incrementallyPopulateRecipePreview`.
				 */

				const stepWaitTime = totalStepsToRender > 10 ? 0 : 100;
				const subStepWaitTime = totalStepsToRender > 10 ? 0 : 33;

				for (let j = 0; j < totalStepsToRender; j++) {
					await new Promise((r) => setTimeout(r, stepWaitTime));
					const stepId = Object.keys(stepsToRenderIncrementally)[j];
					const step = stepsToRenderIncrementally[stepId];
					const splitText = splitIntoThree(step.backupText);
					for (let k = 0; k < splitText.length; k++) {
						const latestRecipe = get().recipe;
						await new Promise((r) => setTimeout(r, subStepWaitTime));
						const text = `${latestRecipe[stepId]?.text}${splitText[k]}`;
						const updatedStep = {
							...step,
							text,
							prefix: step.backupPrefix,
							generated: false,
						};
						stepsToRenderIncrementally[stepId] = step;
						const updatedRecipe = structuredClone(latestRecipe);
						updatedRecipe[stepId] = updatedStep;
						pacEngineStore.setState({ recipe: updatedRecipe });
					}
				}

				if (!editorStore.getState().dirty) {
					editorStore.getState().setDirty(true);
				}
			},
			setOldRecipe: ({ recipe }) => {
				set((state) => {
					state.oldRecipe = recipe;
				});
			},
			setServerRecipe: ({ newRecipe }) => {
				console.log('pacEngineStore/setServerRecipe');
				set((state) => {
					state.serverRecipe = newRecipe;
				});
			},
			setServerFlow: ({ newFlow }) => {
				console.log('pacEngineStore/setServerFlow');
				set((state) => {
					state.serverFlow = newFlow;
				});
			},
			updateStep: ({ stepId, updatedStep }) => {
				console.log('pacEngineStore/updateStep');
				set((state) => {
					state.recipe[stepId] = updatedStep;
				});
				if (!editorStore.getState().dirty) {
					editorStore.getState().setDirty(true);
				}
			},
			onReceivingGenerateMessage: ({
				message,
				tabId,
				stepIdToFocusOn,
				fromLoader = false,
			}) => {
				console.log('pacEngineStore/onReceivingGenerateMessage');
				const mayaFlow = message.stitched_flow;
				const allSteps = message.steps;
				if (!mayaFlow || !allSteps) {
					get().setIsGenerateLoading(false);
					const toast = createStandaloneToast({ theme: theme });
					toast({
						title: 'Request Logged!',
						description: `We ran into some issue during generation. Please try again after some time`,
						status: 'error',
						duration: 3000,
						isClosable: true,
					});
					return;
				}
				const modifiedFlow = overwriteTabProperty({
					mayaFlow,
					tabId,
				});
				get().setRecipe({
					newRecipe: allSteps,
					diff: message.diff,
					renderIncrementally: false,
				});
				get().setServerRecipe({ newRecipe: allSteps });
				get().setServerFlow({ newFlow: modifiedFlow });
				// If step is not generated and does not have an error, return it
				const firstNonGeneratedStepId = findKey(
					get().recipe,
					(step) => !step.generated && !step?.error
				);
				set((state) => {
					state.stepIdBeingGenerated = firstNonGeneratedStepId;
				});

				const { nodes, edges } = parseMayaLangToReactFlow(
					modifiedFlow,
					1,
					'transient'
				);

				// There is no node-red on the loading screen, no tab to set the flow to.
				if (!fromLoader) {
					editorStore.getState().setFlowToTab(tabId, {
						nodes,
						edges,
					});
				}
				const lastGeneratedStepId = findLastKey(allSteps, (step) => {
					// If step is not generated but has an error, return it
					// Else return the last generated step
					if (!step.generated && step?.error) {
						return true;
					} else if (step.generated) {
						return true;
					}
				});
				const flowToFocusOn = mayaFlow.filter((node) => {
					const stepIdToMatch = stepIdToFocusOn
						? stepIdToFocusOn
						: lastGeneratedStepId;
					return node._step_id === stepIdToMatch;
				});
				get().zoomInOnNodes({ nodes: flowToFocusOn });
			},
			zoomInOnNodes: ({ nodes }) => {
				console.log('pacEngineStore/zoomInOnNodes');
				const { x, y } = calculateFocusArea(nodes);
				const nodeIds = nodes.map((n) => n.id);
				editorStore.setState({ focusedStepNodeIds: nodeIds });
				if (nodes.length > 0 && Number.isNaN(y) === false) {
					editorStore.getState().onCommandFocus({
						shouldCenter: true,
						x,
						y,
						zoom: 1,
					});
				}
			},
			setCompletePromptHistory: ({ profile, profilePromptHistory }) => {
				console.log('pacEngineStore/setCompletePromptHistory');
				set((state) => {
					state.completePromptHistory = {
						...state.completePromptHistory,
						[profile]: profilePromptHistory,
					};
				});
			},
			setUserAction: (value) => {
				set((state) => {
					state.userAction = value;
				});
			},
			setFeedbackBoxVisibility: (value) => {
				set((state) => {
					state.feedbackBoxVisible = value;
				});
			},
			setInstructGenerationId: (generationId) => {
				set((state) => {
					state.instructGenerationId = generationId;
				});
			},
			setActionTakenOnceFromEditor: ({ profile, workerId, value }) => {
				set((state) => {
					state.actionTakenOnceFromEditor = {
						...state.actionTakenOnceFromEditor,
						[profile]: {
							...state.actionTakenOnceFromEditor[profile],
							[workerId]: value,
						},
					};
				});
			},
			setStepIdBeingRun: (stepId) => {
				set((state) => {
					state.stepIdBeingRun = stepId;
				});
			},
			setStepIdInFocus: (stepIdInFocus) => {
				set((state) => {
					state.stepIdInFocus = stepIdInFocus;
				});
			},
		})
	)
);

/**
 * @type {import('zustand').UseBoundStore<import('./types').ZustandPacEngineStore>}
 */
export const usePacEngineStore = createHook(pacEngineStore);
