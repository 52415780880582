import React from 'react'
import buttonDots from '../assets/buttonDots.svg';
import {
    Box,
    Accordion,
    AccordionItem,
    AccordionButton as ChakraAccordionButton,
    AccordionPanel,
    AccordionIcon,
    Img
} from '@chakra-ui/react'


const AccordionButton = (props) => {
    const { children } = props
    return (
        <ChakraAccordionButton
            position='relative'
            bg='#fff'
            border='1px solid'
            borderColor='light.border.gray.100'
            height='50px'
            
            _expanded={{
                bg: '#4B4B4B',
                color: '#fff'
            }}
            _focus={{
                outline: 'none'
            }}
            _hover={{
                bg: 'light.theme.gray.200'
            }}
            {...props}
        >
            <Img
                src={buttonDots}
                transform='scaleX(-1)'
                position='absolute'
                right='0'
                top='0'
                mt='3px' mr='2px'
            />
            <AccordionIcon />
            <Box
                ml='8px'
                textStyle='sans.sm'
            >
                {children}
            </Box>
        </ChakraAccordionButton>
    )
}

export default AccordionButton