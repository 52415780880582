import React from 'react';
import { Box } from '@chakra-ui/react';

const SectionHeader = ({
	heading = 'This is the heading',
	subheading = 'This is the subheading',
}) => {
	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<Box textStyle="serif.lg">{heading}</Box>
			<Box textStyle="sans.sm" color="light.font.gray.400" mt="5px" textAlign='center'>
				{subheading}
			</Box>
		</Box>
	);
};

export default SectionHeader;
