import createVanilla from 'zustand/vanilla'
import { devtools } from 'zustand/middleware'
import createHook from 'zustand'
import { flow } from 'lodash'
import produce from 'immer';

/**
 * Immer zustand middleware
 * @param config
 * @returns
 */
export const immer = (config) => (set, get, api) =>
	config(
		(partial, replace) => {
			const nextState =
				typeof partial === 'function' ? produce(partial) : partial;
			return set(nextState, replace);
		},
		get,
		api
	);

const middleware = flow([
    devtools,
    immer
])

/**
 * @type {import('zustand').StoreApi<import('./types').LibraryStore>}
 */
export const libraryStore = createVanilla(
    middleware(
        /**
		 * @param {import ('zustand').SetState<import('./types').LibraryStore>} set
		 * @param {import ('zustand').GetState<import('./types').LibraryStore>} get
		 * @returns {import('./types').LibraryStore}
		 */
        (set, get) => ({
            skills: [],
            loading: false,
            selectedSkill: '',

            setSkills: (skills) => {
                set(
                    produce(
                        state => {
                            state.skills = skills
                        }
                    )
                )
            },

            addSkill: (skill) => {
                set(
                    produce(
                        state => {
                            state.skills = state.skills.concat(skill)
                        }
                    )
                )
            },

            setSelectedSkill: (skillId) => {
                set(
                    produce(
                        state => {
                            state.selectedSkill = skillId
                        }
                    )
                )
            }
        })
    )
)

/**
 * @type {import('zustand').UseBoundStore<import('./types').LibraryStore>}
 */
export const useLibraryStore = createHook(libraryStore)