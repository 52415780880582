import { useSelector } from 'react-redux'
import useProfileSlug from './useProfileSlug'

const useSelectedProfile = () => {
    const profileSlug = useProfileSlug()
    const profiles = useSelector(
        /**
         * 
         * @param {import('../redux/reducers/types/profileReducer').ProfilesState} state 
         * @returns 
         */
        state => state.profiles.profiles
    )

    return profiles?.[profileSlug]
}

export default useSelectedProfile