import React from 'react';
import {
	Input,
	InputElementProps,
	InputGroup,
	InputGroupProps,
	InputLeftElement,
	InputProps,
} from '@chakra-ui/react';

export interface MayaInputProps {
	icon: React.ReactNode;
	name: InputProps['name'];
	type: InputProps['type'];
	placeholder: InputProps['placeholder'];
	value: string;
	onChange: InputProps['onChange'];
	onIconClick: InputElementProps['onClick'];
	/**whether to allow pointer events or not */
	iconPointerEvents: InputElementProps['pointerEvents'];
	onKeyDown: InputProps['onKeyDown'];
	onKeyUp: InputProps['onKeyUp'];
	isInvalid: boolean;
	isDisabled: InputProps['isDisabled'];
	inputProps?: InputProps;
	inputGroupProps?: InputGroupProps;
}

/**
 * Standard input element in Maya styles.
 */
export const MayaInput = ({
	name,
	icon,
	type,
	placeholder,
	value,
	onChange,
	onIconClick,
	iconPointerEvents,
	onKeyDown,
	onKeyUp,
	isInvalid,
	isDisabled,
	inputProps,
	inputGroupProps,
}: MayaInputProps) => {
	return (
		<InputGroup {...inputGroupProps}>
			{icon && (
				<InputLeftElement
					onClick={onIconClick}
					pointerEvents={iconPointerEvents || 'none'}
				>
					{icon}
				</InputLeftElement>
			)}
			<Input
				type={type}
				name={name}
				placeholder={placeholder}
				bg="white"
				width="100%"
				borderRadius="2px"
				border="solid 1px"
				borderColor={isInvalid ? 'red.500' : 'light.border.gray.200'}
				_focus={{
					border: 'solid 1px',
					borderColor: 'light.border.gray.300',
				}}
				_hover={{
					border: 'solid 1px',
					borderColor: isInvalid ? 'red.500' : 'light.border.gray.300',
				}}
				textStyle="sans.xs"
				color="light.font.gray.400"
				_placeholder={{
					color: 'light.font.gray.200',
				}}
				value={value}
				onChange={onChange}
				onKeyDown={onKeyDown}
				onKeyUp={onKeyUp}
				isDisabled={isDisabled}
				{...inputProps}
			/>
		</InputGroup>
	);
};
