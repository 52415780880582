import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';

// config
import { config } from '../../config';

//redux
import { store } from '../../redux/store';
/**
 * Function to Create a New Workspace, given some parameters
 */
const deleteBrain = async ({ brainId, device, slug }) => {
	var { user } = store.getState();
	var profileSlug = slug ? slug : user.info.id;
	var deleteBrainFn;
	if (device.platform === 'cloud') {
		// API CALL
		deleteBrainFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.deleteBrain.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.deleteBrain.PATH_METHOD,
			PATH_QUERY: brainId,
			URL_PARAM: {
				profileSlug: profileSlug,
			},
		});
	} else if (isElectron()) {
		// IPC CALL
		deleteBrainFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.deleteBrain.EVENT,
			PATH_QUERY: brainId,
			URL_PARAM: {
				profileSlug: profileSlug,
			},
		});
	} else
		console.error(
			`local device cannot be accessed on the browser! Please use desktop app`
		);
	/**
	 * @type {import('./types/deleteBrain').DeleteBrainResponse}
	 */
	const deleteBrainResponse = await deleteBrainFn();
	if (deleteBrainResponse['data']['error']) {
		return deleteBrainResponse['data'];
	} else {
		deleteBrainResponse['data']['results']['_id'] = brainId;
		return deleteBrainResponse['data']['results'];
	}
};

export default deleteBrain;
