import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import {
    Flex, Box
} from '@chakra-ui/react'
import useQueryParams from '../../../../util/useQueryParams'
import MayaSkeleton from '../../../../library/skeleton'
import { getLongestTimeElapsedString } from '../../../../util/time'

const modes = {
    NORMAL: 'normal',
    INSTALL_FROM_QUICK_SHARE: 'installFromQuickShare'
}

export const FlowPreview = ({
    setInLocalStorage = true
}) => {
    const queryParams = useQueryParams()
    const [mode, setMode] = useState(modes.NORMAL)
    const [modeMetadata, setModeMetadata] = useState({})
    const [flowDetailsLoading, setFlowDetailsLoading] = useState(true)

    const getFlowInfoFromFlowJson = useCallback((flow) => {
		const botInfoNode = flow.find(node => node.type === 'bot-info')
		const { created, title } = botInfoNode
		const elapsed = getLongestTimeElapsedString(created)
		return { created, title, elapsed }
	}, [])

    useEffect(() => {
        const mode = queryParams.get('mode') || modes.NORMAL
        setMode(mode)

        if (mode !== modes.INSTALL_FROM_QUICK_SHARE) {
            return
        }

        setFlowDetailsLoading(true)
        const link = queryParams.get('link')
        axios.get(link)
            .then((res) => {
                console.log('linkres', res.data)
                let data = res.data
                if (typeof res.data === 'string') {
                    data = JSON.parse(res.data)
                } else if (typeof res.data === 'object') {
                    data = res.data
                }
                setModeMetadata({
                    mode: modes.INSTALL_FROM_QUICK_SHARE,
                    data: {
                        installType: 'installFromQuickShare',
                        flow: data,
                        link: link
                    }
                })
                setFlowDetailsLoading(false)
            })
    }, [])

    useEffect(() => {
        if (setInLocalStorage && modeMetadata?.mode) {
            localStorage.setItem('signupMetadata', JSON.stringify({
                ...modeMetadata,
                timestamp: Date.now()
            }))
        }
    }, [modeMetadata])

    if (mode === modes.INSTALL_FROM_QUICK_SHARE) {
        const flow = modeMetadata?.data?.flow
        let flowDetails = {}
        if (!!flow) {
            flowDetails = getFlowInfoFromFlowJson(flow)
        }
    
        return (
            <Box
                border='1px solid'
                borderColor='light.border.gray.200'
                transform='translateY(-12px)' // Margin not working
                borderRadius='2px'
            >
                <Flex
                    textStyle='sans.sm'
                    paddingX='20px'
                    bg='light.theme.yellow.200'
                    color='light.font.gray.400'
                    height='48px'
                    alignItems='center'
                >
                    {flowDetailsLoading ? (
                        <MayaSkeleton width='100px' height='17px' />
                    ) : null}
                    {!flowDetailsLoading ? flowDetails.title : null}
                </Flex>
                <Flex
                    textStyle='sans.xs'
                    paddingX='20px'
                    bg='#ffffff'
                    color='light.font.gray.300'
                    height='32px'
                    alignItems='center'
                >
                    {flowDetailsLoading ? (
                        <MayaSkeleton width='100px' height='17px' />
                    ) : null}
                    {!flowDetailsLoading ? `Created ${flowDetails.elapsed} ago` : null}
                </Flex>
            </Box>
        )
    }

    return null
}

export const entryModes = {
	NORMAL: 'normal',
	INSTALL_FROM_QUICK_SHARE: 'installFromQuickShare'
}