import { config } from '../../config';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

const stepSearch = async (query) => {
	const stepSearchFn = APIFunction({
		BASEURL: config.pacEngine.baseUrl,
		PATH_SEARCH: `${APIRoutes.apiService.stepSearch.PATH_SEARCH}?q=${query}`,
		PATH_METHOD: APIRoutes.apiService.stepSearch.PATH_METHOD,
	});

	const response = await stepSearchFn();
	return response;
};

export default stepSearch;
