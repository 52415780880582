import React from 'react';
import {
	Flex,
	Box,
	GridItem,
	Center,
    Skeleton,
} from '@chakra-ui/react';
import { GoKebabVertical } from 'react-icons/go';
import { MayaSkeleton } from '@mayahq/ui';


const selectedBorderColor = '#925392';

const CountSkeleton = ({ curveBottom = false, type }) => {
	return (
		<Flex alignItems="center">
			<Center
				padding="8px"
				borderBottomRadius={curveBottom ? '3px' : undefined}
				bg="light.theme.gray.200"
				width="32px"
				height="32px"
			>
				
			</Center>
			<Flex ml="8px" textStyle="sans.sm" color="light.font.gray.300">
                    <MayaSkeleton
                        w={`${type.length*15}px`}
                        h='20px'
                        borderRadius='2px'
                    />
			</Flex>
		</Flex>
	);
};

const SkillCardSkeleton = () => {
	return (
		<>
			<GridItem
				width="100%"
			>
				<Box // This exists just so I can increase the 'border width' on selection of a workspace card, without making content jump
					padding="1px"
					minHeight={'10px'}
					borderRadius="2px"
					cursor="pointer"
					transition="all 0.1s cubic-bezier(.08,.52,.52,1)"
				>
					<Flex
						direction="column"
						height="220px"
						border="1px solid"
						borderColor='light.border.gray.100'
						bg="light.theme.gray.50"
						borderRadius="2px"
						overflow="hidden"
						position="relative"
					>
						<Box
							flex="1 1 auto"
							display="flex"
							flexDirection="column"
							bg="white"
							height="200px"
							overflow="hidden"
							width="100%"
							px="8px"
						>
							<CountSkeleton type='recipes' />
							<CountSkeleton type='steps' />
							<CountSkeleton type='documents' curveBottom />
						</Box>
						<Box
							textStyle="sans.sm"
							padding="12px 16px 10px 16px"
							borderTop="1.25px solid"
							borderColor="light.border.gray.200"
							display="flex"
							justifyContent="space-between"
							color="light.font.gray.400"
						>
							<Box
								width="80%"
								display="flex"
								alignItems="center"
							>
								<Flex alignItems="center" width='100%'>
                                    <Box
                                        whiteSpace="nowrap"
                                        overflow="hidden"
                                        textOverflow="ellipsis"
                                        width='100%'
                                    >
                                        <MayaSkeleton
                                            w='100%'
                                            h='20px'
                                            borderRadius='2px'
                                        />
                                    </Box>
								</Flex>
								<Flex mt="8px" userSelect="none">
									{/* <Tag
                                            bg="light.theme.gray.200"
                                            label={`${getLongestTimeElapsedString(
                                                workspace.updatedAt
                                            )} ago`}
                                            icon={<FiClock />}
                                            size="sm"
                                            ml="12px"
                                        /> */}
								</Flex>
							</Box>
							<Flex alignItems="center">
								<Box
									transition="all 0.1s ease"
									borderRadius="2px"
									cursor="pointer"
									padding="5px"
									_hover={{
										backgroundColor: '#F3F3F3',
									}}
								>
									<GoKebabVertical color="#656161" />
								</Box>
							</Flex>
						</Box>
					</Flex>
				</Box>
			</GridItem>
		</>
	);
};

export default SkillCardSkeleton
