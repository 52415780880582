import React, { useEffect } from 'react'
import {
    Flex, Box,
    LightMode,
    Button
} from '@chakra-ui/react'
import SettingsItem from '../SettingsItem'
import useStepperStore from '../../../../Stepper/StepperStore';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

const Team = () => {
    const { addStep, openStepper } = useStepperStore((state) => {
		return {
			addStep: state.addStep,
			nextStep: state.nextStep,
			openStepper: state.openStepper,
		};
	});

    const selectedProfile = useSelector(
		/**
		 *
		 * @param {import("../../../../../redux/reducers/types/profileReducer").ProfilesState} state
		 * @returns
		 */
		(state) => state.profiles.profiles[state.profiles.selected]
	);

    function onEditButtonClick() {
        addStep('settings.editTeam')
        openStepper()
    }

    if (!selectedProfile.isTeam) {
        return <Redirect to='/settings/general' />
    }

    
    return (
        <Box>
            <SettingsItem
                text={'Edit team'}
                description={'Invite, remove or change permissions for team members'}
                rightItem={
                    <LightMode>
                        <Button
                            colorScheme="gray"
                            size="xs"
                            marginRight="1rem"
                            onClick={onEditButtonClick}
                            textStyle="sans.xs"
                        >
                            Edit
                        </Button>
                    </LightMode>
                }
            />
        </Box>
    )
}

export default Team