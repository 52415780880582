import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import { Flex, Box } from '@chakra-ui/react';
import useProfileSlug from '../../../../hooks/useProfileSlug';
import getConfigProfiles from '../../../../functions/configuration/getConfigProfiles';
import ConfigProfileList from '../../../Dashboard/Sections/Store/ConfigProfile/ConfigProfileList';
import { MayaFilledButton } from '@mayahq/ui';

const ConfigProfileFlow = ({
	selectConfigProfile = (modulePackageName, referenceId) => null,
	onDone = () => null,
	moduleVals,
	configIndex,
	setConfigIndex,
}) => {
	const profileSlug = useProfileSlug();
	const [configProfiles, setConfigProfiles] = useState([]);
	const [configProfilesLoading, setConfigProfilesLoading] = useState(false);
	const [selectedProfiles, setSelectedProfiles] = useState({});

	useEffect(() => {
		const module = moduleVals[configIndex];
		setConfigProfilesLoading(true);
		getConfigProfiles({
			slug: profileSlug,
			moduleId: module.id,
			moduleName: module.packageName,
			brainId: '',
		})
			.then((res) => {
				console.log('cfps', res);
				setConfigProfilesLoading(false);
				setConfigProfiles(res);
			})
			.catch((e) => console.error('error fetching config profiles:', e));
	}, [moduleVals[configIndex]]);

	function handleContinue() {
		setConfigIndex((prev) => {
			if (prev === moduleVals.length - 1) {
				onDone();
				return prev;
			} else {
				return prev + 1;
			}
		});
	}

	function handleProfileSelect(profile) {
		selectConfigProfile(
			moduleVals[configIndex].packageName,
			profile?.referenceId
		);
		console.log('selected cfp', profile);
		setSelectedProfiles(
			update(selectedProfiles, {
				[moduleVals[configIndex].id]: { $set: { ...profile } },
			})
		);
	}

	if (configIndex < moduleVals.length) {
		return (
			<Flex height="100%" direction="column">
				<Box
					display="flex"
					height="100%"
					position="relative"
					overflowY="auto"
					overflowX="hidden"
				>
					<Box
						width="100%"
						display="flex"
						flexDirection="column"
						position="absolute"
					>
						<ConfigProfileList
							configProfiles={configProfiles}
							module={moduleVals[configIndex]}
							selectedProfile={
								selectedProfiles[moduleVals[configIndex].id]
							}
							onSelectProfile={handleProfileSelect}
							selectMode={true}
							newProfileButtonText="+ Login and Create New"
							loading={configProfilesLoading}
							// loading={true}
						/>
					</Box>
				</Box>
				<Box mt="32px">
					<MayaFilledButton
						// text={isLastProfile ? 'Finish' : 'Continue'}
						text="Next"
						colorScheme="purple"
						size="sm"
						buttonProps={{ width: '100%' }}
						onClick={handleContinue}
						isDisabled={!selectedProfiles[moduleVals[configIndex]?.id]}
					/>
				</Box>
			</Flex>
		);
	}

	return null;
};

export default ConfigProfileFlow;
