// @ts-nocheck
import {
	Box,
	CircularProgress,
	createStandaloneToast,
	Flex,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	Text,
	Tooltip,
	useMediaQuery,
} from '@chakra-ui/react';
import { MayaFilledButton } from '@mayahq/ui';
import { useHistory, useLocation } from 'react-router-dom';
import isElectron from 'is-electron';
import { findKey, truncate } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import shallow from 'zustand/shallow';
import changeSession from '../../../../../functions/pac-engine/changeSession';
import generate, {
	listenToGenerateResult,
} from '../../../../../functions/pac-engine/generate';
import getSession from '../../../../../functions/pac-engine/getSession';
import theme from '../../../../../library/theme';
import useAnalytics from '../../../../../util/Analytics/useAnalytics';
import useQueryParams from '../../../../../util/useQueryParams';
import useInstallMissingModules from '../../../hooks/useInstallMissingModules';
import { parseMayaLangToReactFlow } from '../../../Workspace/functions/FlowMethods';
import {
	offNodeExecStatusNotification,
	onNodeExecStatusNotification,
} from '../../../Workspace/functions/redEventListeners';
import InterstitialModuleInstallDrawer from '../../../Workspace/InterstitialModuleInstallDrawer';
import { useStore } from '../../../zustand';
import FeedbackBox from '../../GenerateDAG/FeedbackBox';
import {
	createRecipeWithEmptyStep,
	generateUniqueNodeIds,
} from '../../GenerateDAG/utils';
import Step from '../Step';
import {
	compareFlows,
	compareRecipes,
	diffExists,
	nonEmptyStepCount,
	overwriteTabProperty,
} from '../utils';
import { pacEngineStore, usePacEngineStore } from '../zustand';
import styles from './synthesisSidebar.module.css';
import SynthSidebarHeader from './SynthesisSidebarHeader';
import { v4 } from 'uuid';
import getRedInstance from '../../../Workspace/functions/getRedInstance';
import getStoreModules from '../../../../../functions/store/getStoreModules';
import useInstallModules from '../../../hooks/useInstallModules';
import { instructBarStore } from '../InstructBar/zustand';

const toast = createStandaloneToast({ theme: theme });

const SynthesisSidebar = ({
	sessionId,
	allowInteraction = true,
	showGenerateProgress: inLoader = false,
}) => {
	const selectedProfile = useSelector((state) => {
		return state.profiles.profiles[state.profiles.selected];
	});
	const [errorGettingSession, setErrorGettingSession] = useState(false);

	const suggestionRefs = useRef([]);
	const query = useQueryParams();
	suggestionRefs.current = [];
	const history = useHistory();
	const location = useLocation();

	const track = useAnalytics();

	const stepRefs = useRef({});
	stepRefs.current = {};

	// const { installMissingModules, modulesInstalling, modulesToInstall } =
	// 	useInstallMissingModules();

	const { modulesToInstall, modulesInstalling, installModules } =
		useInstallModules();

	const isModuleInstallationDrawerVisible = useStore(
		(state) => state.isModuleInstallationDrawerVisible
	);

	const setIsModuleInstallationDrawerVisible = useStore(
		(state) => state.setIsModuleInstallationDrawerVisible
	);

	// const doubleClickedNode = useStore(state => state.doubleClickedNode)
	const tabId = useStore((state) => state.tabs.activeTab);
	const currentTabLabel = useStore(
		(state) => state.tabs.byId[state.tabs.activeTab]?.label || 'Flow 1',
		shallow
	);
	const missingModules = useStore((state) => state.missingModules, shallow);
	const setFlowToTab = useStore((state) => state.setFlowToTab);

	const mayaFlow = useStore(
		(state) => state.tabs.byId[state.tabs.activeTab]?.mayaFlowByTab
	);

	// *** START OF ZUSTAND IMPORTS ***

	// *** END OF ZUSTAND IMPORTS ***

	const getMayaFlowByTab = useStore(
		useCallback((state) => state.getMayaFlowByTab, [])
	);
	const workerId = useStore(useCallback((state) => state.brainId, []));
	const onTempNodeStatusUpdate = useStore(
		(state) => state.onTempNodeStatusUpdate
	);
	const onDeploy = useStore((state) => state.onDeploy);

	// Start pacEngine Zustand imports

	const recipe = usePacEngineStore((state) => state.recipe);
	const serverRecipe = usePacEngineStore((state) => state.serverRecipe);
	const serverFlow = usePacEngineStore((state) => state.serverFlow);
	const isGenerateLoading = usePacEngineStore(
		(state) => state.isGenerateLoading
	);
	const setRecipe = usePacEngineStore((state) => state.setRecipe);
	const setServerRecipe = usePacEngineStore((state) => state.setServerRecipe);
	const setServerFlow = usePacEngineStore((state) => state.setServerFlow);
	const setIsGenerateLoading = usePacEngineStore(
		(state) => state.setIsGenerateLoading
	);
	const setStepIdBeingGenerated = usePacEngineStore(
		(state) => state.setStepIdBeingGenerated
	);
	const onReceivingGenerateMessage = usePacEngineStore(
		(state) => state.onReceivingGenerateMessage
	);
	const zoomInOnNodes = usePacEngineStore((state) => state.zoomInOnNodes);
	const actionTakenOnceFromEditor = usePacEngineStore(
		(state) => state.actionTakenOnceFromEditor
	);
	const setActionTakenOnceFromEditor = usePacEngineStore(
		(state) => state.setActionTakenOnceFromEditor
	);
	const onPasteFlow = useStore((state) => state.onPasteFlow);
	const onPasteSubflow = useStore((state) => state.onPasteSubflow);

	const generateTaskId = query.get('generateTaskId');
	// End pacEngine Zustand imports

	useEffect(() => {
		const getSessionHandler = async () => {
			try {
				const res = await getSession({ sessionId });
				const serverRecipe = res?.data.response.steps;
				let recipe;
				if (Object.keys(serverRecipe).length === 0) {
					recipe = createRecipeWithEmptyStep();
				} else {
					recipe = serverRecipe;
				}
				const mayaFlow = res?.data.response.stitched_flow;
				const modifiedFlow = overwriteTabProperty({ mayaFlow, tabId });
				setRecipe({
					newRecipe: recipe,
					diff: res.data.response.diff,
					renderIncrementally: false,
				});
				setServerRecipe({ newRecipe: serverRecipe });
				setServerFlow({ newFlow: modifiedFlow });

				// If nodes don't exist in Node-RED then using the Session to set the flow. `getMayaFlowByTab` is proxy for checking if nodes exist in Node-RED because MayaFlow of a tab is created by referring to Node-RED
				const existingMayaFlow = getMayaFlowByTab({ tabId });
				if (existingMayaFlow.length === 0) {
					const { nodes, edges } = parseMayaLangToReactFlow(
						modifiedFlow,
						1,
						'transient'
					);

					if (!inLoader) {
						setFlowToTab(tabId, {
							nodes,
							edges,
						});
					}
				}
				// Setting the focus on the first step
				const firstStepId = Object.keys(recipe).find((step) => {
					if (recipe[step].prefix === '1.' && recipe[step].text !== '') {
						return true;
					}
				});
				const renderedMayaFlow = getMayaFlowByTab({ tabId });
				const stepFlow = renderedMayaFlow.filter(
					(node) => node._step_id === firstStepId
				);
				if (stepFlow.length > 0) {
					zoomInOnNodes({ nodes: stepFlow });
				}

				let shouldGenerate = false;
				try {
					if (
						!actionTakenOnceFromEditor?.[selectedProfile.name]?.[workerId]
					) {
						shouldGenerate = true;
					}
				} catch (e) {
					console.error(e);
				}
				if (
					generateTaskId &&
					((!inLoader && shouldGenerate && sessionId && tabId) ||
						(inLoader && sessionId))
				) {
					console.log('we listening');
					listenToGenerateResult({
						sessionId,
						onReceivingGenerateMessage,
						tabId,
						setIsGenerateLoading,
						setStepIdBeingGenerated,
						stepIdToFocusOn: null,
						fromEditor: true,
						fromLoader: inLoader,
						taskId: generateTaskId,
					});
				}
			} catch (err) {
				console.error(err);
				// setErrorGettingSession(true);
			}
		};

		if (sessionId) {
			getSessionHandler();
		}
	}, [sessionId, setFlowToTab, setRecipe, tabId, generateTaskId]);

	async function handlePasteFlowClick({ res, loc }) {
		try {
			onPasteFlow({
				mayaFlow: JSON.parse(res),
				tabId: tabId,
				loc: loc,
			});
		} catch (error) {
			console.error(error);
			toast({
				title: error.message === 'Error',
				description: error.message,
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	}

	useEffect(() => {
		const handler = async () => {
			const RED = getRedInstance();
			let positionY = 0;
			const { recipes, steps, docs } = structuredClone(
				pacEngineStore.getState().skillBeingEdited
			);
			pacEngineStore.setState({ skillBeingEdited: {} });
			const flowRepresentation = [];

			recipes.forEach((recipeSamples, index) => {
				const recipeId = recipeSamples?.id;
				recipeSamples = recipes?.[index]?.samples;
				if (recipeSamples?.length > 0) {
					recipeSamples.forEach((recipe, index) => {
						const nodeId = RED.nodes.id();
						const recipePrompt = recipe.content.split(/\r?\n/)[0];
						const recipeContent = recipe.content
							.replaceAll('----', '')
							.replaceAll(recipePrompt, '')
							.trim();
						const info = `<recipe> \n${recipeContent}\n\n</recipe>`;
						const name = `${recipeId.replace('-', '_')}: ${recipePrompt}`;
						flowRepresentation.push({
							id: nodeId,
							z: tabId,
							type: 'comment',
							inputs: 0,
							outputs: 0,
							name,
							info,
							changed: true,
							moved: true,
							w: 100,
							h: 30,
							resize: true,
							x: 75,
							y: (positionY += 75),
							dirty: true,
							valid: true,
							validationErrors: [],
							dirtyStatus: true,
							color: '#674C80',
							darkColor: '#553C6B',
							_id: nodeId,
							wires: [[]],
						});
					});
				}
			});

			if (docs?.length > 0) {
				docs.forEach((doc, index) => {
					const nodeId = RED.nodes.id();
					const parsedContent = doc.content.replaceAll(/\\n/g, '\n');

					const name = `Doc ${index + 1}: ${truncate(parsedContent, {
						length: 25,
					})}`;
					const info = `<docs> \n${parsedContent}\n</docs>`;
					flowRepresentation.push({
						id: nodeId,
						z: tabId,
						type: 'comment',
						inputs: 0,
						outputs: 0,
						name,
						info,
						changed: true,
						moved: true,
						w: 100,
						h: 30,
						resize: true,
						x: 75,
						y: (positionY += 75),
						dirty: true,
						valid: true,
						validationErrors: [],
						dirtyStatus: true,
						color: '#674C80',
						darkColor: '#553C6B',
						_id: nodeId,
						wires: [[]],
					});
				});
			}

			const { nodes, edges } = parseMayaLangToReactFlow(
				flowRepresentation,
				1,
				'transient'
			);
			setFlowToTab(tabId, {
				nodes,
				edges,
			});

			await new Promise((r) => setTimeout(r, 500));
			await onDeploy(sessionId);
			await new Promise((r) => setTimeout(r, 500));

			let newStepsArray = [];
			if (steps?.length > 0) {
				const stepsFlow = [];
				let ogIds = [];
				steps.forEach((step) => {
					if (step?.samples?.length > 0) {
						step.samples.forEach((sample, index) => {
							let isOg = index === 0 ? true : false;
							const { mayaSampleContent, ogIdReturn } =
								generateUniqueNodeIds({
									mayaFlow: sample.content,
									ogIds,
									isOg,
								});
							ogIds = index === 0 ? ogIdReturn : ogIds;
							newStepsArray = [
								...newStepsArray,
								...mayaSampleContent.map((s) => {
									if (s.type === 'subflow') {
										s.name =
											step.id.replace('-', '_') + ': ' + s.name;
									}
									return s;
								}),
							];
							const subflowFlow = JSON.parse(sample?.content);
							stepsFlow.push(subflowFlow);
						});
					}
				});
				const flatStepsFlow = stepsFlow.flat();

				// Installing the modules needed by the nodes
				const allStoreModules = await getStoreModules({
					search: undefined,
				});
				const moduleNameNeededByNodes = [
					...new Set(flatStepsFlow.map((node) => node.module)),
				].filter((m) => m);
				const completeModuleDataNeededByNodes = allStoreModules.filter(
					(m) => moduleNameNeededByNodes.includes(m.packageName)
				);
				for (const selectedModule of completeModuleDataNeededByNodes) {
					const module = {
						[selectedModule.packageName]: {
							moduleName: selectedModule.name,
							status: 'QUEUED',
							configProfileReferenceId: '',
							isInternalModule: true,
							privateModule: selectedModule,
							version: selectedModule.currentVersion,
							requiresConfig:
								selectedModule.userProfile &&
								!!selectedModule.configurationType,
						},
					};
					await installModules(module);
				}
				onPasteSubflow({ mayaFlow: newStepsArray });
			}
		};

		if (Object.keys(pacEngineStore.getState().skillBeingEdited).length > 0) {
			handler();
		}
	}, []);

	const missingModulesRef = useRef(missingModules);
	missingModulesRef.current = missingModules;

	const handleGenerate = async () => {
		try {
			setActionTakenOnceFromEditor({
				profile: selectedProfile.name,
				workerId,
				value: true,
			});
			setIsGenerateLoading(true);
			const recipeDiff = compareRecipes({
				recipe: recipe,
				serverRecipe: serverRecipe,
			});
			const flowDiff = compareFlows({
				flow: JSON.stringify([
					...getMayaFlowByTab({ tabId: tabId }),
					...window.RED.nodes
						.createCompleteNodeSet()
						.filter(
							(n) =>
								!n.x &&
								!n.y &&
								n.type !== 'tab' &&
								n.type !== 'subflow' &&
								n.type !== 'group'
						),
				]),
				serverFlow: JSON.stringify(serverFlow),
			});
			/** @type {import ('../zustand/types').Diff} */
			const diff = {
				recipe: { steps: recipeDiff },
				flow: { nodes: flowDiff },
			};
			if (diffExists({ diff })) {
				console.log('diff', diff);
				const sessionRes = await changeSession({ sessionId, diff });
				if (!sessionRes) {
					throw new Error('Request to changeSession failed');
				}
			}
			const firstNonGeneratedStepId = findKey(
				recipe,
				(step) => step.generated === false
			);
			setStepIdBeingGenerated(firstNonGeneratedStepId);
			generate({
				sessionId,
				onReceivingGenerateMessage,
				tabId,
				setIsGenerateLoading,
				setStepIdBeingGenerated,
				stepIdToFocusOn: null,
				fromEditor: true,
				connId: null,
			});
		} catch (err) {
			console.error(err);
			toast({
				title: 'Request Logged!',
				description: `We're still teaching Maya how to do this. We'll reach out to you as soon as it's ready.`,
				status: 'info',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	useEffect(() => {
		window.addEventListener('maya:generate', handleGenerate);

		return () => {
			window.removeEventListener('maya:generate', handleGenerate);
		};
	}, []);

	useEffect(() => {
		const statusHandler = (e, data) => {
			onTempNodeStatusUpdate(data);
		};

		onNodeExecStatusNotification(statusHandler);
		return () => {
			offNodeExecStatusNotification(statusHandler);
		};
	}, []);

	const [isSmallerThan800] = useMediaQuery('(max-width: 800px)');
	const [isSmallerThan500] = useMediaQuery('(max-width: 400px)');

	const recipeStepIds = Object.keys(recipe);

	function handleSupportClick() {
		window.open('mailto:humans@mayalabs.io');
	}

	function handleRefreshClick() {
		window.location.reload();
	}

	return (
		<>
			<Modal
				isOpen={errorGettingSession && !isElectron()}
				onClose={() => null}
				isCentered
				size="md"
			>
				<ModalOverlay />
				<ModalContent borderRadius="2px">
					<ModalBody
						flex="1"
						minHeight="0"
						display="flex"
						flexDirection="column"
						p="0"
						borderRadius={'2px'}
						height="200px"
					>
						<Flex direction="column" flex="1" minHeight="0" p="4">
							<Box textStyle="serif.md" marginBottom="0.5rem">
								Error loading session.
							</Box>
							<Box
								fontWeight="medium"
								opacity="0.6"
								textStyle="sams.md"
								borderRadius="2px"
								mt="8px"
							>
								There was an error loading the session. Usually,
								refreshing this page fixes this. If it doesn't, please
								contact support.
							</Box>
						</Flex>
					</ModalBody>
					<ModalFooter>
						<MayaFilledButton
							text="Contact Support"
							onClick={handleSupportClick}
							colorScheme="gray"
							size="sm"
							showDotPattern={false}
							buttonProps={{
								mr: '8px',
							}}
						/>
						<MayaFilledButton
							showDotPattern
							text="Refresh"
							onClick={handleRefreshClick}
							colorScheme="purple"
							size="sm"
						/>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Box
				width={isSmallerThan500 ? '100%' : '350px'}
				zIndex="100"
				borderRight="2px solid"
				borderColor="light.border.gray.100"
			>
				<Flex bg="#fff" flexDirection="column" height="95vh">
					<Box>
						<SynthSidebarHeader
							sessionId={sessionId}
							handleGenerate={handleGenerate}
							isGenerateLoading={isGenerateLoading}
						/>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							mt="8px"
							padding="8px 16px"
							pl="20px"
							pb="0px"
							borderBottom="1px solid"
							borderColor="light.border.gray.200"
							textStyle="sans.md"
							paddingBottom="6px"
						>
							<Tooltip
								label={currentTabLabel}
								placement="right"
								openDelay={500}
							>
								<Text className={styles.recipeHeading}>
									{currentTabLabel}
								</Text>
							</Tooltip>
							{instructBarStore.getState().instructInProgress ? (
								<CircularProgress
									isIndeterminate
									color="gray.500"
									size="16px"
								/>
							) : null}
						</Box>
					</Box>

					<Box
						padding="16px 0px"
						// End of the scrollable content gets hidden if not for this margin bottom
						mb="5rem"
						flexGrow={1}
						overflowY="scroll"
						wordBreak="break-word"
						sx={{
							'&::-webkit-scrollbar': {
								w: '0.1rem',
							},
							'&::-webkit-scrollbar-track': {
								w: '0.1rem',
							},
							'&::-webkit-scrollbar-thumb': {
								bg: 'light.borders.gray.300',
								borderRadius: '5px',
							},
						}}
						// backgroundColor="lightblue"
					>
						{recipeStepIds.map((stepId) => (
							<Step
								key={stepId}
								sessionId={sessionId}
								stepId={stepId}
								step={recipe[stepId]}
								stepRefs={stepRefs}
								mayaFlow={mayaFlow}
								allowInteraction={allowInteraction}
							/>
						))}
					</Box>

					{/* Absolutely positioned footer. Desired effect of fixed header, scrollable content and fixed footer is not achievable using only flex. Using absolute position on the footer seems to be the right implementation.
					 */}
					<Box
						mt="8px"
						padding="8px 16px"
						pl="20px"
						backgroundColor="#fff"
						// backgroundColor="lightgreen"
						position="absolute"
						bottom={0}
						width={isSmallerThan500 ? '100%' : '350px'}
						zIndex="100"
						borderColor="light.border.gray.100"
					>
						<Box
							borderTop="1px solid"
							paddingTop="12px"
							borderColor="light.border.gray.200"
						>
							<Text
								marginBottom="0 !important"
								opacity="0.7"
								userSelect="none"
							>
								Describe your step in English, hit [Enter] to generate,
								then hit [Shift + Enter] to deploy.
							</Text>
						</Box>
					</Box>
				</Flex>

				{modulesInstalling && (
					<InterstitialModuleInstallDrawer
						installState={modulesToInstall}
						isOpen={isModuleInstallationDrawerVisible}
						onClose={() => setIsModuleInstallationDrawerVisible(false)}
					/>
				)}
			</Box>
		</>
	);
};

export default React.memo(SynthesisSidebar);
