import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import SectionHeader from '../../../../../library/text/SectionHeader';
import useStepperAnalytics from '../../../hooks/useStepperAnalytics';

const TypeForm = props => {
  React.useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://embed.typeform.com/next/embed.js'
    document.body.appendChild(script)
  }, [])
  return (
    <Box height={{ sm: '630px', md: '700px' }} marginTop='20px'>
      <Box
        data-tf-widget="FUjzgTGN"
        data-tf-iframe-props="title=Custom Maya Workflow"
        data-tf-medium="snippet"
        data-tf-opacity="0" data-tf-hide-headers
        width={{ sm: '100%', md: '1250px' }}
        maxWidth="1250px"
        style={{ height: '87%' }}
      ></Box>
    </Box>
  )
}


/**
 * Options to start a new order.
 *
 * @returns
 */
const NewOrder = () => {
  useStepperAnalytics()
	return (
		<Box
			display="flex"
			flexDir="column"
			justifyContent="center"
			alignItems="center"
		>
			<Box paddingTop="20px" paddingBottom="20px">
				<SectionHeader
					heading="New Custom Order"
					subheading="Tailormade automations built by code-writing algorithms."
				/>
                <TypeForm/>
			</Box>
			
		</Box>
	);
};

const sectionObject = {
	heading: 'new order',
	component: <NewOrder />,
};

export default sectionObject;
