import React, { useEffect, useState } from 'react';
import { Flex, Box, Accordion } from '@chakra-ui/react';
import getStoreModules from '../../../../../functions/store/getStoreModules';
import ModuleConfig from '../../Store/ModuleConfig/ModuleConfig';
import SettingsItem from '../SettingsItem';
import { MayaSkeleton } from '@mayahq/ui';

const IntegrationsSettings = () => {
	const [modules, setModules] = useState(null);
	const [initialIsLoading, setInitialIsLoading] = useState(true);

	useEffect(() => {
		getStoreModules({}).then((res) => {
			console.log('stmod', res);
			setModules(res);
			setInitialIsLoading(false);
		});
	}, []);

	let moduleConfigCards = [];
	if (modules) {
		moduleConfigCards = modules
			.filter((module) => !!module.configurationType)
			.map((module) => {
				return (
					<Box mb="16px" key={module.id}>
						<ModuleConfig
							module={module}
							onSelectProfile={() => null}
							selectedProfile={{}}
							selectMode={false}
						/>
					</Box>
				);
			});
	}

	return (
		<Flex
			direction="column"
			flex="1 1 auto"
			height="100%"
			borderRadius="20px"
		>
			{initialIsLoading ? (
				<>
					{[...Array(4)].map((val, index) => (
						<React.Fragment key={index}>
							<SettingsItem
								text={<MayaSkeleton w="10rem" h="1.5rem" />}
								description={<MayaSkeleton w="25rem" h="1rem" mt="2" />}
								rightItem={
									<>
										<MayaSkeleton w="5rem" h="1.5rem" mr="4" />
									</>
								}
							/>
						</React.Fragment>
					))}
				</>
			) : (
				<Flex
					position="relative"
					overflow="auto"
					flex="1 1 auto"
					width="100%"
				>
					<Flex
						direction="column"
						position="absolute"
						height="100%"
						width="100%"
					>
						<Accordion allowToggle>{moduleConfigCards}</Accordion>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};

export default IntegrationsSettings;
