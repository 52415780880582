import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

type PatternIconProps = {
	patternProps?: IconProps;
};

const PatternIcon = ({ patternProps }: PatternIconProps) => {
	return (
		<Icon
			viewBox="0 0 20 20"
			color="none"
			position="absolute"
			top="3px"
			left="2px"
			boxSize="4"
			{...patternProps}
		>
			<circle
				cx="1.90362"
				cy="1.21382"
				r="1.04602"
				transform="rotate(-0.255338 1.90362 1.21382)"
				fill="white"
			/>
			<circle
				cx="6.00763"
				cy="1.19575"
				r="1.04602"
				transform="rotate(-0.255338 6.00763 1.19575)"
				fill="white"
			/>
			<circle
				cx="10.1116"
				cy="1.17719"
				r="1.04602"
				transform="rotate(-0.255338 10.1116 1.17719)"
				fill="white"
			/>
			<circle
				cx="14.2156"
				cy="1.15913"
				r="1.04602"
				transform="rotate(-0.255325 14.2156 1.15913)"
				fill="white"
			/>
			<circle
				cx="18.3196"
				cy="1.14106"
				r="1.04602"
				transform="rotate(-0.255338 18.3196 1.14106)"
				fill="white"
			/>
			<circle
				cx="1.92218"
				cy="5.31831"
				r="1.04602"
				transform="rotate(-0.255338 1.92218 5.31831)"
				fill="white"
			/>
			<circle
				cx="6.0257"
				cy="5.30024"
				r="1.04602"
				transform="rotate(-0.25536 6.0257 5.30024)"
				fill="white"
			/>
			<circle
				cx="10.1302"
				cy="5.28169"
				r="1.04602"
				transform="rotate(-0.255338 10.1302 5.28169)"
				fill="white"
			/>
			<circle
				cx="14.2342"
				cy="5.26362"
				r="1.04602"
				transform="rotate(-0.255338 14.2342 5.26362)"
				fill="white"
			/>
			<circle
				cx="1.94025"
				cy="9.42182"
				r="1.04602"
				transform="rotate(-0.255338 1.94025 9.42182)"
				fill="white"
			/>
			<circle
				cx="6.04425"
				cy="9.40376"
				r="1.04602"
				transform="rotate(-0.255338 6.04425 9.40376)"
				fill="white"
			/>
			<circle
				cx="10.1483"
				cy="9.38569"
				r="1.04602"
				transform="rotate(-0.255338 10.1483 9.38569)"
				fill="white"
			/>
			<circle
				cx="1.9588"
				cy="13.5258"
				r="1.04602"
				transform="rotate(-0.255338 1.9588 13.5258)"
				fill="white"
			/>
			<circle
				cx="6.0628"
				cy="13.5073"
				r="1.04602"
				transform="rotate(-0.255338 6.0628 13.5073)"
				fill="white"
			/>
			<circle
				cx="1.97687"
				cy="17.6303"
				r="1.04602"
				transform="rotate(-0.255359 1.97687 17.6303)"
				fill="white"
			/>
		</Icon>
	);
};

export default PatternIcon;
