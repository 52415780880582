import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { WarningTwoIcon, CheckCircleIcon } from '@chakra-ui/icons';

/**
 *
 * @param {import("../types/ConfigureButton").ConfigureButtonProps} props
 * @returns {JSX.Element}
 */
const ConfigureButton = ({
	configured = false,
	showState = true,
	// configured = true,
}) => {
	return (
		<Flex
			textStyle="sans.xs"
			border="2px solid"
			borderColor={
				showState && configured ? 'green.400' : 'light.border.gray.200'
			}
			bg={showState && configured ? 'green.100' : 'light.theme.gray.200'}
			borderRadius="2px"
			color={showState && configured ? 'green.600' : '#5F5E5E'}
			padding="4px 12px"
			textAlign="center"
			// width="140px"
			alignItems='center'
			justifyContent='space-between'
		>
			<Flex
				mr='8px'
				mb='1px'
			>
				{configured && showState && (
					<CheckCircleIcon color="green.500" w={4} h={4} />
				)}
				{!configured && showState && (
					<WarningTwoIcon
						color="#5F5E5E"
						w={3.5}
						h={3.5}
						// mb='0.5px'
					/>
				)}
			</Flex>
			{showState && configured ? 'CONFIGURED' : 'CLICK TO CONFIGURE'}
		</Flex>
	);
};

export default ConfigureButton;
