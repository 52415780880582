import { store } from '../../redux/store';
import mixpanel from './mixpanel';
import { config } from '../../config';
const analytics = {};

analytics.identify = (userId) => {
	mixpanel.identify(userId);
};

analytics.track = (event, metadata) => {
	if (process.env.NODE_ENV === 'development') {
		console.log('[ Analytics ]', event, metadata);
	}
	mixpanel.track(event, {
		...metadata,
		release: 'maya-desktop@' + process.env.npm_package_version,
		environment: config.app.MAYA_ENV,
	});
};

analytics.setUserDetails = (email, userId, metadata) => {
	var d = new Date();
	const state = store.getState();
	const user = state.profiles.profiles[state.profiles.userSlug];
	mixpanel.people.set({
		$name: user.name,
		$email: email, // only reserved properties need the $
		'Sign up date': d.toISOString(), // Send dates in ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
		USER_ID: userId,
		...metadata,
	});
};

export default analytics;
