import React from 'react';
import { Button, Box, LightMode, useColorMode } from '@chakra-ui/react';

import { getOS } from '../../../../../util/PlatformUtils/platform';
import IconContainer from '../../../../../util/React_Icon';
import { AiFillApple, AiFillWindows } from 'react-icons/ai';
import { ArrowForwardIcon } from '@chakra-ui/icons';

import { DiLinux } from 'react-icons/di';
import MayaFilledButton from '../../../../../library/buttons/FilledButton';
const https = require('https');

const DownloadSection = () => {
	const { colorMode } = useColorMode();
	const fontColor = { light: '#5C5A5A', dark: 'white' };
	const [selectedPlatform, selectPlatform] = React.useState(getOS());
	const handleClick = () => {
		https.get(
			'https://api.github.com/repos/mayahq/maya-desktop/releases/latest',
			(response) => {
				let data = '';
				let tag = '';
				let releaseName = '';
				response.on('data', (chunk) => {
					data += chunk;
				});
				response.on('end', () => {
					var gitResponse = JSON.parse(data);
					tag = gitResponse.tag_name;
					releaseName = gitResponse.name;
					let platform = selectedPlatform;
					if (platform === 'Windows') {
						window.open(
							'https://github.com/mayahq/maya-desktop/releases/download/' +
								tag +
								'/Maya.Setup.' +
								releaseName +
								'.exe'
						);
					} else if (platform === 'Linux') {
						window.open(
							'https://github.com/mayahq/maya-desktop/releases/download/' +
								tag +
								'/Maya-' +
								releaseName +
								'.AppImage'
						);
					} else if (platform === 'Mac') {
						window.open(
							'https://github.com/mayahq/maya-desktop/releases/download/' +
								tag +
								'/Maya-' +
								releaseName +
								'.dmg'
						);
						// window.open(
						//   "https://drive.google.com/file/d/1qb8Jgy6__j5-1_5KKxciLzUw1gEv4oco/view?usp=sharing"
						// );
					}
				});
			}
		);
	};
	const handleClickArm = () => {
		https.get(
			'https://api.github.com/repos/mayahq/maya-desktop/releases/latest',
			(response) => {
				let data = '';
				let tag = '';
				let releaseName = '';
				response.on('data', (chunk) => {
					data += chunk;
				});
				response.on('end', () => {
					var gitResponse = JSON.parse(data);
					tag = gitResponse.tag_name;
					releaseName = gitResponse.name;
					let platform = selectedPlatform;
					if (platform === 'Mac') {
						window.open(
							'https://github.com/mayahq/maya-desktop/releases/download/' +
								tag +
								'/Maya-' +
								releaseName +
								'-arm64.dmg'
						);
						// window.open(
						//   "https://drive.google.com/file/d/1qb8Jgy6__j5-1_5KKxciLzUw1gEv4oco/view?usp=sharing"
						// );
					}
				});
			}
		);
	};

	var iconColor = '#7D7D7D';
	var selectedIconColor = '#282828';
	var iconSize = '30px';

	return (
		<Box
			display="flex"
			fontFamily="body"
			fontSize="18px"
			flexDirection="column"
		>
			<Box
				marginTop="1rem"
				width="100%"
				marginBottom="10px"
				color="light.font.gray.400"
				textStyle="sans.sm"
			>
				Create unlimited free workspaces on the Maya desktop app. Best for
				tasks that need to be run manually.
			</Box>
			<Box
				width="100%"
				marginBottom="20px"
				color="light.font.gray.300"
				textStyle="sans.sm"
			>
				Available for Windows, Mac or Linux (choose below) :
			</Box>

			<Box
				opacity="0.8"
				display="flex"
				alignItems="center"
				marginBottom="30px"
				color="light.font.gray.400"
				textStyle="sans.sm"
			>
				<Box
					marginRight="0.2rem"
					onClick={() => selectPlatform('Mac')}
					cursor="pointer"
				>
					<IconContainer
						icon={<AiFillApple />}
						value={{
							color:
								selectedPlatform === 'Mac'
									? selectedIconColor
									: iconColor,
							className: 'global-class-name',
							size: iconSize,
						}}
					/>
				</Box>
				<Box
					marginRight="0.2rem"
					onClick={() => selectPlatform('Windows')}
					cursor="pointer"
				>
					<IconContainer
						icon={<AiFillWindows />}
						value={{
							color:
								selectedPlatform === 'Windows'
									? selectedIconColor
									: iconColor,
							className: 'global-class-name',
							size: iconSize,
						}}
					/>
				</Box>
				<Box
					marginRight="0.2rem"
					onClick={() => selectPlatform('Linux')}
					cursor="pointer"
				>
					<IconContainer
						icon={<DiLinux />}
						value={{
							color:
								selectedPlatform === 'Linux'
									? selectedIconColor
									: iconColor,
							className: 'global-class-name',
							size: iconSize,
						}}
					/>
				</Box>
			</Box>
			{selectedPlatform === 'Mac' ? (
				<Box width="100%" display="flex">
					<MayaFilledButton
						text={`For Mac (Intel)`}
						onClick={handleClick}
						colorScheme="gray"
						buttonProps={{ width: '48%' }}
						rightIcon={<ArrowForwardIcon />}
					/>
					<MayaFilledButton
						text={`For Mac (M1 Silicon)`}
						onClick={handleClickArm}
						colorScheme="gray"
						buttonProps={{ width: '48%', ml: '2' }}
						rightIcon={<ArrowForwardIcon />}
					/>
				</Box>
			) : (
				<Box width="100%">
					<MayaFilledButton
						text={`Download For ${selectedPlatform}`}
						onClick={handleClick}
						colorScheme="gray"
						buttonProps={{ width: '100%' }}
						rightIcon={<ArrowForwardIcon />}
					/>
				</Box>
			)}
		</Box>
	);
};

export default DownloadSection;
