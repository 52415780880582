import React from 'react';
import { Flex, Box, Grid, GridItem } from '@chakra-ui/react';

export const GroupTitle = ({ children }) => {
	if (children.length === 0) {
		return null;
	}

	return (
		<Box
			mb="20px"
			textStyle="sans.md"
			color="light.font.gray.300"
			fontWeight="400"
		>
			{children}
		</Box>
	);
};

export const GroupHeader = ({ children }) => {
	if (children.length === 0) {
		return null;
	}

	return (
		<Grid
			templateColumns={`repeat(${8}, minmax(0, 1fr))`}
			gap="16px"
			autoRows="max-content"
			mb="16px"
		>
			{children}
		</Grid>
	);
};

export const GroupContent = ({ children, width }) => {
	if (children.length === 0) {
		return null;
	}

	return (
		<Grid
			templateColumns={`repeat(${width}, minmax(0, 1fr))`}
			gap="16px"
			flex="1 1 auto"
			overflow="auto"
			autoRows="max-content"
			// bg='pink'
			height="100%"
			css={{
				'&::-webkit-scrollbar': {
					width: '5px',
				},
			}}
		>
			{children}
		</Grid>
	);
};

export const GroupFooter = ({ children }) => {
	if (children.length === 0) {
		return null;
	}

	return (
		<Grid
			templateColumns={`repeat(${8}, minmax(0, 1fr))`}
			dir="rtl"
			gap="16px"
			autoRows="max-content"
			mt="12px"
			// bg='green'
		>
			{children}
		</Grid>
	);
};

const GroupComp = ({ width, title, children, gkey }) => {
	return (
		<Box
			key={gkey}
			bg="#fff"
			flex="1 1 auto"
			borderRadius="3px"
			display="flex"
			flexDir="column"
			padding="16px"
			overflow="auto"
			border="1px solid"
			borderColor="light.border.gray.200"
			height="100%"
		>
			{children}
		</Box>
	);
};

export const Group = React.memo(GroupComp);
