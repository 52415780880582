import React, { useCallback, useEffect } from 'react';

import styled from '@emotion/styled';
import { connect, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import isElectron from 'is-electron';
import { Box, useColorMode, IconButton, useToast, Img } from '@chakra-ui/react';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';
import { useLocation, useParams } from 'react-router-dom';
import { createStandaloneToast } from '@chakra-ui/react';
import theme from '../../library/theme';
import SectionList from './Sections';

// ACTIONS
import {
	setCurrentProfile,
	setDashboardInfo,
	setProfiles,
} from '../../redux/actions';

// Component logic abstaction
import getDashboardData from '../../functions/dashboard/getDashboard';

// components
// import SideBar from './Sidebar';
// import NavSearch from './NavSearch';
// import StartupLoader from './SetupWizard/StartupLoader';
// import CheckOrRegisterDevice from './Device/CheckOrRegisterDevice';
import Background from './Background';
// import { SectionList } from './MainApp';

// functions
import getUserProfiles from '../../functions/profile/getUserProfiles';

// import RegisterDeviceComponent from "./Electron/RegisterDevice";

//Analytics
import analytics from '../../util/Analytics';

//Zustand Stores
import { useDashboardStore } from './zustand';
import useSetSlugFromParams from '../../util/ProfileSlug/useSetSlugFromParams';
import InstructBar from '../Editor/Tools/pac-engine/InstructBar';
import { useStore } from '../Editor/zustand';

export const Content = styled.div`
	display: flex;
	flex-grow: 1;
`;

export const CenterSection = styled.div`
	margin-top: 82px;
	flex-grow: 1;
	padding-left: 2rem;
	padding-right: 2rem;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	min-height: 0;
`;

/**
 *
 * @param {import("./types/dashboard").Dashboard} param0
 * @returns
 */

const Dashboard = ({ children }) => {
	useSetSlugFromParams();
	const toast = createStandaloneToast({ theme: theme });
	const dispatch = useDispatch();

	const { setLoading, refreshToggle } = useDashboardStore((state) => {
		return {
			setLoading: state.setLoading,
			refreshToggle: state.refreshToggle,
		};
	});

	const user = useSelector(
		/**
		 *
		 * @param {import("../../redux/reducers/types/userReducer").UserState} state
		 * @returns
		 */
		(state) => state.user
	);

	const { selectedProfile, profilesLoading } = useSelector(
		/**
		 *
		 * @param {import('../../redux/reducers/types/profileReducer').ProfilesState} state
		 * @returns
		 */
		(state) => ({
			selectedProfile: state.profiles.profiles[state.profiles.selected],
			profilesLoading: state.profiles.profilesLoading,
		})
	);
	const { status } = user;
	// const { device } = useSelector((state) => state.devices.current);
	// const { brains } = state.brains;

	// const deviceId = status === 'online' ? (device ? device._id : null) : null;

	if (isElectron()) {
		// @ts-ignore
		var electron = window.electron;
		var { ipcRenderer } = electron;
		ipcRenderer.removeAllListeners('updateDashboardData');
		ipcRenderer.handle('updateDashboardData', (event, arg) => {
			setDashboardData();
		});
	}

	const slug = selectedProfile.profileSlug;
	const setDashboardData = () => {
		// console.log('dashe getting brains for', slug);
		getDashboardData({ slug })
			.then(async (res) => {
				if (res['error']) {
					setLoading(false);
					toast({
						title: 'Error!',
						description: 'Error fetching brains for the profile',
						status: 'error',
						duration: 3000,
						isClosable: true,
					});
				} else {
					//status = "offline"
					setLoading(false);
					dispatch(setDashboardInfo(res));
				}
			})
			.catch((e) => console.error('qsins error', e));
	};

	const instructBarIsOpen = useStore(
		useCallback((state) => state.instructBarIsOpen, [])
	);
	const toggleInstructBar = useStore(
		useCallback((state) => state.toggleInstructBar, [])
	);

	/**
	 * Offline State Update
	 *
	 * useEffect hook to update results in Redux state for both offline and online state
	 *
	 */
	useEffect(() => {
		setLoading(true);
		if (profilesLoading || selectedProfile.profileSlug === '_') {
			console.log('dashe profiles not loaded yet');
			return;
		}

		setDashboardData();
		if (user.info) {
			analytics.identify(user.info.id);
			analytics.setUserDetails(user.info.email, user.info.id);
		}
	}, [status, refreshToggle, selectedProfile.profileSlug, profilesLoading]);

	// Handling the toggling of InstructBar
	const COMMAND_BAR_TOGGLE_KEY = 'KeyK';
	useEffect(() => {
		const shortcutHandler = (e) => {
			if (e.code === COMMAND_BAR_TOGGLE_KEY) {
				if (e.metaKey || e.ctrlKey) {
					analytics.track(
						`[Workspace] Cmd + K > ${
							!useStore.getState().instructBarIsOpen ? 'Open' : 'Close'
						}`
					);
					e.stopPropagation();
					e.preventDefault();
					toggleInstructBar();
				}
			}
		};

		document.addEventListener('keydown', shortcutHandler);
		return () => {
			document.removeEventListener('keydown', shortcutHandler);
		};
	}, []);

	return (
		<>
			{instructBarIsOpen ? <InstructBar /> : null}
			<Background
				topBarOption={'main'}
				// backBtnClick={() => {}}
				// closeBtnClick={() => {}}
			>
				{children}
			</Background>
		</>
	);
};
// TODO : Add electron stuff
// {isElectron() ? <RegisterDeviceComponent /> : null}
// <ColorModeExample />

export default Dashboard;
