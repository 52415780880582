import { useState } from 'react'
import { useSelector } from 'react-redux'

const useProfileSlug = () => {
    const profileSlug = useSelector(
        /**
         * 
         * @param {import('../redux/reducers/types/profileReducer').ProfilesState} state 
         * @returns 
         */
        state => state.profiles.selected
    )

    return profileSlug
}

export default useProfileSlug