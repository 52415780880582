import React, { useCallback, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import isElectron from 'is-electron';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from './Dashboard';
// import { Mutex } from 'async-mutex';

//Components
import TabSection from './Electron/Tabs';
import StepperDrawer from './Stepper';
import { setDevices, setProfiles } from '../redux/actions';
import OfflineRibbon from './Offline';

//Login
import LoginCard from './Dashboard/Login/Login';
import ResetCard from './Dashboard/Login/Reset';
import ForgotCard from './Dashboard/Login/Forgot';
import RegisterCard from './Dashboard/Login/Register';
import VerifyCard from './Dashboard/Login/Verify';
import LandingPage from './Dashboard/Login';
import NewCardUI from './Commands/NewCardUI';

import SectionList from './Dashboard/Sections';

//Utils
import History, { HistoryWithSlug } from '../util/History';
import useOnlineStatus from '../util/useOnlineStatus';

// import NewCardUI from './Commands/NewCardUI';
import { store } from '../redux/store';
import { updateBrainById } from '../redux/actions';
import Editor from './Editor/Editor';
import { config } from '../config';

//Zustand
import useStepperStore from './Stepper/StepperStore';
import UiTest from './UiTest';
import updateMetadata from '../functions/profile/updateMetadata';
import { v4 } from 'uuid';
import { stepperFlowTypes } from './Stepper/Sections/registry';
import { getSlugFromUrl } from '../util/ProfileSlug/getSlugFromUrl';
import { getSlugFromLocalStorage } from '../util/ProfileSlug/localStorageSlug';
import {
	getPathWithoutSlug,
	routesWithSlug,
	updateProfileSlugInUrl,
} from '../functions/profile/switchProfile';
import getUserProfiles from '../functions/profile/getUserProfiles';
import DashboardUI from './DashboardUI';
import useProfileSlug from '../hooks/useProfileSlug';
import analytics from '../util/Analytics';
import getDevices from '../functions/devices/getDevices';
import { normalize } from '../util/Misc';
import { updateTokensForElectron } from '../functions/Auth/updateTokensForElectron';
import { registerInterceptors } from './interceptors';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

registerInterceptors();

const Main = () => {
	const ps = useProfileSlug();
	console.log('pass', ps);
	let location = useLocation();
	const dispatch = useDispatch();
	const [tabs, setTabs] = React.useState(true);

	const onlineStatus = useOnlineStatus();

	const user = useSelector(
		/**
		 *
		 * @param {import("../redux/reducers/types/userReducer").UserState} state
		 * @returns
		 */
		(state) => state.user
	);

	const tokens = useSelector(
		/**
		 *
		 * @param {import("../redux/reducers/types/userReducer").UserState} state
		 * @returns
		 */
		(state) => state.user.tokens
	);

	const { profile, userProfile, allProfiles, profilesLoading } = useSelector(
		/**
		 *
		 * @param {import("../redux/reducers/types/profileReducer").ProfilesState} state
		 * @returns
		 */
		(state) => {
			const allProfiles = state.profiles.profiles;
			return {
				profile: allProfiles[state.profiles.selected],
				userProfile: allProfiles[state.profiles.userSlug],
				allProfiles: allProfiles,
				profilesLoading: state.profiles.profilesLoading,
			};
		}
	);

	const profileMetadata = profile?.metadata;

	/**
	 * @returns {import("../redux/reducers/types/profileReducer").SignUpMetadata}
	 */
	const getSignupMetadataFromLocalStorage = useCallback(() => {
		const dataString = localStorage.getItem('signupMetadata');
		if (!dataString) {
			return null;
		}

		try {
			return JSON.parse(dataString);
		} catch (e) {
			return null;
		}
	}, []);

	React.useEffect(() => {
		console.log('login :: Tokens updated, updating in filesystem');
		if (!tokens) {
			return;
		}
		const { accessToken, refreshToken } = tokens;
		updateTokensForElectron({ accessToken, refreshToken });
	}, [tokens]);

	// Listening for changes in brain status from the main thread
	// This is especially important for listening to brain crash events
	React.useEffect(() => {
		// Can't do this for web
		if (!isElectron()) {
			return;
		}

		// @ts-ignore
		const electron = window.electron;
		const { ipcRenderer } = electron;

		ipcRenderer.removeAllListeners('/brain/status/update');
		ipcRenderer.handle('/brain/status/update', (event, arg) => {
			const data = JSON.parse(arg);
			const { brainId, status } = data;
			store.dispatch(updateBrainById(brainId, { status }));
		});

		try {
			analytics.setUserDetails(user.info.email, user.id, {});
		} catch (e) {
			console.error('Failed to set user details in analytics');
		}
	}, []);

	function getUserSlug(profiles, fallbackSlug) {
		let slug = fallbackSlug;

		if (
			!routesWithSlug.some((route) =>
				getPathWithoutSlug(location.pathname).startsWith(route)
			)
		) {
			slug = getSlugFromLocalStorage();
			if (slug === null || !profiles[slug]) {
				slug = fallbackSlug;
			}
		} else {
			slug = getSlugFromUrl(location.pathname);
			if (slug === null) {
				slug = getSlugFromLocalStorage();
				if (slug === null || !profiles[slug]) {
					slug = fallbackSlug;
				}
			}
		}

		return slug;
	}

	React.useEffect(() => {
		if (user.status === 'offline') {
			return;
		}

		getUserProfiles().then((res) => {
			// console.log('slos profiles', res);
			const userSlug = res.find(
				(profile) => profile.isTeam === false
			)?.profileSlug;
			const allProfiles = {};
			res.forEach((profile) => (allProfiles[profile.profileSlug] = profile));

			const slug = getUserSlug(allProfiles, userSlug);

			dispatch(setProfiles(res, slug));
			const selectedProfile = allProfiles[slug];
			updateProfileSlugInUrl(selectedProfile, location);
		});
	}, [user.status]);

	/**
	 * Update URL with slug if needed
	 */
	React.useEffect(() => {
		if (
			profilesLoading ||
			!profile?.profileSlug ||
			!userProfile?.profileSlug
		) {
			return;
		}

		const appendSlug = routesWithSlug.some((route) =>
			getPathWithoutSlug(location.pathname).startsWith(route)
		);

		if (!appendSlug) {
			return;
		}

		updateProfileSlugInUrl(profile, location);
	}, [location.pathname, profile.profileSlug]);

	React.useEffect(() => {
		if (isElectron()) {
			// @ts-ignore
			const electron = window.electron;
			const { ipcRenderer, Notification } = electron;
			ipcRenderer.removeAllListeners('/overlay/cli');
			ipcRenderer.handle('/overlay/cli', (event, arg) => {
				console.log('/overlay/cli');
				setTabs(false);
				History.push('/cli');
			});
			//Send redirect history push messages from electron
			ipcRenderer.removeAllListeners('/history/go');
			ipcRenderer.handle('/history/go', (event, arg) => {
				console.log('/history/go');
				const { route } = arg;
				if (route[0] === '/') {
					HistoryWithSlug.push(route);
				} else {
					HistoryWithSlug.push('/' + route);
				}
			});
			ipcRenderer.removeAllListeners('/notification/send');
			ipcRenderer.handle('/notification/send', (event, arg) => {
				console.log('/notification/send');
				const { title, message } = arg;
				new Notification({
					title: title,
					body: message,
				}).show();
			});
		}
	}, []);

	const { addStep, openStepper, closeStepper, clearSteps, clearStore } =
		useStepperStore((state) => {
			return {
				addStep: state.addStep,
				nextStep: state.nextStep,
				openStepper: state.openStepper,
				clearSteps: state.clearSteps,
				closeStepper: state.closeStepper,
				clearStore: state.clearStore,
			};
		});

	React.useEffect(() => {
		if (user.status !== 'online') {
			clearStore();
			clearSteps();
			closeStepper();
		}
	}, [user?.status]);

	React.useEffect(() => {
		if (location.pathname === '/cli') {
			setTabs(false);
		} else {
			setSupportWidget(true);
		}
	}, [location]);

	React.useEffect(() => {
		if (user?.status !== 'online' || !user.info) {
			return;
		}

		if (!user?.info?.hasAccess) {
			addStep('onboarding.noAccess', {
				stepperFlowType: stepperFlowTypes.onboarding.NO_ACCESS,
				stepperSessionId: v4(),
			});
			return openStepper();
		}
	}, [user, Date.now()]);

	/**
	 * Show onboarding stepper if needed
	 */
	React.useEffect(() => {
		if (
			!profileMetadata ||
			profileMetadata?.onboarded ||
			user?.info?.hasAccess === false
		) {
			return;
		}

		const signupMetadata = {
			mode: 'normal',
			timestamp: Date.now(),
		};

		updateMetadata({
			metadata: {
				// @ts-ignore
				signupMetadata: signupMetadata,
			},
		});

		const stepperSessionId = v4();
		switch (signupMetadata.mode) {
			case 'normal': {
				addStep('onboarding.welcome', {
					signupMetadata,
					stepperSessionId,
					stepperFlowType: stepperFlowTypes.onboarding.NORMAL,
					currentProfileRegion: profile?.region,
				});
				break;
			}
			default:
				console.error('prom: bad mode in signupMetadata');
		}

		openStepper();
	}, [History.location.pathname, profile?.profileSlug]);

	React.useEffect(() => {
		if (user.status !== 'online') {
			return
		}
		
		getDevices()
			.then((res) => {
				if (!Array.isArray(res.results)) {
					return;
				}

				dispatch(setDevices(normalize(res.results)));
			})
			.catch((e) => console.error('Unable to get devices:', e));
	}, [ps, user.status]);

	const [supportWidget, setSupportWidget] = React.useState(false);

	/** Use this to store current location, that would be used to restore after login */
	const [currentPathWithoutDomain, setCurrentPathWithoutDomain] = useState(
		`${History.location.pathname}${History.location.search}${History.location.hash}`
	);

	const withLoginRedirect = (comp) => {
		if (
			user.status !== 'offline' ||
			currentPathWithoutDomain === '/register'
		) {
			return comp;
		} else {
			return (
				<Redirect
					to={{
						pathname: '/login',
						state: {
							restoreLocation: currentPathWithoutDomain,
						},
					}}
				/>
			);
		}
	};

	return (
		<Box
			height="100vh"
			display="flex"
			flexDirection="column"
			overflow="hidden"
			// bg='green'
		>
			{user.status === 'online' && <StepperDrawer />}
			{tabs ? <TabSection /> : null}
			{/* {onlineStatus ? null : tabs ? <OfflineRibbon /> : null} */}
			<Switch>
				<Route
					exact
					path="/"
					children={
						user.status !== 'offline' ? (
							<Redirect to="/workers" />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/install"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['install']()} />
						) : (
							<Redirect to={`/register${History.location.search}`} />
						)
					}
				/>
				<Route
					exact
					path="/_/:slug/install"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['install']()} />
						) : (
							<Redirect to={`/register${History.location.search}`} />
						)
					}
				/>
				

				<Route
					exact
					path="/acceptInvite"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['acceptInvite']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/workers"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['workspaces']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/_/:slug/workers"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['workspaces']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/workers/ui"
					children={
						user.status !== 'offline' ? (
							<DashboardUI />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/_/:slug/workers/ui"
					children={
						user.status !== 'offline' ? (
							<DashboardUI />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					path="/store"
					children={withLoginRedirect(
						<Switch>
							<Route exact path="/store">
								<Dashboard children={SectionList['store']()} />
							</Route>
							<Route exact path="/store/module/:moduleId">
								<Dashboard
									children={SectionList['storeModulePage']()}
								/>
							</Route>
							<Route exact path="/store/collection/:org/:slug">
								<Dashboard
									children={SectionList['storeCollectionPage']()}
								/>
							</Route>
						</Switch>
					)}
				/>
				<Route
					path="/dashboards"
					children={withLoginRedirect(
						<Dashboard>{SectionList['workspaceDashboards']()}</Dashboard>
					)}
				/>

				<Route
					exact
					path="/orders"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={<Box>orders</Box>} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/_/:slug/orders"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={<Box>orders</Box>} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>

				<Route
					exact
					path="/tasks"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={<Box>tasks</Box>} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/_/:slug/tasks"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={<Box>tasks</Box>} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/settings"
					children={
						user.status !== 'offline' ? (
							<Redirect to="/settings/general" />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>

				<Route
					exact
					path="/_/:slug/settings"
					children={
						user.status !== 'offline' ? (
							<Box>
								hey
								<Redirect to="/settings/general" />
							</Box>
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>

				<Route
					exact
					path="/configure"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['configure']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>

				<Route
					exact
					path="/_/:slug/configure"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['configure']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>

				<Route
					exact
					path="/settings/:id"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['settings']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/_/:slug/settings/:id"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['settings']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>

				<Route
					exact
					path="/library"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['library']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>

				<Route
					exact
					path="/_/:slug/library"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['library']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>

				<Route
					path="/apps/callback"
					children={withLoginRedirect(
						<Dashboard children={SectionList['callback']()} />
					)}
				/>
				<Route
					path="/apps/callback-dev"
					children={withLoginRedirect(
						<Dashboard children={SectionList['callback']()} />
					)}
				/>

				<Route
					path="/edit"
					children={
						user.status !== 'offline' ? (
							<Editor />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					path="/_/:slug/edit"
					children={
						user.status !== 'offline' ? (
							<Editor />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>

				<Route
					path="/login"
					children={
						user.status === 'offline' ? (
							<LandingPage children={<LoginCard />} />
						) : (
							<Redirect
								to={{
									pathname: '/workers',
								}}
							/>
						)
					}
				/>
				<Route
					path="/reset"
					children={<LandingPage children={<ResetCard />} />}
				/>
				<Route
					path="/forgot"
					children={<LandingPage children={<ForgotCard />} />}
				/>
				<Route
					path="/register"
					children={
						user.status === 'offline' ? (
							<LandingPage children={<RegisterCard />} />
						) : (
							<Redirect
								to={{
									pathname: '/workers',
								}}
							/>
						)
					}
				/>
				<Route
					path="/verify"
					children={<LandingPage children={<VerifyCard />} />}
				/>
				<Route path="/cli" children={<NewCardUI />} />
				{config.enableUiTest ? (
					<>
						<Route path="/ui-test" exact children={<UiTest />} />
					</>
				) : null}
			</Switch>
		</Box>
	);
};

const mapStateToProps = (state) => {
	const { user, brains } = state;
	console.log('dat state', state);
	return { user, brains };
};

export default Main;
