import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';
import getInstallerFlow from '../store/getInstallerFlow';

import isElectron from 'is-electron';

// config
import { config } from '../../config';
import { store } from '../../redux/store';

import deploySkillAndUpdateRuntime from '../editor/deploy';

const installExternalSkillpack = async (formData) => {
	var { user, brains } = store.getState();
	formData['profileSlug'] = formData['profileSlug']
		? formData['profileSlug']
		: user.info.id;
	let brainDevice = brains.byId[formData.brainId]['device']['platform'];

	if (brainDevice === 'cloud') {
		//TODO install external flow on cloud runtimes
		let installExternalSkillpackAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.installExternalSkillpack.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.installExternalSkillpack.PATH_METHOD,
		});
		let installExternalSkillpackResponse =
			await installExternalSkillpackAPIFn(formData);
		if (installExternalSkillpackResponse['data']['error']) {
			return installExternalSkillpackResponse['data'];
		} else {
			installExternalSkillpackResponse['data']['brain'] =
				await deploySkillAndUpdateRuntime({
					profileSlug: formData.profileSlug,
					brainId: formData.brainId,
				});
			return installExternalSkillpackResponse['data'];
		}
	} else {
		let installExternalSkillpackIPCFn, installExternalSkillpackResponse;

		if (!isElectron()) {
		} else {
			installExternalSkillpackIPCFn = IPCFunction({
				EVENT: IPCRoutes.ipcService.installExternalSkillpack.EVENT,
			});

			installExternalSkillpackResponse = await installExternalSkillpackIPCFn(
				{
					...formData,
				}
			);
			if (installExternalSkillpackResponse['data']['error']) {
				return installExternalSkillpackResponse['data'];
			}

			installExternalSkillpackResponse['data']['brain'] =
				await deploySkillAndUpdateRuntime({
					profileSlug: formData.profileSlug,
					brainId: formData.brainId,
				});

			return installExternalSkillpackResponse['data'];
		}
	}
};

export default installExternalSkillpack;
