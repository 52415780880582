import create, { GetState, SetState, StoreApi } from 'zustand';
import createStore from '../../utils/zustand';
import { logger } from '../../utils/zustand/loggerMiddleware';
import { StoreApp } from './types/Apps';
import { StoreCategory } from './types/Categories';

export type StoreEntity<T> = T & {
	_selected?: boolean;
};

export type StoreSectionZustandStore = {
	categories: {
		ids: string[];
		byId: {
			[key: string]: StoreEntity<StoreCategory>;
		};
	};
	apps: {
		ids: string[];
		byId: {
			[key: string]: StoreEntity<StoreApp>;
		};
	};
	type: string | null;
	query: string;
	onInit: (args: {
		categories: StoreEntity<StoreCategory>[];
		apps: StoreEntity<StoreApp>[];
		type: string | null;
		query: string;
	}) => void;
	onQueryChange: (query: string) => void;
	onTypeChange: (type: string) => void;
	onCategoryToggle: (categoryId: string, selected: boolean) => void;
	onAppsToggle: (appId: string, selected: boolean) => void;
};

/**
 * Vanilla Store section Zustand Store
 * - Can Use outside react.
 */
export const storeSectionZustandStore = createStore<
	StoreSectionZustandStore,
	SetState<StoreSectionZustandStore>,
	GetState<StoreSectionZustandStore>,
	StoreApi<StoreSectionZustandStore>
>((set, get) => ({
	categories: {
		ids: [],
		byId: {},
	},
	apps: {
		ids: [],
		byId: {},
	},
	type: null,
	query: '',
	onInit: ({ categories, apps, type, query }) => {
		set((state) => {
			state.categories = categories.reduce<typeof state.categories>(
				(acc, category) => {
					acc.byId[category.id] = category;
					acc.ids.push(category.id);
					return acc;
				},
				{ ids: [], byId: {} }
			);
			state.apps = apps.reduce<typeof state.apps>(
				(acc, app) => {
					acc.byId[app.id] = app;
					acc.ids.push(app.id);
					return acc;
				},
				{ ids: [], byId: {} }
			);
			state.type = type;
			state.query = query || '';
		});
	},
	onQueryChange: (query) => {
		set((state) => {
			state.query = query;
		});
	},
	onTypeChange: (type) => {
		set((state) => {
			state.type = type;
		});
	},
	onCategoryToggle: (categoryId, selected) => {
		set((state) => {
			const category = state.categories?.byId[categoryId];
			if (category) {
				state.categories.byId[categoryId]._selected = selected;
			}
		});
	},
	onAppsToggle: (appId, selected) => {
		set((state) => {
			const app = state.apps?.byId[appId];
			if (app) state.apps.byId[appId]._selected = selected;
		});
	},
}));

/**
 * # Use Store section Zustand Store, for use inside react
 */
const useStoreSectionZustandStore = create(storeSectionZustandStore);

export default useStoreSectionZustandStore;
