import React, { useCallback, useEffect, useState } from 'react';
import update from 'immutability-helper';
import installExternalModuleToBrain from '../../../functions/modules/installExternalModuleToBrain';
import { useStore } from '../zustand';
import useProfileSlug from '../../../hooks/useProfileSlug';
import { getModuleList } from '../Workspace/functions/NodeRed';
import InterstitialModuleInstallDrawer from '../Workspace/InterstitialModuleInstallDrawer';
import getInstallerFlow from '../../../functions/store/getInstallerFlow';
import installModuleToBrain from '../../../functions/modules/installModuleToBrain';
import setReferenceIdToConfigMap from '../../../functions/brain/setReferenceIdToConfigMap';
import getRedInstance from '../Workspace/functions/getRedInstance';
import RED_EVENTS from '../Workspace/functions/redEventListeners/events';
import {
	offNodeRedNodeTypeAdded,
	onNodeRedNodeTypeAdded,
} from '../Workspace/functions/redEventListeners';
import { resolve } from 'path';
import semver from 'semver';
import notionModule from './notionModule.json';
import useInstallModules from './useInstallModules';
import shallow from 'zustand/shallow';

/**
 * @type {import('../Workspace/InterstitialModuleInstallDrawer/types').IntModuleInstallProps}
 */
export const testState = {
	'node-red-node-mysql': {
		moduleName: 'node-red-node-mysql',
		status: 'QUEUED',
		privateModule: false,
		requiresConfig: false,
		version: '1.7.1',
	},
	'maya-red-notion': {
		moduleName: 'Notion Module',
		status: 'INSTALLING',
		// configProfileReferenceId: '',
		// isInternalModule: true,
		privateModule: notionModule,
		version: '1.0.4',
		// requiresConfig: true
	},
	// 'maya-red-spotify': {
	// 	moduleName: 'Spotify Module',
	// 	status: 'INSTALLED'
	// },
	// 'maya-red-gcalendar': {
	// 	moduleName: 'Google Calendar',
	// 	status: 'QUEUED'
	// }
};

const SECOND = 1000;
const NODE_ADDED_EVENT_TIMEOUT = 60 * SECOND;

const useInstallMissingModules = (onDone) => {
	const { modulesToInstall, modulesInstalling, installModules } =
		useInstallModules();

	const getDeployableMayaFlow = useStore(
		(state) => state.getDeployableMayaFlow,
		shallow
	);

	const installMissingModules = () => {
		return new Promise((resolve, reject) => {
			const promiseFn = async () => {
				let handler = () => null;

				try {
					const flow = getDeployableMayaFlow();
					const details = await getInstallerFlow(flow);
					const internalModuleMap = {};

					const nodeTypeMap = {};
					const RED = getRedInstance();

					// START :: Listening for node-type-added events
					// This needs to happen within this function so we can de-register the RED event
					// handler in case the function errors out

					const currentNodeTypes = RED.nodes.registry.getNodeTypes();
					const currentTypeMap = {};
					currentNodeTypes.forEach(
						(nType) => (currentTypeMap[nType] = true)
					);

					flow.forEach((node) => {
						if (!currentTypeMap[node.type]) {
							nodeTypeMap[node.type] = false;
						}
					});

					const nodeTypes = Object.keys(nodeTypeMap);

					handler = (arg) => {
						navigator.locks.request('verify_node_added', async () => {
							console.log('got event', arg)
							nodeTypeMap[arg] = true;
							const ready = nodeTypes.every(
								(nodeType) => nodeTypeMap[nodeType]
							);
							if (ready) {
								// If all the required nodes get registered, we resolve. We don't need
								// to wait for the rest of them (they'll get registered in a few milliseconds)
								// as well
								resolve(); // SUCCESS
								offNodeRedNodeTypeAdded(handler);
							}
						})
					};

					onNodeRedNodeTypeAdded(handler);

					// END :: Listening for node-type-added events

					try {
						const neededModules = flow
							.filter((node) => !!node.module)
							.map((node) => ({
								module: node.module,
								version: semver.clean(node.version, { loose: true }),
							}));

						details?.currentFlow?.modules
							.map((mod) => mod.module)
							.forEach((module) => {
								const version = semver.clean(module.currentVersion, {
									loose: true,
								});
								neededModules.push({
									module: module.packageName,
									version: semver.clean(module.currentVersion, {
										loose: true,
									}),
								});
								internalModuleMap[module.packageName] = module;
							});

						const availableModules = getModuleList();

						/**
						 * @type {import('../Workspace/InterstitialModuleInstallDrawer/types').IntModuleInstallProps}
						 */
						const modules = {};
						neededModules.forEach(({ module: modName, version }) => {
							if (!availableModules[modName]) {
								if (internalModuleMap[modName]) {
									const privateModule = internalModuleMap[modName];
									const requiresConfig =
										privateModule.userProfile &&
										!!privateModule.configurationType;
									modules[modName] = {
										moduleName: privateModule.name,
										version,
										status: 'QUEUED',
										configProfileReferenceId: '',
										isInternalModule: true,
										privateModule: privateModule,
										requiresConfig: requiresConfig,
									};
								} else {
									modules[modName] = {
										moduleName: modName,
										version,
										status: 'QUEUED',
									};
								}
							}
						});

						if (Object.keys(modules).length > 0) {
							await installModules(modules);
							setTimeout(() => {
								const error = new Error('Event polling timed out');
								error.name = 'POLL_TIMEOUT';
								offNodeRedNodeTypeAdded(handler);
								reject(error);
							}, NODE_ADDED_EVENT_TIMEOUT);
						} else {
							resolve(); // SUCCESS
							offNodeRedNodeTypeAdded(handler);
						}
					} catch (e) {
						// Something failed during module install
						reject();
						offNodeRedNodeTypeAdded(handler);
					}
				} catch (e) {
					// Something failed in the setup
					reject(e);
					offNodeRedNodeTypeAdded(handler);
				}
			};

			// To avoid making the promise callback an async function
			promiseFn();
		});
	};

	return {
		installMissingModules,
		modulesInstalling,
		modulesToInstall,
	};
};

export default useInstallMissingModules;
