export function createUIMap(flow) {
    const map = {}
    flow.filter(node => node.type.startsWith('dashboard-')).forEach(node => {
        try {
            if (node.type === 'dashboard-group') {
                if (!map[node.id]) {
                    map[node.id] = {
                        groupNode: node,
                        childNodes: []
                    }
                } else {
                    map[node.id].groupNode = node
                }
            } else {
                if (!map[node.group]) {
                    map[node.group] = {}
                }
    
                if (!map[node.group].childNodes) {
                    map[node.group].childNodes = []
                }
    
                map[node.group].childNodes.push(node)
            }

        } catch (e) {
            // Just won't render any node that does not fit in the requirements
        }
    })

    console.log('uiMap', map)

    return map
}