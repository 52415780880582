/**
 * Root Reducer
 *
 * Creates a Redux root reducer for handling all reducer actions in the
 * application.
 */

// Dependencies
import { combineReducers } from 'redux';

// Reducers

import user from './userReducer';
import devices from './deviceReducer';
import brains from './brainReducer';
import subscriptions from './subscriptionsReducer';
import navigation from './navigationReducer';
import update from './updateReducer';
import store from './storeReducer';
import profiles from './profileReducer';
import supportWidget from './supportWidgetReducer';
import commandHistory from './commandHistoryReducer';
import publishedSkillPacks from './publishedSkillPackReducer';
import modules from './moduleReducer';
import task from './taskReducer';
import cardUIV2 from './cardUIV2Reducer';
import { createFilter } from 'redux-persist-transform-filter';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';

/**
 * This is to allow persisting of nested fields such as `cardUIV2.entities.cards`
 * in local storage with the keyName (local storage) set to `cardUIV2`
 */
const persistedCardUIV2 = persistReducer(
	{
		key: 'cardUIV2',
		storage,
		stateReconciler: autoMergeLevel2,
		whitelist: ['entities'],
		transforms: [createFilter('entities', ['cards'])],
	},
	cardUIV2
);

// Export the Redux reducer.
export default combineReducers({
	devices,
	user,
	brains,
	publishedSkillPacks,
	modules,
	navigation,
	update,
	store,
	profiles,
	supportWidget,
	commandHistory,
	task,
	cardUIV2: persistedCardUIV2,
	subscriptions,
});
