import _ from 'lodash';

/**
 * The newTabIdGenerator function generates a 16 digit tab id.
 * @return A string of 16 random alphanumeric characters.
 */
export const newTabIdGenerator = () => {
	const charsToBeUsed =
		'1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
	let ans = '';
	for (let i = 16; i > 0; i--) {
		ans += charsToBeUsed[Math.floor(Math.random() * charsToBeUsed.length)];
	}
	return ans;
};

const newTab = (tabId, nextTabLabelNum) => {
	return {
		disabled: false,
		mayaFlowByTab: [],
		reactFlowByTab: [],
		id: tabId || newTabIdGenerator(),
		info: '',
		label: `Flow ${nextTabLabelNum || 1}`,
		type: 'tab',
	};
};

export const getFlowByTabs = (raw) => {
	const tabs = [];
	if (raw.length === 0) {
		tabs.push(newTab());
		return tabs;
	}
	_.forEach(raw, function (o) {
		if (o.type === 'tab' || o.type === 'subflow') {
			tabs.push({
				...o,
				mayaFlowByTab: [],
				reactFlowByTab: [],
			});
		}
	});

	_.forEach(raw, function (node) {
		if (node.type !== 'tab' || node.type !== 'subflow') {
			_.forEach(tabs, (tab) => {
				if (tab.id === node.z) {
					const nodeIndex = tab.mayaFlowByTab.findIndex(
						(i) => i.id === node.id
					);
					if (nodeIndex !== -1) {
						tab.mayaFlowByTab[nodeIndex] = node;
					} else {
						tab.mayaFlowByTab.push(node);
					}
				}
			});
		}
	});
	return tabs;
};

export const getMayaFlowByTab = (flow, tabId) => {
	return _.filter(flow, (n) => n.z === tabId);
};

/** Converting the MayaLang graph to a react-flow graph. */
export const parseMayaLangToReactFlow = (nodeRedFlowDef, scalePosition = 1) => {
	const nodes = [],
		edges = [];
	_.forEach(nodeRedFlowDef, (curr) => {
		if (typeof curr.x === 'number' && typeof curr.y === 'number') {
			nodes.push({
				id: curr.id,
				type: 'special',
				data: {
					type: curr.type,
					node: curr,
				},
				position: {
					x: curr.x * scalePosition,
					y: curr.y * scalePosition,
				},
				sourcePosition: 'right',
				targetPosition: 'left',
			});
			if (Array.isArray(curr.wires) && curr.wires.length > 0) {
				curr.wires.forEach((outputArray, idx) => {
					if (Array.isArray(outputArray) && outputArray.length > 0) {
						const outWires = outputArray.map((wireId) => ({
							id: `e${curr.id}-${wireId}`,
							source: curr.id,
							target: wireId,
							sourceHandle: idx.toString(),
							// targetHandle: Not required because target handle will always be 1
						}));

						edges.push(...outWires);
					}
				});
			}
		}
	});
	return { nodes, edges };
};

/** Converting the react-flow graph to a MayaLang graph. */
export const parseReactFlowtoMayaLang = ({ nodes, edges }) => {
	var flows = [];

	for (let node of nodes) {
		const wires = [];
		const outputs = node.data.node.wires
			? node.data.node.wires.length
			: node.data.node.outputs || 1;
		for (let i = 0; i < outputs; i++) {
			wires.push([]);
		}
		const curr = {
			...node.data.node,
			x: node.position.x,
			y: node.position.y,
			id: node.id,
			infoEditor: undefined,
			wires: wires,
		};

		for (let edge of edges) {
			if (edge.source && edge.target && edge.source === curr.id) {
				const sourceId = edge.sourceHandle || 0;
				curr.wires[parseInt(sourceId)].push(edge.target);
			}
		}

		flows.push(curr);
	}
	return flows;
};

/**
 * Check if flow is valid
 * @param {*} flow
 */
export const isFlowValid = (flow) => {
	return !_.some(flow, { valid: false });
};
