/**
 * User Reducer
 *
 * Creates a Redux reducer for handling user actions.
 */

// Action Types
import actionTypes from '../actionTypes';

// Setup initial state with an empty user info object.
const initialState = {
	status: 'offline',
	info: null,
	id: '',
	tokens: {
		accessToken: '',
		refreshToken: '',
		lastUpdated: 0,
	},
};

// Export the User Reducer.
export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_USER: {
			const { info } = action.payload;
			if (info) {
				return {
					...state,
					// status: 'online',
					info,
				};
			} else {
				return state;
			}
		}
		case actionTypes.LOGOUT_USER: {
			return {
				...state,
				status: 'offline',
				info: null,
				tokens: {
					accessToken: '',
					refreshToken: '',
					lastUpdated: 0,
				},
			};
		}
		case actionTypes.SET_STATUS: {
			return {
				...state,
				status: action.payload,
			};
		}
		case actionTypes.SET_AUTH_TOKENS: {
			return {
				...state,
				status: 'online',
				tokens: {
					accessToken: action.payload.accessToken,
					refreshToken: action.payload.refreshToken,
					lastUpdated: action.payload.lastUpdated,
				},
			};
		}
		default:
			return state;
	}
};
