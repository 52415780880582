import React, { useState, useEffect } from 'react';
import { Box, createStandaloneToast, Flex } from '@chakra-ui/react';
import developerKey from '../../../../../functions/developer/developerKey';
import SecretKey from './SecretKey';
import KeyGenerationButton from './KeyGenerationButton';
import axios from 'axios';
import { config } from '../../../../../config';
import useProfileSlug from '../../../../../hooks/useProfileSlug';
import theme from '../../../../../library/theme';
import SettingsItem from '../SettingsItem';
import { MayaSkeleton } from '@mayahq/ui';
import analytics from '../../../../../../src/util/Analytics';

const toast = createStandaloneToast({ theme: theme });
const REACT_APP_AUTH_SERVICE_BASEURL =
	config.authservice.BASEURL || 'https://authservice.dev.mayalabs.io';

const Heading = ({ developerAccess }) => {
	return (
		<Box>
			<Box
				color="light.font.gray.400"
				paddingBottom={!developerAccess ? '0px' : '1.5rem'}
				textStyle="sans.md"
			>
				Developer Access
			</Box>
			<Box
				color="light.font.gray.300"
				marginY={!developerAccess ? '1rem' : '0rem'}
				paddingBottom={!developerAccess ? '0px' : '8px'}
				textStyle="sans.xs"
			>
				{!developerAccess
					? 'To receive developer access to Maya, complete this form by clicking the button below.'
					: 'API KEY'}
			</Box>
		</Box>
	);
};

const Developers = () => {
	const track = analytics.track;
	const {
		getDeveloperKey,
		generateDeveloperKey,
		regenerateDeveloperKey,
		revokeDeveloperKey,
	} = developerKey;

	const profileSlug = useProfileSlug();
	const [revokekey, setRevokeKey] = useState(false);
	// defined a state for now to test the UI when developer will not have access
	const [developerAccess, setDeveloperAccess] = useState(true);
	// trigging the generateDeveloperKey on developers page load
	const [secretKey, setSecretKey] = useState('');
	const [buttonLoading, setButtonLoading] = useState(false);
	const [keyVisible, setKeyVisible] = useState(false);
	const [initialRequestCompleted, setInitialRequestCompleted] =
		useState(false);

	// generating the develope key on initial page load
	useEffect(() => {
		console.log('keyy bruh', REACT_APP_AUTH_SERVICE_BASEURL);
		axios({
			url: `${REACT_APP_AUTH_SERVICE_BASEURL}/key/get/${profileSlug}`,
			method: 'get',
		})
			.then((res) => {
				console.log('keyy res', res);
				const key = res.data.key;
				if (key) {
					setSecretKey(key);
					setInitialRequestCompleted(true);
				}
			})
			.catch((e) => {
				setInitialRequestCompleted(true);
				console.error(e);
			});
	}, [profileSlug]);

	function handleKeyGenerateButtonClick() {
		track(`[Workspace] Settings > Developer Section > API Key generated`);
		setButtonLoading(true);
		axios({
			url: `${REACT_APP_AUTH_SERVICE_BASEURL}/key/generate`,
			method: 'post',
			data: {
				profileSlug: profileSlug,
			},
		})
			.then((res) => {
				setButtonLoading(false);
				console.log('keyy res', res.data);
				setSecretKey(res.data.key);
				setKeyVisible(true);
				toast({
					title: 'Key generated successfully!',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
			})
			.catch((e) => {
				setButtonLoading(false);
				toast({
					title: 'Error',
					description:
						e?.response?.data?.message ||
						'There was an unknown error creating the key, please contact the developer',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
				console.error('keyy error', e);
			});
	}

	return (
		<Flex
			direction="column"
			bg="white"
			border="1px solid"
			borderColor={'light.border.gray.200'}
			padding="20px"
		>
			{!initialRequestCompleted ? (
				<SettingsItem
					text={<MayaSkeleton w="10rem" h="1.5rem" />}
					description={<MayaSkeleton w="25rem" h="1rem" mt="2" />}
					rightItem={
						<>
							<MayaSkeleton w="5rem" h="1.5rem" mr="4" />
						</>
					}
				/>
			) : (
				<>
					<Heading developerAccess={developerAccess} />
					{developerAccess && (
						<SecretKey
							secretKey={secretKey}
							keyVisible={keyVisible}
							setKeyVisible={setKeyVisible}
						/>
					)}
					<Box mt="16px">
						<KeyGenerationButton
							keyExists={!!secretKey}
							developerAccess={developerAccess}
							onClick={handleKeyGenerateButtonClick}
							isLoading={buttonLoading}
						/>
					</Box>
				</>
			)}
		</Flex>
	);
};

export default Developers;
