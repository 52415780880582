// Dependency imports

// import worker from 'workerize-loader!../../../src/maya-service-worker'; // eslint-disable-line import/no-webpack-loader-syntax

import APIFunction from '../../util/APIFetch/function';

import APIRoutes from '../../util/APIRoutes';

import { updateProfile } from '../../redux/actions/profile';
// config
import { config } from '../../config';
import GetProfileSlug from '../../util/ProfileSlug';
//redux
import { store } from '../../redux/store';

/**
 * Update profile acc to slug
 * @param {import("./types/index").UpdateMetadata} param0
 * @returns
 */
const updateMetadata = async ({ metadata, slug }) => {
	// Create an instance of your worker
	const { user } = store.getState();

	const profileSlug = slug ? slug : GetProfileSlug().slug;
	var formData = {
		profileSlug: profileSlug ? profileSlug : user.info.id,
		metadata,
	};
	var updateProfileMetadataFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.updateProfileMetadata.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.updateProfileMetadata.PATH_METHOD,
	});

	var updateProfileMetadataResponse = await updateProfileMetadataFn(formData);

	var updateItem = {
		email: updateProfileMetadataResponse['data'].status.email,
		exp: updateProfileMetadataResponse['data'].status.exp,
		icon: updateProfileMetadataResponse['data'].status.icon,
		metadata: updateProfileMetadataResponse['data'].status.metadata,
		name: updateProfileMetadataResponse['data'].status.name,
		profileSlug: updateProfileMetadataResponse['data'].status.profileSlug,
		tier: updateProfileMetadataResponse['data'].status.tier,
		trialDays: updateProfileMetadataResponse['data'].status.trialDays,
		_id: updateProfileMetadataResponse['data'].status._id,
		checklistItems: updateProfileMetadataResponse['data'].status.checklistItems,
		username: updateProfileMetadataResponse['data'].status.username,
		region: updateProfileMetadataResponse['data'].status.region
	};
	console.log('PROFILE UPDATED:', updateItem);
	store.dispatch(updateProfile(formData.profileSlug, updateItem));
	return updateProfileMetadataResponse['data'];
};

export default updateMetadata;
