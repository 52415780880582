import { Text, Flex, Box } from '@chakra-ui/react';
import { useCallback } from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { IoExtensionPuzzleOutline } from 'react-icons/io5';
import { useStore } from '../../zustand';
import { getNodeById } from '../functions/NodeRed';
import React from 'react';

const MissingFieldCard = ({ node }) => {
	const onCommandFocus = useStore(
		useCallback((state) => state.onCommandFocus, [])
	);

	const setDoubleClickedNode = useStore(
		useCallback((state) => state.setDoubleClickedNode, [])
	);

	const editNodeProperties = useCallback((node) => {
		setDoubleClickedNode(node);
		if (node.type === 'special') {
			// add node to moving set
			window.RED.view.movingSet.clear();
			const doubleClickedNode = getNodeById(node.id);
			window.RED.view.movingSet.add(doubleClickedNode);
			// invoke drawer open
			window.RED.actions.invoke('core:edit-selected-node');
		} else {
		}
	}, []);

	const onMissingFieldClick = (selectedNode) => {
		console.log(selectedNode);
		editNodeProperties(selectedNode);
		onCommandFocus({
			shouldCenter: true,
			x: selectedNode.data.node.x,
			y: selectedNode.data.node.y,
			zoom: 1.2,
		});
	};

	return (
		<Flex
			justifyContent="space-between"
			backgroundColor="#ffffff"
			minHeight="70px"
			maxHeight="70px"
			alignItems="center"
			border="1px solid #DEDCDC"
			borderRadius="2px"
			padding="1rem"
			marginBottom="10px"
			cursor="pointer"
			onClick={() => onMissingFieldClick(node)}
		>
			<Flex alignItems="center">
				<Flex shrink={1}>
					<IoExtensionPuzzleOutline size="22px" color="#585858" />
				</Flex>
				<Box marginX="10px">
					<Text
						textStyle="sans.sm"
						marginBottom="0 !important"
						noOfLines={1}
					>
						{node.data.node.name
							? `${node.data.node.name}`
							: `${node.data.node.type}`}
					</Text>
					<Text
						color="#939393"
						fontSize={12}
						fontWeight="medium"
						marginBottom="0 !important"
					>
						REQUIRED FIELD MISSING
					</Text>
				</Box>
			</Flex>
			<Flex shrink={1}>
				<AiOutlineEye size="22px" color="#3a3a3a" />
			</Flex>
		</Flex>
	);
};

export default MissingFieldCard;
