// TODO: deprecated
function addNodeOld(type, x, y, tabId) {
	var m = /^subflow:(.+)$/.exec(type);
	var node_width = 100;
	var node_height = 30;

	// if (activeSubflow && m) {
	// 	var subflowId = m[1];
	// 	if (subflowId === activeSubflow.id) {
	// 		RED.notify(
	// 			RED._('notification.error', {
	// 				message: RED._('notification.errors.cannotAddSubflowToItself'),
	// 			}),
	// 			'error'
	// 		);
	// 		return;
	// 	}
	// 	// if (RED.nodes.subflowContains(m[1], activeSubflow.id)) {

	// 	// 	return;
	// 	// }
	// }

	var nn = {
		id: window.RED.nodes.id(), // TODO: @madhur should we remove this?
		// z: window.RED.workspaces.active()
		z: tabId,
	};

	nn.type = type;
	nn._def = window.RED.nodes.getType(nn.type);

	if (!m) {
		nn.inputs = nn._def.inputs || 0;
		nn.outputs = nn._def.outputs;

		for (var d in nn._def.defaults) {
			if (nn._def.defaults.hasOwnProperty(d)) {
				if (nn._def.defaults[d].value !== undefined) {
					nn[d] = JSON.parse(JSON.stringify(nn._def.defaults[d].value));
				}
			}
		}

		if (nn._def.onadd) {
			try {
				nn._def.onadd.call(nn);
			} catch (err) {
				console.log('Definition error: ' + nn.type + '.onadd:', err);
			}
		}
	} else {
		var subflow = window.RED.nodes.subflow(m[1]);
		nn.name = '';
		nn.inputs = subflow.in.length;
		nn.outputs = subflow.out.length;
	}

	nn.changed = true;
	nn.moved = true;

	nn.w = node_width;
	nn.h = Math.max(node_height, (nn.outputs || 0) * 15);
	nn.resize = true;
	nn._ = nn._def._;
	var historyEvent = {
		t: 'add',
		nodes: [nn.id],
		dirty: window.RED.nodes.dirty(),
	};
	// if (activeSubflow) {
	// 	var subflowRefresh = RED.subflow.refresh(true);
	// 	if (subflowRefresh) {
	// 		historyEvent.subflow = {
	// 			id: activeSubflow.id,
	// 			changed: activeSubflow.changed,
	// 			instances: subflowRefresh.instances,
	// 		};
	// 	}
	// }
	return {
		node: nn,
		historyEvent: historyEvent,
	};
}

function addNode(type, x, y, tabId, nodeId, wires) {
	const RED = window.RED;
	var m = /^subflow:(.+)$/.exec(type);
	var node_width = 100;
	var node_height = 30;

	//  if (activeSubflow && m) {
	// 	  var subflowId = m[1];
	// 	  if (subflowId === activeSubflow.id) {
	// 			RED.notify(RED._("notification.error",{message: RED._("notification.errors.cannotAddSubflowToItself")}),"error");
	// 			return;
	// 	  }
	// 	  if (RED.nodes.subflowContains(m[1],activeSubflow.id)) {
	// 			RED.notify(RED._("notification.error",{message: RED._("notification.errors.cannotAddCircularReference")}),"error");
	// 			return;
	// 	  }
	//  }

	var nn = { id: nodeId, z: tabId || RED.workspaces.active() };
	nn.type = type;
	nn._def = RED.nodes.getType(nn.type);
	if (!m) {
		nn.inputs = nn._def.inputs || 0;
		nn.outputs = nn._def.outputs;

		for (var d in nn._def.defaults) {
			if (nn._def.defaults.hasOwnProperty(d)) {
				if (nn._def.defaults[d].value !== undefined) {
					nn[d] = JSON.parse(JSON.stringify(nn._def.defaults[d].value));
				}
			}
		}
		if (nn._def.onadd) {
			try {
				nn._def.onadd.call(nn);
			} catch (err) {
				console.log('Definition error: ' + nn.type + '.onadd:', err);
			}
		}
	} else {
		var subflow = RED.nodes.subflow(m[1]);
		nn.name = '';
		nn.inputs = subflow.in.length;
		nn.outputs = subflow.out.length;
	}

	nn.changed = true;
	nn.moved = true;

	nn.w = node_width;
	nn.h = Math.max(node_height, (nn.outputs || 0) * 15);
	nn.resize = true;

	var historyEvent = {
		t: 'add',
		nodes: [nn.id],
		dirty: RED.nodes.dirty(),
	};
	//  if (activeSubflow) {
	// 	  var subflowRefresh = RED.subflow.refresh(true);
	// 	  if (subflowRefresh) {
	// 			historyEvent.subflow = {
	// 				 id:activeSubflow.id,
	// 				 changed: activeSubflow.changed,
	// 				 instances: subflowRefresh.instances
	// 			}
	// 	  }
	//  }

	var showLabel = RED.utils.getMessageProperty(
		RED.settings.get('editor'),
		'view.view-node-show-label'
	);
	if (
		showLabel !== undefined &&
		!/^link (in|out)$/.test(nn._def.type) &&
		!nn._def.defaults.hasOwnProperty('l')
	) {
		nn.l = showLabel;
	}

	nn.x = x;
	nn.y = y;
	if (wires) {
		nn.wires = wires;
	}

	RED.nodes.add(nn);

	RED.history.push(historyEvent);
	RED.editor.validateNode(nn);
	RED.nodes.dirty(true);

	if (nn._def.autoedit) {
		RED.editor.edit(nn);
	}

	return {
		node: nn,
		historyEvent: historyEvent,
	};
}

export default addNode;
