import { config } from '../../config';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';
import GetProfileSlug from '../../util/ProfileSlug';

/**
 * # Stripe Create Customer Portal Session
 *
 * - Creates Stripe customer portal session and returns the url for the
 *  customer payments portal.
 */
const createStripePortalAndReturnUrl = APIFunction({
	BASEURL: config.apiServer.BASEURL,
	PATH_SEARCH:
		APIRoutes.apiService.stripeCreateCustomerPortalSession.PATH_SEARCH,
	PATH_METHOD:
		APIRoutes.apiService.stripeCreateCustomerPortalSession.PATH_METHOD,
});

/**
 * # Manage Payments Portal
 *
 * - Sends a request to manage payments portal and returns a url to the portal
 * - `returnUrl`: Url to return to app from the portal
 *
 * @param {{
 * 	returnUrl: string,
 * }} param0
 * @returns
 */
const managePaymentsPortal = async ({ returnUrl }) => {
	const { slug: profileSlug } = GetProfileSlug();
	const res = await createStripePortalAndReturnUrl({ profileSlug, returnUrl });
	/**
	 * @type {string | undefined}
	 */
	const url = res.data?.url;
	return url;
};

export default managePaymentsPortal;
