import React, { useCallback } from 'react';
import { Box } from '@chakra-ui/layout';
import OptionBox from '../../../../library/select/OptionBox/Box';
import SectionHeader from '../../../../library/text/SectionHeader';
import MayaFilledButton from '../../../../library/buttons/FilledButton';
import _ from 'lodash';
import produce from 'immer';
import ActionBtn from '../../../../library/buttons/ActionBtn';

import { ArrowForwardIcon } from '@chakra-ui/icons';
import useStepperStore from '../../StepperStore';
import updateMetadata from '../../../../functions/profile/updateMetadata';
import useStepperAnalytics from '../../hooks/useStepperAnalytics';
import { stepperFlowTypes } from '../registry';
import { useSelector } from 'react-redux';

var describeOptions = [
	{
		label: 'Connect two or more apps',
	},
	{
		label: 'Scrape or navigate the web',
	},
	{
		label: 'Forms, charts,  buttons & sliders',
	},
	{
		label: 'Enter data into spreadsheets',
	},
	{
		label: 'Automate social media apps',
	},
	{
		label: 'Monitor data from a source',
	},
	{
		label: 'Repeated at regular intervals',
	},
	{
		label: 'Run manually when required',
	},
	{
		label: 'Triggered by external event',
	},
];

/**
 * Select some descriptions of task you want automated
 * from a 3*3 grid.
 *
 * Design reference : https://www.figma.com/file/KAF1ostHf3VBeZsctGl3ZA/MayaDesign?node-id=2440%3A2871
 * @returns
 */
const DescriptionSelection = () => {
	useStepperAnalytics()
	const { updateStepState, addStep, nextStep, currentStep, steps } = useStepperStore(
		(state) => {
			return {
				updateStepState: state.updateStepState,
				addStep: state.addStep,
				nextStep: state.nextStep,
				currentStep: state.currentStep,
				steps: state.steps
			};
		}
	);
	const currentSectionId = useStepperStore(
		useCallback((state) => state.steps.allIds[currentStep], [currentStep])
	);
	const currentStepState = useStepperStore(
		useCallback(
			(state) =>
				state.steps.byId[currentSectionId]
					? state.steps.byId[currentSectionId].state
					: {},
			[currentSectionId]
		)
	);

	const { userSlug } = useSelector(
		/**
		 * 
		 * @param {import('../../../../redux/reducers/types/profileReducer').ProfilesState} state 
		 * @returns 
		 */
		state => ({
			userSlug: state.profiles.userSlug
		})
	)

	const [items, setItems] = React.useState({ state: currentStepState });

	const findItem = (label) => {
		var value = _.get(items, `state.${label}`);
		return value;
	};
	const addItem = (label) => {
		var newState = produce((draft) => {
			draft.state[label] = true;
			return draft;
		}, items);
		setItems(newState);
	};

	const removeItem = (label) => {
		var newState = produce((draft) => {
			delete draft.state[label];
			return draft;
		}, items);

		setItems(newState);
	};

	const changeState = () => {
		updateStepState(items);
	};

	const goToNextStep = useCallback(() => {
		updateStepState(items)
		const currentStep = steps.byId['onboarding.describeAutomation']
		const currentState = currentStep.state
		const { stepperFlowType, signupMetadata } = currentState

		const nextStepState = {
			...currentState,
			describeAutomation: currentStep,
		}
		switch (stepperFlowType) {
			case stepperFlowTypes.onboarding.NORMAL: {
				updateMetadata({
					slug: userSlug,
					metadata: {
						onboardingData: {
							describeAutomation: items
						}
					}
				})
				addStep('onboarding.appSelection', nextStepState)
				break
			}
			case stepperFlowTypes.onboarding.INSTALL_FROM_QUICK_SHARE: {
				updateMetadata({
					slug: userSlug,
					metadata: {
						onboarded: true,
						onboardingData: {
							describeAutomation: currentStep
						},
					}
				})
				nextStepState.flowToInstall = signupMetadata?.data?.flow
				nextStepState.quickshareLink = signupMetadata?.data?.link
				addStep('onboarding.quickShareInstall', nextStepState)
				break
			}
			default: throw new Error(`Got invalid stepperFlowType: ${stepperFlowType}`)
		}
		nextStep()
	}, [steps, items])

	const stepperFlowType = steps.byId['onboarding.describeAutomation']?.state?.stepperFlowType
	const headings = {
		'onboarding.normal': 'What do you need automated?',
		'onboarding.installFromQuickShare': 'Before we continue'
	}
	const subheadings = {
		'onboarding.normal': 'Select any of the boxes that best describe the task(s) you wish to automate.',
		'onboarding.installFromQuickShare': 'Please select any of the boxes that describe the task(s) you wish to automate.'
	}

	return (
		<Box
			display="flex"
			flexDir="column"
			justifyContent="center"
			alignItems="center"
		>
			<Box paddingTop="20px" paddingBottom="40px">
				<SectionHeader
					heading={headings[stepperFlowType]}
					subheading={subheadings[stepperFlowType]}
				/>
			</Box>
			<Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gridGap="7px">
				{describeOptions.map((item) => {
					return (
						<OptionBox
							selectMode="multiple"
							text={item.label}
							key={item.label}
							selected={findItem(item.label)}
							onClickFn={(label) => {
								if (findItem(label)) {
									removeItem(label);
								} else {
									addItem(label);
								}
							}}
						/>
					);
				})}
			</Box>
			<Box mt="50px">
				<MayaFilledButton
					text="Continue"
					buttonProps={{
						width: '200px',
					}}
					onClick={goToNextStep}
					rightIcon={<ArrowForwardIcon />}
				/>
			</Box>

			{Object.keys(items.state).length > 0 ? (
				<Box mt="40px">
					<ActionBtn
						text="CLEAR"
						onClick={() => {
							setItems({ state: {} });
						}}
					/>
				</Box>
			) : null}
		</Box>
	);
};

const sectionObject = {
	heading: 'onboarding',
	component: <DescriptionSelection />,
};

export default sectionObject;
