// Import modules
import History from './History';
//redux
import { store } from '../../redux/store';

const HistoryWithSlug = {};

HistoryWithSlug.push = (route) => {
	var { profiles: profilesState } = store.getState();
	var { selected, profiles } = profilesState;
	const selectedProfile = profiles[selected]
	if (route.includes('store')) {
		History.push(route);
	} else {
		if (selectedProfile.isTeam) {
			History.push('/_/' + selectedProfile.profileSlug + route);
		} else {
			History.push(route);
		}
	}
};

HistoryWithSlug.replace = (route) => {
	var { profiles: profilesState } = store.getState();
	var { selected, profiles } = profilesState;
	const selectedProfile = profiles[selected]

	if (route.includes('store')) {
		History.replace(route);
	} else {
		if (selectedProfile.isTeam) {
			History.replace('/_/' + selectedProfile.profileSlug + route);
		} else {
			History.replace(route);
		}
	}
};

export default HistoryWithSlug;
