import React, { useRef, useEffect, useCallback } from 'react';
import {
	Box,
	Textarea,
	Button,
	Tooltip,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Flex,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import useStepperStore from '../../../../../Stepper/StepperStore';
import GetProfileSlug from '../../../../../../util/ProfileSlug';
import { useSelector } from 'react-redux';
import { HiOutlineSupport, HiOutlineMail } from 'react-icons/hi';
import { FaDiscord, FaTelegram } from 'react-icons/fa';
import { AiOutlinePhone } from 'react-icons/ai';

//Functions
import submitFeedback from '../../../../../../functions/misc/submitFeedback';
import analytics from '../../../../../../util/Analytics';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, onClick) {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				onClick();
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
}

const EmojiBox = ({ text, emoji, selectedEmoji, setSelectedEmoji }) => {
	return (
		<Tooltip label={text}>
			<Box
				transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
				onClick={() => setSelectedEmoji(text)}
				cursor="pointer"
				mr="5px"
				p="7px"
				border="solid 1px"
				borderColor={
					selectedEmoji === text ? '#8a8a8a' : 'light.border.gray.200'
				}
				height="30px"
				width="30px"
				borderRadius="20px"
				justifyContent="center"
				alignItems="center"
				display="flex"
			>
				{emoji ? emoji : '🤩'}
			</Box>
		</Tooltip>
	);
};

const emojiList = [
	{
		text: 'delighted',
		emoji: '🤩',
	},
	{
		text: 'happy',
		emoji: '😀',
	},
	{
		text: 'confused',
		emoji: '😕',
	},
	{
		text: 'frustrated',
		emoji: '😭',
	},
];

const FeedbackBtn = ({ mode = '' }) => {
	const track = analytics.track;
	const [visible, setVisible] = React.useState(false);
	const [selectedEmoji, setSelectedEmoji] = React.useState('');
	const [isLoading, setLoading] = React.useState(false);
	const [submitted, setSubmitted] = React.useState(false);
	const [feedback, setFeedback] = React.useState('');
	const wrapperRef = useRef(null);
	const onClose = () => {
		setLoading(false);
		setVisible(false);
		setSelectedEmoji('');
		setSubmitted(false);
		setFeedback('');
	};
	useOutsideAlerter(wrapperRef, () => onClose());

	/**
	 * For Stepper only
	 */
	const { updateStepState, addStep, nextStep, currentStep, steps } =
		useStepperStore((state) => {
			return {
				updateStepState: state.updateStepState,
				addStep: state.addStep,
				nextStep: state.nextStep,
				currentStep: state.currentStep,
				steps: state.steps,
			};
		});
	const currentSectionId = useStepperStore(
		useCallback((state) => state.steps.allIds[currentStep], [currentStep])
	);
	const currentStepState = useStepperStore(
		useCallback(
			(state) =>
				state.steps.byId[currentSectionId]
					? state.steps.byId[currentSectionId].state
					: {},
			[currentSectionId]
		)
	);

	const user = useSelector(
		/**
		 *
		 * @param {import("../../../../../../redux/reducers/types/userReducer").UserState} state
		 * @returns
		 */
		(state) => state.user
	);

	/**
	 * Submit Feedback
	 */
	const handleSubmit = () => {
		const { slug } = GetProfileSlug();
		setLoading(true);
		/**
		 *
		 */
		var formData = {
			feedback: feedback,
			emoji: selectedEmoji,
			href: window.location.href,
			userInfo: user.info,
			slug: slug,
			mode: mode,
			metaData:
				mode === 'stepper'
					? {
							currentSectionId,
							currentStepState,
					  }
					: {},
		};
		console.log(formData);
		submitFeedback(formData).then((res) => {
			setLoading(false);
			setSubmitted(true);
			setTimeout(() => {
				onClose();
			}, 2000);
		});
		// setTimeout(()=>{

		// }, 1000)
	};
	return (
		<>
			<Box
				bg="light.theme.gray.100"
				color="light.font.gray.200"
				textStyle="sans.xs"
				height="27px"
				paddingX="10px"
				border="solid 1px"
				borderColor="light.border.gray.200"
				display="flex"
				borderRadius="2px"
				justifyContent="center"
				alignItems="center"
				cursor="pointer"
				mt="1"
				mr="4"
				onClick={() => {
					if (!visible) {
						track(`[Editor] Support Button > Open`);
						setVisible(true);
					}
				}}
				zIndex="1000"
				userSelect="none"
			>
				<HiOutlineSupport />
				<Box ml="2">support</Box>
			</Box>
			<Modal
				isOpen={visible}
				onClose={() => {
					setVisible(false);
				}}
				isCentered
				size="sm"
				bg="#F7F6F3"
				// {...modalProps}
			>
				<ModalOverlay />
				<ModalContent height="220px" borderRadius="2px">
					<ModalCloseButton _focus={{ boxShadow: 'none' }} />
					<ModalBody
						bg="#F7F6F3"
						flex="1"
						minHeight="0"
						display="flex"
						flexDirection="column"
						p="0"
						borderRadius={'2px'}
						height="220px"
					>
						<Flex direction="column" flex="1" minHeight="0" p="4">
							<Box
								textStyle="serif.md"
								fontWeight="500"
								marginBottom="0.5rem"
								fontSize={'18px'}
							>
								Get Support
							</Box>
							<Box
								fontWeight="medium"
								opacity="0.6"
								textStyle="sams.md"
								borderRadius="2px"
								mt="8px"
							>
								<Box display="flex" width="100%">
									<Box
										border="solid 1px"
										borderColor="gray.300"
										padding="20px"
										width="47%"
										cursor="pointer"
										as="a"
										href={'https://mayalabs.io/discord'}
										target="_blank"
										rel="noopener noreferrer"
										display={'flex'}
										justifyContent="center"
										alignItems={'center'}
										_hover={{
											borderColor: 'gray.400',
										}}
										transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
										borderRadius={'2px'}
										bg="white"
									>
										<FaDiscord />
										<Box ml="2">Discord</Box>
									</Box>
									<Box
										border="solid 1px"
										borderColor="gray.300"
										padding="20px"
										ml="10px"
										width="47%"
										cursor="pointer"
										as="a"
										href={'https://t.me/sibeshkar'}
										target="_blank"
										rel="noopener noreferrer"
										display={'flex'}
										justifyContent="center"
										alignItems={'center'}
										_hover={{
											borderColor: 'gray.400',
										}}
										transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
										borderRadius={'2px'}
										bg="white"
									>
										<FaTelegram />
										<Box ml="2">Telegram</Box>
									</Box>
								</Box>
								<Box display="flex" width="100%" mt="10px">
									<Box
										border="solid 1px"
										borderColor="gray.300"
										padding="20px"
										width="47%"
										cursor="pointer"
										as="a"
										href={'mailto:sibesh@mayalabs.io'}
										target="_blank"
										rel="noopener noreferrer"
										display={'flex'}
										justifyContent="center"
										alignItems={'center'}
										_hover={{
											borderColor: 'gray.400',
										}}
										transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
										borderRadius={'2px'}
										bg="white"
									>
										<HiOutlineMail />
										<Box ml="2">Email</Box>
									</Box>
									<Box
										border="solid 1px"
										borderColor="gray.300"
										padding="20px"
										ml="10px"
										width="47%"
										cursor="pointer"
										as="a"
										href={'tel:+91-9829118530'}
										target="_blank"
										rel="noopener noreferrer"
										display={'flex'}
										justifyContent="center"
										alignItems={'center'}
										_hover={{
											borderColor: 'gray.400',
										}}
										transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
										borderRadius={'2px'}
										bg="white"
									>
										<AiOutlinePhone />
										<Box ml="2">Call us</Box>
									</Box>
								</Box>
							</Box>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
			{false && (
				<Box
					position="absolute"
					bg="white"
					top="55px"
					ref={wrapperRef}
					width="250px"
					borderRadius="2px"
					border="solid 1px"
					borderColor="light.border.gray.200"
					paddingX="10px"
					paddingY="10px"
					zIndex="1000"
				>
					{!submitted ? (
						<Box>
							<Textarea
								onChange={(e) => {
									setFeedback(e.currentTarget.value);
								}}
								textStyle="sans.xs"
								fontSize="15px"
								color="light.font.gray.400"
								borderRadius="2px"
								focusBorderColor="gray.400"
							/>
							<Box
								display="flex"
								mt="7px"
								justifyContent="space-between"
								alignItems="center"
							>
								<Box display="flex">
									{emojiList.map((item) => {
										return (
											<EmojiBox
												text={item.text}
												emoji={item.emoji}
												selectedEmoji={selectedEmoji}
												setSelectedEmoji={setSelectedEmoji}
											/>
										);
									})}
								</Box>
								<Button
									onClick={handleSubmit}
									isLoading={isLoading}
									bg="light.theme.gray.500"
									size="xs"
									borderRadius="2px"
									textStyle="sans.xs"
									color="white"
									outline="none"
									_hover={{
										bg: 'light.theme.gray.400',
									}}
								>
									Send
								</Button>
							</Box>
						</Box>
					) : (
						<Box
							height="150px"
							textStyle="sans.xs"
							display="flex"
							flexDirection="column"
							justifyContent="center"
							alignItems="center"
							color="light.font.gray.400"
						>
							<CheckCircleIcon boxSize="20px" />
							<Box mt="20px">
								Feedback received! <br />
								Thanks for your help.
							</Box>
						</Box>
					)}
				</Box>
			)}
		</>
	);
};

export default FeedbackBtn;
