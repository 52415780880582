import { config } from '../../config';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

const generationFeedback = async ({
	feedbackMessage,
	feedbackScore,
	generationId,
}) => {
	const APIRequestBody = {
		feedback_msg: feedbackMessage,
		feedback_score: feedbackScore,
		generation_id: generationId,
	};

	const generationFeedbackFn = APIFunction({
		BASEURL: config.pacEngine.baseUrl,
		PATH_SEARCH: APIRoutes.apiService.generationFeedback.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.generationFeedback.PATH_METHOD,
	});

	const response = await generationFeedbackFn(APIRequestBody);
	return response;
};

export default generationFeedback;
