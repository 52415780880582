import * as React from 'react';

import { Flex, Box, FlexProps } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

export type FilterItemType = {
	label: string;
	selected: boolean;
	onClick: (selected: boolean) => void;
	containerProps?: FlexProps;
};

const FilterItem = ({
	label,
	selected,
	onClick,
	containerProps,
}: FilterItemType) => {
	return (
		<Flex
			alignItems="center"
			padding="8px 16px"
			bg="#fff"
			border="1px solid #dedede"
			borderRadius="2px"
			height="40px"
			boxSizing="border-box"
			cursor="pointer"
			userSelect="none"
			{...containerProps}
			onClick={() => onClick(!selected)}
		>
			<Box // The checkbox (chakra checkbox was not working)
				display="flex"
				justifyContent="center"
				alignItems="center"
				bg={selected ? '#3E1E3F' : '#F4F4F4'}
				borderRadius="2px"
				width="12px"
				height="12px"
				border="1px solid"
				borderColor={selected ? '#3E1E3F' : '#BABABA'}
				cursor="pointer"
			>
				{selected ? <CheckIcon color="white" fontSize="8px" /> : null}
			</Box>
			<Box textStyle="sans.xs" color="light.font.gray.400" ml="12px">
				{label}
			</Box>
		</Flex>
	);
};

export default FilterItem;
