import React, { useCallback, useEffect, useMemo } from 'react';
import { useStore } from '../../zustand';
import getRedInstance from '../functions/getRedInstance';

const SubflowEditorListener = ({ subflowId }) => {
	const updateTab = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.updateTab,
			[]
		)
	);
	const activeTab = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.tabs.activeTab,
			[]
		)
	);
	const RED = useMemo(() => getRedInstance(), []);

	// handles node edit changes
	useEffect(() => {
		const updateSubFlowHandler = (data) => {
			const subflowConverted = RED.convertSubflow(data);
			if (activeTab === subflowId) {
				updateTab(subflowId, subflowConverted);
			}
		};
		console.log('subflow edit done event added');
		RED.events.on('maya:edit-subflow-done', updateSubFlowHandler);
		return () => {
			console.log('editor close listener destroyed');
			// clean up
			RED.events.off('maya:edit-subflow-done', updateSubFlowHandler);
		};
	}, [RED.events, activeTab, subflowId, updateTab]);

	return null;
};

export default React.memo(SubflowEditorListener);
