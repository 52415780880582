import React, { useCallback, useMemo } from 'react';
import {
	HStack,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberDecrementStepper,
	NumberIncrementStepper,
	FormLabel,
	FormControl,
} from '@chakra-ui/react';
import { CheckIcon, CloseIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useStore } from '../../zustand';
import SubflowEditorListener from './SubflowEditorListener';
import MayaFilledButton from '../../../../library/buttons/FilledButton';
import getRedInstance from '../functions/getRedInstance';
import { parseMayaLangToReactFlow } from '../functions/FlowMethods';
import { scrambleMayaFlowIds } from '../../Tools/GenerateDAG/utils';
import useSessionId from '../../hooks/useSessionId';

const SubflowEditor = ({ subflowId }) => {
	const removeSubflow = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.removeSubflow,
			[]
		)
	);
	const updateTab = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.updateTab,
			[]
		)
	);
	const subflow = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.tabs.byId[subflowId],
			[subflowId]
		)
	);
	const toggleSubflowEditingState = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.toggleSubflowEditingState,
			[]
		)
	);
	const onDeploy = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.onDeploy,
			[]
		)
	);
	const brainId = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.brainId,
			[]
		)
	);
	const profileSlug = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.profileSlug,
			[]
		)
	);
	const runtimeUrl = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.runtime,
			[]
		)
	);
	const runtimeToken = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.token,
			[]
		)
	);
	/** @type {import('../../zustand/types').EditorStore['onEditorInit']} */
	const onEditorInit = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.onEditorInit,
			[]
		)
	);
	const setActiveTab = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.setActiveTab,
			[]
		)
	);
	const setDirty = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.setDirty,
			[]
		)
	);
	const mainSubflow = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.mainSubflow,
			[]
		)
	);
	const instanceSubflows = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.instanceSubflows,
			[]
		)
	);
	const getMayaFlowByTab = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.getMayaFlowByTab,
			[]
		)
	);
	const setFlowToTab = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.setFlowToTab,
			[]
		)
	);
	const RED = useMemo(() => getRedInstance(), []);
	const sessionId = useSessionId(brainId);

	const removeSubflowHandler = () => {
		RED.subflow.removeSubflow(subflowId);
		// Re-init editor
		onEditorInit({
			brainId,
			sessionId,
			profileSlug,
			token: runtimeToken,
			url: runtimeUrl,
		});
		setActiveTab();
		setDirty(true);
	};

	const editSubflowHandler = () => {
		RED.editor.editSubflow(RED.nodes.subflow(subflowId));
	};

	const onInputChangeHandler = (newInputVal) => {
		let updatedSubflow;
		// need this to set active workspace on node-red side
		// as it is used internally by addOutput function
		RED.workspaces.show(subflowId);
		if (newInputVal > subflow.in.length) {
			// add subflow output
			RED.subflow.addInput();
		} else if (newInputVal < subflow.in.length) {
			// remove subflow output
			RED.subflow.removeInput();
		} else {
			// do nothing
		}
		// Re-init editor
		onEditorInit({
			brainId,
			sessionId,
			profileSlug,
			token: runtimeToken,
			url: runtimeUrl,
		});
		// set subflow tab to be in editing state(ie. like how it was before onEditorInit)
		toggleSubflowEditingState(subflowId, true);
		setDirty(true);
	};

	const onOutputChangeHandler = (newOutputVal) => {
		let updatedSubflow;
		// need this to set active workspace on node-red side
		// as it is used internally by addOutput function
		RED.workspaces.show(subflowId);
		if (newOutputVal > subflow.out.length) {
			// add subflow output
			RED.subflow.addOutput();
		} else if (newOutputVal < subflow.out.length) {
			// remove subflow output
			RED.subflow.removeOutput();
		} else {
			// do nothing
		}
		// Re-init editor
		onEditorInit({
			brainId,
			sessionId,
			profileSlug,
			token: runtimeToken,
			url: runtimeUrl,
		});
		// set subflow tab to be in editing state(ie. like how it was before onEditorInit)
		toggleSubflowEditingState(subflowId, true);
		setDirty(true);
	};

	const updateInstanceSubflows = async () => {
		const mainSubflowFlow = getMayaFlowByTab({ tabId: subflowId });
		for (let nodeId in instanceSubflows) {
			const tabId = instanceSubflows[nodeId];
			const modifiedInstanceFlow = mainSubflowFlow.map((node) => {
				return { ...node, z: tabId };
			});
			const scrambledModifiedInstanceFlow = scrambleMayaFlowIds({
				mayaFlow: modifiedInstanceFlow,
			});
			const { nodes, edges } = parseMayaLangToReactFlow(
				scrambledModifiedInstanceFlow,
				1,
				'transient'
			);
			setFlowToTab(tabId, { nodes, edges });
			await new Promise((r) => setTimeout(r, 500));
			await onDeploy();
		}
	};

	const doneEditingHandler = () => {
		if (mainSubflow.tabId === subflowId) {
			updateInstanceSubflows();
		}
		toggleSubflowEditingState(subflowId, false);
	};

	const closeTabHandler = () => {
		toggleSubflowEditingState(subflowId, false);
	};

	return (
		<FormControl>
			<HStack spacing="20px" paddingX="16px" paddingY="8px">
				<HStack spacing="0px">
					<FormLabel htmlFor="input">Inputs</FormLabel>
					<NumberInput
						id="input"
						min={0}
						max={1}
						width="44px"
						size="xs"
						onChange={onInputChangeHandler}
						value={subflow.in.length}
					>
						<NumberInputField
							height="100% !important"
							width="100% !important"
							mb="0 !important"
							ml="0 !important"
						/>
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					</NumberInput>
				</HStack>

				<HStack spacing="0px">
					<FormLabel htmlFor="output">Outputs</FormLabel>
					<NumberInput
						id="output"
						min={0}
						width="44px"
						size="xs"
						onChange={onOutputChangeHandler}
						value={subflow.out.length}
					>
						<NumberInputField
							height="100% !important"
							width="100% !important"
							mb="0 !important"
							ml="0 !important"
						/>
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					</NumberInput>
				</HStack>

				<MayaFilledButton
					text="Edit Properties"
					leftIcon={<EditIcon />}
					size="sm"
					onClick={editSubflowHandler}
					showDots={false}
					buttonProps={{
						bg: '#FFFFFF',
						color: 'font.gray.400',
						_hover: {
							bg: '#EEEEEE',
						},
					}}
				/>

				<MayaFilledButton
					text="Delete Subflow"
					leftIcon={<DeleteIcon />}
					size="sm"
					onClick={removeSubflowHandler}
					showDots={false}
					buttonProps={{
						bg: 'chakra.color.gray.200',
						color: 'font.gray.400',
						borderColor: 'font.gray.400',
						border: '1px',
						_hover: {
							bg: '#EEEEEE',
						},
					}}
				/>
				<MayaFilledButton
					text="Done Editing"
					leftIcon={<CheckIcon />}
					showDots={false}
					size="sm"
					onClick={doneEditingHandler}
					buttonProps={{
						bg: '#567E4F',
						_hover: {
							bg: '#66A55C',
						},
					}}
				/>
				{mainSubflow.tabId === subflowId ? (
					<MayaFilledButton
						text="Close Tab"
						leftIcon={<CloseIcon />}
						showDots={false}
						size="sm"
						onClick={closeTabHandler}
						buttonProps={{
							bg: '#7d4f4f',
							_hover: {
								bg: '#a65d5d',
							},
						}}
					/>
				) : null}
				<SubflowEditorListener subflowId={subflowId} />
			</HStack>
		</FormControl>
	);
};

export default SubflowEditor;
