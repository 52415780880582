import { config } from '../../config';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

const redoSession = async ({ sessionId }) => {
	const APIRequestBody = { session_id: sessionId };

	const redoSessionFn = APIFunction({
		BASEURL: config.pacEngine.baseUrl,
		PATH_SEARCH: APIRoutes.apiService.redoSession.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.redoSession.PATH_METHOD,
	});

	const response = await redoSessionFn(APIRequestBody);
	return response;
};

export default redoSession;
