import React, { useContext } from 'react';
import { Box, useColorMode, Image, Text } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import commandsInstructionsDark from '../../../../../assets/cardui/commands_search_instructions_dark.svg';
import cardsInstructionsDark from '../../../../../assets/cardui/cards_search_instructions_dark.svg';
import mayaIconDark from '../../../../../assets/cardui/mayaicon_dark.svg';
import mayaIconColored from '../../../../../assets/icon64.png';

import { useDispatch, useSelector } from 'react-redux';
import IntentDisplay from './IntentDisplay';
import InstructionSection from '../../../CardUI/Search/ExpandedSection/InstructionSection';
import ItemRightInstructions from './ItemInstructions';
import {
	suggestionsTabClick,
	vibrateInstructionsChange,
} from '../../../../../redux/actions/cardUIV2';
import TabBar from './TabBar';
import CommandBarContext from '../CommandBarContext';
import CardItemDisplay from './CardItemDisplay';
import StoreSkillDisplay from './StoreSkillDisplay';
import DefaultSuggestionIcon from './DefaultSuggestionIcon';

function isValidUrl(url) {
	try {
		new URL(url);
		return true;
	} catch (e) {
		return false;
	}
}

/**
 * Default suggestions item height (number) in `rem`
 */
const defaultItemHeight = 3; // in rem

/**
 * Height of the selected suggestions item for store tab
 */
const selectedStoreItemHeight = 5; // in rem

/**
 *
 * @param {{
 * 	selected: boolean,
 * 	lastItem: boolean,
 * 	itemObject: import('../../../../../redux/reducers/cardUIV2').CuiIntent | import('../../../../../redux/reducers/cardUIV2').CuiCard | import('../../../../../redux/reducers/cardUIV2').CuiStoreSkill,
 * 	onMouseDown: (e: MouseEvent) => void,
 * }} param0
 * @returns
 */
const SearchItem = ({ selected, lastItem, itemObject, onMouseDown }) => {
	const { colorMode } = useColorMode();
	const bgColorSelected = {
		light: 'card_ui.focus_light',
		dark: 'card_ui.focus_dark',
	};
	const color = {
		light: 'card_ui.font_light.200',
		dark: 'card_ui.font_dark.200',
	};

	const dividerColor = {
		light: 'card_ui.divider_light',
		dark: 'card_ui.divider_dark',
	};

	let icon = <DefaultSuggestionIcon />;
	if (itemObject.brainId === 'nativePseudoBrain') {
		icon = <Image src={mayaIconColored} maxHeight="20px" />;
	} else if (
		itemObject.entity === 'intents' &&
		itemObject.icon &&
		isValidUrl(itemObject.icon)
	) {
		icon = <Image src={itemObject.icon} maxHeight="20px" />;
	}

	return (
		<Box
			paddingY="1.5rem"
			paddingLeft="1.2rem"
			height={`${
				// set bigger height for selected store skill item
				selected && itemObject.entity === 'storeSkill'
					? selectedStoreItemHeight
					: defaultItemHeight
			}rem`}
			display="flex"
			alignItems="center"
			bg={selected ? bgColorSelected[colorMode] : 'none'}
			borderRight="solid 3px"
			borderRightColor={selected ? '#9B51E0' : 'transparent'}
			borderTop={'solid 1px'}
			justifyContent="space-between"
			borderTopColor={dividerColor[colorMode]}
			borderBottomLeftRadius={lastItem ? '5px' : '0px'}
			borderBottomRightRadius={lastItem ? '5px' : '0px'}
			onMouseDown={onMouseDown}
		>
			<Box display="flex" alignItems="center" flex="1" minWidth="0">
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					padding="4px 4px 4px 0px"
					// bg='tomato'
					boxSizing="border-box"
				>
					{icon}
				</Box>
				<Box
					display="flex"
					alignItems="center"
					ml={3}
					flexWrap={'wrap'}
					flex="1"
					minWidth="0"
				>
					{itemObject.entity === 'intents' ? (
						<IntentDisplay intentComponentIds={itemObject.components} />
					) : null}
					{itemObject.entity === 'cards' ? (
						<CardItemDisplay cardObject={itemObject} />
					) : null}
					{itemObject.entity === 'storeSkill' ? (
						<StoreSkillDisplay
							storeSkillItemObject={itemObject}
							selected={selected}
						/>
					) : null}
				</Box>
			</Box>
			{selected ? <ItemRightInstructions /> : null}
		</Box>
	);
};

const NormalSearch = () => {
	const { currentInputRef } = useContext(CommandBarContext);
	const {
		searchItems,
		selectedIndex,
		type: currentTab,
		vibrateInstructions,
	} = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.commandBar.suggestionsArea
	);
	const dispatch = useDispatch();

	const maxResultsLength = 4;
	const searchItemsIndexed = searchItems.allIds.map((itemId, i) => ({
		index: i,
		itemId,
	}));

	const itemsToRender = () => {
		let indiceArrayLength =
			searchItemsIndexed.length > maxResultsLength
				? maxResultsLength
				: searchItemsIndexed.length;
		let indiceArray = Array.from({ length: indiceArrayLength }, (_, i) => i);
		if (selectedIndex > maxResultsLength - 1) {
			indiceArray = Array.from(
				{ length: maxResultsLength },
				(_, i) => i + selectedIndex - maxResultsLength + 1
			);
		}

		return {
			items: indiceArray.map((index) => {
				return searchItemsIndexed[index];
			}),
			indices: indiceArray,
		};
		// return searchItemsIndexed;
	};

	let toRender = itemsToRender();
	const listItemsRendered = toRender.items.map((item, i) => (
		<SearchItem
			key={i}
			itemObject={searchItems.byId[item.itemId]}
			selected={item.index === selectedIndex ? true : false}
			lastItem={i === toRender.items.length - 1 ? true : false}
		/>
	));

	var itemHeight = defaultItemHeight; //in rem

	var scrollHeight =
		(maxResultsLength * itemHeight * maxResultsLength) /
		searchItemsIndexed.length;
	var listHeight = itemHeight * maxResultsLength;

	var scrollMarginTop =
		toRender.indices[0] === 0
			? 0
			: ((selectedIndex - maxResultsLength + 1) *
					(listHeight - scrollHeight)) /
			  (searchItemsIndexed.length - maxResultsLength);

	return (
		<Box
			onMouseDown={(e) => {
				e.preventDefault();
				if (currentInputRef.current) currentInputRef.current.focus();
				dispatch(vibrateInstructionsChange({ vibrateInstructions: true }));
				setTimeout(
					() =>
						dispatch(
							vibrateInstructionsChange({ vibrateInstructions: false })
						),
					500
				);
			}}
		>
			<TabBar
				currentTab={currentTab}
				setCurrentTab={(tab) =>
					dispatch(suggestionsTabClick({ tabName: tab }))
				}
			/>
			<Box display="flex">
				{searchItemsIndexed.length > maxResultsLength ? (
					<Box width="1px" position="absolute">
						<Box
							width="100%"
							bg={'card_ui.purple'}
							height={scrollHeight + 'rem'}
							borderRadius="4px"
							// marginBottom="0"
							marginTop={scrollMarginTop + 'rem'}
						/>
					</Box>
				) : null}
				{listItemsRendered.length > 0 ? (
					<Box width="100%">{listItemsRendered}</Box>
				) : (
					<Box
						h="12rem"
						display="flex"
						alignItems="center"
						justifyContent="center"
						w="full"
					>
						<Box display="flex" flexDir="column" alignItems="center">
							<Image
								src={mayaIconDark}
								boxSize="5rem"
								ignoreFallback
								mb="1rem"
							/>
							<Text color="#878787">
								Sorry! We could not find what you're looking for.
							</Text>
						</Box>
					</Box>
				)}
			</Box>
			<InstructionSection
				vibrate={vibrateInstructions}
				// Commented out to make it look more elegant for now
				// children={
				// 	<Image
				// 		src={
				// 			currentTab === 'intents'
				// 				? commandsInstructionsDark
				// 				: currentTab === 'cards'
				// 				? cardsInstructionsDark
				// 				: commandsInstructionsDark
				// 		}
				// 		height="1.2rem"
				// 		mr={4}
				// 	/>
				// }
			/>
		</Box>
	);
};

export default NormalSearch;
