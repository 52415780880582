import React, { useEffect, useState, useRef } from 'react';
import {
	Flex,
	Box,
	Grid,
	Input,
	Img,
	GridItem,
	Center,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	useDisclosure,
	createStandaloneToast,
	Spinner,
	Tooltip,
} from '@chakra-ui/react';
import { useContextMenu } from 'react-contexify';
import { GoKebabVertical } from 'react-icons/go';
import SkillContextMenu from './SkillContextMenu';
import MayaFilledButton from '../../../../../library/buttons/FilledButton';
import useStepperStore from '../../../../Stepper/StepperStore';
import { stepperFlowTypes } from '../../../../Stepper/Sections/registry';
import useEditableText from '../../Workspaces/WorkspaceList/WorkspaceCard/Hooks/useEditableText';
import {
	getSkill,
	updateSkill,
} from '../../../../../functions/pac-engine/skills';
import theme from '../../../../../library/theme';

// Imports for `handleNewAutomationClick`
import randomDefaultBrainName from '../../../../../util/RandomDefaults/newbrain';
import createBrain from '../../../../../functions/brain/createBrain';
import isElectron from 'is-electron';
import useAnalytics from '../../../../../util/Analytics/useAnalytics';
import { v4 } from 'uuid';
import { HistoryWithSlug } from '../../../../../util/History';
import { addBrains } from '../../../../../redux/actions';
import { normalize } from '../../../../../util/Misc';
import useProfileSlug from '../../../../../hooks/useProfileSlug';
import { useDispatch, useSelector } from 'react-redux';
import { pacEngineStore } from '../../../../Editor/Tools/pac-engine/zustand';

const selectedBorderColor = '#925392';
const toast = createStandaloneToast({ theme: theme });

const Count = ({ count, type, curveBottom = false }) => {
	return (
		<Flex alignItems="center">
			<Center
				padding="8px"
				borderBottomRadius={curveBottom ? '3px' : undefined}
				bg="light.theme.gray.200"
				width="32px"
				height="32px"
			>
				{count}
			</Center>
			<Flex ml="8px" textStyle="sans.sm" color="light.font.gray.300">
				{type}
			</Flex>
		</Flex>
	);
};

/**
 *
 * @param {import('../types').SkillcardProps} param0
 */
const SkillCard = ({
	skill,
	isSelected = false,
	onSelect,
	handleSkillDelete,
	onSkillRename,
}) => {
	const track = useAnalytics();
	const profileSlug = useProfileSlug();
	const cloudDevice = useSelector(
		// @ts-ignore
		(state) =>
			Object.values(state.devices?.list?.byId || {}).find(
				(device) => device.platform === 'cloud'
			)
	);
	const dispatch = useDispatch();

	const { addStep, openStepper } = useStepperStore();
	const { show } = useContextMenu({ id: skill.skill_id });
	const optionsRef = useRef(null);
	const {
		isOpen: deleteModalOpen,
		onOpen: onDeleteModalOpen,
		onClose: onDeleteModalClose,
	} = useDisclosure();
	const [renaming, setRenaming] = useState(false);
	const inputRef = useRef();
	const [deleting, setDeleting] = useState(false);

	function handleOptionsButtonClick(e) {
		const { right: x, top: y } = optionsRef.current.getBoundingClientRect();
		// console.log('cm', a)
		show(e, {
			position: { x: x + 2, y: y },
		});
	}

	function showSkill() {
		addStep('teach.modifySkill', {
			skillId: skill.skill_id,
			stepperFlowType: stepperFlowTypes.teach.MODIFY_SKILL,
		});
		openStepper();
	}

	function handleClick(e) {
		switch (e.detail) {
			case 1: {
				onSelect(skill.skill_id);
				e.stopPropagation();
				return;
			}
			case 2: {
				showSkill();
				return;
			}
			default: {
				console.error('');
			}
		}
	}

	function handleDelete() {
		setDeleting(true);
		handleSkillDelete().then(() => {
			onDeleteModalClose();
			setDeleting(false); // Don't really need to do this, but meh
		});
	}

	function handleSkillRename(newName) {
		setRenaming(true);
		updateSkill(skill.skill_id, { name: newName })
			.then(() => {
				onSkillRename(newName);
				console.log('Renamed successfully');
			})
			.catch((e) => {
				console.error('Error renaming skill', e);
				toast({
					title: 'Error renaming skill',
					description:
						'An unknown error occured while renaming the skill. Please refresh and try again.',
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			})
			.finally(() => {
				setRenaming(false);
			});
	}

	function handleNewAutomationClick() {
		track('[Workspaces] New Automation Clicked', {});

		if (isElectron()) {
			// Need to open stepper and let user select device if on desktop app
			const stepperSessionId = v4();
			const initState = {
				stepperSessionId,
				stepperFlowType: stepperFlowTypes.home.NEW_AUTOMATION,
				platformOption: 'On Cloud',
			};
			addStep('new.workspace', initState);
			return openStepper();
		}

		const workspaceName = randomDefaultBrainName();
		Promise.all([
			// @ts-ignore
			createBrain({
				name: `${workspaceName.split(' ')[0]} Skill Edit`,
				slug: profileSlug,
				device: cloudDevice,
			}),
		]).then(async (res) => {
			try {
				// @ts-ignore
				if (!res[0].error) {
					dispatch(
						addBrains(normalize([{ ...res[0], status: 'STOPPED' }]))
					);
					track('[Workspace] Creation Success', {
						// @ts-ignore
						id: res[0]._id,
						name: workspaceName,
						device: cloudDevice,
						slug: profileSlug,
					});

					return HistoryWithSlug.push(`/edit?id=${res[0]._id}`);
				}
			} catch (err) {
				toast({
					title: 'Error creating workspace!',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			}
		});
	}

	async function handleSkillEditClick() {
		console.log(skill.skill_id);
		const { recipes, steps, docs } = await getSkill(skill.skill_id);
		pacEngineStore.setState({ skillBeingEdited: { recipes, steps, docs } });
		handleNewAutomationClick();
	}

	const {
		textInput: skillNameInput,
		textInputRef: skillNameInputRef,
		handleTextInputKeyDown: handleSkillNameInputKeyDown,
		handleTextInputChange: handleSkillNameInputChange,
		handleTextInputSubmit: handleSkillNameInputSubmit,
		handleTextRenameClick: handleSkillRenameClick,
	} = useEditableText(skill.name || skill.skill_id, handleSkillRename);

	const numRecipes = skill?.details?.recipe_ids?.length || 0;
	const numSteps = skill?.details?.step_ids?.length || 0;
	const numDocs = skill?.details?.doc_ids?.length || 0;

	return (
		<>
			<Modal
				isOpen={deleteModalOpen}
				onClose={() => null}
				isCentered
				size="md"
			>
				<ModalOverlay />
				<ModalContent borderRadius="2px">
					<ModalBody
						flex="1"
						minHeight="0"
						display="flex"
						flexDirection="column"
						p="0"
						borderRadius={'2px'}
						height="200px"
					>
						<Flex direction="column" flex="1" minHeight="0" p="4">
							<Box textStyle="serif.md" marginBottom="0.5rem">
								Delete skill?
							</Box>
							<Box
								fontWeight="medium"
								opacity="0.6"
								textStyle="sams.md"
								borderRadius="2px"
								mt="8px"
							>
								This action is irreversible and will permanently delete
								this skill.
							</Box>
						</Flex>
					</ModalBody>
					<ModalFooter>
						<MayaFilledButton
							text={deleting ? 'Deleting' : 'Yes, delete'}
							onClick={handleDelete}
							colorScheme="purple"
							size="sm"
							showDotPattern={false}
							buttonProps={{
								mr: '8px',
							}}
							isLoading={deleting}
						/>
						<MayaFilledButton
							showDotPattern
							text="Cancel"
							onClick={onDeleteModalClose}
							colorScheme="gray"
							size="sm"
						/>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<SkillContextMenu
				skillId={skill.skill_id}
				onDeleteClick={onDeleteModalOpen}
				onShowClick={showSkill}
				onRenameClick={handleSkillRenameClick}
				onEditClick={handleSkillEditClick}
			/>
			<GridItem
				width="100%"
				onClick={handleClick}
				onContextMenu={(e) => {
					onSelect(skill.skill_id);
					show(e);
					e.stopPropagation();
				}}
			>
				<Box // This exists just so I can increase the 'border width' on selection of a workspace card, without making content jump
					padding="1px"
					minHeight={'10px'}
					bg={isSelected ? selectedBorderColor : undefined}
					borderRadius="2px"
					cursor="pointer"
					transition="all 0.1s cubic-bezier(.08,.52,.52,1)"
				>
					<Flex
						direction="column"
						height="220px"
						border="1px solid"
						borderColor={
							isSelected ? selectedBorderColor : 'light.border.gray.100'
						}
						// transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
						bg="light.theme.gray.50"
						borderRadius="2px"
						overflow="hidden"
						position="relative"
					>
						{/* <Flex
                                position="absolute"
                                height="28px"
                                width="28px"
                                right="0"
                                mr="12px"
                                mt="8px"
                                borderRadius="2px"
                                justifyContent="center"
                                alignItems="center"
                                cursor="pointer"
                                transition="all 0.1s ease"
                            >
                                <FiExternalLink color="#656161" />
                            </Flex> */}
						<Box
							flex="1 1 auto"
							display="flex"
							flexDirection="column"
							bg="white"
							height="200px"
							overflow="hidden"
							width="100%"
							px="8px"
						>
							<Count count={numRecipes} type={'recipes'} />
							<Count count={numSteps} type={'steps'} />
							<Count count={numDocs} type={'documents'} curveBottom />
						</Box>
						<Box
							textStyle="sans.sm"
							padding="12px 16px 10px 16px"
							borderTop="1.25px solid"
							borderColor="light.border.gray.200"
							display="flex"
							justifyContent="space-between"
							color="light.font.gray.400"
						>
							<Box display="flex" alignItems="center" width="80%">
								<Flex alignItems="center" overflow="hidden">
									{skillNameInput.isEditing ? (
										<Input
											ref={skillNameInputRef}
											variant="unstyled"
											w="100%"
											h="100%"
											p="0"
											textAlign="left"
											my="0 !important"
											px="2"
											_placeholder={{
												fontSize: '2rem !important',
											}}
											value={skillNameInput.inputValue}
											fontWeight="500 !important"
											_focus={{ boxShadow: 'none' }}
											borderRadius="0"
											onChange={handleSkillNameInputChange}
											onBlur={handleSkillNameInputSubmit}
											onKeyDown={handleSkillNameInputKeyDown}
										/>
									) : (
										<Tooltip
											label={skill.name || skill.skill_id}
											openDelay={300}
										>
											<Box
												whiteSpace="nowrap"
												overflow="hidden"
												textOverflow="ellipsis"
												// bg='tomato'
												// width='100%'
												// height='100%'
											>
												{skill.name || skill.skill_id}
											</Box>
										</Tooltip>
									)}
								</Flex>
								<Flex mt="8px" userSelect="none">
									{/* <Tag
                                            bg="light.theme.gray.200"
                                            label={`${getLongestTimeElapsedString(
                                                workspace.updatedAt
                                            )} ago`}
                                            icon={<FiClock />}
                                            size="sm"
                                            ml="12px"
                                        /> */}
								</Flex>
							</Box>
							<Flex alignItems="center">
								<Flex
									transition="all 0.1s ease"
									borderRadius="2px"
									cursor="pointer"
									padding="5px"
									_hover={{
										backgroundColor: '#F3F3F3',
									}}
									ref={optionsRef}
									onClick={handleOptionsButtonClick}
									alignItems="center"
									justifyContent="center"
								>
									{renaming && <Spinner color="#656161" size="xs" />}
									{!renaming && <GoKebabVertical color="#656161" />}
								</Flex>
							</Flex>
						</Box>
					</Flex>
				</Box>
			</GridItem>
		</>
	);
};

export default SkillCard;
