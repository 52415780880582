import axios from 'axios';
import config from '../../config/config';

import { store } from '../../redux/store';

const CMS_API_ROOT = config.app.CMS_BACKEND;

const getStoreAppsOld = async () => {
	try {
		const response = await axios.get(`${CMS_API_ROOT}/apps`);
		/**
		 * @type {import('./types/Apps').StoreApp[]}
		 */
		const apps = response.data;
		return apps;
	} catch (e) {
		console.error(e);
		return [];
	}
};

const getStoreApps = async () => {
	const response = await axios.get(`${config.app.PAYLOAD_CMS_BACKEND}/api/apps`)
	return response.data.docs
}

export default getStoreApps;
