// Export the Login API.
const loginAPI = {
	fusionAuth: {
		getLogin: {
			PATH_SEARCH: '/api/login',
			PATH_METHOD: 'post',
		},
		identityProviderLogin: {
			PATH_SEARCH: '/api/identity-provider/login',
			PATH_METHOD: 'post',
		},
	},
	apiService: {
		getLogin: {
			PATH_SEARCH: '/fusionAuth/login',
			PATH_METHOD: 'post',
		},
		getAccessTokens: {
			PATH_SEARCH: '/v2/status/login',
			PATH_METHOD: 'get',
		},
		verifyEmail: {
			PATH_SEARCH: '/fusionAuth/verify/email',
			PATH_METHOD: 'post',
		},
		resendVerifyEmail: {
			PATH_SEARCH: '/fusionAuth/verify/resend',
			PATH_METHOD: 'post',
		},
		registerFusionAuthRegisteredUser: {
			PATH_SEARCH: '/fusionauth/registered',
			PATH_METHOD: 'post',
		},
	},
};

export default loginAPI;
