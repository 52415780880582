import React from 'react'
import {
    Flex, Box, 
    Avatar,
    Img,
} from '@chakra-ui/react'
import PermSelect, { permLabels, permLevels } from './PermSelect'
import MayaSkeleton from '../../../../../../library/skeleton'

export const TeamMemberSkeleton = () => {
    return (
        <Flex
            bg='#fff'
            border='1px solid'
            borderColor='light.border.gray.200'
            borderRadius='2px'
            height='48px'
            alignItems='center'
            textStyle='sans.xs'
        >
            <Flex
                px='12px'
            >
                <MayaSkeleton width='32px' height='32px' borderRadius='16px' />
            </Flex>
            <Flex
                flex='1 1 auto'
                // bg='tomato'
                height='100%'
                px='8px'
                alignItems='center'
                color='light.font.gray.400'
            >
                <MayaSkeleton width='100px' height='20px' />
            </Flex>
            <Flex
                width='115px'
                height='100%'
                alignItems='center'
                padding='8px 8px'
            >
                <MayaSkeleton width='100%' height='100%' borderRadius='2px' />
            </Flex>
        </Flex>
    )
}

export const TeamMember = ({
    key,
    member,
    onPermChange = (profileSlug, newPerm) => null,
    onMemberDelete = (profileSlug) => null
}) => {
    const { profile } = member


    return (
        <Flex
            bg='#fff'
            border='1px solid'
            borderColor='light.border.gray.200'
            borderRadius='2px'
            height='48px'
            alignItems='center'
            textStyle='sans.xs'
        >
            <Flex
                px='12px'
            >
                <Avatar 
                    name={profile.name} 
                    size='sm'
                    border='1px solid #D8D8D8'
                    bg='light.theme.yellow.200'
                    color='light.font.gray.300'
                />
            </Flex>
            <Flex
                flex='1 1 auto'
                // bg='tomato'
                height='100%'
                px='8px'
                alignItems='center'
                color='light.font.gray.400'
            >
                {profile.name}
            </Flex>
            <Flex
                width='115px'
                height='100%'
                alignItems='center'
                padding='8px 8px'
            >
                <PermSelect 
                    showActions
                    selectedPerm={member.permissions} 
                    onPermChange={(newPerm) => onPermChange(member.profile.profileSlug, newPerm)}
                    onDelete={() => onMemberDelete(profile.profileSlug)}
                />
            </Flex>
        </Flex>
    )
}

export default TeamMember