// Config object
try {
	require('dotenv').config();
} catch (err) {
	console.error(err);
}
const config = {
	settings: {
		teamEnabled: false,
		storeEnabled: true,
		publishEnabled: false,
		waitlistMode: true,
		soloPlanAllowed: false,
		synthSettingsEnabled:
			JSON.parse(process.env.REACT_APP_SYNTH_SETTINGS) || false,
	},
	fusionAuth: {
		// FusionAuth URL: http://localhost:9011
		BASEURL: process.env.REACT_APP_FUSIONAUTH_BASEURL,
		// Application ID from FusionAuth
		APPLICATION_ID: process.env.REACT_APP_FUSIONAUTH_APPLICATION_ID,
		IDENTITY_PROVIDER_ID:
			process.env.REACT_APP_FUSIONAUTH_IDENTITY_PROVIDER_ID,
		API_KEY: process.env.REACT_APP_FUSIONAUTH_API_KEY,
	},
	authservice: {
		BASEURL:
			process.env.REACT_APP_AUTH_SERVICE_BASEURL ||
			'https://authservice.dev.mayalabs.io',
	},
	apiServer: {
		// API Server URL: http://localhost:5000
		BASEURL:
			process.env.NODE_ENV === 'development'
				? // ? process.env.REACT_APP_BACKEND
				  process.env.REACT_APP_BACKEND
				: process.env.REACT_APP_BACKEND_PROD,
		CLOUDBASEURL: process.env.REACT_APP_BACKEND_PROD,
	},
	apiGateway: {
		BASEURL:
			process.env.REACT_APP_MAYA_ENV === 'development'
				? 'https://api.dev.mayalabs.io'
				: 'https://api.mayalabs.io',
		// BASEURL: 'http://192.168.29.233:7575',
	},
	programSynthesis: {
		enable: true,
		genEndpoint: process.env.REACT_APP_SYNTH_ENDPOINT,
	},
	app: {
		// Name for the 2Factor application: FusionAuth
		TWO_FA_NAME: 'AppName',
		MAYA_ENV: process.env.REACT_APP_MAYA_ENV,
		BASEURL:
			process.env.NODE_ENV === 'development'
				? `http://localhost:3000`
				: process.env.REACT_APP_BACKEND_PROD.includes('prod')
				? 'https://app.mayalabs.io'
				: 'https://devapp.mayalabs.io',
		VERSION: require('../../package.json').version,
		APP_URL_STAGING: process.env.APP_URL_STAGING,
		APP_URL_PROD: process.env.APP_URL_PROD,
		CMS_BACKEND:
			process.env.REACT_APP_CMS_BACKEND || 'https://cms.dev.mayalabs.io',
		WEBSITE_URL:
			process.env.NODE_ENV === 'development'
				? 'https://mayalabs.io'
				: 'https://mayalabs.io',
		PAYLOAD_CMS_BACKEND:
			process.env.REACT_APP_PAYLOAD_CMS_BACKEND || 'http://localhost:9090',
	},
	payment: {
		razorpay: {
			KEY_ID: process.env.REACT_APP_RAZORPAY_TEST_KEY_ID,
			checkoutTimeout: process.env.REACT_APP_RAZORPAY_CHECKOUT_TIMEOUT,
		},
		enabled: process.env.REACT_APP_PAYMENTS_ENABLED,
		// Name for the 2Factor application: FusionAuth
	},
	editor: {
		/** Enables the dashboard split view feature in editor */
		enableDashboardSplitView: true,
		/** Show/hide `new toggle` on editor  */
		editorNewToggleShow: JSON.parse(process.env.REACT_APP_EDITOR_TOGGLE_SHOW),
	},
	mixpanel: {
		TOKEN:
			process.env.REACT_APP_MAYA_ENV === 'development'
				? process.env.REACT_APP_MIXPANEL_DEV
				: process.env.REACT_APP_MIXPANEL_PROD,
	},
	reservedPaths: JSON.parse(process.env.REACT_APP_RESERVED_ROUTES),
	enableUiTest: JSON.parse(process.env.REACT_APP_ENABLE_UI_TEST),
	enableSocialLogin: process.env.REACT_APP_ENABLE_SOCIAL_LOGIN === 'true',
	// pacEngine: {
	// 	baseUrl: 'http://192.168.29.233:7575/pac',
	// 	baseWSURl: 'ws://192.168.29.233:8080/socket',
	// 	APIKey: 'sk-akljnv13bvi2vfo0b0bw',
	// },
	pacEngine: {
		// baseUrl: 'http:///localhost:8001',
		baseUrl: process.env.REACT_APP_PAC_BASEURL,
		baseWSURl: process.env.REACT_APP_PAC_WEBSOCKETURL,
		APIKey: 'sk-akljnv13bvi2vfo0b0bw',
	},
	oauth: {
		GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID,
	},
};

// Export the application config.
export default config;

// Cloud URLs for PAC Engine
// baseUrl: 'https://api.dev.mayalabs.io/pac',
// baseWSURl: 'wss://paccomms.pac.dev.mayalabs.io/socket
