// Dependency imports

// import worker from 'workerize-loader!../../../src/maya-service-worker'; // eslint-disable-line import/no-webpack-loader-syntax

import APIFunction from '../../util/APIFetch/function';

import APIRoutes from '../../util/APIRoutes';

// config
import { config } from '../../config';
/**
 * Function to get all profiles for a user
 */
const setUsername = async ({
	profileSlug,
    username,
}) => {
	// Create an instance of your worker
	var setUsernameFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.setUsername.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.setUsername.PATH_METHOD,
	});

    const formData = {
        profileSlug,
        username
    }

	var setUsernameResult = await setUsernameFn(formData);
	return setUsernameResult['data'].results
};

export default setUsername;