import { config } from '../../config';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

const createSession = async ({ fromRecipe }) => {
	const APIRequestBody = {};
	if (fromRecipe) {
		APIRequestBody.from_recipe = fromRecipe;
	}

	const createSessionFn = APIFunction({
		BASEURL: config.pacEngine.baseUrl,
		PATH_SEARCH: APIRoutes.apiService.createSession.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.createSession.PATH_METHOD,
	});

	const response = await createSessionFn(APIRequestBody);
	return response;
};

export default createSession;
