import React from 'react';
import { Box, Image, useMediaQuery } from '@chakra-ui/react';
import { HistoryWithSlug } from '../../../../../../util/History';
import { useLocation } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu';
import HorizontalMenu from '../../../../../../library/menus/Horizontal';
import { getPathWithoutSlug } from '../../../../../../functions/profile/switchProfile';

const menuItemList = [
	{
		label: 'workers',
		redirect: '/workers',
	},
	{
		label: 'library',
		redirect: '/library',
	},
	{
		label: 'store',
		redirect: '/store',
	},
	// {
	// 	label: 'orders',
	// 	redirect: '/orders',
	// },
	// {
	// 	label: 'tasks',
	// 	redirect: '/tasks',
	// },
	{
		label: 'settings',
		redirect: '/settings/integrations',
	},
];

const MenuItems = () => {
	const [selectedItem, setSelected] = React.useState('workspaces');
	const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
	let location = useLocation();
	React.useEffect(() => {
		const barePath = getPathWithoutSlug(location.pathname);
		setSelected(barePath.split('/')[1]);
	}, [location]);

	console.log('seli', selectedItem);

	return isSmallerThan900 ? (
		<HamburgerMenu list={menuItemList} setSelected={setSelected} />
	) : (
		<HorizontalMenu
			menuItemList={menuItemList}
			setSelected={setSelected}
			selectedItem={selectedItem}
		/>
	);
};

export default MenuItems;
