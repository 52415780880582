import { GenerateTask } from '../../components/Editor/util/pacEngine';

const handleViewportLoaded = ({ task, setStepIdBeingGenerated }) => {
	console.log('Viewport loaded: closing the generate WS connection');
	setStepIdBeingGenerated('');
	task.done();
};

const generate = ({
	sessionId,
	onReceivingGenerateMessage,
	tabId,
	setIsGenerateLoading,
	setStepIdBeingGenerated,
	stepIdToFocusOn,
	fromEditor,
	fromLoader = false,
	connId,
}) => {
	const task = new GenerateTask({ sessionId, connId });

	task.on('stepGenerated', (message) => {
		if (message.status === 'complete') {
			task.done();
			setIsGenerateLoading(false);
			setStepIdBeingGenerated('');
			return;
		}

		if (fromEditor) {
			onReceivingGenerateMessage({ message, tabId, stepIdToFocusOn, fromLoader });
		}

		// Closing the WS request if connection was opened outside the Editor and it's finished loading
		if (!fromEditor && !fromLoader) {
			document.addEventListener(
				'maya::viewportLoaded',
				() => handleViewportLoaded({ task, setStepIdBeingGenerated }),
				// TODO Not being respected, to be improved
				{ once: true }
			);
		}
	});

	return task.execute();
};

export const listenToGenerateResult = ({
	taskId,
	sessionId,
	onReceivingGenerateMessage,
	tabId,
	setIsGenerateLoading,
	setStepIdBeingGenerated,
	stepIdToFocusOn,
	fromLoader = false,
	onComplete = () => null
}) => {
	const task = new GenerateTask({ sessionId, connId: sessionId });
	task.on('stepGenerated', (message) => {
		if (message.status === 'complete') {
			task.done();
			setIsGenerateLoading(false);
			setStepIdBeingGenerated('');
			onComplete()
			return;
		}

		onReceivingGenerateMessage({ message, tabId, stepIdToFocusOn, fromLoader });
	})
	task.listenForResults({ taskId })

	// Hard timeout to close socket
	setTimeout(() => {
		try {
			task.done()
		} catch (_) {}
	}, 10 * 60 * 1000)
}

export default generate;
