import React, { useCallback } from 'react';
import { Box, Image } from '@chakra-ui/react';
import MayaFilledButton from '../../../../../library/buttons/FilledButton';
import MayaHollowButton from '../../../../../library/buttons/HollowButton';
import { HistoryWithSlug } from '../../../../../util/History';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import useStepperStore from '../../../StepperStore';

const TallOption = ({
	heading,
	subheading,
	img,
	primaryBtnText,
	secondaryBtnText,
	bottomTextOne,
	bottomTextTwo,
	link,
	secondaryLink,
}) => {
	const { addStep, nextStep, closeStepper, currentState } = useStepperStore(
		(state) => {
			return {
				addStep: state.addStep,
				nextStep: state.nextStep,
				closeStepper: state.closeStepper,
				currentState: state.steps.byId['new.automation'].state,
			};
		}
	);

	return (
		<Box
			width="310px"
			height="540px"
			bg="white"
			padding="20px"
			display="flex"
			flexDirection="column"
			alignItems="center"
			border="solid 1px"
			borderColor="light.border.gray.200"
			_hover={{
				borderColor: 'light.border.gray.300',
			}}
			borderRadius="2px"
			cursor="pointer"
			onClick={() => {
				if (link['route']) {
					HistoryWithSlug.push(link['route']);
					closeStepper();
				} else if (link['step']) {
					addStep(link['step'], {
						...currentState,
						...link['initState'],
					});
					nextStep();
				}
			}}
			transition="all 0.1s cubic-bezier(.08,.52,.52,1)"
		>
			<Box
				mt="20px"
				textStyle="serif.md"
				fontWeight="600"
				color="light.font.gray.400"
			>
				{heading}
			</Box>
			<Box
				textStyle="sans.xs"
				color="light.font.gray.300"
				textAlign="center"
				mt="10px"
				width="230px"
			>
				{subheading}
			</Box>

			<Image src={img} height="28%" width="75%" mb="30px" mt="30px" />

			<MayaFilledButton
				text={primaryBtnText}
				onClick={() => {
					if (link['route']) {
						HistoryWithSlug.push(link['route']);
						closeStepper();
					} else if (link['step']) {
						addStep(link['step'], {
							...currentState,
							...link['initState'],
						});
						// nextStep();
						// console.log('TEST');
					}
				}}
				buttonProps={{
					width: '200px',
				}}
				rightIcon={<ArrowForwardIcon />}
				colorScheme="gray"
			/>
			<Box marginTop="5px" />
			{secondaryBtnText === '' ? (
				<Box height="40px" />
			) : (
				<MayaHollowButton
					text={secondaryBtnText}
					onClick={() => {
						if (secondaryLink['route']) {
							HistoryWithSlug.push(secondaryLink['route']);
							closeStepper();
						} else if (secondaryLink['step']) {
							addStep(secondaryLink['step'], {
								...currentState,
								...secondaryLink['initState'],
							});
							// nextStep();
						}
					}}
					buttonProps={{
						width: '200px',
					}}
				/>
			)}
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				mt="20px"
			>
				<Box textStyle="sans.xs" color="light.font.gray.400">
					{bottomTextOne}
				</Box>
				<Box textStyle="sans.xs" color="light.font.gray.400">
					{bottomTextTwo}
				</Box>
			</Box>
		</Box>
	);
};

export default TallOption;
