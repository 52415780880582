import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';
import _ from 'lodash';

// config
import { config } from '../../config';
import { store } from '../../redux/store';

/**
 *
 * @param {{ workspaceUrl: string, modulePackageName: string, profileSlug: string, version: string, workspaceId: string }} formData
 * @returns
 */
const installExternalModuleToBrain = async ({
	workspaceUrl,
	modulePackageName,
	profileSlug,
	version,
	workspaceId,
}) => {
	const { brains } = store.getState();
	let brainDevice = brains.byId[workspaceId]['device']['platform'];

	if (brainDevice === 'cloud') {
		const installExternallModuleToBrainAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH:
				APIRoutes.apiService.installExternalModuleToBrain.PATH_SEARCH,
			PATH_METHOD:
				APIRoutes.apiService.installExternalModuleToBrain.PATH_METHOD,
		});

		const result = await installExternallModuleToBrainAPIFn({
			workspaceUrl,
			profileSlug,
			packageName: modulePackageName,
			version: version,
		});

		return result;
	} else {
		const installExternallModuleToBrainIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.installExternalModuleToBrain.EVENT,
		});

		const result = await installExternallModuleToBrainIPCFn({
			workspaceUrl,
			profileSlug,
			packageName: modulePackageName,
			version: version,
		});
		return result;
	}
};

export default installExternalModuleToBrain;
