// Export the API.
const API_ROUTES = {
	apiService: {
		//Dashboard data
		//ELECTRON
		getDashboardData: {
			PATH_SEARCH: '/v2/dashboard',
			PATH_METHOD: 'get',
		},
		//Feedback
		submitFeedback: {
			PATH_SEARCH: '/v2/feedback/',
			PATH_METHOD: 'post',
		},
		//Teams
		//ELECTRON
		modifyTeam: {
			PATH_SEARCH: '/api/team/edit',
			PATH_METHOD: 'put',
		},
		// createTeam: {
		//   PATH_SEARCH: "/api/team/create",
		//   PATH_METHOD: "post",
		// },
		inviteMember: {
			PATH_SEARCH: '/api/team/members/invite',
			PATH_METHOD: 'post',
		},
		removeMember: {
			PATH_SEARCH: '/api/team/members/remove',
			PATH_METHOD: 'post',
		},
		addFlowToTeamLibrary: {
			PATH_SEARCH: '/api/team/library/add',
			PATH_METHOD: 'post',
		},
		deleteFlowFromTeamLibrary: {
			PATH_SEARCH: '/api/team/library/delete',
			PATH_METHOD: 'post',
		},
		getTeamLibraryItems: {
			PATH_SEARCH: '/api/team/library/list',
			PATH_METHOD: 'post',
		},
		getTeamFromStoreItems: {
			PATH_SEARCH: '/api/team/library/fromstore',
			PATH_METHOD: 'post',
		},
		//Flows
		getCurrentFlow: {
			PATH_SEARCH: '/api/flow/current',
			PATH_METHOD: 'post',
		},
		createFlow: {
			PATH_SEARCH: '/api/flow/create',
			PATH_METHOD: 'post',
		},
		deleteFlow: {
			PATH_SEARCH: '/api/flow/delete',
			PATH_METHOD: 'post',
		},
		//Fleets
		//ELECTRON
		createFleet: {
			PATH_SEARCH: '/api/fleet/create',
			PATH_METHOD: 'post',
		},
		//ELECTRON
		deleteFleet: {
			PATH_SEARCH: '/api/fleet/delete',
			PATH_METHOD: 'post',
		},
		getFleets: {
			PATH_SEARCH: '/api/fleet/list',
			PATH_METHOD: 'get',
		},
		//Devices
		checkDevice: {
			PATH_SEARCH: '/v2/device/check',
			PATH_METHOD: 'post',
		},
		registerDevice: {
			PATH_SEARCH: '/v2/device/register',
			PATH_METHOD: 'post',
		},
		getDevices: {
			PATH_SEARCH: '/v2/device/list',
			PATH_METHOD: 'get',
		},
		//Modules
		getModules: {
			PATH_SEARCH: '/api/modules/',
			PATH_METHOD: 'get',
		},
		getModuleList: {
			PATH_SEARCH: '/v2/modules/list',
			PATH_METHOD: 'post',
		},
		cmsGetStoreModules: {
			PATH_SEARCH: '/modules',
			PATH_METHOD: 'get',
		},
		//Robots
		//ELECTRON
		getRobotInfo: {
			PATH_SEARCH: '/api/robot/info',
			PATH_METHOD: 'post',
		},
		listRobots: {
			PATH_SEARCH: '/api/robot/list',
			PATH_METHOD: 'get',
		},
		updateRobot: {
			PATH_SEARCH: '/api/robot/update',
			PATH_METHOD: 'post',
		},
		installFlow: {
			PATH_SEARCH: '/api/robot/installFlow',
			PATH_METHOD: 'post',
		},
		uninstallFlow: {
			PATH_SEARCH: '/api/robot/uninstallFlow',
			PATH_METHOD: 'post',
		},
		installModule: {
			PATH_SEARCH: '/api/robot/installModule',
			PATH_METHOD: 'post',
		},
		installExternalModule: {
			PATH_SEARCH: '/v2/',
		},
		uninstallModule: {
			PATH_SEARCH: '/api/robot/uninstallModule',
			PATH_METHOD: 'post',
		},
		onDeploy: {
			PATH_SEARCH: '/api/robot/deploy',
			PATH_METHOD: 'post',
		},
		//Config Types
		getConfigType: {
			PATH_SEARCH: '/v2/configurationTypes/',
			PATH_METHOD: 'get',
		},
		//CONFIG PROFILES
		getConfigProfiles: {
			PATH_SEARCH: '/v2/profiles/configs',
			PATH_METHOD: 'get',
		},
		addConfigToProfile: {
			PATH_SEARCH: '/v2/profiles/config',
			PATH_METHOD: 'post',
		},
		removeConfigProfile: {
			PATH_SEARCH: '/v2/profiles/configs',
			PATH_METHOD: 'delete',
		},
		getAuthUrl: {
			PATH_SEARCH: '/v2/oauth/url',
			PATH_METHOD: 'post',
		},
		checkAuthStatusAndFetch: {
			PATH_SEARCH: '/v2/oauth/oauthstatus/',
			PATH_METHOD: 'get',
		},
		saveConfigProfileTokens: {
			PATH_SEARCH: '/v2/oauth/client-cred',
			PATH_METHOD: 'post',
		},
		//BRAINS
		createBrain: {
			PATH_SEARCH: '/v2/brains',
			PATH_METHOD: 'post',
		},
		deleteBrain: {
			PATH_SEARCH: '/v2/brains',
			PATH_METHOD: 'delete',
		},
		resetBrain: {
			PATH_SEARCH: '/v2/brains/resetFlows',
			PATH_METHOD: 'post',
		},
		//ELECTRON
		startBrain: {
			PATH_SEARCH: '/v2/brains/start',
			PATH_METHOD: 'post',
		},
		setReferenceIdToConfigMap: {
			PATH_SEARCH: '/v2/tokens/setReferenceId',
			PATH_METHOD: 'post',
		},
		//ELECTRON
		stopBrain: {
			PATH_SEARCH: '/v2/brains/stop',
			PATH_METHOD: 'post',
		},
		updateBrain: {
			PATH_SEARCH: '/v2/brains/',
			PATH_METHOD: 'put',
		},
		getBrainById: {
			PATH_SEARCH: '/v2/brains',
			PATH_METHOD: 'get',
		},
		getCurrentBrainById: {
			PATH_SEARCH: '/v2/brains/getCurrentBrain',
			PATH_METHOD: 'get',
		},
		getToken: {
			PATH_SEARCH: '/v2/brains/token',
			PATH_METHOD: 'get',
		},
		deploySkills: {
			PATH_SEARCH: '/v2/deploy',
			PATH_METHOD: 'put',
		},
		installModuleToBrain: {
			PATH_SEARCH: '/v2/brains/modules/installModuleToBrain',
			PATH_METHOD: 'post',
		},
		installExternalModuleToBrain: {
			PATH_SEARCH: '/v2/brains/modules/installExternalModule',
			PATH_METHOD: 'post',
		},
		uninstallModuleFromBrain: {
			PATH_SEARCH: '/v2/brains/modules/uninstallModuleFromBrain',
			PATH_METHOD: 'post',
		},
		getMayaJson: {
			PATH_SEARCH: '/v2/brains/skillPacks/getMayaJson',
			PATH_METHOD: 'post',
		},
		installPublishedSkillPackToBrain: {
			PATH_SEARCH:
				'/v2/brains/publishedSkillPacks/installPublishedSkillPackToBrain',
			PATH_METHOD: 'post',
		},
		installExternalSkillpack: {
			PATH_SEARCH: '/v2/brains/publishedSkillPacks/installExternalSkillpack',
			PATH_METHOD: 'post',
		},
		uninstallPublishedSkillpackFromBrain: {
			PATH_SEARCH:
				'/v2/brains/publishedSkillPacks/uninstallPublishedSkillPack',
			PATH_METHOD: 'post',
		},
		getBrainsByPublishedSkillpack: {
			PATH_SEARCH: '/v2/brains/getBrainsByPublishedSkillpack',
			PATH_METHOD: 'post',
		},
		getBrainConfigMap: {
			PATH_SEARCH: '/v2/brains/getProfileMap',
			PATH_METHOD: 'get',
		},
		getStoreModules: {
			PATH_SEARCH: '/v2/store/modules',
			PATH_METHOD: 'get',
		},
		getModuleById: {
			PATH_SEARCH: '/v2/store/modules/',
			PATH_METHOD: 'get',
		},

		//ELECTRON
		deleteRobot: {
			PATH_SEARCH: '/api/robot/delete',
			PATH_METHOD: 'post',
		},

		//SETTINGS
		getUserDetails: {
			PATH_SEARCH: '/api/settings/user',
			PATH_METHOD: 'post',
		},
		updateUser: {
			PATH_SEARCH: '/api/settings/user',
			PATH_METHOD: 'put',
		},
		newDeveloperRequest: {
			PATH_SEARCH: '/v2/developer',
			PATH_METHOD: 'post',
		},
		deleteDeveloperRequest: {
			PATH_SEARCH: '/v2/developer',
			PATH_METHOD: 'delete',
		},
		getDeveloperAccounts: {
			PATH_SEARCH: '/v2/developer',
			PATH_METHOD: 'get',
		},
		//PUBLISH
		publishNewFlow: {
			PATH_SEARCH: '/api/publish/flow/new',
			PATH_METHOD: 'post',
		},
		updatePublishedFlow: {
			PATH_SEARCH: '/api/publish/flow/update',
			PATH_METHOD: 'post',
		},
		//STORE
		getStoreSkillPacks: {
			PATH_SEARCH: '/v2/store/skills',
			PATH_METHOD: 'post',
		},
		getStoreSkills: {
			PATH_SEARCH: '/api/skills',
			PATH_METHOD: 'get',
		},
		searchStoreSkillPacks: {
			PATH_SEARCH: '/v2/store/skills/search',
			PATH_METHOD: 'get',
		},
		getPublishedSkillPack: {
			PATH_SEARCH: '/v2/publish',
			PATH_METHOD: 'get',
		},
		getPublishedSkillPackByDeveloper: {
			PATH_SEARCH: '/v2/publish/developer',
			PATH_METHOD: 'get',
		},
		getStoreFlows: {
			PATH_SEARCH: '/api/store/',
			PATH_METHOD: 'get',
		},
		getStoreFlowById: {
			PATH_SEARCH: '/api/store/',
			PATH_METHOD: 'post',
		},
		getStoreFlowVersions: {
			PATH_SEARCH: '/api/store/versions',
			PATH_METHOD: 'post',
		},
		// UPLOAD
		uploadFileS3: {
			PATH_SEARCH: '/api/upload/public/s3',
			PATH_METHOD: 'post',
		},
		//CART
		createOrder: { PATH_SEARCH: '/api/cart/order', PATH_METHOD: 'post' },
		updatePayment: { PATH_SEARCH: '/api/cart/payment', PATH_METHOD: 'post' },
		syncData: {
			PATH_SEARCH: '/v2/sync',
			PATH_METHOD: 'post',
		},
		//LIBRARY
		addToLibrary: {
			PATH_SEARCH: '/v2/library/skillPack',
			PATH_METHOD: 'post',
		},
		addToLibraryFromStore: {
			PATH_SEARCH: '/v2/library/publishedSkillPack',
			PATH_METHOD: 'post',
		},
		deleteFromLibrary: {
			PATH_SEARCH: '/v2/library/skillPack',
			PATH_METHOD: 'delete',
		},
		copyToLibrary: {
			PATH_SEARCH: '/v2/library/copy',
			PATH_METHOD: 'post',
		},
		getLibraryItems: {
			PATH_SEARCH: '/v2/library/list',
			PATH_METHOD: 'get',
		},
		//skillPack
		updateLibrarySkillPack: {
			PATH_SEARCH: '/v2/library/skillPack/update',
			PATH_METHOD: 'post',
		},
		getSkillPack: {
			PATH_SEARCH: '/v2/skillPack',
			PATH_METHOD: 'get',
		},
		getSkillPackList: {
			PATH_SEARCH: '/v2/skillPack/list',
			PATH_METHOD: 'post',
		},
		//publishedSkillPack
		publishSkillPack: {
			PATH_SEARCH: '/v2/publish',
			PATH_METHOD: 'post',
		},
		updatePublishedSkillPack: {
			PATH_SEARCH: '/v2/publish',
			PATH_METHOD: 'patch',
		},
		getPublishedSkillPackList: {
			PATH_SEARCH: '/v2/publish/list',
			PATH_METHOD: 'post',
		},
		// Profile
		checkSlug: {
			PATH_SEARCH: '/v2/profiles/check',
			PATH_METHOD: 'get',
		},
		createTeam: {
			PATH_SEARCH: '/v2/profiles',
			PATH_METHOD: 'post',
		},
		getUserProfiles: {
			PATH_SEARCH: '/v2/profiles',
			PATH_METHOD: 'get',
		},
		deleteProfile: {
			PATH_SEARCH: '/v2/profiles',
			PATH_METHOD: 'delete',
		},
		updateProfileMetadata: {
			PATH_SEARCH: '/v2/profiles/metadata',
			PATH_METHOD: 'put',
		},
		getTeamMembers: {
			PATH_SEARCH: '/v2/profiles/teamMembers',
			PATH_METHOD: 'get',
		},
		changeTeamMemberPermission: {
			PATH_SEARCH: '/v2/profiles/changeTeamMemberPermission',
			PATH_METHOD: 'put',
		},
		removeUserFromTeam: {
			PATH_SEARCH: '/v2/profiles/removeUserFromTeam',
			PATH_METHOD: 'delete',
		},
		inviteUserToTeam: {
			PATH_SEARCH: '/v2/profiles/inviteUserToTeam',
			PATH_METHOD: 'post',
		},
		setUsername: {
			PATH_SEARCH: '/v2/profiles/setUsername',
			PATH_METHOD: 'put',
		},
		setProfileRegion: {
			PATH_SEARCH: '/v2/profiles/setRegion',
			PATH_METHOD: 'put',
		},
		acceptTeamInvite: {
			PATH_SEARCH: '/v2/invite/acceptTeamInvite',
			PATH_METHOD: 'post',
		},
		getAllTeamInvites: {
			PATH_SEARCH: '/v2/invite/getAllInvites',
			PATH_METHOD: 'get',
		},
		updateChecklistItem: {
			PATH_SEARCH: '/v2/profiles/updateChecklistItem',
			PATH_METHOD: 'put',
		},
		// UPDATES
		checkUpdates: {
			PATH_SEARCH: '/v2/brains/checkUpdates',
			PATH_METHOD: 'post',
		},
		// CATEGORIES
		getCategories: {
			PATH_SEARCH: '/v2/categories',
			PATH_METHOD: 'get',
		},
		// PLATFORMS
		getPlatforms: {
			PATH_SEARCH: '/v2/platforms',
			PATH_METHOD: 'get',
		},
		// PAYMENTS
		// createSubscription: {
		// 	PATH_SEARCH: '/v2/payments/subscription/create',
		// 	PATH_METHOD: 'post',
		// },
		// mayapayments
		getStripeSubscriptionDetails: {
			PATH_SEARCH: '/v2/payments/stripe/getSubscriptionDetails',
			PATH_METHOD: 'post',
		},
		stripeCreateCheckoutSession: {
			PATH_SEARCH: '/v2/payments/stripe/createCheckoutSession',
			PATH_METHOD: 'post',
		},
		stripeCreateCustomerPortalSession: {
			PATH_SEARCH: '/v2/payments/stripe/create-customer-portal-session',
			PATH_METHOD: 'post',
		},
		paymentsStatus: {
			PATH_SEARCH: '/v2/configurations/paymentenabled',
			PATH_METHOD: 'get',
		},
		getCheckoutPageURL: {
			PATH_SEARCH: '/v2/subscription/',
			PATH_METHOD: 'post',
		},
		verifySubscription: {
			PATH_SEARCH: '/v2/subscription/verify',
			PATH_METHOD: 'post',
		},
		deleteSubscription: {
			// only for internal use
			PATH_SEARCH: '/v2/subscription/internal',
			PATH_METHOD: 'delete',
		},
		cancelSubscription: {
			PATH_SEARCH: '/v2/subscription/cancel',
			PATH_METHOD: 'post',
		},
		updateSubscription: {
			PATH_SEARCH: '/v2/subscription/',
			PATH_METHOD: 'put',
		},
		getAvailablePlanPriceIds: {
			PATH_SEARCH: '/v2/subscription/plans',
			PATH_METHOD: 'get',
		},
		getProfileTier: {
			PATH_SEARCH: '/v2/profiles/tier',
			PATH_METHOD: 'get',
		},
		updateProfileTier: {
			PATH_SEARCH: '/v2/profiles/tier',
			PATH_METHOD: 'put',
		},
		generateFlow: {
			PATH_SEARCH: '/v2/synthesis/generate',
			PATH_METHOD: 'post',
		},
		suggestPrompts: {
			PATH_SEARCH: '/v2/synthesis/suggest',
			PATH_METHOD: 'get',
		},
		getNLProgram: {
			PATH_SEARCH: '/v2/synthesis/abstractgen',
			PATH_METHOD: 'post',
		},
		generateDeveloperKey: {
			PATH_SEARCH: '/v2/developer/key',
			PATH_METHOD: 'post',
		},
		regenerateDeveloperKey: {
			PATH_SEARCH: '/v2/developer/key',
			PATH_METHOD: 'put',
		},
		getDeveloperKey: {
			PATH_SEARCH: '/v2/developer/key',
			PATH_METHOD: 'get',
		},
		revokeDeveloperKey: {
			PATH_SEARCH: '/v2/developer/key',
			PATH_METHOD: 'delete',
		},
		createSession: {
			PATH_SEARCH: '/v1/session/new',
			PATH_METHOD: 'post',
		},
		linkSessionToRuntime: {
			PATH_SEARCH: '/v2/brains/linkSessionToRuntime',
			PATH_METHOD: 'post',
		},
		clearSession: {
			PATH_SEARCH: '/v1/session/clear',
			PATH_METHOD: 'post',
		},
		instruct: {
			PATH_SEARCH: '/v1/session/instruct',
			PATH_METHOD: 'post',
		},
		getSession: {
			PATH_SEARCH: '/v1/session',
			PATH_METHOD: 'get',
		},
		getSessionSuggest: {
			PATH_SEARCH: '/v1/session/suggest',
			PATH_METHOD: 'get',
		},
		changeSession: {
			PATH_SEARCH: '/v1/session/change',
			PATH_METHOD: 'post',
		},
		createStep: {
			PATH_SEARCH: '/v1/session/step/add',
			PATH_METHOD: 'post',
		},
		deleteStep: {
			PATH_SEARCH: '/v1/session/step/delete',
			PATH_METHOD: 'delete',
		},
		updateStep: {
			PATH_SEARCH: '/v1/library/step',
			PATH_METHOD: 'put',
		},
		stepSearch: {
			PATH_SEARCH: '/v1/library/step/search',
			PATH_METHOD: 'get',
		},
		generate: {
			PATH_SEARCH: '/v1/session/generate',
			PATH_METHOD: 'post',
		},
		parseRecipe: {
			PATH_SEARCH: '/v1/library/recipe/parse',
			PATH_METHOD: 'post',
		},
		updateRecipe: {
			PATH_SEARCH: '/v1/library/recipe',
			PATH_METHOD: 'put',
		},
		nlFeedback: {
			PATH_SEARCH: '/v2/synthesis/nlFeedback',
			PATH_METHOD: 'post',
		},
		skillTeach: {
			PATH_SEARCH: '/v1/library/skill/teach',
			PATH_METHOD: 'post',
		},
		verifySkill: {
			PATH_SEARCH: '/v1/library/skill/verify',
			PATH_METHOD: 'post',
		},
		undoSession: {
			PATH_SEARCH: '/v1/session/undo',
			PATH_METHOD: 'post',
		},
		redoSession: {
			PATH_SEARCH: '/v1/session/redo',
			PATH_METHOD: 'post',
		},
		generationCorrection: {
			PATH_SEARCH: '/v1/generations/correction',
			PATH_METHOD: 'post',
		},
		generationFeedback: {
			PATH_SEARCH: '/v1/generations/feedback',
			PATH_METHOD: 'post',
		},
		getSkills: {
			PATH_SEARCH: '/v1/library/skills',
			PATH_METHOD: 'get',
		},
		deleteSkill: {
			PATH_SEARCH: '/v1/library/skill/delete',
			PATH_METHOD: 'post',
		},
		updateSkill: {
			PATH_SEARCH: '/v1/library/skill',
			PATH_METHOD: 'put',
		},
		getSkill: {
			PATH_SEARCH: '/v1/library/skill',
			PATH_METHOD: 'get',
		},
	},
};

export default API_ROUTES;
