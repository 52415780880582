import React from 'react';
import { Box, useMediaQuery } from '@chakra-ui/react';

import TopBarRight from './TopBarRight';
import TopBarLeft from './TopBarLeft';

/**
 *
 * @param {import ("./types/index").TopBar} props
 * @returns
 */

const TopBar = ({ topBarOption, topBarStepperHeading }) => {
	const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
	return (
		<Box
			height="70px"
			width="100vw"
			borderBottom="solid 1px"
			borderColor="light.border.gray.200"
			alignItems="center"
			paddingTop="10px"
			paddingX={isSmallerThan900 ? '40px' : '100px'}
			bg="white"
			position="relative"
			zIndex="200"
		>
			<Box display="flex" justifyContent="center" alignItems="center">
				<TopBarLeft
					topBarOption={topBarOption}
					topBarStepperHeading={topBarStepperHeading}
				/>
				<TopBarRight topBarOption={topBarOption} />
			</Box>
		</Box>
	);
};

export default TopBar;
