import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { MayaSkeleton } from '../../skeleton';

const FilterItemSkeleton = () => {
	return (
		<Flex
			alignItems="center"
			padding="8px 16px"
			bg="#fff"
			border="1px solid #dedede"
			borderRadius="2px"
			height="40px"
			boxSizing="border-box"
		>
			<MayaSkeleton width="12px" height="12px" />
			<Box
				textStyle="sans.xs"
				color="light.font.gray.400"
				ml="12px"
				width="80px"
			>
				{/* asd */}
				<MayaSkeleton width="80px" height="16px" />
			</Box>
		</Flex>
	);
};

export default FilterItemSkeleton;
