import React, {
	useState,
	useRef,
	useCallback,
	useMemo,
	useReducer,
	useEffect,
} from 'react';
import ReactFlow, {
	applyNodeChanges,
	applyEdgeChanges,
	addEdge,
	Controls,
	Background,
	ReactFlowProvider,
	useKeyPress,
} from 'react-flow-renderer';
import _ from 'lodash';
import { Box, createStandaloneToast, useDisclosure } from '@chakra-ui/react';
import { nodeTypes } from '../EditorStyles/CustomNode';
import { edgeTypes } from '../EditorStyles/CustomEdge';
import addNode from '../functions/addNode';
import { editorStore, useStore } from '../../zustand';
import './grid.css';
import GridListener from '../GridListener';
import ContextMenuManager from '../ContextMenuManager';
import cloneDeep from 'lodash/cloneDeep';
import { deployEditor, getNodeById } from '../functions/NodeRed';
import SubflowEditor from '../SubflowEditor';
import useStepperStore from '../../../Stepper/StepperStore';
import { v4 as uuidV4 } from 'uuid';
import duplicateNode from '../functions/duplicateNode';
import CenterFlow from './CenterFlow';
import produce, { current } from 'immer';
import getRedInstance from '../functions/getRedInstance';
import {
	parseMayaLangToReactFlow,
	parseReactFlowtoMayaLang,
} from '../functions/FlowMethods';
import { scrambleMayaFlowIds } from '../../Tools/GenerateDAG/utils';
import useSessionId from '../../hooks/useSessionId';
import customTheme from '../../../../../src/library/theme/index';
import useProfileSlug from '../../../../hooks/useProfileSlug';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { usePacEngineStore } from '../../Tools/pac-engine/zustand';
import { stepperFlowTypes } from '../../../Stepper/Sections/registry';
import { segregateFiles } from '@mayahq/ui';
import { getSkillData } from '@mayahq/ui';
import analytics from '../../../../util/Analytics';

const reactFlowDeleteKeyCode = ['Delete', 'Backspace'];

const duplicateNodes = (nodes, offset = 100) => {
	return nodes.map((n) => {
		// TODO: find a better way to adjust position
		const position = {
			x: n.position.x + offset,
			y: n.position.y + offset,
		};
		const nodeType = n.data.node.type;
		const { historyEvent, node } = duplicateNode(
			n.data.node,
			position.x,
			position.y
		);
		return {
			item: {
				id: node.id,
				// need to set this to `special` instead of `node.type` because it
				// renders the node glitched with an outline only
				type: 'special',
				position,
				data: {
					type: nodeType ? nodeType : 'NA',
					historyEvent,
					node: _.omit(node, ['_', '_def', '_config']),
				},
			},
			type: 'add',
		};
	});
};

/**
 *
 * # Workspace Grid
 *
 * - Current flow's grid area.
 *
 * @param {{
 *  tabId: string,
 * 	debounceDeploy: () => void,
 * sessionId: string
 * }} param0
 * @returns
 */
const Grid = ({ sessionId, tabId, debounceDeploy }) => {
	const track = analytics.track;
	const reactFlowWrapper = useRef(null);
	const removeSubflowToastRef = useRef(null);
	const [reactFlowInstance, setReactFlowInstance] = useState(null);
	const tab = useStore(
		useCallback((state) => state.tabs.byId[tabId], [tabId])
	);
	const setDoubleClickedNode = useStore(
		useCallback((state) => state.setDoubleClickedNode, [])
	);
	const setNodesToTab = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.setNodesToTab,
			[]
		)
	);
	const setEdgesToTab = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.setEdgesToTab,
			[]
		)
	);
	const setDirty = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.setDirty,
			[]
		)
	);
	const createSubflowFromSelection = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.createSubflowFromSelection,
			[]
		)
	);
	const workspaceId = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.brainId,
			[]
		)
	);
	const onRfConnectStart = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.onRfConnectStart,
			[]
		)
	);
	const onRfConnectStop = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.onRfConnectStop,
			[]
		)
	);
	const centerFlow = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.centerFlow,
			[tabId]
		)
	);
	const setNodesWithIssues = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.setNodesWithIssues,
			[]
		)
	);
	const fullWorkspaceMayaFlow = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.mayaFlow,
			[]
		)
	);
	const onPasteFlow = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.onPasteFlow,
			[]
		)
	);
	const onFlowExport = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.onFlowExport,
			[]
		)
	);

	const onCommandFocus = useStore(
		useCallback((state) => state.onCommandFocus, [])
	);

	const setErrorNodeId = useStore(
		useCallback((state) => state.setErrorNodeId, [])
	);
	const getReactFlowByTab = useStore(
		useCallback((state) => state.getReactFlowByTab, [])
	);
	const getMayaFlowByTab = useStore(
		useCallback((state) => state.getMayaFlowByTab, [])
	);
	const createSubflow = useStore(
		useCallback((state) => state.createSubflow, [])
	);
	const setFlowToTab = useStore(
		useCallback((state) => state.setFlowToTab, [])
	);
	const onDeploy = useStore(useCallback((state) => state.onDeploy, []));
	const feedbackBoxVisible = usePacEngineStore(
		useCallback((state) => state.feedbackBoxVisible, [])
	);

	const toast = createStandaloneToast({ theme: customTheme });
	const profileSlug = useProfileSlug();

	const {
		isOpen: contextMenuIsOpen,
		onOpen: contextMenuOnOpen,
		onClose: contextMenuOnClose,
	} = useDisclosure();
	const [contextMenuPosition, setContextMenuPosition] = useState({
		x: 0,
		y: 0,
	});
	const insertPosition = useMemo(
		/** @returns {{x: number, y: number} | null} */
		() => {
			if (!reactFlowWrapper.current || !reactFlowInstance) return null;
			const reactFlowBounds =
				reactFlowWrapper.current.getBoundingClientRect();
			return reactFlowInstance.project({
				x: contextMenuPosition.x + 110 - reactFlowBounds.left,
				y: contextMenuPosition.y + 20 - reactFlowBounds.top,
			});
		},
		[contextMenuPosition.x, contextMenuPosition.y, reactFlowInstance]
	);

	// stepper logic
	const addStep = useStepperStore(useCallback((state) => state.addStep, []));
	const openStepper = useStepperStore(
		useCallback((state) => state.openStepper, [])
	);
	const setStore = useStepperStore(useCallback((state) => state.setStore, []));
	const handleImportClick = useCallback(() => {
		const stepperSessionId = uuidV4();
		const initState = {
			stepperSessionId,
			selectedWorkspaceId: workspaceId,
			stepperFlowType: 'workspace.importNewFlow',
		};
		addStep('editor.import', initState);
		setStore({
			selectedWorkspace: workspaceId, // Keeping this around for backward compatibility
			selectedWorkspaceId: workspaceId,
		});
		openStepper();
	}, [addStep, openStepper, setStore, workspaceId]);

	async function handlePasteFlowClick() {
		try {
			const res = await navigator.clipboard.readText();
			onPasteFlow({
				mayaFlow: JSON.parse(res),
				tabId: tabId,
			});
		} catch (error) {
			console.error(error);
			toast({
				title: 'Error',
				description: error.message,
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	}

	const handleExportClick = useCallback(() => {
		const stepperSessionId = uuidV4();
		const initState = {
			stepperSessionId,
			stepperFlowType: 'workspace.exportFlow',
			workspaceId: workspaceId,
		};
		addStep('editor.export', initState);
		openStepper();
	}, [addStep, openStepper, workspaceId]);

	const onInit = (_reactFlowInstance) =>
		setReactFlowInstance(_reactFlowInstance);

	const onNodesChange = useCallback(
		(changes) => {
			// TODO: update x,y position of node in node-red when change.type === "position"
			const updatedNodes = applyNodeChanges(
				changes,
				tab.reactFlowByTab.nodes
			);
			setNodesToTab(updatedNodes, tabId);
		},
		[setNodesToTab, tab.reactFlowByTab.nodes, tabId]
	);

	const onEdgesChange = useCallback(
		(changes) => {
			for (let change of changes) {
				if (change.type === 'remove') {
					const removingEdge = tab.reactFlowByTab.edges.find(
						(e) => e.id === change.id
					);
					if (removingEdge.source && removingEdge.target) {
						const links = window.RED.nodes.filterLinks({
							source: { id: removingEdge.source },
							target: { id: removingEdge.target },
							sourcePort: parseInt(removingEdge.sourceHandle),
						});
						links.forEach((l) => window.RED.nodes.removeLink(l));
					}
				}
			}
			let updatedEdges = applyEdgeChanges(changes, tab.reactFlowByTab.edges);
			// Setting the newly created edges' type as custom
			updatedEdges = updatedEdges.map((edge) => {
				return { ...edge, type: 'custom' };
			});
			setEdgesToTab(updatedEdges, tabId);
			setDirty(true);
		},
		[setDirty, setEdgesToTab, tab.reactFlowByTab.edges, tabId]
	);

	const onConnect = useCallback(
		(connection) => {
			const sourceNode = tab.reactFlowByTab.nodes.find(
				(n) => n.id === connection.source
			);
			const targetNode = tab.reactFlowByTab.nodes.find(
				(n) => n.id === connection.target
			);
			if (
				sourceNode &&
				sourceNode.data.node &&
				targetNode &&
				targetNode.data.node
			) {
				window.RED.nodes.addLink({
					source: sourceNode.data.node,
					target: targetNode.data.node,
					sourcePort: parseInt(connection.sourceHandle),
				});
			}

			let updatedEdges = addEdge(connection, tab.reactFlowByTab.edges);
			updatedEdges = updatedEdges.map((edge) => {
				return { ...edge, type: 'custom' };
			});
			setEdgesToTab(updatedEdges, tabId);
			setDirty(true);
		},
		[
			setDirty,
			setEdgesToTab,
			tab.reactFlowByTab.edges,
			tab.reactFlowByTab.nodes,
			tabId,
		]
	);

	const onDragOver = useCallback((event) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = 'move';
	}, []);

	const addNodeHandler = useCallback(
		(position, nodeType, nodeStyle, nodeId) => {
			const { historyEvent, node } = addNode(
				nodeType,
				position.x,
				position.y,
				tabId,
				nodeId
			);
			const newNode = {
				id: nodeId,
				type: nodeStyle,
				position,
				data: {
					type: nodeType ? nodeType : 'NA',
					historyEvent,
					node: _.omit(node, ['_', '_def', '_config']),
				},
			};
			setNodesToTab([...tab.reactFlowByTab.nodes, newNode], tabId);
			setDirty(true);
		},
		[setDirty, setNodesToTab, tab.reactFlowByTab.nodes, tabId]
	);

	const editNodeProperties = useCallback((node) => {
		setDoubleClickedNode(node);
		console.log('DOUBLE CLICK', node);
		if (node.type === 'special') {
			// add node to moving set
			window.RED.view.movingSet.clear();
			const doubleClickedNode = getNodeById(node.id);
			window.RED.view.movingSet.add(doubleClickedNode);
			// invoke drawer open
			window.RED.actions.invoke('core:edit-selected-node');
		} else {
		}
	}, []);

	const deleteNodeFromSubflow = useCallback(
		(deletedNode) => {
			const onSubflow = tab.type === 'subflow';
			if (onSubflow) {
				const RED = getRedInstance();
				const nodesWithTheSame_id = RED.nodes
					.createCompleteNodeSet()
					.filter(
						(node) =>
							node._id === deletedNode._id &&
							node.id !== deletedNode.id &&
							node
					);

				nodesWithTheSame_id.forEach((node) => {
					const tabId = node.z;
					const existingReactFlow = getReactFlowByTab({ tabId });
					const newNodes = existingReactFlow.nodes.filter(
						(n) => n.id !== node.id
					);
					setFlowToTab(tabId, {
						nodes: newNodes,
						edges: existingReactFlow.edges,
					});
				});
			}
		},
		[getReactFlowByTab, setFlowToTab, tab.type]
	);

	const onNodesDelete = useCallback(
		(nodes) => {
			for (let node of nodes) {
				window.RED.nodes.remove(node.id);
				setDirty(true);
				deleteNodeFromSubflow(node);
			}
			setNodesWithIssues(tab.reactFlowByTab.nodes);
		},
		[
			deleteNodeFromSubflow,
			setDirty,
			setNodesWithIssues,
			tab.reactFlowByTab.nodes,
		]
	);

	const createSubflowInstance = async ({ subflowNodeId }) => {
		const ogWorkspaceSubflowNode = tab.mayaFlowByTab.find(
			(node) => node.id === subflowNodeId
		);
		const ogSubflowProps = cloneDeep(
			window.RED.nodes
				.createCompleteNodeSet()
				.find(
					(node) => node.id === ogWorkspaceSubflowNode.type.split(':')[1]
				)
		);
		const ogSubflowTabId = ogSubflowProps.id;
		const newSubflowTabId = window.RED.nodes.id();

		const newSubflowProps = {
			...ogSubflowProps,
			id: newSubflowTabId,
			_id: ogWorkspaceSubflowNode.type.split(':')[1],
			name: ogSubflowProps.name,
		};

		const ogSubflowNodes = cloneDeep(
			window.RED.nodes
				.createCompleteNodeSet()
				.filter((node) => node.z === ogSubflowTabId)
		);

		const newSubflowNodesWithZIds = ogSubflowNodes.map((n) => {
			n.z = newSubflowTabId;
			return n;
		});

		let newSubflowNodes = await scrambleMayaFlowIds({
			mayaFlow: [newSubflowProps, ...newSubflowNodesWithZIds],
		});

		let updatedSubflowProps = newSubflowNodes.pop();

		const existingReactFlow = getReactFlowByTab({ tabId });
		const { nodes, edges } = parseMayaLangToReactFlow(
			newSubflowNodes,
			1,
			'transient'
		);
		setFlowToTab(tabId, {
			nodes: [...existingReactFlow.nodes, ...nodes],
			edges: [...existingReactFlow.edges, ...edges],
		});
		await new Promise((r) => setTimeout(r, 500));
		try {
			await onDeploy(sessionId);
		} catch (err) {
			toast({
				title: `Couldn't sync session!`,
				description: `Couldn't sync session with the server`,
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
		await new Promise((r) => setTimeout(r, 500));
		try {
			createSubflow({
				flow: newSubflowNodes,
				subflowNode: updatedSubflowProps,
				loc: {
					x: ogWorkspaceSubflowNode['x'],
					y: ogWorkspaceSubflowNode['y'],
				},
			});
		} catch (err) {
			toast({
				title: `Instance creation failed`,
				description: `Node selection malformed, refresh and try again`,
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}

		// Ugly redeploy to fix the ports visibility issue
		await new Promise((r) => setTimeout(r, 500));

		try {
			await onDeploy(sessionId);
		} catch (err) {
			toast({
				title: `Couldn't sync session!`,
				description: `Couldn't sync session with the server`,
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	const onFlattenSubflow = async ({ subflowNodeId, reactFlowSubflowNode }) => {
		toast.closeAll();
		removeSubflowToastRef.current = toast({
			title: 'Flattening subflow',
			description: 'Extracting flow and removing subflow..',
			duration: 9000,
			isClosable: false,
			position: 'top',
			status: 'info',
		});

		try {
			const RED = getRedInstance();
			const currentFlow = RED.nodes.createCompleteNodeSet();
			// Deleting the subflow node
			onNodesDelete([reactFlowSubflowNode]);
			onNodesChange([{ id: reactFlowSubflowNode.id, type: 'remove' }]);
			// Declaring variables needed later for removing subflow and setting transient nodes
			const subflowNode = currentFlow.find((n) => n.id === subflowNodeId);
			const subflowTab = currentFlow.find(
				(n) => n.id === subflowNode.type.split(':')[1]
			);
			const subflowNodes = currentFlow.filter((n) => {
				if (n.z === subflowTab.id) {
					return true;
				} else {
					return false;
				}
			});
			const subflowNodesWithChangedZ = subflowNodes.map((n) => {
				return { ...n, z: tabId };
			});
			const newSubflowNodes = await scrambleMayaFlowIds({
				mayaFlow: subflowNodesWithChangedZ,
			});
			// Removing subflow tab and subflow nodes part of it
			RED.subflow.removeSubflow(subflowTab.id, false);
			await deployEditor(true, true, RED.nodes.createCompleteNodeSet());
			await new Promise((r) => setTimeout(r, 1000));
			// Setting subflow nodes as transient to the active tab
			const existingReactFlow = getReactFlowByTab({ tabId });
			const { nodes, edges } = parseMayaLangToReactFlow(
				newSubflowNodes,
				1,
				'transient'
			);
			setFlowToTab(tabId, {
				nodes: [...existingReactFlow.nodes, ...nodes],
				edges: [...existingReactFlow.edges, ...edges],
			});
			await onDeploy(sessionId);
			if (removeSubflowToastRef.current) {
				toast.update(removeSubflowToastRef.current, {
					title: 'Subflow Removed',
					duration: 2000,
					isClosable: true,
					position: 'top',
					status: 'success',
				});
			}
		} catch (err) {
			console.error(err);
			if (removeSubflowToastRef.current) {
				toast.close(removeSubflowToastRef.current);
			}
			toast({
				title: 'Error while removing subflow',
				description:
					'There was an error while removing subflow. Please try again.',
				duration: 2000,
				isClosable: true,
				position: 'bottom',
				status: 'error',
			});
		}
	};

	async function getTeachableSkillZip({ storeZipIn }) {
		const flow = await onFlowExport({
			profileSlug,
			brainId: workspaceId,
			mode: 'selected',
		});

		const zip = new JSZip();
		zip.folder('recipes');
		zip.folder('steps');
		zip.folder('docs');

		const stepSamples = {};
		const subflowTabs = {};

		flow.forEach((node) => {
			if (node.type === 'subflow') {
				console.log('peep sf', node);
				subflowTabs[node.id] = node;
				if (!stepSamples[node._id]) {
					stepSamples[node._id] = {};
				}
				stepSamples[node._id][node.id] = [];
			}
		});

		flow.forEach((node) => {
			if (node.type === 'subflow') {
				stepSamples[node._id][node.id].push(node);
			} else if (node.type.startsWith('subflow:')) {
				const id = node.type.split(':')[1];
				const parentTabNode = subflowTabs[id];
				stepSamples[parentTabNode._id][parentTabNode.id].push(node);
			} else if (!node.x && !node.y) {
				// Avoid config nodes for now
				return;
			} else {
				const parentTabNode = subflowTabs[node.z];
				if (parentTabNode) {
					stepSamples[parentTabNode._id][node.z].push(node);
				}
			}
		});

		// Handle config nodes separately
		flow.forEach((node) => {
			if (!node.x && !node.y && node.type !== 'subflow') {
				Object.keys(stepSamples).forEach((groupId) => {
					Object.keys(stepSamples[groupId]).forEach((sampleId) => {
						const configNodeBelongsToSample = stepSamples[groupId][
							sampleId
						].some((n) => Object.values(n).includes(node.id));
						if (configNodeBelongsToSample) {
							stepSamples[groupId][sampleId].push(node);
						}
					});
				});
			}
		});

		const recipeSamples = {};
		const docs = [];
		flow
			.filter((n) => n.type === 'comment')
			.forEach((node) => {
				const { name, info } = node;
				if (info.includes('<recipe>') && info.includes('</recipe>')) {
					const recipeGroup = name.split(':')[0];
					const recipeTitle = name.replace(`${recipeGroup}:`, '').trim();
					const recipeContent = info
						.split('<recipe>')[1]
						.split('</recipe>')[0]
						.trim();
					if (!recipeSamples[recipeGroup]) {
						recipeSamples[recipeGroup] = [];
					}

					recipeSamples[recipeGroup].push(
						`${recipeTitle}\n\n----\n\n${recipeContent}`
					);
				} else if (info.includes('<doc>') && info.includes('</doc>')) {
					const docContent = info
						.split('<doc>')[1]
						.split('</doc>')[0]
						.trim();
					docs.push({
						name: name,
						content: docContent,
					});
				}
			});

		Object.keys(stepSamples).forEach((groupId) => {
			const subflowTabNodes = Object.values(subflowTabs).filter(
				(tab) => tab._id === groupId
			);
			let name = groupId;
			for (let i = 0; i < subflowTabNodes.length; i++) {
				const tab = subflowTabNodes[i];
				const nameParts = tab.name.split(':');
				if (nameParts.length > 1) {
					if (!nameParts[0].includes(' ')) {
						name = nameParts[0];
						break;
					}
				}
			}

			Object.keys(stepSamples[groupId]).forEach((sampleId) => {
				const idx = stepSamples[groupId][sampleId].findIndex(
					(n) => n.type === 'subflow'
				);
				const tab = stepSamples[groupId][sampleId][idx];
				const nameParts = tab.name.split(':');
				if (nameParts.length > 1 && !nameParts[0].includes(' ')) {
					stepSamples[groupId][sampleId][idx].name = stepSamples[groupId][
						sampleId
					][idx].name
						.replace(`${nameParts[0]}:`, '')
						.trim();
				}

				zip.file(
					`steps/${name}/${sampleId}.json`,
					JSON.stringify(stepSamples[groupId][sampleId], null, 4)
				);
			});
		});

		Object.keys(recipeSamples).forEach((recipeGroup) => {
			recipeSamples[recipeGroup].forEach((recipe, idx) => {
				zip.file(`recipes/${recipeGroup}/s${idx}.txt`, recipe);
			});
		});

		docs.forEach((doc) => {
			zip.file(`docs/${doc.name}.txt`, doc.content);
		});

		if (storeZipIn === 'local') {
			zip.generateAsync({ type: 'blob' }).then((content) => {
				saveAs(content, 'skill.zip');
			});
		} else if (storeZipIn === 'memory') {
			return await zip.generateAsync({ type: 'blob' }).then((content) => {
				return content;
			});
		}
	}

	const teachSubflow = async () => {
		track(`[Editor] > Grid > Teach`);
		const stepperSessionId = uuidV4();
		const initState = {
			stepperSessionId: stepperSessionId,
			stepperFlowType: stepperFlowTypes.teach.TEACH_SKILL,
			selecterWorkspaceId: null,
			showStoreOption: true,
		};
		const memoryZip = await getTeachableSkillZip({
			storeZipIn: 'memory',
		});

		// copied from handleUpload
		const fileContent = await JSZip.loadAsync(memoryZip);
		const data = await segregateFiles(fileContent);
		const skillData = getSkillData('bruh', data);
		const zipData = {
			steps: data.structuredSteps,
			docs: data.structuredDocs,
			recipes: data.structuredRecipes,
			name: 'skill.zip',
		};

		// copied from goToNextStep
		const nextStepState = {
			...initState,
			...zipData,
			skillData: skillData,
			metadata: {
				source: 'manual',
			},
		};
		const stepperFlowType = initState.stepperFlowType;
		switch (stepperFlowType) {
			case stepperFlowTypes.teach.TEACH_SKILL: {
				addStep('teach.skill', nextStepState);
			}
		}

		openStepper();
	};

	const contextMenuReducer = useCallback(
		(contextMenuState, action) => {
			switch (action.type) {
				case 'search':
					return {
						menuItems: [],
						context: action.type,
					};
				case 'selection':
					const selectionMenuObject = {
						menuItems: [
							{
								id: 1,
								label: 'create subflow',
								group: 'primary',
								onClick: () => {
									createSubflowFromSelection(sessionId);
									contextMenuOnClose();
								},
							},
							{
								id: 2,
								label: 'export flow',
								group: 'primary',
								onClick: () => {
									handleExportClick();
									contextMenuOnClose();
								},
							},
							{
								id: 3,
								label: 'duplicate',
								command: 'Cmd + D',
								group: 'primary',
								onClick: () => {
									onNodesChange(duplicateNodes(action.nodes));
									contextMenuOnClose();
								},
							},
							{
								id: 4,
								label: 'delete',
								command: 'Del',
								group: 'primary',
								onClick: () => {
									onNodesDelete(action.nodes);
									onNodesChange(
										_.map(action.nodes, (n) => {
											return { id: n.id, type: 'remove' };
										})
									);
									contextMenuOnClose();
								},
							},
						],
						context: action.type,
					};
					const allNodesAreSubflowOrComment = action.nodes.every(
						(node) =>
							node.data.type.includes('subflow') ||
							node.data.type === 'comment'
					);
					if (allNodesAreSubflowOrComment) {
						selectionMenuObject.menuItems.push(
							{
								id: 7,
								label: 'get skill zip',
								group: 'secondary',
								onClick: () => {
									getTeachableSkillZip({ storeZipIn: 'local' });
									contextMenuOnClose();
								},
							},
							{
								id: 8,
								label: 'teach',
								group: 'secondary',
								onClick: () => {
									teachSubflow();
									contextMenuOnClose();
								},
							}
						);
					}
					return selectionMenuObject;
				case 'node':
				case 'edge':
					const nodeAndEdgeMenuObject = {
						menuItems: [
							{
								id: 1,
								label: 'edit properties',
								group: 'primary',
								onClick: () => {
									editNodeProperties(action.node);
									contextMenuOnClose();
								},
							},
							{
								id: 2,
								label: 'duplicate',
								command: 'Cmd + D',
								group: 'primary',
								onClick: () => {
									onNodesChange(duplicateNodes([action.node]));
									contextMenuOnClose();
								},
							},
							{
								id: 3,
								label: 'delete',
								group: 'primary',
								command: 'Del',
								onClick: () => {
									onNodesDelete([action.node]);
									onNodesChange([
										{ id: action.node.id, type: 'remove' },
									]);
									contextMenuOnClose();
								},
							},
						],
						context: action.type,
					};
					if (action.node.data.type.includes('subflow')) {
						nodeAndEdgeMenuObject.menuItems.push(
							{
								id: 4,
								label: 'flatten subflow',
								group: 'secondary',
								onClick: () => {
									onFlattenSubflow({
										subflowNodeId: action.node.id,
										reactFlowSubflowNode: action.node,
									});
									contextMenuOnClose();
								},
							},
							{
								id: 5,
								label: 'create instance',
								group: 'secondary',
								onClick: () => {
									createSubflowInstance({
										subflowNodeId: action.node.id,
									});
									contextMenuOnClose();
								},
							}
						);
					}
					const currentFlow = getReactFlowByTab({ tabId });
					if (
						currentFlow.nodes.every(
							(node) =>
								node.data.type.includes('subflow') ||
								node.data.type === 'comment'
						)
					) {
						nodeAndEdgeMenuObject.menuItems.push(
							{
								id: 6,
								label: 'get skill zip',
								group: 'secondary',
								onClick: () => {
									getTeachableSkillZip({ storeZipIn: 'local' });
									contextMenuOnClose();
								},
							},
							{
								id: 7,
								label: 'teach',
								group: 'secondary',
								onClick: () => {
									teachSubflow();
									contextMenuOnClose();
								},
							}
						);
					}

					return nodeAndEdgeMenuObject;
				case 'default':
				case 'pane':
				default:
					return {
						menuItems: [
							{
								id: 1,
								label: 'insert node',
								group: 'primary',
								onClick: () => {
									contextMenuDispatch({ type: 'search' });
								},
							},
							{
								id: 3,
								label: 'import flow',
								group: 'primary',
								onClick: () => {
									handleImportClick();
									contextMenuOnClose();
								},
							},
							{
								id: 4,
								label: 'paste flow',
								group: 'primary',
								onClick: () => {
									handlePasteFlowClick();
									contextMenuOnClose();
								},
							},
						],
						context: action.type || 'default',
					};
			}
		},
		[
			contextMenuOnClose,
			createSubflowFromSelection,
			editNodeProperties,
			handleExportClick,
			handleImportClick,
			onNodesChange,
			onNodesDelete,
		]
	);

	const [contextMenuState, contextMenuDispatch] = useReducer(
		contextMenuReducer,
		{ menuItems: [], context: 'pane' }
	);

	const onPaneContextMenu = useCallback(
		(e) => {
			e.preventDefault();
			const rect = e.currentTarget.getBoundingClientRect();
			const { clientX, clientY } = e;
			setContextMenuPosition({
				x: clientX - rect.left,
				y: clientY - rect.top,
			});
			contextMenuDispatch({ type: 'pane' });
			contextMenuOnOpen();
		},
		[contextMenuOnOpen]
	);

	const onNodeContextMenu = useCallback(
		(e, node) => {
			e.preventDefault();
			const { clientX, clientY } = e;
			const reactFlowBounds =
				reactFlowWrapper.current.getBoundingClientRect();
			setContextMenuPosition({
				x: clientX - reactFlowBounds.left,
				y: clientY - reactFlowBounds.top,
			});
			contextMenuDispatch({ type: 'node', node });
			contextMenuOnOpen();
		},
		[contextMenuOnOpen]
	);
	const onSelectionContextMenu = useCallback(
		(e, nodes) => {
			e.preventDefault();
			const { clientX, clientY } = e;
			const reactFlowBounds =
				reactFlowWrapper.current.getBoundingClientRect();
			setContextMenuPosition({
				x: clientX - reactFlowBounds.left,
				y: clientY - reactFlowBounds.top,
			});
			contextMenuDispatch({ type: 'selection', nodes });
			contextMenuOnOpen();
		},
		[contextMenuOnOpen]
	);

	const addNodeToSubflowInstances = useCallback(
		(position, nodeType, nodeStyle, nodeId) => {
			const RED = getRedInstance();
			const onSubflow = tab.type === 'subflow';
			const allIds = cloneDeep(useStore.getState().tabs.allIds);
			const byId = cloneDeep(useStore.getState().tabs.byId);

			const subflowIdsWithSame_id = allIds
				.map((id) => {
					if (byId[id]._id === tab._id && byId[id].id !== tab.id) {
						return id;
					} else {
						return null;
					}
				})
				.filter((x) => x);

			if (onSubflow) {
				subflowIdsWithSame_id.forEach((tabId) => {
					// copied from add node handler
					const newNodeId = RED.nodes.id();
					const { historyEvent, node } = addNode(
						nodeType,
						position.x,
						position.y,
						tabId,
						newNodeId
					);
					const newNode = {
						id: newNodeId,
						_id: nodeId,
						type: nodeStyle,
						position,
						data: {
							type: nodeType ? nodeType : 'NA',
							historyEvent,
							node: _.omit({ ...node, _id: nodeId }, [
								'_',
								'_def',
								'_config',
							]),
						},
					};
					const existingSubflowReactFlow = getReactFlowByTab({ tabId });
					setNodesToTab(
						// @ts-ignore
						[...existingSubflowReactFlow.nodes, newNode],
						tabId
					);
				});
			}
		},
		[getReactFlowByTab, setNodesToTab, tab._id, tab.id, tab.type]
	);

	const handleDrop = useCallback(
		(e) => {
			e.preventDefault();
			const { clientX, clientY } = e;

			const reactFlowBounds =
				reactFlowWrapper.current.getBoundingClientRect();
			const nodeStyle = e.dataTransfer.getData('application/reactflow');
			const nodeType = e.dataTransfer.getData('application/type');

			const RED = getRedInstance();
			const [nodeWidth, nodeHeight] = RED.view.calculateNodeDimensions();

			const x = clientX - reactFlowBounds.left - nodeWidth / 2;
			const y = clientY - reactFlowBounds.top - nodeHeight / 2;

			const position = reactFlowInstance.project({ x, y });
			const newNodeId = RED.nodes.id();
			addNodeHandler(position, nodeType, nodeStyle, newNodeId);
			addNodeToSubflowInstances(position, nodeType, nodeStyle, newNodeId);
		},
		[addNodeHandler, addNodeToSubflowInstances, reactFlowInstance]
	);

	const handleNodeDoubleClick = useCallback(
		(e, node) => {
			editNodeProperties(node);
		},
		[editNodeProperties]
	);

	useEffect(() => {
		const handler = (e) => {
			//Focus on the node with the id
			const info = e?.detail;
			if (!info) {
				return;
			}

			// console.log('heyo', e)

			let nodeId = info.sourceNode.id;
			let debugMsg = info.debugDetails.msg;
			if (typeof debugMsg !== 'object') {
				try {
					debugMsg = JSON.parse(debugMsg);
				} catch (e) {}
			}

			if (debugMsg && typeof debugMsg === 'object') {
				if (debugMsg.__isError || debugMsg.error || debugMsg._error) {
					const errorSource = debugMsg.__errorSource;
					if (errorSource) {
						nodeId = errorSource;
					}
				}
			}

			const node = tab.reactFlowByTab.nodes.find((n) => n.id === nodeId);
			// console.log('heyo', nodeId, node, tab)
			if (!node) {
				return;
			}

			setErrorNodeId(nodeId);
			setTimeout(() => setErrorNodeId(''), 3000);
			onCommandFocus({
				shouldCenter: true,
				x: node.position.x,
				y: node.position.y,
			});
		};

		document.addEventListener('maya::debugMessageIdClick', handler);
		return () => {
			document.removeEventListener('maya::debugMessageIdClick', handler);
		};
	}, [tab]);

	const nodes = tab.reactFlowByTab.nodes.map((node) => {
		return {
			...node,
			data: {
				...node.data,
				debounceDeploy,
			},
		};
	});

	// console.log('zss bruh');

	const copyShortcutPressed = useKeyPress(['Control+c', 'Meta+c', 'Strg+c']);
	const selectAllShortcutPressed = useKeyPress([
		'Control+a',
		'Meta+a',
		'Strg+a',
	]);

	useEffect(() => {
		if (selectAllShortcutPressed) {
			const currentFlow = getReactFlowByTab({ tabId });
			const newFlowNodes = currentFlow.nodes.map((node) => ({
				...node,
				selected: true,
			}));
			const newFlowEdges = currentFlow.edges.map((edge) => ({
				...edge,
				selected: true,
			}));
			setFlowToTab(tabId, { nodes: newFlowNodes, edges: newFlowEdges });
		}
	}, [getReactFlowByTab, selectAllShortcutPressed, setFlowToTab, tabId]);

	useEffect(() => {
		try {
			const handler = async () => {
				let flow = await onFlowExport({
					profileSlug,
					brainId: workspaceId,
					mode: 'selected',
				});
				flow = flow.filter((n) => n.type !== 'subflow' && n.type !== 'tab');
				if (flow.length > 0) {
					await navigator.clipboard.writeText(JSON.stringify(flow));
					toast({
						title: 'Flow copied',
						duration: 2000,
						isClosable: true,
						position: 'top',
						status: 'info',
					});
				}
			};
			if (copyShortcutPressed) {
				handler();
			}
		} catch (err) {
			console.error(err);
			toast({
				title: 'Copy error!',
				description: `We ran into an error while copying. Please make sure your flow is valid and try again.`,
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	}, [copyShortcutPressed]);

	return (
		<Box
			className="dndflow"
			h="full"
			w="full"
			// borderLeft='1px solid #bbbbbb'
		>
			{tab.type === 'subflow' ? <SubflowEditor subflowId={tabId} /> : null}
			<ReactFlowProvider>
				<Box
					className="reactflow-wrapper"
					ref={reactFlowWrapper}
					position="relative"
				>
					<ReactFlow
						nodes={nodes}
						edges={cloneDeep(tab.reactFlowByTab.edges)}
						onNodesChange={onNodesChange}
						onEdgesChange={onEdgesChange}
						onConnect={onConnect}
						onConnectStart={onRfConnectStart}
						onConnectStop={onRfConnectStop}
						nodeTypes={nodeTypes}
						edgeTypes={edgeTypes}
						onInit={onInit}
						deleteKeyCode={reactFlowDeleteKeyCode}
						onDrop={handleDrop}
						onDragOver={onDragOver}
						onNodeDoubleClick={handleNodeDoubleClick}
						onNodesDelete={onNodesDelete}
						connectionMode="strict"
						onPaneContextMenu={onPaneContextMenu}
						onNodeContextMenu={onNodeContextMenu}
						onSelectionContextMenu={onSelectionContextMenu}
						onPaneClick={contextMenuOnClose}
						snapToGrid={true}
						panOnScroll={true}
						onClick={() => {
							editorStore.setState(
								produce((state) => {
									state.centerFlow.shouldCenter = false;
								})
							);
							editorStore.setState({ focusedStepNodeIds: [] });
						}}
						onMoveStart={() => {
							editorStore.setState(
								produce((state) => {
									state.centerFlow.shouldCenter = false;
								})
							);
						}}
					>
						<Controls />
						<Background
							size={1}
							// gap={14}
							style={{
								background: 'white',
							}}
							color="#D1D1D1"
						/>
						{centerFlow.shouldCenter ? (
							<CenterFlow
								x={centerFlow.x}
								y={centerFlow.y}
								zoom={centerFlow.zoom}
							/>
						) : null}
					</ReactFlow>

					<GridListener tabId={tabId} nodes={tab.reactFlowByTab.nodes} />
					{contextMenuIsOpen ? (
						<ContextMenuManager
							onClose={contextMenuOnClose}
							menuItems={contextMenuState.menuItems}
							context={contextMenuState.context}
							insertPosition={
								insertPosition ? insertPosition : { x: 0, y: 0 }
							}
							containerProps={{
								position: 'absolute',
								zIndex: 'popover',
								left: contextMenuPosition.x,
								top: contextMenuPosition.y,
							}}
						/>
					) : null}
				</Box>
			</ReactFlowProvider>
		</Box>
	);
};

export default React.memo(Grid);
