import React from 'react';
import { Box, IconProps } from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';

export type MayaActionBtnProps = {
	// not a traditional button, so no ButtonProps Click : ButtonProps['onClick']
	onClick: () => void;
	icon?: IconProps;
	text: string;
};

/**
 * Action Button for Clear, Back, Close actions
 */
export const MayaActionButton = ({
	text,
	icon,
	onClick,
}: MayaActionBtnProps) => {
	return (
		<Box
			color="light.font.gray.400"
			paddingY="10px"
			display="flex"
			alignItems="center"
			justifyContent="center"
			cursor="pointer"
			width="60px"
			mr="4"
			onClick={onClick}
		>
			{icon ? icon : <SmallCloseIcon />}
			<Box ml="3px" textStyle="sans.xs" color="light.font.gray.400">
				{text}
			</Box>
		</Box>
	);
};
