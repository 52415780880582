import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';
import {
	useDisclosure,
	Flex,
	Box,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Input,
} from '@chakra-ui/react';
import ConfigProfile from './ConfigProfile';
import ConfigProfileEditor from './ConfigProfileEditor';
import removeConfigProfile from '../../../../../functions/configuration/removeConfigProfile';
import { MayaSkeleton } from '@mayahq/ui';
import ConfigProfileSkeleton from './ConfigProfileSkeleton';

const ConfigProfileList = ({
	module,
	configProfiles,
	selectMode = false,
	selectedProfile,
	newProfileButtonText = '+ Connect',
	loading = false,
	onSelectProfile = (profile) => null,
	onDeleteProfile = (profileId) => null,
}) => {
	const [configProfileMap, setConfigProfileMap] = useState({});
	const [profileToEdit, setProfileToEdit] = useState(null);
	const [addProfileMode, setAddProfileMode] = useState(false);
	// const [selectedProfileId, setSelectedProfileId] = useState(null)

	// Initialising local state from props because I don't want to
	// prop-drill, don't want to redux and configProfile data is not
	// required anywhere. This is NOT an antipattern.
	useEffect(() => {
		if (configProfiles) {
			const cpMap = {};
			configProfiles.forEach((profile) => (cpMap[profile._id] = profile));
			setConfigProfileMap(cpMap);
		}
	}, [configProfiles]);

	function handleSelect(e, profileId) {
		if (selectMode) {
			e.stopPropagation();
			// setSelectedProfileId(profileId)
			onSelectProfile(configProfileMap[profileId]);
		}
	}

	function updateConfigProfile(profileId, profile) {
		setConfigProfileMap(
			update(configProfileMap, {
				[profileId]: {
					$set: profile,
				},
			})
		);

		if (selectMode) {
			// setSelectedProfileId(profile._id)
			onSelectProfile(profile);
		}
	}

	function deleteProfile(profile) {
		return new Promise((resolve, reject) => {
			const { referenceId } = configProfileMap[profile._id];
			const { _id: moduleId, name: moduleName } = module;

			removeConfigProfile(referenceId, moduleId, moduleName)
				.then((res) => {
					delete configProfileMap[profile._id];
					setConfigProfileMap({ ...configProfileMap });

					// If the deleted profile was earlier selected, unselect it.
					// if (selectMode && selectedProfileId === profileId) {
					if (selectMode && selectedProfile?._id === profile._id) {
						onSelectProfile(null);
					}
					onDeleteProfile(profile._id);

					resolve();
				})
				.catch(reject);
		});
	}

	console.log('yeee configMap', configProfileMap, selectedProfile);

	const configProfileItems = Object.values(configProfileMap).map((profile) => {
		return (
			<Box key={profile._id} mb="8px">
				<ConfigProfile
					configProfile={profile}
					onEditClick={() => setProfileToEdit(profile._id)}
					onClick={(e) => handleSelect(e, profile._id)}
					// selected={profile._id === selectedProfileId}
					selected={profile._id === selectedProfile?._id}
					onDelete={() => deleteProfile(profile)}
				/>
			</Box>
		);
	});

	function exitEditor() {
		setProfileToEdit(null);
		setAddProfileMode(false);
	}

	const showEditor = profileToEdit || addProfileMode;

	let skeletons = [];
	if (loading) {
		skeletons = [1, 2, 3].map((idx) => (
			<Box key={idx} mb="8px">
				<ConfigProfileSkeleton />
			</Box>
		));
	}

	return (
		<Flex
			direction="column"
			// onClick={handleUnselect}
		>
			{showEditor && (
				<ConfigProfileEditor
					configProfile={configProfileMap[profileToEdit]}
					onExitEditor={exitEditor}
					module={module}
					onUpdateConfigProfile={updateConfigProfile}
					mode={addProfileMode ? 'add' : 'edit'}
				/>
			)}
			{!showEditor && (
				<Box>
					{loading ? skeletons : configProfileItems}
					<Box
						padding="8px 16px"
						textStyle="sans.xs"
						color="light.font.gray.400"
						bg="light.theme.gray.100"
						borderRadius="2px"
						border="1px solid"
						borderColor="light.border.gray.100"
						mt="20px"
						onClick={() => setAddProfileMode(true)}
						cursor="pointer"
						// width='110px'
						display="flex"
						justifyContent="center"
						_hover={{
							bg: '#fff',
						}}
					>
						{newProfileButtonText}
					</Box>
				</Box>
			)}
		</Flex>
	);
};

export default ConfigProfileList;
