import React, { useState } from 'react';
import {
	Flex,
	Box,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	PopoverFooter,
	PopoverArrow,
	PopoverCloseButton,
	// PopoverAnchor,
} from '@chakra-ui/react';
import { SettingsIcon, CloseIcon } from '@chakra-ui/icons';
import MayaFilledButton from '../../../../../library/buttons/FilledButton';

const IconBox = (props) => {
	const { selected } = props
	return (
		<Flex
			{...props}
			color={selected ? "#fff" : undefined}
			height="25px"
			width="25px"
			bg={selected ? "#666" : "light.theme.gray.200"}
			border="1px solid"
			borderColor={selected ? "#6a6a6a" : "light.border.gray.200"}
			justifyContent="center"
			alignItems="center"
			borderRadius="2px"
			cursor="pointer"
			// transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			_hover={{
				bg: selected ? '#777' : 'light.theme.gray.300',
			}}
		/>
	);
};

const ConfigProfile = ({
	configProfile,
	onEditClick,
	onDelete,
	onClick,
	selected,
}) => {
	const [deleteLoading, setDeleteLoading] = useState(false);

	return (
		<Box
			display="flex"
			textStyle="sans.xs"
			bg={selected ? "#555" : "#fff"}
			border="1px solid"
			borderColor={
				selected ? "light.theme.gray.500" : 'light.border.gray.100'
			}
			padding="0px 16px"
			color={selected ? "#fff" : "light.font.gray.400"}
			borderRadius="2px"
			height="40px"
			alignItems="center"
			cursor="pointer"
			// justifyContent='space-between'
		>
			<Flex
				textStyle="sans.xs"
				fontSize="sm"
				value={configProfile.name}
				height="30px"
				pl="4px"
				alignItems="center"
				flex="1 1 auto"
				onClick={onClick}
			>
				{configProfile.name}
			</Flex>
			<Flex>
				<IconBox mr="8px" onClick={onEditClick} selected={selected}>
					<SettingsIcon
						w={2.5}
						h={2.5}
						color={selected ? "#fff" : "light.theme.gray.400"}
						// rotate={isOpen ? '45deg' : '0deg'}
						transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
					/>
				</IconBox>
				<Popover placement="top-start">
					{({ isOpen, onClose }) => (
						<>
							<PopoverTrigger>
								<Box>
									<IconBox selected={selected}>
										<CloseIcon
											w={2}
											h={2}
											color={selected ? "#fff" : "light.theme.gray.400"}
										/>
									</IconBox>
								</Box>
							</PopoverTrigger>
							<PopoverContent
								// maxWidth='200px'
								borderRadius="2px"
								_focus={{
									outline: 'none',
									border: 'light.border.gray.200',
								}}
							>
								<PopoverArrow />
								<PopoverCloseButton />
								<PopoverHeader
									textStyle="sans.sm"
									color="light.font.gray.400"
								>
									Delete profile?
								</PopoverHeader>
								<PopoverBody
									textStyle="sans.xs"
									color="light.font.gray.300"
								>
									This will remove your credentials from everywhere and
									is irreversible.
								</PopoverBody>
								<PopoverFooter>
									<MayaFilledButton
										text={deleteLoading ? 'Deleting' : 'Delete'}
										size="sm"
										showDotPattern={true}
										colorScheme="purple"
										buttonProps={{
											// width: '100px',
											// mt: '16px',
											ml: '1px',
										}}
										isLoading={deleteLoading}
										onClick={() => {
											setDeleteLoading(true);
											onDelete().then(() => {
												onClose();
												setDeleteLoading(false);
											});
										}}
									/>
								</PopoverFooter>
							</PopoverContent>
						</>
					)}
				</Popover>
			</Flex>
		</Box>
	);
};

export default ConfigProfile;
