/**
 * # NR Layout Functions/API
 *
 * - Node Red native functions/api to adjust the layout of the editor.
 */

import getRedInstance from '../getRedInstance';

// /**
//  * Show or Hide the RHS side bar in the editor.
//  *
//  * @param {boolean} state set `true` to show and `false` to hide
//  */
// export const toggleRightSideBar = (state) => {
// 	const RED = getRedInstance();
// 	RED.sidebar.toggleSidebar(state);
// };

/**
 * Show or Hide the RHS side bar in the editor.
 *
 * @param {boolean} debugState set `true` to show and `false` to hide
 */
 export const toggleRightSideBar = (debugState, infoState = false) => {
	const RED = getRedInstance();
	if (!debugState && !infoState) {
		RED.sidebar.toggleSidebar(false);
	} else {
		if (debugState) {
			RED.actions.invoke('core:show-debug-tab', true);
		} else {
			RED.actions.invoke('core:show-help-tab', true);
		}
	}
};

/**
 * Show or hide the RHS Node Palette in the editor.
 *
 * @param {boolean} state set `true` to show and `false` to hide
 */
export const toggleLeftNodePalette = (state) => {
	const RED = getRedInstance();
	RED.actions.invoke('core:toggle-palette', state);
};
