import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
	Box,
	Flex,
	useColorMode,
	useDisclosure,
	useMediaQuery,
	createStandaloneToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Button,
} from '@chakra-ui/react';
import { ErrorBoundary } from 'react-error-boundary';
import { connect, useDispatch, useSelector } from 'react-redux';
import getDashboardData from '../../functions/dashboard/getDashboard';
import getToken from '../../functions/brain/getToken';
import isElectron from 'is-electron';
import {
	setDashboardInfo,
	updateBrainById,
	setNavPageMode,
} from '../../redux/actions';
import startBrain from '../../functions/brain/startBrain';
import getBrainById from '../../functions/brain/getBrainById';
import redScript from './redScript';
import Workspace from './Workspace';
import { useTabStore } from '../Electron/Tabs/zustand';
import NewToggle from './Workspace/NewToggle';
import FeedbackBtn from '../Dashboard/Background/TopBar/TopBarRight/Feedback';
import EditorLoader from './EditorLoader';
import { mayaRedCustomEvents } from './config';
import { getEditorMode } from './Workspace/functions/Misc';
import { config } from '../../config';
import useProfileSlug from '../../hooks/useProfileSlug';
import theme from '../../library/theme';
import _ from 'lodash';
import MayaFilledButton from '../../library/buttons/FilledButton';
import useQueryParams from '../../util/useQueryParams';
// import './editor.css';

const toast = createStandaloneToast({ theme: theme });

const RedEditor = ({
	brainId,
	runtime,
	brainStatus,
	token,
	viewportGridRef,
}) => {
	const [renderWorkspace, setRenderWorkspace] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const loadStyles = useCallback(() => {
		if (getEditorMode() !== 'old') {
			// @ts-ignore
			import('./style.css'); // load new style.css
		} else {
			// @ts-ignore
			import('./style.backup.css'); // else load old style.css
		}
	}, []);

	useEffect(() => {
		/**
		 * This useEffect mounts the new editor only when red-editor
		 * init is completed via custom events.
		 */
		/** Load styles: old or new */
		loadStyles();
		/** Run after editor is init, ie. on `redOnInit` custom event dispatched from node-red  */
		const listener = (e) => {
			if (getEditorMode() !== 'old') setRenderWorkspace(true);
		};
		document.addEventListener(mayaRedCustomEvents.redOnInit, listener);
		return () => {
			document.removeEventListener(mayaRedCustomEvents.redOnInit, listener);
		};
	}, [loadStyles]);

	return (
		<>
			{renderWorkspace && (
				<Workspace
					runtime={runtime}
					token={token}
					brainId={brainId}
					viewportGridRef={viewportGridRef}
				/>
			)}
		</>
	);
};

function ErrorFallback({ error, resetErrorBoundary }) {
	return (
		<div role="alert">
			<p>Something went wrong:</p>
			<pre>{error.message}</pre>
			<button onClick={resetErrorBoundary}>Try again</button>
		</div>
	);
}

class EditorErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		console.error('There was an error', error, errorInfo);
	}

	render() {
		return (
			<>
				<Modal
					isOpen={this.state.hasError}
					onClose={() => null}
					isCentered
					size="md"
				>
					<ModalOverlay />
					<ModalContent borderRadius="2px">
						<ModalBody
							flex="1"
							minHeight="0"
							display="flex"
							flexDirection="column"
							p="0"
							borderRadius={'2px'}
							height="200px"
						>
							<Flex direction="column" flex="1" minHeight="0" p="4">
								<Box textStyle="serif.md" marginBottom="0.5rem">
									Delete skill?
								</Box>
								<Box
									fontWeight="medium"
									opacity="0.6"
									textStyle="sams.md"
									borderRadius="2px"
									mt="8px"
								>
									This action is irreversible and will permanently
									delete this skill.
								</Box>
							</Flex>
						</ModalBody>
						<ModalFooter>
							<MayaFilledButton
								text="Yes, delete"
								onClick={window.location.reload}
								colorScheme="purple"
								size="sm"
								showDotPattern={false}
								buttonProps={{
									mr: '8px',
								}}
							/>
						</ModalFooter>
					</ModalContent>
				</Modal>
				{this.props.children}
			</>
		);
	}
}

/**
 * EditorFetch Component
 * @param {*} props
 */

const EditorFetch = ({ setNavPageMode, updateBrainById, brains }) => {
	const { colorMode } = useColorMode();
	const [runtime, setRuntime] = React.useState('');
	const [brainId, setBrainId] = React.useState('');
	// const [token, setToken] = React.useState('');
	const [starting, setStarting] = React.useState(true);
	const [redScriptIsInjectedInBrain, setRedScriptIsInjectedInBrain] =
		React.useState(false);
	const [viewportLoaded, setViewportLoaded] = useState(false);
	const [loaderComplete, setLoaderComplete] = useState(false);
	const { selected } = useTabStore((state) => {
		return {
			selected: state.selected,
		};
	});
	const dispatch = useDispatch();
	const errorToastRef = useRef(null);
	const query = useQueryParams();
	const token = useSelector(
		/**
		 *
		 * @param {import('../../redux/reducers/types/userReducer').UserState} state
		 * @returns
		 */
		(state) => state.user.tokens.accessToken
	);

	// console.log('login2', token);

	const selectedSlug = useProfileSlug();

	const brainIds = useSelector(
		/**
		 *
		 * @param {{ brains: import('../../redux/reducers/types/brains').Brains} } state
		 * @returns
		 */
		(state) => state.brains.allIds
	);

	const selectedWorkspaceName = useSelector(
		/**
		 *
		 * @param {{ brains: import('../../redux/reducers/types/brains').Brains} } state
		 * @returns
		 */
		(state) => state.brains?.byId[brainId]?.name
	);

	const selectedWorkspaceSessionId = useSelector(
		/**
		 *
		 * @param {{ brains: import('../../redux/reducers/types/brains').Brains} } state
		 * @returns
		 */
		(state) => state.brains?.byId[brainId]?.sessionId
	);

	const viewportGridRef = React.useRef();

	const slug = selectedSlug;

	React.useEffect(() => {
		setNavPageMode('editor');
		// Update the document title using the browser API
	}, [setNavPageMode]);

	useEffect(() => {
		getDashboardData({ slug: selectedSlug })
			.then(async (res) => {
				if (res['error']) {
					return;
				} else {
					//status = "offline"
					dispatch(setDashboardInfo(res));
				}
			})
			.catch((e) => {
				console.error('Error fetching profile data', e);
			});
	}, [selectedSlug]);

	useEffect(() => {
		function viewportLoadListener(e) {
			// console.log('Bee::Event Listener fired', e);
			setLoaderComplete(true);
			setTimeout(() => setViewportLoaded(true), 600);
		}

		document.addEventListener('maya::viewportLoaded', viewportLoadListener, {
			once: true,
		});
		// console.log('Bee::Event listener added');
	}, []);

	useEffect(() => {
		// console.log('yum running useEffect', runtime, token);
		if (!runtime || !token) {
			return;
		}

		async function init() {
			// console.log('yum initialising workspace', runtime, token);
			await redScript(runtime, token);
			setRedScriptIsInjectedInBrain(true);
			setStarting(false);
		}

		init();
	}, [runtime, token]);

	/**
	 * Online State Update
	 *
	 * useEffect hook to update results in Redux state for online state
	 */
	React.useEffect(() => {
		// if (selectedSlug === '_') {
		// 	return
		// }
		try {
			// if (brainIds.length === 0) {
			// 	return;
			// }

			const handleDone = (formData, res, fetchAgain) => {
				// console.log('yum res is', res);
				// getToken(formData).then((tokenResponse) => {
				// 	if (tokenResponse.error) {
				// 		return;
				// 	}

				// 	if (tokenResponse.results) {
				// 		setToken(tokenResponse['results']);
				// 	}
				// });

				// console.log('yum got to update res', res);
				setRuntime(res.url);
			};

			getDashboardData({ slug: selectedSlug })
				.then(async (res) => {
					// console.log("This is B")
					setDashboardInfo(res);
					let search = window.location.search;
					let params = new URLSearchParams(search);
					let id = params.get('id');
					let formData = {
						brainId: id,
						profileSlug: selectedSlug,
					};
					setBrainId(id);

					await getBrainById({ brainId: id, slug: selectedSlug })
						.then(async (res) => {
							if (res.status === 'STARTED') {
								handleDone(formData, res, true);
							} else if (res.status === 'STOPPED') {
								setStarting(true);
								startBrain({
									brain: res,
									slug,
									onDone: () => {
										handleDone(formData, res, true);
									},
									// @ts-ignore
									OnError: () => {},
									onLoading: () => {},
								});
							} else if (res.status === 'PENDING') {
								setStarting(true);
								if (!isElectron()) {
									return;
								}
								// @ts-ignore
								var electron = window.electron;
								var { ipcRenderer } = electron;
								ipcRenderer.removeAllListeners(
									'/brain/status/start/' + res._id
								);
								ipcRenderer.handle(
									'/brain/status/start/' + res._id,
									(event, arg) => {
										if (arg === 'STARTED') {
											updateBrainById(res._id, {
												...res,
												status: 'STARTED',
											});
											getBrainById({
												brainId: id,
												slug: selectedSlug,
											}).then(async (response) => {
												if (res.status === 'STARTED') {
													handleDone(formData, res, true);
												}
											});
										}
									}
								);
							}
						})
						.catch((err) => {
							console.error('Editor Error', err);
						});
					//fetchData(id);
				})
				.catch((err) => {
					console.error('Editor Error', err);
				});
		} catch (error) {
			console.error('letting it fail gracefully', error);
		}
	}, [selected, selectedSlug]);

	const [isSmallerThan500] = useMediaQuery('(max-width: 500px)');

	React.useEffect(() => {
		const handleError = (e) => {
			console.error(e);
			e.stopPropagation();
			e.preventDefault();

			if (errorToastRef.current) {
				return;
			}

			errorToastRef.current = toast({
				duration: null,
				isClosable: process.env.NODE_ENV === 'development' ? true : false,
				status: 'error',
				render: () => {
					return (
						<Flex
							bg="red.500"
							padding="16px 32px"
							borderRadius="5px"
							direction="column"
							color="white"
						>
							<Flex fontSize="20px" fontWeight="500">
								Error
							</Flex>
							<Flex mt="4px">
								There was an unrecoverable error. Please refresh the
								page.
							</Flex>
							<Flex mt="16px" justifyContent="flex-end">
								<Button
									onClick={() => window.location.reload()}
									// variant='ghost'
									colorScheme="red"
									bg="red.400"
								>
									Refresh
								</Button>
							</Flex>
						</Flex>
					);
				},
			});
		};

		// window.addEventListener('unhandledrejection', handleError);
		// window.addEventListener('error', handleError);

		// return () => {
		// 	// window.removeEventListener('error', handleError);
		// 	window.removeEventListener('unhandledrejection', handleError);
		// };
	}, []);

	return (
		// @ts-ignore
		<Box height="100%" width="100%" position="relative">
			<Box id="red-ui-editor" />
			<Flex
				position="absolute"
				right="20%"
				top={isElectron() ? '47px' : '2px'}
				zIndex="10000"
			>
				<FeedbackBtn />
			</Flex>
			{runtime === '' || !viewportLoaded ? (
				<EditorLoader
					// @ts-ignore
					text={starting ? 'Starting workspace...' : 'Loading editor...'}
					workspaceName={selectedWorkspaceName}
					complete={loaderComplete}
					workspaceId={brainId}
					workspaceSessionId={selectedWorkspaceSessionId}
					showGenerateProgress={
						query.get('showGenerateProgress') === 'true'
					}
				/>
			) : null}
			{redScriptIsInjectedInBrain ? (
				<Box height="100%" width="100%">
					<RedEditor
						brainId={brainId}
						runtime={runtime}
						brainStatus={brains.byId[brainId]?.status}
						token={token}
						viewportGridRef={viewportGridRef}
					/>
				</Box>
			) : null}
		</Box>
	);
};

const mapStateToProps = (state) => {
	const { status } = state.user;
	const { user } = state;
	const { device } = state.devices.current;
	const { brains } = state;
	const deviceId = status === 'online' ? (device ? device._id : null) : null;

	return { status, device, brains, deviceId, user };
};

export default connect(mapStateToProps, {
	setDashboardInfo,
	updateBrainById,
	setNavPageMode,
})(EditorFetch);
