import { Flex } from '@chakra-ui/react';
import React from 'react';
import { EditorContextMenu } from '@mayahq/ui';
import SearchContextMenu from './SearchContextMenu';

/**
 * # Editor Context Menu Manager
 *
 * - Renders various context menus on the editor canvas.
 *
 * @param {{
 * 	onClose: () => void,
 * 	insertPosition: {
 * 		x: number,
 * 		y: number,
 * 	},
 * 	menuItems: import('@mayahq/ui').EditorMenuItemConfig[],
 * 	context: string,
 * 	containerProps: import('@chakra-ui/react').FlexProps
 * }} param0
 */
const ContextMenuManager = ({
	onClose,
	containerProps,
	menuItems,
	context,
	insertPosition,
}) => {
	return (
		<Flex
			direction="column"
			onKeyDown={(e) => e.stopPropagation()}
			{...containerProps}
		>
			{context !== 'search' ? (
				<EditorContextMenu
					containerProps={{
						bg: 'light.theme.gray.200',
						borderRadius: 'sm',
						minWidth: '56',
						py: '0.5',
						boxShadow: 'lg',
						borderWidth: '1.5px',
						borderStyle: 'solid',
						borderColor: 'light.border.gray.200',
					}}
					menuItems={menuItems}
				/>
			) : (
				<SearchContextMenu
					onClose={onClose}
					insertPosition={insertPosition}
				/>
			)}
		</Flex>
	);
};

export default ContextMenuManager;
