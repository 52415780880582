/**
 * User Actions
 *
 * Action creators for User based actions.
 */

// Dependencies
// import { get } from "lodash";

// Action Types
import actionTypes from '../actionTypes';

/**
 * Set the User Object
 *
 * Attempt to set the user object with data about the user.
 *
 * @param {Object} user The user object returned from FusionAuth /api/login.
 */
const setUser = (user) => (dispatch, getState) => {
	// Grab only the pertinent information to send to storage. We don't want everything.
	const savedUser = {
		email: user.email,
		username: user.username,
		firstName: user.firstName,
		lastName: user.lastName,
		id: user.id,
		hasAccess: user?.data?.hasAccess === 'yes' ? true : false
	};

	// Dispatch the result.
	dispatch({
		type: actionTypes.SET_USER,
		payload: {
			info: savedUser,
		},
	});
};

/**
 * Logout User
 *
 * Logs out the user by using the LOGOUT_USER action type. This will
 * set the user.info object to null.
 */
const logoutUser = () => (dispatch, getState) => {
	// Dispatch the result.
	dispatch({
		type: actionTypes.LOGOUT_USER,
	});
	dispatch({
		type: actionTypes.CLEAR_BRAINS,
	});
	dispatch({
		type: actionTypes.CLEAR_TABS,
	});
	dispatch({
		type: actionTypes.CLEAR_DEVICES,
	});
	dispatch({
		type: actionTypes.CLEAR_PROFILES,
	});
};

/**
 * Set User status
 *
 * Logs out the user by using the LOGOUT_USER action type. This will
 * set the user.info object to null.
 */
const setStatus = (status) => (dispatch, getState) => {
	// Dispatch the result.
	// offline if desktop app is user skips signing in
	// online if the user does log in
	dispatch({
		type: actionTypes.SET_STATUS,
		payload: status,
	});
};

const setAuthTokens =
	({ accessToken, refreshToken, lastUpdated }) =>
	(dispatch) => {
		dispatch({
			type: actionTypes.SET_AUTH_TOKENS,
			payload: {
				accessToken,
				refreshToken,
				lastUpdated: lastUpdated ? lastUpdated : Date.now(),
			},
		});
	};

// Export the actions.
export { setUser, logoutUser, setStatus, setAuthTokens };
