// Dependency imports

// import worker from 'workerize-loader!../../../src/maya-service-worker'; // eslint-disable-line import/no-webpack-loader-syntax

import APIFunction from '../../util/APIFetch/function';

import APIRoutes from '../../util/APIRoutes';

// config
import { config } from '../../config';
/**
 * Function to get all profiles for a user
 */
const removeUserFromTeam = async ({
	teamProfileSlug,
    userProfileSlug,
}) => {
	// Create an instance of your worker
	var removeUserFromTeamFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.removeUserFromTeam.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.removeUserFromTeam.PATH_METHOD,
	});

    const formData = {
        teamProfileSlug,
        userProfileSlug,
    }

	var removeUserFromTeamResult = await removeUserFromTeamFn(formData);
	return removeUserFromTeamResult['data'];
};

export default removeUserFromTeam;