import React from 'react'
import {
    Flex, Box, 
    Avatar,
    Img,
} from '@chakra-ui/react'

export const TeamInvite = ({
    email
}) => {
    return (
        <Flex
            bg='#fff'
            border='1px solid'
            borderColor='light.border.gray.200'
            borderRadius='2px'
            height='48px'
            alignItems='center'
            textStyle='sans.xs'
        >
            <Flex
                px='12px'
            >
                <Avatar 
                    name={email} 
                    size='sm'
                    border='1px solid #D8D8D8'
                    bg='light.theme.yellow.200'
                    color='light.font.gray.300'
                />
            </Flex>
            <Flex
                flex='1 1 auto'
                // bg='tomato'
                height='100%'
                px='8px'
                alignItems='center'
                color='light.font.gray.400'
            >
                {email}
            </Flex>
            <Flex
                width='115px'
                height='100%'
                alignItems='center'
                padding='8px 8px'
            >
                <Flex
                    width='100%'
                    height='100%'
                    color='light.font.gray.300'
                    alignItems='center'
                    border='1px solid'
                    borderColor='light.border.gray.200'
                    bg='light.theme.yellow.200'
                    justifyContent='center'
                >
                    Invited
                </Flex>
            </Flex>
        </Flex>
    )
}

export default TeamInvite