import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/react';
import SettingsItem from '../SettingsItem';
import ManageButton from './ManageButton';
import managePaymentsPortal from '../../../../../functions/payments/managePaymentsPortal';
import { MayaSkeleton } from '@mayahq/ui';
import getSubscriptionDetails from '../../../../../functions/payments/getSubscriptionDetails';
import { useSelector } from 'react-redux';
import createCheckoutSession from '../../../../../functions/payments/createCheckoutSession';
import theme from '../../../../../library/theme';

const Billing = () => {
	const toast = createStandaloneToast({ theme: theme });
	const profileIsLoading = useSelector(
		(state) => state.profiles.profilesLoading
	);
	const [isLoading, setIsLoading] = useState(true);
	const [manageBtnIsLoading, setManageBtnIsLoading] = useState(false);
	const [subscribeBtnIsLoading, setsubscribeBtnIsLoading] = useState(false);
	const [buttonType, setButtonType] = useState(
		/**
		 * @returns {'subscribe' | 'manage' | 'unknown'}
		 */
		() => 'unknown'
	);

	const handleManageClick = useCallback(async () => {
		try {
			setManageBtnIsLoading(true);
			const returnUrl = window.location.href;
			const url = await managePaymentsPortal({ returnUrl });
			window.open(url, '_blank').focus();
		} catch (error) {
			console.error(error);
			toast({
				status: 'error',
				title: 'Something went wrong!',
				description: 'Please try again later.',
			});
		} finally {
			setManageBtnIsLoading(false);
		}
	}, []);

	const handleSubscribeClick = useCallback(async () => {
		try {
			setsubscribeBtnIsLoading(true);
			const currentPageUrl = window.location.href;
			const { url } = await createCheckoutSession({
				successUrl: currentPageUrl,
				cancelUrl: currentPageUrl,
			});
			if (!url) throw new Error(`invalid url/response`);
			window.open(url, '_blank').focus();
		} catch (error) {
			console.error(error);
			toast({
				status: 'error',
				title: 'Something went wrong!',
				description: 'Please try again later.',
			});
		} finally {
			setsubscribeBtnIsLoading(false);
		}
	}, []);

	const checkForActiveSubscription = useCallback(async () => {
		try {
			setIsLoading(true);
			const gsdRes = await getSubscriptionDetails();
			if (!gsdRes)
				throw new Error('getSubscriptionDetails response invalid!');
			if (gsdRes.subscription?.status === 'active') setButtonType('manage');
			else setButtonType('subscribe');
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		if (!profileIsLoading) checkForActiveSubscription();
	}, [checkForActiveSubscription, profileIsLoading]);

	return (
		<Box>
			{isLoading ? (
				<SettingsItem
					text={<MayaSkeleton w="10rem" h="1.5rem" />}
					description={<MayaSkeleton w="25rem" h="1rem" mt="2" />}
					rightItem={
						<>
							<MayaSkeleton w="5rem" h="1.5rem" mr="4" />
						</>
					}
					onClick={() => {}}
				/>
			) : (
				// <MayaSkeleton w="full" h="4.5rem" />
				<SettingsItem
					text="Billing Details"
					description="Subscribe or manage your subscription details here."
					rightItem={
						<>
							{buttonType === 'subscribe' ? (
								<ManageButton
									buttonProps={{
										mr: '4',
										isLoading: subscribeBtnIsLoading,
									}}
									onClick={handleSubscribeClick}
								>
									Subscribe
								</ManageButton>
							) : null}
							{buttonType === 'manage' ? (
								<ManageButton
									buttonProps={{
										mr: '4',
										isLoading: manageBtnIsLoading,
									}}
									onClick={handleManageClick}
								>
									Manage
								</ManageButton>
							) : null}
						</>
					}
					onClick={() => {}}
				/>
			)}
		</Box>
	);
};

export default Billing;
