import isElectron from 'is-electron';
import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

export async function updateTokensForElectron({ accessToken, refreshToken }) {
	if (!isElectron()) {
		return;
	}

	const updateTokensIPCFunction = IPCFunction({
		EVENT: IPCRoutes.ipcService.updateTokens.EVENT,
	});

	const result = await updateTokensIPCFunction({
		accessToken,
		refreshToken,
	});
}
