import {
	Box,
	createStandaloneToast,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Textarea,
} from '@chakra-ui/react';
import { MayaFilledButton } from '@mayahq/ui';
import React, { useRef, useState } from 'react';
import { FiThumbsDown, FiThumbsUp } from 'react-icons/fi';
import { IoEllipsisVertical } from 'react-icons/io5';
import generationFeedback from '../../../../../functions/pac-engine/generationFeedback';
import theme from '../../../../../library/theme';

const StepFeedbackCollection = ({
	step,
	isHovered,
	handleGenerate,
	instructGenerationId,
}) => {
	const toast = createStandaloneToast({ theme: theme });

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [feedbackMessage, setFeedbackMessage] = useState('');
	const [feedbackScore, setFeedbackScore] = useState(123);
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [showFeedbackOptions, setShowFeedbackOptions] = useState(false);

	let feedbackType;
	let generationId;
	if (step.generation_id) {
		feedbackType = 'flow';
		generationId = step.generation_id;
	} else if (instructGenerationId) {
		feedbackType = 'recipe';
		generationId = instructGenerationId;
	} else {
		feedbackType = null;
		generationId = null;
	}

	const feedbackTextareaRef = useRef(null);

	const handleFeedback = async ({
		feedbackScore,
		feedbackMessage,
		showSuccessToast,
	}) => {
		try {
			let modifiedMessage = feedbackMessage;
			if (feedbackType === 'recipe') {
				modifiedMessage = `feedback for the following step from the generated recipe:\n ${step.prefix} ${step.text} \n ${feedbackMessage}\n`;
			}
			const res = await generationFeedback({
				feedbackMessage: modifiedMessage,
				feedbackScore,
				generationId,
			});
			console.log(res);
			if (showSuccessToast) {
				toast({
					description: `Thank you! We will use your feedback to make Maya better.`,
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
			}
		} catch (err) {
			console.error(err);
			toast({
				title: 'Error!',
				description: `Something went wrong. Please try again.`,
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		} finally {
			setFeedbackMessage('');
		}
	};

	const handleThumbsUp = () => {
		try {
			setIsPopoverOpen(false);
			setShowFeedbackOptions(false);
			setFeedbackScore(1);
			handleFeedback({
				feedbackScore: 1,
				feedbackMessage,
				showSuccessToast: true,
			});
		} catch (err) {
			console.error(err);
		}
	};

	const handleThumbsDown = () => {
		try {
			setIsPopoverOpen(false);
			setShowFeedbackOptions(false);
			setFeedbackScore(0);
			handleFeedback({
				feedbackScore: 0,
				feedbackMessage,
				showSuccessToast: false,
			});
			setIsModalOpen(true);
		} catch (err) {
			console.error(err);
		}
	};

	const showStepPopoverIcon = (isHovered && step.text !== '') || isPopoverOpen;

	return (
		<>
			<Popover
				placement="bottom-start"
				isOpen={isPopoverOpen}
				onClose={() => setIsPopoverOpen(false)}
				closeOnBlur={true}
			>
				<PopoverTrigger>
					<Box
						marginTop="3px"
						cursor={showStepPopoverIcon ? 'pointer' : 'text'}
						opacity={showStepPopoverIcon ? '100' : '0'}
						pointerEvents={showStepPopoverIcon ? 'auto' : 'none'}
						color="light.font.gray.200"
						_hover={{ color: 'light.font.gray.400' }}
						onClick={() => {
							setIsPopoverOpen((p) => !p);
							setShowFeedbackOptions(false);
						}}
						tabIndex={72}
					>
						<IoEllipsisVertical />
					</Box>
				</PopoverTrigger>
				<PopoverContent
					borderRadius="2px"
					border="1px solid"
					borderColor="light.border.gray.200"
					boxShadow="none"
					bg="light.theme.yellow.200"
					_focus={{
						outline: 'none',
					}}
					width="10rem"
					textStyle="sans.sm"
					fontSize="14px"
				>
					<PopoverBody
						borderBottom="1px solid #d8d8d8"
						_hover={{ bg: '#e2e8f0' }}
						cursor="pointer"
						userSelect="none"
						onClick={() => {
							setIsPopoverOpen(false);
							setShowFeedbackOptions(false);
							handleGenerate();
						}}
					>
						{step?.generated || step?.error ? 'regenerate' : 'generate'}
					</PopoverBody>
					{feedbackType ? (
						<PopoverBody
							borderBottom="1px solid #d8d8d8"
							color={showFeedbackOptions ? '#fff' : '#555'}
							bg={
								showFeedbackOptions ? '#666' : 'light.theme.yellow.200'
							}
							_hover={{
								bg: showFeedbackOptions ? undefined : '#e2e8f0',
							}}
							cursor="pointer"
							userSelect="none"
							onClick={() => {
								setShowFeedbackOptions((f) => !f);
							}}
						>
							give feedback
						</PopoverBody>
					) : null}
					{showFeedbackOptions ? (
						<>
							<PopoverBody cursor="pointer" userSelect="none">
								{feedbackType === 'flow'
									? "Did Maya get the step's flow right?"
									: feedbackType === 'recipe'
									? 'Did Maya get the step right?'
									: null}
							</PopoverBody>
							<PopoverBody padding="0">
								<Flex justifyContent="space-around">
									<Box
										paddingBlock="10px"
										paddingLeft="30px"
										flexGrow={1}
										_hover={{ bg: '#e2e8f0' }}
										cursor="pointer"
										color="#555"
										onClick={handleThumbsUp}
									>
										<FiThumbsUp size={18} />
									</Box>
									<Box
										paddingBlock="10px"
										paddingLeft="30px"
										flexGrow={1}
										_hover={{
											bg: '#e2e8f0',
										}}
										cursor="pointer"
										tabIndex={89}
										bg="light.theme.yellow.200"
										color="#555"
										onClick={handleThumbsDown}
									>
										<FiThumbsDown size={18} />
									</Box>
								</Flex>
							</PopoverBody>
						</>
					) : null}
				</PopoverContent>
			</Popover>
			<Modal
				isOpen={isModalOpen}
				onClose={() => {
					setIsModalOpen(false);
				}}
				isCentered
				initialFocusRef={feedbackTextareaRef}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton _focus={{ outline: 'none' }} />
					<ModalBody bg="#F7F6F3" padding="4" borderRadius="2px">
						<Flex alignItems="center" marginBottom="0.5rem">
							<Box textStyle="serif.md" fontWeight="500" fontSize="18px">
								{feedbackType === 'recipe'
									? 'What went wrong in the step?'
									: feedbackType === 'flow'
									? 'What went wrong in the flow?'
									: null}
							</Box>
						</Flex>
						<Textarea
							ref={feedbackTextareaRef}
							_focus={{ outline: 'none' }}
							value={feedbackMessage}
							onChange={(e) => setFeedbackMessage(e.target.value)}
							bg="#fff"
							borderRadius="2px"
						/>
						<Flex justifyContent="flex-end" paddingTop="16px">
							<MayaFilledButton
								colorScheme="gray"
								text="Submit"
								onClick={() => {
									setIsModalOpen(false);
									handleFeedback({
										feedbackScore,
										feedbackMessage,
										showSuccessToast: true,
									});
								}}
							/>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default React.memo(StepFeedbackCollection);
