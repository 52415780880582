import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
	Flex,
	Box,
	Input,
	InputGroup,
	InputRightElement,
	InputLeftAddon,
} from '@chakra-ui/react';
import { ArrowForwardIcon, CheckIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { MayaFilledButton } from '@mayahq/ui';
import checkSlug from '../../../../functions/team/checkSlug';
import createTeam from '../../../../functions/team/createTeam';
import useStepperStore from '../../StepperStore';
import { stepperFlowTypes } from '../registry';
import {
	addTeamProfile,
	setCurrentProfile,
	setProfiles,
} from '../../../../redux/actions';
import useChecklistItems from '../../../Checklist/hooks/useChecklistItems';
import checklistItems from '../../../Checklist/registry';
import useStepperAnalytics from '../../hooks/useStepperAnalytics';
import switchProfile from '../../../../functions/profile/switchProfile';
import { useLocation } from 'react-router-dom';
import Avatar from '../../../Dashboard/Background/TopBar/TopBarRight/ProfileButton/Avatar';
import updateMetadata from '../../../../functions/profile/updateMetadata';
const allChecklistItems = checklistItems;

const errors = {
	CANNOT_START_WITH_NUMBER_OR_DASH: `Can't start username with a number or '-'`,
	CANNOT_CONTAIN_SPECIAL_CHARACTERS: `Can't use special characters in team slug.`,
};

export const CreateTeam = () => {
	useStepperAnalytics();
	const location = useLocation();

	// Local UI state
	const [existenceCheckLoading, setExistenceCheckLoading] = useState(false);
	const [profileSlugTaken, setProfileSlugTaken] = useState(false);
	const [teamCreationLoading, setTeamCreationLoading] = useState(false);
	const [error, setError] = useState(null);

	const { teams, userSlug } = useSelector(
		/**
		 *
		 * @param {import('../../../../redux/reducers/types/profileReducer').ProfilesState} state
		 * @returns
		 */
		(state) => ({
			teams: Object.values(state.profiles.profiles).filter((p) => p.isTeam),
			userSlug: state.profiles.userSlug,
		})
	);

	// Initialising stepper
	const { updateStepState, addStep, nextStep, steps, closeStepper } =
		useStepperStore((state) => {
			return {
				updateStepState: state.updateStepState,
				addStep: state.addStep,
				nextStep: state.nextStep,
				steps: state.steps,
				closeStepper: state.closeStepper,
			};
		});

	// Step state (stored in zustand)
	const stepState = useStepperStore(
		useCallback(
			(state) =>
				state.steps.byId['onboarding.createTeam'] || {
					profileSlug: '',
					teamName: '',
				},
			[]
		)
	);
	const { profileSlug = '', teamName = '' } = stepState;
	const setProfileSlug = (val) => {
		const valToSet = val.toLowerCase().split(' ').join('-');
		if (/^[0-9]$/.test(valToSet[0]) || valToSet[0] === '-') {
			console.log('eee', valToSet);
			setError(errors.CANNOT_START_WITH_NUMBER_OR_DASH);
		} else {
			const regex = /^[a-z0-9-]+$/;
			const acceptable = regex.test(valToSet);
			if (!acceptable) {
				setError(errors.CANNOT_CONTAIN_SPECIAL_CHARACTERS);
			} else {
				setError(null);
			}
		}

		updateStepState({ ...stepState, profileSlug: valToSet });
	};
	const setTeamName = (val) =>
		updateStepState({ ...stepState, teamName: val });

	// To dispatch redux actions
	const dispatch = useDispatch();

	const { checklistItems, updateChecklistItem } = useChecklistItems();

	const checkSlugTakenDebounced = useCallback(
		_.debounce((slug, setValue) => {
			checkSlug({ slug: slug })
				.then((exists) => {
					setValue(exists);
				})
				.catch((e) => console.error('cte', e));
		}, 300),
		[]
	);

	async function handleTeamUrlChange(e) {
		const newTeamUrl = e.target.value;
		setProfileSlug(newTeamUrl);
	}

	async function createNewTeam() {
		let teamNameToSubmit = teamName;
		if (teamName.length === 0) {
			teamNameToSubmit = `Team ${_.startCase(profileSlug)}`;
		}

		return await createTeam({
			workspaceName: teamNameToSubmit,
			slug: profileSlug,
		});
	}

	const goToNextStep = useCallback(
		async (mode) => {
			const currentStep = steps.byId['onboarding.createTeam'];
			const currentState = currentStep.state;
			const { stepperFlowType, signupMetadata } = currentState;
			const nextStepState = { ...currentState };

			let teamProfile = teams[0];

			if (mode !== 'SWITCH') {
				setTeamCreationLoading(true);

				teamProfile = await createNewTeam();
				if (!checklistItems[allChecklistItems.createNewTeam].done) {
					await updateChecklistItem(allChecklistItems.createNewTeam, true);
				}
				setTeamCreationLoading(false);
				dispatch(addTeamProfile(teamProfile));
			}

			switch (stepperFlowType) {
				case stepperFlowTypes.onboarding.NORMAL: {
					switchProfile(teamProfile.profileSlug, location);
					// addStep('onboarding.describeAutomation', nextStepState);
					updateMetadata({
						slug: userSlug,
						metadata: {
							onboarded: true,
						},
					});
					addStep('new.automation', nextStepState);
					break;
				}
				case stepperFlowTypes.onboarding.INSTALL_FROM_QUICK_SHARE: {
					updateMetadata({
						slug: userSlug,
						metadata: {
							onboarded: true,
						},
					});
					nextStepState.flowToInstall = signupMetadata?.data?.flow;
					nextStepState.quickshareLink = signupMetadata?.data?.link;
					addStep('onboarding.quickShareInstall', nextStepState);
				}
				case stepperFlowTypes.checklist.CREATE_TEAM: {
					switchProfile(teamProfile.profileSlug, location);
					addStep('settings.editTeam', nextStepState);
					break;
				}
				case stepperFlowTypes.planWall.CREATE_TEAM: {
					switchProfile(teamProfile.profileSlug);
					return closeStepper();
				}
				default:
					throw new Error(`Invalid stepperFlowType: ${stepperFlowType}`);
			}

			nextStep();
		},
		[steps]
	);

	useEffect(() => {
		setExistenceCheckLoading(true);
		checkSlugTakenDebounced(profileSlug, (val) => {
			setExistenceCheckLoading(false);
			setProfileSlugTaken(val);
		});
	}, [profileSlug]);

	const profileSlugFieldIsInvalid =
		!existenceCheckLoading && profileSlug.length > 0 && profileSlugTaken;
	const profileSlugFieldIsValid =
		!existenceCheckLoading &&
		profileSlug.length > 0 &&
		!profileSlugTaken &&
		!error;
	const validationError = error && profileSlug.length !== 0;

	const alreadyInTeam = teams.length > 0;

	// if (false) {
	if (alreadyInTeam) {
		return (
			<Flex direction="column" alignItems="center" height="100%">
				<Box paddingY="20px">
					<Flex
						textStyle="serif.lg"
						textAlign="center"
						justifyContent="center"
					>
						You're already in a team.
					</Flex>
					<Flex
						textStyle="sans.sm"
						justifyContent="center"
						textAlign="center"
						mt="5px"
						color="light.font.gray.400"
					>
						Please switch to the team profile to continue.
					</Flex>
				</Box>

				<Flex
					direction="column"
					bg="#fff"
					padding="16px 16px 16px 8px"
					alignItems="center"
					borderRadius="2px"
					border="1px solid"
					borderColor="light.border.gray.200"
					mt="48px"
				>
					<Flex width="100%">
						<Box mr="16px">
							<Avatar name={teams[0].name} size="40" />
						</Box>
						<Box textStyle="sans.sm" color="light.font.gray.400">
							<Box>{teams[0].name}</Box>
							<Box textStyle="sans.xs" color="light.font.gray.300">
								{teams[0].users.length} members
							</Box>
						</Box>
					</Flex>

					<MayaFilledButton
						text="Switch to this team and continue"
						colorScheme="gray"
						onClick={() => goToNextStep('SWITCH')}
						size="sm"
						buttonProps={{
							width: '100%',
							ml: '2.5',
							mt: '16px',
						}}
					/>
				</Flex>
			</Flex>
		);
	}

	return (
		<Flex direction="column" alignItems="center" height="100%">
			<Box paddingY="20px">
				<Flex
					textStyle="serif.lg"
					textAlign="center"
					justifyContent="center"
				>
					Create a new team
				</Flex>
				<Flex
					textStyle="sans.sm"
					justifyContent="center"
					textAlign="center"
					mt="5px"
					color="light.font.gray.400"
				>
					Our team plan lets you share automations and tools for your
					entire team to use.
				</Flex>
			</Box>

			<Flex direction="column" width="420px" mt="48px">
				<Box textStyle="sans.sm" mb="8px" color="light.font.gray.400">
					Choose a unique team URL
				</Box>
				<InputGroup
					height="40px"
					overflow="visible"
					padding="1px" // Otherwise the top focus border of the input component is hidden. Chakra bug.
					boxSizing="border-box"
				>
					<InputLeftAddon
						textStyle="sans.sm"
						// color='light.font.gray.400'
						color="#fff"
						borderLeftRadius="2px"
						bg="light.theme.gray.500"
					>
						mayalabs.io/
					</InputLeftAddon>
					<Input
						bg="#fff"
						borderRadius="2px"
						focusBorderColor={
							profileSlugFieldIsInvalid
								? 'red.200'
								: profileSlugFieldIsValid
								? 'green.300'
								: '#ddd'
						}
						isInvalid={profileSlugFieldIsInvalid}
						errorBorderColor="red.100"
						value={profileSlug}
						onChange={handleTeamUrlChange}
						autoFocus
						pl="16px"
						textStyle="sans.xs"
						color="light.font.gray.300"
						spellCheck={false}
					/>
					<InputRightElement
						width="125px"
						mt="1px" // It'll look misaligned otherwise. Idk why.
						padding="4px 20px"
						// bg='pink'
						display="flex"
						justifyContent="flex-end"
					>
						{profileSlugFieldIsValid ? (
							<Flex
								color="green.500"
								alignItems="center"
								textStyle="sans.xs"
							>
								<CheckIcon mr="8px" />
								Available
							</Flex>
						) : null}
						{profileSlugFieldIsInvalid ? (
							<Flex
								color="red.500"
								alignItems="center"
								textStyle="sans.xs"
							>
								<WarningTwoIcon mr="8px" />
								Unavailable
							</Flex>
						) : null}
					</InputRightElement>
				</InputGroup>
				<Flex
					textStyle="sans.xs"
					color={validationError ? 'red.400' : 'light.font.gray.300'}
					mt="8px"
				>
					{!validationError &&
						`Your team will be accessed through this URL.`}
					{validationError && error}
				</Flex>

				<Box
					textStyle="sans.sm"
					mb="8px"
					color="light.font.gray.400"
					mt="24px"
				>
					Team Name
				</Box>
				<InputGroup
					height="40px"
					overflow="visible"
					padding="1px" // Otherwise the top focus border of the input component is hidden. Chakra bug.
					boxSizing="border-box"
				>
					<Input
						bg="#fff"
						borderRadius="2px"
						focusBorderColor="#ddd"
						value={teamName}
						onChange={(e) => setTeamName(e.target.value)}
						pl="16px"
						textStyle="sans.xs"
						color="light.font.gray.300"
						spellCheck={false}
						placeholder={`Team ${_.startCase(profileSlug)}`}
						_placeholder={{
							color: 'light.font.gray.200',
						}}
					/>
					<InputRightElement
						width="125px"
						mt="1px" // It'll look misaligned otherwise. Idk why.
						padding="4px 20px"
						// bg='pink'
						display="flex"
						justifyContent="flex-end"
					>
						{teamName.length === 0 ? (
							<Flex
								color="light.font.gray.200"
								alignItems="center"
								textStyle="sans.xs"
							>
								<CheckIcon mr="8px" />
								Default
							</Flex>
						) : null}
					</InputRightElement>
				</InputGroup>
			</Flex>

			<MayaFilledButton
				text="Continue"
				rightIcon={<ArrowForwardIcon />}
				colorScheme="gray"
				size="sm"
				buttonProps={{
					mt: '72px',
				}}
				isDisabled={!profileSlugFieldIsValid}
				isLoading={teamCreationLoading}
				onClick={goToNextStep}
			/>
		</Flex>
	);
};

const CreateTeamObject = {
	component: <CreateTeam />,
	heading: 'create team',
};

export default CreateTeamObject;
