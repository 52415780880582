// Dependency imports

import isElectron from 'is-electron';

import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';

//redux
import { store } from '../../redux/store';

/**
 * @type {import('./types/createBrain').BrainDevice}
 */
const CLOUD_DEVICE = {
	_id: process.env.REACT_APP_CLOUD_DEVICE_ID,
	name: 'online-cpu',
	release: '1.4.0',
	platform: 'cloud',
}

/**
 *
 * @param {import('./types/createBrain').CreateBrainArgs} param0
 * @returns
 */
const createBrain = async ({ 
	name, 
	device = CLOUD_DEVICE, 
	slug, 
	defaultRuntime = false 
}) => {
	const { user } = store.getState();

	const formData = {
		name: name,
		profileSlug: slug ? slug : user.info.id,
		default: defaultRuntime,
		device: {
			id: device._id,
			name: device.name,
			platform: device.platform,
			release: device.release,
		},
	};

	let createBrainAPIFn, createBrainIPCFn;
	/**
	 * @type {import('./types/createBrain').CreateBrainResponse}
	 */
	let createBrainResponse;
	if (device.platform === 'cloud') {
		// API CALL
		formData['local'] = false; //flag to check if brain PV is to be created on cloud
		createBrainAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.createBrain.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.createBrain.PATH_METHOD,
		});
		createBrainResponse = await createBrainAPIFn(formData);
		if (!createBrainResponse['data']['error']) {
			return createBrainResponse['data']['results'];
		} else {
			return createBrainResponse['data'];
		}
	} else if (isElectron()) {
		// IPC CALL
		formData['local'] = true; //flag to check if brain PV is to be created on cloud
		createBrainIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.createBrain.EVENT,
		});
		createBrainResponse = await createBrainIPCFn(formData);
		if (!createBrainResponse['data']['error']) {
			return createBrainResponse['data']['results'];
		} else {
			return createBrainResponse['data'];
		}
	} else
		console.error(
			`local device cannot be accessed on the browser! Please use desktop app`
		);
};

if (process.env.NODE_ENV === 'development')
	window.__TEST__ = {
		...window.__TEST__,
		createBrain,
	};

export default createBrain;
