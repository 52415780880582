/**
 * IPC Fetch Utility
 *
 * This utility is used for IPC requests for the application, which will work locally on desktop.
 * It handles responses and sets error objects as well. IPC requests can be made on component update, or through an
 * explicit request with the fetchResults function.
 */

// Dependencies
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

// IPC caller
import IPC from './ipc';

/**
 * API Fetch Utility
 *
 * A clean utility for making calls to different IPC main endpoints on
 * electron.
 *
 * @typedef {Object} IPCCall
 * @property {string} EVENT Event name for IPC based communication.
 * @property {string} METHOD To mimic API like behaviours but with IPC
 * @property {any} formData Form data to be sent with the API request.
 * @property {any} initialData Form data to be sent with the API request.
 */

const IPCFetch = ({ EVENT, METHOD, formData = null, initialData = null }) => {
	const [isLoadingIPC, setIsLoading] = useState(false);
	const [resultsIPC, setResults] = useState(initialData);
	const [hasErrorIPC, setError] = useState(null);

	// Array of methods that require input data.
	const postMethods = ['post', 'put'];

	/**
	 * Set IPC request result
	 *
	 * Set the result to the response object from the API request.
	 *
	 * @param {Object} result Result object from the API request.
	 */
	const setAPIResults = (result) => {
		// Set the result state to the result object.
		setResults(result);
		// Set the loading state to false.
		setIsLoading(false);
	};

	/**
	 * Fetch results from the IPC main
	 *
	 * Function performs the request to the provided IPC backend. Can be called manually,
	 * but it is also called when state changes in useEffect which can be used to
	 * load data when the page first loads.
	 *
	 * @param {Object} formData Form data to be sent with the API request.
	 */
	const fetchResultsIPC = (formData) => {
		// Set the error state to null.
		setError(null);
		// Set loading to true.
		setIsLoading(true);
		// Determine the authorization method.
		// console.log("Fetching IPC");
		/**
		 * Fetch a response from an API
		 *
		 * Returns a promise after making a request to an API endpoint.
		 */
		return new Promise((resolve, reject) => {
			IPC({ event: EVENT, data: formData })
				.then((result) => {
					// Make a function call to set the result from the API request.

					setAPIResults(result);
					// Set loading to false.
					setIsLoading(false);
					// Return from the promise with the result from the API request.
					resolve(result);
				})
				.catch((err) => {
					// Check if the response requires the user to log in again.

					// Set the error state to the error from the API request.
					setError(err);
					// Set loading to false.
					setIsLoading(false);
					// Return from the promise with the error from the API request.
					reject(err);
				});
		});
	};

	/**
	 * Update IPC results
	 *
	 * IPC results are automatically loaded on component load, or are updated when
	 * the query is changed. Additionally, IPC results can be fetched by calling
	 * fetchResults.
	 */
	useEffect(() => {
		// Set a variable so we can cancel the request if needed (ex, user
		// moves to a new page).
		let didCancel = false;

		/**
		 * Make the IPC request
		 *
		 * Make the request to the API endpoint, but check for a few things before
		 * doing so. We make sure that the user did not navigate to a new page,
		 * and that formData is set if the request is POST.
		 */
		const fetchData = () => {
			// Make sure we don't try to fetch data after re-render.
			if (!didCancel) {
				// If the method is post, we want to make sure we have formData for the request.
				if (
					!postMethods.includes(METHOD) ||
					(postMethods.includes(METHOD) && formData)
				) {
					// Make sure we prevent re-renders when we're using `get` but updating local stage
					// in a component.
					if (METHOD === 'get' && isEmpty(formData)) {
						// Fetch the API results. Catch the error to handle the promise.
						fetchResultsIPC(formData).catch(() => null);
					}
				}
			}
		};

		// Make the call to the fetch function.
		fetchData();

		/**
		 * Perform action when the component is unmounted
		 *
		 * The return function in useEffect is equivalent to componentWillUnmount and
		 * can be used to cancel the API request.
		 */
		return () => {
			// Set the canceled variable to true.
			didCancel = true;
		};

		// eslint-disable-next-line
	}, [formData]);

	return [
		{
			fetchResultsIPC,
			isLoadingIPC,
			resultsIPC,
			hasErrorIPC,
		},
	];
};

export default IPCFetch;
