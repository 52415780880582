import React, { useEffect, useState } from 'react'
import {
    Flex, Box,
    Progress,
} from '@chakra-ui/react'

const PERCENTAGE_AT_EXPECTED_TIME = 80
const bgcolor = '#EDF2F6'

function progressFn(time, slowdown) {
    return 100 * Math.atan(time/slowdown) / (Math.PI / 2)
}

export const FakeProgress = ({
    expectedCompletionTime = 30000,
    complete = false
}) => {
    const [progress, setProgress] = useState(0)
    const [interval, _setInterval] = useState(null)
    const [initialised, setInitialised] = useState(false)

    useEffect(() => {
        if (!initialised) {
            return setInitialised(true)
        }
        if (!complete) {
            const resolution = 100
            const slowdown = expectedCompletionTime / (Math.tan(Math.PI * (PERCENTAGE_AT_EXPECTED_TIME/100) / 2))

            let time = 0
            const interval = setInterval(() => {
                time += resolution
                const prog = progressFn(time, slowdown)
                setProgress(prog)
            }, resolution)

            _setInterval(interval)
        } else {
            if (interval) {
                clearInterval(interval)
            }
            setProgress(100)
        }
    }, [complete, initialised])

    return (
        <Flex
            // bg='tomato'
            width='260px'
        >
            <Flex
                bg={bgcolor}
                width='100%'
                borderRadius='2px'
                overflow='hidden'
                height='4px'
            >
                <Flex
                    bg='#888'
                    width={`${progress}%`}
                    transition='width 0.4s ease'
                    height='100%'
                >

                </Flex>
            </Flex>
            {/* <Progress 
                value={progress} 
                width='100%'
                height='10px'
                borderRadius='2px'
            /> */}
        </Flex>
    )
}
