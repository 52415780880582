// Dependency imports

// import worker from 'workerize-loader!../../../src/maya-service-worker'; // eslint-disable-line import/no-webpack-loader-syntax

import APIFunction from '../../util/APIFetch/function';

import APIRoutes from '../../util/APIRoutes';

// config
import { config } from '../../config';
/**
 * Function to get all profiles for a user
 */
const acceptTeamInvite = async ({
	inviteId
}) => {
	// Create an instance of your worker
	var acceptTeamInviteFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.acceptTeamInvite.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.acceptTeamInvite.PATH_METHOD,
	});

    const formData = {
        inviteId
    }

	var acceptTeamInviteResult = await acceptTeamInviteFn(formData);
	return acceptTeamInviteResult['data'].results
};

export default acceptTeamInvite;