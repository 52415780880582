// Dependency imports

import isElectron from 'is-electron';
// import worker from 'workerize-loader!../../../src/maya-service-worker'; // eslint-disable-line import/no-webpack-loader-syntax

import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';

import { store } from '../../redux/store';
/**
 * Function to get Devices
 */
const getDevices = async () => {
	const { user } = store.getState();
	const userId = user.info.id;
	// Create an instance of your worker
	var getDevicesFn;
	var getDevicesResponse;
	if (isElectron()) {
		getDevicesFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.getDevices.EVENT,
		});
		getDevicesResponse = await getDevicesFn({ userId: userId });
		if (Array.isArray(getDevicesResponse.data.results))
			getDevicesResponse.data.results.push({
				_id: process.env.REACT_APP_CLOUD_DEVICE_ID,
				name: 'online-cpu',
				release: '1.4.0',
				platform: 'cloud',
			});
	} else {
		getDevicesResponse = {
			data: {
				results: [
					{
						_id: process.env.REACT_APP_CLOUD_DEVICE_ID,
						name: 'online-cpu',
						release: '1.4.0',
						platform: 'cloud',
					},
				],
			},
		};
	}

	return getDevicesResponse['data'];
};

export default getDevices;
