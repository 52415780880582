import React, { useCallback, useEffect, useRef, useState } from 'react';
import { motion, useCycle } from 'framer-motion';
import { AddIcon, AttachmentIcon } from '@chakra-ui/icons';
import MayaFilledButton from '../../../../library/buttons/FilledButton';
import ActionBtn from '../../../../library/buttons/ActionBtn';
import useStepperStore from '../../../Stepper/StepperStore';
import { RepeatIcon } from '@chakra-ui/icons';

import {
	FiPlay,
	FiPause,
	FiExternalLink,
	FiAlertCircle,
	FiRepeat,
	FiRefreshCcw,
} from 'react-icons/fi';
import IconContainer from '../../../../util/React_Icon';

import {
	Box,
	Flex,
	useMediaQuery,
	Fade,
	createStandaloneToast, // Transitions
} from '@chakra-ui/react';
import WorkspaceList from './WorkspaceList';

//Zustand Stores
import { useDashboardStore } from '../../zustand';
import useQueryParams from '../../../../util/useQueryParams';
import { v4 } from 'uuid';
import { stepperFlowTypes } from '../../../Stepper/Sections/registry';
import checklistItems from '../../../Checklist/registry';
import { HistoryWithSlug } from '../../../../util/History';
import { useDispatch, useSelector } from 'react-redux';
import useAnalytics from '../../../../util/Analytics/useAnalytics';
import isElectron from 'is-electron';
import createBrain from '../../../../functions/brain/createBrain';
import useProfileSlug from '../../../../hooks/useProfileSlug';
import randomDefaultBrainName from '../../../../util/RandomDefaults/newbrain';
import { addBrains } from '../../../../redux/actions';
import { normalize } from '../../../../util/Misc';
import theme from '../../../../library/theme';
import createSession from '../../../../functions/pac-engine/createSession';
import linkSessionToRuntime from '../../../../functions/pac-engine/linkSessionToRuntime';
import { useStore } from '../../../Editor/zustand';
import InstructBar from '../../../Editor/Tools/pac-engine/InstructBar';
import updateMetadata from '../../../../functions/profile/updateMetadata';
const allChecklistItems = checklistItems;

const toast = createStandaloneToast({ theme: theme });

/**
 * @param {{
 *  currentWorkspaceId : string
 * }} props
 * @returns
 */
const WorkspaceSection = ({ currentWorkspaceId }) => {
	const { addStep, openStepper } = useStepperStore();
	const [isSmallerThan500] = useMediaQuery('(max-width: 500px)');
	const [selectedWorkspace, setSelectedWorkspace] = React.useState(null);
	const queryParams = useQueryParams();
	// const [checklistItems, updateItem] = useChecklistItems()
	// const { checklistItems, updateChecklistItem } = useChecklistItems();
	const profileSlug = useProfileSlug();
	const track = useAnalytics();
	const [createLoading, setCreateLoading] = React.useState(false);

	const selectedProfile = useSelector(
		/**
		 *
		 * @param {import('../../../../redux/reducers/types/profileReducer').ProfilesState} state
		 * @returns
		 */
		(state) => state.profiles.profiles[state.profiles.selected]
	);

	const cloudDevice = useSelector(
		// @ts-ignore
		(state) =>
			Object.values(state.devices?.list?.byId || {}).find(
				(device) => device.platform === 'cloud'
			)
	);

	const setTeachingZipFile = useStore((state) => state.setTeachingZipFile);
	const toggleInstructBar = useStore((state) => state.toggleInstructBar);
	const instructBarIsOpen = useStore((state) => state.instructBarIsOpen);
	const setInstructBarVisibility = useStore(
		useCallback((state) => state.setInstructBarVisibility, [])
	);
	const stepperOpen = useStepperStore((state) => state.open);

	/**
	 * Set visible by default if in mombile view
	 */
	useEffect(() => {
		if (isSmallerThan500) {
			setInstructBarVisibility(true);
		}
	}, [isSmallerThan500, setInstructBarVisibility]);

	const dispatch = useDispatch();
	const teachInputRef = useRef(null);

	function handleNewAutomationClick() {
		track('[Workspaces] New Automation Clicked', {});
		if (createLoading) {
			return;
		}

		if (isElectron()) {
			// Need to open stepper and let user select device if on desktop app
			const stepperSessionId = v4();
			const initState = {
				stepperSessionId,
				stepperFlowType: stepperFlowTypes.home.NEW_AUTOMATION,
				platformOption: 'On Cloud',
			};
			addStep('new.workspace', initState);
			return openStepper();
		}

		const workspaceName = randomDefaultBrainName();
		setCreateLoading(true);
		Promise.all([
			// @ts-ignore
			createBrain({
				name: workspaceName,
				slug: profileSlug,
				device: cloudDevice,
			}),
			createSession({ fromRecipe: null }),
		]).then(async (res) => {
			try {
				setCreateLoading(false);
				// @ts-ignore
				if (!res[0].error) {
					dispatch(
						addBrains(normalize([{ ...res[0], status: 'STOPPED' }]))
					);
					track('[Workspace] Creation Success', {
						// @ts-ignore
						id: res[0]._id,
						name: workspaceName,
						device: cloudDevice,
						slug: profileSlug,
					});

					await linkSessionToRuntime({
						profileSlug: res[0].profileSlug,
						workspaceId: res[0]._id,
						sessionId: res[1].data.response.session_id,
					});

					return HistoryWithSlug.push(`/edit?id=${res[0]._id}`);
				}
			} catch (err) {
				toast({
					title: 'Error creating workspace!',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			}
		});
	}

	function handleImportClick() {
		track('[Workspaces] Import Clicked', {});

		const stepperSessionId = v4();
		const initState = {
			stepperSessionId,
			stepperFlowType: stepperFlowTypes.home.IMPORT_NEW_FLOW,
			selectedWorkspaceId: null,
		};
		addStep('editor.import', initState);
		openStepper();
	}

	const { refreshDashboard } = useDashboardStore((state) => {
		return {
			refreshDashboard: state.refreshDashboard,
		};
	});

	const handleChecklistItemClick = (itemType) => {
		switch (itemType) {
			case allChecklistItems.createNewTeam: {
				const initState = {
					stepperSessionId: v4(),
					stepperFlowType: stepperFlowTypes.checklist.CREATE_TEAM,
				};
				addStep('onboarding.createTeam', initState);
				return openStepper();
			}
			case allChecklistItems.exploreStore: {
				return HistoryWithSlug.push('/store');
			}
			case allChecklistItems.addTeamMembersToTeam: {
				const initState = {
					stepperSessionId: v4(),
					stepperFlowType: stepperFlowTypes.checklist.ADD_TEAM_MEMBERS,
				};
				addStep('settings.editTeam', initState);
				return openStepper();
			}
			case allChecklistItems.createFirstWorkspace: {
				const initState = {
					stepperSessionId: v4(),
					stepperFlowType: stepperFlowTypes.checklist.CREATE_WORKSPACE,
					platformOption: 'On Cloud',
				};
				addStep('new.workspace', initState);
				return openStepper();
			}
			case allChecklistItems.installFirstAutomation: {
				return HistoryWithSlug.push('/store?type=collections');
			}
		}
	};

	const handleTeachButton = async () => {
		// teachInputRef.current?.click();
		const stepperSessionId = v4();
		const initState = {
			stepperSessionId: stepperSessionId,
			stepperFlowType: stepperFlowTypes.teach.TEACH_SKILL,
			selecterWorkspaceId: null,
			showStoreOption: true,
		};
		addStep('teach.uploadSkillZip', initState);
		openStepper();
	};

	const SectionIcon = ({ icon }) => {
		return (
			<Box
				marginLeft="10px"
				cursor="pointer"
				padding="10px"
				borderRadius="2px"
				border="solid 1px"
				borderColor="transparent"
				_hover={{
					bg: 'white',
					borderColor: 'light.border.gray.200',
				}}
			>
				<IconContainer
					icon={icon}
					value={{
						color: '#4B4B4B',
						className: 'global-class-name',
						size: '23px',
					}}
				/>
			</Box>
		);
	};

	// Setting the title of the page on mount
	useEffect(() => {
		document.title = 'Maya :: Programs that build other programs.';
	}, []);

	useEffect(() => {
		if (!selectedProfile?.profileSlug || stepperOpen) {
			return;
		}

		if (
			selectedProfile &&
			!selectedProfile.isTeam &&
			!selectedProfile.metadata.commandBarOpened &&
			selectedProfile?.metadata?.onboarded
		) {
			console.log('peep 3');
			setInstructBarVisibility(true);
			updateMetadata({
				slug: selectedProfile.profileSlug,
				metadata: {
					commandBarOpened: true,
				},
			});
		}
	}, [selectedProfile?.profileSlug, stepperOpen]);

	return (
		<>
			<Box
				width="100%"
				flex="1 1 auto"
				display="flex"
				boxSizing="border-box"
				flexDir="column"
				height="100%"
				position="relative"
			>
				{/* <Box position="absolute" right="0" bottom="0" mb="24px">
					{Object.values(checklistItems).some((item) => !item.done) && (
						<Checklist
							totalSteps={Object.values(checklistItems).length}
							completedSteps={
								Object.values(checklistItems).filter((x) => x.done)
									.length
							}
						>
							{Object.keys(checklistItems).map((itemType) => (
								<ChecklistItem
									description={checklistItems[itemType].description}
									done={checklistItems[itemType].done}
									onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();
										handleChecklistItemClick(itemType);
									}}
								/>
							))}
						</Checklist>
					)}
				</Box> */}
				<Flex
					mb={isSmallerThan500 ? '0px' : '32px'}
					height={isSmallerThan500 ? '55px' : '45px'}
					justifyContent="space-between"
				>
					<Flex direction={isSmallerThan500 ? 'column' : 'row'}>
						<Box width="100%">
							<MayaFilledButton
								text="New Worker"
								leftIcon={<AddIcon fontSize="10px" />}
								onClick={handleNewAutomationClick}
								isLoading={createLoading}
								buttonProps={{
									width: isSmallerThan500 ? '75vw' : null,
								}}
							/>
						</Box>
						{!isSmallerThan500 && (
							<Box>
								<MayaFilledButton
									text="Teach"
									leftIcon={<AttachmentIcon boxSize="13px" />}
									colorScheme="gray"
									onClick={handleTeachButton}
									buttonProps={{
										ml: '13px',
									}}
								/>
								{/* <input
									ref={teachInputRef}
									type="file"
									accept=".zip"
									style={{ display: 'none' }}
									onChange={handleTeachInput}
								/> */}
							</Box>
						)}
					</Flex>

					<Box>
						{isSmallerThan500 ? null : (
							<>
								{selectedWorkspace !== null ? (
									<Fade in={selectedWorkspace !== null}>
										<Box
											display="flex"
											alignItems="center"
											justifyContent="center"
										>
											<SectionIcon
												icon={true ? <FiPlay /> : <FiPause />}
											/>
											<SectionIcon icon={<FiExternalLink />} />
											<SectionIcon icon={<FiAlertCircle />} />
										</Box>
									</Fade>
								) : null}
								{selectedWorkspace === null ? (
									<Fade in={selectedWorkspace === null}>
										<Box>
											<ActionBtn
												text="REFRESH"
												icon={
													<RepeatIcon
														w={5}
														h={5}
														mr="1"
														color="light.theme.gray.500"
													/>
												}
												onClick={() => {
													refreshDashboard();
												}}
											/>
										</Box>
									</Fade>
								) : null}
							</>
						)}
					</Box>
				</Flex>
				<WorkspaceList
					selectedWorkspace={selectedWorkspace}
					setSelectedWorkspace={setSelectedWorkspace}
				/>
			</Box>
		</>
	);
};

export default WorkspaceSection;
