// @ts-nocheck
import { v4 } from 'uuid';
import JSZip from 'jszip';
import { RecipeData, StepData, DocumentData, SkillData } from '../../library/ZipVisualizer/index'
import path from 'path'

export const fetchZipFile = async url => {
	const response = await fetch(url, {
		method: 'get',
	});
	const blob = await response.blob();
	const zip = new JSZip();
	const blobPromise = Promise.resolve(blob);
	const result = await zip.loadAsync(blobPromise);
	return result;
};

export const getZipFilesContent = async data => {
	const zipContent = [];
	const promises = [];
	data.forEach(async (relativePath, file) => {
		if (
			(relativePath.includes('txt') || relativePath.includes('json')) &&
			!relativePath.includes('_MACOSX')
		) {
			const promise = file.async('string');
			promises.push(promise);
			zipContent.push({
				filePath: relativePath,
				content: await promise,
			});
		}
	});

	await Promise.all(promises);
	return zipContent;
};

export const structureData = allFiles => {
	const fileWithSamples = allFiles.map(file => {
		const path = file.filePath.substring(0, file.filePath.lastIndexOf('/'));
		return {
			...file,
			samples: allFiles.filter(f => {
				if (f.filePath.includes(path) && f.id !== file.id) {
					return true;
				} else {
					return false;
				}
			}),
		};
	});
	const paths = fileWithSamples.map(file => {
		const path = file.filePath.substring(0, file.filePath.lastIndexOf('/'));
		return path;
	});
	const filtered = fileWithSamples.filter((file, index) => {
		const path = file.filePath.substring(0, file.filePath.lastIndexOf('/'));
		return !paths.includes(path, index + 1);
	});
	const sortedFilter = filtered.sort((a, b) => {
		if (a.samples.length > b.samples.length) {
			return -1;
		} else return 1;
	});
	return sortedFilter;
};

export const segregateFiles = async zipFile => {
	const allFiles = await getZipFilesContent(zipFile);
	const allRecipes = allFiles
		.filter(f => f.filePath.includes('recipes/'))
		.map(f => ({ ...f, selected: true, id: v4() }));
	const structuredRecipes = structureData(allRecipes);

	const allSteps = allFiles
		.filter(f => f.filePath.includes('steps/'))
		.map(f => ({ ...f, selected: true, id: v4() }));
	const structuredSteps = structureData(allSteps);

	const allDocs = allFiles
		.filter(f => f.filePath.includes('docs/'))
		.map(f => ({ ...f, selected: true, id: v4() }));
	const structuredDocs = allDocs;

	return { structuredRecipes, structuredSteps, structuredDocs };
};

export const getSkillData = (name, segregatedData): SkillData => {
	const { structuredRecipes, structuredSteps, structuredDocs } = segregatedData
	const recipeDataList: RecipeData[] = []
	structuredRecipes.forEach(recipe => {
		const recipeName = path.basename(
			path.dirname(recipe.filePath)
		)

		const recipeData: RecipeData = {
			name: recipeName,
			id: v4(),
			public: true,
			description: recipe.content.split('----')[0].trim(),
			samples: []
		}

		recipeData.samples.push({
			id: v4(),
			public: true,
			content: recipe.content
		})

		recipe.samples.forEach(sample => {
			recipeData.samples.push({
				id: v4(),
				public: true,
				content: sample.content
			})
		})
		
		recipeDataList.push(recipeData)
	})

	const stepDataList: StepData[] = []
	structuredSteps.forEach(step => {
		const stepName = path.basename(
			path.dirname(step.filePath)
		)

		const stepData: StepData = {
			name: stepName,
			id: v4(),
			public: true,
			samples: []
		}

		try {
			stepData.description = JSON.parse(step.content).find(
				(n: { type: string }) => n.type === 'subflow'
			).name;
		} catch (e) {
			return
		}

		stepData.samples.push({
			id: v4(),
			public: true,
			content: step.content
		})

		step.samples.forEach(sample => {
			stepData.samples.push({
				id: v4(),
				public: true,
				content: sample.content
			})
		})

		stepDataList.push(stepData)
	})

	const docDataList: DocumentData[] = []
	structuredDocs.forEach(doc => {
		const docName = path.basename(doc.filePath)
		const docData: DocumentData = {
			id: v4(),
			public: true,
			name: docName,
			description: docName,
			content: doc.content
		}
		docDataList.push(docData)
	})

	return {
		name,
		recipes: recipeDataList,
		steps: stepDataList,
		docs: docDataList
	}
}