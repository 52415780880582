// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
	Flex,
	Box,
	useColorMode,
	Button,
	Text,
	Img,
	Progress,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	MenuItemOption,
	MenuGroup,
	MenuOptionGroup,
	MenuDivider,
	createStandaloneToast,
} from '@chakra-ui/react';
import GeneralLoader from './Reusable/GeneralLoader';
import { CloseIcon, HamburgerIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { MayaActionButton, MayaSkeleton } from '@mayahq/ui';
import { HistoryWithSlug } from '../../util/History';
import EditorControlBar, { ControlButton } from './Workspace/EditorControlBar';
import {
	FiCopy,
	FiFeather,
	FiFileText,
	FiPlay,
	FiSettings,
} from 'react-icons/fi';
import {
	IoDocumentTextOutline,
	IoExtensionPuzzleOutline,
} from 'react-icons/io5';
import { VscGraph, VscExtensions } from 'react-icons/vsc';
import { AiOutlineBug, AiOutlineUndo, AiOutlineLayout } from 'react-icons/ai';
import ControlIconButton from './Tools/GenerateDAG/ControlIconButton';
import { config } from '../../config';
import { BiGitBranch, BiHomeAlt } from 'react-icons/bi';
import { truncate } from 'lodash';
import { BsPlay } from 'react-icons/bs';
import purpleIcon from '../../assets/mayaIcons/purple.svg';
import { FakeProgress } from './FakeProgress';
import useQueryParams from '../../util/useQueryParams';
import EditorMenu from './Workspace/EditorMenu';
import SynthesisSidebar from './Tools/pac-engine/SynthesisSidebar';
import Grid from './Workspace/Grid';
import ReactFlow, {
	applyNodeChanges,
	applyEdgeChanges,
	addEdge,
	Controls,
	Background,
	ReactFlowProvider,
	useKeyPress,
} from 'react-flow-renderer';
import { nodeTypes } from './Workspace/EditorStyles/CustomNode';
import { edgeTypes } from './Workspace/EditorStyles/CustomEdge';
import cloneDeep from 'lodash/cloneDeep';
import { usePacEngineStore } from './Tools/pac-engine/zustand';
import { useStore } from './zustand';
import { parseMayaLangToReactFlow } from './Workspace/functions/FlowMethods';
import CenterFlow from './Workspace/Grid/CenterFlow';
import theme from '../../library/theme';

const bgColor = {
	light: 'white',
	dark: 'maya_dark.300',
};

const toast = createStandaloneToast({ theme: theme });

/**
 * Not reusing the actual EditorControlBar component here
 * so we can quickly iterate on this one
 */
export const ControlButtonSkeleton = ({
	toggled = false,
	onClick = () => null,
}) => {
	return (
		<MayaSkeleton
			position="relative"
			alignItems="center"
			justifyContent="center"
			borderRadius="4px"
			width="36px"
			height="36px"
			bg="#DFDDDC"
			mb="18px"
			cursor="pointer"
			color="light.theme.gray.500"
			onClick={onClick}
			_hover={{
				backgroundColor: toggled ? '#DFDDDC' : '#E9E7E5',
			}}
		/>
	);
};

const ControlBarSkeleton = ({ visible, workspaceId }) => {
	return (
		<Flex
			direction="column"
			height="100%"
			bg="light.theme.yellow.200"
			width="54px"
			borderRight="1px solid"
			borderColor="light.border.gray.300"
			alignItems="center"
			zIndex="1000"
			transform={visible ? undefined : 'translateX(-100%)'}
			transition="transform 0.3s ease"
			// pt='6px'
		>
			<Flex
				alignItems="center"
				justifyContent="center"
				mb="8px"
				mt="2px"
				// mb='-10px'
			>
				<EditorMenu forSkeleton={true} workspaceId={workspaceId} />
			</Flex>
			<Flex direction="column" justifyContent="space-between" height="100%">
				<Box>
					<ControlButton
						toggled={false}
						tooltipMessage="Describe (Ctrl + G)"
					>
						<FiFeather size="21px" />
					</ControlButton>
					<ControlButton toggled={false} tooltipMessage="Nodes (Ctrl + P)">
						<IoExtensionPuzzleOutline size="22px" />
					</ControlButton>
					<ControlButton
						toggled={false}
						tooltipMessage="Dashboard (Ctrl + D)"
					>
						<AiOutlineLayout size="22px" />
					</ControlButton>
					<ControlButton
						toggled={false}
						tooltipMessage="Debug (Ctrl + Space)"
					>
						<AiOutlineBug size="22px" />
					</ControlButton>
					<ControlButton toggled={false} tooltipMessage="Help">
						<IoDocumentTextOutline size="22px" />
					</ControlButton>
					<ControlButton toggled={false} tooltipMessage="Install">
						<VscExtensions size="22px" />
					</ControlButton>
				</Box>
				<Box>
					<ControlButton>
						<FiSettings size="18px" />
					</ControlButton>
				</Box>
			</Flex>
		</Flex>
	);
};

const ControlIconButtonSkeleton = () => {
	return (
		<MayaSkeleton
			width="30px"
			height="30px"
			justifyContent="center"
			alignItems="center"
			borderRadius="4px"
			cursor="pointer"
			_hover={{
				backgroundColor: 'light.theme.gray.300',
			}}
			mr="4px"
			color="#626262"
		/>
	);
};

const StepInputSkeleton = ({ width }) => {
	return (
		<Flex alignItems="start" mt="8px">
			<MayaSkeleton height="5" width={width} />
		</Flex>
	);
};

const Loader = ({
	complete = false,
	expectedCompletionTime = 30000, // 30 seconds
}) => {
	return (
		<Flex
			alignItems="center"
			padding="8px 16px"
			borderRadius="2px"
			// bg='light.theme.yellow.200'
		>
			<Img
				src={purpleIcon}
				filter="grayscale(100%)"
				opacity="0.55"
				height="40px"
			/>
			<Box ml="12px" mb="8px">
				<Box textStyle="sans.sm" color="light.font.gray.200" mb="8px">
					We're loading your worker...
				</Box>
				<FakeProgress
					complete={complete}
					expectedCompletionTime={expectedCompletionTime}
				/>
			</Box>
		</Flex>
	);
};

const LoaderFlowViz = ({ nodes, edges, visible, centerFlow = {} }) => {
	return (
		<ReactFlowProvider>
			<Box
				className="reactflow-wrapper"
				// ref={reactFlowWrapper}
				position="relative"
				bg="tomato"
				width="100%"
				height="100%"
				opacity={visible ? '1' : '0'}
				transition="all 0.5s ease"
			>
				<ReactFlow
					nodes={nodes}
					edges={cloneDeep(edges)}
					nodeTypes={nodeTypes}
					edgeTypes={edgeTypes}
					connectionMode="strict"
					snapToGrid={true}
					panOnScroll={true}
					// onMoveStart={() => {
					// 	editorStore.setState(
					// 		produce((state) => {
					// 			state.centerFlow.shouldCenter = false;
					// 		})
					// 	);
					// }}
				>
					<Controls />
					<Background
						size={1}
						// gap={14}
						style={{
							background: 'white',
						}}
						color="#D1D1D1"
					/>
					<CenterFlow
						x={centerFlow.x}
						y={centerFlow.y}
						zoom={centerFlow.zoom}
					/>
				</ReactFlow>
			</Box>
		</ReactFlowProvider>
	);
};

const LoaderFlowMemoed = React.memo(LoaderFlowViz);

const SynthSidebarSkeleton = () => {
	return (
		<Flex // Synth-panel skeleton
			width="350px"
			borderRight="2px solid"
			borderColor="light.border.gray.100"
			bg="#fff"
			direction="column"
			// transform={synthPanelVisible ? undefined : 'translateX(-115%)'}
			// width={synthPanelVisible ? '350px' : '0px'}
			// transition="all 0.5s ease"
			// overflow='hidden'
		>
			<Flex // Top bar
				borderBottom="1px solid"
				borderColor="light.border.gray.100"
				height="42px"
				width="100%"
				bg="light.theme.yellow.200"
				padding="8px 16px"
				justifyContent="space-between"
				alignItems="center"
				overflow="hidden"
			>
				<Flex>
					{/* {[1, 2, 3].map(idx => <ControlIconButtonSkeleton />)} */}
					<ControlIconButton
						icon={<FiCopy size="15px" />}
						tooltip="Copy"
						onClick={() => null}
					/>
					<ControlIconButton
						icon={<AiOutlineUndo size="15px" />}
						tooltip="Clear"
						onClick={() => null}
					/>
					{/* {config.settings.synthSettingsEnabled && (
										<ControlIconButton
											icon={<FiSettings size="15px" />}
											tooltip="Settings"
											onClick={() => null}
										/>
									)} */}
					<ControlIconButton
						icon={<FiPlay size="15px" />}
						tooltip="Deploy (Shift + Enter)"
						onClick={() => null}
					/>
					<a
						href="https://mayalabs.io/docs"
						target="_blank"
						rel="noreferrer"
					>
						<ControlIconButton
							icon={<FiFileText size="15px" />}
							tooltip="Docs"
						/>
					</a>
				</Flex>
				<Flex>
					{/* <MayaSkeleton height='30px' width='130px' /> */}
					<Button
						// bg='light.theme.gray.500'
						borderRadius="2px"
						bg="light.theme.gray.500"
						_hover={{
							backgroundColor: 'light.theme.gray.600',
						}}
						color="#fff"
						size="sm"
						leftIcon={<BiGitBranch />}
						loadingText="Generating..."
						spinner={null}
						fontFamily="sans.xs"
						fontWeight="400"
					>
						Generate
					</Button>
				</Flex>
			</Flex>

			<Flex // Step section
				padding="8px 16px"
				pl="20px"
			>
				<Flex
					mt="8px"
					paddingBottom="12px"
					borderBottom="1px solid"
					borderColor="light.border.gray.200"
					width="100%"
				>
					<MayaSkeleton width="160px" height="30px" />
					{/* <Spacer /> */}
				</Flex>
			</Flex>
			<Box pl="20px">
				<StepInputSkeleton width="200px" />
				<StepInputSkeleton width="300px" />
				<StepInputSkeleton width="250px" />
			</Box>
		</Flex>
	);
};

const EditorLoader = ({
	workspaceName = null,
	complete = false,
	workspaceId = '',
	workspaceSessionId = '',
	showGenerateProgress = false,
}) => {
	const [controlBarVisible, setControlBarVisible] = useState(false);
	const [synthPanelVisible, setSynthPanelVisible] = useState(false);
	const [workspaceHeaderVisible, setWorkspaceHeaderVisible] = useState(false);
	const [gridVisible, setGridVisible] = useState(false);
	const serverFlow = usePacEngineStore((state) => state.serverFlow);
	const centerFlow = useStore((state) => state.centerFlow);
	const query = useQueryParams();

	useEffect(() => {
		setTimeout(() => setControlBarVisible(true), 600);
		setTimeout(() => setWorkspaceHeaderVisible(true), 700);
		setTimeout(() => setSynthPanelVisible(true), 800);
		setTimeout(() => setGridVisible(true), 1300);

		toast({
			title: 'Creating a worker',
			description: `We're starting a machine, just for you`,
			status: 'info',
			position: 'bottom-right',
			duration: 3000,
		});
	}, []);

	const expectedCompletionTime =
		query.get('status') !== 'started' ? 30 * 1000 : 15 * 1000;

	const reactFlow = parseMayaLangToReactFlow(serverFlow, 1, 'transient');
	const nodes = reactFlow.nodes.map((node) => {
		return {
			...node,
			data: {
				...node.data,
				transient: true,
				debounceDeploy: () => null,
			},
		};
	});

	return (
		<Flex
			width="100%"
			height="100%"
			zIndex="9000"
			position="absolute"
			top="0"
			left="0"
		>
			<ControlBarSkeleton
				visible={controlBarVisible}
				workspaceId={workspaceId}
			/>
			<Flex // Main stuff
				flex="1 1 auto"
				direction="column"
			>
				<Flex // Workspace top bar
					height="42px"
					width="100%"
					bg="light.theme.yellow.200"
					borderBottom="1px solid"
					borderColor="light.border.gray.300"
					alignItems="center"
					justifyContent="space-between"
					transform={
						workspaceHeaderVisible ? undefined : 'translateY(-100%)'
					}
					transition="transform 0.3s ease"
				>
					{workspaceName && (
						<Box
							as="span"
							display="flex"
							alignItems="center"
							fontSize="14.5px"
							textAlign="left"
							textStyle="sans.xs"
							fontWeight="500 !important"
							// position="absolute"
							pl="1rem"
						>
							{truncate(`${workspaceName}`, { length: 15 })}
						</Box>
					)}
					{!workspaceName && (
						<MayaSkeleton
							width="160px"
							height="20px"
							borderRadius="2px"
							pl="1rem"
						/>
					)}
					<Box>
						<Button
							leftIcon={<BsPlay size="18px" />}
							bg="light.theme.purple.300"
							color="#fff"
							fontFamily="ubuntu, sans-serif"
							fontWeight="400"
							fontSize="14.5px"
							borderRadius="2px"
							mr="8px"
							width="150px"
							_hover={{
								bg: 'light.theme.gray.400',
							}}
							_active={{
								bg: 'light.theme.gray.400',
							}}
							_focus={{}}
							_loading={{ opacity: 1 }}
							_disabled={{
								bg: 'light.theme.gray.400',
							}}
							height="40px"
							loadingText="Deploying"
						>
							Starting
						</Button>
					</Box>
				</Flex>
				<Flex width="100%" flex="1 1 auto" bg="#fff">
					<Flex // Main area - synth panel, grid, dashboard
						bg="#fff"
						width="100%"
					>
						<Flex
							transform={
								synthPanelVisible ? undefined : 'translateX(-115%)'
							}
							transition="all 0.5s ease"
						>
							{!showGenerateProgress && <SynthSidebarSkeleton />}
							{showGenerateProgress && (
								<SynthesisSidebar
									sessionId={workspaceSessionId}
									allowInteraction={false}
									showGenerateProgress={showGenerateProgress}
								/>
							)}
						</Flex>

						<Flex
							flex="1 1 auto"
							justifyContent="center"
							alignItems="center"
							// pr={synthPanelVisible ? '0px' : '350px'} // # Loader change
							pr={
								synthPanelVisible
									? '0px'
									: showGenerateProgress
									? '0px'
									: '350px'
							}
							transition="padding 0.6s ease"
						>
							{!showGenerateProgress && (
								<Loader
									complete={complete}
									expectedCompletionTime={expectedCompletionTime}
								/>
							)}
							{showGenerateProgress && (
								<LoaderFlowMemoed
									nodes={nodes}
									edges={reactFlow.edges}
									visible={gridVisible}
									centerFlow={centerFlow}
								/>
							)}
						</Flex>
					</Flex>
					<Flex // Debug panel
						height="100%"
						bg="pink"
					>
						{/* Debug panel */}
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default EditorLoader;
