import React, { useCallback } from 'react'
import {
    Flex
} from '@chakra-ui/react'

import useStepperAnalytics from '../../../hooks/useStepperAnalytics'
import useStepperStore from '../../../StepperStore'
import WorkspaceDashboardWrapper from '../../../../Dashboard/Sections/WorkspaceDashboards'

const WorkspaceDashboard = () => {
    useStepperAnalytics()

    const steps = useStepperStore(useCallback(state => state.steps, []))

    const step = steps.byId['workspace.dashboard']
    const { workspaceId } = step.state

    return (
        <WorkspaceDashboardWrapper
            expanded
            workspaceId={workspaceId}
        />
    )
}

const WorkspaceDashboardStep = {
    component: <WorkspaceDashboard />,
    heading: 'workspace dashboard'
}

export default WorkspaceDashboardStep