import * as React from 'react';
import {
	Input,
	InputGroup,
	InputGroupProps,
	InputLeftElement,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

export interface SearchBarProps {
	inputGroupProps?: InputGroupProps;
	inputTextColor: string;
	handleSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
	query: string;
}

export const SearchBar = ({
	inputGroupProps,
	inputTextColor,
	handleSearchInput,
	query,
}: SearchBarProps) => {
	return (
		<InputGroup
			border="1px solid #ddd"
			borderRadius="2px"
			{...inputGroupProps}
		>
			<InputLeftElement>
				<SearchIcon color={inputTextColor} />
			</InputLeftElement>
			<Input
				placeholder="Type to search"
				bg="light.theme.gray.50"
				textStyle="sans.xs"
				color={inputTextColor}
				_placeholder={{
					color: inputTextColor,
				}}
				onChange={handleSearchInput}
				defaultValue={query}
				borderRadius="2px"
				// border='10px solid #ddd'
			/>
		</InputGroup>
	);
};
