import React from 'react'
import {
    Box, Flex
} from '@chakra-ui/react'
import { FiPlay, FiPause, FiExternalLink, FiClock } from 'react-icons/fi'
import { Tag } from '../../../Tag'

const TaskCard = (props) => {

    const { taskBg = 'green.400' } = props
    const { task } = props

    return (
        <Flex
            height='100px'
            borderRadius='2px'
            overflow='hidden'
            borderTop='1px solid'
            borderRight='1px solid'
            borderBottom='1px solid'
            borderColor='light.border.gray.200'
            bg='#fff'
            alignItems='center'

            {...props}
        >
            <Box
                bg={task.isRunning ? 'green.400' : '#F3C13F'}
                width='5px'
                height='100%'
            />
            <Flex
                // bg='tomato'
                flex='1 1 auto'
                height='100%'
                ml='16px'
                textStyle='sans.sm'
                // paddingTop='12px'
                direction='column'
                justifyContent='center'
            >
                <Box
                    // bg='pink'
                >
                    {task.topic}
                </Box>
                <Box
                    alignSelf='flex-start'
                    // bg='green'
                >
                    <Tag
                        bg='light.theme.gray.200'
                        size='sm'
                        label={`Dushyant's Macbook Pro`}
                        // label={`a`}
                        mt='12px'
                        maxWidth='200px'
                    />
                </Box>
            </Flex>
            <Flex
                alignItems='center'
                justifyContent='center'
                // width='40px'
                mr='16px'
            >
                <FiPause fontSize='20px' />
            </Flex>
        </Flex>
    )
}

const TaskCardList = (props) => {
    const { tasks } = props
    if (tasks.length > 0) {
        return tasks.map(task => (
            <TaskCard task={task} mb='12px' />
        ))
    } else {
        return (
            <Flex
                textStyle='sans.sm'
                color='light.font.gray.400'
                justifyContent='center'
                textAlign='center'
                mt='8px' mb='16px'
            >
                This workspace has no long-running tasks registered
            </Flex>
        )
    }
}

export default TaskCardList
