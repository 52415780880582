import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Box, Button as ChakraButton, GridItem } from '@chakra-ui/react';
import getToken from '../../../../functions/brain/getToken';
import useProfileSlug from '../../../../hooks/useProfileSlug';
import { useWorkspaceDashboardStore } from './zustand';
import { getFlow } from './functions/getFlow';
import { useSelector } from 'react-redux';
import { WorkspaceDashboard } from './WorkspaceDashboard';
import shallow from 'zustand/shallow';
import {
	offRedRuntimeDeployNotification,
	onRedRuntimeDeployNotification,
} from '../../../Editor/Workspace/functions/redEventListeners';
import _ from 'lodash';

export const WorkspaceDashboardWrapper = ({
	expanded = false,
	fullScreen = false,
	workspaceId,
}) => {
	const slug = useProfileSlug();
	const setCompleteFlow = useWorkspaceDashboardStore(
		(state) => state.setCompleteFlow,
		shallow
	);
	const workspaceUrl = useSelector(
		/**
		 * @param {{
		 *  brains: import('../../../../redux/reducers/types/brains').Brains
		 * }} state
		 */
		(state) => state.brains.byId[workspaceId]?.url
	);

	const token = useSelector(
		/**
		 *
		 * @param {import('../../../../redux/reducers/types/userReducer').UserState} state
		 * @returns
		 */
		(state) => state?.user?.tokens?.accessToken
	);

	useEffect(() => {
		const handler = _.debounce(() => {
			/**
			 * Not getting the flows from the frontend because rearranging UI groups
			 * cause the flows to be updated on the server but not on the editor frontend.
			 *
			 * In such a case, closing and opening the dashboard again after rearranging
			 * groups will cause the changes to not reflect (since the group will still)
			 * be using the frontend's version of the flow.
			 */
			getFlow(workspaceUrl, token)
				.then((flow) => {
					setCompleteFlow(workspaceId, flow);
				})
				.catch((e) => console.error(e));
		}, 200);

		handler();
		onRedRuntimeDeployNotification(handler);
		return () => {
			offRedRuntimeDeployNotification(handler);
		};
	}, [slug, workspaceId, workspaceUrl, token]);

	return (
		<Box width="100%" height="100%">
			<WorkspaceDashboard
				workspaceId={workspaceId}
				workspaceUrl={workspaceUrl}
				token={token}
				expanded={expanded}
				fullScreen={fullScreen}
			/>
		</Box>
	);
};

export default React.memo(WorkspaceDashboardWrapper);
