/**
 * All RED events(both native and custom) to be
 * added here and ref from here.
 */
const RED_EVENTS = {
	MAYA_NOTIFICATION_RUNTIME_DEPLOY: 'maya:notification-runtime-deploy',
	MAYA_RESOLVE_CONFLICT_MERGE: 'maya:resolveConflict:merge',
	MAYA_PUBLIC_MODULE_INSTALL_COMPLETE: 'maya:public-module:install-complete',
	// Trig when a new module is installed and results in new node being added
	NODE_RED_REGISTRY_NODE_TYPE_ADDED: 'registry:node-type-added',
	/** Maya custom event fired when palette is toggled. Returns the state to be set to */
	MAYA_TOGGLE_PALETTE: 'maya:core:toggle-palette',
	/**Event fired by Node-RED whenever a node's details is changed */
	NODE_RED_NODE_CHANGE: 'nodes:change',
	NODE_RED_DEBUG_MESSAGE: 'debug',
	NODE_RED_NODE_ERROR: 'mayanodeerror',
	NODE_RED_NODE_WARNING: 'mayanodewarning',
	NODE_RED_NODE_EXECUTION_STATUS: 'nodeexecstatus'
};

export default RED_EVENTS;