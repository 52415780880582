import React, { useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import SectionHeader from '../../../../../library/text/SectionHeader';
import useStepperStore from '../../../StepperStore';
import getInstallerFlow from '../../../../../functions/store/getInstallerFlow';
import { getModuleById } from '../../../../../functions/store/getStoreModules';
import Form from './Form';
import useStepperAnalytics from '../../../hooks/useStepperAnalytics';
import { stepperFlowTypes } from '../../registry';
import { useSelector } from 'react-redux';
import { config } from '../../../../../config';

const getModulesFromFlowJSON = async mayaJson => {
	const flowDetails = await getInstallerFlow(mayaJson);

	const mods = {};
	const result = await Promise.all(
		flowDetails.currentFlow.modules.map(mod => getModuleById(mod._id))
	);
	result.forEach(mod => (mods[mod._id] = mod));

	// return mods;
	return { modules: mods, flowDetails }
};

const ImportStepper = () => {
	useStepperAnalytics()
	const updateStepState = useStepperStore(
		useCallback(state => state.updateStepState, [])
	);
	const addStep = useStepperStore(useCallback(state => state.addStep, []));
	const nextStep = useStepperStore(useCallback(state => state.nextStep, []));
	const updateStore = useStepperStore(
		useCallback(state => state.updateStore, [])
	);
	const mayaFlowJson = useStepperStore(
		useCallback(state => state.store.mayaFlowJson, [])
	);
	const steps = useStepperStore(state => state.steps)
	const selectedProfile = useSelector(
		/**
		 * 
		 * @param {import('../../../../../redux/reducers/types/profileReducer').ProfilesState} state 
		 * @returns 
		 */
		state => state.profiles.profiles[state.profiles.selected]
	)

	
	const goToNextStep = useCallback(async ({ mayaFlowJson: newMayaFlowJson }) => {
		const currentStep = steps.byId['editor.import']
		const currentState = currentStep.state
		const { stepperFlowType } = currentState

		const { modules, flowDetails } = await getModulesFromFlowJSON(newMayaFlowJson)
		const newState = {
			mayaFlowJson: newMayaFlowJson,
			flowModules: modules, // Keeping this around for backward compatibility
			moduleList: modules,
			flowDetails: flowDetails
		}
		const nextStepState = { ...currentState, ...newState }
		updateStore(newState)
		updateStepState({ state: newState })
		
		const configProfileScreenNeeded = Object.values(modules)
			.some(mod => !!mod.configurationType)

		switch (stepperFlowType) {
			case stepperFlowTypes.workspace.IMPORT_NEW_FLOW: {
				if (configProfileScreenNeeded) {
					addStep('module.configProfile', nextStepState)
				} else {
					addStep('collection.install', nextStepState)
				}
				break
			}
			case stepperFlowTypes.home.IMPORT_NEW_FLOW: {
				addStep('workspace.select', nextStepState)
				break
			}
		}
		nextStep()

	}, [steps])

	return (
		<Box
			display="flex"
			flexDir="column"
			justifyContent="center"
			alignItems="center"
		>
			<Box paddingTop="20px" paddingBottom="20px">
				<SectionHeader
					heading="Import the flow."
					subheading="Copy-paste the workflow into this box, or upload a JSON file."
				/>
			</Box>
			<Form mayaFlowJson={mayaFlowJson} onContinue={goToNextStep} />
		</Box>
	);
};

const sectionObject = {
	heading: 'import flow',
	component: <ImportStepper />,
};

export default sectionObject;
