import { Box, Flex, Spacer } from '@chakra-ui/react';
import React from 'react';
import DeployButton from '../DeployButton';
import NewToggle from '../NewToggle';
import EditorMenu from '../EditorMenu';
import Tabs from './Tabs';
import WorkspaceName from './WorkspaceName';
import IssuesButton from '../IssuesButton';
import useInstallModules from '../../hooks/useInstallModules';

const WorkspaceHeader = ({ workspaceId }) => {
	return (
		<Flex
			alignItems="center"
			bg="light.theme.yellow.200"
			height="10"
			w="full"
		>
			{/* <EditorMenu workspaceId={workspaceId} /> */}
			<WorkspaceName />
			<Tabs />
			<Spacer />
			<IssuesButton />
			<DeployButton />
		</Flex>
	);
};

export default WorkspaceHeader;
