import React from 'react';
import { Box, Flex, Spacer, Stack } from '@chakra-ui/react';
import { MayaSkeleton } from '../../skeleton';

const CollectionCardSkeleton = () => {
	return (
		<Flex
			direction="column"
			borderRadius="2px"
			bg="#fefefe"
			border="1.5px solid #ddd"
			textStyle={'sans.sm'}
			cursor="pointer"
		>
			<Flex px="4" py="2.5" bg="#F3F3F3">
				<Stack direction="row" spacing="5px" alignItems="center">
					<MayaSkeleton width="6rem" height="2rem" />
				</Stack>

				<Spacer />
				<MayaSkeleton width="6rem" height="30px" />
			</Flex>
			<Flex direction="column" p="4" alignItems="flex-start" h="full">
				<MayaSkeleton width="full" height="1.5rem" my="2" />
				<MayaSkeleton width="full" height="1.5rem" my="2" />
				<Spacer />
				<MayaSkeleton width="6rem" height="1.5rem" mt="2" />
			</Flex>
		</Flex>
	);
};

export default CollectionCardSkeleton;
