import React from 'react';
import { Box } from '@chakra-ui/react';
import _ from 'lodash';
import useStepperAnalytics from '../../hooks/useStepperAnalytics';

const linkStyle = {
    textDecoration: 'underline'
}

/**
 * Select all the apps that are relevant to
 * your automation.
 *
 * Design reference : https://www.figma.com/file/KAF1ostHf3VBeZsctGl3ZA/MayaDesign?node-id=2424%3A1240
 * @returns
 */
const NoAccessSection = () => {
    useStepperAnalytics()

    return (
        <Box
            display="flex"
            flexDir="column"
            // justifyContent="center"
            alignItems="center"
            // width='100px'
            // bg='tomato'
        >
            <Box 
                paddingBottom="40px"
                // bg='yellow.200'
                width='100%'
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    marginTop='40px'
                    // bg='pink'
                    width='100%'
                >
                    <Box
                        textStyle="serif.lg"
                        fontSize='50px'
                        // bg='green'
                        width='100%'
                        textAlign='center'
                    >
                        Looks like you don't have access, yet.
                    </Box>
                    <Box
                        textStyle="sans.sm"
                        color="light.font.gray.400"
                        mt="30px"
                        fontSize='19px'
                        maxWidth='650px'
                        textAlign='center'
                        fontWeight='400'
                    >
                        {/* {'The following few steps will help us understand the task you wish to automate.'} */}
                        <Box>
                            At the moment, Maya is only available on an invite basis.
                        </Box>
                        <Box>
                            Join the waitlist <a style={linkStyle} href='https://mayalabs.io/start' target='_blank'>here</a>, or
                            mail us at <a style={linkStyle} href='mailto:humans@mayalabs.io' target='_blank'>humans@mayalabs.io</a>.
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const sectionObject = {
    heading: 'no access',
    component: <NoAccessSection />,
};

export default sectionObject;
