import WorkspaceImageOne from './workspaceImage.png';
import WorkspaceImageTwo from './workspaceImage2.png';
import WorkspaceImageThree from './workspaceImage3.png';

var imgArray = [WorkspaceImageOne, WorkspaceImageTwo, WorkspaceImageThree];

const getRandomThumbnail = (index) => {
	return imgArray[index % imgArray.length];
};

export default getRandomThumbnail;
