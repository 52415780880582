import React, { useMemo } from 'react';
import { Divider, Flex, FlexProps } from '@chakra-ui/react';
import uniq from 'lodash/uniq';
import { EditorMenuItem, EditorMenuItemProps } from './MenuItem';
import { EditorMenuItemConfig } from './types';

export interface EditorContextMenuProps {
	/**
	 * Configure menu items.
	 * - Configure the item `label`, `icon`, `key-command` and `handler` to trigger `onClick`.
	 */
	menuItems: EditorMenuItemConfig[];
	containerProps?: FlexProps;
	/** Customize the menu items styling + other customizations */
	editorMenuItemProps?: Omit<EditorMenuItemProps, 'item'>;
}

/**
 * # Editor Context Menu
 */
export const EditorContextMenu = ({
	menuItems,
	containerProps,
	editorMenuItemProps,
}: EditorContextMenuProps) => {
	const uniqueGroups = useMemo(
		() =>
			uniq(
				menuItems.reduce((acc, item) => {
					if (item.group) acc.push(item.group);
					return acc;
				}, [] as string[])
			),
		[menuItems]
	);
	const itemsWithoutGroup = useMemo(
		() => menuItems.filter(item => !item.group),
		[menuItems]
	);

	return (
		<Flex direction="column" {...containerProps}>
			{uniqueGroups.length > 0
				? uniqueGroups.map((group, idx) => (
						<React.Fragment key={group}>
							{menuItems
								.filter(item => item.group === group)
								.map(item => (
									<EditorMenuItem
										key={item.id}
										{...editorMenuItemProps}
										item={item}
									/>
								))}
							{idx !== uniqueGroups.length - 1 ? (
								<Divider my="0 !important" />
							) : null}
						</React.Fragment>
				  ))
				: null}
			{itemsWithoutGroup.length > 0 ? (
				<>
					{uniqueGroups.length > 0 ? <Divider my="0 !important" /> : null}
					{itemsWithoutGroup.map(item => (
						<EditorMenuItem
							key={item.id}
							{...editorMenuItemProps}
							item={item}
						/>
					))}
				</>
			) : null}
		</Flex>
	);
};
