import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

const CommandRep = ({ command }) => {
	console.log('sd command', command);
	const { components } = command;

	return (
		<Box>
			{components.map((component) => (
				<Flex
					justify="flex-start"
					align="center"
					bg="#fff"
					color="light.font.gray.300"
					paddingY={2.5}
					paddingX={3.5}
					mb="12px"
					borderRadius="2px"
					border="1px solid"
					borderColor="light.border.gray.200"
				>
					<Box fontSize="17px" marginRight={4}>
						{component.value}
					</Box>
					<Box
						as="span"
						fontSize="14px"
						bg="#5A5959"
						color="#fff"
						borderRadius="2.5px"
						paddingY={1}
						paddingX={2.5}
					>
						{component.type}
					</Box>
				</Flex>
			))}
		</Box>
	);
};

export default CommandRep;
