/**
 * GetProfileSlug Utility
 *
 * This utility is used for fetching the profileSlug from the url
 */

// Dependencies

import queryString from 'query-string';
import { matchPath } from 'react-router-dom';

// Toasty
// import Toasty from "../Toasty";
//redux
import { store } from '../../redux/store';
import { config } from '../../config';
import History from '../History';
import Cookies from 'js-cookie';

const GetProfileSlug = () => {
	const { profiles } = store.getState();
	return {
		slug: profiles.selected,
	};
};

/**
 * GetProfileSlug Utility
 *
 */
const GetProfileSlug1 = () => {
	let search = window.location.search;
	let params = new URLSearchParams(search);
	let { slug } = queryString.parse(params);

	var { user, navigation, profiles } = store.getState();
	const { route } = navigation;
	const match = matchPath(window.location.pathname, {
		path: `/:slug/`,
		exact: false,
		strict: false,
	});

	var profileSlug;
	try {
		if (config.reservedPaths.includes(match.params.slug)) {
			if (route.includes('store/')) {
				profileSlug = profiles.selected.profileSlug;
			} else {
				profileSlug = slug ? slug : user.info.id;
			}
		} else {
			if (route.includes('store/')) {
				profileSlug = profiles.selected.profileSlug;
			} else {
				profileSlug = match.params.slug ? match.params.slug : user.info.id;
			}
		}
	} catch (err) {
		// History.push('/login');
		profileSlug = null;
	}

	// Return the useful data and functions from the API Fetch utility.
	return { slug: profileSlug };
};

// Export the API Fetch utility.
export default GetProfileSlug;
