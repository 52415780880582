import { useState, useRef, useCallback } from 'react';

const useEditableText = (initialValue, onRename) => {
	const [textInput, setTextInput] = useState(
		/**
		 * @returns {{
		 * 	isEditing: boolean,
		 * 	initialValue: string,
		 * 	inputValue: string,
		 * }}
		 */
		() => ({
			isEditing: false,
			initialValue: initialValue,
			inputValue: initialValue,
		})
	);

	const textInputRef = useRef(null);

	const handleTextInputSubmit = useCallback(() => {
		onRename(textInput.inputValue);
		setTextInput((old) => ({
			...old,
			isEditing: false,
		}));
	}, [onRename, textInput.inputValue]);

	const handleTextInputReset = useCallback(() => {
		setTextInput((old) => ({
			...old,
			inputValue: old.initialValue,
			isEditing: false,
		}));
	}, []);

	const handleTextRenameClick = useCallback(() => {
		setTextInput((old) => ({
			...old,
			isEditing: true,
		}));
		setTimeout(() => {
			if (textInputRef.current) {
				textInputRef.current.focus();
				textInputRef.current.select();
			}
		}, 100);
	}, []);

	const handleTextInputChange = useCallback((e) => {
		e.persist();
		setTextInput((old) => ({
			...old,
			inputValue: e.target.value,
		}));
	}, []);

	const handleTextInputKeyDown = useCallback(
		/**
		 * @param {import('react').KeyboardEvent<HTMLInputElement>} e
		 */
		(e) => {
			if (e.key === 'Enter') handleTextInputSubmit();
			if (e.key === 'Escape') handleTextInputReset();
		},
		[handleTextInputReset, handleTextInputSubmit]
	);

	return {
		textInput,
		textInputRef,
		handleTextInputKeyDown,
		handleTextInputChange,
		handleTextInputSubmit,
		handleTextRenameClick,
	};
};

export default useEditableText;
