import { Box, createStandaloneToast, Flex } from '@chakra-ui/react';
import { SkillVisualizer, segregateFiles } from '@mayahq/ui';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import shallow from 'zustand/shallow';
import { verifySkill } from '../../../../../functions/pac-engine/skills';
import skillTeach from '../../../../../functions/pac-engine/skillTeach';
import { updateSkill } from '../../../../../functions/pac-engine/skills';
import MayaFilledButton from '../../../../../library/buttons/FilledButton';
import SectionHeader from '../../../../../library/text/SectionHeader';
import theme from '../../../../../library/theme';
import { useStore } from '../../../../Editor/zustand';
import useStepperStore from '../../../StepperStore';
import useProfileSlug from '../../../../../hooks/useProfileSlug';

const TeachStepper = () => {
	const [skillData, setSkillData] = useState({
		steps: [],
		recipes: [],
		docs: [],
		name: '',
	});
	const [skillName, setSkillName] = useState('');
	const [skillMetadata, setMetadata] = useState({});
	const [teachLoading, setTeachLoading] = useState(false);
	const profileSlug = useProfileSlug();

	const toast = createStandaloneToast({ theme: theme });

	const currentStepState = useStepperStore(
		(state) => state.steps.byId['teach.skill']?.state,
		shallow
	);

	const { onAddSkill } = currentStepState;

	// console.log('CURRENT STATE:', currentStepState);

	const closeStepper = useStepperStore((state) => state.closeStepper, shallow);
	const clearSteps = useStepperStore((state) => state.clearSteps, shallow);
	const [collisionData, setCollisionData] = useState({
		recipes: {},
		steps: {},
		docs: [],
	});
	const [verifyLoading, setVerifyLoading] = useState(false);

	async function getZipFromSkillData(skillData, useIdForName = false) {
		const zip = new JSZip();
		console.log('rtt recipes', skillData.recipes);
		skillData.recipes.forEach((recipe) => {
			if (recipe.public) {
				let name = useIdForName ? recipe.id : recipe.name || recipe.id;
				recipe.samples.forEach((sample, idx) => {
					console.log(
						'rtt rsample',
						`skill/recipes/${name}/s${idx}.txt`,
						sample.content
					);
					zip.file(`skill/recipes/${name}/s${idx}.txt`, sample.content);
				});
			}
		});

		skillData.steps.forEach((step) => {
			if (step.public) {
				let name = useIdForName ? step.id : step.name || step.id;
				step.samples.forEach((sample, idx) => {
					zip.file(`skill/steps/${name}/s${idx}.json`, sample.content);
				});
			}
		});

		skillData.docs.forEach((doc) => {
			if (doc.public) {
				let name = useIdForName ? doc.id : doc.name || doc.id;
				zip.file(`skill/docs/${name}`, doc.content);
			}
		});

		return await zip.generateAsync({ type: 'blob' });
	}

	function verifySkillCollisions(skillData) {
		return new Promise((resolve, reject) => {
			getZipFromSkillData(skillData, true).then((skillZip) => {
				const formData = new FormData();
				formData.set('files', skillZip);

				verifySkill({ formData })
					.then((result) => {
						const recipeResults =
							result.recipes.length > 0 ? result.recipes[0] : {};
						const recipeScores = {};
						Object.keys(recipeResults).forEach((recipeId) => {
							recipeScores[recipeId] = Math.max(
								...recipeResults[recipeId].map(
									(o) => o.mean_collision_score
								)
							);
						});

						const stepResults =
							result.steps.length > 0 ? result.steps[0] : {};
						const stepScores = {};
						Object.keys(stepResults).forEach((stepId) => {
							stepScores[stepId] = Math.max(
								...stepResults[stepId].map(
									(o) => o.mean_collision_score
								)
							);
						});

						const docResults =
							result.docs.length > 0 ? result.docs[0] : [];
						const docScores = docResults.map(
							(d) => d.mean_collision_score
						);

						resolve({
							recipes: recipeScores,
							steps: stepScores,
							docs: docScores,
						});
					})
					.catch((e) => {
						reject(e);
					});
			});
		});
	}

	useEffect(() => {
		const { skillData, name, metadata } = currentStepState;

		console.log('ret css', currentStepState);

		setSkillData(skillData);

		setVerifyLoading(true);
		verifySkillCollisions(skillData)
			.then((result) => {
				setCollisionData(result);
			})
			.catch((e) => {
				console.error('Unable to verify zip:', e);
			})
			.finally(() => {
				setVerifyLoading(false);
			});

		let skillName = name;
		if (skillName.endsWith('.zip')) {
			skillName = skillName.replace('.zip', '');
		}
		if (metadata) {
			setMetadata(metadata);
		}
		setSkillName(skillName);
	}, [currentStepState]);

	const handleTeach = async () => {
		getZipFromSkillData(skillData).then(async (content) => {
			try {
				var metadata = {
					source: '',
				};
				setTeachLoading(true);
				const formData = new FormData();
				formData.set('files', content);
				formData.set('name', skillName);
				// formData.set('metadata', skillMetadata);
				const res = await skillTeach({ formData });

				const skillData = res.data;
				const skill = {
					skill_id: skillData.skill_id,
					details: skillData.reference,
					createdAt: Date.now() / 1000,
					name: skillName,
					profile_id: `profile-${profileSlug}`,
				};
				if (onAddSkill) {
					if (typeof onAddSkill === 'function') {
						onAddSkill(skill);
					}
				}

				console.log('ske taught skill', res);
				// console.log('SKILL TEACH:', res);
				if (res.status === 200) {
					toast({
						title: 'Skill taught!',
						description: 'Hit Cmd/Ctrl + K to instruct Maya to use it.',
						status: 'success',
						duration: 5000,
						isClosable: true,
					});
					clearSteps();
					updateSkill(res.data.skill_id, {
						metadata: skillMetadata,
					});
				}
			} catch (err) {
				console.error('error teaching skill', err);
				toast({
					title: 'Failed to teach!',
					description:
						'Unable to teach the uploaded skill. Please try again later.',
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			} finally {
				setTeachLoading(false);
			}
		});
	};

	return (
		<>
			<Flex direction="column" height="100%">
				<Box
					display="flex"
					flexDir="column"
					justifyContent="center"
					alignItems="center"
				>
					<Box paddingTop="20px" paddingBottom="20px">
						<SectionHeader
							heading="Review the skill."
							subheading="The following artifacts will be taught and become available to all your workers."
						/>
					</Box>
				</Box>
				<Flex
					position="relative"
					flex="1 1 auto"
					height="100%"
					width="100%"
					overflow="auto"
					css={{
						'&::-webkit-scrollbar': { display: 'none' },
					}}
				>
					<Flex
						position="absolute"
						width="100%"
						flex="1 1 auto"
						direction="column"
						marginBottom="1rem"
						height="100%"
					>
						<SkillVisualizer
							skillData={skillData}
							setSkillData={setSkillData}
							collisionData={collisionData}
							showCollisionData
							verifyLoading={verifyLoading}
							showPreview={true}
							allowToggle={true}
						/>
					</Flex>
				</Flex>
				<Box
					display="flex"
					flexDir="column"
					justifyContent="center"
					alignItems="center"
					marginTop="64px"
					marginBottom="64px"
				>
					<MayaFilledButton
						text="Teach"
						onClick={handleTeach}
						colorScheme="purple"
						isLoading={teachLoading}
					/>
				</Box>
			</Flex>
		</>
	);
};

const sectionObject = {
	heading: 'teach skill',
	component: <TeachStepper />,
};

export default sectionObject;
