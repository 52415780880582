import React from 'react';
import { Router } from 'react-router-dom';
import AppParent from './AppParent';
import Main from './components/MainApp';
import History from './util/History';
import performanceData from './util/Performance';

//Sentry added
performanceData.init();

const AppChild = () => {
	return (
		<Router history={History}>
			<Main />
		</Router>
	);
};

const App = () => {
	return (
		<AppParent>
			<AppChild children={<AppChild />} />
		</AppParent>
	);
};

export default App;

// const App = () => {
//   return (
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <ThemeProvider theme={customTheme}>
//           <ColorModeProvider>
//             <CSSReset />
//             <Router history={History}>
//               <Main />
//             </Router>
//           </ColorModeProvider>
//         </ThemeProvider>
//       </PersistGate>
//     </Provider>
//   );
// };
