import React, { useEffect, useState } from 'react';
import {
	Box,
	Flex,
	Img,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Tooltip,
} from '@chakra-ui/react';
import { HiOutlineKey } from 'react-icons/hi';
import { Tag } from '../../../Tag';
import getConfigProfiles from '../../../../../../../../../functions/configuration/getConfigProfiles';
import useProfileSlug from '../../../../../../../../../hooks/useProfileSlug';
import ConfigProfileList from '../../../../../../Store/ConfigProfile/ConfigProfileList';
import { getModuleById } from '../../../../../../../../../functions/store/getStoreModules';
import { MayaFilledButton } from '@mayahq/ui';
import setReferenceIdToConfigMap from '../../../../../../../../../functions/brain/setReferenceIdToConfigMap';

const ModuleRep = ({ module, workspaceId }) => {
	const slug = useProfileSlug();
	const [fullModule, setFullModule] = useState({});
	const [configProfiles, setConfigProfiles] = useState([]);
	const [configProfilesLoading, setConfigProfilesLoading] = useState(false);
	const [selectedProfile, setSelectedProfile] = useState({});
	const [profileSaveLoading, setProfileSaveLoading] = useState(false);

	/**
	 * Storing this in state to account for the case when a user changes the selected profile once,
	 * tries to change it again in the same session but then cancels. We can't revert back to
	 * module.referenceId because it won't be equal to the latest referenceId
	 */
	const [currentReferenceId, setCurrentReferenceId] = useState(
		module.referenceId
	); // Not an antipattern

	useEffect(() => {
		setCurrentReferenceId(module.referenceId);
	}, [module.referenceId]);

	useEffect(() => {
		setConfigProfilesLoading(true);
		Promise.all([
			getConfigProfiles({
				slug,
				brainId: workspaceId,
				moduleId: module.module,
				moduleName: module.packageName,
			}),
			getModuleById(module.module),
		])
			.then((res) => {
				const configProfiles = res[0];
				const module = res[1];
				//  console.log('ass', module)
				setFullModule(module);
				setConfigProfiles(configProfiles);
				setConfigProfilesLoading(false);
			})
			.catch((e) => {
				setConfigProfilesLoading(false);
			});
	}, []);

	function cancelEdit() {
		setSelectedProfile({});
	}

	function handleProfileSave() {
		if (!selectedProfile.referenceId) {
			return;
		}
		setProfileSaveLoading(true);
		setReferenceIdToConfigMap({
			runtimeId: workspaceId,
			modulePackageName: module.packageName,
			referenceId: selectedProfile.referenceId,
		})
			.then((res) => {
				setCurrentReferenceId(selectedProfile.referenceId);
				setProfileSaveLoading(false);
			})
			.catch((e) => {
				console.error('Error updating referenceId:', e);
				setProfileSaveLoading(false);
			});
	}

	function handleSelectProfile(profile) {
		const exists = configProfiles.findIndex((cfp) => cfp._id === profile._id);
		if (exists === -1) {
			setConfigProfiles([profile, ...configProfiles]);
		}
		setSelectedProfile(profile);
	}

	function handleDeleteProfile(profileId) {
		if (selectedProfile._id === profileId) {
			setSelectedProfile({});
		}
		setConfigProfiles(configProfiles.filter((cfp) => cfp._id !== profileId));
	}

	const { name, thumbnail, version } = module;

	// @ts-ignore
	const referenceIdToHighlight = selectedProfile.referenceId
		? selectedProfile.referenceId
		: currentReferenceId;

	const profileToHighlight =
		configProfiles.find(
			(profile) => profile.referenceId === referenceIdToHighlight
		) || {};
	// @ts-ignore
	const allowSave =
		!!selectedProfile.referenceId &&
		selectedProfile.referenceId !== currentReferenceId;

	// console.log('yeee referenceId', referenceIdToHighlight)
	// console.log('yeee profileToHighlight', profileToHighlight)
	// console.log('yeee selected', selectedProfile)
	// console.log('yeee configProfiles', configProfiles)

	return (
		<AccordionItem border="1px solid #d8d8d8">
			<Box bg="#fff" padding="8px">
				<Flex alignItems="center">
					<Box>
						<Img
							src={thumbnail}
							width="60px"
							paddingRight="1rem"
							paddingLeft="0.5rem"
						/>
					</Box>
					<Box flex="1 1 auto">
						<Box textStyle="sans.sm" color="light.font.gray.400">
							{name}
						</Box>
						<Box textStyle="sans.xs" color="light.font.gray.300">
							{version}
						</Box>
					</Box>
					<AccordionButton
						width="35px"
						textStyle="sans.xs"
						padding="6px"
						borderRadius="4px"
						cursor="pointer"
						bg="rgba(0,0,0,0)"
						display={module.referenceId ? 'flex' : 'none'}
						justifyContent="center"
						alignItems="center"
						mr="8px"
						_hover={{
							backgroundColor: 'rgba(0,0,0,0.05)',
							border: 'none',
						}}
						_focus={{
							outline: 'none',
						}}
					>
						<HiOutlineKey size="22px" color="#656565" />
					</AccordionButton>
				</Flex>
			</Box>
			<AccordionPanel bg="#F9F9F9" padding="20px">
				<Flex direction="column">
					<Box
						textStyle="sans.xs"
						color="light.font.gray.300"
						mb="12px"
						mt="8px"
						ml="2px"
					>
						Select a different profile first.
					</Box>
					<ConfigProfileList
						loading={configProfilesLoading}
						configProfiles={configProfiles}
						module={fullModule}
						selectMode={true}
						onSelectProfile={(profile) => handleSelectProfile(profile)}
						selectedProfile={profileToHighlight}
						onDeleteProfile={handleDeleteProfile}
					/>
					{/* <Box mt='8px'> */}
					<Flex marginTop="20px">
						<Tooltip
							label="Select a different profile to save."
							bg="light.theme.gray.500"
							isDisabled={allowSave}
							// openDelay={200}
							placement="right-start"
						>
							<Box>
								<MayaFilledButton
									text="Save"
									onClick={handleProfileSave}
									colorScheme="purple"
									size="sm"
									isDisabled={!allowSave}
									isLoading={profileSaveLoading}
								/>
							</Box>
						</Tooltip>
						{allowSave && (
							<MayaFilledButton
								text="Cancel"
								onClick={cancelEdit}
								colorScheme="gray"
								size="sm"
								showDotPattern={false}
								buttonProps={{
									marginLeft: '8px',
									// width: '120px'
								}}
							/>
						)}
					</Flex>
					{/* </Box> */}
				</Flex>
			</AccordionPanel>
		</AccordionItem>
	);
};

export default ModuleRep;
