import React, { useState } from 'react';
import update from 'immutability-helper';
import { Flex, Box } from '@chakra-ui/react';

import isElectron from 'is-electron';
import ConfigProfileFlow from '../InterstitialModuleInstallDrawer/ConfigProfileFlow';
import ModuleIcon from './ModuleIcon';

const ModuleConfigurationFlow = ({ installState, setIsModuleConfigured }) => {
	const [profileMap, setProfileMap] = useState({});
	const [configIndex, setConfigIndex] = useState(0);

	const profilesRequiringConfigs = Object.keys(installState).filter(
		(packageName) => installState[packageName].requiresConfig
	);

	function handleProfileSelect(modulePackageName, referenceId) {
		setProfileMap(
			update(profileMap, {
				[modulePackageName]: { $set: referenceId },
			})
		);
	}

	function onDone() {
		const event = new CustomEvent('Maya::ConfigProfilesSelected', {
			bubbles: true,
			detail: { profileMap },
		});

		document.dispatchEvent(event);
		setIsModuleConfigured(true);
	}

	const moduleVals = profilesRequiringConfigs.map(
		(key) => installState[key].privateModule
	);

	return (
		<Flex
			flexDirection="column"
			height="100%"
			justifyContent="space-between"
			paddingTop={isElectron() ? '45px' : undefined}
			bg="light.theme.yellow.200"
		>
			<Box justifyContent="space-between" color="#444" marginTop="1rem">
				<Flex
					textStyle="sans.sm"
					mb="4px"
					mt="8px"
					alignItems="center"
					justifyContent="start"
				>
					<ModuleIcon thumbnailUrl={moduleVals[configIndex].thumbnail} />
					{`Setup ${moduleVals[configIndex].name}`}
				</Flex>
			</Box>
			<Box marginTop="-2rem" height="60vh">
				<ConfigProfileFlow
					selectConfigProfile={handleProfileSelect}
					onDone={onDone}
					moduleVals={moduleVals}
					configIndex={configIndex}
					setConfigIndex={setConfigIndex}
				/>
			</Box>
			<Box paddingX="16px">
				<Box
					textStyle="sans.xs"
					color="light.font.gray.200"
					borderTop="1px solid"
					borderColor="light.border.gray.200"
					pt="16px"
				>
					Choose an existing profile, or click
					<b> "+ Login and Create New" </b>
					to create a new one.
				</Box>
			</Box>
		</Flex>
	);
};

export default ModuleConfigurationFlow;
