import { v4 } from 'uuid';
import { config } from '../../config';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

export const updateRecipe = async (recipeId, updates) => {
	const updateRecipeFn = APIFunction({
		BASEURL: config.pacEngine.baseUrl,
		PATH_SEARCH: APIRoutes.apiService.updateRecipe.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.updateRecipe.PATH_METHOD,
        PATH_QUERY: recipeId,
	});

    const response = await updateRecipeFn(updates);
    return response.data
};