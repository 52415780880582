import React from 'react';

import {
	Box,
	Stack,
	Input,
	InputGroup,
	InputRightElement,
	Button,
	useColorMode,
	useToast,
} from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

//Redux
import { connect, useSelector } from 'react-redux';
import { setUser, setStatus } from '../../../../redux/actions';

// Controllers
import LoginController from '../Login/LoginController';
import History from '../../../../util/History';

//Components
import Container from '../Container';
import MayaFilledButton from '../../../../library/buttons/FilledButton';
import theme from '../../../../library/theme';

const VerifyCard = ({ setUser, setStatus }) => {
	const toast = createStandaloneToast({ theme: theme });

	const { colorMode } = useColorMode();

	const email = useSelector((state) => {
		return state.user.info ? state.user.info.email : '';
	});

	const fontColor = { light: 'maya_light.400', dark: 'white' };

	const [isLoading, setLoading] = React.useState(false);

	const handleResend = () => {
		setLoading(true);
		LoginController.resendVerifyEmail({ email })
			.then((res) => {
				toast({
					title: 'Success!',
					description: 'Please check your inbox for verification email!',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
				setLoading(false);
				History.push('/');
			})
			.catch((err) => {
				toast({
					title: 'Error occurred',
					description: 'Could not resend mail.',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
				setLoading(false);
			});
	};

	return (
		<Container
			heading="Verify Email"
			subheading={
				email !== ''
					? `Seems like your email ID hasn't been verified yet. Check your
	inbox for a verification mail or click this button to resend it to this address:`
					: `Looks like you haven't logged in yet. Log in to continue verifying your email.`
			}
		>
			<Stack>
				<Box
					textStyle="sans.xs"
					height="40px"
					color="light.font.gray.400"
					width="100%"
					bg="light.theme.gray.100"
					alignItems="center"
					justifyContent="center"
					display="flex"
					border="solid 1px"
					borderColor="light.border.gray.200"
				>
					{email}
				</Box>

				<MayaFilledButton
					size="sm"
					onClick={handleResend}
					isLoading={isLoading}
					isDisabled={email === ''}
					text={'Resend Verification Mail'}
					rightIcon={<ArrowForwardIcon />}
				/>
			</Stack>
		</Container>
	);
};

// Export the Login View.
export default connect(null, { setUser, setStatus })(VerifyCard);

// <Box
//           display="flex"
//           justifyContent="center"
//           color="gray.600"
//           fontSize="0.8rem"
//           fontFamily="body"
//         >
//           or
//         </Box>
//         <Button leftIcon={FaGoogle} variantColor="gray" size="md">
//           Sign in with Google
//         </Button>
