import { ChevronRightIcon } from '@chakra-ui/icons';
import {
	Box,
	CircularProgress,
	Flex,
	Text,
	Textarea,
	Tooltip,
	useMediaQuery,
	useToast,
	Progress,
} from '@chakra-ui/react';
import { debounce, findKey, truncate } from 'lodash';
import React, {
	useCallback,
	useEffect,
	useState,
	useMemo,
	useRef,
} from 'react';
import { flushSync } from 'react-dom';
import { BsTextLeft } from 'react-icons/bs';
import { FaCircle } from 'react-icons/fa';
import { IoMdSend } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import ResizeTextarea from 'react-textarea-autosize';
import createBrain from '../../../../../functions/brain/createBrain';
import changeSession from '../../../../../functions/pac-engine/changeSession';
import createSession from '../../../../../functions/pac-engine/createSession';
import getSessionSuggest from '../../../../../functions/pac-engine/getSessionSuggest';
import instruct from '../../../../../functions/pac-engine/instruct';
import instruct_new from '../../../../../functions/pac-engine/instruct_new';
import linkSessionToRuntime from '../../../../../functions/pac-engine/linkSessionToRuntime';
import useProfileSlug from '../../../../../hooks/useProfileSlug';
import { addBrains } from '../../../../../redux/actions';
import useAnalytics from '../../../../../util/Analytics/useAnalytics';
import { HistoryWithSlug } from '../../../../../util/History';
import { normalize } from '../../../../../util/Misc';
import randomDefaultBrainName from '../../../../../util/RandomDefaults/newbrain';
import { useStore } from '../../../zustand';
import {
	calculateWaitTimes,
	compareFlows,
	compareRecipes,
	diffExists,
	nonEmptyStepCount,
	overwriteTabProperty,
} from '../utils';
import { pacEngineStore, usePacEngineStore } from '../zustand';
import RecipePreview from '../RecipePreview';
import RecipePreviewEditable from '../RecipePreview/Editor';
import { splitIntoThree } from '../../../Workspace/functions/Misc';
import generate from '../../../../../functions/pac-engine/generate';
import { instructBarStore, useInstructBarStore } from './zustand';
import parseRecipe from '../../../../../functions/pac-engine/parseRecipe';
import useSessionId from '../../../hooks/useSessionId';
import { createRecipeWithEmptyStep } from '../../GenerateDAG/utils';

const SearchOption = ({
	i,
	selected,
	text,
	display_array,
	handleClick,
	setSelectedSearchOption,
	isMobile,
}) => {
	const bgColor = selected ? '#545454' : null;
	const fontMainColor = selected ? '#efefef' : '#878787';
	const fontSubColor = selected ? '#c2c2c2' : '#B6B6B6';

	const handleMouseEnter = () => {
		setSelectedSearchOption(i);
	};

	const handleMouseLeave = () => {
		setSelectedSearchOption(-1);
	};

	return (
		<Flex
			tabIndex={0}
			width="100%"
			paddingY="1.1rem"
			justifyContent={'space-between'}
			alignItems="center"
			borderTop={isMobile ? null : 'solid 1px'}
			borderTopColor={isMobile ? null : '#e6e6e6'}
			borderBottom={isMobile ? 'solid 1px' : null}
			borderBottomColor={isMobile ? '#e6e6e6' : null}
			cursor="pointer"
			bg={bgColor}
			onClick={handleClick}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			paddingRight="0.6rem"
		>
			<Box paddingX="1.2rem">
				<BsTextLeft size="1.1rem" color={fontMainColor} />
			</Box>
			<Flex direction={'column'} width="100%">
				<Flex
					fontSize={'1.1rem'}
					fontWeight="400"
					alignItems={'center'}
					justifyContent="space-between"
				>
					<Box color={fontMainColor}>{text}</Box>
					{!isMobile && (
						<Flex
							fontSize={'0.7rem'}
							bg="#F7F6F3"
							color="#878787"
							width="4.9rem"
							paddingX="1rem"
							paddingY="0.2rem"
							borderRadius="2rem"
							border={'solid 1px'}
							borderColor={'#e6e6e6'}
							justifyContent="center"
							fontWeight={'500'}
						>
							{display_array.slice(-1)}
						</Flex>
					)}
				</Flex>
				{/* <Flex paddingTop="0.6rem" alignItems="center">
					{display_array.map((item, i) => {
						return (
							<React.Fragment key={i}>
								<Flex color={fontSubColor} fontSize="0.8rem">
									{item}
								</Flex>
								{i === display_array.length - 1 ? (
									false
								) : (
									<Box marginX="0.6rem">
										<FaCircle size={'0.2rem'} color={fontSubColor} />
									</Box>
								)}
							</React.Fragment>
						);
					})}
				</Flex> */}
			</Flex>
		</Flex>
	);
};

const PreviewSection = ({
	recipePreview,
	handleManualRecipePreviewEdits,
	previewPrompt,
	isMobile,
	onDeployFn,
	onResetFn,
	recipeEditorContRef,
	scrollFn,
	generatingPreview,
	generateRecipeFromPreview,
	instructResponseInProgress,
}) => {
	const [editorOverflowing, setEditorOverflowing] = useState(false);

	useEffect(() => {
		const value =
			recipeEditorContRef.current?.scrollHeight >
			recipeEditorContRef.current?.clientHeight;
		setEditorOverflowing(value);
	}, [recipeEditorContRef, recipePreview]);

	return (
		<Flex width="100%" bg="white" tabIndex={0}>
			<Box width="3px" bg="light.theme.gray.500" />
			<Flex
				width="100%"
				paddingX="0.8rem"
				borderTop="solid 1px"
				borderTopColor={'#e6e6e6'}
				borderBottom="solid 1px"
				borderBottomColor={'#e6e6e6'}
				fontSize="0.8rem"
				direction={'column'}
				color="light.theme.gray.500"
				flexDirection={'column-reverse'}
			>
				<Flex height="40px" justifyContent={'right'} marginY="0.8rem">
					{editorOverflowing && !isMobile ? (
						<Flex
							padding="0.5rem"
							bg="#F7F6F3"
							ml="0.8rem"
							border="solid 1px"
							borderColor={'#e6e6e6'}
							borderRadius={'2px'}
							fontSize="0.7rem"
						>
							<Flex
								marginX="0.4rem"
								justifyContent="left"
								alignItems={'center'}
							>
								Scroll
							</Flex>
							<Flex
								bg="#fbfbfb"
								padding="0.2rem"
								border="solid 1px"
								borderColor={'#e6e6e6'}
								justifyContent="left"
								alignItems={'center'}
								marginX="0.4rem"
								paddingX="0.4rem"
								borderRadius={'2px'}
								onClick={() => scrollFn('up')}
								tabIndex={0}
								cursor="pointer"
							>
								↑
							</Flex>
							<Flex
								bg="#fbfbfb"
								padding="0.2rem"
								border="solid 1px"
								borderColor={'#e6e6e6'}
								justifyContent="left"
								alignItems={'center'}
								marginX="0.4rem"
								paddingX="0.4rem"
								borderRadius={'2px'}
								onClick={() => scrollFn('down')}
								tabIndex={0}
								cursor="pointer"
							>
								↓
							</Flex>
						</Flex>
					) : null}
					{!generatingPreview && !instructResponseInProgress ? (
						<Flex
							padding="0.5rem"
							bg="#F7F6F3"
							ml="0.8rem"
							border="solid 1px"
							borderColor={'#e6e6e6'}
							borderRadius={'2px'}
							fontSize="0.7rem"
							onClick={onDeployFn}
							cursor="pointer"
							tabIndex={0}
						>
							<Flex
								marginX="0.4rem"
								justifyContent="left"
								alignItems={'center'}
							>
								Deploy
							</Flex>
							<Flex
								bg="#fbfbfb"
								padding="0.2rem"
								border="solid 1px"
								borderColor={'#e6e6e6'}
								justifyContent="left"
								alignItems={'center'}
								marginX="0.4rem"
								paddingX="0.4rem"
								borderRadius={'2px'}
							>
								Shift
							</Flex>
							<Flex justifyContent="left" alignItems={'center'}>
								+
							</Flex>
							<Flex
								bg="#fbfbfb"
								padding="0.2rem"
								border="solid 1px"
								borderColor={'#e6e6e6'}
								justifyContent="left"
								alignItems={'center'}
								marginX="0.4rem"
								paddingX="0.4rem"
								borderRadius={'2px'}
							>
								↵
							</Flex>
						</Flex>
					) : null}
					<Flex
						padding="0.5rem"
						bg="#F7F6F3"
						ml="0.8rem"
						border="solid 1px"
						borderColor={'#e6e6e6'}
						borderRadius={'2px'}
						fontSize="0.7rem"
						onClick={onResetFn}
						cursor="pointer"
						tabIndex={0}
					>
						<Flex
							marginX="0.4rem"
							justifyContent="left"
							alignItems={'center'}
						>
							Reset
						</Flex>
						<Flex
							bg="#fbfbfb"
							padding="0.2rem"
							border="solid 1px"
							borderColor={'#e6e6e6'}
							justifyContent="left"
							alignItems={'center'}
							marginX="0.4rem"
							paddingX="0.4rem"
							borderRadius={'2px'}
						>
							Esc
						</Flex>
					</Flex>
				</Flex>
				<Box
					border="solid 1px"
					borderColor={'#e6e6e6'}
					bg="#F7F6F3"
					marginTop={previewPrompt ? '0rem' : '0.8rem'}
					// marginBottom={isMobile ? '0rem' : '0.8rem'}
				>
					<RecipePreviewEditable
						content={recipePreview}
						changeContent={handleManualRecipePreviewEdits}
						maxHeight={isMobile ? '20rem' : '12rem'}
						recipeEditorContRef={recipeEditorContRef}
						generateRecipeFromPreview={generateRecipeFromPreview}
					/>
				</Box>
				{previewPrompt ? (
					<Flex
						bg="#F7F6F3"
						border="solid 1px"
						borderColor={'#e6e6e6'}
						justifyContent="left"
						alignItems={'center'}
						paddingX="1rem"
						width="100%"
						borderRadius={'2px'}
						color="light.theme.gray.400"
						overflow="clip"
						marginY="0.8rem"
						paddingY="0.5rem"
						fontSize={'1rem'}
					>
						<Text noOfLines={2} marginBottom="0 !important">
							{generatingPreview && instructResponseInProgress
								? `> ${previewPrompt}`
								: `> ${previewPrompt}`}
						</Text>
					</Flex>
				) : null}
			</Flex>
		</Flex>
	);
};

const InstructBar = () => {
	const selectedProfile = useSelector((state) => {
		return state.profiles.profiles[state.profiles.selected];
	});

	const instructBarIsOpen = useStore(
		useCallback((state) => state.instructBarIsOpen, [])
	);
	const setInstructBarVisibility = useStore(
		useCallback((state) => state.setInstructBarVisibility, [])
	);
	const getMayaFlowByTab = useStore(
		useCallback((state) => state.getMayaFlowByTab, [])
	);
	const activeTab = useStore(useCallback((state) => state.tabs.activeTab, []));
	const renameTab = useStore(useCallback((state) => state.renameTab, []));
	const workerId = useStore(useCallback((state) => state.brainId, []));
	const sessionId = useSessionId(workerId);

	const recipe = usePacEngineStore((state) => state.recipe);
	const serverRecipe = usePacEngineStore((state) => state.serverRecipe);
	const serverFlow = usePacEngineStore((state) => state.serverFlow);
	const setRecipe = usePacEngineStore((state) => state.setRecipe);
	const setServerRecipe = usePacEngineStore((state) => state.setServerRecipe);
	const setServerFlow = usePacEngineStore((state) => state.setServerFlow);
	const completePromptHistory = usePacEngineStore(
		(state) => state.completePromptHistory
	);
	const setCompletePromptHistory = usePacEngineStore(
		(state) => state.setCompletePromptHistory
	);
	const setOldRecipe = usePacEngineStore((state) => state.setOldRecipe);
	const setUserAction = usePacEngineStore((state) => state.setUserAction);
	const setInstructGenerationId = usePacEngineStore(
		(state) => state.setInstructGenerationId
	);
	const setStepIdBeingGenerated = usePacEngineStore(
		(state) => state.setStepIdBeingGenerated
	);
	const onReceivingGenerateMessage = usePacEngineStore(
		(state) => state.onReceivingGenerateMessage
	);
	const setIsGenerateLoading = usePacEngineStore(
		(state) => state.setIsGenerateLoading
	);
	const setActionTakenOnceFromEditor = usePacEngineStore(
		(state) => state.setActionTakenOnceFromEditor
	);
	const updateStep = usePacEngineStore((state) => state.updateStep);
	const instructBarPrompt = useInstructBarStore(
		(state) => state.instructBarPrompt
	);
	const setInstructBarPrompt = useInstructBarStore(
		(state) => state.setInstructBarPrompt
	);
	const recipePreview = useInstructBarStore((state) => state.recipePreview);
	const setRecipePreview = useInstructBarStore(
		(state) => state.setRecipePreview
	);
	const previewPrompt = useInstructBarStore((state) => state.previewPrompt);
	const setPreviewPrompt = useInstructBarStore(
		(state) => state.setPreviewPrompt
	);
	const tempSessionId = useInstructBarStore((state) => state.tempSessionId);

	const promptHistory = completePromptHistory[selectedProfile.name]
		? completePromptHistory[selectedProfile.name]
		: [];

	const [isSmallerThan800] = useMediaQuery('(max-width: 800px)');
	const [isSmallerThan500] = useMediaQuery('(max-width: 500px)');
	const [instructBarIsLoading, setInstructBarIsLoading] = useState(false);
	const [historyIndex, setHistoryIndex] = useState(-100);
	const [searchOptions, setSearchOptions] = useState([]);
	const [selectedSearchOption, setSelectedSearchOption] = useState(-1);
	const [viewSearchOptions, setViewSearchOptions] = useState(true);
	const [showRecipePreview, setShowRecipePreview] = useState(false);
	const [isStartingWorker, setIsStartingWorker] = useState(null);
	const [recipePasted, setRecipePasted] = useState(false);
	const [generatingPreview, setGeneratingPreview] = useState(false);
	const [isRecipePreviewEditedManually, setIsRecipePreviewEditedManually] =
		useState(false);
	const [isShowingAnswer, setIsShowingAnswer] = useState(false);
	const [instructResponseInProgress, setInstructResponseInProgress] =
		useState(false);

	const toast = useToast();

	const track = useAnalytics();
	const profileSlug = useProfileSlug();
	const cloudDevice = useSelector(
		// @ts-ignore
		(state) =>
			Object.values(state.devices?.list?.byId || {}).find(
				(device) => device.platform === 'cloud'
			)
	);
	const dispatch = useDispatch();

	const totalSteps = nonEmptyStepCount({
		recipe: usePacEngineStore.getState().recipe,
	});

	const getSearchSuggestions = useCallback(
		debounce((e) => {
			getSessionSuggest({
				query: e.target.value,
				display_length: isSmallerThan800 ? 80 : 55,
				limit: 3,
			})
				.then((res) => {
					if (res.status === 200) {
						console.log(res.data);
						setSearchOptions(res.data);
					} else {
						setSearchOptions([]);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}, 150),
		[]
	);

	useEffect(() => {
		if (isSmallerThan800) {
			setInstructBarVisibility(true);
		}
	}, [isSmallerThan800]);

	useEffect(() => {
		if (instructBarPrompt === '') {
			setSearchOptions([]);
		}
		if (!instructBarIsOpen) {
			setSearchOptions([]);
		}
	}, [instructBarPrompt, instructBarIsOpen]);

	useEffect(() => {
		setSelectedSearchOption(-1);
	}, []);

	const placeholder = useMemo(() => {
		let result;
		if (!sessionId) {
			if (showRecipePreview && !isShowingAnswer && !generatingPreview) {
				result = 'How do you want to edit this program?';
			} else {
				if (isSmallerThan800) {
					result = 'Type to start...';
				} else {
					result = 'What would you like to do?';
				}
			}
		} else if (sessionId) {
			const temp =
				totalSteps > 1
					? isSmallerThan500
						? 'Type to modify...'
						: 'How do you want to edit the program?'
					: isSmallerThan500
					? 'Type to build...'
					: 'What would you like to do?';
			result = temp;
		}
		return result;
	}, [
		generatingPreview,
		isShowingAnswer,
		isSmallerThan500,
		isSmallerThan800,
		sessionId,
		showRecipePreview,
		totalSteps,
	]);

	const onReceivingInstructMessage = async ({
		message,
		fromScratch,
		fromEditor,
		count,
	}) => {
		if (message === 'error') {
			setOldRecipe({ recipe });
			setHistoryIndex(-100);
			setInstructBarIsLoading(false);
			instructBarStore.setState({ instructInProgress: false });
			if (!fromEditor) {
				setInstructResponseInProgress(false);
			}
			return;
		}

		if (!fromEditor) {
			setInstructResponseInProgress(true);
		}
		setInstructBarPrompt('');
		const previewArrBeforeChange = instructBarStore
			.getState()
			.recipePreview.split(/\r?\n/);

		if (count === 0) {
			instructBarStore.setState({ recipePreview: '' });
		}

		const isResponseAnAnswer =
			message.metadata.type === 'answer' ||
			message.metadata.type === 'donotknow';

		if (fromScratch && !isResponseAnAnswer) {
			renameTab(activeTab, instructBarPrompt);
		}

		if (message.metadata.status === 'complete') {
			setInstructGenerationId(message.metadata.generation_id);
			setOldRecipe({ recipe });
			setHistoryIndex(-100);
			setInstructBarIsLoading(false);
			instructBarStore.setState({ instructInProgress: false });
			if (!fromEditor) {
				setInstructResponseInProgress(false);
			}
		}

		if (fromEditor) {
			setRecipePreview('');
			setInstructBarVisibility(false);
			setShowRecipePreview(false);
		} else {
			setShowRecipePreview(true);
			if (isResponseAnAnswer) {
				incrementallyPopulateRecipePreview({
					recipe: message.metadata.reply.answer.trim(),
				});
				setIsShowingAnswer(true);
				return;
			} else {
				if (fromScratch && count === 0) {
					incrementallyPopulateRecipePreview({
						recipe: message.recipe.trim(),
					});
				} else {
					const previewArrAfterChange = message.recipe
						.trim()
						.split(/\r?\n/);
					const changedSteps = previewArrAfterChange.filter(
						(p) => !previewArrBeforeChange.includes(p)
					);
					await incrementallyPopulateRecipePreviewChange({
						recipe: message.recipe.trim(),
						changedSteps,
					});
				}
			}
		}
		if (!isResponseAnAnswer) {
			setServerRecipe({ newRecipe: message.steps });
			const modifiedFlow = overwriteTabProperty({
				mayaFlow: message.stitched_flow,
				tabId: activeTab,
			});
			setServerFlow({ newFlow: modifiedFlow });
			await setRecipe({
				newRecipe: message.steps,
				diff: message.diff,
				renderIncrementally: true,
			});
		} else {
			instructBarStore.setState({ instructInProgress: false });
			const clientRecipe = createRecipeWithEmptyStep();
			setRecipe({
				newRecipe: clientRecipe,
				diff: null,
				renderIncrementally: false,
			});
			toast({
				title: 'Request Logged!',
				description: `We're still teaching Maya how to do this. We'll reach out to you as soon as it's ready.`,
				status: 'info',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	const callInstructWS = async ({ fromScratch, sessionId, fromEditor }) => {
		if (fromEditor) {
			const recipeDiff = compareRecipes({
				recipe: recipe,
				serverRecipe: serverRecipe,
			});
			const flowDiff = compareFlows({
				flow: JSON.stringify([
					...getMayaFlowByTab({ tabId: activeTab }),
					...window.RED.nodes
						.createCompleteNodeSet()
						.filter(
							(n) =>
								!n.x &&
								!n.y &&
								n.type !== 'tab' &&
								n.type !== 'subflow' &&
								n.type !== 'group'
						),
				]),
				serverFlow: JSON.stringify(serverFlow),
			});
			/** @type {import ('../zustand/types').Diff} */
			const diff = {
				recipe: { steps: recipeDiff },
				flow: { nodes: flowDiff },
			};
			if (diffExists({ diff })) {
				console.log('diff', diff);
				const sessionRes = await changeSession({ sessionId, diff });
				if (!sessionRes) {
					throw new Error('Request to changeSession failed');
				}
			}
		}
		try {
			await instruct_new({
				sessionId,
				instruction: instructBarPrompt,
				fromScratch,
				fromEditor,
				onReceivingInstructMessage,
				count: 0,
			});
		} catch (err) {
			console.error(err);
			toast({
				title: 'Error',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	const updatePromptHistory = ({ instructBarPrompt }) => {
		const isPromptSameAsLast =
			promptHistory[promptHistory.length - 1] === instructBarPrompt;

		const newHistory = [...promptHistory, instructBarPrompt];
		if (promptHistory.length === 100) {
			newHistory.shift();
		}

		if (!isPromptSameAsLast && instructBarPrompt !== '') {
			setCompletePromptHistory({
				profile: selectedProfile.name,
				profilePromptHistory: newHistory,
			});
		}
	};

	const incrementallyPopulateRecipePreview = async ({ recipe }) => {
		const recipeArr = recipe.split(/\r?\n/);
		const [stepWaitTime, subStepWaitTime] = calculateWaitTimes({
			totalStepsToRender: recipeArr.length,
		});
		setGeneratingPreview(true);

		let temp = '';
		for (const [topIndex, step] of recipeArr.entries()) {
			await new Promise((r) => setTimeout(r, stepWaitTime));
			const splitStep = splitIntoThree(step);
			// @ts-ignore
			for (const [index, subStep] of splitStep.entries()) {
				await new Promise((r) => setTimeout(r, subStepWaitTime));
				const newRecipePreview =
					recipePreview +
					subStep +
					(index === 2 && topIndex !== recipeArr.length - 1 ? '\n' : '');
				temp = temp + newRecipePreview;
				instructBarStore.setState({ recipePreview: temp });
			}
		}
		setGeneratingPreview(false);
	};

	const incrementallyPopulateRecipePreviewChange = async ({
		recipe,
		changedSteps,
	}) => {
		setGeneratingPreview(true);
		const recipeArr = recipe.split(/\r?\n/);
		instructBarStore.setState({
			recipePreview: '',
		});

		let temp = '';
		for (const [index, step] of recipeArr.entries()) {
			if (changedSteps.includes(step)) {
				temp += '...\n';
				instructBarStore.setState({ recipePreview: temp.trim() });
			} else {
				temp += step;
				if (index !== recipeArr.length - 1) {
					temp += '\n';
				}
				setRecipePreview(temp.trim());
			}
		}

		// Second pass
		const currentRecipe = temp.split(/\r?\n/);
		const retempArr = [...currentRecipe];
		let retemp = '';
		let changedStepIndex = 0;
		// @ts-ignore
		for (const [index, step] of currentRecipe.entries()) {
			if (step === '...') {
				await new Promise((r) => setTimeout(r, 300));
				const splitChangedSteps = splitIntoThree(
					changedSteps[changedStepIndex]
				);
				retempArr[index] = '';
				changedStepIndex++;
				for (const subStep of splitChangedSteps) {
					await new Promise((r) => setTimeout(r, 150));
					retempArr[index] = retempArr[index] + subStep;
					retemp = retempArr.join('\n');
					instructBarStore.setState({ recipePreview: retemp.trim() });
				}
			}
		}
		setGeneratingPreview(false);
	};

	const createWorker = async (generateTaskId) => {
		const workspaceName = randomDefaultBrainName();
		// @ts-ignore
		const brainRes = await createBrain({
			name: workspaceName,
			slug: profileSlug,
			device: cloudDevice,
		});
		// @ts-ignore
		if (!brainRes.error) {
			dispatch(addBrains(normalize([{ ...brainRes, status: 'STOPPED' }])));
			track('[Workspace] Creation Success', {
				// @ts-ignore
				id: brainRes._id,
				name: workspaceName,
				device: cloudDevice,
				slug: profileSlug,
			});
			// Linking the new session with the new worker
			await linkSessionToRuntime({
				profileSlug: brainRes.profileSlug,
				workspaceId: brainRes._id,
				sessionId: instructBarStore.getState().tempSessionId,
			});
			setRecipePreview('');
			setInstructBarPrompt('');
			setInstructBarVisibility(false);
			setHistoryIndex(-100);
			return HistoryWithSlug.push(
				`/edit?id=${brainRes._id}&showGenerateProgress=true&generateTaskId=${generateTaskId}`
			);
		}
	};

	const handleManualRecipePreviewEdits = (value) => {
		setRecipePreview(value);
		setIsRecipePreviewEditedManually(true);
	};

	const generateRecipeFromPreview = async () => {
		console.log(
			'tempSessionId && shiftPressed && isRecipePreviewEditedManually 🦆'
		);
		const parseRes = await parseRecipe({
			toParseItem: recipePreview,
			fromFormat: 'str',
			toFormat: 'obj',
		});
		const newRecipePostEdits = parseRes.data;
		pacEngineStore.setState({ recipe: newRecipePostEdits });
		const sessionRes = await createSession({
			fromRecipe: recipePreview,
		});
		const newSessionId = sessionRes.data.response.session_id;
		instructBarStore.setState({ tempSessionId: newSessionId });

		setInstructBarIsLoading(true);
		const firstNonGeneratedStepId = findKey(
			recipe,
			(step) => step.generated === false
		);
		setStepIdBeingGenerated(firstNonGeneratedStepId);
		const taskId = generate({
			sessionId: newSessionId,
			onReceivingGenerateMessage,
			tabId: activeTab,
			setIsGenerateLoading,
			setStepIdBeingGenerated,
			stepIdToFocusOn: null,
			fromEditor: false,
			connId: newSessionId,
		});
		setShowRecipePreview(false);
		setIsStartingWorker(true);
		setInstructBarIsLoading(true);
		await createWorker(taskId);
	};

	const handleEnter = async ({ shiftPressed }) => {
		try {
			setSearchOptions([]);
			setViewSearchOptions(false);
			if (!sessionId) {
				console.log('!sessionId 🦆');
				if (instructBarPrompt === '' && !shiftPressed) {
					setPreviewPrompt('');
					const sessionRes = await createSession({ fromRecipe: null });
					const newSessionId = sessionRes.data.response.session_id;
					instructBarStore.setState({ tempSessionId: newSessionId });
					setShowRecipePreview(true);
					setRecipePreview('1. Start typing here...');
				} else if (!tempSessionId && !recipePasted) {
					console.log('!tempSessionId && !recipePasted 🦆');
					setInstructBarIsLoading(true);
					updatePromptHistory({ instructBarPrompt });
					// new
					setInstructBarPrompt('');
					setInstructResponseInProgress(true);
					setPreviewPrompt(instructBarPrompt);
					setShowRecipePreview(true);
					setRecipePreview('...');
					// new end
					const sessionRes = await createSession({ fromRecipe: null });
					const newSessionId = sessionRes.data.response.session_id;
					instructBarStore.setState({ tempSessionId: newSessionId });
					await callInstructWS({
						fromScratch: true,
						sessionId: newSessionId,
						fromEditor: false,
					});
				} else if (tempSessionId && !shiftPressed) {
					console.log('tempSessionId && !shiftPressed 🦆');
					// new
					setInstructBarPrompt('');
					setInstructResponseInProgress(true);
					// new end
					setInstructBarIsLoading(true);
					let fromScratch = false;
					await callInstructWS({
						fromScratch,
						sessionId: tempSessionId,
						fromEditor: false,
					});
				} else if (
					tempSessionId &&
					shiftPressed &&
					!isRecipePreviewEditedManually
				) {
					console.log(
						'tempSessionId && shiftPressed && !isRecipePreviewEditedManually 🦆'
					);
					setInstructBarIsLoading(true);
					const firstNonGeneratedStepId = findKey(
						recipe,
						(step) => step.generated === false
					);
					setStepIdBeingGenerated(firstNonGeneratedStepId);
					const taskId = generate({
						sessionId: tempSessionId,
						onReceivingGenerateMessage,
						tabId: activeTab,
						setIsGenerateLoading,
						setStepIdBeingGenerated,
						stepIdToFocusOn: null,
						fromEditor: false,
						connId: tempSessionId,
					});
					setShowRecipePreview(false);
					setIsStartingWorker(true);
					setInstructBarIsLoading(true);
					await createWorker(taskId);
				} else if (
					tempSessionId &&
					shiftPressed &&
					isRecipePreviewEditedManually
				) {
					console.log(
						'tempSessionId && shiftPressed && isRecipePreviewEditedManually 🦆'
					);
					const parseRes = await parseRecipe({
						toParseItem: recipePreview,
						fromFormat: 'str',
						toFormat: 'obj',
					});
					const newRecipePostEdits = parseRes.data;
					pacEngineStore.setState({ recipe: newRecipePostEdits });
					const sessionRes = await createSession({
						fromRecipe: recipePreview,
					});
					const newSessionId = sessionRes.data.response.session_id;
					instructBarStore.setState({ tempSessionId: newSessionId });

					setInstructBarIsLoading(true);
					const firstNonGeneratedStepId = findKey(
						recipe,
						(step) => step.generated === false
					);
					setStepIdBeingGenerated(firstNonGeneratedStepId);
					const taskId = generate({
						sessionId: newSessionId,
						onReceivingGenerateMessage,
						tabId: activeTab,
						setIsGenerateLoading,
						setStepIdBeingGenerated,
						stepIdToFocusOn: null,
						fromEditor: false,
						connId: newSessionId,
					});
					setShowRecipePreview(false);
					setIsStartingWorker(true);
					setInstructBarIsLoading(true);
					await createWorker(taskId);
				}
			} else if (sessionId) {
				console.log('sessionId 🦆');
				setInstructBarIsLoading(true);
				// new
				instructBarStore.setState({ instructInProgress: true });
				setInstructBarVisibility(false);
				// new end
				updatePromptHistory({ instructBarPrompt });
				setActionTakenOnceFromEditor({
					profile: selectedProfile.name,
					workerId,
					value: true,
				});

				let fromScratch = false;
				if (totalSteps === 0 || shiftPressed) {
					fromScratch = true;
					setUserAction('recipe.generate');
				}
				// Setting the first step to '...' if fromScratch to further indicate that generation is in progress
				if (fromScratch) {
					const stepId = Object.keys(recipe)[0];
					const updatedStep = {
						...recipe[stepId],
						text: '...',
					};
					updateStep({ stepId, updatedStep });
				}
				callInstructWS({
					fromScratch,
					sessionId,
					fromEditor: true,
				});
			}
		} catch (err) {
			console.error(err);
			toast({
				title: 'Error',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
			setInstructBarPrompt('');
			setRecipePreview('');
			setInstructBarVisibility(false);
			setInstructBarIsLoading(false);
		}
	};

	const handleKeyDown = async (e) => {
		e.stopPropagation();
		const suggestionSelected =
			searchOptions.length > 0 &&
			selectedSearchOption < searchOptions.length &&
			selectedSearchOption >= 0;

		if (e.key === 'Enter' && suggestionSelected) {
			setInstructBarPrompt(searchOptions[selectedSearchOption].main_text);
			setSelectedSearchOption(-1);
			setSearchOptions([]);
			instructBarTextareaRef.current.focus();
		} else if (e.key === 'Enter' && !instructBarIsLoading) {
			handleEnter({ shiftPressed: e.shiftKey });
		} else if (e.key === 'Escape') {
			if (!isSmallerThan800) {
				setRecipePreview('');
				setInstructBarPrompt('');
				setShowRecipePreview(false);
				setInstructBarVisibility(false);
				setHistoryIndex(-100);
				setPreviewPrompt('');
				instructBarStore.setState({ tempSessionId: '' });
			}
		} else if (e.key === 'ArrowUp') {
			e.preventDefault();
			handleArrowUp();
		} else if (e.key === 'ArrowDown') {
			e.preventDefault();
			handleArrowDown();
		}
	};

	const handleArrowUp = () => {
		if (!showRecipePreview) {
			if (searchOptions.length === 0) {
				const newIndex =
					historyIndex === -100
						? promptHistory.length - 1
						: historyIndex - 1;
				if (newIndex !== -1) {
					const newInstruction = promptHistory[newIndex];
					setHistoryIndex(newIndex);
					setInstructBarPrompt(newInstruction);
				}
			} else {
				if (selectedSearchOption < 0) {
					const len = searchOptions.length;
					setSelectedSearchOption(len - 1);
				} else {
					setSelectedSearchOption(selectedSearchOption - 1);
				}
			}
		} else {
			handleRecipePreviewScroll('up');
		}
	};

	const handleArrowDown = () => {
		if (!showRecipePreview) {
			if (searchOptions.length === 0) {
				const newIndex = historyIndex === -100 ? 0 : historyIndex + 1;
				if (newIndex < promptHistory.length) {
					const newInstruction = promptHistory[newIndex];
					setHistoryIndex(newIndex);
					setInstructBarPrompt(newInstruction);
				}
			} else {
				const len = searchOptions.length;
				if (selectedSearchOption === len - 1) {
					setSelectedSearchOption(-1);
				} else {
					setSelectedSearchOption(selectedSearchOption + 1);
				}
			}
		} else {
			handleRecipePreviewScroll('down');
		}
	};

	const handleRecipePreviewScroll = (direction) => {
		const top =
			direction === 'down'
				? recipeEditorContRef.current.scrollTop + 50
				: direction === 'up'
				? recipeEditorContRef.current.scrollTop - 50
				: 0;

		recipeEditorContRef.current.scroll({
			top: top,
			left: 0,
			behavior: 'smooth',
		});
	};

	const handleChange = (e) => {
		// https://reactjs.org/docs/legacy-event-pooling.html
		e.persist();
		// Disable newlines
		if (e.nativeEvent.inputType === 'insertLineBreak') {
			return;
		}
		e.stopPropagation();
		e.preventDefault();
		setInstructBarPrompt(e.target.value);
		if (selectedSearchOption !== -1) {
			setSelectedSearchOption(-1);
		}
		let showSuggestions = false;
		if (sessionId && e.target.value !== '' && totalSteps === 0) {
			showSuggestions = true;
		} else if (!sessionId && !showRecipePreview) {
			showSuggestions = true;
		}
		if (showSuggestions) {
			getSearchSuggestions(e);
		} else {
			setSearchOptions([]);
		}
	};

	const handleSearchOptionClick = (e, i) => {
		setSelectedSearchOption(i);
		setInstructBarPrompt(searchOptions[i].main_text);
		setSelectedSearchOption(-1);
		setSearchOptions([]);
		instructBarTextareaRef.current.focus();
	};

	const handleOverlayClick = (e) => {
		const clickedOnChildComponent = e.currentTarget.contains(e.relatedTarget);
		if (!isSmallerThan800 && !clickedOnChildComponent) {
			setInstructBarVisibility(false);
			setHistoryIndex(-100);
		}
	};

	useEffect(() => {
		if (recipePreview !== '') {
			setShowRecipePreview(true);
		}
	}, []);

	const instructBarTextareaRef = useRef(null);

	useEffect(() => {
		const element = instructBarTextareaRef.current;
		element.focus();
		element.setSelectionRange(element.value.length, element.value.length);
	}, []);

	const handlePaste = async (e) => {
		e.preventDefault();
		const pastedText = e.clipboardData.getData('Text');
		if (pastedText.includes('\r') || pastedText.includes('\n')) {
			let modifiedPastedText = '';
			if (pastedText.includes('\r\n')) {
				modifiedPastedText = pastedText.replaceAll('\r\n', ' (*)\r\n');
			} else if (pastedText.includes('\n') && !pastedText.includes('\r')) {
				modifiedPastedText = pastedText.replaceAll('\n', ' (*)\n');
			} else if (pastedText.includes('\r') && !pastedText.includes('\n')) {
				modifiedPastedText = pastedText.replaceAll('\r', ' (*)\r');
			}
			// Ensures the last step has the '(*)' character at the end
			modifiedPastedText = modifiedPastedText + '(*)';
			modifiedPastedText = modifiedPastedText.replaceAll('(!)', '');
			const res = await parseRecipe({
				toParseItem: modifiedPastedText,
				fromFormat: 'str',
				toFormat: 'obj',
			});
			const parsedText = res.data;
			if (
				!Object.keys(parsedText).includes('status_code') &&
				Object.keys(parsedText).length !== 0
			) {
				setShowRecipePreview(true);
				setRecipePreview(pastedText);
				setRecipePasted(true);
				setPreviewPrompt('');
				const sessionRes = await createSession({
					fromRecipe: pastedText,
				});
				const newSessionId = sessionRes.data.response.session_id;
				instructBarStore.setState({ tempSessionId: newSessionId });
			}
		} else {
			setInstructBarPrompt(pastedText);
		}
	};

	const recipeEditorContRef = useRef(null);

	const resetFunction = () => {
		if (!isSmallerThan800) {
			setInstructBarVisibility(false);
		}
		setRecipePreview('');
		setInstructBarPrompt('');
		setShowRecipePreview(false);
		setHistoryIndex(-100);
		instructBarStore.setState({ tempSessionId: '' });
	};

	return (
		<>
			{instructBarIsOpen && !sessionId && !isSmallerThan800 ? (
				<div
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						backgroundColor: 'rgba(0, 0, 0, 0.4)',
						zIndex: 9999,
					}}
					onClick={handleOverlayClick}
				/>
			) : null}
			<Flex
				textStyle="sans.sm"
				zIndex={1000000000000034}
				width={isSmallerThan800 ? '100%' : '40rem'}
				bg="#F7F6F3"
				position="fixed"
				top={isSmallerThan800 ? null : '32%'}
				bottom={isSmallerThan800 ? '0px' : null}
				left="50%"
				transform="translateX(-50%)"
				border="1px solid #d7d7d7 !important"
				borderLeft="0px solid transparent !important"
				borderRadius={
					isSmallerThan800 ? '0px !important' : '5px !important'
				}
				boxShadow="0px 3px 10px rgba(0,0,0,0.1)"
				padding="0px"
				minH="50px"
				flexDirection={'column'}
			>
				{/* Search options if the screen size is smaller than 800px */}

				{viewSearchOptions && isSmallerThan800 ? (
					<Flex bg="white" tabIndex={0}>
						<Flex width="100%">
							{!isSmallerThan800 && (
								<Box width="3px" bg="light.theme.gray.500" />
							)}
							<Flex direction={'column'} width="100%">
								{searchOptions.length > 0 && (
									<Flex
										height="6px"
										width="100%"
										bg="#F7F6F3"
										borderTop="solid 1px"
										borderTopColor={'#e6e6e6'}
										borderRadius="2px"
									></Flex>
								)}
								{searchOptions.map((item, i) => {
									return (
										<React.Fragment key={i}>
											<SearchOption
												i={i}
												text={item.display_text}
												display_array={item.info}
												selected={i === selectedSearchOption}
												handleClick={(e) =>
													handleSearchOptionClick(e, i)
												}
												setSelectedSearchOption={
													setSelectedSearchOption
												}
												isMobile={isSmallerThan800}
											/>
										</React.Fragment>
									);
								})}
							</Flex>
						</Flex>
					</Flex>
				) : null}

				{showRecipePreview && isSmallerThan800 ? (
					<PreviewSection
						recipePreview={recipePreview}
						handleManualRecipePreviewEdits={
							handleManualRecipePreviewEdits
						}
						previewPrompt={previewPrompt}
						isMobile={isSmallerThan800}
						onDeployFn={() => {
							handleEnter({ shiftPressed: true });
						}}
						onResetFn={resetFunction}
						recipeEditorContRef={recipeEditorContRef}
						scrollFn={handleRecipePreviewScroll}
						generatingPreview={generatingPreview}
						instructResponseInProgress={instructResponseInProgress}
						generateRecipeFromPreview={generateRecipeFromPreview}
					/>
				) : null}
				<Flex width="100%">
					{!isSmallerThan800 && (
						<Box
							width="3px"
							bg="light.theme.gray.500"
							borderLeftRadius="5px !important"
						/>
					)}
					<Box>
						{isSmallerThan800 ? (
							<Box width="20px" />
						) : (
							<ChevronRightIcon
								boxSize="25px"
								marginLeft="15px"
								marginTop="24px"
							/>
						)}
					</Box>
					<Box
						width="100%"
						display="flex"
						alignItems="center"
						py="16px"
						ml="5px"
						bg="#F7F6F3"
					>
						<Textarea
							ref={instructBarTextareaRef}
							disabled={isStartingWorker}
							autoFocus
							as={ResizeTextarea}
							bg="transparent !important"
							contentEditable
							suppressContentEditableWarning
							_placeholder={{ color: '#aaa !important' }}
							placeholder={
								isStartingWorker
									? 'Deploying...'
									: instructResponseInProgress || generatingPreview
									? 'Generating...'
									: placeholder
							}
							outline="none !important"
							border="none !important"
							flex="1 1 auto"
							fontSize="24px !important"
							_focus={{
								boxShadow: 'none',
								border: 'none',
								outline: 'none',
							}}
							_active={{
								boxShadow: 'none',
								border: 'none',
								outline: 'none',
							}}
							onKeyDown={handleKeyDown}
							onChange={handleChange}
							onPaste={handlePaste}
							value={instructBarPrompt}
							spellCheck={false}
							overflow="hidden"
							mb="0px !important"
							lineHeight="30px !important"
							color="#555 !important"
							minRows={0}
							resize="none"
							minHeight="0px !important"
							css={{
								'&::-webkit-scrollbar': {
									display: 'none',
								},
							}}
						/>
					</Box>
					<Flex alignItems="center">
						<Box
							width="30px"
							height="100%"
							marginX="20px"
							display="flex"
							alignItems={isSmallerThan800 ? 'center' : undefined}
							pt={isSmallerThan800 ? undefined : '28px'}
						>
							{isSmallerThan800 && !instructBarIsLoading ? (
								<Box width="10px" color="gray.500">
									<IoMdSend size={'20px'} onClick={handleEnter} />
								</Box>
							) : null}
							{instructBarIsLoading && (
								<CircularProgress
									isIndeterminate
									color="gray.500"
									size="20px"
								/>
							)}
						</Box>
					</Flex>
				</Flex>
				{showRecipePreview && !isSmallerThan800 ? (
					<PreviewSection
						recipePreview={recipePreview}
						handleManualRecipePreviewEdits={
							handleManualRecipePreviewEdits
						}
						previewPrompt={previewPrompt}
						isMobile={isSmallerThan800}
						onDeployFn={() => {
							handleEnter({ shiftPressed: true });
						}}
						onResetFn={resetFunction}
						recipeEditorContRef={recipeEditorContRef}
						scrollFn={handleRecipePreviewScroll}
						generatingPreview={generatingPreview}
						instructResponseInProgress={instructResponseInProgress}
						generateRecipeFromPreview={generateRecipeFromPreview}
					/>
				) : null}
				{/* Search options if the screen size is bigger than 800px */}
				{viewSearchOptions && !isSmallerThan800 ? (
					<Flex bg="white" tabIndex={0}>
						<Flex width="100%" borderBottomRadius="5px">
							{!isSmallerThan800 && (
								<Box width="3px" bg="light.theme.gray.500" />
							)}
							<Flex
								direction={'column'}
								width="100%"
								borderBottomRadius="5px"
							>
								{searchOptions.map((item, i) => {
									return (
										<React.Fragment key={i}>
											<SearchOption
												i={i}
												text={item.display_text}
												display_array={item.info}
												selected={i === selectedSearchOption}
												handleClick={(e) =>
													handleSearchOptionClick(e, i)
												}
												setSelectedSearchOption={
													setSelectedSearchOption
												}
												isMobile={isSmallerThan800}
											/>
										</React.Fragment>
									);
								})}
								{searchOptions.length > 0 && (
									<Flex
										height="6px"
										width="100%"
										bg="#F7F6F3"
										borderTop="solid 1px"
										borderTopColor={'#e6e6e6'}
										borderRadius="2px"
									></Flex>
								)}
							</Flex>
						</Flex>
					</Flex>
				) : null}
			</Flex>
		</>
	);
};

export default InstructBar;
