/**
 * # Maya Red Custom Events
 *
 * We have injected our own custom code in the Red Editor to
 * allow us to communicate with our React front-end. One of the ways
 * we achieve this is by using HTML Custom Event API. We create this on
 * the global document object and dispatch them on the jquery side, which
 * are in-turn listened to on the React side to "react" to them.
 *
 *	This object Defines all the HTML Custom Events created and
 * dispatched on the RedEditor (jquery) side.
 */
export const mayaRedCustomEvents = {
	/**
	 * Event triggered on red-editor init
	 */
	redOnInit: 'maya:redOnInit',
};

/**
 * # Maya Red Actions
 *
 * These are the actions triggered by red-editor, we listen to this actions
 * on the react side to respond to them.
 */
export const mayaRedActions = {
	/**
	 * Triggered on `export` hamburger Menu btn click
	 */
	showExportDialog: 'core:show-export-dialog',
	/**
	 * Triggered on `import` hamburger Menu btn click
	 */
	showImportDialog: 'core:show-import-dialog',
	/**
	 * Triggered when the a new flow is updated
	 */
	triggerFlowUpdate: 'core:show-import-dialog',
};
