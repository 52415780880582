import axios from 'axios';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';
import GQLFetch from '../../util/GQLFetch';

// config
import config from '../../config/config';

const CMS_API_ROOT = config.app.CMS_BACKEND;

// import { store } from "../../redux/store";

const getStoreModules = async ({
	_id = null,
	categories = [],
	apps = [],
	slug = '',
	configOnly = false,
	search = '',
}) => {
	let request, configFetch;
	if (_id) {
		request = {
			url: `${CMS_API_ROOT}/modules/${_id}`,
			method: 'get',
		};
	} else {
		configFetch = configOnly;

		request = {
			url: `${CMS_API_ROOT}/modules/filter`,
			method: 'post',
			data: {
				config: configFetch,
			},
		};
	}

	if (categories.length !== 0) {
		request.data.categories = categories.map((c) => c._id);
	}

	if (apps.length !== 0) {
		request.data.apps = apps.map((a) => a._id);
	}

	if (search) {
		request.data.search = search;
	}

	// const getStoreModulesFn = APIFunction({
	// 	BASEURL: CMS_API_ROOT,
	// 	PATH_SEARCH: APIRoutes.apiService.cmsGetStoreModules.PATH_SEARCH,
	// 	PATH_METHOD: APIRoutes.apiService.cmsGetStoreModules.PATH_METHOD,
	// 	URL_PARAM: { config: configFetch },
	// 	formData: data
	// })

	// const response = await getStoreModulesFn()
	try {
		const response = await axios(request);
		return response.data;
	} catch (e) {
		console.error('Error fetching module list:', e);
		return [];
	}
};

export async function getStoreModuleByIdForDetailsPage(id) {
	const getStoreModuleByIdFn = GQLFetch({
		BASEURL: config.app.CMS_BACKEND,
		gquery: `
		query($_id: String) {
			modules (where: {
				_id: $_id
			}){
				_id
				name
				description
				currentVersion
				updatesAt
				type
				nodes {
					name
					description
				}
				platforms {
					_id
					label
				}
				versions {
					nodes {
						name
						description
					}
				}

			}
		}
		`,
		params: { _id: id },
	});

	try {
		console.log('mp hey 1');
		const response = await getStoreModuleByIdFn();
		console.log('mp hey 2');
		return response;
	} catch (e) {
		console.error('mp error', e);
	}
}

export async function getModuleById(id) {
	const response = await axios.get(`${config.app.CMS_BACKEND}/modules/${id}`);
	return response.data;
}

export default getStoreModules;
