import React, { useState } from 'react';
import { Flex, Code, Box } from '@chakra-ui/react';
import { VscCopy, VscTrash } from 'react-icons/vsc';
import { FiCheck, FiCopy, FiEye, FiEyeOff, FiTrash } from 'react-icons/fi';
import analytics from '../../../../../util/Analytics';

// const hiddenApiKey = 'hehe the key could be anything';
const hiddenApiKey = '***************************';

const SecretKey = ({ secretKey, keyVisible, setKeyVisible }) => {
	const track = analytics.track;
	const [copyTick, showCopyTick] = useState(false);

	function toggleKeyVisibility() {
		setKeyVisible(!keyVisible);
	}

	function handleCopy() {
		track(`[Workspace] Settings > Developer Section > API Key copied`);
		showCopyTick(true);
		window.navigator.clipboard.writeText(secretKey);
		setTimeout(() => showCopyTick(false), 800);
	}

	return (
		<Flex
			direction="row"
			alignItems="center"
			position={'relative'}
			justifyContent="space-between"
			// paddingBottom="1.5rem"
		>
			<Code
				border="1px solid"
				transform="translateX(-1px)"
				borderColor="light.border.gray.100"
				width="100%"
				fontSize="14px"
				borderRadius="3px"
				bg="light.theme.yellow.200"
				p={3}
				color="light.font.gray.400"
				display="flex"
				justifyContent="space-between"
				alignItems="center"
			>
				{secretKey && (
					<>
						<Box>{keyVisible ? secretKey : hiddenApiKey}</Box>
						<Box cursor="pointer">
							{keyVisible && (
								<FiEyeOff
									size="16px"
									color="#4B4B4B"
									onClick={toggleKeyVisibility}
								/>
							)}
							{!keyVisible && (
								<FiEye
									size="16px"
									color="#4B4B4B"
									onClick={toggleKeyVisibility}
								/>
							)}
						</Box>
					</>
				)}
				{!secretKey && (
					<>Click 'Generate Key' to generate an API key for this profile</>
				)}
			</Code>
			{secretKey && (
				<Flex
					alignItems="center"
					// width="40px"
					justifyContent="space-between"
					// ml="16px"
				>
					<Box
						cursor="pointer"
						width="30px"
						ml="16px"
						onClick={handleCopy}
					>
						{copyTick && <FiCheck size="16px" color="#4B4B4B" />}
						{!copyTick && <FiCopy size="16px" color="#4B4B4B" />}
					</Box>
					{/* TODO: Implement UI for revoking key */}
					{/* <Box
						cursor="pointer"
						right={0}
						width="30px"
						onClick={() => {
							revokeDeveloperKey();
							setRevokeKey(true);
							setSecretKey(false);
						}}
					>
						<FiTrash size="16px" color="#4B4B4B" />
					</Box> */}
				</Flex>
			)}
		</Flex>
	);
};

export default SecretKey;
