import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getModuleById } from '../../../../../functions/store/getStoreModules';
import getConfigProfiles from '../../../../../functions/configuration/getConfigProfiles';
import { ModuleSection, InfoTag } from '@mayahq/ui';
import useStepperStore from '../../../../Stepper/StepperStore';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import ConfigProfileList from '../ConfigProfile/ConfigProfileList';
import { stepperFlowTypes } from '../../../../Stepper/Sections/registry';
import useProfileSlug from '../../../../../hooks/useProfileSlug';

/**
 * @param {{
 * 	module: import('../../../../../functions/store/types/Modules').StoreModule,
 * 	slug: string
 * }} param0
 * @returns
 */
const RenderConfigProfiles = ({ module, slug }) => {
	const [configProfiles, setConfigProfiles] = useState(null);
	const [loading, setLoading] = useState(false);
	console.log('pass rerender');

	useEffect(() => {
		setLoading(true);
		getConfigProfiles({
			slug: slug,
			moduleId: module._id,
			moduleName: module.packageName,
			brainId: '',
		})
			.then((res) => {
				setLoading(false);
				setConfigProfiles(res);
			})
			.catch((e) => {
				// setLoading(false)
				console.error(e);
			});
	}, [module._id, module.packageName, slug]);

	return (
		<>
			<InfoTag label="Configure">
				Authenticate this module to interact with the app.
			</InfoTag>
			<Box mt="12px">
				<ConfigProfileList
					configProfiles={configProfiles}
					module={module}
					loading={loading}
				/>
			</Box>
		</>
	);
};

const ModulePageNew = () => {
	/**
	 * @type {{ moduleId: string }}
	 */
	const { moduleId } = useParams();
	const history = useHistory();
	const { addStep, openStepper } = useStepperStore();
	const [module, setModule] = useState(null);
	const [loading, setLoading] = useState(true);
	const slug = useProfileSlug();

	const onInstallClick = () => {
		const initState = {
			moduleList: {
				[module._id]: module,
			},
			stepperFlowType: stepperFlowTypes.store.INSTALL_MODULE_FLOW,
		};
		addStep('workspace.select', initState);
		openStepper();
	};

	useEffect(() => {
		setLoading(true);
		getModuleById(moduleId)
			.then((res) => {
				setModule(res);
				setLoading(false);
			})
			.catch((e) => {
				console.error(e);
				setLoading(false);
			});
	}, [moduleId]);

	return loading ? (
		<Flex justifyContent="center" alignItems="center" flex="1 1 auto">
			<Spinner bg="light.theme.purple.400" />
		</Flex>
	) : !module ? (
		<Flex justifyContent="center" alignItems="center" flex="1 1 auto">
			404
		</Flex>
	) : (
		<ModuleSection
			module={module}
			onBackButtonClick={history.goBack}
			onInstallButtonClick={onInstallClick}
			onConfigProfilesSectionRender={({ module }) => (
				<RenderConfigProfiles module={module} slug={slug} />
			)}
		/>
	);
};

export default ModulePageNew;
