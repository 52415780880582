import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';
import _ from 'lodash';

// config
import { config } from '../../config';
import { store } from '../../redux/store';

/**
 *
 * @param {import('./types/installModuleToWorkspace').InstallModuleToBrainArg} formData
 * @param {import('./types/installModuleToWorkspace').ModuleToInstall} moduleToInstall
 * @returns
 */
const installModuleToBrain = async (formData, moduleToInstall) => {
	const { user, brains } = store.getState();
	let profileSlug = formData.profileSlug ? formData.profileSlug : user.info.id;
	console.log('mpcp brains', brains)
	const brainDevice = brains.byId[formData.brainId].device
	formData.profileSlug = profileSlug;
	var installModuleToBrainAPIFn,
		installModuleToBrainIPCFn,
		installModuleToBrainResponse;

	if (brainDevice.platform === 'cloud') {
		console.log('imp brain platform is cloud')
		installModuleToBrainAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.installModuleToBrain.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.installModuleToBrain.PATH_METHOD,
		});

		console.log('imp sending module install request')
		installModuleToBrainResponse = await installModuleToBrainAPIFn({
			...formData,
			moduleToInstall: moduleToInstall,
		});
		console.log('imp response', installModuleToBrainResponse)
		if (installModuleToBrainResponse['data']['error']) {
			return installModuleToBrainResponse['data'];
		} else {
			return installModuleToBrainResponse['data']['results'];
		}
	} else {
		installModuleToBrainIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.installModuleToBrain.EVENT,
		});
		installModuleToBrainResponse = await installModuleToBrainIPCFn({
			...formData,
			moduleToInstall: moduleToInstall,
		});
		return installModuleToBrainResponse['data'];
	}
};

export default installModuleToBrain;
