import React from 'react'
import {
    Flex, Box
} from '@chakra-ui/react'

const InfoTag = ({
    label,
    align = 'left',
    children
}) => {
  return (
    <Box
        textAlign={align}
        // textAlign='right'
    >
        <Box
            textStyle='sans.sm'
            color='light.font.gray.400'
        >
            {label}
        </Box>
        <Box
            // mt='4px'
            color='light.font.gray.200'
            textStyle='sans.xs'
            pl='1px'
        >
            {children}
        </Box>
    </Box>
  )
}

export default InfoTag