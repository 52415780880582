import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { useStore } from '../zustand';
import { getNodeById } from './functions/NodeRed';
import ReactFlow, {
	applyNodeChanges,
	applyEdgeChanges,
	addEdge,
	Controls,
	Background,
	ReactFlowProvider,
} from 'react-flow-renderer';

const GridListener = ({ tabId }) => {
	const { setNodesToTab, toggleSubflowEditingState, setDirty } = useStore(
		useCallback((state) => {
			return {
				setNodesToTab: state.setNodesToTab,
				toggleSubflowEditingState: state.toggleSubflowEditingState,
				setDirty: state.setDirty,
				// activeTab: state.tabs.activeTab
			};
		}, [])
	);
	useEffect(() => {
		// handles node edit changes
		const updateReactFlow = (updatedNode) => {
			const nodes =
				useStore.getState().tabs.byId[tabId].reactFlowByTab.nodes;

			// update reactFlow
			const updatedNodes = _.map(nodes, (n) => {
				return !n.selected
					? n
					: {
							...n,
							data: {
								type: updatedNode.type,
								node: _.omit(updatedNode, ['_', '_def', '_config']),
							},
					  };
			});

			setNodesToTab(updatedNodes, tabId);
			setDirty(true);
		};
		const onCloseHandler = () => {
			const activeTab = useStore.getState().tabs.activeTab;
			if (activeTab === tabId) {
				var movingSet = window.RED.view.movingSet.nodes();
				if (movingSet && movingSet.length > 0) {
					updateReactFlow(getNodeById(movingSet[0].id));
					window.RED.view.movingSet.clear();
				}
			}
		};

		const onNodesDelete = (nodes) => {
			for (let node of nodes) {
				window.RED.nodes.remove(node.id);
				setDirty(true);
			}
		};

		const onNodesChange = (changes) => {
			const tab = useStore.getState().tabs;
			const tId = tab.activeTab;
			// TODO: update x,y position of node in node-red when change.type === "position"
			const updatedNodes = applyNodeChanges(
				changes,
				tab.byId[tId].reactFlowByTab.nodes
			);
			setNodesToTab(updatedNodes, tab['activeTab']);
			//setDirty(true);
		};

		const updateGridWithDeletion = (nodes) => {
			onNodesDelete(nodes);
			onNodesChange(
				_.map(nodes, (n) => {
					return { id: n.id, type: 'remove' };
				})
			);
			setDirty(true);
		};

		const deleteButtonHandler = () => {
			const activeTab = useStore.getState().tabs.activeTab;
			if (activeTab === tabId) {
				var movingSet = window.RED.view.movingSet.nodes();
				if (movingSet && movingSet.length > 0) {
					updateGridWithDeletion(movingSet);
					window.RED.view.movingSet.clear();
				}
			}
		};

		console.log('editor close event added');
		window.RED.events.on('maya:editor:close', onCloseHandler);
		window.RED.events.on('maya:delete:button', deleteButtonHandler);
		return () => {
			console.log('editor close listener destroyed');
			// clean up
			window.RED.events.off('maya:editor:close', onCloseHandler);
			window.RED.events.off('maya:delete:button', deleteButtonHandler);
		};
	}, []);

	// toggles open subflow editor tab
	useEffect(() => {
		const editSubflowHandler = (nodeId) => {
			const activeTab = useStore.getState().tabs.activeTab;
			if (activeTab === tabId) {
				const subflowInstance = getNodeById(nodeId);
				const subflowId = subflowInstance.type.substring(
					subflowInstance.type.indexOf(':') + 1
				);
				// set subflow editing
				toggleSubflowEditingState(subflowId, true);
			}
		};
		console.log('edit subflow event added');
		window.RED.events.on('maya:edit:subflow', editSubflowHandler);
		return () => {
			console.log('edit subflow listener destroyed');
			// clean up
			window.RED.events.off('maya:edit:subflow', editSubflowHandler);
		};
	}, []);

	return null;
};

export default React.memo(GridListener);
