import { cloneDeep, find } from 'lodash';
import { customAlphabet } from 'nanoid';
import getRedInstance from '../../Workspace/functions/getRedInstance';
import { editorStore } from '../../zustand';
/**
 *	Calculates the focus point for a list of nodes
 *
 * @param {import("../../zustand/types").Node[]} nodes
 */
export const calculateFocusArea = (nodes) => {
	let sumOfY = [];
	let sumOfX = [];
	let x;
	let y;
	nodes.forEach((cmd) => {
		if (cmd.y !== undefined) sumOfY.push(cmd.y);
		if (cmd.x !== undefined) sumOfX.push(cmd.x);
	});
	y = sumOfY?.reduce(
		(previousValue, currentValue) => previousValue + currentValue,
		0
	);
	x = sumOfX?.reduce(
		(previousValue, currentValue) => previousValue + currentValue,
		0
	);
	y = y / sumOfY.length;
	x = x / sumOfY.length;
	return { x, y };
};

/**
 * Parses the text pasted by the user and returns an array of commands
 * @param {string} pastedText
 */
export const parsePastedText = (pastedText) => {
	const numberedList = /[0-9]\. /g;

	if (pastedText.match(numberedList)?.length) {
		const pastedCommands = pastedText
			.split(numberedList)
			.map((p) => {
				return p.trim();
			})
			.filter((f) => f !== '');
		return pastedCommands;
	} else if (pastedText.includes('\r') || pastedText.includes('\n')) {
		if (pastedText.includes('\r\n')) {
			return pastedText.split('\r\n').filter((f) => f !== '');
		} else if (pastedText.includes('\n') && !pastedText.includes('\r')) {
			return pastedText.split('\n').filter((f) => f !== '');
		} else if (pastedText.includes('\r') && !pastedText.includes('\n')) {
			return pastedText.split('\r').filter((f) => f !== '');
		}
	} else {
		return [String(pastedText)].filter((f) => f !== '');
	}
};

/**
 * Scrambles the ids of the passed mayaFlow. Returns the new mayaFlow
 */
export const scrambleMayaFlowIds = async ({ mayaFlow }) => {
	const newFlow = cloneDeep(mayaFlow);
	let subflowNode,
		otherNodes = [];
	for (let i = 0; i < newFlow.length; i++) {
		if (newFlow[i].type === 'subflow') {
			subflowNode = newFlow[i];
		} else {
			otherNodes.push(newFlow[i]);
		}
	}

	let stringSubflowNode = JSON.stringify(subflowNode);
	let stringifiedOtherNodes = JSON.stringify(otherNodes);
	let updatedSubflowNode;
	let ogIds = [];
	let oguIds = [];
	for (let i = 0; i < otherNodes.length; i++) {
		const nodeId = otherNodes[i].id;
		const nodeuId = otherNodes[i]?._id ? otherNodes[i]?._id : nodeId;
		ogIds.push(nodeId);
		oguIds.push(nodeuId);
		const newNodeId = window.RED.nodes.id();
		otherNodes[i]._def = {};
		if (subflowNode) {
			updatedSubflowNode = stringSubflowNode.replaceAll(nodeId, newNodeId);
			stringSubflowNode = updatedSubflowNode;
			otherNodes[i].z = subflowNode.id;
		}
		stringifiedOtherNodes = stringifiedOtherNodes.replaceAll(
			nodeId,
			newNodeId
		);
	}
	otherNodes = JSON.parse(stringifiedOtherNodes);
	for (let i = 0; i < oguIds.length; i++) {
		otherNodes[i]['_id'] = oguIds[i];
	}
	if (updatedSubflowNode) {
		updatedSubflowNode = await updatedSubflowNode.replaceAll(
			subflowNode._id,
			subflowNode.id
		);
		let c = JSON.parse(updatedSubflowNode);
		c['_id'] = subflowNode['_id'];
		otherNodes.push(c);
	}
	return otherNodes;
};

export const generateUniqueNodeIds = ({ mayaFlow, ogIds, isOg }) => {
	const tabId = editorStore.getState().tabs.activeTab;

	const RED = getRedInstance();
	let stringFlow = mayaFlow;
	const flow = JSON.parse(stringFlow);
	const oldIds = isOg ? flow.map((node) => node.id) : ogIds;

	if (!isOg) {
		flow.forEach((node) => {
			let id = RED.nodes.id();
			stringFlow = stringFlow.replaceAll(node.id, id);
		});
	}

	const result = JSON.parse(stringFlow);
	const modResult = result.map((node, index) => {
		if (node.type === 'tab' || node.type.startsWith('subflow:')) {
			node.z = tabId;
		}
		node._id = oldIds[index];
		return node;
	});

	return { mayaSampleContent: modResult, ogIdReturn: oldIds };
};

export const findMainTab = ({ tabs }) => {
	let mainTab;
	for (let id in tabs.byId) {
		if (tabs.byId[id].type === 'tab') {
			mainTab = tabs.byId[id];
			break;
		}
	}
	return mainTab;
};

export const getMainSubflowDetails = ({ mainTab }) => {
	const mainSubflowNode = mainTab.mayaFlowByTab.find(
		(node) => node.id === node._id && node.type.includes('subflow')
	);
	if (mainSubflowNode) {
		const mainSubflowNodeId = mainSubflowNode.id;
		const mainSubflowTabId = mainSubflowNode.type.split(':')[1];
		return { nodeId: mainSubflowNodeId, tabId: mainSubflowTabId };
	} else {
		return { nodeId: '', tabId: '' };
	}
};

export const getInstanceSubflowDetails = ({ mainTab, mainSubflowNodeId }) => {
	const instanceSubflowNodes = mainTab.mayaFlowByTab.filter(
		(node) =>
			node._id === mainSubflowNodeId &&
			node.id !== mainSubflowNodeId &&
			node.type.includes('subflow')
	);
	if (instanceSubflowNodes.length > 0) {
		const instanceSubflows = {};
		instanceSubflowNodes.forEach((node) => {
			const nodeId = node.id;
			const tabId = node.type.split(':')[1];
			instanceSubflows[nodeId] = tabId;
		});
		return instanceSubflows;
	} else {
		return {};
	}
};

export const createRecipeWithEmptyStep = () => {
	const nanoId = customAlphabet('123456789abcdefghijklmnopqrstuvwxyz', 6);
	const newStepId = `step-instance-${nanoId()}`;
	const updatedRecipe = {
		[newStepId]: {
			id: newStepId,
			type: 'linear',
			indent_lvl: 0,
			lvl_idx: 0,
			from_node: '',
			prefix: '1.',
			parent_id: '',
			generated: false,
			scrambled: false,
			transformed: false,
			deployed: false,
			children: [],
			text: '',
		},
	};

	return updatedRecipe;
};
