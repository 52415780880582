import create from 'zustand';
import produce from 'immer';
import registry from './Sections/registry';
import _ from 'lodash';

// Log every time state is changed
const log = (config) => (set, get, api) =>
	config(
		(args) => {
			console.log('---- Stepper  applying ----');
			set(args);
			console.log('---- Stepper  new state ----', get());
		},
		get,
		api
	);

const useStepperStore = create(
	log((set, get) => ({
		open: false,
		steps: { byId: {}, allIds: [] },
		currentStep: 0,
		store: {},
		/**
		 * Open the Stepper from anywhere by triggering
		 * this function.
		 */
		openStepper: () => {
			set(
				produce(
					/**
					 *
					 * @param {import("./types/stepper").StepperState} draft
					 */
					draft => {
						draft.open = true;
					}
				)
			);
		},
		/**
		 * Close the Stepper from anywhere by triggering
		 * this function.
		 */
		closeStepper: () => {
			set(
				produce(
					/**
					 *
					 * @param {import("./types/stepper").StepperState} draft
					 */
					draft => {
						draft.open = false;
					}
				)
			);
		},
		/**
		 * Add a new step using a sectionId and Object as reference.
		 * @param {String} sectionId
		 * @param {Object} initState
		 * @param {Object} sectionObject
		 */
		addStep: (sectionId, initState, heading) => {
			set(
				produce(
					/**
					 *
					 * @param {import("./types/stepper").StepperState} draft
					 */
					draft => {
						if (draft.steps.byId[sectionId]) {
						} else {
							var objectHeading = _.get(
								registry,
								sectionId + '.heading'
							);
							var sectionObject = {
								heading: heading ? heading : objectHeading,
								state: initState ? initState : {},
							};
							console.log('OBJECT', sectionObject, initState);
							draft.steps.allIds = [
								...new Set([...draft.steps.allIds, sectionId]),
							];
							draft.steps.byId[sectionId] = sectionObject;
						}
					}
				)
			);
		},
		/**
		 * Clear all steps
		 */
		clearSteps: () => {
			set(
				produce(
					/**
					 *
					 * @param {import("./types/stepper").StepperState} draft
					 */
					draft => {
						draft.open = false;
						draft.steps.allIds = [];
						draft.steps.byId = {};
						draft.currentStep = 0;
						draft.store = {};
					}
				)
			);
		},
		/**
		 * Remove a step using sectionId as reference.
		 * @param {*} sectionId
		 */
		removeSteps: sectionId => {
			set(
				produce(
					/**
					 *
					 * @param {import("./types/stepper").StepperState} draft
					 */
					draft => {
						const index = draft.steps.allIds.indexOf(sectionId);
						if (index > -1) {
							draft.steps.allIds.splice(index, 1);
							delete draft.steps.byId[sectionId];
						}
					}
				)
			);
		},
		/**
		 * Switch according to some positive or negative increment
		 * @param {*} increment
		 */
		switchStep: increment => {
			set(
				produce(
					/**
					 *
					 * @param {import("./types/stepper").StepperState} draft
					 */
					draft => {
						if (
							draft.currentStep + increment >
							draft.steps.allIds.length - 1
						) {
							draft.currentStep = 0;
						} else if (draft.currentStep + increment < 0) {
							draft.currentStep = draft.steps.allIds.length - 1;
						} else {
							draft.currentStep += increment;
						}
					}
				)
			);
		},
		/**
		 * Change to next step
		 */
		nextStep: () => {
			console.log('anaste', get())
			get().switchStep(1);
		},
		/**
		 * Change to previous step
		 * Same as popLeadingSteps now, since we don't want to preserve future step states
		 */
		previousStep: () => {
			get().switchStep(-1);
			// remove leading steps
			set(
				produce(
					/**
					 * @param {import("./types/stepper").StepperState} draft
					 */
					draft => {
						draft.steps.allIds.splice(draft.currentStep + 1);
					}
				)
			);
		},
		/**
		 * Changes to previous state and removes all leading steps
		 * Use this to abandon a particular path
		 */
		popLeadingSteps: () => {
			get().switchStep(-1);
			// remove leading steps
			set(
				produce(
					/**
					 * @param {import("./types/stepper").StepperState} draft
					 */
					draft => {
						draft.steps.allIds.splice(draft.currentStep + 1);
					}
				)
			);
		},
		/**
		 * Update a particular step's state using section ID
		 */
		updateStepState: state => {
			set(
				produce(
					/**
					 *
					 * @param {import("./types/stepper").StepperState} draft
					 */
					draft => {
						var sectionId = draft.steps.allIds[draft.currentStep];
						console.log(
							state,
							sectionId,
							draft.currentStep,
							get().steps.allIds[draft.currentStep]
						);
						draft.steps.byId[sectionId] = {
							...draft.steps.byId[sectionId],
							...state,
						};
					}
				)
			);
		},
		/**
		 * Set the global store
		 */
		setStore: store => {
			set(
				produce(
					/**
					 * @param {import("./types/stepper").StepperState} draft
					 */
					draft => void (draft.store = store)
				)
			);
		},
		/**
		 * Update the global store
		 */
		updateStore: updateObject => {
			set(
				produce(
					/**
					 * @param {import("./types/stepper").StepperState} draft
					 */
					draft => void (draft.store = { ...draft.store, ...updateObject })
				)
			);
		},
		/**
		 * Clear the global store
		 */
		clearStore: () => {
			set(
				produce(
					/**
					 * @param {import("./types/stepper").StepperState} draft
					 */
					draft => void (draft.store = {})
				)
			);
		},
	}))
);

export default useStepperStore;
