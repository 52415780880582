import React from "react";
import { Pre } from "./styles";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/nightOwlLight";

const exampleCode = `
1. from gsheet get First_Name, Company, Email, Website, About (*)
2. split data into batches and send at intervals of  3 seconds (*)
    - 2.1. scrape and extract title, description and text from {{Website}} (*)
        2.1.1. go to step 3 (*)
    - 2.2. scrape and extract text from {{About}} (*)
        2.2.1. go to step 3 (*)
    - 2.3. store {{Company}} in {{company}} (*)
        2.3.1. go to step 3 (*)
3. show in a table with button Research (*)
`.trim();

const RecipePreview = ({textContent}) => (
  <Highlight {...defaultProps} theme={theme} code={textContent} language="jsx">
    {({ className, style, tokens, getLineProps, getTokenProps }) => (
      <Pre className={className} style={style}>
        {tokens.map((line, i) => (
          <div {...getLineProps({ line, key: i })}>
            {line.map((token, key) => (
              <span {...getTokenProps({ token, key })} />
            ))}
          </div>
        ))}
      </Pre>
    )}
  </Highlight>
);

export default RecipePreview;
