import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

import { useLocation } from 'react-router-dom';
// API
import APIFetch from '../../../../util/APIFetch';
import RegisterAPI from './RegisterAPI';
import { Link } from '@chakra-ui/react';
// Config
import { config } from '../../../../config';

//History
import History, { HistoryWithSlug } from '../../../../util/History';

//Utils
import validate_password from '../../../../util/Validate/validate_password';
import validate_email from '../../../../util/Validate/validate_email';

//Components
import Container from '../Container';
import MayaFilledButton from '../../../../library/buttons/FilledButton';
import ActionBtn from '../../../../library/buttons/ActionBtn';
import MayaInput from '../../../../library/input';

import {
	Flex,
	Box,
	Stack,
	Input,
	InputGroup,
	InputRightElement,
	Button,
	useToast,
	useColorMode,
} from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { ArrowForwardIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import useQueryParams from '../../../../util/useQueryParams';
import { getLongestTimeElapsedString } from '../../../../util/time';
import MayaSkeleton from '../../../../library/skeleton';
import { entryModes, FlowPreview } from '../FlowPreview/FlowPreview';
import theme from '../../../../library/theme';
import { setLoggedIn, setUser } from '../../../../redux/actions';
import { useDispatch } from 'react-redux';
import { setAuthTokens } from '../../../../redux/actions/user';
import OrDivider from '../Login/OrDivider';
import LoginWithGoogleButton from '../Login/LoginWithGoogle';


const PasswordInput = ({
	name,
	placeholder,
	onChange,
	onKeyDown,
	isInvalid,
	isDisabled,
}) => {
	const [show, setShow] = React.useState(false);

	const handleClick = () => setShow(!show);

	return (
		<InputGroup size="md" fontFamily="body">
			<MayaInput
				name={name}
				type={show ? 'text' : 'password'}
				onChange={onChange}
				placeholder={placeholder}
				onKeyDown={onKeyDown}
				isDisabled={isDisabled}
				isInvalid={isInvalid}
			/>

			<InputRightElement width="3rem">
				<Box
					as={show ? MdVisibilityOff : MdVisibility}
					size="25px"
					color="gray.300"
					onClick={handleClick}
				/>
			</InputRightElement>
		</InputGroup>
	);
};

export const RegisterCard = () => {
	const queryParams = useQueryParams();
	const [formData, setFormData] = useState({
		// inviteCode: { value: '' },
		firstName: { value: '' },
		email: { value: '' },
		inviteCode: { value: queryParams.get('inviteCode') || '' },
	});
	const [isRegistering, setRegistering] = useState(false);
	const [pwd, setPwd] = useState('');
	const [confirmPwd, setConfirmPwd] = useState('');
	const [pwdErr, setPwdErr] = useState(false);
	const [emailErr, setEmailErr] = useState(false);
	const [match, setMatch] = useState(false);
	const dispatch = useDispatch();

	const toast = createStandaloneToast({ theme: theme });
	// const [user, setUser] = useState("");
	// const [password, setPw] = useState("");
	// Setup the API Fetch utility for the Register View.
	// @ts-ignore
	const [{ fetchResults }] = APIFetch({
		locale: 'en',
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: RegisterAPI.apiService.postRegister.PATH_SEARCH,
		PATH_METHOD: RegisterAPI.apiService.postRegister.PATH_METHOD,
		formData,
	});

	useEffect(() => {
		if (pwd && confirmPwd) {
			if (pwd === confirmPwd) {
				setMatch(true);
			} else {
				setMatch(false);
			}
		} else {
			setMatch(false);
		}
	}, [pwd, confirmPwd]);

	const handleInputChange = ({ target }) => {
		// Get the right value for the type of input.
		const value = target.value;

		// Update state with new value for the input.
		setFormData((prevState) => {
			return {
				...prevState,
				[target.name]: {
					...formData[target.name],
					value,
				},
			};
		});
	};

	const onSubmit = async (e) => {
		// console.log(formData);
		setRegistering(true);
		try {
			const inviteCode = queryParams.get('inviteCode');
			const body = { ...formData };
			if (inviteCode) {
				// @ts-ignore
				body.username = { value: inviteCode };
			}
			const response = await fetchResults(body);
			if (response.status !== 200) {
				switch (response.status) {
					case 404: {
						toast({
							title: 'Account creation failed!',
							description:
								'Registration not allowed from outside https://app.mayalabs.io/register',
							position: 'bottom',
							status: 'error',
							duration: 4000,
							isClosable: false,
						});
						break;
					}
					case 402: {
						toast({
							title: 'Account creation failed!',
							description:
								'An account account already exists with the same email. Try login!',
							position: 'bottom',
							status: 'error',
							duration: 4000,
							isClosable: false,
						});
						break;
					}
					case 403: {
						toast({
							title: 'Account creation failed!',
							description: response.data.message,
							position: 'bottom',
							status: 'error',
							duration: 6000,
							isClosable: false,
						});
						break;
					}
					default: {
						toast({
							title: 'Account creation failed!',
							description:
								'The account already exists or some error occured signing user up',
							position: 'bottom',
							status: 'error',
							duration: 3000,
							isClosable: false,
						});
					}
				}
				setRegistering(false);
			} else {
				toast({
					title: 'Account Created!',
					description: 'You can now login to Maya.',
					position: 'bottom',
					status: 'success',
					duration: 3000,
					isClosable: false,
				});
				setRegistering(false);
				const user = response?.data?.user;
				const tokens = response?.data?.tokens
				if (user && tokens) { // Log the user in
					if (user.name.includes(' ')) {
						const parts = user.name.split(' ');
						user.firstName = parts[0];
						user.lastName = parts[1];
					} else {
						user.firstName = user.name;
						user.lastName = '';
					}
					user.id = user.profileSlug;
					dispatch(setLoggedIn(true));
					dispatch(setUser(user));
					dispatch(setAuthTokens({
						accessToken: tokens.accessToken,
						refreshToken: tokens.refreshToken,
						lastUpdated: Date.now()
					}))
					History.push('/workers');
				} else {
					History.push('/login');
				}
			}
			// console.log(response);
		} catch (err) {
			toast({
				title: 'Account creation failed!',
				description:
					'Some unknown error occured registering account for this email',
				position: 'bottom',
				status: 'error',
				duration: 3000,
				isClosable: false,
			});
			setRegistering(false);
		}
	};

	let query = new URLSearchParams(useLocation().search);
	useEffect(() => {
		// const inviteCode = query.get('inviteCode');
		const emailId = query.get('emailId');
		const firstName = query.get('firstName');
		// if (inviteCode) {
		// 	setFormData((prevState) => {
		// 		return {
		// 			...prevState,
		// 			inviteCode: {
		// 				value: inviteCode,
		// 			},
		// 		};
		// 	});
		// }
		if (emailId) {
			setFormData((prevState) => {
				return {
					...prevState,
					email: {
						value: emailId,
					},
				};
			});
		}
		if (firstName) {
			setFormData((prevState) => {
				return {
					...prevState,
					firstName: {
						value: firstName,
					},
				};
			});
		}
	}, [query, setFormData]);

	const [registerMode, setRegisterMode] = React.useState('');
	const headings = {
		[entryModes.NORMAL]: 'Start today, for free.',
		[entryModes.INSTALL_FROM_QUICK_SHARE]: 'Install in minutes.',
	};
	const subheadings = {
		[entryModes.NORMAL]:
			'Delegate boring, repetitive tasks to a machine so you can waste time in better ways.',
		[entryModes.INSTALL_FROM_QUICK_SHARE]:
			'Configure & install this across the Maya desktop or cloud app in just a few clicks.',
	};

	const mode = queryParams.get('mode') || entryModes.NORMAL;
	const inviteExists = !!queryParams.get('inviteCode');

	return (
		<Container heading={headings[mode]} subheading={subheadings[mode]}>
			<FlowPreview />
			{registerMode === '' && config.enableSocialLogin ? (
				<Stack marginBottom="40px">
					<Link
						href={`${config.app.BASEURL}/login?autoLoginWithGoogle=true`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
						onClick={() => {
							// analytics.track('Continue with Gmail', {
							// 	page: 'get access',
							// })
						}}
					>
						<MayaFilledButton
							size="sm"
							onClick={() => {}}
							isLoading={false}
							text={'Sign up with Google'}
							rightIcon={<ArrowForwardIcon />}
							buttonProps={{
								width: '100%',
							}}
						/>
					</Link>
					<MayaFilledButton
						size="sm"
						onClick={() => {
							setRegisterMode('email');
						}}
						isLoading={false}
						text={'Sign up with Email'}
						rightIcon={<ArrowForwardIcon />}
						colorScheme="gray"
						buttonProps={{
							marginBottom: '8px !important',
						}}
					/>
				</Stack>
			) : (
				registerMode !== 'email' && (
					<Stack marginBottom="40px">
						<MayaFilledButton
							size="sm"
							onClick={() => {
								setRegisterMode('email');
							}}
							isLoading={false}
							text={'Sign up with Email'}
							rightIcon={<ArrowForwardIcon />}
							colorScheme="gray"
							buttonProps={{
								marginBottom: '8px !important',
								mt: '20px',
							}}
						/>
					</Stack>
				)
			)}
			{registerMode === 'email' && (
				<Stack>
					{inviteExists && (
						<Box
							textStyle="sans.xs"
							color="light.font.gray.400"
							bg="light.theme.yellow.200"
							border="1px solid"
							borderColor="light.border.gray.200"
							borderRadius="2px"
							padding="8px 16px"
							mt="8px"
							// pl='2px'
						>
							Invite Code: <b>{queryParams.get('inviteCode')}</b>
						</Box>
					)}
					<ActionBtn
						text="BACK"
						icon={<ChevronLeftIcon />}
						onClick={() => {
							setRegisterMode('');
						}}
					/>
					<InputGroup size="md" style={{ marginBottom: '0rem' }}>
						<MayaInput
							name="firstName"
							placeholder="Enter first name"
							onChange={handleInputChange}
						/>
					</InputGroup>
					<InputGroup size="md" style={{ marginBottom: '0rem' }}>
						<MayaInput
							name="email"
							placeholder="Enter your email"
							onChange={(e) => {
								if (validate_email(e.target.value)) {
									setEmailErr(false);
								} else if (e.target.value) {
									setEmailErr(true);
								} else {
									setEmailErr(false);
								}
								handleInputChange(e);
							}}
							defaultValue={formData.email.value}
							isInvalid={emailErr}
						/>
					</InputGroup>

					<PasswordInput
						name="password"
						placeholder="Enter password"
						isInvalid={pwdErr}
						onChange={(e) => {
							setPwd(e.target.value);

							if (validate_password(e.target.value)) {
								setPwdErr(false);
							} else if (e.target.value) {
								setPwdErr(true);
							} else {
								setPwdErr(false);
							}
							handleInputChange(e);
						}}
					/>
					<PasswordInput
						name="password"
						placeholder="Confirm password"
						onChange={(e) => {
							setConfirmPwd(e.target.value);

							handleInputChange(e);
						}}
						isInvalid={confirmPwd && !match}
						isDisabled={pwdErr}
					/>

					<MayaFilledButton
						size="sm"
						onClick={onSubmit}
						isLoading={isRegistering}
						text={'Sign Up'}
						rightIcon={<ArrowForwardIcon />}
						colorScheme="purple"
						isDisabled={!match}
					/>

					{pwdErr && (
						<Box
							height="50px"
							width="250px"
							textStyle="sans.xs"
							color="light.font.gray.200"
							textAlign="left"
							mb="20px !important"
						>
							{pwdErr
								? 'Must have at least 1 special character, 1 capital letter, 1 number, and be at least 8 characters long.'
								: null}
						</Box>
					)}
				</Stack>
			)}
			<Flex
				pb='8px'
			>
				<OrDivider />
			</Flex>
			<Flex
				mt='8px'
				bg='tomato'
			>
				<LoginWithGoogleButton 
					onClick={() => setRegisterMode('google')}
				/>
			</Flex>
			<Box
				marginTop="40px"
				textStyle="sans.xs"
				textAlign="left"
				color="light.font.gray.200"
			>
				By proceeding, you agree to our <br />{' '}
				<Link
					href="https://mayalabs.io/terms-of-use/"
					target="_blank"
					rel="noopener noreferrer"
					color="light.theme.purple.300"
				>
					Terms of Use
				</Link>{' '}
				and{' '}
				<Link
					href="https://mayalabs.io/privacy/"
					target="_blank"
					rel="noopener noreferrer"
					color="light.theme.purple.300"
				>
					Privacy Policy
				</Link>
				.
			</Box>
		</Container>
	);
};

export default RegisterCard;

// <Box
//           display="flex"
//           justifyContent="center"
//           color="gray.600"
//           fontSize="0.8rem"
//           fontFamily="body"
//         >
//           or
//         </Box>
//         <Button leftIcon={FaGoogle} variantColor="gray" size="md">
//           Sign in with Google
//         </Button>
