import { config } from '../../config';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

const linkSessionToRuntime = async ({
	profileSlug,
	workspaceId,
	sessionId,
}) => {
	const APIRequestBody = { profileSlug, workspaceId, sessionId };

	const linkSessionToRuntimeFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.linkSessionToRuntime.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.linkSessionToRuntime.PATH_METHOD,
	});

	const response = await linkSessionToRuntimeFn(APIRequestBody);
	return response;
};

export default linkSessionToRuntime;
