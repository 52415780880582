/**
 * 
 * @param {string} groupId 
 * @param {import('../zustand/types').DashboardLayout} fullLayout
 * 
 * @returns {import('../zustand/types').GroupLayout}
 */
export function getGroupLayoutFromFullLayout(groupId, fullLayout) {
    const breakpoints = ['lg', 'md', 'sm', 'xs', 'xxs']
    const ret = {}

    breakpoints.forEach(breakpoint => {
        const layouts = fullLayout[breakpoint]
        const layout = layouts.find(l => l.i === groupId)
        if (!layout) {
            return
        }

        const { x, y, w, h, minH, minW} = layout
        ret[breakpoint] = { x, y, w, h, minH, minW}
    })

    // @ts-ignore
    return ret
}