import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

import { config } from '../../config';
/**
 * get AuthURL for the service
 * @param {import('./types/configurations').GetAuthUrlArgs} param
 * @returns
 */
const getAuthUrl = async ({ configurationType, formData }) => {
	var getAuthUrlResponse, getAuthUrlAPIFn;
	let data = {
		config: {
			resource: configurationType.resource,
			type: configurationType.type,
			meta: configurationType.config.variables[0],
			redirectUrl: configurationType.redirectUrl,
			clientId: configurationType.clientId,
			authUrl: configurationType.authUrl,
			responseType: configurationType.responseType,
			extraAuthParams: configurationType.extraAuthParams,
			values: {},
		},
	};

	for (let key in formData) {
		// if (key !== 'name') {
			data['config']['values'][key] = formData[key];
		// }
	}
	if (window.navigator.onLine) {
		getAuthUrlAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.getAuthUrl.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.getAuthUrl.PATH_METHOD,
		});
		try {
			getAuthUrlResponse = await getAuthUrlAPIFn(data);
			return getAuthUrlResponse['data'];
		} catch (err) {
			console.log('Error authenticating configuration');
			return {
				error: true,
				results: null,
			};
		}
	} else {
		// TODO notify for offline state
	}
};

export default getAuthUrl;
