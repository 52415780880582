/**
 * # Initializes Maya red editor
 *
 * @param {string} runtime
 * @param {*} tokens
 * @returns
 */
function redScript(runtime, tokens) {
	return new Promise(function (resolve, reject) {
		var url = runtime;
		var token = tokens;
		document.body.style.zoom = '100%';
		const scriptOne = `
    function reload_js(src) {
      $('script[src="' + src + '"]').remove();
      $('<script>').attr('src', src).appendTo('head');
    }
    reload_js('/vendor/vendor.js')
    reload_js('/red/red.js')
    $(function() {
      "localhost" !== window.location.hostname &&
        "127.0.0.1" !== window.location.hostname &&
        (document.title = document.title + " : " + window.location.hostname),
        RED.init({ apiRootUrl: "${url}", accessToken : "${token}" });
        RED.events.on("workspace:dirty", function (state) {
          if (state.dirty) {
            var tabElems = document.querySelector("#root > div > div.css-14pomo5 > div.css-1vdg86b").children;
            // for(let index = 0; index < tabElems.length; index++){
            //   tabElems[index].style.pointerEvents = 'none'
            // }
            var event = new CustomEvent("dirtyTabDOM", { "toggle": true });
            document.dispatchEvent(event);
            var event = new CustomEvent("dirtyGridDOM", { "toggle": true });
            document.dispatchEvent(event);
          } else {
            window.onbeforeunload = null;
            var tabElems = document.querySelector("#root > div > div.css-14pomo5 > div.css-1vdg86b").children;
            // for(let index = 0; index < tabElems.length; index++){
            //   tabElems[index].style.pointerEvents = 'auto'
            // }
            var event = new CustomEvent("cleanTabDOM", { "toggle": false });
            document.dispatchEvent(event);
            var event = new CustomEvent("cleanGridDOM", { "toggle": false });
            document.dispatchEvent(event);
          }
        });

    });
    `;
		const scriptTwo = `
    RED = null
    location.reload()
    function reload_js(src) {
      $('script[src="' + src + '"]').remove();
      $('<script>').attr('src', src).appendTo('head');
    }
    reload_js('/vendor/vendor.js')
    reload_js('/red/red.js')
    $(function() {
      "localhost" !== window.location.hostname &&
        "127.0.0.1" !== window.location.hostname &&
        (document.title = document.title + " : " + window.location.hostname),
       RED.init({ apiRootUrl: "${url}", accessToken : "${token}" });
       RED.events.on("workspace:dirty", function (state) {
        if (state.dirty) {
          var tabElems = document.querySelector("#root > div > div.css-1niahm8").children;
          // for(let index = 0; index < tabElems.length; index++){
          //   tabElems[index].style.pointerEvents = 'none'
          // }
          var event = new CustomEvent("dirtyTabDOM", { "toggle": true });
          document.dispatchEvent(event);
          var event = new CustomEvent("dirtyGridDOM", { "toggle": true });
          document.dispatchEvent(event);
        } else {
          window.onbeforeunload = null;
          var tabElems = document.querySelector("#root > div > div.css-1niahm8").children;
          // for(let index = 0; index < tabElems.length; index++){
          //   tabElems[index].style.pointerEvents = 'auto'
          // }
          var event = new CustomEvent("cleanTabDOM", { "toggle": false });
          document.dispatchEvent(event);
          var event = new CustomEvent("cleanGridDOM", { "toggle": false });
          document.dispatchEvent(event);
        }
      });

    });
    `;
		if (document.body.getElementsByClassName('node-red-maya')[0]) {
			// console.log("script changed", scriptTwo);
			let elem = document.body.getElementsByClassName('node-red-maya');
			const s_new = document.createElement('script');
			s_new.type = 'text/javascript';
			s_new.className = 'node-red-maya';
			// s.async = true;
			s_new.innerHTML = scriptTwo;
			document.body.replaceChild(s_new, elem[0]);
			// console.log("Node is ", s_new);
			// console.log("The document body", document.getElementsByClassName("node-red-maya"));
		} else {
			// console.log("Script", scriptOne)
			const s = document.createElement('script');
			s.type = 'text/javascript';
			s.className = 'node-red-maya';
			// s.async = true;
			s.innerHTML = scriptOne;
			document.body.appendChild(s);
			// console.log("Node is ", s);
			// console.log("The document body", document.getElementsByClassName("node-red-maya"));
		}
		resolve();
	});
}

export default redScript;
