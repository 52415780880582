// Dependency imports

// import worker from 'workerize-loader!../../../src/maya-service-worker'; // eslint-disable-line import/no-webpack-loader-syntax

import APIFunction from '../../util/APIFetch/function';

import APIRoutes from '../../util/APIRoutes';

// config
import { config } from '../../config';
/**
 * Function to get all profiles for a user
 */
const changeTeamMemberPermission = async ({
	teamProfileSlug,
    userProfileSlug,
    newPermission
}) => {
	// Create an instance of your worker
	var changeTeamMemberPermissionFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.changeTeamMemberPermission.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.changeTeamMemberPermission.PATH_METHOD,
		// URL_PARAM: { profileSlug },

	});

    const formData = {
        teamProfileSlug,
        userProfileSlug,
        newPermission
    }

	var changeTeamMemberPermissionResult = await changeTeamMemberPermissionFn(formData);
	return changeTeamMemberPermissionResult['data'];
};

export default changeTeamMemberPermission;