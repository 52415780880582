import { logoutUser } from '../../redux/actions';
import { store } from '../../redux/store';
import History from '../History';

/**
 * List of paths that should not be restored after login
 */
const restoreLocationBlacklist = ['/login', '/reset', '/forgot', '/register'];

/**
 * Common fn to redirect user to `/login`
 */
const redirectToLogin = () => {
	/** reset/logout user state in redux */
	store.dispatch(logoutUser());
	/** Sending the user to the login page. */
	/** get current location */
	const currentPathWithoutDomain = `${History.location.pathname}${History.location.search}${History.location.hash}`;
	History.push(
		'/login',
		// do not restore location for blacklisted paths
		!restoreLocationBlacklist.some((path) =>
			History.location.pathname.startsWith(path)
		)
			? {
					restoreLocation: currentPathWithoutDomain, // pass it via state to be restored after login
			  }
			: undefined
	);
};

export default redirectToLogin;
