import React, { useCallback, useEffect } from 'react';
import { Box, useToast } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/react';
import WorkspaceElement from './WorkspaceElement';
import SectionHeader from '../../../../../library/text/SectionHeader';
import produce from 'immer';
import useStepperStore from '../../../StepperStore';
import createBrain from '../../../../../functions/brain/createBrain';
import startBrain from '../../../../../functions/brain/startBrain';

import { useDispatch, useSelector } from 'react-redux';

import { addBrains } from '../../../../../redux/actions';
import { normalize } from '../../../../../util/Misc';

import { useTabStore } from '../../../../Electron/Tabs/zustand';
import { HistoryWithSlug } from '../../../../../util/History';
import useStepperAnalytics from '../../../hooks/useStepperAnalytics';
import { stepperFlowTypes } from '../../registry';
import useChecklistItems from '../../../../Checklist/hooks/useChecklistItems';
import allChecklistItems from '../../../../Checklist/registry';
import useProfileSlug from '../../../../../hooks/useProfileSlug';
import useAnalytics from '../../../../../util/Analytics/useAnalytics';
import theme from '../../../../../library/theme';
/**
 * This step is used to select where to create the
 * workspace and what to name it.
 *
 * Design reference : https://www.figma.com/file/KAF1ostHf3VBeZsctGl3ZA/MayaDesign?node-id=2642%3A2308
 * @returns
 */

const NewWorkspace = () => {
	useStepperAnalytics();
	const track = useAnalytics();
	const slug = useProfileSlug();
	const toast = createStandaloneToast({ theme: theme });
	const dispatch = useDispatch();
	const { checklistItems, updateChecklistItem } = useChecklistItems();

	const { addTab } = useTabStore((state) => {
		return {
			addTab: state.addTab,
		};
	});

	const {
		currentStep,
		nextStep,
		updateStepState,
		updateStore,
		addStep,
		closeStepper,
		clearSteps,
	} = useStepperStore(
		/**
		 * @param {import('../../../types/stepper').StepperState }
		 * @returns {{currentStep: number}}
		 */
		(state) => {
			return {
				currentStep: state.currentStep,
				nextStep: state.nextStep,
				updateStepState: state.updateStepState,
				updateStore: state.updateStore,
				addStep: state.addStep,
				closeStepper: state.closeStepper,
				clearSteps: state.clearSteps,
			};
		}
	);
	const currentSectionId = useStepperStore(
		useCallback(
			/**
			 *
			 * @param {import('../../../types/stepper').StepperState} s
			 * @returns
			 */
			(s) => s.steps.allIds[currentStep],
			[currentStep]
		)
	);

	const currentStepState = useStepperStore(
		useCallback(
			/**
			 * @param {import('../../../types/stepper').StepperState} s
			 */
			(s) =>
				s.steps.byId[currentSectionId]
					? s.steps.byId[currentSectionId].state
					: {},
			[currentSectionId]
		)
	);

	/**
	 * @type {[{state: any}, import('react').Dispatch<import('react').SetStateAction<{ state: any; }>>]}
	 */
	const [state, setState] = React.useState({
		state: {
			...currentStepState,
		},
	});
	const [isLoading, setIsLoading] = React.useState(false);

	const setPlatform = (option) => {
		var newState = produce(
			/**
			 * @param {import('../../types/newWorkspace').DraftWorkspaceCreationState} draft
			 */
			(draft) => {
				draft.state.platformOption = option;
				return draft;
			},
			state
		);

		setState(newState);
	};

	console.log('CREATE STATE', state);
	const setDevice = (device) => {
		var newState = produce(
			/**
			 * @param {import('../../types/newWorkspace').DraftWorkspaceCreationState} draft
			 */
			(draft) => {
				draft.state.device = device;
				return draft;
			},
			state
		);

		setState(newState);
	};

	const setName = (name) => {
		var newState = produce(
			/**
			 * @param {import('../../types/newWorkspace').DraftWorkspaceCreationState} draft
			 */
			(draft) => {
				draft.state.name = name;
				return draft;
			},
			state
		);

		setState(newState);
	};

	const setselectedWorkspaceId = (id) => {
		var newState = produce(
			/**
			 * @param {import('../../types/newWorkspace').DraftWorkspaceCreationState} draft
			 */
			(draft) => {
				draft.state.selectedWorkspaceIdId = id;
				return draft;
			},
			state
		);

		setState(newState);
	};

	const selectedProfile = useSelector(
		/**
		 *
		 * @param {import('../../../../../redux/reducers/types/profileReducer').ProfilesState} state
		 * @returns
		 */
		(state) => state.profiles.profiles[state.profiles.selected]
	);

	const handleStepperStateChange = (selectedWorkspaceId) => {
		const { stepperFlowType, moduleList } = currentStepState;
		const nextStepState = {
			...currentStepState,
			...state.state,
			selectedWorkspaceId,
		};
		updateStore(nextStepState);

		if (
			checklistItems[allChecklistItems.createFirstWorkspace] &&
			!checklistItems[allChecklistItems.createFirstWorkspace]?.done
		) {
			try {
				updateChecklistItem(allChecklistItems.createFirstWorkspace, true);
			} catch (e) {
				console.error('error', e);
			}
		}

		switch (stepperFlowType) {
			case stepperFlowTypes.checklist.CREATE_WORKSPACE:
			// case stepperFlowTypes.onboarding.NORMAL:
			case stepperFlowTypes.home.NEW_AUTOMATION: {
				addTab(selectedWorkspaceId, nextStepState.name);
				closeStepper();
				clearSteps();
				HistoryWithSlug.push(`/edit?id=${selectedWorkspaceId}`);
				break;
			}
			case stepperFlowTypes.store.INSTALL_MODULE_FLOW:
			case stepperFlowTypes.onboarding.INSTALL_FROM_QUICK_SHARE:
			case stepperFlowTypes.external.INSTALL_FROM_QUICK_SHARE:
			case stepperFlowTypes.home.IMPORT_NEW_FLOW: {
				if (
					moduleList &&
					Object.values(moduleList).some((mod) => !!mod.configurationType)
				) {
					addStep('module.configProfile', nextStepState);
				} else {
					addStep('collection.install', nextStepState);
				}
				break;
			}
			default:
				return;
		}
		nextStep();
	};

	const createWorkspace = () => {
		setIsLoading(true);
		track('[Workspace] Creation Started', {
			name: state.state.name,
			device: state.state.device,
			slug: slug,
		});
		// @ts-ignore
		createBrain({
			name: state.state.name,
			device: state.state.device,
			slug: slug,
		}).then((res) => {
			// @ts-ignore
			if (!res.error) {
				startBrain({
					// @ts-ignore
					brain: res,
					slug,
					onDone: () => {
						dispatch(
							addBrains(normalize([{ ...res, status: 'STARTED' }]))
						);
						track('[Workspace] Creation Success', {
							id: res._id,
							name: state.state.name,
							device: state.state.device,
							slug: slug,
						});
						setIsLoading(false);
						toast({
							title: 'Workspace started!',
							description: 'The workspace has successfully started',
							status: 'success',
							duration: 1000,
							isClosable: true,
						});
						setselectedWorkspaceId(res._id);
						handleStepperStateChange(res._id);
						// analytics.track(`Select Brain: Start Brain`, {
						// 	feature: 'uncategorized',
						// 	status: 'success',
						// 	brain: {
						// 		...brain,
						// 		flows: null,
						// 		intents: null,
						// 		nodes: null,
						// 	},
						// });
					},
					onError: () => {
						setIsLoading(false);
						track('[Workspace] Creation Failed', {
							name: state.state.name,
							device: state.state.device,
							slug: slug,
						});
						toast({
							title: 'Error!',
							description:
								'This workspace could not be started. Try again later.',
							status: 'error',
							duration: 1000,
							isClosable: true,
						});

						// analytics.track(`Select Brain: Start Brain`, {
						// 	feature: 'uncategorized',
						// 	status: 'failure',
						// 	brain: {
						// 		...brain,
						// 		flows: null,
						// 		intents: null,
						// 		nodes: null,
						// 	},
						// });
					},
					onLoading: () => {
						track('[Workspace] Creation Loading', {
							name: state.state.name,
							device: state.state.device,
							slug: slug,
						});
						toast({
							title: 'Start in progress.',
							description:
								'Please wait while we are starting the workspace',
							status: 'info',
							duration: 3000,
							isClosable: true,
						});
					},
				});
			}
		});
		// setTimeout(() => {
		// 	setIsLoading(false);
		// }, 2000);
	};

	const { stepperFlowType } = currentStepState;

	return (
		<Box
			display="flex"
			flexDir="column"
			justifyContent="center"
			alignItems="center"
		>
			<Box paddingTop="20px" paddingBottom="30px">
				<SectionHeader
					heading={
						stepperFlowType === stepperFlowTypes.onboarding.NORMAL
							? 'Create a workspace'
							: 'Where do you want this to run?'
					}
					subheading="Workspaces are visual editors to describe and modify your natural language programs."
				/>
			</Box>
			<WorkspaceElement
				state={state.state}
				setDevice={setDevice}
				setPlatform={setPlatform}
				setName={setName}
				createWorkspace={createWorkspace}
				isLoading={isLoading}
				// isLoading={true}
			/>
		</Box>
	);
};

const sectionObject = {
	heading: 'new workspace',
	component: <NewWorkspace />,
};

export default sectionObject;
