// Dependency imports

import isElectron from 'is-electron';

import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';
import { store } from '../../redux/store';
/**
 * Function to Create a New Brain, given some parameters
 */

const updateBrain = async (formData) => {
	const { brains } = store.getState();
	var updateBrainAPIFn, updateBrainIPCFn, updateBrainResponse;
	let brainDevice = brains.byId[formData['_id']]['device']['platform'];
	if (brainDevice === 'cloud') {
		formData['local'] = false; //flag to check if brain PV is to be created on cloud
		updateBrainAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.updateBrain.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.updateBrain.PATH_METHOD,
			PATH_QUERY: formData['_id'],
		});
		updateBrainResponse = await updateBrainAPIFn(formData);
		return updateBrainResponse['data'];
	} else {
		formData['local'] = true; //flag to check if brain PV is to be created on cloud
		updateBrainIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.updateBrain.EVENT,
		});
		updateBrainResponse = await updateBrainIPCFn(formData);
		return updateBrainResponse['data'];
	}
};

export default updateBrain;
