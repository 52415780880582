// @ts-nocheck
import React, { useEffect, useRef } from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css'; //Example style, you can use another
import { Box } from '@chakra-ui/react';
import './Editor.module.css';
import marked from 'marked';

function MarkdownRenderer({ content }) {
	const html = marked.parse(content);
	const customHtml = html.replace(
		/<a /g,
		'<a style="color: blue; text-decoration: underline;" target="_blank" '
	);

	return (
		<Box
			paddingInline={7}
			paddingBlock={3}
			style={{
				fontFamily:
					'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
			}}
			dangerouslySetInnerHTML={{ __html: customHtml.trim() }}
		/>
	);
}

const RecipePreviewEditable = ({
	content,
	changeContent,
	maxHeight = '12rem',
	recipeEditorContRef,
	generateRecipeFromPreview,
}) => {
	const bottomRef = useRef(null);

	useEffect(() => {
		if (!content.includes('...')) {
			bottomRef.current?.scrollIntoView({ behavior: 'auto' });
		}
	}, [content]);

	useEffect(() => {
		const handler = (e) => {
			if (e.key === 'Enter' && e.shiftKey) {
				e.preventDefault();
				generateRecipeFromPreview();
			}
		};

		document.addEventListener('keydown', handler);
		return () => {
			document.removeEventListener('keydown', handler);
		};
	}, [content, generateRecipeFromPreview]);

	const regex = /\[([^\]]+)\]\((https?:\/\/[^\)]+)\)/g;
	const matches = content.match(regex);

	return (
		<Box
			maxHeight={maxHeight}
			overflow="scroll"
			ref={recipeEditorContRef}
			sx={{
				'&::-webkit-scrollbar': {
					w: '0.1rem',
				},
				'&::-webkit-scrollbar-track': {
					w: '0.1rem',
				},
				'&::-webkit-scrollbar-thumb': {
					bg: 'light.borders.gray.300',
					borderRadius: '5px',
				},
			}}
			paddingLeft="4px"
			paddingBottom="4px"
		>
			{!matches ? (
				<Editor
					value={content}
					onValueChange={(val) => changeContent(val)}
					highlight={(val) => highlight(val, languages.js)}
					padding={7}
					style={{
						fontFamily:
							'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
					}}
					textareaClassName="recipe-preview-editor"
				/>
			) : (
				<div>
					<MarkdownRenderer content={content} />
				</div>
			)}
			<div ref={bottomRef} />
		</Box>
	);
};

export default RecipePreviewEditable;
