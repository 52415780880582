import {
	Menu,
	Item,
	theme,
	animation,
	Separator,
	Submenu,
	useContextMenu,
	contextMenu,
} from 'react-contexify';
import { Flex, Box } from '@chakra-ui/react';
import styled from 'styled-components';

const StyledMenu = styled(Menu)`
	box-shadow: none;
	background-color: #fdfdfd;
	border-radius: 2px;
	padding: 2px 0px;
	border: 1.5px solid #e7e7e7;

	.react-contexify__separator {
		background-color: #e7e7e7;
		height: 1px;
		width: 100%;
	}
`;

export const ContextMenu = ({ id, children }) => {
	return (
		<StyledMenu
			id={id}
			// animation={animation.fade}
			animation={false}
			theme={theme.light}
			className="react-contexify"
		>
			{children}
		</StyledMenu>
	);
};

/**
 *
 * @param {import("./types/menu").ContextMenu} param0
 * @returns
 */
export const ContextMenuItem = ({ icon, label, onClick }) => {
	function handleClick({ event: e }) {
		onClick(e);
		e.stopPropagation();
		contextMenu.hideAll();
	}

	return (
		<Item onClick={handleClick}>
			<Flex alignItems="center">
				<Box width="15px" mr="6px">
					{icon}
				</Box>
				<Box textStyle={'sans.xs'} color="#656161">
					{label}
				</Box>
			</Flex>
		</Item>
	);
};

export const ContextMenuSeparator = () => {
	return <Separator />;
};
