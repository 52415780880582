import { config } from '../../config';

export function requestIsForAppBackend(requestConfig) {
	let rUrl = requestConfig.url;
	if (requestConfig.baseURL) {
		rUrl = requestConfig.baseURL;
	}
	const reqUrl = new URL(rUrl);
	const serverUrl = new URL(config.apiServer.BASEURL);

	return reqUrl.origin === serverUrl.origin;
}

export function requestIsToWorkspaceServer(requestConfig) {
	let rUrl = requestConfig.url;
	if (requestConfig.baseURL) {
		rUrl = requestConfig.baseURL;
	}
	const reqUrl = new URL(rUrl);
	// console.log('login2 reqUrl', reqUrl);
	return reqUrl.host.startsWith('rt-');
}

export function requestIsToAuthService(requestConfig) {
	let rUrl = requestConfig.url;
	if (requestConfig.baseURL) {
		rUrl = requestConfig.baseURL;
	}
	const reqUrl = new URL(rUrl);
	let serverUrl = new URL(
		config.authservice.BASEURL || 'http://localhost:9000'
	);
	return (
		reqUrl.origin === serverUrl.origin &&
		!reqUrl.pathname.startsWith('/login') &&
		!reqUrl.pathname.startsWith('/register')
	);
}

export function requestIsToApiGateway(requestConfig) {
	let rUrl = requestConfig.url;
	if (requestConfig.baseURL) {
		rUrl = requestConfig.baseURL;
	}

	const reqUrl = new URL(rUrl);
	const apiGatewayUrl = new URL(config.apiGateway.BASEURL);

	return reqUrl.origin === apiGatewayUrl.origin;
}
