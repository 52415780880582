import React from 'react';
import _ from 'lodash';
import { Flex, Box } from '@chakra-ui/react';

const NodeCard = ({ node }) => {
	return (
		<Flex
			bg="#FFF"
			borderRadius="2px"
			overflow="hidden"
			borderTop="1px solid"
			borderRight="1px solid"
			borderBottom="1px solid"
			borderColor="light.border.gray.200"
			boxSizing="border-box"
			// pt='16px' pr='20px' pb='16px'
		>
			<Box bg="light.font.gray.200" width="4px" minWidth="4px" />
			<Box
				boxSizing="border-box"
				mt="12px"
				mr="24px"
				mb="12px"
				ml="24px"
				flex="1 1 auto"
				// bg='green'
				borderRadius="10px"
			>
				<Box textStyle="sans.sm" color="light.font.gray.400">
					{_.startCase(node.name)}
				</Box>
				<Box
					mt="4px"
					textStyle="sans.xs"
					color="light.font.gray.300"
					display="-webkit-box"
					css={{
						'-webkit-box-orient': 'vertical',
						'-webkit-line-clamp': '2',
					}}
					overflow="hidden"
					minHeight="45px"
				>
					{node.description ? node.description : 'No description'}
				</Box>
			</Box>
		</Flex>
	);
};

export default NodeCard;
