import { config } from '../../config';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

const parseRecipe = async ({ toParseItem, fromFormat, toFormat }) => {
	const APIRequestBody = {
		to_parse_item: toParseItem,
		from_format: fromFormat,
		to_format: toFormat,
	};

	const parseRecipeFn = APIFunction({
		BASEURL: config.pacEngine.baseUrl,
		PATH_SEARCH: APIRoutes.apiService.parseRecipe.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.parseRecipe.PATH_METHOD,
	});

	const response = await parseRecipeFn(APIRequestBody);
	return response;
};

export default parseRecipe;
