import React, { useMemo } from 'react';

import MayaFilledButton from '../../../../../../../../library/buttons/FilledButton';
import { Link } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import isElectron from 'is-electron';

const DashboardLink = ({ workspaceId, hasDashboard }) => {
	const dashboardLinkParams = useMemo(() => {
		const params = {
			url: '/store/module/618e6937b14a32dae59c1fb9',
			text: 'Install Dashboard Module',
			inNewTab: false,
		};
		if (hasDashboard) {
			params.url = `/workers/ui?id=${workspaceId}`;
			params.text = 'View Dashboard';
			params.inNewTab = true;
		}
		return params;
	}, [hasDashboard, workspaceId]);

	return (
		<Flex mt="8px">
			<Link
				to={{
					pathname: dashboardLinkParams.url,
				}}
				target={
					dashboardLinkParams.inNewTab
						? !isElectron()
							? '_blank'
							: 'dash'
						: ''
				}
				rel="noreferrer"
			>
				<MayaFilledButton text={dashboardLinkParams.text} />
			</Link>
		</Flex>
	);
};

export default DashboardLink;
