import React from 'react';
import { VscArrowSmallRight } from 'react-icons/vsc';
import Fuse from 'fuse.js';
import _ from 'lodash';
import SearchItem from './SearchItem';
import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';
import { Box } from '@chakra-ui/layout';

/**
 * Max number of visible placeholder suggestions(results)
 */
const MAX_VISIBLE_RESULTS = 5;

/**
 * Max number of total results
 */
const MAX_RESULTS = 15;

/**
 * Fn to get clipped subset of allItems for scrollable displaying
 * @param {any[]} allItems list of all items
 * @param {number} selectedIdx index of selected item
 * @param {number} maxResults max number of displayable results
 */
const getClippedItemsScrollable = (
	allItems,
	selectedIdx,
	maxResults = MAX_VISIBLE_RESULTS
) => {
	const itemsCopy = allItems.map((item, idx) => ({ item, idx }));
	if (itemsCopy.length <= maxResults) {
		return itemsCopy;
	} else {
		if (selectedIdx <= maxResults) return itemsCopy.slice(0, maxResults);
		else return itemsCopy.slice(selectedIdx - maxResults, selectedIdx);
	}
};

const CustomTypeSearch = ({
	placeholderText: text,
	setPlaceholderSearchItems,
	searchItems,
	selectedIndex,
}) => {
	const options = {
		// isCaseSensitive: false,
		includeScore: true,
		// shouldSort: true,
		// includeMatches: false,
		// findAllMatches: false,
		// minMatchCharLength: 1,
		// location: 0,
		threshold: 0.8, // the less the more accurate the match
		distance: 10,
		// useExtendedSearch: false,
		// ignoreLocation: false,
		// ignoreFieldNorm: false,
		keys: ['value', 'meta.subtext'],
	};

	const [filteredItems, setFilteredItems] = React.useState([]);

	/**
	 * This value will get selected by the placeholder, in this case it is email
	 * @param {} array
	 */
	const selectFunction = (itemsArray) => {
		return itemsArray.map((thing) => {
			return { show: thing.item.value, submit: thing.item.meta };
		});
	};

	var doneTypingInterval = 10; //time in ms, 5 second for example
	const typingTimer = React.useRef(null);

	const onTextChange = (searchText) => {
		axios({
			url: `https://suggestqueries.google.com/complete/search?client=chrome&q=${searchText}`,
			adapter: jsonpAdapter,
		})
			.then(function (response) {
				var suggests = response.data[1] || [];
				var out = [];

				suggests.map((m) => {
					out.push({ value: m, meta: 'a few moments ago' });
				});
				const fuse = new Fuse(out, options);
				if (
					searchText &&
					_.findIndex(searchItems, function (o) {
						return o.show === searchText;
					}) === -1
				) {
					var results = fuse.search(searchText);
					const searchItems = results.slice(0, MAX_RESULTS);
					setFilteredItems(searchItems);
					setPlaceholderSearchItems(selectFunction(searchItems));
				}
			})
			.catch(function (error) {
				console.error(error);
			});
	};

	React.useEffect(() => {
		clearTimeout(typingTimer.current);
		if (text) {
			typingTimer.current = setTimeout(() => {
				onTextChange(text);
			}, doneTypingInterval);
		}
	}, [text, doneTypingInterval]);

	/** Scrollbar height in % */
	const scrollbarHeight = (MAX_VISIBLE_RESULTS / filteredItems.length) * 100;
	/**
	 * Get scrollbar top margin based on the following mapping ratio:
	 * idx = 0 -> top = 0
	 * idx = items.length - 1 -> top = 100% - height
	 * PS: actual values subjected to tweaks
	 */
	/** Scrollbar top margin in % */
	const scrollbarTopMargin =
		(selectedIndex / filteredItems.length) * (100 - scrollbarHeight);

	return (
		<Box position="relative">
			{/* show scrollbar only if items are more than max visible items */}
			{filteredItems.length > MAX_VISIBLE_RESULTS ? (
				<Box
					position="absolute"
					left="0"
					bg="card_ui.purple"
					w="1px"
					h={`${scrollbarHeight}%`}
					top={`${scrollbarTopMargin}%`}
				/>
			) : null}
			{getClippedItemsScrollable(filteredItems, selectedIndex).map(
				({ item: current, idx: i }) => (
					<SearchItem
						selectedIndex={selectedIndex}
						key={i}
						i={i}
						searchItemMainText={current.item.value}
						searchItemSubText={current.item.meta.subtext || ''}
						// searchItemSubText={'Bruh'}
						reactIcon={<VscArrowSmallRight />}
					/>
				)
			)}
		</Box>
	);

	// return filteredItems.map((current, i) => {
	// 	return (
	// 		<SearchItem
	// 			selectedIndex={selectedIndex}
	// 			key={i}
	// 			i={i}
	// 			searchItemMainText={current.item.value}
	// 			searchItemSubText={current.item.meta.subtext || ''}
	// 			// searchItemSubText={'Bruh'}
	// 			reactIcon={<VscArrowSmallRight />}
	// 		/>
	// 	);
	// });
};

export default CustomTypeSearch;
