// Dependency imports

import isElectron from 'is-electron';
// import worker from 'workerize-loader!../../../src/maya-service-worker'; // eslint-disable-line import/no-webpack-loader-syntax

import APIFunction from '../../util/APIFetch/function';
import axios from 'axios';
import config from '../../config/config';

import APIRoutes from '../../util/APIRoutes';

// config
// import { config } from '../../config';

import { store } from '../../redux/store';

const CMS_API_ROOT = config.app.CMS_BACKEND;

const getStoreSkillPacks1 = async ({ slug, _id, categories, platforms }) => {
	var { user } = store.getState();
	const profileSlug = slug ? slug : user.info.id;
	let url_param = _id ? { profileSlug, _id } : { profileSlug };

	var getStoreSkillPacksFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.getStoreSkillPacks.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.getStoreSkillPacks.PATH_METHOD,
		URL_PARAM: url_param,
	});

	var getStoreSkillPacksResponse = await getStoreSkillPacksFn({
		categoryList: categories,
		platformList: platforms,
	});

	return getStoreSkillPacksResponse;
};

async function getStoreSkillPacks({
	slug,
	_id,
	platforms,
	search = '',
	apps = [],
	categories = [],
}) {
	let request = null;
	if (_id) {
		request = {
			method: 'GET',
			url: `${CMS_API_ROOT}/skillpacks/${_id}`,
		};
	} else {
		request = {
			method: 'post',
			url: `${CMS_API_ROOT}/skillpacks/filter`,
			data: {
				slug,
				_id,
				platforms,
			},
		};

		if (categories.length !== 0) {
			request.data.categories = categories.map((c) => c._id);
		}
		if (apps.length !== 0) {
			request.data.apps = apps.map((a) => a._id);
		}
		if (search) request.data.search = search;
	}

	try {
		const response = await axios(request);
		const skillpacks = response.data;
		return skillpacks;
	} catch (e) {
		console.error('Error fetching packlist:', e);
		return [];
	}
}

async function getStoreSkillPacks2({
	slug,
	_id,
	categories,
	platforms,
	search = '',
	apps = [],
}) {
	let request = null;

	if (_id) {
		request = {
			url: `${CMS_API_ROOT}/skillpacks/${_id}`,
		};
	} else {
		request = {
			url: `${CMS_API_ROOT}/skillpacks`,
			data: {
				slug,
				platforms,
			},
		};

		if (categories.length !== 0) request.categories = categories;
		if (apps.length !== 0) request.apps = apps;
		if (search) request.search = search;
	}

	try {
		const response = await axios(request);
		const skillpacks = response.data;
		return skillpacks;
	} catch (e) {
		console.error('Error fetching packlist:', e);
		return [];
	}
}

export default getStoreSkillPacks;
