import React, { useEffect, useState } from 'react';
import { Flex, Box, Spinner } from '@chakra-ui/react';
import acceptTeamInvite from '../../../../functions/profile/acceptTeamInvite';
import useQueryParams from '../../../../util/useQueryParams';

export const AcceptInvite = () => {
	const [loading, setLoading] = useState(false);
	const queryParams = useQueryParams();

	useEffect(() => {
		setLoading(true);
		const inviteId = queryParams.get('inviteId');
		acceptTeamInvite({ inviteId })
			.then((res) => {
				setLoading(false);
			})
			.catch((e) => {
				console.error('invite unable to accept', e);
				setLoading(false);
			});
	}, []);

	return (
		<Flex height="100%" justifyContent="center" alignItems="center">
			<Flex direction="column" alignItems="center">
				{loading ? (
					<Spinner size="xl" color="light.theme.purple.300" />
				) : null}
				<Box mt="24px" textStyle="sans.md" color="light.font.gray.400">
					{loading ? 'Accepting invite' : 'Invite accepted!'}
				</Box>
			</Flex>
		</Flex>
	);
};
