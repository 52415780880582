import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import {
	Flex,
	Box,
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	Img,
	Spinner,
	Center,
	Circle,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { FiPackage } from 'react-icons/fi';

import isElectron from 'is-electron';
import ConfigProfileFlow from './ConfigProfileFlow';
import { CloseIcon } from '@chakra-ui/icons';

const THUMBNAIL =
	'https://maya-public-assets.s3.ap-south-1.amazonaws.com/modules/notionlogo.png';

/**
 *
 * @param {import('./types').ModuleInstallState} param0
 */
const ModuleInstallProgress = ({
	moduleName,
	status,
	privateModule,
	version,
}) => {
	// console.log('bool pm', privateModule)
	const thumbnail = privateModule?.thumbnail;
	return (
		<Flex
			height="80px"
			width="100%"
			borderRadius="2px"
			bg="light.theme.gray.50"
			border="1px solid"
			borderColor="light.border.gray.100"
			// padding='12px 12px'
			paddingX="8px"
			pr="24px"
			alignItems="center"
			justifyContent="space-between"
		>
			<Flex alignItems="center">
				<Box
				// bg='green'
				// maxHeight='45px'
				// paddingX="10px"
				>
					{thumbnail && (
						<Img
							src={thumbnail}
							height="100%"
							maxHeight="30px"
							maxWidth="30px"
							// ml="3px"
							marginLeft="13.5px"
							marginRight="9px"
						/>
					)}
					{!thumbnail && (
						<Box marginLeft="13.5px" marginRight="9px">
							<FiPackage size="30px" color="#656565" />
						</Box>
					)}
				</Box>
				<Box ml="12px" mb="2px">
					<Box
						textStyle="sans.sm"
						color="light.font.gray.400"
						mb="1px"
						whiteSpace="nowrap"
						overflow="hidden"
						textOverflow="ellipsis"
						// bg='green'
						maxWidth="160px"
					>
						{moduleName}
					</Box>
					<Box
						fontSize="13px"
						fontWeight="500"
						color="light.font.gray.200"
					>
						<Flex alignItems="center">
							{status}
							{status !== 'INSTALLING' && (
								<Circle bg="#777575" size="1" ml="6px" />
							)}
							<Box as="span" ml="6px">
								v{version}
							</Box>
						</Flex>
					</Box>
				</Box>
			</Flex>
			<Flex alignItems="center">
				{status === 'INSTALLING' && <Spinner size="sm" />}
				{status === 'INSTALLED' && <CheckIcon />}
			</Flex>
		</Flex>
	);
};

/**
 *
 * @param {{ thumbnailUrl?: string }} param0
 */
const ModuleIcon = ({ thumbnailUrl }) => {
	if (thumbnailUrl) {
		return (
			<Flex justifyContent="start" alignItems="center">
				<Img
					src={thumbnailUrl}
					maxHeight="30px"
					maxWidth="30px"
					marginRight="16px"
					// borderLeft='1px solid'
					// borderColor='light.border.gray.300'
				/>
			</Flex>
		);
	} else {
		return (
			<Flex
				justifyContent="start"
				alignItems="center"
				// bg='green'
				marginRight="16px"
			>
				<FiPackage size="30px" color="#656565" />
			</Flex>
		);
	}
};

/**
 *
 * @param {{
 *  installState: import('./types').IntModuleInstallProps,
 *  isOpen: boolean,
 * onClose: () => void,
 * }} props
 */
const InterstitialModuleInstall = ({ installState, isOpen, onClose }) => {
	const [profileMap, setProfileMap] = useState({});
	const [configComplete, setConfigComplete] = useState(false);
	const [configIndex, setConfigIndex] = useState(0);

	const InstallProgressCards = Object.values(installState).map(
		(moduleProgress) => (
			<Box mb="12px">
				<ModuleInstallProgress
					moduleName={moduleProgress.moduleName}
					version={moduleProgress.version}
					status={moduleProgress.status}
					privateModule={moduleProgress?.privateModule}
				/>
			</Box>
		)
	);

	console.log('bru installState', installState);
	const profilesRequiringConfigs = Object.keys(installState).filter(
		(packageName) => installState[packageName].requiresConfig
	);
	console.log('profilesRequiringConfigsOne', profilesRequiringConfigs);

	function handleProfileSelect(modulePackageName, referenceId) {
		setProfileMap(
			update(profileMap, {
				[modulePackageName]: { $set: referenceId },
			})
		);
	}

	useEffect(() => console.log('poo profileMap', profileMap), [profileMap]);

	function onDone() {
		// return
		// This event will be caught by our hook, and thats how it'll know all profiles are configured.
		// Is this a good way to do it? I'm not sure. It's probably not. But hey, we got a demo to make.
		const event = new CustomEvent('Maya::ConfigProfilesSelected', {
			bubbles: true,
			detail: { profileMap },
		});

		document.dispatchEvent(event);
		setConfigComplete(true);
	}

	const showConfigs = profilesRequiringConfigs.length > 0 && !configComplete;
	const moduleVals = profilesRequiringConfigs.map(
		(key) => installState[key].privateModule
	);

	console.log('moduleValesOne', moduleVals);

	return (
		<>
			<Drawer isOpen={isOpen} onClose={onClose}>
				<DrawerContent
					paddingTop={isElectron() ? '45px' : undefined} // Electron app has a high z-index 45px-tall tab bar at the top
					bg="light.theme.yellow.200"
					borderLeft="1px solid"
					borderColor="light.border.gray.200"
					maxWidth="325px" // We trying to cover node-red's native sidebar.
				>
					<DrawerHeader
						paddingX="16px"
						// display="flex"
						justifyContent="space-between"
						color="#444"
					>
						<Box
							onClick={() => {
								onClose();
							}}
							color="font.gray.400"
							cursor="pointer"
							paddingBottom="0.5rem"
							marginBottom="0.2rem"
							fontSize="12px"
						>
							<CloseIcon />
						</Box>
						<Flex
							textStyle="sans.sm"
							mb="4px"
							mt="8px"
							alignItems="center"
							justifyContent="start"

							// pl="3px" // Visual alignment
						>
							{showConfigs && (
								<ModuleIcon
									thumbnailUrl={
										showConfigs
											? moduleVals[configIndex].thumbnail
											: null
									}
									// thumbnailUrl={null}
								/>
							)}
							{showConfigs
								? `Setup ${moduleVals[configIndex].name}`
								: `Installing dependencies...`}
						</Flex>
					</DrawerHeader>
					<DrawerBody paddingX="16px">
						{showConfigs && (
							<ConfigProfileFlow
								selectConfigProfile={handleProfileSelect}
								onDone={onDone}
								moduleVals={moduleVals}
								configIndex={configIndex}
								setConfigIndex={setConfigIndex}
							/>
						)}
						{!showConfigs ? InstallProgressCards : null}
					</DrawerBody>
					{showConfigs && (
						<DrawerFooter paddingX="16px">
							<Box
								textStyle="sans.xs"
								color="light.font.gray.200"
								borderTop="1px solid"
								borderColor="light.border.gray.200"
								pt="16px"
								// direction='column'
							>
								Choose an existing profile, or click
								<b> "+ Login and Create New" </b>
								to create a new one.
							</Box>
						</DrawerFooter>
					)}
				</DrawerContent>
			</Drawer>
		</>
	);
};

export default InterstitialModuleInstall;
