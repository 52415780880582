import React from 'react';
import { Box, Stack } from '@chakra-ui/layout';

const Container = ({ heading, subheading, children }) => {
	return (
		<Box
			paddingTop="1rem"
			paddingBottom="2rem"
			paddingX="1.2rem"
			display="flex"
			flexDirection="row"
			justifyContent="center"
			flexGrow="0"
			flexShrink="0"
			width="300px"
			bg={'white'}
			borderRadius="2px"
			border="1px solid"
			borderColor={'light.border.gray.200'}
			zIndex="50"
		>
			<Stack width="340px">
				<Box>
					<Box
						color={'light.theme.gray.500'}
						textStyle="serif.md"
						fontWeight="700"
					>
						{heading}
					</Box>
					<Box
						color={'light.theme.gray.400'}
						textStyle="sans.xs"
						// mb="4px"
					>
						{subheading}
					</Box>
				</Box>
				{children}
			</Stack>
		</Box>
	);
};

export default Container;
