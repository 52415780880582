import React, { useCallback, useEffect } from 'react';
import {
	Flex,
	Box,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	ModalCloseButton,
} from '@chakra-ui/react';
import ReactDOM from 'react-dom';
import SideBar from './SideBar';
import Viewport from './Viewport';
import WorkspaceHeader from './WorkspaceHeader';
// import EditorMenu from './EditorMenu/index';
import { useStore } from '../zustand';
import AppParent from '../../../AppParent';
import {
	offNodeRedNodeChange,
	offPublicModuleInstallComplete,
	offRemoteFlowsResolveConflictMerge,
	onNodeRedNodeChange,
	onNodeRedNodeTypeAdded,
	onPublicModuleInstallComplete,
	onRemoteFlowsResolveConflictMerge,
} from './functions/redEventListeners';
import useProfileSlug from '../../../hooks/useProfileSlug';
import analytics from '../../../util/Analytics';
import EditorControlBar from './EditorControlBar';
import { getConfigNodes } from './functions/NodeRed';
import cloneDeep from 'lodash/cloneDeep';
import { MayaFilledButton } from '@mayahq/ui';
import useSessionId from '../hooks/useSessionId';
import { parseMayaLangToReactFlow } from './functions/FlowMethods';
// import useAnalytics from '../../../util/Analytics/useAnalytics';

const PalletteComponent = ({ runtime, token }) => {
	var pallette = document.getElementById('red-ui-palette');
	if (pallette) {
		pallette.innerText = '';
		return ReactDOM.createPortal(
			<AppParent>
				<SideBar runtime={runtime} token={token} />
			</AppParent>,
			document.getElementById('red-ui-palette')
		);
	}
	return null;
};

const WorkspaceViewport = ({ brainId, token, viewportGridRef }) => {
	var chart = document.getElementById('red-ui-workspace');
	if (chart) {
		chart.innerText = '';
		return ReactDOM.createPortal(
			<AppParent>
				<Viewport
					brainId={brainId}
					token={token}
					viewportGridRef={viewportGridRef}
				/>
			</AppParent>,
			chart
		);
	}
	return null;
};

const WorkspaceHeaderComponent = ({ workspaceId }) => {
	var header = document.getElementById('red-ui-header');
	if (header) {
		header.innerText = '';
		header.setAttribute('style', null);
		return ReactDOM.createPortal(
			<AppParent>
				<WorkspaceHeader workspaceId={workspaceId} />
			</AppParent>,
			header
		);
	}
	return null;
};

const Workspace = ({ runtime, token, brainId, viewportGridRef }) => {
	const profileSlug = useProfileSlug();
	// const track = useAnalytics();
	const onEditorInit = useStore(
		useCallback((state) => state.onEditorInit, [])
	);
	const onImportFlowsResolveConflictMerge = useStore(
		useCallback((state) => state.onImportFlowsResolveConflictMerge, [])
	);
	const nodeRedNodeTypeAdded = useStore(
		useCallback((state) => state.nodeRedNodeTypeAdded, [])
	);
	const onNodeStatusUpdate = useStore(
		useCallback((state) => state.onNodeStatusUpdate, [])
	);
	const editorBlankoutError = useStore(
		useCallback((state) => state.editorBlankoutError, [])
	);
	const setEditorBlankoutError = useStore(
		useCallback((state) => state.setEditorBlankoutError, [])
	);

	const sessionId = useSessionId(brainId);

	// init editor
	useEffect(() => {
		try {
			onEditorInit({ brainId, sessionId, profileSlug, url: runtime, token });
			analytics.track('[Editor] Page Loaded', {
				status: 'success',
			});
		} catch (error) {
			console.error(error);
			analytics.track('[Editor] Page Load Error', {
				status: 'failed',
				error: error,
			});
		}
	}, [brainId, onEditorInit, profileSlug, runtime, token]);

	useEffect(() => {
		onRemoteFlowsResolveConflictMerge(onImportFlowsResolveConflictMerge);
		return () =>
			offRemoteFlowsResolveConflictMerge(onImportFlowsResolveConflictMerge);
	}, [onImportFlowsResolveConflictMerge, runtime, token]);

	useEffect(() => {
		onNodeRedNodeTypeAdded(nodeRedNodeTypeAdded);
		return () => onNodeRedNodeTypeAdded(nodeRedNodeTypeAdded);
	}, [nodeRedNodeTypeAdded]);

	// Node Status

	useEffect(() => {
		window.RED.comms.subscribe('status/#', function (topic, msg) {
			var parts = topic.split('/');
			var node = window.RED.nodes.node(parts[1]);
			if (node) {
				const message = { ...msg };
				// if (
				// 	message.hasOwnProperty('text') &&
				// 	message.text !== null &&
				// 	/^[a-zA-Z]/.test(message.text)
				// ) {
				// 	message.text = node._(message.text.toString(), {
				// 		defaultValue: message.text.toString(),
				// 	});
				// }
				console.log({ node, message });
				onNodeStatusUpdate({ id: node.id, msg: message });
			}
		});
		return () => {
			window.RED.comms.unsubscribe('status/#');
		};
	}, [onNodeStatusUpdate]);

	useEffect(() => {
		const editorContainer = document.getElementById('red-ui-editor');
		editorContainer.parentElement.style.display = 'flex';
		editorContainer.style.marginLeft = '54px';

		const paletteContainer = document.getElementById('red-ui-palette');
	}, []);

	// useEffect responsible for updating config nodes
	useEffect(() => {
		// Function which updates the entire config nodes in Zustand store
		// const nodeRedNodeChange = (node) => {
		// 	const tabId = node.z;
		// 	let configNodes = [...getConfigNodes()];
		// 	console.log(configNodes);
		// 	useStore.getState().updateConfigNodes(configNodes, tabId);
		// };

		// Function which updates a single config node in Zustand store
		const nodeRedNodeChange = (node) => {
			const tabId = node.z;
			if (
				node.x === undefined &&
				node.y === undefined &&
				node.type !== 'tab' &&
				node.type !== 'subflow' &&
				node.type !== 'group'
			) {
				const modifiedNode = cloneDeep(node);
				delete modifiedNode._;
				delete modifiedNode._def;
				delete modifiedNode._config;
				delete modifiedNode.infoEditor;
				delete modifiedNode.label;
				delete modifiedNode.users;
				useStore.getState().updateSingleConfigNode(modifiedNode, tabId);
			} else if (node.x && node.y && node.type !== 'group') {
				const rFlow = parseMayaLangToReactFlow([node]).nodes;
				const currentReactFlow = useStore
					.getState()
					.getReactFlowByTab({ tabId })
					.nodes.filter((n) => n.id !== node.id);
				const newReactFlow = [...currentReactFlow, ...rFlow];
				//@ts-ignore
				useStore.getState().setNodesToTab(newReactFlow, tabId);
			}
		};

		setTimeout(() => {
			const grid = document.querySelector('.react-flow');
			if (!grid) {
				setEditorBlankoutError(true);
				console.log('Editor did not load');
			} else {
				console.log('Editor loaded successfully');
			}
		}, 2000);

		onNodeRedNodeChange(nodeRedNodeChange);
		return () => offNodeRedNodeChange(nodeRedNodeChange);
	}, []);

	function handleSupportClick() {
		window.open('mailto:humans@mayalabs.io');
	}

	function handleRefreshClick() {
		window.location.reload();
	}

	return (
		<>
			<Modal
				isOpen={editorBlankoutError}
				onClose={() => null}
				isCentered
				size="md"
				// {...modalProps}
			>
				<ModalOverlay />
				<ModalContent borderRadius="2px">
					<ModalBody
						flex="1"
						minHeight="0"
						display="flex"
						flexDirection="column"
						p="0"
						borderRadius={'2px'}
						height="200px"
					>
						<Flex direction="column" flex="1" minHeight="0" p="4">
							<Box textStyle="serif.md" marginBottom="0.5rem">
								Error loading workspace.
							</Box>
							<Box
								fontWeight="medium"
								opacity="0.6"
								textStyle="sams.md"
								borderRadius="2px"
								mt="8px"
							>
								There was an error loading this workspace. Usually,
								refreshing this page fixes this. If it doesn't, please
								contact support.
							</Box>
						</Flex>
					</ModalBody>
					<ModalFooter>
						<MayaFilledButton
							text="Contact Support"
							onClick={handleSupportClick}
							colorScheme="gray"
							size="sm"
							showDotPattern={false}
							buttonProps={{
								mr: '8px',
							}}
						/>
						<MayaFilledButton
							showDotPattern
							text="Refresh"
							onClick={handleRefreshClick}
							colorScheme="purple"
							size="sm"
						/>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Flex ref={viewportGridRef} height="100%">
				<EditorControlBar workspaceId={brainId} />
				<WorkspaceHeaderComponent workspaceId={brainId} />
				<WorkspaceViewport
					brainId={brainId}
					token={token}
					viewportGridRef={viewportGridRef}
				/>
				<PalletteComponent runtime={runtime} token={token} />
			</Flex>
		</>
	);
};

export default React.memo(Workspace);
