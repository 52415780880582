import React, { useRef, useState } from 'react'
import {
    Flex, Box,
    Menu, MenuList, MenuItem, MenuItemOption, MenuGroup, MenuOptionGroup, MenuDivider, MenuButton,
    Button
} from '@chakra-ui/react'
import { 
    ContextMenu,
    ContextMenuItem
} from '../../../../../../library/ContextMenu'
import { useContextMenu } from 'react-contexify'
import { ChevronDownIcon } from '@chakra-ui/icons'

export const permLevels = {
    admin: 'ADMIN',
    canEdit: 'CAN_EDIT',
    canView: 'CAN_VIEW'
}

export const permLabels = {
    [permLevels.admin]: 'admin',
    [permLevels.canEdit]: 'can edit',
    [permLevels.canView]: 'can view'
}

const PermSelect = ({
    selectedPerm,
    onPermChange,
    showActions = false,
    onDelete = () => null
}) => {

    return (
            <Menu
                // width='100px'
                matchWidth
            >
                <MenuButton 
                    as={Button}
                    width='100%' 
                    height='100%'
                    rightIcon={<ChevronDownIcon />} 
                    textStyle='sans.sm'
                    borderRadius='2px'
                    size='sm'
                    _focus={{ outline: 'none' }}
                    // bg='light.theme.yellow.200'
                    bg='#efefef'
                    color='light.font.gray.400'
                    border='1px solid'
                    borderColor='light.border.gray.200'
                    _hover={{
                        filter: 'brightness(97%)' // TODO: Replace with a theme color instead
                    }}
                    _active={{
                        filter: 'brightness(97%)'
                    }}
                    transition='all 0.2s cubic-bezier(.08,.52,.52,1)'
                >
                    {permLabels[selectedPerm]}
                </MenuButton>
                <MenuList 
                    borderRadius='2px'
                >
                    {Object.values(permLevels).map(perm => (
                        <MenuItem
                            textStyle='sans.xs'
                            onClick={() => onPermChange(perm)}
                            // width='100px'
                        >
                            {permLabels[perm]}
                        </MenuItem>
                    ))}
                    {showActions ? (
                        <>
                            <MenuDivider />
                            <MenuItem
                                textStyle='sans.xs'
                                onClick={onDelete}
                            >
                                remove user
                            </MenuItem>
                        </>
                    ) : null}
                </MenuList>
            </Menu>
    )
}

export default PermSelect