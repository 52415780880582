import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import isElectron from 'is-electron';
import {
	useToast,
	Flex,
	Box,
	InputGroup,
	InputLeftElement,
	Input,
	Spinner,
} from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/react';
import {
	RepeatIcon,
	ChevronLeftIcon,
	WarningTwoIcon,
	CheckCircleIcon,
} from '@chakra-ui/icons';
import MayaFilledButton from '../../../../../library/buttons/FilledButton';
import authenticateConfiguration from '../../../../../functions/configuration/authenticateConfiguration';
import randomDefaultProfileName from '../../../../../util/RandomDefaults/newprofile';
import saveConfigProfile from '../../../../../functions/configuration/saveConfigProfile';
import getConfigProfiles from '../../../../../functions/configuration/getConfigProfiles';

import { config } from '../../../../../config';
import useProfileSlug from '../../../../../hooks/useProfileSlug';
import { useSelector } from 'react-redux';
import theme from '../../../../../library/theme';

const authStates = {
	INIT: 'init',
	AUTHENTICATING: 'authenticating',
	SAVING: 'saving',
	FAILED: 'failed',
	SUCCESS: 'success',
};

const StatusMessage = ({ icon, text, buttonLabel, onButtonClick }) => {
	return (
		<Box>
			<Flex
				// direction='column'
				alignItems="center"
				pl="8px"
			>
				<Flex
					justifyContent="center"
					// mt='8px'
				>
					{icon}
				</Flex>
				<Flex
					justifyContent="center"
					ml="16px"
					// textStyle='sans.sm'
					textStyle="sans.xs"
					color="light.font.gray.300"
				>
					{text}
				</Flex>
			</Flex>
			{buttonLabel && (
				<MayaFilledButton
					text={buttonLabel}
					showDotPattern={false}
					colorScheme="gray"
					buttonProps={{
						width: '120px',
						mt: '16px',
						ml: '1px',
					}}
					onClick={onButtonClick}
				/>
			)}
		</Box>
	);
};

const ConfigProfileEditor = ({
	module,
	configProfile = null,
	onExitEditor,
	mode = 'edit',
	onUpdateConfigProfile,
}) => {
	const localDevice = useSelector(
		(state) =>
			// @ts-ignore
			Object.values(state.devices.list.byId).find(
				(device) => device.platform !== 'cloud'
			)
		// state => state.devices
	);

	// console.log('imp localDevice', localDevice)

	const [loading, setLoading] = useState(false);
	const [authState, setAuthState] = useState(authStates.INIT);
	const slug = useProfileSlug();
	const [profile, setProfile] = useState({
		name: '',
		_id: '',
		referenceId: '',
		configType: '',
	});
	const [externalPopup, setExternalPopup] = useState(null);
	const toast = createStandaloneToast({ theme: theme });

	useEffect(() => {
		setAuthState(authStates.INIT);
		if (mode === 'edit') {
			setProfile(configProfile);
		}
		// @ts-ignore
		else
			setProfile({
				_id: mode === 'edit' ? configProfile._id : '',
				name:
					mode === 'edit'
						? configProfile.name
						: randomDefaultProfileName(module.configurationType.resource),
				referenceId: mode === 'edit' ? configProfile.referenceId : '',
			});
	}, []);

	useEffect(() => {
		if (!externalPopup) {
			return;
		}

		const listener = function (event) {
			console.log('imp', event.origin);
			console.log('imp data', event.data);
			console.log();
			const eventOrigin = new URL(event.origin).origin;
			const apiGatewayOrigin = new URL(config.apiServer.CLOUDBASEURL).origin;

			if (eventOrigin === apiGatewayOrigin) {
				console.log('imp message', event);
				// if (event.origin === 'http://localhost:5000') {
				if (event.data === 'closing') {
					setAuthState(authStates.SAVING);
					// @ts-ignore
					getConfigProfiles({
						slug,
						moduleId: module._id,
						moduleName: module.name,
					})
						.then((res) => {
							console.log('imp saving profile', res);
							const profile = res[0];
							setProfile(profile);
							setAuthState(authStates.SUCCESS);
							setLoading(false);

							setTimeout(() => {
								onUpdateConfigProfile(profile._id, {
									name: profile.name,
									...profile,
								});
								onExitEditor();
							}, 1000);
						})
						.catch((e) => {
							console.error(e);
							setAuthState(authStates.FAILED);
							setLoading(false);
						})
						.finally(() => {
							setExternalPopup(null);
						});
				}
			} else {
				// something from an unknown domain, let's ignore it
				// console.log('imp error 4', event)
				// setAuthState(authStates.FAILED);
				// setLoading(false);
				return;
			}
		};

		window.addEventListener('message', listener);
		return () => {
			window.removeEventListener('message', listener);
		};
	}, [externalPopup, slug]);

	function handleNameChange(name) {
		setProfile(
			update(profile, {
				name: { $set: name },
			})
		);
	}

	function handleConfigEdit() {
		setLoading(true);
		setAuthState(authStates.AUTHENTICATING);
		const formData =
			mode === 'add'
				? {
						name: profile.name,
						mode: mode,
				  }
				: {
						name: profile.name,
						mode: mode,
						referenceId: profile.referenceId,
						configType: profile.configType,
				  };
		const { configurationType } = module;
		authenticateConfiguration(
			{ configurationType, formData },
			module._id
		).then(async (res) => {
			if (res.error) {
				setAuthState(authStates.FAILED);
				setLoading(false);
				toast({
					title: 'Error occurred',
					description:
						'Something went wrong while authenticating profile.',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
			} else {
				// This is for browser automation
				if (configurationType.type === 'maya-auth') {
					setAuthState(authStates.SAVING);
					console.log('saving profile');
					let savedProfileResponse = await saveConfigProfile({
						configurationType,
						formData: { ...formData, ...res.results.tokens },
						slug,
						moduleId: module._id,
						modulePackageName: module.packageName,
						mode,
						referenceId: profile.referenceId,
						device: localDevice,
					});
					// console.log('savedProfileResponse', savedProfileResponse)
					console.log('response', savedProfileResponse);
					if (savedProfileResponse.status === 200) {
						setProfile(
							update(profile, {
								referenceId: {
									$set: savedProfileResponse.data.results.referenceId,
								},
							})
						);
						setAuthState(authStates.SUCCESS);
						setLoading(false);
					} else {
						setAuthState(authStates.FAILED);
						setLoading(false);
					}
				}
				// This is for API keys and all
				if (configurationType.type === 'app-oauth') {
					setAuthState(authStates.SAVING);
					let savedProfileResponse = await saveConfigProfile({
						configurationType,
						formData: { ...formData },
						slug,
						moduleId: module._id,
						modulePackageName: module.packageName,
						mode,
						referenceId: profile.referenceId,
						device: localDevice,
					});
					if (savedProfileResponse.status === 200) {
						setAuthState(authStates.SUCCESS);
						setLoading(false);
					} else {
						setAuthState(authStates.FAILED);
						setLoading(false);
					}
				}
				// This is for oauth like spotify and google
				if (configurationType.type === 'user-oauth') {
					const width = 800;
					const height = 900;
					const left = window.screenX + (window.outerWidth - width) / 2;
					const top = window.screenY + (window.outerHeight - height) / 2.5;
					const url = res.results.moduleAuthUrl;
					let popup;
					// frameName added for electron based oauth to allow opening popup in electron browser window
					if (isElectron()) {
						popup = window.open(
							url,
							'auth',
							`width=${width},height=${height},left=${left},top=${top},frameName=auth`
						);
					} else {
						popup = window.open(
							url,
							'_blank',
							`width=${width},height=${height},left=${left},top=${top}`
						);
					}
					console.log('imp popup', popup);
					setExternalPopup(popup);
				}
				// }
			}
		});
	}

	let comp = null;
	if (authState === authStates.INIT) {
		comp = (
			<Box>
				<Flex
					textStyle="sans.xs"
					alignItems="center"
					color="#444444"
					onClick={onExitEditor}
					cursor="pointer"
					_hover={{
						textDecoration: 'underline',
					}}
					width="70px"
				>
					<ChevronLeftIcon w={5} h={5} />
					BACK
				</Flex>
				<InputGroup
					bg="#FFFFFF"
					borderRadius="2px"
					mt="12px"
					textStyle="sans.sm"
					color="light.font.gray.300"
				>
					<InputLeftElement>
						<RepeatIcon />
					</InputLeftElement>
					<Input
						value={profile.name}
						onChange={(e) => handleNameChange(e.target.value)}
						borderRadius="2px"
					/>
				</InputGroup>
				<MayaFilledButton
					text="Continue"
					showDotPattern={false}
					colorScheme="gray"
					buttonProps={{
						width: '120px',
						mt: '16px',
						ml: '1px',
					}}
					onClick={handleConfigEdit}
				/>
			</Box>
		);
	} else if (authState === authStates.AUTHENTICATING) {
		let icon, text, buttonLabel, buttonClickFunction;
		icon = <Spinner color="light.theme.purple.300" size="sm" />;
		text = (
			<Box>
				<span>Please authenticate this module to continue.</span>
			</Box>
		);

		comp = (
			<Box>
				<Flex
					textStyle="sans.xs"
					alignItems="center"
					color="#444444"
					onClick={() => {
						if (externalPopup) {
							externalPopup.close();
						}
						setAuthState(authStates.INIT);
					}}
					cursor="pointer"
					_hover={{
						textDecoration: 'underline',
					}}
					width="70px"
				>
					<ChevronLeftIcon w={5} h={5} />
					BACK
				</Flex>
				<Box mt="8px">
					<StatusMessage
						icon={icon}
						text={text}
						buttonLabel={buttonLabel}
						onButtonClick={buttonClickFunction}
					/>
				</Box>
			</Box>
		);
	} else {
		let icon, text, buttonLabel, buttonClickFunction;
		const spinner = <Spinner color="light.theme.purple.300" size="sm" />;

		if (authState === authStates.SAVING) {
			icon = spinner;
			text = 'Saving this profile';
		} else if (authState === authStates.FAILED) {
			icon = (
				<WarningTwoIcon color="light.theme.purple.300" w={3.5} h={3.5} />
			);
			text = 'Something went wrong. Please try again';
			buttonLabel = 'Continue';
			buttonClickFunction = onExitEditor;
		} else if (authState === authStates.SUCCESS) {
			icon = (
				<CheckCircleIcon color="light.theme.purple.300" w={3.5} h={3.5} />
			);
			text =
				mode === 'edit'
					? 'Profile successfully saved'
					: 'Profile successfully created';
			// buttonLabel = 'Continue';
			// buttonClickFunction = (e) => {
			// 	onUpdateConfigProfile(profile._id, {
			// 		...profile,
			// 		name: profile.name,
			// 	});
			// 	onExitEditor();
			// };
		}

		comp = (
			<StatusMessage
				icon={icon}
				text={text}
				buttonLabel={buttonLabel}
				onButtonClick={buttonClickFunction}
			/>
		);
	}

	return comp;
};

export default ConfigProfileEditor;
