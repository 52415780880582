import React, { useCallback, useEffect, useState } from 'react';
import {
	Box,
	Stack,
	Input,
	InputGroup,
	InputRightElement,
	Button,
	Alert,
	AlertDescription,
	AlertIcon,
	useColorMode,
	useToast,
	Link,
	CloseButton,
	CircularProgress,
	Text,
	Icon,
	Tooltip,
} from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/react';
import LoginWithGoogleButton from './LoginWithGoogle';

import { Link as ReactLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { ArrowForwardIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import isElectron from 'is-electron';
// API
import APIFetch from '../../../../util/APIFetch';
import APIFetchFn from '../../../../util/APIFetch/function';
import LoginAPI from './LoginAPI';
//Redux
import { connect } from 'react-redux';
import { setUser, setStatus, setLoggedIn } from '../../../../redux/actions';
// Config
import { config } from '../../../../config';
import theme from '../../../../library/theme';
//History
import History, { HistoryWithSlug } from '../../../../util/History';
//Utils
import KeyEnum from '../../../../util/KeyEnum';
// Controllers
import LoginController from './LoginController';
import BrowserLogin from './BrowserLogin';
import OrDivider from './OrDivider';
import AppProtocol from '../../../../util/AppProtocol';
import ElectronLoginWithGoogle from './LoginWithGoogle/ElectronLoginWithGoogle';
//Components
import MayaFilledButton from '../../../../library/buttons/FilledButton';
import MayaInput from '../../../../library/input';
import ActionBtn from '../../../../library/buttons/ActionBtn';
import Container from '../Container';
import useQueryParams from '../../../../util/useQueryParams';
import { entryModes, FlowPreview } from '../FlowPreview/FlowPreview';
import { store } from '../../../../redux/store';
import analytics from '../../../../util/Analytics';
import { setAuthTokens } from '../../../../redux/actions/user';

const PasswordInput = ({ name, onChange, onKeyDown }) => {
	const [show, setShow] = useState(false);

	const handleClick = () => setShow(!show);

	return (
		<InputGroup size="md" fontFamily="body">
			<MayaInput
				name={name}
				type={show ? 'text' : 'password'}
				onChange={onChange}
				placeholder="Enter password"
				onKeyDown={onKeyDown}
			/>
			<InputRightElement width="3rem">
				<Box
					as={show ? MdVisibilityOff : MdVisibility}
					size="25px"
					color="light.theme.gray.300"
					onClick={handleClick}
				/>
			</InputRightElement>
		</InputGroup>
	);
};

/**
 * Login Card component
 * @param {*} param0
 * @returns
 */
const LoginCard = ({ setUser, setStatus, setAuthTokens }) => {
	const toast = createStandaloneToast({ theme: theme });
	const [formData, setFormData] = useState({});
	const [formAlert, setFormAlert] = useState({ type: null });
	const [isLoggingIn, setIsLoggingIn] = useState(false);
	const [isBrowserLoggingIn, setIsBrowserLoggingIn] = useState(false);
	const queryParams = useQueryParams();
	/** For restoring the page the user was trying to access earlier, its passed via `location.state` */
	const [restoreLocation, setRestoreLocation] = useState(
		// @ts-ignore
		History.location.state?.restoreLocation
	);
	/** Auto Login behavior config */
	const [autoLoginConfig, setAutoLoginConfig] = useState({
		isLoading: false,
		loadingText: '',
	});
	const [loginProvider, setLoginProvider] = useState(
		/**
		 * @returns {'emailPassword' | 'google.com' | 'unset' }
		 */
		() => 'unset'
	);
	// Setup the API Fetch utility for the Login View.
	// console.log(formData);
	// @ts-ignore
	const [{ fetchResults: fusionAuthLogin }] = APIFetch({
		BASEURL: config.fusionAuth.BASEURL,
		PATH_SEARCH: LoginAPI.fusionAuth.getLogin.PATH_SEARCH,
		PATH_METHOD: LoginAPI.fusionAuth.getLogin.PATH_METHOD,
	});

	const [showVerify, setShowVerify] = useState(false);
	const [verified, setVerified] = useState(false);
	let query = new URLSearchParams(useLocation().search);
	var verificationId = query.get('verificationId');
	var tenantId = query.get('tenantId');

	React.useEffect(() => {
		if (verificationId && tenantId) {
			// @ts-ignore
			LoginController.verifyEmail({ verificationId, tenantId })
				.then((res) => {
					if (res.error) {
						setShowVerify(true);
						setVerified(false);
						toast({
							title: 'Unable to verify email',
							description: 'Email verification failed.',
							status: 'error',
							duration: 3000,
							isClosable: true,
						});
					} else {
						if (res.verifiedNow) {
							setShowVerify(true);
							setVerified(true);
						} else {
							setShowVerify(true);
							setVerified(true);
							toast({
								title: 'Email already verified',
								description:
									'Email is already verified. Proceed to login',
								status: 'success',
								duration: 3000,
								isClosable: true,
							});
						}
					}
				})
				.catch((err) => {
					if (err.errorObject.status === 404) {
						setShowVerify(true);
						setVerified(true);
						toast({
							title: 'Email already verified',
							description: 'Email is already verified. Proceed to login',
							status: 'success',
							duration: 3000,
							isClosable: true,
						});
					} else {
						setShowVerify(true);
						setVerified(false);
						toast({
							title: 'Unable to verify email',
							description: 'Email verification failed.',
							status: 'error',
							duration: 3000,
							isClosable: true,
						});
					}
				});
		}
	}, [tenantId, toast, verificationId]);
	/** for redirect to desktop app */
	const desktopRedirect = JSON.parse(query.get('desktopRedirect'));
	const { colorMode } = useColorMode();

	/**
	 * Handle input changes
	 *
	 * Update the formData object to hold the newest value for an input
	 * when the value is changed. This is done so we can have a single source
	 * of truth for the inputs.
	 *
	 * @param {object} event Event object that executed the function.
	 */
	const handleInputChange = ({ target }) => {
		// Update state with new value for the input.
		setFormData({
			...formData,
			[target.name]: target.value,
		});
	};

	const handleLogin = async (e) => {
		if (e) {
			e.preventDefault();
		}
		try {
			// @ts-ignore
			formData.applicationId = config.fusionAuth.APPLICATION_ID;
			setIsLoggingIn(true);
			const response = await fusionAuthLogin(formData);

			if (response.status !== 200) {
				setIsLoggingIn(false);
				toast({
					title: 'Login Unsuccessful',
					description: 'You may have entered wrong credentials. Try again',
					status: 'warning',
					duration: 3000,
					isClosable: true,
				});
			} else {
				const { token, refreshToken, user } = response.data;
				store.dispatch(setLoggedIn(true));

				analytics.identify(user.id);
				analytics.setUserDetails(user.email, user.id);

				setAuthTokens({
					accessToken: token,
					refreshToken,
					lastUpdated: Date.now(),
				});
				setUser(user);
				setLoggedIn(true);
				setIsLoggingIn(false);
				History.push({
					pathname: restoreLocation ? restoreLocation : '/workers',
				});
			}
		} catch (e) {
			console.error('There was an error logging in');
			setIsLoggingIn(false);
			toast({
				title: 'Error logging in',
				description:
					'Please try again. If error persist please contant humans@mayalabs.io',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	const handleOnKeyDown = (e) => {
		const { keyCode } = e;
		if (keyCode === KeyEnum.ENTER) {
			handleLogin(e);
		}
	};

	const [loginMode, setLoginMode] = React.useState('');
	const headings = {
		[entryModes.NORMAL]: 'Welcome Back.',
		[entryModes.INSTALL_FROM_QUICK_SHARE]: 'Install in minutes.',
	};
	const subheadings = {
		[entryModes.NORMAL]:
			'Build, run, view and modify your automated tasks in one place.',
		[entryModes.INSTALL_FROM_QUICK_SHARE]:
			'Login to configure & install this across the Maya desktop or cloud app in just a few clicks.',
	};

	const mode = queryParams.get('mode') || entryModes.NORMAL;

	return (
		<Box>
			{showVerify && (
				<Alert
					status={verified ? 'success' : 'error'}
					size="sm"
					marginBottom="1rem"
					borderRadius="0.2rem"
				>
					<AlertIcon />
					<AlertDescription>
						{verified
							? 'Email verified! Now log in.'
							: 'Could not verify email.'}
					</AlertDescription>
				</Alert>
			)}
			<Container heading={headings[mode]} subheading={subheadings[mode]}>
				<FlowPreview setInLocalStorage={false} />
				{autoLoginConfig.isLoading ? (
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						minWidth="44"
						minHeight="44"
					>
						<CircularProgress
							isIndeterminate
							color="light.theme.purple.400"
							size="2.5rem"
							thickness="6px"
						/>
						{autoLoginConfig.loadingText ? (
							<Text
								textStyle="sans.sm"
								maxWidth="10rem"
								textAlign="center"
								marginTop="40px"
							>
								{autoLoginConfig.loadingText}
							</Text>
						) : null}
					</Box>
				) : (
					<Stack>
						{loginMode === 'email' && (
							<Stack>
								<ActionBtn
									text="BACK"
									icon={<ChevronLeftIcon />}
									onClick={() => {
										setLoginMode('');
									}}
								/>
								<InputGroup size="md">
									<MayaInput
										onChange={handleInputChange}
										name="loginId"
										placeholder="Enter email"
										onKeyDown={handleOnKeyDown}
									/>
									{/* <Input
								name="loginId"
								placeholder="Enter email"
								borderColor={'light.border.gray.200'}
								onChange={handleInputChange}
								focusBorderColor="purple.500"
								_placeholder={{ color: inputFontColor[colorMode] }}
								bg={'white'}
								color={'light.font.gray.400'}
								onKeyDown={handleOnKeyDown}
								size="md"
								width="14rem"
								textStyle="sans.md"
								borderRadius="2px"
							/> */}
								</InputGroup>
								<PasswordInput
									name="password"
									onChange={handleInputChange}
									onKeyDown={handleOnKeyDown}
								/>
								<MayaFilledButton
									size="sm"
									onClick={handleLogin}
									isLoading={isLoggingIn}
									text={'Login'}
									rightIcon={<ArrowForwardIcon />}
								/>
								<Box
									marginTop="20px"
									textStyle="sans.xs"
									textAlign="center"
									color="light.font.gray.400"
								>
									<Link to="/forgot" as={ReactLink}>
										Forgot password?
									</Link>
								</Box>
							</Stack>
						)}

						{/* <OrDivider /> */}
						{loginMode === '' && (
							<Stack>
								<MayaFilledButton
									size="sm"
									onClick={() => {
										setLoginMode('email');
									}}
									isLoading={isLoggingIn}
									text={'Login with Email'}
									rightIcon={<ArrowForwardIcon />}
								/>
							</Stack>
						)}
						<OrDivider />
						<LoginWithGoogleButton
							onClick={() => setLoginProvider('google.com')}
						/>
						{isElectron() && <OrDivider />}
						{isElectron() ? (
							<BrowserLogin
								onClick={() => {
									setLoginProvider('emailPassword');
									setIsBrowserLoggingIn(true);
								}}
								onTimeout={() => setIsBrowserLoggingIn(false)}
								isLoading={
									loginProvider === 'emailPassword' &&
									isBrowserLoggingIn
								}
							/>
						) : null}
					</Stack>
				)}
			</Container>
		</Box>
	);
};

// Export the Login View.
export default connect(null, { setUser, setStatus, setAuthTokens })(LoginCard);
