import create from 'zustand';
import { persist } from 'zustand/middleware';
import produce, { current } from 'immer';
import _ from 'lodash';

// Log every time state is changed
const log = (config) => (set, get, api) =>
	config(
		(args) => {
			console.log('Zustand  applying : useTabStore');
			set(args);
			console.log('Zustand  new state : useTabStore', get());
		},
		get,
		api
	);

export const useTabStore = create(
	persist(
		log((set, get) => ({
			// STORE
			selected: 'dashboard',
			list: {
				byId: {
					dashboard: { typeOf: 'dash', path: '/' },
				},
				allIds: ['dashboard'],
			},

			addTab: (workspaceId, name) => {
				set(
					produce(
						/**
						 * @param { import('./types').TabsState} draft
						 * @returns
						 */
						(draft) => {
							const tabObject = {
								typeOf: 'runtime',
								path: `/edit?id=${workspaceId}`,
								name: name,
								brainId: workspaceId,
							};
							if (!_.includes(draft.list.allIds, workspaceId)) {
								draft.list.byId[workspaceId] = tabObject;
								draft.list.allIds.push(tabObject.brainId);
							}
							draft.selected = tabObject.brainId;
						}
					)
				);
			},

			closeTab: (workspaceId) => {
				set(
					produce(
						/**
						 * @param { import('./types').TabsState} draft
						 * @returns
						 */
						(draft) => {
							const index = draft.list.allIds.findIndex(
								(id) => id === workspaceId
							);
							if (index !== -1) {
								draft.selected = 'dashboard';
								draft.list.allIds.splice(index, 1);
							}
						}
					)
				);
			},
			switchTab: (value) => {
				set(
					produce(
						/**
						 * @param { import('./types').TabsState} draft
						 * @returns
						 */
						(draft) => {
							draft.selected = value;
						}
					)
				);
			},
			clearTabs: () => {
				set(
					produce(
						/**
						 * @param { import('./types').TabsState} draft
						 * @returns
						 */
						(draft) => {
							draft.list.byId = {
								dashboard: { typeOf: 'dash', path: '/' },
							};
							draft.list.allIds = ['dashboard'];
						}
					)
				);
			},
		})),
		{
			name: 'tab-persist-storage', // unique name
			getStorage: () => sessionStorage, // (optional) by default, 'localStorage' is used
		}
	)
);

// // Setup initial state with an tab info object.
// const initialState = {
// 	selected: 'dashboard',
// 	list: {
// 		byId: {
// 			dashboard: { typeOf: 'dash', path: '/' },
// 		},
// 		allIds: ['dashboard'],
// 	},
// };

// // Export the Tab Reducer.
// export default (state = initialState, action) => {
// 	switch (action.type) {
// 		case actionTypes.ADD_TAB: {
// 			const { info } = action.payload;
// 			if (!_.includes(dotProp.get(state, `list.allIds`), info.brainId)) {
// 				state = dotProp.set(state, `list.byId.${info.brainId}`, info);
// 				state = dotProp.set(state, `list.allIds`, (list) => [
// 					...list,
// 					info.brainId,
// 				]);
// 			}
// 			state = dotProp.set(state, `selected`, info.brainId);
// 			return state;
// 		}
// 		case actionTypes.SWITCH_TAB: {
// 			const { info } = action.payload;
// 			state = dotProp.set(state, `selected`, info);
// 			return state;
// 		}
// 		case actionTypes.CLOSE_TAB: {
// 			const { info } = action.payload;
// 			let index = _.indexOf(dotProp.get(state, 'list.allIds'), info);
// 			if (index !== -1) {
// 				state = dotProp.set(state, `selected`, 'dashboard');
// 				state = dotProp.delete(state, `list.allIds.${index}`);
// 				state = dotProp.delete(state, `list.byId.${info}`);
// 			}

// 			return state;
// 		}
// 		case actionTypes.CLEAR_TABS: {
// 			state = dotProp.set(state, `list.byId`, {
// 				dashboard: { typeOf: 'dash', path: '/' },
// 			});
// 			state = dotProp.set(state, `list.allIds`, ['dashboard']);

// 			return state;
// 		}
// 		default:
// 			return state;
// 	}
// };
