import { GetState, SetState, State, StateCreator, StoreApi } from 'zustand';

/**
 * logger zustand middleware
 * @param config
 * @returns
 */
export const logger =
	<
		T extends State,
		CustomSetState extends SetState<T>,
		CustomGetState extends GetState<T>,
		CustomStoreApi extends StoreApi<T>
	>(
		config: StateCreator<
			T,
			(partial: ((draft: T) => void) | T) => void,
			CustomGetState,
			CustomStoreApi
		>
	): StateCreator<T, CustomSetState, CustomGetState, CustomStoreApi> =>
	(set, get, api) =>
		config(
			(args) => {
				// console.log('Zustand  applying');
				set(args);
				// console.log('Zustand  new state', get());
			},
			get,
			api
		);
