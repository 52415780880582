import React from 'react';
import { Box } from '@chakra-ui/react';
import TopBar from './TopBar';
import Body from './Body';
import isElectron from 'is-electron';

/**
 * Background
 *
 * This is the main warm white + white top bar section which
 * serves as the background for the dashboard and all other steppers.
 *
 * Reference :
 * https://www.figma.com/file/KAF1ostHf3VBeZsctGl3ZA/MayaDesign?node-id=2830%3A31260
 * @param {import("./types/background").Background} props
 * @returns
 */
const Background = ({
	children,
	topBarOption,
	topBarStepperHeading,
	stepCount,
	currentStep,
	backBtnClick,
	closeBtnClick,
}) => {
	return (
		<Box
			height="100%"
			width="100vw"
			color="black"
			textStyle="serif.xs"
			bg="light.theme.yellow.200"
			flex="1"
			minHeight="0"
			display="flex"
			flexDirection="column"
		>
			<TopBar
				topBarStepperHeading={topBarStepperHeading}
				topBarOption={topBarOption}
				stepCount={stepCount}
				currentStep={currentStep}
			/>
			<Body
				bodyContent={children}
				backBtnClick={backBtnClick}
				closeBtnClick={closeBtnClick}
			/>
		</Box>
	);
};

export default Background;
