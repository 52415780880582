import React, { useState, useCallback, useEffect, useRef } from 'react';
import shallow from 'zustand/shallow';
import {
	Flex,
	Box,
	Tooltip,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	Switch,
	createStandaloneToast,
	useMediaQuery,
} from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
import EditorMenu from './EditorMenu';
import { FiFeather, FiSettings } from 'react-icons/fi';
import { BsCommand } from 'react-icons/bs';
import { useStore } from '../zustand';
import {
	IoDocumentTextOutline,
	IoExtensionPuzzleOutline,
	IoArrowDownOutline,
} from 'react-icons/io5';
import { VscExtensions, VscGraph } from 'react-icons/vsc';
import { AiOutlineBug, AiOutlineLayout } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import useProfileSlug from '../../../hooks/useProfileSlug';
import { updateBrain as updateBrainInRedux } from '../../../redux/actions';
import updateBrain from '../../../functions/brain/updateBrain';
import theme from '../../../library/theme';
import {
	offNodeRedDebugMessage,
	offNodeRedNodeError,
	offNodeRedNodeWarning,
	onNodeRedDebugMessage,
	onNodeRedNodeChange,
	onNodeRedNodeError,
	onNodeRedNodeWarning,
} from './functions/redEventListeners';
import { BsCircleFill } from 'react-icons/bs';
import IssuesButton from './IssuesButton';
import useSelectedProfile from '../../../hooks/useSelectedProfile';

const toast = createStandaloneToast({ theme: theme });

export const ControlButton = ({
	children,
	toggled = false,
	tooltipMessage = '',
	onClick = () => null,
}) => {
	return (
		<Tooltip
			label={tooltipMessage}
			placement="right"
			// bg='light.theme.purple.300'
			bg="light.theme.gray.500"
			isDisabled={tooltipMessage.length === 0}
			openDelay={500}
			position="relative"
		>
			<Flex
				position="relative"
				alignItems="center"
				justifyContent="center"
				borderRadius="4px"
				width="40px"
				height="40px"
				bg={toggled ? '#DFDDDC' : 'transparent'}
				mb="18px"
				cursor="pointer"
				color="light.theme.gray.500"
				onClick={onClick}
				_hover={{
					backgroundColor: toggled ? '#DFDDDC' : '#E9E7E5',
				}}
			>
				{children}
			</Flex>
		</Tooltip>
	);
};

const EditorControlBar = ({ workspaceId }) => {
	const selectedProfile = useSelectedProfile()

	const synthesisSidebarVisible = useStore(
		(state) => state.synthesisSidebarVisible
	);
	const nodePaletteIsOpen = useStore((state) => state.nodePaletteIsOpen);
	const dashboardIsOpen = useStore((state) => state.dashboardIsOpen);
	const debugPanelOpen = useStore((state) => state.debugPanelOpen);
	const infoPanelOpen = useStore((state) => state.infoPanelOpen);
	const moduleSidebarIsOpen = useStore((state) => state.moduleSidebarIsOpen);
	const instructBarIsOpen = useStore((state) => state.instructBarIsOpen);
	const issuesSidebarIsOpen = useStore((state) => state.issuesSidebarIsOpen);
	const toggleNodePalette = useStore(
		(state) => state.toggleNodePalette,
		shallow
	);
	const toggleSynthSidebar = useStore(
		(state) => state.toggleSynthSidebar,
		shallow
	);
	const toggleDashboard = useStore((state) => state.toggleDashboard, shallow);
	const toggleDebugPanel = useStore(
		(state) => state.toggleDebugPanel,
		shallow
	);
	const toggleInfoPanel = useStore((state) => state.toggleInfoPanel, shallow);
	const toggleModuleSidebar = useStore(
		(state) => state.toggleModuleSidebar,
		shallow
	);
	const toggleInstructBar = useStore(
		(state) => state.toggleInstructBar,
		shallow
	);
	const toggleIssuesSidebar = useStore(
		(state) => state.toggleIssuesSidebar,
		shallow
	);

	// const {
	// 	// rightSidebarIsOpen,
	// 	// nodePaletteIsOpen,
	// 	// dashboardIsOpen,
	// 	// debugPanelOpen,
	// 	// infoPanelOpen,
	// 	// moduleSidebarIsOpen,
	// 	// instructBarIsOpen,
	// 	// dashboardOpened,
	// 	// issuesSidebarIsOpen,
	// 	// toggleNodePalette,
	// 	toggleRightSidebar,
	// 	// toggleSynthSidebar,
	// 	// toggleDashboard,
	// 	// toggleDebugPanel,
	// 	// toggleInfoPanel,
	// 	// toggleModuleSidebar,
	// 	// toggleInstructBar,
	// 	// toggleIssuesSidebar,
	// 	// dashboardNodes,
	// } = useStore(
	// 	state => state, shallow
	// );

	const profileSlug = useProfileSlug();
	const currentBrain = useSelector(
		/**
		 *
		 * @param {{brains: import('../../../redux/reducers/types/brains').Brains}} state
		 * @returns
		 */
		(state) => state.brains.byId[workspaceId]
	);
	const dispatch = useDispatch();
	const [debugLogHasErrorMessage, setDebugLogHasErrorMessage] =
		useState(false);
	const [debugLogHasWarningMessage, setDebugLogHasWarningMessage] =
		useState(false);
	const [debugLogCounter, _setDebugLogCounter] = useState(0);
	const ref = useRef(0);

	const setDebugLogCounter = useCallback((x) => {
		ref.current = x;
		_setDebugLogCounter(x);
	}, []);

	function handleAutoDeploySwitch(shouldAutoDeploy) {
		const originalValue = currentBrain.editorAutoDeploy;
		console.log('yoo sha', shouldAutoDeploy);

		// Assuming operation will succeed
		dispatch(
			updateBrainInRedux({
				...currentBrain,
				editorAutoDeploy: shouldAutoDeploy,
			})
		);

		updateBrain({
			...currentBrain,
			slug: profileSlug,
			editorAutoDeploy: shouldAutoDeploy,
		}).catch((e) => {
			console.error('pss Error changing editorAutoDeploy state:', e);
			// Reset state if it fails
			dispatch(
				updateBrainInRedux({
					...currentBrain,
					editorAutoDeploy: originalValue,
				})
			);
			toast({
				title: 'Error',
				description: 'Unable to change the auto-deploy setting',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		});
	}

	const handleAutoShutdownSwitch = (showAutoShutdown) => {
		if (showAutoShutdown) {
			//set autoShutdownBehaviour to NEVER
			console.log('SET VALUE TO:', showAutoShutdown);
			updateBrain({
				...currentBrain,
				slug: profileSlug,
				autoShutdownBehaviour: 'BY_LAST_USE',
			})
				.then((res) => {
					dispatch(
						updateBrainInRedux({
							...currentBrain,
							autoShutdownBehaviour: 'BY_LAST_USE',
						})
					);
				})
				.catch((e) => {
					console.error('Error changing editorAutoDeploy state:', e);
					// Reset state if it fails
					toast({
						title: 'Error',
						description: 'Unable to change the auto-shutdown setting',
						status: 'error',
						duration: 3000,
						isClosable: true,
					});
				});
		} else {
			//set autoShutdownBehaviour to BY_LAST_USE
			console.log('SET VALUE TO NOT:', showAutoShutdown);
			updateBrain({
				...currentBrain,
				slug: profileSlug,
				autoShutdownBehaviour: 'NEVER',
			})
				.then((res) => {
					dispatch(
						updateBrainInRedux({
							...currentBrain,
							autoShutdownBehaviour: 'NEVER',
						})
					);
				})
				.catch((e) => {
					console.error('Error changing editorAutoDeploy state:', e);
					// Reset state if it fails
					toast({
						title: 'Error',
						description: 'Unable to change the auto-shutdown setting',
						status: 'error',
						duration: 3000,
						isClosable: true,
					});
				});
		}
	};

	useEffect(() => {
		const debugHandler = (type, data) => {
			try {
				const msg = JSON.parse(data.msg);
				if (msg.error || msg.__isError || msg._error) {
					setDebugLogHasErrorMessage(true);
				}
			} catch (e) {
				console.log('User may not have logged the entire message');
			}

			if (!debugPanelOpen) {
				setDebugLogCounter(ref.current + 1);
			}
		};

		const errorHandler = () => {
			!debugPanelOpen && setDebugLogHasErrorMessage(true);
		};

		const warningHandler = () => {
			!debugPanelOpen && setDebugLogHasWarningMessage(true);
		};

		onNodeRedDebugMessage(debugHandler);
		onNodeRedNodeError(errorHandler);
		onNodeRedNodeWarning(warningHandler);
		return () => {
			offNodeRedDebugMessage(debugHandler);
			offNodeRedNodeError(errorHandler);
			offNodeRedNodeWarning(warningHandler);
		};
	}, [debugPanelOpen]);

	useEffect(() => {
		if (debugPanelOpen) {
			setDebugLogCounter(0);
			setDebugLogHasErrorMessage(false);
			setDebugLogHasWarningMessage(false);
		}
	}, [debugPanelOpen]);

	const [isSmallerThan500] = useMediaQuery('(max-width: 500px)');

	return (
		<Flex
			direction="column"
			height="100%"
			bg="light.theme.yellow.200"
			width="54px"
			borderRight="1px solid"
			borderColor="light.border.gray.300"
			alignItems="center"
			zIndex="1000"
		>
			<Flex alignItems="center" justifyContent="center" mb="8px" mt="2px">
				<EditorMenu workspaceId={workspaceId} />
			</Flex>
			<Flex direction="column" justifyContent="space-between" height="100%">
				<Box>
					<ControlButton
						toggled={synthesisSidebarVisible}
						onClick={toggleSynthSidebar}
						tooltipMessage="Describe (Ctrl + G)"
					>
						<FiFeather size="21px" />
					</ControlButton>
					<ControlButton
						toggled={nodePaletteIsOpen}
						onClick={toggleNodePalette}
						tooltipMessage="Nodes (Ctrl + P)"
					>
						<IoExtensionPuzzleOutline size="22px" />
					</ControlButton>
					<Box position="relative">
						<ControlButton
							toggled={dashboardIsOpen}
							onClick={toggleDashboard}
							tooltipMessage="Dashboard (Ctrl + D)"
						>
							<AiOutlineLayout size="22px" />
						</ControlButton>
						{/* {dashboardNodes.length &&
						!dashboardIsOpen &&
						!dashboardOpened ? (
							<Box position="absolute" top="2px" right="2px">
								<BsCircleFill color="#CC5500" size="0.5em" />
							</Box>
						) : null} */}
					</Box>
					<Box
						// bg='tomato'
						position="relative"
					>
						<ControlButton
							toggled={debugPanelOpen}
							onClick={toggleDebugPanel}
							tooltipMessage="Debug (Ctrl + Space)"
						>
							<AiOutlineBug size="22px" />
						</ControlButton>
						{debugLogCounter > 0 && (
							<Flex
								borderRadius="9px"
								paddingX="6px"
								minWidth="22px"
								height="18px"
								position="absolute"
								top="-4px"
								right="-4px"
								alignItems="center"
								justifyContent="center"
								fontSize="12px"
								bg={
									debugLogHasErrorMessage
										? 'red.700'
										: debugLogHasWarningMessage
										? 'yellow.500'
										: 'light.theme.gray.400'
								}
								color="#fff"
							>
								{debugLogCounter}
							</Flex>
						)}
					</Box>
					<ControlButton
						toggled={infoPanelOpen}
						onClick={toggleInfoPanel}
						tooltipMessage="Help"
					>
						<IoDocumentTextOutline size="22px" />
					</ControlButton>
					<ControlButton
						toggled={moduleSidebarIsOpen}
						onClick={toggleModuleSidebar}
						tooltipMessage="Install"
					>
						<VscExtensions size="20px" />
					</ControlButton>
					<ControlButton
						toggled={instructBarIsOpen}
						onClick={toggleInstructBar}
						tooltipMessage="Instruct Bar (Cmd + K)"
					>
						<BsCommand size="18px" />
					</ControlButton>
					{isSmallerThan500 && (
						<ControlButton
							toggled={issuesSidebarIsOpen}
							onClick={toggleIssuesSidebar}
							tooltipMessage="Instruct Bar (Cmd + K)"
						>
							<IssuesButton disableClick={true} />
						</ControlButton>
					)}
				</Box>
				<Box>
					<Popover placement="right-end">
						<PopoverTrigger>
							<Box position="relative">
								<ControlButton>
									<FiSettings size="18px" />
								</ControlButton>
							</Box>
						</PopoverTrigger>
						<PopoverContent
							borderRadius="2px"
							border="1px solid"
							borderColor="light.border.gray.200"
							boxShadow="none"
							bg="light.theme.yellow.200"
							// padding='8px'
							_focus={{
								outline: 'none',
							}}
						>
							<PopoverHeader
								textStyle="sans.sm"
								color="light.font.gray.400"
								// pb='12px'
							>
								Worker Settings
							</PopoverHeader>
							<PopoverBody
								textStyle="sans.xs"
								color="light.font.gray.300"
							>
								<Flex
									justifyContent="space-between"
									alignItems="center"
								>
									<Box>Auto-deploy flows</Box>
									<Switch
										onChange={(e) =>
											handleAutoDeploySwitch(e.target.checked)
										}
										isChecked={currentBrain.editorAutoDeploy}
										colorScheme="gray"
									/>
								</Flex>
								{selectedProfile.tier === 'PREMIUM' && (
									<Flex
										justifyContent="space-between"
										alignItems="center"
										mt="10px"
									>
										<Box>Auto-shutdown</Box>
										<Switch
											onChange={(e) =>
												handleAutoShutdownSwitch(e.target.checked)
											}
											isChecked={
												currentBrain.autoShutdownBehaviour !==
												'NEVER'
											}
											colorScheme="gray"
										/>
									</Flex>
								)}
							</PopoverBody>
						</PopoverContent>
					</Popover>
				</Box>
			</Flex>
		</Flex>
	);
};

export default EditorControlBar;
