import React from 'react';
import { VscArrowSmallRight } from 'react-icons/vsc';
import Fuse from 'fuse.js';
import _ from 'lodash';
import SearchItem from './SearchItem';
import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';
const CustomTypeSearch = ({
	placeholderText: text,
	placeholderType: type,
	currentPlaceholderId,
	placeholderDetails,
	setPlaceholderSearchItems,
	setSearchBarLoadingStatus,
	searchItems,
	selectedIndex,
}) => {
	// console.log("details :", placeholderDetails, currentPlaceholderId);
	// React.useEffect(() => {
	//   axios
	//     .post("http://localhost:1880/customsearch", {})
	//     .then(function (response) {
	//       console.log(response);
	//       setAllItems(response.data);
	//     })
	//     .catch(function (error) {
	//       console.log(error);
	//     });
	// }, [setAllItems]);

	const options = {
		// isCaseSensitive: false,
		includeScore: true,
		// shouldSort: true,
		// includeMatches: false,
		// findAllMatches: false,
		// minMatchCharLength: 1,
		// location: 0,
		threshold: 0.8, // the less the more accurate the match
		distance: 10,
		// useExtendedSearch: false,
		// ignoreLocation: false,
		// ignoreFieldNorm: false,
		keys: ['value', 'meta.subtext'],
	};

	const [filteredItems, setFilteredItems] = React.useState([]);

	/**
	 * This value will get selected by the placeholder, in this case it is email
	 * @param {} array
	 */
	const selectFunction = (itemsArray) => {
		return itemsArray.map((thing) => {
			return { show: thing.item.value, submit: thing.item.meta };
		});
	};

	var doneTypingInterval = 10; //time in ms, 5 second for example
	const typingTimer = React.useRef(null);

	const numberOfResults = 5; // max number of results shown

	const onTextChange = (searchText) => {
		axios({
			url: `https://suggestqueries.google.com/complete/search?client=chrome&q=${searchText}`,
			adapter: jsonpAdapter,
		})
			.then(function (response) {
				var suggests = response.data[1] || [];
				var out = [];

				suggests.map((m) => {
					out.push({ value: m, meta: 'a few moments ago' });
				});
				const fuse = new Fuse(out, options);
				if (
					searchText &&
					_.findIndex(searchItems, function (o) {
						return o.show === searchText;
					}) === -1
				) {
					var results = fuse.search(searchText);

					const searchItems = results.slice(0, numberOfResults);
					setFilteredItems(searchItems);
					setPlaceholderSearchItems(selectFunction(searchItems));
				}
			})
			.catch(function (error) {
				console.error(error);
			});
	};

	React.useEffect(() => {
		clearTimeout(typingTimer.current);
		if (text) {
			typingTimer.current = setTimeout(() => {
				onTextChange(text);
			}, doneTypingInterval);
		}
	}, [text, doneTypingInterval]);

	return filteredItems.map((current, i) => {
		return (
			<SearchItem
				selectedIndex={selectedIndex}
				key={i}
				i={i}
				searchItemMainText={current.item.value}
				searchItemSubText={current.item.meta.subtext || ''}
				// searchItemSubText={'Bruh'}
				reactIcon={<VscArrowSmallRight />}
			/>
		);
	});
};

export default CustomTypeSearch;
