import create from 'zustand';
import _ from 'lodash';
import createVanilla from 'zustand/vanilla';
import update from 'immutability-helper';
import { devtools, persist, subscribeWithSelector } from 'zustand/middleware';
import produce from 'immer';
import lodashFlow from 'lodash/flow';
import { getGroupLayoutFromFullLayout } from '../functions/getGroupLayoutFromFullLayout';
import { getTableDataFromEvent } from '../functions/getTableDataFromEvent';
import { createUIMap } from '../functions/createUIMap';
import { compSortFn } from '../functions/sortComponents'


// Log every time state is changed
const loggerMiddleware = (config) => (set, get, api) =>
	config(
		(args) => {
			set(args);
			if (process.env.NODE_ENV === 'development') {
				console.log('dashsock zustand state', get());
			}
		},
		get,
		api
	);

/**
 * Immer zustand middleware
 * @param config
 * @returns
 */
export const immer = (config) => (set, get, api) =>
	config(
		(partial, replace) => {
			const nextState =
				typeof partial === 'function' ? produce(partial) : partial;
			return set(nextState, replace);
		},
		get,
		api
	);

const middleware = lodashFlow([
	// immer, // TODO Enable this later and remove individual produce fns in each action
	loggerMiddleware,
	subscribeWithSelector,
	immer,
	devtools,
	(fn) =>
		persist(fn, {
			name: 'workspaceDashboardStore',
			partialize: (state) => ({
				// @ts-ignore
				dashboardData: state.dashboardData,
				// @ts-ignore
				layout: state.layout,
			}),
		}),
]);

/**
 * @type {import('zustand').StoreApi<import('./types').DashboardStore>}
 */
export const workspaceDashboardStore = createVanilla(
	middleware(
		/**
		 *
		 * @param {import('zustand').SetState<import('./types').DashboardStore>} set
		 * @param {import('zustand').GetState<import('./types').DashboardStore>} get
		 * @param {import('zustand').StoreApi<import('./types').DashboardStore>} api
		 * @returns {import('./types').DashboardStore}
		 */
		// @ts-ignore
		(set, get, api) => ({
			dashboardData: {},
			dashboardLayout: [],
			dashboardFlow: [],
			completeFlow: [],
			layout: {},
			dashboardComponentTempData: {
				buttons: {},
				templates: {},
				forms: {},
			},
			componentMeta: {
				buttons: {}
			},
			editMode: false,
			dashboardUiMap: {},
			// templateData: {},

			setLayout: (workspaceId, newLayout) => {
				console.log('dala new', newLayout);
				set(
					produce((state) => {
						state.layout = newLayout;
					})
				);
			},

			setCompleteFlow: (workspaceId, flow) => {
				set(
					produce((state) => {
						if (!state.dashboardData[workspaceId]) {
							state.dashboardData[workspaceId] = {}
						}
						const dashboardData = state.dashboardData[workspaceId]

						flow.forEach((node) => {
							switch (node.type) {
								case 'dashboard-table': {
									if (!dashboardData[`table:${node.id}`]) {
										dashboardData[`table:${node.id}`] = {
											rows: [],
											columns: {},
										};
									}
									break;
								}
								case 'dashboard-template': {
									if (!dashboardData[`template:${node.id}`]) {
										dashboardData[`template:${node.id}`] = {
											body: '',
											variables: {},
										};
									}
									break;
								}
								case 'dashboard-form': {
									if (!dashboardData[`form:${node.id}`]) {
										let formData = {};
										try {
											const config = JSON.parse(node.config);
											Object.keys(config).forEach(
												(key) => (formData[key] = '')
											);
										} catch (e) {}

										dashboardData[`form:${node.id}`] = formData;
									}
								}
								case 'dashboard-richtext': {
									if (!dashboardData[`richtext:${node.id}`]) {
										dashboardData[`richtext:${node.id}`] = {
											body: '',
										};
									}
								}
								case 'dashboard-image': {
									if (!dashboardData[`image:${node.id}`]) {
										dashboardData[`image:${node.id}`] = {
											src: '',
											caption: ''
										}
									}

									const src = node.src
									if (node.payloadTypesrc === 'str') {
										let isValidURL = false
										try {
											new URL(src)
											isValidURL = true
										} catch (e) {}

										if (isValidURL) {
											dashboardData[`image:${node.id}`].src = src
										}
									}

									if (node.payloadTypecaption === 'str') {
										dashboardData[`image:${node.id}`].caption = node.caption
									}

								}
								case 'dashboard-button': {
									const buttonId = `button:${node.id}`
									if (!state.componentMeta.buttons[buttonId]) {
										state.componentMeta.buttons[buttonId] = {
											loading: false
										}
									}
								}
							}
						});

						state.dashboardData[workspaceId] = dashboardData
						state.completeFlow = flow.sort(compSortFn)
						state.dashboardUiMap = createUIMap(flow)
					})
				);
			},

			setDashboardFlow: (flow) => {
				set(
					produce((state) => {
						state.dashboardFlow = flow;
					})
				);
			},

			setDashboardLayout: (layout) => {
				set(
					produce((state) => {
						state.dashboardLayout = layout;
					})
				);
			},

			setDashboardData: (data) => {
				set(
					produce((state) => {
						state.dashboardData = data;
					})
				);
			},

			handleRichTextFieldChange: (workspaceId, fieldId, val) => {
				set(
					produce((state) => {
						state.dashboardData[workspaceId][fieldId].body = val;
					})
				);
			},

			handleImageEvent: (workspaceId, fieldId, event) => {
				set(
					produce((state) => {
						if (event.type === 'POPULATE') {
							state.dashboardData[workspaceId][fieldId].src = event?.data?.src
							state.dashboardData[workspaceId][fieldId].caption = event?.data?.caption
						}
					})
				)
			},

			handleTableEvent: (workspaceId, id, event) => {
				switch (event.type) {
					case 'POPULATE': {
						set(
							produce((state) => {
								const { rows, columns } = getTableDataFromEvent(event);
								state.dashboardData[workspaceId] = update(state.dashboardData[workspaceId], {
									[id]: { $set: { rows, columns } },
								});
							})
						);
						break;
					}
					case 'ADD_ROWS': {
						set(
							produce((state) => {
								const { rows, columns } = getTableDataFromEvent(event);
								let currentData = state.dashboardData[workspaceId][id];
								if (!currentData) {
									currentData = {
										rows: [],
										columns: {},
									};
								}

								currentData.rows = currentData.rows.concat(rows);
								currentData.columns = {
									...currentData.columns,
									...columns,
								};
								state.dashboardData[workspaceId][id] = currentData;
							})
						);
						break;
					}
					case 'UPSERT_ROWS': {
						set(
							produce((state) => {
								const { rows, columns } = getTableDataFromEvent(event);
								let currentData = state.dashboardData[workspaceId][id];
								if (!currentData) {
									currentData = {
										rows: [],
										columns: {},
									};
								}

								rows.forEach((row) => {
									const id = row._identifier.value;
									Object.keys(row.fields).forEach((fieldName) => {
										currentData.columns[fieldName] = true;
									});

									const targetRow = currentData.rows.find(
										(r) => r._identifier.value === id
									);
									if (!targetRow) {
										currentData.rows = currentData.rows.concat(row);
										return;
									} else {
										targetRow.fields = {
											...targetRow.fields,
											...row.fields,
										};
									}
								});
								state.dashboardData[workspaceId][id] = currentData;
							})
						);
						break;
					}
					default:
						return;
				}
			},

			handleTemplateEvent: (workspaceId, id, event) => {
				set(
					produce((state) => {
						console.log('dashsock tmp e', id, event);
						state.dashboardData[workspaceId] = update(state.dashboardData[workspaceId], {
							[id]: { $set: event.data },
						});

						state.dashboardComponentTempData.templates[id] = {
							body: event.data.body,
							saved: true,
						};
						// if (!state.dashboardComponentTempData.templates[id]) {
						// }
					})
				);
			},

			handleFormEvent: (workspaceId, id, event) => {
				set(
					produce((state) => {
						if (event.type === 'POPULATE') {
							const data = event.data;
							state.dashboardData[workspaceId] = update(state.dashboardData[workspaceId], {
								[id]: { $set: data },
							});
						}
					})
				);
			},

			handleRichTextEditorEvent: (workspaceId, id, event) => {
				set(
					produce((state) => {
						if (!state.dashboardData[workspaceId][id]) {
							state.dashboardData[workspaceId][id] = { body: '' };
						}
						state.dashboardData[workspaceId][id].body = event.body;
					})
				);
			},

			handleButtonEvent: (buttonId, event) => {
				set(
					produce(state => {
						const buttonState = state.componentMeta.buttons[buttonId]
						switch (event.type) {
							case 'UPDATE_LOADING_STATUS': {
								buttonState.loading = event.data.state
							}
						}
					})
				)
			},

			saveLayoutToFlow: (updateFn) => {
				set(
					produce((state) => {
						state.completeFlow.forEach((node) => {
							if (node.type !== 'dashboard-group') {
								return;
							}

							const layout = getGroupLayoutFromFullLayout(
								node.id,
								state.layout
							);

							console.log('dala', layout);
							node.positionDetails = JSON.stringify(layout);
						});

						updateFn(_.cloneDeep(state.completeFlow));
					})
				);
			},

			handleFormFieldChange: (workspaceId, formId, fieldName, value) => {
				console.log('dashsock zustand ffc', formId, fieldName, value);
				set(
					produce((state) => {
						let newFormData = state.dashboardData[workspaceId][formId];
						if (!newFormData?.fields) {
							newFormData = {
								_identifier: {
									type: 'random',
									value: Date.now().toString(36)
								},
								fields: {}
							}
						}

						if (!newFormData?.fields?.[fieldName]) {
							newFormData.fields[fieldName] = {}
						}

						if (newFormData.fields?.[fieldName]?.type) {
							newFormData = update(newFormData, {
								fields: {
									[fieldName]: {
										value: { $set: value }
									}
								}
							})
						} else {
							newFormData = update(newFormData, {
								fields: {
									[fieldName]: {
										$set: { type: typeof value, value: value }
									}
								}
							})
						}

						state.dashboardData[workspaceId] = update(state.dashboardData[workspaceId], {
							[formId]: { $set: newFormData },
						});
					})
				);
			},

			setFormData: (workspaceId, formId, data) => {
				set(
					produce((state) => {
						const currentFormData = state.dashboardData[workspaceId][formId]
						if (currentFormData) {
							data = {
								...currentFormData,
								...data
							}
						}

						state.dashboardData[workspaceId] = update(state.dashboardData[workspaceId], {
							[formId]: { $set: data }
						})
					})
				)
			},

			setTemplateBody: (templateNodeId, newTemplateBody) => {
				set(
					produce((state) => {
						// state.dashboardData[templateNodeId].body = newTemplateBody
						state.dashboardComponentTempData.templates[templateNodeId].body =
							newTemplateBody;
					})
				);
			},

			/**
			 * Copies the flow's node properties into a temporary object (dashboardComponentTempData)
			 * for editing, and enables edit mode in the UI.
			 */
			enterEditMode: () => {
				const completeFlow = get().completeFlow;
				const templates = {};
				const forms = {};
				const buttons = {};

				completeFlow
					.filter((node) => node.type.startsWith('dashboard-'))
					.forEach((node) => {
						switch (node.type) {
							case 'dashboard-button': {
								console.log('buttonNode', node);
								buttons[`button:${node.id}`] = {
									_saved: true,
									color: node.color,
									label: node.label,
									width: node.width,
									tooltip: node.tootlip,
									variant: node.variant,
								};
								break;
							}
							case 'dashboard-template': {
								console.log('templateNode', node);
								const objectToFill = {
									_saved: true,
									title: node.title,
									width: node.width,
								};
								if (node.payloadTypetemplateBody === 'str') {
									objectToFill.body = node.templateBody;
								}
								templates[`template:${node.id}`] = objectToFill;
								break;
							}
							case 'dashboard-form': {
								break;
							}
							default:
								return;
						}
					});

				set(
					produce((state) => {
						state.dashboardComponentTempData.templates = templates;
						state.dashboardComponentTempData.forms = forms;
						state.dashboardComponentTempData.buttons = buttons;
						state.editMode = true;
					})
				);
			},

			/**
			 * Saves the temporary dashboardComponentTempData changes back into flow, deploys it, and disables
			 * edit mode in UI.
			 * @param {Function} updateFn A function that deploys the current dashboard flow by any means possible.
			 */
			exitEditMode: (workspaceId, updateFn) => {
				set(
					produce((state) => {
						const flow = state.completeFlow;
						const dashboardComponentTempData = get().dashboardComponentTempData;
						flow
							.filter((node) => node.type.startsWith('dashboard-'))
							.forEach((node) => {
								switch (node.type) {
									case 'dashboard-template': {
										const meta =
											dashboardComponentTempData.templates[
												`template:${node.id}`
											];
										if (!meta) return;

										node.title = meta.title;
										node.width = meta.width;
										if (meta.body) {
											node.templateBody = meta.body;
											if (
												!state.dashboardData[workspaceId][`template:${node.id}`]
											) {
												state.dashboardData[workspaceId][`template:${node.id}`] =
													{
														body: '',
														variables: {},
													};
											}
											state.dashboardData[workspaceId][
												`template:${node.id}`
											].body = meta.body;
										}

										break;
									}
									case 'dashboard-group': {
										const layout = getGroupLayoutFromFullLayout(
											node.id,
											state.layout
										);
										node.positionDetails = JSON.stringify(layout);
									}

									default:
										break;
								}
							});

						state.dashboardComponentTempData = {
							templates: {},
							forms: {},
							buttons: {},
						};
						state.editMode = false;
						state.dashboardUiMap = createUIMap(flow)
						// get().setCompleteFlow(flow)

						const completeFlow = _.cloneDeep(state.completeFlow);
						updateFn(completeFlow);
					})
				);
			},
		})
	)
);

/**
 * @type {import('zustand').UseBoundStore<import('./types').DashboardStore>}
 */
export const useWorkspaceDashboardStore = create(workspaceDashboardStore);
