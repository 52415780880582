import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { Box, Image, Tooltip, Flex } from '@chakra-ui/react';
import { useStore } from '../../../zustand';
import TransientNodeComponent from '../TransientNode';
import SubflowIONodeComponent from '../SubflowIONodeComponent';
import { getNodeById } from '../../functions/NodeRed';
import getRedInstance from '../../functions/getRedInstance';
import truncate from 'lodash/truncate';
import range from 'lodash/range';

// Importing extracted components
import TriggerElement from './TriggerElement';
import UndeployedElement from './UndeployedElement';
import ReqMissingElement from './ReqMissingElement';
import { sanitize } from 'dompurify';

import './status.css';

/**
 * @type {import('react').CSSProperties}
 */
const dummyHandleStyles = {
	outline: 'solid 1.6px #727272',
	background: 'white',
	borderRadius: '2.5px',
	width: '9px',
	height: '9.5px',
	border: 'none',
};

/**
 * @type {import('react').CSSProperties}
 */
const handleStyles = {
	outline: 'solid 1.6px #727272',
	background: 'white',
	borderRadius: '2.5px',
	// Should be 0, set to 1 for testing
	opacity: 0,
};

const CustomNodeComponent = ({ id, data, selected }) => {
	/**
	 * nodeById useMemo needs to depend on data so as to update,
	 * whenever data prop changes, otherwise it ends up in a stale
	 * state which causes issues.
	 */
	const nodeById = useMemo(() => getNodeById(id), [id, data]);
	// template values for that particular node type. Not specific to node-id
	const nodeTemplate = useStore(
		useCallback(
			(state) => state.nodeConfigs.byType[nodeById.type],
			[nodeById.type]
		)
	);
	const interactionMode = useStore(
		useCallback((state) => state.interactionMode, [])
	);
	const edgeDrag = useStore(useCallback((state) => state.edgeDrag, []));
	const nodeStatus = useStore(
		useCallback((state) => state.nodeStatus.byId[id], [id])
	);
	const errorNodeId = useStore(useCallback((state) => state.errorNodeId, []));
	const focusedStepNodeIds = useStore(
		useCallback((state) => state.focusedStepNodeIds, [])
	);
	/**
	 * icon also needs to depend on data so as to update,
	 * whenever data prop changes, otherwise it ends up in a stale
	 * state which causes issues.
	 */
	const icon = useMemo(() => {
		const RED = getRedInstance();
		return RED.utils.getNodeIcon(nodeById?._def, nodeById);
	}, [nodeById, data]);

	const [isNodeHovered, setIsNodeHovered] = useState(false);

	const buttonClick = useMemo(() => {
		if (nodeById?._def?.button && nodeById?._def?.button?.onclick) {
			return nodeById._def.button.onclick.bind(nodeById);
		}
		return undefined;
	}, [nodeById]);

	const outputLabels = useMemo(() => {
		if (nodeById?._def?.outputLabels?.bind) {
			return nodeById._def.outputLabels.bind(nodeById);
		}
		return undefined;
	}, [nodeById]);

	const truncatedNodeStatusText = useMemo(() => {
		return truncate(nodeStatus?.msg?.text, {
			length: 60,
			separator: ' ',
			omission: '...',
		});
	}, [nodeStatus?.msg?.text]);

	const { nodeStatusPlainText, nodeStatusHtml } = useMemo(() => {
		// const converter = new showdown.Converter()
		// const html = converter.makeHtml(nodeStatus?.msg?.text)
		const cleanHtml = sanitize(nodeStatus?.msg?.text, {
			USE_PROFILES: { html: true },
			ADD_ATTR: ['target'],
		});

		const s = document.createElement('span');
		s.innerHTML = cleanHtml;
		const textContent = s.textContent || s.innerText;

		return {
			nodeStatusPlainText: textContent,
			nodeStatusHtml: cleanHtml,
		};
	}, [nodeStatus?.msg?.text]);

	const { debounceDeploy } = data;
	const { dirty, changed } = data.node;

	useEffect(() => {
		if (dirty || changed) {
			if (typeof debounceDeploy === 'function') {
				console.log('not transient, so deploying');
				debounceDeploy();
			}
		}
	}, [dirty, changed]);

	const nodeHasError = errorNodeId === id;
	// const nodeIsHighlighted = focusedStepNodeIds?.includes(id);
	const nodeIsHighlighted = false;

	const nodeName = useMemo(() => {
		const name =
			nodeById?.name ||
			(typeof nodeTemplate?.paletteLabel === 'function'
				? nodeTemplate?.paletteLabel()
				: nodeTemplate?.paletteLabel) ||
			(nodeById.type.substring(0, 7) === 'subflow'
				? nodeTemplate?.paletteLabel()
				: nodeById.type);
		return name;
	}, [nodeById?.name, nodeById.type, nodeTemplate]);

	return nodeTemplate && data && nodeById ? (
		<Box>
			{nodeTemplate.button && (
				<TriggerElement
					align={nodeTemplate.align || 'left'}
					onClick={buttonClick}
				/>
			)}
			<Box
				bg={nodeTemplate.color}
				maxWidth="400px"
				borderRadius="2px"
				boxShadow={
					nodeHasError
						? '0px 0px 10px 4px #ED8936'
						: nodeIsHighlighted
						? '0px 0px 8px 2px #6c757d'
						: 'rgba(0, 0, 0, 0.1) 0px -2px 4px'
				}
				// Visual indication of the selected node
				outline={
					selected
						? `solid 3px #fb8500`
						: nodeIsHighlighted
						? 'solid 1px #6c757d'
						: 'solid 1px #727272'
				}
				transition="all 0.1s cubic-bezier(.08,.52,.52,1)"
				cursor="pointer"
				height={`${30 + 7 * (nodeById.outputs || 1)}px`}
				filter={
					isNodeHovered &&
					interactionMode === 'edgeDrag' &&
					'brightness(1.15)'
				}
				onMouseEnter={() => setIsNodeHovered(true)}
				onMouseLeave={() => setIsNodeHovered(false)}
			>
				{nodeById?.valid === false ? (
					<ReqMissingElement />
				) : data.node.dirtyStatus ||
				  data.node.dirty ||
				  data.node.changed ? (
					<UndeployedElement />
				) : null}

				<Box
					width={
						isNodeHovered && interactionMode === 'edgeDrag'
							? '100%'
							: '9px'
					}
					display="flex"
					left="-2px"
					position="absolute"
					height="100%"
				>
					{range(nodeById.inputs).map((item, i) => {
						return (
							<React.Fragment key={item}>
								{edgeDrag.nodeId !== data.node.id && (
									<Handle
										type="target"
										position={Position.Left}
										id={item.toString()}
										key={item}
										style={{
											...handleStyles,
											width:
												interactionMode === 'edgeDrag'
													? '100%'
													: '200%',
											height: `${
												30 + 7 * (nodeById.outputs || 1)
											}px`,
											border: 'none',
											outline: selected
												? `solid 2px #727272`
												: 'solid 1.6px #727272',
											zIndex: 23,
										}}
									/>
								)}

								<div
									style={{
										...dummyHandleStyles,
										position: 'absolute',
										left: '-4px',
										top: '50%',
										transform: 'translateY(-50%)',
									}}
								></div>
							</React.Fragment>
						);
					})}
				</Box>
				<Box
					fontWeight="700"
					color="#323232"
					display="flex"
					justifyContent="flex-start"
					alignItems="center"
					height="100%"
				>
					<Box
						bg={nodeTemplate.darkColor}
						paddingX="7px"
						height="100%"
						borderLeftRadius="2px"
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<Image src={icon} boxSize="22px !important" />
					</Box>
					<Tooltip
						label={nodeName}
						placement="right"
						openDelay={750}
						visibility={nodeName.length > 24 ? 'visible' : 'hidden'}
					>
						<Box
							paddingX="23px"
							color="white"
							fontWeight="500 !important"
							fontFamily="Ubuntu"
							fontSize="14px"
							noOfLines={1}
						>
							{nodeName}
						</Box>
					</Tooltip>
				</Box>
				{nodeStatus ? (
					<Box position="relative">
						<Flex
							position="absolute"
							top="1"
							alignItems="center"
							justifyItems="center"
						>
							{nodeStatus.msg?.shape ? (
								<Box
									background={
										nodeStatus.msg.fill === 'red'
											? '#BA0702'
											: 'red' && nodeStatus.msg.fill === 'blue'
											? '#014D9A'
											: 'blue' && nodeStatus.msg.fill === 'yellow'
											? '#ECCD00'
											: 'yellow' && nodeStatus.msg.fill === 'green'
											? '#03A464'
											: 'green' && nodeStatus.msg.fill === 'grey'
											? '#A6A6A6'
											: 'grey'
									}
									w="2.5"
									h="2.5"
									mr="1"
									borderRadius="20%"
								></Box>
							) : null}
							{truncatedNodeStatusText ? (
								<Box
									color="light.theme.gray.400"
									fontFamily="Ubuntu, sans-serif"
									fontSize="13px"
									fontWeight="400"
									whiteSpace="nowrap"
									mr="1"
									ml="4px"
									className="node-status-container"
									// bg='tomato'
								>
									<Tooltip
										label={nodeStatusPlainText}
										placement="right"
									>
										<Box
											// color='light.theme.gray.400 !important'
											fontFamily="Ubuntu, sans-serif"
											dangerouslySetInnerHTML={{
												__html: nodeStatusHtml,
											}}
										/>
									</Tooltip>
								</Box>
							) : null}
						</Flex>
					</Box>
				) : null}

				<Box
					width="9px"
					display="flex"
					right="-1px"
					position="absolute"
					top="0"
					height="100%"
				>
					{range(nodeById.outputs).map((item, i) => {
						if (nodeById.type === 'debug') return null;

						return (
							<Tooltip
								key={item}
								label={outputLabels ? outputLabels(i) : ''}
								placement="right"
								openDelay={2000}
								closeOnMouseDown={true}
							>
								<Box>
									{edgeDrag.nodeId !== data.node.id && (
										<Handle
											type="source"
											position={Position.Right}
											id={item.toString()}
											key={item}
											style={{
												top: `${
													((i + 1) * 100) / (nodeById.outputs + 1) //get percentage of port shift from top
												}%`,
												...handleStyles,
												width:
													interactionMode === 'edgeDrag'
														? '100%'
														: '200%',
												// Changing the height of the handle depending on the number of outputs
												height: `${
													(30 + 7 * (nodeById.outputs || 1)) /
													nodeById.outputs
												}px`,
												outline: selected
													? `solid 2px #727272`
													: 'solid 1.6px #727272',
												zIndex:
													edgeDrag.handleType === 'target'
														? 25
														: 22,
											}}
										/>
									)}
								</Box>
							</Tooltip>
						);
					})}
					{nodeById.outputs >= 1 && (
						<Box
							display="flex"
							flexDirection="column"
							justifyContent="space-around"
						>
							{range(nodeById.outputs).map((item, i) => {
								return (
									<div
										key={item}
										style={{
											...dummyHandleStyles,
											transform: 'translateX(50%) translateY(10%)',
										}}
									></div>
								);
							})}
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	) : null;
};

export const nodeTypes = {
	special: CustomNodeComponent,
	transient: TransientNodeComponent,
	subflowIO: SubflowIONodeComponent,
};
