import { config } from '../../config';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

const changeSession = async ({ sessionId, diff }) => {
	const APIRequestBody = {
		session_id: sessionId,
		diff,
	};

	const changeSessionFn = APIFunction({
		BASEURL: config.pacEngine.baseUrl,
		PATH_SEARCH: APIRoutes.apiService.changeSession.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.changeSession.PATH_METHOD,
	});

	const response = await changeSessionFn(APIRequestBody);
	return response;
};

export default changeSession;
