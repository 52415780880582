const SECOND = 1000
const MINUTE = 60 * SECOND
const HOUR = 60 * MINUTE
const DAY = 24 * HOUR
const MONTH = 30 * DAY
const YEAR = 365 * DAY

export function getTimeElapsed(timestamp) {
    const d = new Date(timestamp)
    let elapsed = Date.now() - d.getTime()
    
    const years = parseInt(elapsed / YEAR)
    elapsed -= years * YEAR

    const months = parseInt(elapsed / MONTH)
    elapsed -= months * MONTH

    const days = parseInt(elapsed / DAY)
    elapsed -= days * DAY

    const hours = parseInt(elapsed / HOUR)
    elapsed -= hours * HOUR

    const minutes = parseInt(elapsed / MINUTE)
    elapsed -= minutes * MINUTE

    const seconds = parseInt(elapsed / SECOND)

    return { years, months, days, hours, minutes, seconds }
}

export function getLongestTimeElapsedString(timestamp) {
    const { days, hours, minutes, seconds } = getTimeElapsed(timestamp)
    
    if (days > 0) return `${days}d`
    if (hours > 0) return `${hours}h`
    if (minutes > 0) return `${minutes}m`
    if (seconds > 0) return `${seconds}s`
	return `1s`
}