import React from 'react';
import { Box, Flex, Stack } from '@chakra-ui/react';
import { InfoTag } from './InfoTag';
import { PlatformList } from './PlatformList';
import { StoreSkillPack } from '../types/SkillPacks';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ReactMarkdown from 'react-markdown';

dayjs.extend(relativeTime);

export interface DetailSectionProps {
	collection: any;
}

const DetailSection = ({ collection }: DetailSectionProps) => {
	return (
		<Flex maxWidth="920px" borderRadius="5px" overflow="hidden">
			<Flex direction="column" flex="1">
				<Stack direction="row" spacing="6">
					<InfoTag label="Platforms">
						<Flex alignItems="center">
							<PlatformList
								platforms={[
									{ label: 'MacOS', id: 'MacOS' },
									{ label: 'Linux', id: 'Linux' },
								]}
							/>
						</Flex>
					</InfoTag>
					<InfoTag label="Created">
						<Box textStyle="sans.xs" width="100px">
							{collection.createdAt
								? dayjs(collection.createdAt).fromNow()
								: '- NA -'}
						</Box>
					</InfoTag>
					<InfoTag label="Developer">Maya Official</InfoTag>
				</Stack>
				<Box mt="20px">
					<InfoTag label="Description">
						<Box mt="5px" />
						{collection.shortDescription ? (
							<ReactMarkdown>{collection.shortDescription}</ReactMarkdown>
						) : (
							'- NA -'
						)}
					</InfoTag>
				</Box>
			</Flex>
		</Flex>
	);
};

export default DetailSection;
