import React from 'react';
import { Code } from '@chakra-ui/layout';
import { Box, Textarea } from '@chakra-ui/react';
import ReactJson from 'react-json-view';

/**
 *
 * @param {{
 * 	value: string,
 * 	onChange?: import('@chakra-ui/react').TextareaProps['onChange'],
 * 	onClick?: import('@chakra-ui/react').BoxProps['onClick']
 * 	editable?: boolean,
 * 	textAreaProps?: import('@chakra-ui/react').TextareaProps
 * }} param0
 * @returns
 */

const FlowJsonView = ({
	value,
	onChange,
	onClick,
	textAreaProps,
	editable,
}) => {
	return (
		<>
			{editable && !value ? (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					height="100%"
					onClick={onClick}
					textStyle="sans.sm"
					color="light.font.gray.400"
					cursor={'pointer'}
					border="solid 1px"
					borderColor="#D8D8D8 !important"
				>
					Click to Paste Here
				</Box>
			) : (
				<Box overflowY="auto" minWidth="0" flex="1" bg="white">
					{/* <ReactJson
						theme="shapeshifter:inverted"
						src={mayaFlowJson}
						collapsed={false}
						displayDataTypes={false}
						displayObjectSize={false}
						name={null}
						style={{
							font: 'Lekton',
							fontSize: '14px',
						}}
						enableClipboard={false}
						onEdit={editable? ()=>{} : false}
						onDelete={editable? ()=>{} : false}
					/> */}
					<Textarea
						h="full"
						fontFamily="Ubuntu"
						fontSize="14px"
						p="1.5"
						border="solid 1px"
						borderColor="#D8D8D8"
						borderRadius="none"
						spellCheck={false}
						placeholder="Paste Maya flow JSON"
						value={value}
						onChange={onChange}
						_focus={{ boxShadow: 'none' }}
						isReadOnly={!editable}
						{...textAreaProps}
					/>
				</Box>
			)}
		</>
	);
};

export default FlowJsonView;
