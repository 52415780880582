import React, { useEffect, useState, useCallback } from 'react';
import {
	Flex,
	Box,
	Button as ChakraButton,
	Circle,
	layout,
	DarkMode,
	createStandaloneToast,
	GridItem,
	Tooltip,
} from '@chakra-ui/react';
import { Responsive, WidthProvider } from 'react-grid-layout';

import useProfileSlug from '../../../../hooks/useProfileSlug';
import { getFlow } from './functions/getFlow';
import { Table } from './Components/Table';
import io from 'socket.io-client';
import { useWorkspaceDashboardStore, workspaceDashboardStore } from './zustand';
import {
	Group,
	GroupContent,
	GroupFooter,
	GroupHeader,
	GroupTitle,
} from './Components/Group';
import { Button } from './Components/Button';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './custom.css';
import { updateFlow } from './functions/updateFlow';
import { Template } from './Components/Template';
import { Form } from './Components/Form';
import useStepperStore from '../../../Stepper/StepperStore';
import shallow from 'zustand/shallow';
import { HistoryWithSlug } from '../../../../util/History';
import { deployEditor } from '../../../Editor/Workspace/functions/NodeRed';
import theme from '../../../../library/theme';
import { RichTextEditor } from './Components/RichTextEditor';
import { Image } from './Components/Image';

function isValidLayout(layoutStr) {
	try {
		const layout = JSON.parse(layoutStr);
		if (!layout || !layout.lg) {
			return false;
		}

		const isValid = Object.values(layout).every((l) => l.x >= 0 && l.y >= 0);
		return isValid;
	} catch (e) {
		return false;
	}
}

const defaultPosition = {
	x: 0,
	y: 0,
	w: 8,
	h: 6,
};

const defaultLayout = {
	lg: defaultPosition,
	md: defaultPosition,
	sm: defaultPosition,
	xs: defaultPosition,
	xxs: defaultPosition,
};

const componentPriority = {
	BUTTON: 1,
	FORM: 2,
	TABLE: 3,
};

const compSortFn = (dNode1, dNode2) => {
	if (componentPriority[dNode1.type] < componentPriority[dNode2.type]) {
		return -1;
	} else if (componentPriority[dNode1.type] > componentPriority[dNode2.type]) {
		return 1;
	}

	return dNode1?.type?.localeCompare(dNode2?.type);
};

const ResponsiveGridLayout = WidthProvider(Responsive);
const toast = createStandaloneToast({ theme: theme });

export const WorkspaceDashboard = ({
	expanded,
	fullScreen,
	workspaceUrl,
	workspaceId,
	token,
}) => {
	const slug = useProfileSlug();
	const [sockConnected, setSockConnected] = useState(false);
	const [socket, setSocket] = useState(null);
	const completeFlow = useWorkspaceDashboardStore(
		(state) => state.completeFlow,
		shallow
	);
	const dashboardUiMap = useWorkspaceDashboardStore(
		(state) => state.dashboardUiMap,
		shallow
	);

	// For managing in-dashboard edits
	const editMode = useWorkspaceDashboardStore((state) => state.editMode);
	const enterEditMode = useWorkspaceDashboardStore(
		(state) => state.enterEditMode,
		shallow
	);
	const exitEditMode = useWorkspaceDashboardStore(
		(state) => state.exitEditMode,
		shallow
	);

	// For modifying UI state based on events coming from the runtime
	const handleTableEvent = useWorkspaceDashboardStore(
		(state) => state.handleTableEvent,
		shallow
	);
	const handleTemplateEvent = useWorkspaceDashboardStore(
		(state) => state.handleTemplateEvent,
		shallow
	);
	const handleFormEvent = useWorkspaceDashboardStore(
		(state) => state.handleFormEvent,
		shallow
	);
	const handleRichTextEditorEvent = useWorkspaceDashboardStore(
		(state) => state.handleRichTextEditorEvent,
		shallow
	);

	const handleButtonEvent = useWorkspaceDashboardStore(
		(state) => state.handleButtonEvent,
		shallow
	);

	const handleImageEvent = useWorkspaceDashboardStore(
		(state) => state.handleImageEvent,
		shallow
	);

	// For managing the layout
	const setLayout = useWorkspaceDashboardStore(
		(state) => state.setLayout,
		shallow
	);
	const layout = useWorkspaceDashboardStore((state) => state.layout, shallow);

	// For opening in stepper view
	const openStepper = useStepperStore(
		useCallback((state) => state.openStepper, []),
		shallow
	);
	const addStep = useStepperStore(
		useCallback((state) => state.addStep, []),
		shallow
	);

	// Filtering out the nodes needed for the dashboard
	const dashboardFlow = completeFlow.filter((node) =>
		node.type.startsWith('dashboard-')
	);

	function handleLayoutChange(newLayout, allLayouts) {
		if (newLayout.length === 0) {
			return;
		}
		setLayout(workspaceId, allLayouts);
	}

	const handleEditModeToggle = useCallback(() => {
		const updateFlowFn = (flow) => {
			if (fullScreen) {
				return updateFlow(workspaceUrl, token, flow);
			}

			try {
				deployEditor(true, true, flow);
				return;
			} catch (e) {
				console.error('Unable to deploy through RED object', e);
			}

			// updateFlow(workspaceUrl, token, flow);
		};

		if (editMode) {
			exitEditMode(workspaceId, updateFlowFn);
		} else {
			enterEditMode();
		}
	}, [editMode, workspaceUrl, token, completeFlow, fullScreen]);

	const handleExpand = useCallback(() => {
		addStep('workspace.dashboard', { workspaceId: workspaceId });
		openStepper();
	}, []);

	const handleNotificationEvent = useCallback((event) => {
		const { title, description, status, position } = event?.data;
		toast({
			title,
			description,
			status: status,
			position: position,
			duration: 3000,
			isClosable: true,
		});
	}, []);

	/**
	 * Setting up the websocket to send and recieve UI events from the runtime.
	 */
	useEffect(() => {
		const socket = io(workspaceUrl, { path: '/dashsock/socket.io' });
		setSocket(socket);
		socket.on('connect', () => {
			setSockConnected(true);
		});
		socket.on('disconnect', () => {
			setSockConnected(false);
		});
		socket.on('dashboardDataUpdate', (updateData) => {
			try {
				console.error(updateData);
				const { componentType, componentId, event } = updateData;
				switch (componentType) {
					case 'TABLE':
						return handleTableEvent(workspaceId, componentId, event);
					case 'TEMPLATE':
						return handleTemplateEvent(workspaceId, componentId, event);
					case 'FORM':
						return handleFormEvent(workspaceId, componentId, event);
					case 'NOTIFICATION':
						return handleNotificationEvent(event);
					case 'RICHTEXT':
						return handleRichTextEditorEvent(
							workspaceId,
							componentId,
							event
						);
					case 'IMAGE':
						return handleImageEvent(workspaceId, componentId, event);
					case 'BUTTON':
						return handleButtonEvent(componentId, event);
					default:
						return;
				}
			} catch (e) {
				// Error boundary. Don't want bad events from runtime to crash the app.
				console.log('Unable to interpret dashboard-update event:', e);
			}
		});

		return () => {
			setSocket(null);
			socket.off('connect');
			socket.off('disconnect');
			socket.disconnect();
		};
	}, [workspaceUrl]);

	/**
	 * Constructing the layout object from the flow info, for react-grid-layout.
	 * Try to get positioning info from node, or set it to a default.
	 */
	useEffect(() => {
		const layout = { lg: [], md: [], sm: [], xs: [], xxs: [] };
		dashboardFlow.forEach((node) => {
			let pos = defaultLayout;
			if (isValidLayout(node.positionDetails)) {
				pos = JSON.parse(node.positionDetails);
			}

			Object.keys(layout).forEach((breakpoint) => {
				if (pos[breakpoint]) {
					layout[breakpoint].push({ i: node.id, ...pos[breakpoint] });
				} else {
					layout[breakpoint].push({ i: node.id, ...defaultPosition });
				}
			});
		});

		setLayout(workspaceId, layout);
		// setTempLayout(layout)
	}, [completeFlow]);

	/***********************************************************************
	 * UI action functions
	 */

	const handleDashboardButtonClick = useCallback(
		(buttonId) => {
			if (!socket) {
				return;
			}
			socket.emit('uiEvent', {
				componentId: buttonId,
				componentType: 'BUTTON',
				event: { type: 'click' },
			});
		},
		[socket]
	);

	const handleTableRowSelectToggle = useCallback(
		(tableId, rowIdentifier, selected) => {
			if (!socket) {
				return;
			}

			socket.emit('uiEvent', {
				componentId: tableId,
				event: {
					type: 'rowSelect',
					componentType: 'TABLE',
					rowIdentifier: rowIdentifier,
					selected: selected,
				},
			});
		},
		[socket]
	);

	const handleTableRowClick = useCallback(
		(tableId, action, row) => {
			if (!socket) {
				return;
			}

			socket.emit('uiEvent', {
				componentId: tableId,
				event: {
					type: 'rowClick',
					componentType: 'TABLE',
					action,
					rowData: row,
				},
			});
		},
		[socket]
	);

	const handleFormSubmit = useCallback(
		(formId, formData) => {
			if (!socket) {
				return;
			}

			socket.emit('uiEvent', {
				componentId: formId,
				event: {
					type: 'formSubmit',
					componentType: 'FORM',
					formData: formData,
				},
			});
		},
		[socket]
	);

	const handleRichTextEditorContentChange = useCallback(
		(editorId, newValue) => {
			if (!socket) {
				return;
			}

			socket.emit('uiEvent', {
				componentId: editorId,
				event: {
					type: 'bodyChange',
					componentType: 'RICHTEXT',
					body: newValue.body,
					format: newValue.format,
				},
			});
		},
		[socket]
	);

	const handleRichTextEditorActionButtonClick = useCallback(
		(componentId) => {
			if (!socket) {
				return;
			}

			const body =
				workspaceDashboardStore.getState().dashboardData[workspaceId][
					componentId
				]?.body || '';
			socket.emit('uiEvent', {
				componentId: componentId,
				event: {
					type: 'actionButtonClick',
					componentType: 'RICHTEXT',
					body: body,
					format: 'html',
				},
			});
		},
		[socket]
	);

	const handleTemplateActionButtonClick = useCallback(
		(templateId) => {
			if (!socket) {
				return;
			}

			socket.emit('uiEvent', {
				componentId: templateId,
				event: {
					type: 'actionButtonClick',
					componentType: 'TEMPLATE',
				},
			});
		},
		[socket]
	);

	/**
	 *
	 ***********************************************************************/

	const comps = Object.keys(dashboardUiMap).map((key, i) => {
		const node = dashboardUiMap[key]?.groupNode;
		const childNodes = dashboardUiMap[key].childNodes;

		if (!node) {
			return null;
		}

		if (childNodes.length <= 0) {
			return null;
		}

		let pos = defaultPosition;
		if (node.positionDetails) {
			try {
				const parsedPos = JSON.parse(node.positionDetails);
				if (parsedPos.x >= 0 && parsedPos.y >= 0) {
					pos = parsedPos;
				}
			} catch (e) {}
		}

		const headerCompNodes = [];
		const footerCompNodes = [];
		const contentCompNodes = [];

		childNodes.forEach((dNode) => {
			console.log('dnodet', dNode.type);
			let comp = null;
			if (dNode.type === 'dashboard-table') {
				comp = (
					<Table
						workspaceId={workspaceId}
						width={parseInt(dNode.width)}
						componentId={`table:${dNode.id}`}
						handleTableRowClick={(action, row) =>
							handleTableRowClick(`table:${dNode.id}`, action, row)
						}
						truncateAfter={parseInt(dNode.truncateAfter)}
						handleTableRowSelectToggle={(rowIdentifier, selected) =>
							handleTableRowSelectToggle(
								`table:${dNode.id}`,
								rowIdentifier,
								selected
							)
						}
						actionButtonLabel={dNode.actionButtonLabel || ''}
					/>
				);
			} else if (dNode.type === 'dashboard-button') {
				comp = (
					<Button
						componentId={`button:${dNode.id}`}
						width={parseInt(dNode.width)}
						color={dNode.color}
						label={dNode.label}
						variant={dNode.style}
						onClick={() =>
							handleDashboardButtonClick(`button:${dNode.id}`)
						}
						tooltip={dNode.tooltip}
						debounce={parseInt(dNode.debounce)}
					/>
				);
			} else if (dNode.type === 'dashboard-template') {
				comp = (
					<Template
						workspaceId={workspaceId}
						width={parseInt(dNode.width)}
						componentId={`template:${dNode.id}`}
						title={dNode.title}
					/>
				);

				if (dNode.actionButtonText) {
					const relatedButton = (
						<Tooltip
							label={dNode.actionButtonText}
							openDelay={500}
							hasArrow
							placement="top"
						>
							<GridItem colSpan={3}>
								<ChakraButton
									onClick={() =>
										handleTemplateActionButtonClick(
											`template:${dNode.id}`
										)
									}
									borderRadius="3px"
									textStyle="sans.sm"
									fontWeight="500 !important"
									fontSize="16px !important"
								>
									<p
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											direction: 'ltr',
											marginTop: 'auto',
											marginBottom: 'auto',
										}}
									>
										{dNode.actionButtonText}
									</p>
								</ChakraButton>
							</GridItem>
						</Tooltip>
					);

					footerCompNodes.push(relatedButton);
				}
			} else if (dNode.type === 'dashboard-form') {
				let config = {};
				try {
					config = JSON.parse(dNode.config);
				} catch (e) {
					comp = <Box>Invalid form config</Box>;
				}
				comp = (
					<Form
						workspaceId={workspaceId}
						width={parseInt(dNode.width)}
						formConfig={config}
						submitButtonLabel={dNode.submitButtonLabel}
						cancelButtonLabel={dNode.cancelButtonLabel}
						componentId={`form:${dNode.id}`}
						handleSubmit={(formData) =>
							handleFormSubmit(`form:${dNode.id}`, formData)
						}
					/>
				);
			} else if (dNode.type === 'dashboard-richtext') {
				comp = (
					<RichTextEditor
						workspaceId={workspaceId}
						width={parseInt(dNode.width)}
						componentId={`richtext:${dNode.id}`}
						handleValChange={(val) =>
							handleRichTextEditorContentChange(
								`richtext:${dNode.id}`,
								val
							)
						}
						debounceBy={parseInt(dNode.debounceBy)}
					/>
				);

				if (dNode.actionButtonText) {
					const relatedButton = (
						<Tooltip
							label={dNode.actionButtonText}
							openDelay={500}
							hasArrow
							placement="top"
						>
							<GridItem colSpan={3}>
								<ChakraButton
									onClick={() =>
										handleRichTextEditorActionButtonClick(
											`richtext:${dNode.id}`
										)
									}
									width="100%"
									borderRadius="3px"
									textStyle="sans.sm"
									fontWeight="500 !important"
									fontSize="16px !important"
									colorScheme="button.primary"
								>
									<p
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											direction: 'ltr',
											marginTop: 'auto',
											marginBottom: 'auto',
										}}
									>
										{dNode.actionButtonText}
									</p>
								</ChakraButton>
							</GridItem>
						</Tooltip>
					);

					footerCompNodes.push(relatedButton);
				}
			} else if (dNode.type === 'dashboard-image') {
				comp = (
					<Image
						workspaceId={workspaceId}
						width={parseInt(dNode.width)}
						componentId={`image:${dNode.id}`}
					/>
				);
			}

			switch (dNode.placement) {
				case 'header':
					return headerCompNodes.push(comp);
				case 'footer':
					return footerCompNodes.push(comp);
				default:
					return contentCompNodes.push(comp);
			}
		});

		return (
			<Box
				key={node.id}
				height="100%"
				width="100%"
				display="flex"
				flexDir="column"
			>
				<Group
					width={parseInt(node.width)}
					title={node.title || ''}
					gkey={`${i}`}
				>
					<GroupTitle>{node.title}</GroupTitle>
					<GroupHeader>{headerCompNodes}</GroupHeader>
					<GroupContent width={parseInt(node.width)}>
						{contentCompNodes}
					</GroupContent>
					<GroupFooter>{footerCompNodes}</GroupFooter>
				</Group>
			</Box>
		);
	});

	return (
		<Flex
			direction="column"
			height="100%"
			mt={expanded ? '0px' : '24px'}
			// mt='24px'
			pb="16px"
		>
			<Flex
				justifyContent="space-between"
				alignItems="center"
				pl={fullScreen ? '54px' : '28px'}
				pr={fullScreen ? '54px' : '16px'}
				mb="12px"
			>
				<Flex
					textStyle="sans.sm"
					color="light.font.gray.300"
					alignItems="center"
				>
					<Circle
						size="10px"
						bg={sockConnected ? 'green.500' : 'gray.400'}
						mr="8px"
					/>
					<Box>{sockConnected ? 'Connected' : 'Disconnected'}</Box>
				</Flex>
				<Flex
				// alignItems='center'
				>
					{!fullScreen && !expanded && (
						<Box mr="4px">
							<ChakraButton
								fontSize="14px"
								as="a"
								href={`/workers/ui?id=${workspaceId}&version=2`}
								target="_blank"
								variant="ghost"
								size="xs"
								colorScheme="blackAlpha"
								borderRadius="2px"
								textStyle="sans.sm"
								color="#0000007A !important"
								fontWeight="400"
							>
								Open in tab
							</ChakraButton>
						</Box>
					)}

					{!(expanded || fullScreen) && (
						<Box mr="4px">
							<ChakraButton
								onClick={handleExpand}
								fontWeight="500"
								variant="ghost"
								size="xs"
								colorScheme="blackAlpha"
								borderRadius="2px"
								textStyle="sans.sm"
							>
								Expand
							</ChakraButton>
						</Box>
					)}

					<ChakraButton
						onClick={handleEditModeToggle}
						fontSize="14px"
						// textStyle='sans.sm'
						fontWeight="400"
						variant="ghost"
						size="xs"
						colorScheme="blackAlpha"
						borderRadius="2px"
						textStyle="sans.sm"
					>
						{editMode ? 'Save' : 'Edit'}
					</ChakraButton>
				</Flex>
			</Flex>
			<Box flex="1 1 auto">
				<Flex height="100%" position="relative" overflow="auto">
					<Flex
						width="100%"
						flexDirection="column"
						position="absolute"
						px={fullScreen ? '40px' : undefined}
					>
						<ResponsiveGridLayout
							className="layout"
							layouts={layout}
							breakpoints={{
								lg: 1200,
								md: 996,
								sm: 768,
								xs: 480,
								xxs: 0,
							}}
							cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
							measureBeforeMount={true} // No init animation
							isResizable={editMode}
							isDraggable={editMode}
							margin={[16, 16]}
							rowHeight={20}
							onLayoutChange={handleLayoutChange}
						>
							{comps}
						</ResponsiveGridLayout>
					</Flex>
				</Flex>
			</Box>
		</Flex>
	);
};
