import React, { useEffect } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import MayaInput from '../../../../../library/input';
import { RepeatIcon, ArrowForwardIcon } from '@chakra-ui/icons';
// import MayaFilledButton from '../../../../../library/buttons/FilledButton';
// import MayaFilledButton from '@mayahq/ui'
import SelectDevice from '../../../../Dashboard/Device';
import randomDefaultBrainName from '../../../../../util/RandomDefaults/newbrain';
import isElectron from 'is-electron';
import DownloadSection from './DownloadApp';
import { MayaFilledButton } from '@mayahq/ui';

const WAIT_MSG_DISPLAY_TIMEOUT = 30000;

const CloudOrDesktopOption = ({ selected, text, onClickFn }) => {
	return (
		<Box
			width="49%"
			height="45px"
			textStyle="sans.xs"
			bg={selected ? 'light.theme.gray.500' : 'white'}
			textAlign="center"
			border="solid 1px"
			borderColor={
				selected ? 'light.theme.gray.500' : 'light.border.gray.200'
			}
			display="flex"
			alignItems="center"
			justifyContent="center"
			mr="2"
			color={selected ? 'white' : 'light.font.gray.400'}
			onClick={onClickFn}
			cursor="pointer"
		>
			{text}
		</Box>
	);
};

const WorkspaceElement = ({
	state,
	setPlatform,
	setDevice,
	setName,
	createWorkspace,
	isLoading,
}) => {
	const [tempName, setTempName] = React.useState(randomDefaultBrainName());
	const [trigger, setTrigger] = React.useState(false);
	const [showWaitMessage, setShowWaitMessage] = React.useState(false);
	React.useEffect(() => {
		var name = randomDefaultBrainName();
		setTempName(name);
		setName(name);
	}, [trigger]);

	const shuffleName = () => {
		setTrigger(!trigger);
	};

	useEffect(() => {
		if (!isLoading) {
			return setShowWaitMessage(false);
		}

		setTimeout(() => {
			setShowWaitMessage(true);
		}, WAIT_MSG_DISPLAY_TIMEOUT);
	}, [isLoading]);

	return (
		<Box width="450px" mt="20px">
			<Box display="flex" width="100%">
				<CloudOrDesktopOption
					text="On Cloud"
					selected={state.platformOption === 'On Cloud' ? true : false}
					onClickFn={() => {
						setPlatform('On Cloud');
					}}
				/>
				<CloudOrDesktopOption
					selected={state.platformOption === 'On Desktop' ? true : false}
					text="On Desktop"
					onClickFn={() => {
						setPlatform('On Desktop');
					}}
				/>
			</Box>
			{!isElectron() && state.platformOption === 'On Desktop' ? (
				<DownloadSection />
			) : (
				<>
					<Box>
						<Box mt="16px">
							<Box
								textStyle="sans.sm"
								mb="10px"
								color="light.font.gray.400"
							>
								Enter workspace name
							</Box>
							<MayaInput
								placeholder={tempName}
								icon={
									<RepeatIcon
										color="light.border.gray.400"
										cursor="pointer"
									/>
								}
								onIconClick={() => {
									shuffleName();
								}}
								iconPointerEvents={'auto'}
								onChange={(e) => {
									setName(e.currentTarget.value);
								}}
								onKeyDown={(e) => {
									if (e.key === 'Enter' && !isLoading) {
										createWorkspace();
									}
								}}
							/>
						</Box>
						<Box mt="10px">
							<Box
								textStyle="sans.sm"
								mb="10px"
								color="light.font.gray.400"
							>
								{state.platformOption === 'On Cloud'
									? 'Device'
									: 'Select your OS '}
							</Box>
							<SelectDevice
								onChange={(deviceItem) => setDevice(deviceItem)}
								platformOption={state.platformOption}
							/>
						</Box>
						<Box
							height="40px" // temp hack to deal with whatever the hell is up with the button component
							mt="16px"
						>
							<MayaFilledButton
								text={
									state.platformOption === 'On Cloud' || isElectron()
										? 'Continue'
										: 'Download App'
								}
								colorScheme="gray"
								// colorScheme="purple"
								rightIcon={<ArrowForwardIcon />}
								onClick={createWorkspace}
								isLoading={isLoading}
								isDisabled={isLoading}
								size="sm"
							/>
						</Box>
					</Box>
					<Box
						textStyle="sans.xs"
						mb="10px"
						color="light.font.gray.400"
						mt="10px"
						width="100%"
						opacity={'0.8'}
					>
						{showWaitMessage && (
							<Box>
								Sometimes this takes a while. In the meantime, feel free
								to explore the
								<a
									href="/store"
									target="_blank"
									style={{
										textDecoration: 'underline',
										color: '#4A304B',
										marginLeft: '4px',
										marginRight: '4px',
									}}
								>
									store
								</a>
								or the
								<a
									href="https://mayalabs.io/docs"
									target="_blank"
									style={{
										textDecoration: 'underline',
										color: '#4A304B',
										marginLeft: '4px',
									}}
								>
									docs
								</a>
								.
							</Box>
						)}
						{/* {state.platformOption === 'On Cloud'
								? 'The first one might take a few minutes to create.'
								: 'Best for tasks that need to be manually run. '} */}
					</Box>
				</>
			)}
		</Box>
	);
};

export default WorkspaceElement;
