const componentPriority = {
    'dashboard-button': 1,
    'dashboard-form': 2,
    'dashboard-table': 3,
    'dashboard-image': 4 
}

export function compSortFn(dNode1, dNode2) {
    if (componentPriority[dNode1.type] < componentPriority[dNode2.type]) {
        return -1
    } else if (componentPriority[dNode1.type] > componentPriority[dNode2.type]) {
        return 1
    }

    return dNode1?.id?.localeCompare(dNode2?.id)
}