import APIFunction from '../../util/APIFetch/function';

import APIRoutes from '../../util/APIRoutes';

// config
import { config } from '../../config';

const updateChecklistItem = async ({
	profileSlug,
    itemName,
    done
}) => {
	// Create an instance of your worker
	var updateChecklistItemFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.updateChecklistItem.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.updateChecklistItem.PATH_METHOD,
	});

    const formData = {
        profileSlug,
        itemName,
        done
    }

	var updateChecklistItemResult = await updateChecklistItemFn(formData);
	return updateChecklistItemResult['data'].results
};

export default updateChecklistItem;