// Dependency imports

// import worker from 'workerize-loader!../../../src/maya-service-worker'; // eslint-disable-line import/no-webpack-loader-syntax

import APIFunction from '../../util/APIFetch/function';

import APIRoutes from '../../util/APIRoutes';

// config
import { config } from '../../config';
/**
 * Function to get all profiles for a user
 */
const getTeamMemberProfiles = async ({
	profileSlug
}) => {
	// Create an instance of your worker
	var getTeamMemberProfilesFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.getTeamMembers.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.getTeamMembers.PATH_METHOD,
		// URL_PARAM: { profileSlug },
		PATH_QUERY: profileSlug
	});

	var getTeamMemberProfilesResponse = await getTeamMemberProfilesFn();
	return getTeamMemberProfilesResponse['data'].results;
};

export default getTeamMemberProfiles;
