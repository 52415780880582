import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
	Box,
	Flex,
	Input,
	InputGroup,
	InputLeftElement,
	Text,
} from '@chakra-ui/react';
import { InfoTag, MayaFilledButton, ModuleCard } from '@mayahq/ui';
import { truncate } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DiLinux } from 'react-icons/di';
import { ImAppleinc, ImGithub, ImNpm, ImWindows8 } from 'react-icons/im';
import { IoSearch } from 'react-icons/io5';
import getStoreModules, {
	getModuleById,
} from '../../../functions/store/getStoreModules';
import ActionBtn from '../../../library/buttons/ActionBtn';
import { getLongestTimeElapsedString } from '../../../util/time';
import NodeCard from '../../Dashboard/Sections/Store/ModulePage/ModuleDetails/NodeCard';
import useInstallModules from '../hooks/useInstallModules';
import { useStore } from '../zustand';
import InterstitialModuleInstallDrawer from './InterstitialModuleInstallDrawer';

const ModuleSourceIcon = (props) => {
	const { type } = props;
	if (type === 'git') {
		return <ImGithub {...props} />;
	} else {
		return <ImNpm {...props} />;
	}
};

const PlatformIcon = (props) => {
	const { platform } = props;
	switch (platform) {
		case 'macos':
			return <ImAppleinc size="16px" />;
		case 'windows':
			return <ImWindows8 />;
		case 'linux':
			return <DiLinux size="16px" />;
		default:
			return null;
	}
};

const ModuleSidebar = () => {
	const installedModules = useStore(
		useCallback((state) => state.installedModules, [])
	);
	const installedModulesNames = installedModules.map((m) => m.name);

	const { modulesToInstall, modulesInstalling, installModules } =
		useInstallModules();

	const isModuleInstallationDrawerVisible = useStore(
		(state) => state.isModuleInstallationDrawerVisible
	);

	const setIsModuleInstallationDrawerVisible = useStore(
		(state) => state.setIsModuleInstallationDrawerVisible
	);

	const [mode, setMode] = useState('search');
	const [value, setValue] = useState('');
	const [modules, setModules] = useState([]);
	const [userSelectedModule, setUserSelectedModule] = useState({});

	const containerRef = useRef(null);

	useEffect(() => {
		const getModules = async () => {
			const res = await getStoreModules({
				search: value ? value : undefined,
			});
			setModules((m) => res);
		};
		getModules();
	}, [value]);

	const handleChange = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setValue(e.target.value);
	};

	const onInstallClick = async (selectedModule) => {
		const module = {
			[selectedModule.packageName]: {
				moduleName: selectedModule.name,
				status: 'QUEUED',
				configProfileReferenceId: '',
				isInternalModule: true,
				privateModule: selectedModule,
				version: selectedModule.currentVersion,
				requiresConfig:
					selectedModule.userProfile && !!selectedModule.configurationType,
			},
		};
		await installModules(module);
	};

	let nodeCards = null;
	let versionDetails;
	const { currentVersion, versions } = userSelectedModule;
	if (versions) {
		versionDetails = versions.find((v) => v.version === currentVersion);
	}
	if (versionDetails) {
		nodeCards = versionDetails.nodes.map((node) => (
			<Box mt="8px">
				<NodeCard node={node} />
			</Box>
		));
	}

	return (
		<Box
			width="350px"
			zIndex="500"
			borderRight="2px solid"
			borderColor="light.border.gray.100"
			backgroundColor="#F7F6F3"
			maxHeight="95vh"
			overflow="scroll"
			marginTop="5px"
			sx={{
				'&::-webkit-scrollbar': {
					w: '0.1rem',
				},
				'&::-webkit-scrollbar-track': {
					w: '0.1rem',
				},
				'&::-webkit-scrollbar-thumb': {
					bg: 'light.borders.gray.300',
					borderRadius: '5px',
				},
			}}
			ref={containerRef}
		>
			{mode === 'install' ? (
				<Box marginLeft="15px" marginRight="15px">
					<ActionBtn
						text="BACK"
						icon={<ChevronLeftIcon />}
						onClick={() => {
							setMode('search');
							containerRef.current.scrollTop = 0;
						}}
					/>
					<Flex direction="column">
						<Box mt="8px">
							<Text textStyle="sans.md" fontWeight="500">
								{userSelectedModule.name}
							</Text>
						</Box>
						{installedModulesNames.includes(userSelectedModule.name) ? (
							<MayaFilledButton
								text={'Installed'}
								showDotPattern={false}
								size="sm"
								buttonProps={{
									mt: '4px',
									cursor: 'default !important',
								}}
								colorScheme="gray"
								isDisabled="true"
							/>
						) : (
							<MayaFilledButton
								text={'Install'}
								showDotPattern={false}
								size="sm"
								buttonProps={{
									mt: '4px',
								}}
								onClick={() => onInstallClick(userSelectedModule)}
							/>
						)}
						<Box mt="24px" mb="12px">
							<InfoTag label="Description">
								{userSelectedModule.description}
							</InfoTag>
						</Box>
						<Flex mt="8px" flexDirection="column" marginRight="10px">
							{nodeCards}
						</Flex>
						{/* Additional data section */}
						<Box>
							<Flex
								flexDirection="column"
								marginRight="100px"
								mt="24px"
								mb="12px"
							>
								{/* Platforms */}
								{userSelectedModule.platforms.length > 0 ? (
									<Flex
										justifyContent="space-between"
										alignItems="center"
									>
										<Box
											textStyle="sans.sm"
											color="light.font.gray.400"
										>
											Platforms
										</Box>{' '}
										<Flex alignItems="center" mt="4px">
											{userSelectedModule.platforms.map(
												(platform) => (
													<Box
														textStyle="sans.xs"
														color="light.font.gray.200"
														pl="1px"
													>
														<PlatformIcon
															platform={platform.value}
														/>
													</Box>
												)
											)}
										</Flex>
									</Flex>
								) : null}

								{/* Version */}
								<Flex
									justifyContent="space-between"
									alignItems="center"
								>
									<Box
										textStyle="sans.sm"
										color="light.font.gray.400"
										mt={2}
									>
										Version
									</Box>
									<Box
										textStyle="sans.xs"
										color="light.font.gray.200"
										pl="1px"
									>
										{' '}
										{userSelectedModule.currentVersion}
									</Box>
								</Flex>

								{/* Updated */}
								<Flex
									justifyContent="space-between"
									alignItems="center"
								>
									<Box
										textStyle="sans.sm"
										color="light.font.gray.400"
										mt={2}
									>
										Updated
									</Box>
									<Box
										textStyle="sans.xs"
										color="light.font.gray.200"
										pl="1px"
									>
										{getLongestTimeElapsedString(
											userSelectedModule.updatedAt
										)}{' '}
										ago
									</Box>
								</Flex>

								{/* Source */}
								<Flex
									justifyContent="space-between"
									alignItems="center"
								>
									<Box
										textStyle="sans.sm"
										color="light.font.gray.400"
										mt={2}
									>
										Source
									</Box>{' '}
									<Flex alignItems="center">
										<ModuleSourceIcon
											type={userSelectedModule.type}
										/>
										<Box
											ml="4px"
											transform="translateY(1px)"
											textStyle="sans.xs"
											color="light.font.gray.200"
											pl="1px"
										>
											{userSelectedModule.type === 'git'
												? 'Github'
												: 'npm'}
										</Box>
									</Flex>
								</Flex>
							</Flex>
						</Box>
						{/* </Box> */}
					</Flex>{' '}
				</Box>
			) : (
				<Box marginLeft="15px" marginRight="15px">
					<Box
						textStyle="sans.md"
						fontWeight="500"
						mt="8px"
						padding="8px 0px"
					>
						Install Modules
					</Box>
					<Box marginTop="10px">
						<InputGroup>
							<InputLeftElement
								pointerEvents="none"
								children={<IoSearch />}
							/>
							<Input
								width="100% !important"
								value={value}
								onKeyDown={(e) => e.stopPropagation()}
								onChange={handleChange}
								placeholder="Type to search"
								bg="#fefefe"
								border="1.5px solid #ddd !important"
								borderRadius="2px"
								_focus={{ boxShadow: 'none' }}
							/>
						</InputGroup>
					</Box>
					{modules.map((module) => {
						const description = truncate(module.description, {
							length: 50,
						});
						const modifiedModule = { ...module, description };
						return (
							<Box
								key={module.id}
								marginTop="10px"
								onClick={async () => {
									const res = await getModuleById(module.id);
									setUserSelectedModule(res);
									setMode('install');
									containerRef.current.scrollTop = 0;
								}}
							>
								<ModuleCard
									module={modifiedModule}
									containerProps={{
										height: '90px',
										_hover: { backgroundColor: '#eee' },
									}}
								/>
							</Box>
						);
					})}
				</Box>
			)}
			{modulesInstalling && (
				<InterstitialModuleInstallDrawer
					installState={modulesToInstall}
					isOpen={isModuleInstallationDrawerVisible}
					onClose={() => setIsModuleInstallationDrawerVisible(false)}
				/>
			)}
		</Box>
	);
};

export default React.memo(ModuleSidebar);
