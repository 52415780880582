import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { MayaSkeleton } from '../../skeleton';

const ModuleCardSkeleton = () => {
	return (
		<Flex
			borderRadius="2px"
			bg="#fefefe"
			border="1.5px solid #ddd"
			height="110px"
			alignItems="center"
			pt="0px"
			pr="16px"
			pb="0px"
			pl="4px"
			cursor="pointer"
		>
			<Flex alignItems="center">
				<MayaSkeleton width="80px" height="80px" />
			</Flex>
			<Box ml="8px">
				<Box textStyle={'sans.sm'} color="#585858" userSelect="none">
					<MayaSkeleton width="80px" height="30px" />
				</Box>
				<Box
					mt="4px"
					textStyle="sans.xs"
					color="#888888"
					display="-webkit-box"
					css={{
						'-webkit-box-orient': 'vertical',
						'-webkit-line-clamp': '2',
					}}
					overflow="hidden"
					minHeight="45px"
					userSelect="none"
				>
					<MayaSkeleton width="100px" height="20px" />
				</Box>
			</Box>
		</Flex>
	);
};

export default ModuleCardSkeleton;
