import React from 'react';
import { Img, Box, CircularProgress, Flex } from '@chakra-ui/react';
// @ts-ignore
import buttonDots from '../assets/buttonDots.svg';

/**
 * Dark Filled Button
 *
 * @param { import('./types/buttonProps').FilledButton } props
 * @returns
 */

const MayaFilledButton = (props) => {
	const {
		text,
		onClick = () => {},
		rightIcon,
		leftIcon,
		colorScheme,
		size,
		isLoading,
		isDisabled,
		buttonProps,
		showDotPattern = true,
		showDots = true,
	} = props;

	const sizes = {
		sm: {
			textStyle: 'sans.xs',
			height: '40px',
		},
		md: {
			textStyle: 'sans.md',
			height: '60px',
		},
	};

	const colorSchemes = {
		purple: 'light.theme.purple.300',
		gray: 'light.theme.gray.500',
		white: '#fff',
	};

	const colorSchemesOnHover = {
		purple: 'light.theme.purple.500',
		gray: 'light.theme.gray.600',
		white: 'light.theme.gray.200',
	};

	const fontColorSchemes = {
		purple: 'white',
		gray: 'white',
		white: 'light.theme.gray.500',
	};

	return (
		<Flex
			color={
				fontColorSchemes[colorScheme]
					? fontColorSchemes[colorScheme]
					: 'white'
			}
			bg={
				isDisabled
					? 'light.theme.gray.600'
					: colorSchemes[colorScheme]
					? colorSchemes[colorScheme]
					: 'light.theme.purple.300'
			}
			borderRadius="2px"
			textStyle={sizes[size] ? sizes[size].textStyle : 'sans.xs'}
			transition="all 0.02s cubic-bezier(.08,.52,.52,1)"
			_hover={{
				bg: isDisabled
					? 'light.theme.gray.600'
						? colorSchemes[colorScheme]
						: colorSchemes['purple']
					: colorSchemesOnHover[colorScheme]
					? colorSchemesOnHover[colorScheme]
					: 'light.theme.purple.500',
			}}
			onClick={(e) => {
				if (isDisabled) {
				} else {
					onClick(e);
				}
			}}
			position="relative"
			display="flex"
			opacity={isDisabled ? '0.8' : '1'}
			flexDirection="row"
			justifyContent="center"
			// justifyContent="space-between"
			height={sizes[size] ? sizes[size].height : '40px'}
			{...buttonProps}
			cursor="pointer"
			pl="24px"
			pr={isLoading || rightIcon ? '12px' : '24px'}
			// pr="12px"
			boxSizing="border-box"
			float="left"
			// bg='yellow'
			userSelect="none"
		>
			{/* DOTS */}
			{showDots ? (
				<Img
					position="absolute"
					src={buttonDots}
					top="0"
					left="0"
					margin="3px 0px 0px 2px"
					height="40%"
				/>
			) : null}

			{/* LEFT ICON */}
			{leftIcon ? (
				<Box
					height="100%"
					display="flex"
					flexDirection="row"
					justifyContent="center"
					alignItems="center"
					// paddingX="10px"
					ml="10px"
					// mr='10px'
				>
					{leftIcon}
				</Box>
			) : null}

			{/* BUTTON TEXT */}
			<Box
				height="100%"
				display="flex"
				flexDirection="row"
				justifyContent="center"
				alignItems="center"
				ml="10px"
				mr="10px"
			>
				{text}
			</Box>

			{/* BUTTON LOADING OR RIGHT ICON */}
			{isLoading ? (
				<Box
					height="100%"
					display="flex"
					flexDirection="row"
					justifyContent="center"
					alignItems="center"
					paddingLeft="5px"
					paddingRight="15px"
				>
					<CircularProgress
						isIndeterminate
						color="white"
						size="15px"
						trackColor="none"
						capIsRound
					/>
				</Box>
			) : rightIcon ? ( // Right icon
				<Box
					height="100%"
					display="flex"
					flexDirection="row"
					justifyContent="center"
					alignItems="center"
					paddingLeft="5px"
					// ml='10px'
					// paddingRight="15px"
				>
					{rightIcon}
				</Box>
			) : // <Box width="2px" />
			null}
		</Flex>
	);
};

export default MayaFilledButton;
