// Export the API.
const routes = {
	ipcService: {
		// Dashboard data
		// ELECTRON
		getDashboardData: {
			EVENT: 'getDashboardData',
		},
		// BRAINS
		getBrainFlows: {
			EVENT: 'getBrainFlows',
		},
		createBrain: {
			EVENT: 'createBrain',
		},
		deleteBrain: {
			EVENT: 'deleteBrain',
		},
		startBrain: {
			EVENT: 'startBrain',
		},
		stopBrain: {
			EVENT: 'stopBrain',
		},
		updateBrain: {
			EVENT: 'updateBrain',
		},
		updateAllBrains: {
			EVENT: 'updateAllBrains',
		},
		getUpdates: {
			EVENT: 'getUpdates',
		},
		getTokenIPC: {
			EVENT: 'getToken',
		},
		updateTokens: {
			EVENT: 'updateTokens',
		},
		getBrainById: {
			EVENT: 'getBrainById',
		},
		getCurrentBrainById: {
			EVENT: 'getCurrentBrainById',
		},
		getBrainsByPublishedSkillPack: {
			EVENT: 'getBrainsByPublishedSkillPack',
		},
		startInterruptedBrains: {
			EVENT: 'startInterruptedBrains',
		},
		checkStartupStatus: {
			EVENT: 'checkStartupStatus',
		},
		getLocale: {
			EVENT: 'getLocale',
		},
		getAppVersion: {
			EVENT: 'getAppVersion',
		},
		autoStartToggle: {
			EVENT: 'autoStartToggle',
		},
		resetDefaults: {
			EVENT: 'resetDefaults',
		},
		autoStartStatus: {
			EVENT: 'autoStartStatus',
		},
		deploySkill: {
			EVENT: 'deploySkill',
		},
		installModuleToBrain: {
			EVENT: 'installModuleToBrain',
		},
		uninstallModuleFromBrain: {
			EVENT: 'uninstallModuleFromBrain',
		},
		getInstalledModulesFromBrain: {
			EVENT: 'getInstalledModulesFromBrain',
		},
		installSkillPackToBrain: {
			EVENT: 'installSkillPackToBrain',
		},
		uninstallSkillPackFromBrain: {
			EVENT: 'uninstallSkillPackFromBrain',
		},
		updateSkillPackToBrain: {
			EVENT: 'updateSkillPackToBrain',
		},
		addSkillPacktoBrain: {
			EVENT: 'addSkillPacktoBrain',
		},
		deleteSkillPackFromBrains: {
			EVENT: 'deleteSkillPackFromBrains',
		},
		updateSkillPackVersion: {
			EVENT: 'updateSkillPackVersion',
		},
		installPublishedSkillPackToBrain: {
			EVENT: 'installPublishedSkillPackToBrain',
		},
		uninstallPublishedSkillPackFromBrain: {
			EVENT: 'uninstallPublishedSkillPackFromBrain',
		},
		updatePublishedSkillPackToBrain: {
			EVENT: 'updatePublishedSkillPackToBrain',
		},
		embedConfigProfileToRuntime: {
			EVENT: 'embedConfigProfileToRuntime',
		},
		saveConfigProfile: {
			EVENT: 'saveConfigProfile',
		},
		getAccountId: {
			EVENT: 'getAccountId',
		},
		revokeAndRemoveConfigProfile: {
			EVENT: 'revokeAndRemoveConfigProfile',
		},
		authenticateConfiguration: {
			EVENT: 'authenticateConfiguration',
		},
		refreshConfiguration: {
			EVENT: 'refreshConfiguration',
		},
		getDecodedAccessToken: {
			EVENT: 'getDecodedAccessToken',
		},
		filterConfigProfiles: {
			EVENT: 'filterConfigProfiles',
		},
		toggleCommandBar: {
			EVENT: 'toggleCommandBar',
		},
		navigateToMainWindowRoute: {
			EVENT: 'navigateToRoute',
		},
		toggleMouseClickThrough: {
			EVENT: 'toggleMouseClickThrough',
		},
		// UPDATES
		getBrainsForUpdates: {
			EVENT: 'getBrainsForUpdates',
		},
		setUpdates: {
			EVENT: 'setUpdates',
		},
		// Devices
		checkDevice: {
			EVENT: 'checkDevice',
		},
		registerDevice: {
			EVENT: 'registerDevice',
		},
		getDevices: {
			EVENT: 'getDevices',
		},
		getMachineDetails: {
			EVENT: 'getMachineDetails',
		},
		getMachineName: {
			EVENT: 'getMachineName',
		},
		setupCheck: {
			EVENT: '/setup/check',
		},
		toggleSetupStatus: {
			EVENT: 'toggleSetupStatus',
		},
		//CONTACTS
		authenticateContactService: {
			EVENT: 'authenticateContactService',
		},
		listContactServices: {
			EVENT: 'listContactServices',
		},
		syncContactService: {
			EVENT: 'syncContactService',
		},
		listContacts: {
			EVENT: 'listContacts',
		},
		getIconPng: {
			EVENT: 'getIconPng',
		},
		getFormattedFlow: {
			EVENT: 'getFormattedFlow',
		},
		getMayaJson: {
			EVENT: 'getMayaJson',
		},
		installExternalSkillpack: {
			EVENT: 'installExternalSkillpack',
		},
		useKeytar: {
			EVENT: 'useKeytar',
		},
		installExternalModuleToBrain: {
			EVENT: 'installExternalModuleToBrain',
		},
	},
};

export default routes;
