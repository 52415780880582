const { useSelector } = require('react-redux');
const { default: analytics } = require('.');

const useAnalytics = () => {
	const { selectedProfile, userProfile } = useSelector(
		/**
		 *
		 * @param {import('../../redux/reducers/types/profileReducer').ProfilesState} state
		 * @returns
		 */
		(state) => {
			const { profiles: allProfiles, selected, userSlug } = state.profiles;

			return {
				selectedProfile: allProfiles[selected],
				userProfile: allProfiles[userSlug],
			};
		}
	);

	const email = useSelector(
		/**
		 *
		 * @param {import('../../redux/reducers/types/userReducer').UserState} state
		 * @returns
		 */
		(state) => state?.user?.info?.email
	);

	/**
	 *
	 * @param {String} eventName Name (identifier) of the event
	 * @param {Object} info Additional info about the event
	 */
	const track = (eventName, info) => {
		if (email && selectedProfile && userProfile) {
			try {
				analytics.track(eventName, {
					profileSlug: selectedProfile.profileSlug,
					name: selectedProfile.name,
					userSlug: userProfile.profileSlug,
					userEmail: email,

					...info,
				});
			} catch (e) {
				console.log('Failed to track event');
			}
		}
	};

	return track;
};

export default useAnalytics;
