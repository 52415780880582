import { Box, Flex } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import useInstallModules from '../../hooks/useInstallModules';
import { useStore } from '../../zustand';
import ModuleConfigurationFlow from './ModuleConfigurationFlow';
import MissingFieldCard from './MissingFieldCard';
import MissingModuleCard from './MissingModuleCard';
import { cloneDeep, groupBy } from 'lodash';
import CardContainer from './CardContainer';
import useQueryParams from '../../../../util/useQueryParams';
import { useHistory, useLocation } from 'react-router-dom';

const IssuesSidebar = () => {
	const query = useQueryParams()
	const location = useLocation()
	const history = useHistory()

	const { modulesToInstall, modulesInstalling, installModules } =
		useInstallModules();

	const toggleIssuesSidebar = useStore(
		useCallback((state) => state.toggleIssuesSidebar, [])
	);
	const nodesWithIssues = useStore(
		useCallback((state) => state.nodesWithIssues, [])
	);
	const missingModules = useStore(
		useCallback((state) => state.missingModules, [])
	);
	const selectedMissingModule = useStore(
		useCallback((state) => state.selectedMissingModule, [])
	);
	const setSelectedMissingModule = useStore(
		useCallback((state) => state.setSelectedMissingModule, [])
	);

	const issuesSidebarIsOpen = useStore(
		useCallback((state) => state.issuesSidebarIsOpen, [])
	);
	const areModuleInstalling = useStore((state) => state.areModuleInstalling);
	const isModuleConfigured = useStore((state) => state.isModuleConfigured);
	const setIsModuleConfigured = useStore(
		(state) => state.setIsModuleConfigured
	);
	const getReactFlowByTab = useStore((state) => state.getReactFlowByTab);
	const tabId = useStore((state) => state.tabs.activeTab);
	const nodeDetails = useStore((state) => state.nodeConfigs.byType);

	const initialMode =
		missingModules.length + nodesWithIssues.length > missingModules.length ||
		missingModules.length + nodesWithIssues.length > nodesWithIssues.length
			? 'all'
			: missingModules.length > 0
			? 'modules'
			: 'nodes';

	const [mode, setMode] = useState(initialMode);

	useEffect(() => {
		if (!issuesSidebarIsOpen) {
			setMode(
				missingModules.length + nodesWithIssues.length >
					missingModules.length ||
					missingModules.length + nodesWithIssues.length >
						nodesWithIssues.length
					? 'all'
					: missingModules.length > 0
					? 'modules'
					: 'nodes'
			);
		}
	}, [missingModules.length, nodesWithIssues.length]);

	const [moduleRequiresConfig, setModuleRequiresConfig] = useState(false);

	useEffect(() => {
		const installModulesHandler = async () => {
			const selectedModule = cloneDeep(selectedMissingModule);
			await installModules(selectedModule);
		};
		if (
			Object.keys(selectedMissingModule).length > 0 &&
			!areModuleInstalling
		) {
			installModulesHandler();
		}
	}, [selectedMissingModule]);

	// for node grouping by module type
	const invalidNodesWithModuleInfo = nodesWithIssues.map((n) => {
		const nodeType = n.data.type;
		const nodeModule = nodeDetails[nodeType];
		// @ts-ignore
		return { ...n, nodeModule };
	});

	const invalidNodesGroupedByModules = groupBy(
		invalidNodesWithModuleInfo,
		(n) => n.nodeModule?.set.module
	);

	const validNodes = getReactFlowByTab({ tabId }).nodes.filter(
		(n) => n.data?.node?.valid
	);
	const validNodesWithModules = validNodes.map((n) => {
		const nodeType = n.data.type;
		const nodeModule = nodeDetails[nodeType];
		// @ts-ignore
		return { ...n, nodeModule };
	});

	const validNodesGroupedByModules = groupBy(
		validNodesWithModules,
		(n) => n.nodeModule?.set.module
	);
	// end

	function closeSidebar() {
		query.delete('showIssues')
		history.replace({
			pathname: location.pathname,
			search: query.toString()
		})
		toggleIssuesSidebar()
	}

	return (
		<Box
			width="350px"
			zIndex="500"
			borderLeft="2px solid"
			borderColor="light.border.gray.100"
			backgroundColor="#F7F6F3"
			paddingX="16px"
			maxHeight="95vh"
			overflow="scroll"
			sx={{
				'&::-webkit-scrollbar': {
					w: '0.1rem',
				},
				'&::-webkit-scrollbar-track': {
					w: '0.1rem',
				},
				'&::-webkit-scrollbar-thumb': {
					bg: 'light.borders.gray.300',
					borderRadius: '5px',
				},
			}}
		>
			<Box>
				{modulesInstalling &&
				moduleRequiresConfig &&
				!isModuleConfigured ? (
					<ModuleConfigurationFlow
						installState={modulesToInstall}
						setIsModuleConfigured={setIsModuleConfigured}
					/>
				) : (
					<Box>
						<Box color="#444">
							<Flex
								alignItems="center"
								justifyContent="space-between"
								textStyle="sans.sm"
								marginY="12px"
								pl="3px"
							>
								Status
								<Box
									onClick={closeSidebar}
									borderRadius="2px"
									cursor="pointer"
									_focus={{ boxShadow: 'none' }}
									_hover={{ backgroundColor: '#DFDDDC' }}
								>
									<IoCloseOutline size="22px" />
								</Box>
							</Flex>

							<Flex
								justifyContent="space-around"
								backgroundColor="#ffffff"
								color="#606060"
								border="0.5px solid #D3D3D3"
								borderRadius="1px"
								textStyle="sans.sm"
								fontSize={14}
								marginBottom="10px"
							>
								<Flex alignItems="center">
									<Box
										cursor="pointer"
										padding="6px 10px"
										color={mode === 'all' ? '#444' : '#606060'}
										transition="all 0.1s linear"
										onClick={() => setMode('all')}
									>
										All
									</Box>
									{nodesWithIssues.length + missingModules.length ? (
										<Flex
											borderRadius="9px"
											paddingX="4px"
											minWidth="18px"
											height="18px"
											alignItems="center"
											justifyContent="center"
											fontSize="10px"
											bg="light.theme.gray.400"
											color="#eee"
										>
											{nodesWithIssues.length +
												missingModules.length}
										</Flex>
									) : null}
								</Flex>
								<Flex alignItems="center">
									<Box
										cursor="pointer"
										padding="6px 10px"
										color={mode === 'nodes' ? '#444' : '#606060'}
										transition="all 0.1s linear"
										onClick={() => setMode('nodes')}
									>
										Nodes
									</Box>
									{nodesWithIssues.length ? (
										<Flex
											borderRadius="9px"
											paddingX="4px"
											minWidth="18px"
											height="18px"
											alignItems="center"
											justifyContent="center"
											fontSize="10px"
											bg="light.theme.gray.400"
											color="#eee"
										>
											{nodesWithIssues.length}
										</Flex>
									) : null}
								</Flex>
								<Flex alignItems="center">
									<Box
										cursor="pointer"
										padding="6px 10px"
										color={mode === 'modules' ? '#444' : '#606060'}
										transition="all 0.1s linear"
										onClick={() => setMode('modules')}
									>
										Modules
									</Box>
									{missingModules.length ? (
										<Flex
											borderRadius="9px"
											paddingX="4px"
											minWidth="18px"
											height="18px"
											alignItems="center"
											justifyContent="center"
											fontSize="10px"
											bg="light.theme.gray.400"
											color="#eee"
										>
											{missingModules.length}
										</Flex>
									) : null}
								</Flex>
							</Flex>
						</Box>
						{mode === 'all' ? (
							<Box>
								{nodesWithIssues.map((node) => {
									if (node.data.type !== 'subflowIO') {
										return (
											<MissingFieldCard node={node} key={node.id} />
										);
									} else return null;
								})}
								{missingModules.map((module) => {
									return (
										<MissingModuleCard
											key={module.packageName}
											module={module}
											selectedMissingModule={selectedMissingModule}
											setSelectedMissingModule={
												setSelectedMissingModule
											}
											setModuleRequiresConfig={
												setModuleRequiresConfig
											}
											areModuleInstalling={areModuleInstalling}
										/>
									);
								})}
							</Box>
						) : mode === 'nodes' ? (
							<Box>
								{Object.keys(invalidNodesGroupedByModules).map(
									(nodeModule) => {
										return (
											<CardContainer
												key={nodeModule}
												tabId={tabId}
												nodes={
													invalidNodesGroupedByModules[nodeModule]
												}
												validNode={
													validNodesGroupedByModules[
														nodeModule
													]?.[0]
												}
											/>
										);
									}
								)}
							</Box>
						) : (
							<Box>
								{missingModules.map((module) => {
									return (
										<MissingModuleCard
											key={module.packageName}
											module={module}
											selectedMissingModule={selectedMissingModule}
											setSelectedMissingModule={
												setSelectedMissingModule
											}
											setModuleRequiresConfig={
												setModuleRequiresConfig
											}
											areModuleInstalling={areModuleInstalling}
										/>
									);
								})}
							</Box>
						)}
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default IssuesSidebar;
