import React, { useCallback, useEffect, useState } from 'react';
import {
	Flex,
	Box,
	Spinner,
	BoxProps,
	Tooltip,
	FlexProps,
	Stack,
	Img,
	Circle,
	Spacer,
} from '@chakra-ui/react';
import { ChevronLeftIcon, CopyIcon } from '@chakra-ui/icons';
import { MayaActionButton, MayaFilledButton } from '../../buttons';
import CollectionSectionContext from './Context';
import { StoreSkillPack } from '../types/SkillPacks';
import DetailSection from './DetailsSection';
import FlowVisualizer from '../../FlowVisualizer';
import TagsSection from './TagsSection';

export interface CollectionSectionProps {
	/** collection */
	collection: any;
	/** Trig on back btn click */
	onBackButtonClick: () => void;
	/** Trig on install btn click */
	onInstallButtonClick?: () => void;
	/** Trig on flow json copy to clipboard */
	onCopyFlowClick?: (flow: any) => void;
	containerProps?: FlexProps;
	hasCopied: boolean;
	onTeachButtonClick: () => void;
}

/**
 * # CollectionSection
 *
 * Main component to render on the Collection Page
 */
export const CollectionSection = ({
	collection,
	onBackButtonClick,
	onInstallButtonClick,
	onCopyFlowClick,
	containerProps,
	hasCopied,
	onTeachButtonClick,
}: CollectionSectionProps) => {
	return (
		<CollectionSectionContext.Provider
			value={{
				collection,
				onBackButtonClick,
				onInstallButtonClick,
				onCopyFlowClick,
				hasCopied,
				onTeachButtonClick,
			}}
		>
			<Flex
				direction="column"
				flex="1"
				minWidth="0"
				h="full"
				{...containerProps}
			>
				<MayaActionButton
					text={'BACK'}
					icon={<ChevronLeftIcon />}
					onClick={onBackButtonClick}
				/>
				<Flex
					flex="1"
					direction="column"
					bg="light.theme.yellow.200"
					paddingTop="2"
				>
					<Box flex="1" w="full">
						<Flex justifyContent="space-between" flexWrap="wrap">
							<Flex>
								<Flex
									textStyle="sans.lg"
									fontSize={{
										sm: '3xl',
										md: '4xl',
									}}
									alignItems="center"
									color="light.font.gray.400"
									ml="2px"
								>
									{collection.title}
								</Flex>
								{collection.apps ? (
									<Stack
										direction="row"
										spacing="5px"
										alignItems="center"
										ml="6"
										display={{ sm: 'none', md: 'flex' }}
									>
										{/* {collection.apps.slice(0, 2).map((app: any) => (
											<Img
												key={app.id}
												src={app.thumbnail}
												alt={app.label + 'thumbnail'}
												w="6"
												h="6"
												borderRadius="full"
											/>
										))} */}
										{collection.apps.length > 2 ? (
											<Circle
												size="6"
												bg="#DDDDDD"
												color="#585858"
												fontSize="x-small"
											>
												{collection.apps.length - 2}+
											</Circle>
										) : null}
									</Stack>
								) : null}
							</Flex>
							<Flex
								alignItems="center"
								justifyContent="space-between"
								marginTop={{ sm: '20px', md: '0px' }}
							>
								<MayaFilledButton
									text="Teach"
									showDots={true}
									colorScheme="gray"
									size="sm"
									buttonProps={{
										width: { sm: '10rem', md: '180px' },
									}}
									patternProps={{
										top: '3px',
										left: '2px',
									}}
									onClick={onTeachButtonClick}
								/>
							</Flex>
						</Flex>
					</Box>
					<Flex
						direction={{ sm: 'column', md: 'row' }}
						py="5"
						pt={{ sm: '2rem', md: '3.5rem' }}
					>
						{collection?.previews?.length > 0 ? (
							<Flex
								width={{ sm: '100%', md: '50%' }}
								mr={{ sm: '0', md: '8' }}
								pb={{ sm: '5', md: '0' }}
								maxHeight="350px"
							>
								{collection.previews[0].type === 'embed' ? (
									<iframe
										width="100%"
										height="350px"
										allow="autoplay; picture-in-picture"
										frameBorder={0}
										src={collection.previews
											.map((previews: any) => previews.url)
											.join(',')}
									></iframe>
								) : null}
								{collection.previews[0].type === 'video' ? (
									<Box border="1px solid #e3dede">
										<video
											width="100%"
											autoPlay
											loop
											muted
											src={collection.previews
												.map((previews: any) => previews.url)
												.join(',')}
										></video>
									</Box>
								) : null}
								{collection.previews[0].type === 'gif' ? (
									<Img
										width="100%"
										alt="gif-preview"
										border="1px solid #e3dede"
										src={collection.previews
											.map((previews: any) => previews.url)
											.join(',')}
									/>
								) : null}
							</Flex>
						) : null}

						<Flex
							direction="column"
							width={{ sm: '100%', md: '50%' }}
							alignItems="start"
							justifyContent="start"
						>
							<DetailSection collection={collection} />
							{collection.categories ? (
								<TagsSection
									tags={collection.categories.map(
										(cat: any) => cat.label
									)}
									containerProps={{
										maxWidth: '920px',
										width: 'full',
										marginTop: '20px',
									}}
								/>
							) : null}
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</CollectionSectionContext.Provider>
	);
};
