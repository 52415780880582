// Dependency imports

// import worker from 'workerize-loader!../../../src/maya-service-worker'; // eslint-disable-line import/no-webpack-loader-syntax

import APIFunction from '../../util/APIFetch/function';

import APIRoutes from '../../util/APIRoutes';

// config
import { config } from '../../config';
/**
 * Function to get all profiles for a user
 */
const setProfileRegion = async ({
	profileSlug,
    region,
}) => {
	// Create an instance of your worker
	var setProfileRegionFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.setProfileRegion.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.setProfileRegion.PATH_METHOD,
	});

    const formData = {
        profileSlug,
        region
    }

	var setProfileRegionData = await setProfileRegionFn(formData);
	return setProfileRegionData
};

export default setProfileRegion;