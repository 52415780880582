import { Flex } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useStore } from '../../zustand';
import AddTabButton from '../Tab/AddTabButton';
import TabButton from '../Tab/TabButton';

const Tabs = () => {
	const allTabs = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.tabs.byId,
			[]
		)
	);
	const allTabIds = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.tabs.allIds,
			[]
		)
	);
	const tabIds = useMemo(() => {
		return allTabIds.filter(
			(id) =>
				allTabs[id].type === 'tab' ||
				(allTabs[id].type === 'subflow' && allTabs[id].editing)
		);
	}, [allTabIds, allTabs]);

	const onTabAdd = useStore(useCallback((state) => state.onTabAdd, []));
	const tabsContainerRef = useRef(null);

	useEffect(() => {
		const workspace = document.getElementById('red-ui-workspace');
		const resizeTabContainer = () => {
			if (workspace && tabsContainerRef.current) {
				tabsContainerRef.current.setAttribute(
					'style',
					`width: ${workspace.clientWidth}px`
				);
			}
		};
		const observer = new ResizeObserver(resizeTabContainer);
		observer.observe(workspace);

		return () => {
			observer.unobserve(workspace);
		};
	}, []);

	return (
		// <Flex ref={tabsContainerRef} h="full" ml="8.7rem">
		<Flex ref={tabsContainerRef} h="full" ml="11.8rem">
			{/* <AddTabButton addTab={onTabAdd} /> */}
			<Flex
				flex="1"
				minWidth="0"
				border="none"
				h="full"
				overflowX="auto"
				overflowY="hidden"
				sx={{
					'&::-webkit-scrollbar': {
						height: '0.2rem',
					},
					'&::-webkit-scrollbar-track': {
						height: '0.2rem',
					},
					'&::-webkit-scrollbar-thumb': {
						background: 'light.borders.gray.300',
						borderRadius: '5px',
					},
				}}
			>
				{tabIds.length > 0
					? tabIds.map((tabId) => <TabButton key={tabId} tabId={tabId} />)
					: null}
			</Flex>
		</Flex>
	);
};

export default Tabs;
