export function getSlugFromLocalStorage() {
    const slug = localStorage.getItem('mayaProfileSlug')
    if (!slug) return null
    return slug
}

/**
 * 
 * @param {String} slug Slug value to set
 */
export function setSlugInLocalStorage(slug) {
    localStorage.setItem('mayaProfileSlug', slug)
}