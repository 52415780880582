import { DownloadIcon } from '@chakra-ui/icons';
import {
	Box,
	createStandaloneToast,
	Flex,
	Image,
	Spinner,
	Text,
} from '@chakra-ui/react';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { VscExtensions } from 'react-icons/vsc';
import theme from '../../../../library/theme';

const MissingModuleCard = ({
	module,
	selectedMissingModule,
	setSelectedMissingModule,
	setModuleRequiresConfig,
	areModuleInstalling,
}) => {
	const [installationStatus, setInstallationStatus] = useState('false');
	const toast = createStandaloneToast({ theme: theme });

	useEffect(() => {
		const moduleDetails = {
			[module.packageName]: {
				...module,
			},
		};

		if (isEqual(selectedMissingModule, moduleDetails)) {
			setInstallationStatus(module.status);
		}
	}, []);

	const onMissingModuleClick = async (module) => {
		if (!areModuleInstalling) {
			const moduleDetails = {
				[module.packageName]: {
					...module,
				},
			};

			if (module.requiresConfig) {
				setModuleRequiresConfig(true);
			}

			setInstallationStatus(module.status);
			setSelectedMissingModule(moduleDetails);
		} else {
			toast({
				title: 'Installation in Progress!',
				description: `Please wait for the current module to finish installing.`,
				status: 'info',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	return (
		<Flex
			justifyContent="space-between"
			backgroundColor="#ffffff"
			minHeight="70px"
			maxHeight="70px"
			alignItems="center"
			border="1px solid #DEDCDC"
			borderRadius="2px"
			padding="1rem"
			marginBottom="10px"
			cursor="pointer"
			onClick={() => onMissingModuleClick(module)}
		>
			<Flex alignItems="center">
				<Flex shrink={1}>
					{module?.privateModule?.thumbnail ? (
						<Image width="30px" src={module.privateModule.thumbnail} />
					) : (
						<VscExtensions size="30px" color="#585858" />
					)}
				</Flex>
				<Box marginX="10px">
					<Text
						textStyle="sans.sm"
						noOfLines={1}
						marginBottom="0 !important"
					>
						{module.moduleName}
					</Text>
					<Flex alignItems="center">
						<Text
							color="#939393"
							fontSize={12}
							fontWeight="medium"
							marginBottom="0 !important"
						>
							v{module.version}
						</Text>
					</Flex>
				</Box>
			</Flex>
			<Flex shrink={1}>
				{installationStatus === 'QUEUED' ? (
					<Spinner color="#777575" size="sm" ml="6px" />
				) : (
					<DownloadIcon w="22px" color="#3a3a3a" />
				)}
			</Flex>
		</Flex>
	);
};

export default MissingModuleCard;
