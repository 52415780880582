import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Tag } from './Tag';
import {
	Box,
	Flex,
	Circle,
	Img,
	Spinner,
	useToast,
	useDisclosure,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
} from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/react';
import { useContextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';

// Icons
import {
	AiFillApple,
	AiFillWindows,
	AiFillCloud,
	AiOutlineLaptop,
} from 'react-icons/ai';
import { DiLinux } from 'react-icons/di';
import { FiPlay, FiPause, FiExternalLink, FiClock } from 'react-icons/fi';
import { GoKebabVertical } from 'react-icons/go';
import { BiPlay, BiPause, BiTrash } from 'react-icons/bi';

// @ts-ignore
import WorkspaceImage from './sampleImages/workspaceImage.png';
import theme from '../../../../../../library/theme';

import getRandomThumbnail from './sampleImages';

import { useDispatch } from 'react-redux';

// Workspace functions
import startWorkspace from '../../../../../../functions/brain/startBrain';
import stopWorkspace from '../../../../../../functions/brain/stopBrain';
import updateWorkspace from '../../../../../../functions/brain/updateBrain';
import deleteWorkspace from '../../../../../../functions/brain/deleteBrain';
import resetBrain from '../../../../../../functions/brain/resetBrain';
import { addTab } from '../../../../../../redux/actions';
import { HistoryWithSlug } from '../../../../../../util/History';
import { getLongestTimeElapsedString } from '../../../../../../util/time';
// Redux
import WorkspaceDetailsDrawer from './WorkspaceDetailsDrawer';
import WorkspaceContextMenu from './WorkspaceContextMenu';
import {
	removeBrain,
	updateBrainById,
	closeTab,
} from '../../../../../../redux/actions';
//Zustand
import { useTabStore } from '../../../../../Electron/Tabs/zustand';
import useAnalytics from '../../../../../../util/Analytics/useAnalytics';

// Hooks
import useEditableText from './Hooks/useEditableText';
import useProfileSlug from '../../../../../../hooks/useProfileSlug';
import { MayaFilledButton } from '@mayahq/ui';
import clearSession from '../../../../../../functions/pac-engine/clearSession';

function getStatusColor(workspaceStatus) {
	// console.log('workspace status', workspaceStatus);
	switch (workspaceStatus) {
		case 'PENDING':
			return 'orange.400';
		case 'STARTED':
			return 'green.400';
		case 'CRASHED':
			return 'grey';
		case 'STOPPED':
			return 'red.400';
		default:
			return 'red.400';
	}
}

const PlatformIcon = ({ platform }) => {
	switch (platform) {
		case 'darwin':
			return <AiFillApple size="20px" />;
		case 'win32':
			return <AiFillWindows />;
		case 'cloud':
			return <AiFillCloud />;
		case 'linux':
			return <DiLinux />;
		default:
			return <AiOutlineLaptop size="20px" />;
	}
};

/**
 * Workspace Card Definition
 *
 * @param { import('../../types/card').WorkspaceCard } props
 * @returns
 */
export const WorkspaceCard = ({
	workspace,
	isSelected,
	selectWorkspace,
	onDelete,
	cardHeight = '220px',
	index,
}) => {
	const [mouseHovering, setMouseHovering] = useState(false);
	const [loading, setLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const optionsButtonRef = useRef(null);
	const { show } = useContextMenu({ id: workspace._id });
	const toast = createStandaloneToast({ theme: theme });
	const dispatch = useDispatch();
	const {
		isOpen: isDetailsDrawerOpen,
		onOpen: onDetailsDrawerOpen,
		onClose: onDetailsDrawerClose,
	} = useDisclosure();

	// For the delete confirmation popover control
	const {
		isOpen: isDeleteConfirmationOpen,
		onOpen: onDeleteConfirmationOpen,
		onClose: onDeleteConfirmationClose,
	} = useDisclosure();

	const { addTab } = useTabStore((state) => {
		return {
			addTab: state.addTab,
		};
	});
	const track = useAnalytics();
	const slug = useProfileSlug();

	function handleStart() {
		setLoading(true);
		startWorkspace({
			brain: workspace,
			slug,
			onDone: () => {
				setLoading(false);
				toast({
					title: 'Success!',
					description: 'The Worker has been started.',
					status: 'warning',
					duration: 3000,
					isClosable: true,
				});
				dispatch(
					updateBrainById(workspace._id, {
						...workspace,
						status: 'STARTED',
					})
				);
			},
			onError: () => {
				setLoading(false);
				toast({
					title: 'Error!',
					description: 'This Worker could not be started.',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			},
		});

		track('[Workspace Card] Start Workspace', {
			workspaceId: workspace._id,
			workspaceDeviceName: workspace?.device?.name,
			workspacePlatform: workspace?.device?.platform,
			workspaceName: workspace.name,
		});
	}

	function handleStop() {
		setLoading(true);
		stopWorkspace({
			brain: workspace,
			slug,
			onDone: () => {
				setLoading(false);				
				toast({
					title: 'Success!',
					description: 'The Worker has been stopped.',
					status: 'warning',
					duration: 3000,
					isClosable: true,
				});
				dispatch(
					updateBrainById(workspace._id, {
						...workspace,
						status: 'STOPPED',
					})
				);
			},
			onError: () => {
				setLoading(false);
				toast({
					title: 'Error!',
					description: 'This Worker could not be stopped.',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			},
		});

		track('[Workspace Card] Stop Workspace', {
			workspaceId: workspace._id,
			workspaceDeviceName: workspace?.device?.name,
			workspacePlatform: workspace?.device?.platform,
			workspaceName: workspace.name,
		});
	}

	const handleDelete = () => {
		track('[Workspace Card] Delete Workspace', {
			workspaceId: workspace._id,
			workspaceDeviceName: workspace?.device?.name,
			workspacePlatform: workspace?.device?.platform,
			workspaceName: workspace.name,
		});

		return new Promise((resolve) => {
			setLoading(true);

			deleteWorkspace({
				brainId: workspace._id,
				device: workspace.device,
				slug: slug,
			})
				.then((res) => {
					if (res['error']) {
						setLoading(false);						
						toast({
							title: 'Error!',
							description: 'The selected Worker could not be removed.',
							status: 'error',
							duration: 3000,
							isClosable: true,
						});
						resolve();
					} else {
						dispatch(removeBrain(workspace._id));
						dispatch(closeTab(workspace._id));
						setLoading(false);
						onDelete();

						toast({
							title: 'Deleted!',
							description: 'The selected Worker has been removed.',
							status: 'success',
							duration: 3000,
							isClosable: true,
						});
						resolve();
					}
					// onClose();
				})
				.catch((err) => {
					setLoading(false);
					toast({
						title: 'Error!',
						description: 'The selected Worker could not be removed',
						status: 'error',
						duration: 3000,
						isClosable: true,
					});
					resolve();
				});
		});
	};

	const handleRenameWorkspace = (newWorkspaceName) => {
		// to stop the user from entering invalid workspace names
		if (newWorkspaceName.trim() === '') {
			toast({
				title: 'Error!',
				description: 'Please enter a valid worker name.',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
			return;
			// to avoid updating the name if the workspace name is not edited
		} else if (newWorkspaceName === workspace?.name) {
			return;
		}

		setLoading(true);
		updateWorkspace({
			...workspace,
			slug,
			name: newWorkspaceName,
		})
			.then(() => {
				setLoading(false);
				toast({
					title: 'Success!',
					description: 'The worker has been renamed.',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
				dispatch(
					updateBrainById(workspace._id, {
						...workspace,
						name: newWorkspaceName,
					})
				);
			})
			.catch(() => {
				setLoading(false);
				toast({
					title: 'Error!',
					description: 'This worker could not be renamed.',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			});

		track('[Workspace Card] Rename Workspace', {
			workspaceId: workspace._id,
			workspaceDeviceName: workspace?.device?.name,
			workspacePlatform: workspace?.device?.platform,
			workspaceName: workspace.name,
		});
	};

	// custom hook to handle workspace renaming
	const {
		textInput: workspaceNameInput,
		textInputRef: workspaceNameInputRef,
		handleTextInputKeyDown: handleWorkspaceNameInputKeyDown,
		handleTextInputChange: handleWorkspaceNameInputChange,
		handleTextInputSubmit: handleWorkspaceNameInputSubmit,
		handleTextRenameClick: handleWorkspaceRenameClick,
	} = useEditableText(workspace.name, handleRenameWorkspace);

	function openDetailsDrawer() {
		onDetailsDrawerOpen();
		track('[Workspace Card] More Info Click', {
			workspaceId: workspace._id,
			workspaceDeviceName: workspace?.device?.name,
			workspacePlatform: workspace?.device?.platform,
			workspaceName: workspace.name,
		});
	}

	function openWorkspaceEditor() {
		addTab(workspace._id, workspace.name);
		if (workspace.status === 'STARTED') {
			// If runtime is already running, tell the loader to go faster by putting a mode variable in the query
			HistoryWithSlug.push(`/edit?id=${workspace._id}&status=started`);
		} else {
			HistoryWithSlug.push(`/edit?id=${workspace._id}`);
		}
	}

	function handleStartStopButtonClick() {
		if (workspace.status === 'STARTED') {
			console.log('ws', workspace.status);
			return handleStop();
		} else if (workspace.status === 'STOPPED') {
			return handleStart();
		}
	}

	function handleWorkerReset() {
		setLoading(true)
		Promise.all([
			clearSession({ sessionId: workspace.sessionId }),
			resetBrain({ workerId: workspace._id })
		])
			.then((res) => {
				toast({
					title: 'Worker reset successfully!',
					status: 'success',
					duration: 3000,
					isClosable: true,
				})
			})
			.catch(e => {
				console.log('Unable to reset worker:', e)
				toast({
					title: 'Unable to reset worker.',
					description: 'There was an unexpected error, please contact us for help.',
					status: 'error',
					duration: 3000,
					isClosable: true,
				})
			})
			.finally(() => setLoading(false))
	}

	function handleOptionsButtonClick(e) {
		const { right: x, top: y } =
			optionsButtonRef.current.getBoundingClientRect();
		// console.log('cm', a)
		show(e, {
			position: { x: x + 2, y: y },
		});
	}

	function handleClick(e) {
		console.log('yoo clicked', e);
		switch (e.detail) {
			case 1: {
				selectWorkspace();
				e.stopPropagation();
				break;
			}
			case 2: {
				track('[Workspace Card] Double Click', {
					workspaceId: workspace._id,
					workspaceDeviceName: workspace?.device?.name,
					workspacePlatform: workspace?.device?.platform,
					workspaceName: workspace.name,
				});
				return openWorkspaceEditor();
			}
			default:
				return openWorkspaceEditor();
		}
	}

	function handleRightClick(e) {
		console.log('yoo right clicked', e);
		selectWorkspace();
		e.stopPropagation();
		e.preventDefault();
		show(e);
	}

	const workspaceLoading = loading || workspace.status === 'PENDING';
	const selectedBorderColor = '#925392';
	return (
		<>
			<Modal
				isOpen={isDeleteConfirmationOpen}
				onClose={onDeleteConfirmationClose}
				isCentered
				size="sm"
				// {...modalProps}
			>
				<ModalOverlay />
				<ModalContent height="200px" borderRadius="2px">
					<ModalCloseButton _focus={{ boxShadow: 'none' }} />
					<ModalBody
						flex="1"
						minHeight="0"
						display="flex"
						flexDirection="column"
						p="0"
						borderRadius={'2px'}
						height="200px"
					>
						<Flex direction="column" flex="1" minHeight="0" p="4">
							<Box
								textStyle="serif.md"
								fontWeight="500"
								marginBottom="0.5rem"
							>
								Delete Worker?
							</Box>
							<Box
								fontWeight="medium"
								opacity="0.6"
								textStyle="sams.md"
								borderRadius="2px"
								mt="8px"
							>
								All skills inside the Worker will be deleted. This is
								irreversible.
							</Box>
						</Flex>
					</ModalBody>
					<ModalFooter>
						<MayaFilledButton
							showDotPattern
							text="Delete"
							onClick={() => {
								setDeleteLoading(true);
								handleDelete().then(() => {
									setDeleteLoading(false);
									onDeleteConfirmationClose();
								});
							}}
							colorScheme="purple"
							size="sm"
							isLoading={deleteLoading}
						/>
					</ModalFooter>
				</ModalContent>
			</Modal>{' '}
			{isDetailsDrawerOpen ? (
				// @ts-ignore
				<WorkspaceDetailsDrawer
					isOpen={isDetailsDrawerOpen}
					onClose={onDetailsDrawerClose}
					workspace={workspace}
				/>
			) : null}
			<Box // This exists just so I can increase the 'border width' on selection of a workspace card, without making content jump
				padding="1px"
				minHeight={'10px'}
				bg={isSelected ? selectedBorderColor : undefined}
				borderRadius="2px"
				cursor="pointer"
				// _hover={{
				// 	bg: selectedBorderColor,
				// }}
				transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			>
				<WorkspaceContextMenu
					workspace={workspace}
					handleStartStopButtonClick={handleStartStopButtonClick}
					handleOpenWorkspaceClick={openWorkspaceEditor}
					handleMoreDetailsClick={openDetailsDrawer}
					handleDeleteWorkspaceClick={onDeleteConfirmationOpen}
					handleRenameWorkspaceClick={(e) => {
						e.stopPropagation();
						handleWorkspaceRenameClick();
					}}
					handleResetWorkspaceClick={() => {
						handleWorkerReset()
					}}
				/>
				<Flex
					direction="column"
					height="220px"
					border="1px solid"
					borderColor={
						isSelected ? selectedBorderColor : 'light.border.gray.100'
					}
					// _hover={{
					// 	borderColor: 'light.theme.gray.300',
					// }}
					transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
					bg="light.theme.gray.50"
					borderRadius="2px"
					overflow="hidden"
					onMouseEnter={() => setMouseHovering(true)}
					onMouseLeave={() => setMouseHovering(false)}
					onClick={handleClick}
					onContextMenu={handleRightClick}
					position="relative"
				>
					<Flex
						position="absolute"
						height="28px"
						width="28px"
						right="0"
						mr="12px"
						mt="8px"
						// bg='light.theme.gray.100'
						borderRadius="2px"
						justifyContent="center"
						alignItems="center"
						cursor="pointer"
						onClick={openWorkspaceEditor}
						opacity={mouseHovering || workspaceLoading ? undefined : '0'}
						transition="all 0.1s ease"
					>
						<FiExternalLink color="#656161" />
					</Flex>

					<Box
						flex="1 1 auto"
						display="flex"
						bg="white"
						height="200px"
						overflow="hidden"
					>
						<Img
							width="100%"
							height="100%"
							src={getRandomThumbnail(index)}
							objectFit="cover"
							userSelect="none"
						/>
					</Box>
					<Box
						// height='50px'
						textStyle="sans.sm"
						// display='flex'
						padding="12px 16px 10px 16px"
						borderTop="1.25px solid"
						borderColor="light.border.gray.200"
						display="flex"
						justifyContent="space-between"
						color="light.font.gray.400"
					>
						<Box
							// bg='green'
							width="80%"
						>
							<Flex alignItems="center">
								<Circle
									bg={getStatusColor(workspace.status)}
									size="10px"
									mr="10px"
								/>
								{workspaceNameInput.isEditing ? (
									<Input
										ref={workspaceNameInputRef}
										variant="unstyled"
										w="100%"
										h="100%"
										p="0"
										textAlign="left"
										my="0 !important"
										px="2"
										_placeholder={{
											fontSize: '2rem !important',
										}}
										fontWeight="500 !important"
										_focus={{ boxShadow: 'none' }}
										borderRadius="0"
										value={workspaceNameInput.inputValue}
										onChange={handleWorkspaceNameInputChange}
										onBlur={handleWorkspaceNameInputSubmit}
										onKeyDown={handleWorkspaceNameInputKeyDown}
									/>
								) : (
									<Box
										whiteSpace="nowrap"
										overflow="hidden"
										textOverflow="ellipsis"
									>
										{workspaceNameInput.inputValue}
									</Box>
								)}
							</Flex>
							<Flex mt="8px" userSelect="none">
								<Tag
									bg="light.theme.gray.200"
									label={workspace.deviceName}
									icon={
										<PlatformIcon
											platform={workspace?.device?.platform || ''}
										/>
									}
									size="sm"
									maxWidth="120px"
									// textStyle='sans.sm'
								/>
								<Tag
									bg="light.theme.gray.200"
									label={`${getLongestTimeElapsedString(
										workspace.updatedAt
									)} ago`}
									icon={<FiClock />}
									size="sm"
									ml="12px"
								/>
							</Flex>
						</Box>
						<Flex alignItems="center">
							<Box
								margin="3px 4px 2px 0px"
								opacity={
									mouseHovering || workspaceLoading ? undefined : '0'
								}
								transition="all 0.1s ease"
								borderRadius="2px"
								cursor={workspaceLoading ? undefined : 'pointer'}
								padding="5px"
								_hover={{
									backgroundColor: workspaceLoading
										? undefined
										: '#F3F3F3',
								}}
								// bg='tomato'
								onClick={handleStartStopButtonClick}
							>
								{workspaceLoading ? (
									<Spinner size="sm" color="#656161" />
								) : null}
								{/* {!workspaceLoading ?
                                    workspace.status === 'STARTED' ? <FiPause color='#656161' /> : <FiPlay color='#656161' />
                                : null} */}
							</Box>
							<Box
								transition="all 0.1s ease"
								borderRadius="2px"
								cursor="pointer"
								padding="5px"
								_hover={{
									backgroundColor: '#F3F3F3',
								}}
								ref={optionsButtonRef}
								onClick={handleOptionsButtonClick}
							>
								<GoKebabVertical color="#656161" />
							</Box>
						</Flex>
					</Box>
				</Flex>
			</Box>
		</>
	);
};

export default WorkspaceCard;
