import React, { useEffect, useState, useRef } from 'react';
import {
	Flex,
	Box,
	Grid,
	createStandaloneToast,
	Center,
} from '@chakra-ui/react';
import {
	deleteSkill,
	getSkills,
} from '../../../../../functions/pac-engine/skills';
import useProfileSlug from '../../../../../hooks/useProfileSlug';
import SkillCard from './SkillCard';
import theme from '../../../../../library/theme';
import SkillCardSkeleton from './SkillCardSkeleton';
import update from 'immutability-helper';
import { useLibraryStore } from '../zustand/libraryStore';
// import useStepperStore from '../../../../Stepper/StepperStore';
// import { stepperFlowTypes } from '../../../../Stepper/Sections/registry';

const toast = createStandaloneToast({ theme: theme });

const BlankRecipeListMessage = () => {
	return (
		<Box
			textStyle="sans.sm"
			color="light.font.gray.300"
			textAlign="center"
			// CSS to center the text on the center of the viewport
			position="absolute"
			top="50%"
			left="50%"
			transform="translate(-50%)"
			marginTop="-6rem"
		>
			<Box textStyle="serif.lg">Nothing here, yet.</Box>
			<Box textStyle="sans.sm" fontWeight="400" mt="6px" maxWidth="500px">
				A skill is a collection of related actions and documents that
				extends Maya's capabilities. Click the Teach button to upload a
				skill, or find one on the{' '}
				<a style={{ textDecoration: 'underline' }} href="/store">
					store
				</a>
				.
			</Box>
		</Box>
	);
};

const SkillsSection = () => {
	const slug = useProfileSlug();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const skills = useLibraryStore((state) => state.skills);
	const setSkills = useLibraryStore((state) => state.setSkills);
	// const { addStep, openStepper } = useStepperStore((state) => state)

	const selectedSkillId = useLibraryStore((state) => state.selectedSkill);
	const setSelectedSkill = useLibraryStore((state) => state.setSelectedSkill);

	useEffect(() => {
		if (slug === '_') {
			return;
		}

		setLoading(true);
		getSkills()
			.then((skillList) => {
				setLoading(false);
				console.log('ske skillList', skillList);
				setSkills(skillList);

				// // For testing convenience. Do not remove
				// addStep('teach.modifySkill', {
				//     skillId: skillList[0].skill_id,
				//     stepperFlowType: stepperFlowTypes.teach.MODIFY_SKILL
				// })
				// openStepper()
			})
			.catch((e) => {
				setLoading(false);
				setError(true);
			});
	}, [slug]);

	function handleSkillDelete(skillId) {
		return new Promise((resolve) => {
			deleteSkill(skillId)
				.then((res) => {
					console.log('delete res', res);
					toast({
						title: 'Skill deleted!',
						status: 'success',
						duration: 5000,
						isClosable: true,
					});
					// @ts-ignore
					setSkills(skills.filter((skill) => skill.skill_id !== skillId));
					resolve();
				})
				.catch((e) => {
					console.error('delete', e);
					toast({
						title: 'Error deleting skill',
						description:
							'An unknown error occured while deleting the skill. Please refresh and try again.',
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
					resolve();
				});
		});
	}

	function onSkillRename(skillId, newName) {
		const skillIdx = skills.findIndex((s) => s.skill_id === skillId);
		setSkills(
			update(skills, {
				[skillIdx]: {
					name: { $set: newName },
				},
			})
		);
	}

	function onSkillAdd(skill) {}

	const skillCards = !loading
		? skills.map((skill, idx) => {
				return (
					<SkillCard
						key={idx}
						skill={skill}
						isSelected={skill.skill_id === selectedSkillId}
						onSelect={(skillId) => setSelectedSkill(skillId)}
						handleSkillDelete={() => handleSkillDelete(skill.skill_id)}
						onSkillRename={(newName) =>
							onSkillRename(skill.skill_id, newName)
						}
					/>
				);
		  })
		: [1, 2, 3, 4].map((i) => <SkillCardSkeleton key={i} />);

	if (!loading && skills.length === 0) {
		return (
			<Flex
				// bg='tomato'
				height="100%"
				width="100%"
				justifyContent="center"
				alignItems="center"
			>
				<BlankRecipeListMessage />
			</Flex>
		);
	}

	return (
		<Flex
			width="100%"
			height="100%"
			flex="1 1 auto"
			position="relative"
			overflow="auto"
			onClick={() => setSelectedSkill('')}
			direction="column"
		>
			<Flex width="100%" position="absolute" direction="column">
				<Grid
					width="100%"
					spacing="16px"
					templateColumns={{
						xs: 'repeat(1, minmax(0, 1fr))',
						sm: 'repeat(2, minmax(0, 1fr))',
						md: 'repeat(3, minmax(0, 1fr))',
						lg: 'repeat(4, minmax(0, 1fr))',
						xl: 'repeat(5, minmax(0, 1fr))',
						xxl: 'repeat(7, minmax(0, 1fr))',
					}}
					gap="16px"
					margin="0px"
					pb="80px"
				>
					{skillCards}
					{/* {skillCards} */}
				</Grid>
			</Flex>
		</Flex>
	);
};

export default SkillsSection;
