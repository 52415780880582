/**
 *
 * @param {String} url URL in which to find slug
 */
export function getSlugFromUrl(url) {
	const hasTeamSlug = url.includes('/_/');
	if (!hasTeamSlug) {
		return null;
	}

	const parts = url.split('/_/');
	const slug = parts[1].split('/')[0];
	return slug;
}

// const path = '/_/mayahq/workers'
// const slug = getSlugFromUrl(path)
// console.log('slug:', slug)
