import React, { useState } from 'react';
import CommandRep from './CommandRep';
import EntryPointRep from './EntryPointRep';
import MayaFilledButton from '../../../../../../../../../library/buttons/FilledButton';

import { Box, Flex } from '@chakra-ui/react';

const SECTION_NAMES = {
	commands: 'Commands',
	entryPoints: 'Entry Points',
};

const Triggers = ({
	workspaceUrl,
	workspaceIntents,
	workspaceTriggersNodes,
}) => {
	const [visibleSection, setVisibleSection] = useState(SECTION_NAMES.commands);

	let visibleSectionContent;
	switch (visibleSection) {
		case SECTION_NAMES.entryPoints:
			visibleSectionContent = workspaceTriggersNodes.map((triggerNode) => {
				const url =
					(!triggerNode.url.startsWith('/') ? '/' : '') + triggerNode.url;
				return (
					<EntryPointRep
						workspaceUrl={workspaceUrl}
						url={url}
						method={triggerNode.method}
					/>
				);
			});
			break;
		default:
			visibleSectionContent = workspaceIntents.map((intent, index) => (
				<CommandRep command={intent} key={index} />
			));
	}

	return (
		<Box>
			<Flex justify="space-between" marginBottom="10px">
				{Object.keys(SECTION_NAMES).map((sectionNameKey) => {
					const sectionName = SECTION_NAMES[sectionNameKey];
					return (
						<MayaFilledButton
							key={sectionName}
							text={sectionName}
							colorScheme={
								visibleSection === sectionName ? 'purple' : 'white'
							}
							showDots={false}
							buttonProps={{
								width: '49%',
								border: 'solid 1.5px',
								borderColor: 'light.theme.gray.300',
							}}
							onClick={() => setVisibleSection(sectionName)}
						/>
					);
				})}
			</Flex>
			<Flex direction="column">{visibleSectionContent}</Flex>
		</Box>
	);
};

export default Triggers;
