import React, { useCallback } from 'react'
import {
    Flex, Box,
    Button as ChakraButton,
    GridItem,
    Tooltip,
    Img
} from '@chakra-ui/react'
import _ from 'lodash'
import { useWorkspaceDashboardStore } from '../zustand'


const ImageComp = ({
    workspaceId,
    width,
    componentId
}) => {
    const src = useWorkspaceDashboardStore(state => state.dashboardData[workspaceId][componentId].src)
    const caption = useWorkspaceDashboardStore(state => state.dashboardData[workspaceId][componentId].caption)

    return (
        <GridItem
            colSpan={parseInt(width)}
        >
            <Flex
                direction='column'
                height='100%'
            >
                <Img
                    src={src}
                    borderRadius='2px'
                />
                {caption && (
                    <Flex
                        // position='sticky'
                        // bottom='-1px' // Setting 0px here leaves a 1px gap between this and the parent div. Dunno why, need to fix.
                        bg='white'
                        justifyContent='center'
                        textStyle='sans.xs'
                        height='40px'
                        alignItems='center'
                        fontWeight='400'
                        color='light.font.gray.300'
                    >
                        <Box mt='4px'>
                            {caption}
                        </Box>
                    </Flex>
                )}
            </Flex>
        </GridItem>
    )
}

export const Image = React.memo(ImageComp)