import React from 'react';
import { Box, Textarea } from '@chakra-ui/react';

interface FileContentDisplayer {
	value: string;
	onChange?: () => void;
	onClick?: () => void;
	textAreaProps?: import('@chakra-ui/react').TextareaProps;
	editable: boolean;
}

export const FileContentDisplayer = ({
	value,
	onChange,
	onClick,
	textAreaProps,
	editable,
}: FileContentDisplayer) => {
	return (
		<>
			{editable && !value ? (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					height="100%"
					onClick={onClick}
					textStyle="sans.sm"
					color="light.font.gray.400"
					cursor={'pointer'}
					border="solid 1px"
					borderColor="#D8D8D8 !important"
					position='sticky'
					top='0'
					borderRadius='2px'
				>
					Click to Paste Here
				</Box>
			) : (
				<Box 
					minWidth="0" 
					flex="1" 
					bg="white"
				>
					<Textarea
						h="full"
						fontFamily="Ubuntu"
						fontSize="14px"
						p="1.5"
						border="solid 1px"
						borderColor="#D8D8D8"
						spellCheck={false}
						placeholder="Paste Maya flow JSON"
						value={value}
						onChange={onChange}
						_focus={{ boxShadow: 'none' }}
						isReadOnly={!editable}
						{...textAreaProps}
						position='sticky'
						top='0'
						borderRadius='2px'
					/>
				</Box>
			)}
		</>
	);
};
