import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Grid, useDisclosure } from '@chakra-ui/react';
import WorkspaceCard from './WorkspaceCard';
import CardSkeleton from './WorkspaceCard/Skeleton';

//Zustand Store
import { useDashboardStore } from '../../../zustand';

const gridChoices = {
	three: {
		grid: {
			xs: 'repeat(1, minmax(0, 1fr))',
			sm: 'repeat(1, minmax(0, 1fr))',
			md: 'repeat(2, minmax(0, 1fr))',
			lg: 'repeat(3, minmax(0, 1fr))',
			xl: 'repeat(4, minmax(0, 1fr))',
		},
		cardHeight: '280px',
	},
	four: {
		grid: {
			xs: 'repeat(1, minmax(0, 1fr))',
			sm: 'repeat(2, minmax(0, 1fr))',
			md: 'repeat(3, minmax(0, 1fr))',
			lg: 'repeat(4, minmax(0, 1fr))',
			xl: 'repeat(5, minmax(0, 1fr))',
			xxl: 'repeat(7, minmax(0, 1fr))',
		},
		cardHeight: '220px',
	},
};

const gridChoice = gridChoices['four'];

const ContentWrapper = ({ children, setSelectedWorkspace }) => {
	return (
		<Box
			position="relative"
			display="flex"
			flexDir="column"
			flex="1 1 auto"
			overflow="auto"
			onClick={() => setSelectedWorkspace(null)}
			css={{
				'&::-webkit-scrollbar': {
					display: 'none',
				},
				'&::-webkit-scrollbar-track': {
					display: 'none',
				},
				'&::-webkit-scrollbar-thumb': {
					display: 'none',
				},
			}}
		>
			<Box
				width="100%"
				position="absolute"
				display="flex"
				flexDir="column"
				flex="1 1 auto"
			>
				<Grid
					// width='100%'
					spacing="16px"
					templateColumns={gridChoice.grid}
					gap="16px"
					margin="0px"
					pb="80px"
					// templateRows={1}
				>
					{children}
				</Grid>
			</Box>
		</Box>
	);
};

const linkStyle = {
	textDecoration: 'underline',
};

const BlankDashboardMessage = () => {
	return (
		<Box
			textStyle="sans.sm"
			color="light.font.gray.300"
			textAlign="center"
			// CSS to center the text on the center of the viewport
			position="absolute"
			top="50%"
			left="50%"
			transform="translate(-50%)"
			marginTop="-6rem"
		>
			<Box textStyle="serif.lg">Nothing here, yet.</Box>
			<Box textStyle="sans.sm" fontWeight="400" mt="6px" maxWidth="500px">
				A Worker is a unit of compute that runs generated software.
				Functions created using the{' '}
				<a
					href="https://github.com/mayahq/mayalabs-sdk-python"
					style={linkStyle}
				>
					Python SDK
				</a>{' '}
				will be run on these, when created.
			</Box>
		</Box>
	);
};

/**
 * Workspace Card Definition
 *
 * @param { import('../types/list').WorkspaceList } props
 * @returns
 */

export const WorkspaceList = ({ selectedWorkspace, setSelectedWorkspace }) => {
	const workspaces = useSelector(
		/**
		 * @param {{
		 *  brains: import('../../../../../redux/reducers/types/brains').Brains
		 * }} state
		 */
		(state) => state.brains
	);

	const { isLoading } = useDashboardStore((state) => {
		return {
			isLoading: state.isLoading,
		};
	});

	const skeltonContent = [1, 2, 3, 4].map((item, idx) => {
		return <CardSkeleton cardHeight={gridChoice.cardHeight} key={idx} />;
	});

	const workspaceContent = workspaces.allIds
		.map((wid, idx) => {
			return (
				<React.Fragment key={wid}>
					<WorkspaceCard
						workspace={workspaces.byId[wid]}
						isSelected={wid === selectedWorkspace}
						selectWorkspace={() => setSelectedWorkspace(wid)}
						onDelete={() => {
							// Clear selected workspace if selected during delete
							if (wid === selectedWorkspace) {
								setSelectedWorkspace(null);
							}
						}}
						key={wid}
						index={idx}
						cardHeight={gridChoice.cardHeight}
					/>
				</React.Fragment>
			);
		})
		.reverse();

	console.log('yoo rerender');

	return (
		<>
			{isLoading ? (
				<ContentWrapper setSelectedWorkspace={setSelectedWorkspace}>
					{skeltonContent}
				</ContentWrapper>
			) : !isLoading && workspaces.allIds.length > 0 ? (
				<ContentWrapper setSelectedWorkspace={setSelectedWorkspace}>
					{workspaceContent}
				</ContentWrapper>
			) : !isLoading && workspaces.allIds.length === 0 ? (
				<BlankDashboardMessage />
			) : null}
			{/* <BlankDashboardMessage /> */}
		</>
	);
};

export default WorkspaceList;
