import React from 'react';
import { Box } from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';

/**
 * Action Button for Clear, Back, Close actions
 *
 * @param { import('./types/buttonProps').ActionBtn } props
 * @returns
 */

const ActionBtn = ({ text, icon, onClick }) => {
	return (
		<Box
			color="light.font.gray.400"
			paddingY="10px"
			display="flex"
			alignItems="center"
			justifyContent="center"
			cursor="pointer"
			width="60px"
			mr="4"
			onClick={onClick}
			userSelect="none"
		>
			{icon ? icon : <SmallCloseIcon />}
			<Box ml="3px" textStyle="sans.xs" color="light.font.gray.400">
				{text}
			</Box>
		</Box>
	);
};

export default ActionBtn;
