import { Box, Flex, Spinner } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import shallow from 'zustand/shallow';
import { useStore } from '../zustand';

const IssuesButton = () => {
	const toggleIssuesSidebar = useStore(
		useCallback((state) => state.toggleIssuesSidebar, [])
	);

	const areModuleInstalling = useStore((state) => state.areModuleInstalling);

	const nodesWithIssues = useStore(
		useCallback((state) => state.nodesWithIssues.length, [])
	);
	const missingModules = useStore(
		useCallback((state) => state.missingModules.length, [])
	);
	const issuesSidebarIsOpen = useStore((state) => state.issuesSidebarIsOpen);
	const [isHovered, setIsHovered] = useState(false);

	const [totalIssues, setTotalIssues] = useState(
		nodesWithIssues + missingModules
	);

	useEffect(() => {
		setTotalIssues(nodesWithIssues + missingModules);
	}, [missingModules, nodesWithIssues]);

	return (
		<Box>
			{totalIssues > 0 ? (
				<Flex
					marginRight="1.5rem"
					cursor="pointer"
					padding="5px"
					borderRadius="2px"
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
					__hover={{ cursor: 'pointer' }}
				>
					<Flex
						alignItems="center"
						justifyContent="center"
						onClick={toggleIssuesSidebar}
					>
						{areModuleInstalling && !issuesSidebarIsOpen ? (
							<Spinner size="xs" />
						) : (
							<BsFillExclamationTriangleFill
								fill={isHovered ? '#c71f37' : '#a11d33'}
								size={20}
							/>
						)}
						<Box>
							<Box
								textStyle="sans.sm"
								marginLeft="6px"
								userSelect="none"
								opacity={isHovered ? 0.85 : 1}
							>
								{totalIssues}
							</Box>
						</Box>
					</Flex>
				</Flex>
			) : null}
		</Box>
	);
};

export default React.memo(IssuesButton);
