import React, { useCallback, useEffect, useState } from 'react'
import {
    Flex, Spinner
} from '@chakra-ui/react'
import useQueryParams from '../../../../util/useQueryParams'
import { getModuleById } from '../../../../functions/store/getStoreModules'
import useStepperStore from '../../../Stepper/StepperStore'
import { v4 } from 'uuid'
import { stepperFlowTypes } from '../../../Stepper/Sections/registry'

const Configure = () => {
    const query = useQueryParams()

    const addStep = useStepperStore(state => state.addStep)
    const openStepper = useStepperStore(state => state.openStepper)

    useEffect(() => {
        const moduleIds = query.get('modules')?.split(',')
        const workerId = query.get('worker')
        if (!moduleIds || moduleIds.length === 0) {
            return
        }

        Promise.all(
            moduleIds.map(moduleId => getModuleById(moduleId))
        ).then((modules) => {
            addStep('module.configProfile', {
                stepperSessionId: v4(),
                stepperFlowType: stepperFlowTypes.workspace.CONFIGURE,
                modules: modules,
                workerId: workerId
            })

            openStepper()
        })
    }, [])

    return (
        <Flex
            direction='column'
            height='100%'
            justifyContent='center'
            alignItems='center'
        >
            <Flex
                mb='160px'
                textStyle='serif.md'
                alignItems='center'
                color='light.font.gray.400'
            >
                <Spinner
                    size='sm'
                />
                <Flex
                    ml='12px'
                >
                    Fetching information.
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Configure