import React from 'react';
import { Box } from '@chakra-ui/react';

export interface InfoTagProps {
	label: React.ReactNode;
	children?: React.ReactNode;
}

export const InfoTag = ({ label, children }: InfoTagProps) => {
	return (
		<Box>
			<Box textStyle="sans.sm" color="light.font.gray.400">
				{label}
			</Box>
			<Box color="light.font.gray.200" textStyle="sans.xs" pl="1px">
				{children}
			</Box>
		</Box>
	);
};
