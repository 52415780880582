import React from 'react';
import {
	Img,
	Box,
	useMediaQuery,
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure,
	Button,
	Flex,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { HistoryWithSlug } from '../../../../../../util/History';

function HamburgerMenu({ list, setSelected }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef();

	return (
		<>
			<HamburgerIcon
				color="light.theme.gray.500"
				boxSize="25px"
				onClick={onOpen}
				ref={btnRef}
				marginLeft="10px"
			/>

			<Drawer
				isOpen={isOpen}
				placement="right"
				onClose={onClose}
				finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent bg="light.theme.white" fontFamily="secondary">
					<DrawerCloseButton color="light.font.gray.400" />
					<DrawerHeader textStyle="sans.sm" color="light.font.gray.400">
						Menu
					</DrawerHeader>

					<DrawerBody>
						{list.map((item, i) => {
							return (
								<Box
									key={i}
									color="light.font.gray.400"
									textStyle="sans.sm"
									cursor="pointer"
									onClick={() => {
										setSelected(item.label);
										HistoryWithSlug.push(item.redirect);
										onClose();
									}}
									mb="30px"
								>
									{item.label}
								</Box>
							);
						})}
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}

export default HamburgerMenu;
