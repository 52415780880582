import React, { useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import SectionHeader from '../../../../library/text/SectionHeader';
import MayaFilledButton from '../../../../library/buttons/FilledButton';
import WorkspaceList from '../../../Dashboard/Sections/Workspaces/WorkspaceList';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import useStepperStore from '../../StepperStore';
import useStepperAnalytics from '../../hooks/useStepperAnalytics';
import { stepperFlowTypes } from '../registry';

const SelectWorkspace = () => {
	useStepperAnalytics();
	const [selectedWorkspace, setSelectedWorkspace] = React.useState('');
	const { updateStepState, addStep, nextStep, updateStore, steps } =
		useStepperStore((state) => {
			return {
				updateStepState: state.updateStepState,
				addStep: state.addStep,
				nextStep: state.nextStep,
				updateStore: state.updateStore,
				steps: state.steps,
			};
		});

	const goToNextStep = useCallback(
		(createNewWorkspace) => {
			const currentStep = steps.byId['workspace.select'];
			const currentState = currentStep.state;
			console.log('stmo', currentState);
			const { stepperFlowType, moduleList } = currentState;
			const nextStepState = {
				...currentState,
				selectedWorkspaceId: selectedWorkspace,
			};

			if (createNewWorkspace) {
				addStep('workspace.new', nextStepState);
				return nextStep();
			}

			switch (stepperFlowType) {
				case stepperFlowTypes.store.INSTALL_MODULE_FLOW:
				case stepperFlowTypes.onboarding.INSTALL_FROM_QUICK_SHARE:
				case stepperFlowTypes.external.INSTALL_FROM_QUICK_SHARE:
				case stepperFlowTypes.home.IMPORT_NEW_FLOW: {
					const configProfileScreenNeeded = Object.values(moduleList).some(
						(mod) => !!mod.configurationType
					);
					if (configProfileScreenNeeded) {
						addStep('module.configProfile', nextStepState);
					} else {
						addStep('collection.install', nextStepState);
					}
					nextStep();
					break;
				}
				default:
					return;
			}
		},
		[steps, selectedWorkspace]
	);

	return (
		<Box
			display="flex"
			flexDir="column"
			justifyContent="center"
			alignItems="center"
			height="100%"
		>
			<Box paddingTop="20px" paddingBottom="20px">
				<SectionHeader
					heading="Select workspace."
					subheading="This automation will be installed in the selected workspace."
				/>
			</Box>

			<Box
				width="100%"
				flex="1 1 auto"
				display="flex"
				boxSizing="border-box"
				flexDir="column"
				height="100%"
			>
				<Box height="50%" display="flex">
					<WorkspaceList
						selectedWorkspace={selectedWorkspace}
						setSelectedWorkspace={setSelectedWorkspace}
					/>
				</Box>

				<Box
					display="flex"
					flexDir="row"
					justifyContent="center"
					width="100%"
					marginTop="1rem"
				>
					<MayaFilledButton
						text={'New Workspace'}
						onClick={() => goToNextStep(true)}
						buttonProps={{
							width: '250px',
							height: '3rem',
							marginRight: '32px',
						}}
						rightIcon={<ArrowForwardIcon />}
						colorScheme="gray"
					/>

					<MayaFilledButton
						text={'Continue'}
						onClick={() => goToNextStep(false)}
						isDisabled={
							!(selectedWorkspace && selectedWorkspace.length > 0)
						}
						buttonProps={{
							width: '250px',
							height: '3rem',
						}}
						rightIcon={<ArrowForwardIcon />}
						colorScheme="purple"
					/>
				</Box>
			</Box>
		</Box>
	);
};

const sectionObject = {
	heading: 'select workspace',
	component: <SelectWorkspace />,
};

export default sectionObject;
