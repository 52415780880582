// Export the Login API.
export default {
	fusionAuth: {
		changePassword: {
			PATH_SEARCH: '/api/user/change-password',
			PATH_METHOD: 'post',
		},
	},
	apiService: {
		changePassword: {
			PATH_SEARCH: '/fusionAuth/change-password',
			PATH_METHOD: 'post',
		},
	},
};
