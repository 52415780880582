import isElectron from 'is-electron';

import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';

/**
 * Function to get all configuration profiles of a certain module for user
 */
const removeConfigProfile = async (referenceId, moduleId, moduleName) => {
	var removeConfigProfileIPCResponse, removeConfigProfileIPCFn;
	var removeConfigProfileAPIResponse, removeConfigProfileAPIFn;
	let data = {
		referenceId: referenceId,
		moduleId: moduleId,
		moduleName: moduleName,
	};

	if (isElectron()) {
		removeConfigProfileIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.revokeAndRemoveConfigProfile.EVENT,
		});
		try {
			removeConfigProfileIPCResponse = await removeConfigProfileIPCFn(data);
			if (removeConfigProfileIPCResponse.error) {
				return {
					error: true,
					message: 'Error removing config profile from local storage',
				};
			}
		} catch (err) {
			return {
				error: true,
				message: err,
			};
		}
	}
	removeConfigProfileAPIFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.removeConfigProfile.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.removeConfigProfile.PATH_METHOD,
		URL_PARAM: {
			moduleId: moduleId,
		},
		PATH_QUERY: referenceId,
	});
	removeConfigProfileAPIResponse = await removeConfigProfileAPIFn();
	console.log('Received:', removeConfigProfileAPIResponse);
	if (removeConfigProfileAPIResponse.status === 200) {
		return {
			error: false,
			results: {
				message: 'Configuration profile removed successfully',
			},
		};
	} else {
		return {
			error: true,
		};
	}
};

export default removeConfigProfile;
