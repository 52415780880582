import { config } from '../../config';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

const clearSession = async ({ sessionId }) => {
	const APIRequestBody = { session_id: sessionId };

	const clearSessionFn = APIFunction({
		BASEURL: config.pacEngine.baseUrl,
		PATH_SEARCH: APIRoutes.apiService.clearSession.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.clearSession.PATH_METHOD,
	});

	const response = await clearSessionFn(APIRequestBody);
	return response;
};

export default clearSession;
