import React from 'react';
import { Box, LightMode, Button } from '@chakra-ui/react';
import SettingsItem from './SettingsItem';
import updateMetadata from '../../../../functions/profile/updateMetadata';

const ResetUserDefaults = () => {
	return (
		<Box>
			<SettingsItem
				text={'Reset User Defaults'}
				description={'Set user metadata to factory defaults.'}
				rightItem={
					<LightMode>
						<Button
							colorScheme="gray"
							size="xs"
							marginRight="1rem"
							onClick={async () => {
								updateMetadata({
									metadata: { onboarded: false },
								});
							}}
							textStyle="sans.xs"
						>
							Reset
						</Button>
					</LightMode>
				}
				onClick={() => {}}
			/>
		</Box>
	);
};

const GeneralSettings = () => {
	return (
		<Box>
			<ResetUserDefaults />
		</Box>
	);
};

export default GeneralSettings;
