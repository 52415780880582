import React from 'react';
import { Box } from '@chakra-ui/react';
import { connect } from 'react-redux';
import WebView from './ElectronWebView';
import NavBar from './NavBar';

/**
 * @typedef {{
 * url: string,
 * isMaximized: boolean,
 * onMaximize?: () => void,
 * onMinimize?: () => void,
 * hideNavbar?: boolean,
 * onWebviewDidNavigate?: (url: string) => void,
 * } & import('@chakra-ui/react').BoxProps} WebCardProps
 */

/**
 * @param {WebCardProps} param0
 * @returns
 */
const WebCard = ({
	url,
	isMaximized,
	onMaximize,
	onMinimize,
	hideNavbar = false,
	onWebviewDidNavigate,
	...rest
}) => {
	const openInBrowser = async () => {
		const wv = document.querySelector('webview');
		let url = wv.getURL();
		console.log(url);
		// @ts-ignore
		const electron = window.electron;
		const { shell } = electron;
		await shell.openExternal(url);
	};

	const goBack = () => {
		const wv = document.querySelector('webview');
		wv.goBack();
	};

	const goForward = () => {
		const wv = document.querySelector('webview');
		wv.goForward();
	};

	return (
		<Box display="flex" flexDirection="column" {...rest}>
			<Box
				marginX="0.2rem"
				marginBottom="0.2rem"
				marginTop="0.1rem"
				position="relative"
			>
				{url ? (
					<WebView
						src={url}
						height={isMaximized ? '74vh' : '34vh'}
						width="full"
						iframeStyle="border-radius: 3px; border:none"
						onDidNavigate={onWebviewDidNavigate}
					/>
				) : null}
				{hideNavbar ? null : (
					<NavBar
						position="absolute"
						bottom="-1px"
						right="-1px"
						roundedTopLeft="0.25rem"
						paddingRight="0"
						paddingBottom="0"
						onOpenInBrowser={openInBrowser}
						onBackward={goBack}
						onForward={goForward}
						onMaximize={onMaximize}
						onMinimize={onMinimize}
					/>
				)}
			</Box>
		</Box>
	);
};

export default WebCard;

// /**
//  *
//  * @param {any} state
//  * @param {WebCardProps} ownProps
//  * @returns
//  */
// const matchStateToProps = (state, ownProps) => {
// 	const { cards } = state.cardUI;
// 	const { id } = ownProps;
// 	const url = dotProp.get(cards, `byId.${id}.middle.url`);
// 	return { url };
// };

// export default connect(matchStateToProps, {
// 	setCardMaximized,
// })(WebCard);
