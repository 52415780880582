import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Icon, Tooltip, Flex, Spinner, createStandaloneToast } from '@chakra-ui/react';
import { AiOutlineGoogle } from 'react-icons/ai';
import { fusionAuthGoogleLogin, registerGoogleLoginUser } from './Service';
import useQueryParams from '../../../../../util/useQueryParams';
import MayaFilledButton from '../../../../../library/buttons/FilledButton';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { config } from '../../../../../config';
import { useDispatch } from 'react-redux';
import { setLoggedIn, setUser } from '../../../../../redux/actions';
import { setAuthTokens } from '../../../../../redux/actions/user';
import { Helmet } from 'react-helmet'
import theme from '../../../../../library/theme';


const toast = createStandaloneToast({ theme: theme })
const redirectUri = `${config.app.BASEURL}/login`;
const scopes = encodeURI(
	[
		'https://www.googleapis.com/auth/userinfo.email',
		'https://www.googleapis.com/auth/userinfo.profile',
	].join(' ')
);

export function getOauthUrl(state = 'none') {
	const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${config.oauth.GOOGLE_CLIENT_ID}&response_type=code&redirect_uri=${redirectUri}&scope=${scopes}&state=${state}&access_type=offline`;
	return url;
}

const LoginWithGoogleButton = ({ onClick = () => null }) => {
	const queryParams = useQueryParams();
	const googleSigningIn = queryParams.get('googleSigningIn');
	const [isLoading, setIsLoading] = useState(googleSigningIn === 'true');
	
	const dispatch = useDispatch();
	
	const idToken = queryParams.get('id_token')

	async function handleSuccess(idToken) {
		const faglRes = await fusionAuthGoogleLogin({
			idToken: idToken
		});
		if (!faglRes.data.user) {
			throw new Error(`faglRes.data.user invalid!`);
		}

		const firstName = faglRes?.user?.firstName
		try {
			const backendRes = await registerGoogleLoginUser({
				user: faglRes.data.user,
				firstName,
				accessToken: faglRes.data.token,
				refreshToken: faglRes.data.refreshToken,
			});
			// await handleFusionAuthLoginResponse(faglRes);
			dispatch(setLoggedIn(true));
			dispatch(setUser(backendRes.data.user));
			dispatch(
				setAuthTokens({
					accessToken: backendRes.data.tokens.accessToken,
					refreshToken: backendRes.data.tokens.refreshToken,
					lastUpdated: Date.now(),
				})
			);
		} catch (error) {
			if (error?.status === 400) {
				// axios 400 error handler
				toast({
					title: 'Login failed.',
					description: 'Something went wrong, please try again.',
					status: 'error',
					isClosable: true,
					duration: 3000
				})
			}
		}
	}

	useEffect(() => {
		if (!idToken) {
			return
		}

		handleSuccess(idToken)
	}, [idToken])

	return (
		<>
		<Helmet>
			<script>
			{`
				function handleGoogleToken(tokens) {
					window.location.href = '/login?googleSigningIn=true&id_token=' + tokens.credential
				}
			`}
			</script>
			<script src="https://accounts.google.com/gsi/client" async defer></script>
		</Helmet>
		{isLoading && (
			<Flex
				width='259px'
				height='38px'
				justifyContent='center'
				alignItems='center'
				border='1px solid'
				borderRadius='3px'
				borderColor='light.border.gray.200'
			>
				<Spinner size='sm' color='light.theme.purple.200' />
				<Flex
					textStyle='sans.sm'
					ml='12px'
					color='light.font.gray.300'
				>
					Signing in
				</Flex>
			</Flex>
		)}
		{!isLoading && (
			<>
				<div id="g_id_onload"
					data-client_id={process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID}
					data-callback='handleGoogleToken'
					data-auto_prompt="false"
					data-width='259'
					data-context='signin'
					data-ux_mode='popup'
				/>
				<div 
					class="g_id_signin"
					data-type="standard"
					data-size="large"
					data-theme="outline"
					data-text="sign_in_with"
					data-logo_alignment="left"
					data-width='259'
					data-height='40'
				/>
			</>
		)}
		</>
	);
};

export default LoginWithGoogleButton;
