export function getTableDataFromEvent(event) {
    const rows = event.data
    const columns = {}

    if (rows.length === 0) {
        return {
            columns: {},
            rows: []
        }
    }

    Object.keys(rows[0].fields).forEach(key => columns[key] = true)
    return { columns, rows }
}