import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
	Flex,
	Box,
	Input,
	InputGroup,
	InputRightElement,
	InputLeftAddon,
	Img,
} from '@chakra-ui/react';
import { ArrowForwardIcon, CheckIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { MayaFilledButton } from '@mayahq/ui';
import checkSlug from '../../../../functions/team/checkSlug';
import createTeam from '../../../../functions/team/createTeam';
import useStepperStore from '../../StepperStore';
import { stepperFlowTypes } from '../registry';
import {
	addTeamProfile,
	setCurrentProfile,
	setProfiles,
} from '../../../../redux/actions';
import useChecklistItems from '../../../Checklist/hooks/useChecklistItems';
import checklistItems from '../../../Checklist/registry';
import setUsername from '../../../../functions/profile/setUsername';
import { updateProfile } from '../../../../redux/actions/profile';
import useStepperAnalytics from '../../hooks/useStepperAnalytics';
const allChecklistItems = checklistItems;

const errors = {
	CANNOT_START_WITH_NUMBER: `Can't start username with a number.`,
	CANNOT_CONTAIN_SPECIAL_CHARACTERS: `Can't use special characters in username.`,
};

export const SelectUsername = () => {
	useStepperAnalytics();

	// Local UI state
	const [existenceCheckLoading, setExistenceCheckLoading] = useState(false);
	const [usernameTaken, setUsernameTaken] = useState(false);
	const [usernameSetLoading, setUsernameSetLoading] = useState(false);
	const [error, setError] = useState(null);

	// Initialising stepper
	const { updateStepState, addStep, nextStep, steps, closeStepper } =
		useStepperStore((state) => {
			return {
				updateStepState: state.updateStepState,
				addStep: state.addStep,
				nextStep: state.nextStep,
				steps: state.steps,
				closeStepper: state.closeStepper,
			};
		});

	// Step state (stored in zustand)
	const stepState = useStepperStore(
		useCallback(
			(state) =>
				state.steps.byId['onboarding.selectUsername'] || {
					profileSlug: '',
					teamName: '',
				},
			[]
		)
	);
	const { username = '' } = stepState;

	const setProfileSlug = (val) => {
		const valToSet = val.toLowerCase();
		const regex = /^[a-z]\w*$/;
		const acceptable = regex.test(valToSet);
		if (!acceptable) {
			if (/^[0-9]$/.test(valToSet[0])) {
				setError(errors.CANNOT_START_WITH_NUMBER);
			} else {
				setError(errors.CANNOT_CONTAIN_SPECIAL_CHARACTERS);
			}
		} else {
			setError(null);
		}
		updateStepState({ ...stepState, username: val.toLowerCase() });
	};

	// To dispatch redux actions
	const dispatch = useDispatch();

	const selectedProfile = useSelector(
		/**
		 *
		 * @param {import('../../../../redux/reducers/types/profileReducer').ProfilesState} state
		 * @returns
		 */
		(state) => state.profiles.profiles[state.profiles.selected]
	);

	const checkSlugTakenDebounced = useCallback(
		_.debounce((slug, setValue) => {
			checkSlug({ slug: slug })
				.then((exists) => {
					setValue(exists);
				})
				.catch((e) => console.error('cte', e));
		}, 300),
		[]
	);

	async function handleTeamUrlChange(e) {
		const newTeamUrl = e.target.value;
		console.log('sun ntu', e.target.value);
		setProfileSlug(newTeamUrl);
	}

	const goToNextStep = useCallback(async () => {
		const currentStep = steps.byId['onboarding.selectUsername'];
		const currentState = currentStep.state;
		const { stepperFlowType } = currentState;

		const { ...nextStepState } = currentState;

		if (selectedProfile.isTeam) {
			throw new Error('Cannot set username for a team');
		}
		setUsernameSetLoading(true);
		await setUsername({
			profileSlug: selectedProfile.profileSlug,
			username: username,
		});
		dispatch(
			updateProfile(selectedProfile.profileSlug, {
				...selectedProfile,
				username: username,
			})
		);
		setUsernameSetLoading(false);

		switch (stepperFlowType) {
			case stepperFlowTypes.onboarding.NORMAL:
			case stepperFlowTypes.onboarding.INSTALL_FROM_QUICK_SHARE: {
				addStep('onboarding.createTeam', nextStepState);
				// addStep('onboarding.describeAutomation', nextStepState)
				break;
			}
			case stepperFlowTypes.misc.SELECT_USERNAME: {
				return closeStepper();
			}
			default:
				throw new Error(`Invalid stepperFlowType: ${stepperFlowType}`);
		}

		nextStep();
	}, [steps]);

	useEffect(() => {
		setExistenceCheckLoading(true);
		checkSlugTakenDebounced(username, (val) => {
			setExistenceCheckLoading(false);
			setUsernameTaken(val);
		});
	}, [username]);

	const profileSlugFieldIsInvalid =
		!existenceCheckLoading && username.length > 0 && usernameTaken;
	const profileSlugFieldIsValid =
		!existenceCheckLoading && username.length > 0 && !usernameTaken && !error;
	const validationError = error && username.length !== 0;

	return (
		<Flex direction="column" alignItems="center" height="100%">
			<Box paddingY="20px">
				<Flex
					textStyle="serif.lg"
					textAlign="center"
					justifyContent="center"
				>
					Select a username.
				</Flex>
				<Flex
					textStyle="sans.sm"
					justifyContent="center"
					textAlign="center"
					mt="5px"
					color="light.font.gray.400"
				>
					This is permanent, you won't be able to change it later.
				</Flex>
			</Box>

			<Box
				// padding='16px'
				pt="0px"
				bg="#fff"
				borderRadius="2000px"
				border="1px solid"
				borderColor="light.border.gray.200"
				overflow="hidden"
				mt="20px"
			>
				<Img
					src={`https://robohash.org/${
						username || 'dushyant'
					}?gravatar=yes`}
					width="128px"
					height="128px"
					filter="grayscale(100%)"
					// borderRadius='1000px'
				/>
			</Box>

			<Flex direction="column" width="420px" mt="32px">
				{/* <Box
                    textStyle="sans.sm"
                    mb="8px"
                    color="light.font.gray.400"
                >
                    Username
                </Box> */}
				<InputGroup
					height="40px"
					overflow="visible"
					padding="1px" // Otherwise the top focus border of the input component is hidden. Chakra bug.
					boxSizing="border-box"
				>
					<InputLeftAddon
						textStyle="sans.sm"
						// color='light.font.gray.400'
						color="#fff"
						borderLeftRadius="2px"
						bg="light.theme.gray.500"
					>
						mayalabs.io/
					</InputLeftAddon>
					<Input
						bg="#fff"
						borderRadius="2px"
						focusBorderColor={
							profileSlugFieldIsInvalid
								? 'red.200'
								: profileSlugFieldIsValid
								? 'green.300'
								: '#ddd'
						}
						isInvalid={profileSlugFieldIsInvalid}
						errorBorderColor="red.100"
						value={username}
						onChange={handleTeamUrlChange}
						autoFocus
						pl="16px"
						textStyle="sans.xs"
						color="light.font.gray.300"
						spellCheck={false}
					/>
					<InputRightElement
						width="125px"
						mt="1px" // It'll look misaligned otherwise. Idk why.
						padding="4px 20px"
						// bg='pink'
						display="flex"
						justifyContent="flex-end"
					>
						{profileSlugFieldIsValid ? (
							<Flex
								color="green.500"
								alignItems="center"
								textStyle="sans.xs"
							>
								<CheckIcon mr="8px" />
								Available
							</Flex>
						) : null}
						{profileSlugFieldIsInvalid ? (
							<Flex
								color="red.500"
								alignItems="center"
								textStyle="sans.xs"
							>
								<WarningTwoIcon mr="8px" />
								Unavailable
							</Flex>
						) : null}
					</InputRightElement>
				</InputGroup>
				<Flex
					textStyle="sans.xs"
					color={validationError ? 'red.400' : 'light.font.gray.300'}
					mt="8px"
					ml="1px"
					// width='300px'
				>
					{!validationError && `You can access your profile at this URL.`}
					{validationError && error}
				</Flex>
			</Flex>

			<MayaFilledButton
				text="Continue"
				rightIcon={<ArrowForwardIcon />}
				colorScheme="gray"
				size="sm"
				buttonProps={{
					mt: '72px',
				}}
				isDisabled={!profileSlugFieldIsValid}
				isLoading={usernameSetLoading}
				onClick={goToNextStep}
			/>
		</Flex>
	);
};

const SelectUsernameStep = {
	component: <SelectUsername />,
	heading: 'select username',
};

export default SelectUsernameStep;
