import React from 'react';
import { Box } from '@chakra-ui/react';

const EntryPointHeading = () => {
	return (
		<Box marginLeft="15px">
			<Box
				textStyle="sans.md"
				marginRight="50px"
				color={'light.font.gray.400'}
				transition="all 0.1s cubic-bezier(.08,.52,.52,1)"
				_hover={{
					color: 'light.font.gray.400',
				}}
			>
				{'maya'}
			</Box>
			<Box
				height="2px"
				bg="light.theme.gray.400"
				width="14px"
				mt="-2px"
				borderRadius="1px"
			/>
		</Box>
	);
};

export default EntryPointHeading;
