import React from 'react';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import customTheme from './library/theme';
import { store, persistor } from './redux/store';

const AppParent = ({ children }) => {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ChakraProvider theme={customTheme}>
					<ColorModeScript initialColorMode="dark" />
					{children}
				</ChakraProvider>
			</PersistGate>
		</Provider>
	);
};

export default AppParent;
