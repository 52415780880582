import { Box } from '@chakra-ui/react';
import React from 'react';
// import '../../components/Editor/Workspace/dnd.css';
import { nodeTypes } from './EditorStyles/CustomNode';
import { edgeTypes } from './EditorStyles/CustomEdge';
import ReactFlow, {
	Background,
	useNodesState,
	useEdgesState,
} from 'react-flow-renderer';
import { getFlowByTabs, parseMayaLangToReactFlow } from './FlowMethods';
import _ from 'lodash';

const FlowVisualizer = ({ mayaFlowJson, height, width }) => {
	const [nodes, setNodes, onNodesChange] = useNodesState([]);
	const [edges, setEdges, onEdgesChange] = useEdgesState([]);

	React.useEffect(() => {
		// get flow by tabs
		const tabs = getFlowByTabs(mayaFlowJson);
		// parse mayaflow into reactflow
		const parsedTabs = _.map(tabs, (tab) => {
			tab.reactFlowByTab = parseMayaLangToReactFlow(tab.mayaFlowByTab, 1);
			return tab;
		});

		// setNodes(parsedTabs[0].reactFlowByTab.nodes);
		// setEdges(parsedTabs[0].reactFlowByTab.edges);
	}, [mayaFlowJson, setEdges, setNodes]);

	return (
		<Box
			className="dndflow"
			borderColor="#D8D8D8 !important"
			border="solid 1px"
			height={height}
			width={width}
		>
			<ReactFlow
				nodes={nodes}
				edges={edges}
				nodeTypes={nodeTypes}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
			>
				<Background
					size={1}
					style={{
						background: 'white',
					}}
					color="#D1D1D1"
				/>
			</ReactFlow>
		</Box>
	);
};

export default FlowVisualizer;
