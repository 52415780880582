import {
	Button,
	ButtonProps,
	Flex,
	FlexProps,
	Icon,
	Spacer,
	Text,
	TextProps,
} from '@chakra-ui/react';
import React from 'react';
import { EditorMenuItemConfig } from './types';

export interface EditorMenuItemProps {
	item: EditorMenuItemConfig;
	containerProps?: ButtonProps;
	iconContainerProps?: FlexProps;
	labelContainerProps?: TextProps;
	commandContainerProps?: TextProps;
}

/**
 * # Editor Menu Item
 */
export const EditorMenuItem = ({
	item,
	containerProps,
	iconContainerProps,
	labelContainerProps,
	commandContainerProps,
}: EditorMenuItemProps) => {
	return (
		<Button
			display="flex"
			alignItems="center"
			borderRadius="none"
			bg="none"
			_focus={{
				boxShadow: 'none',
			}}
			{...containerProps}
			onClick={item.onClick}
		>
			<Flex
				as="span"
				alignItems="center"
				boxSize="4"
				fontWeight="bold"
				mr="3"
				{...iconContainerProps}
			>
				{item.icon}
			</Flex>
			<Text as="span" fontWeight="500 !important" {...labelContainerProps}>
				{item.label}
			</Text>
			<Spacer />
			{item.command ? (
				<Text
					as="span"
					fontWeight="500 !important"
					{...commandContainerProps}
				>
					{item.command}
				</Text>
			) : null}
		</Button>
	);
};
