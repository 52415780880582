import isElectron from 'is-electron';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';
import { store } from '../../redux/store';
import GetProfileSlug from '../../util/ProfileSlug';
/**
 * Function to get all configuration profiles of a certain module for user
 */
const getConfigProfiles = async ({ slug, moduleId, brainId, moduleName }) => {
	const { user, devices } = store.getState();
	const localDevice = Object.values(devices.list.byId).find(device => device.platform !== 'cloud')

	const profileSlug = slug ? slug : GetProfileSlug().slug;
	var getConfigProfilesResponse,
		getConfigProfilesAPIFn,
		filterConfigProfilesIPCFn,
		filterConfigProfilesResponse;

	// if (!isElectron()) {
		if (window.navigator.onLine) {
			getConfigProfilesAPIFn = APIFunction({
				BASEURL: config.apiServer.BASEURL,
				PATH_SEARCH: APIRoutes.apiService.getConfigProfiles.PATH_SEARCH,
				PATH_METHOD: APIRoutes.apiService.getConfigProfiles.PATH_METHOD,
				PATH_QUERY: profileSlug,
				URL_PARAM: {
					moduleId: moduleId,
				},
			});
			getConfigProfilesResponse = await getConfigProfilesAPIFn();
			if (!getConfigProfilesResponse.data.error) {
				const configProfiles = getConfigProfilesResponse['data']['results'];
				
				// Browser automation profiles are device specific, so we wanna display only the ones
				// specific to given device.
				const filtered = configProfiles.filter(profile => {
					if (profile.type === 'maya-auth' && localDevice._id !== profile.deviceId) {
						return false
					}
					return true
				})
				return filtered
			}
		}
	};
// };

export default getConfigProfiles;
