/**
 * Profile Reducer
 *
 * Creates a Redux reducer for populating the profiles - users and teams.
 */

// Action Types
import actionTypes from '../actionTypes';

//Dependencies
import _ from 'lodash';
import update from 'immutability-helper'
import Cookies from 'js-cookie'
import { setSlugInLocalStorage } from '../../util/ProfileSlug/localStorageSlug';

const newInitialState = {
	selected: '_',
	userSlug: '_',
	profiles: {
		_: {
			// exp: 999999999,
			// icon: 'M',
			// isTeam: false,
			// name: '',

		}
	},
	contacts: {},
	profilesLoading: true
}

// Export the Profile Reducer.
const profileReducer = (state = newInitialState, action) => {
	switch (action.type) {
		case actionTypes.SET_PROFILES: {
			const { profiles, selectedProfileSlug } = action.payload
			const newProfilesObject = {}
			profiles.forEach(profile => newProfilesObject[profile.profileSlug] = profile)
			const newState = {
				...state,
				profiles: { ...state.profiles, ...newProfilesObject },
				profilesLoading: false
			}
			if (!state.selected || state.selected === '_') {
				newState.selected = Object.values(profiles)[0].profileSlug
			}

			if (selectedProfileSlug) {
				newState.selected = selectedProfileSlug
			}

			newState.userSlug = Object.values(profiles)
				.find(profile => profile.isTeam === false).profileSlug
			
			delete newState['_']
			return newState
		}

		case actionTypes.ADD_TEAM_PROFILE: {
			const { info: newTeamProfile } = action.payload
			const newState = {
				...state, 
				profiles: { 
					...state.profiles,
					[newTeamProfile.profileSlug]: newTeamProfile
				}
			}
			return newState
		}

		case actionTypes.SET_CURRENT_PROFILE: {
			const { profileSlug } = action.payload;
			Cookies.set('active_profile_slug', profileSlug)
			setSlugInLocalStorage(profileSlug)
			state = { ...state, selected: profileSlug }
			return state
		}

		case actionTypes.SET_CONTACTS: {
			const { info } = action.payload;
			state = { ...state, contacts: info };
			return state;
		}

		case actionTypes.CLEAR_PROFILES: {
			const newState = {
				...state, 
				profiles: { _: {} }, 
				selected: '_', 
				contacts: {}, 
				userSlug: '_',
				profilesLoading: true
			}
			return newState;
		}

		case actionTypes.CURRENT_PROFILE_TIER_UPDATE: {
			const { info } = action.payload;
			const newState =  update(state, {
				profiles: {
					[state.selected]: {
						tier: { $set: info }
					}
				}
			})
			
			return newState;
		}

		case actionTypes.UPDATE_PROFILE: {
			const { slug, profile } = action.payload;
			const newState = update(state, {
				profiles: {
					[slug]: { $set: profile }
				}
			})

			return newState
		}

		default:
			return state;
	}
};

export default profileReducer;
