import React from 'react';
import _ from 'lodash';
import { Flex, Box } from '@chakra-ui/react';

import FilterItem from './FilterItem';
import FilterItemSkeleton from './FilterItemSkeleton';
import { itemTypes } from '../itemTypes';
import { StoreApp } from '../types/Apps';
import { StoreCategory } from '../types/Categories';
import { StoreEntity } from '../zustand';
import EntityFilter from './EntityFilter';

export const filterTypes = {
	APP: 'app',
	CATEGORY: 'category',
};

export interface FiltersProps {
	apps?: { ids: string[]; byId: { [key: string]: StoreEntity<StoreApp> } };
	categories?: {
		ids: string[];
		byId: { [key: string]: StoreEntity<StoreCategory> };
	};
	itemType: string | null;
	handleAppToggle: (appId: string, isSelected: boolean) => void;
	handleCategoryToggle: (categoryId: string, isSelected: boolean) => void;
	handleTypeChange: (type: string) => void;
}

const Filters = ({
	apps,
	categories,
	itemType,
	handleAppToggle,
	handleCategoryToggle,
	handleTypeChange,
}: FiltersProps) => {
	let appFilterItems = null;
	if (apps !== null) {
		appFilterItems = apps?.ids.map((appId) => {
			return (
				<Box mb="8px">
					<FilterItem
						label={apps.byId[appId].label}
						onClick={(selected) =>
							handleAppToggle(apps.byId[appId].id, selected)
						}
						selected={apps.byId[appId]._selected}
						// filterType={filterTypes.APP}
					/>
				</Box>
			);
		});
	}

	let categoryFilterItems = null;
	if (categories !== null) {
		categoryFilterItems = categories?.ids.map((categoryId) => {
			return (
				<Box mb="8px">
					<FilterItem
						label={categories.byId[categoryId].label}
						onClick={(selected) =>
							handleCategoryToggle(
								categories.byId[categoryId].id,
								selected
							)
						}
						selected={categories.byId[categoryId]._selected}
						// filterType={filterTypes.CATEGORY}
					/>
				</Box>
			);
		});
	}

	const numSkeletons = 10;
	const filterItemSkeletons = [...Array(numSkeletons).keys()].map((key) => {
		return (
			<Box mb="8px">
				<FilterItemSkeleton />
			</Box>
		);
	});

	return (
		<Flex direction="column" flex="1" minHeight="0" borderRadius="10px">
			<EntityFilter
				selectedEntity={itemType}
				onEntityChange={handleTypeChange}
			/>
			<Box
				flex="1"
				minHeight="0"
				display={{
					sm: 'none',
					md: 'flex',
				}}
				flexDir={{
					sm: 'unset',
					md: 'column',
				}}
			>
				<Box textStyle="sans.sm" mb="8px" color="light.font.gray.400">
					Apps
				</Box>
				<Flex direction="column" overflow="auto" flex="1" minHeight="0">
					<Flex direction="column" flex="1" minHeight="0">
						{appFilterItems && appFilterItems.length > 0
							? appFilterItems
							: filterItemSkeletons}
					</Flex>
				</Flex>
				<Box
					textStyle="sans.sm"
					mt="16px"
					mb="8px"
					color="light.font.gray.400"
				>
					Categories
				</Box>
				<Flex direction="column" overflow="auto" flex="1" minHeight="0">
					<Flex direction="column" flex="1" minHeight="0">
						{categoryFilterItems && categoryFilterItems.length > 0
							? categoryFilterItems
							: filterItemSkeletons}
					</Flex>
				</Flex>
			</Box>
		</Flex>
	);
};

export default Filters;
