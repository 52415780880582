import { config } from '../../config';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

const getSessionSuggest = async ({ query, display_length = 20, limit = 5 }) => {
	// var baseUrl = 'http://127.0.0.1:8000';
	const getSessionSuggestFn = APIFunction({
		BASEURL: config.pacEngine.baseUrl,
		PATH_SEARCH: `${APIRoutes.apiService.getSessionSuggest.PATH_SEARCH}`,
		PATH_METHOD: APIRoutes.apiService.getSessionSuggest.PATH_METHOD,
		URL_PARAM: {
			q: query,
			display_length: display_length,
			limit: limit,
		},
	});

	const response = await getSessionSuggestFn();
	return response;
};

export default getSessionSuggest;
