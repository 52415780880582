import axios from 'axios';
import { config } from '../../config';
import { setAuthTokens } from '../../redux/actions/user';
import { store } from '../../redux/store';

export async function refreshTokens({ refreshToken }) {
	const requestConfig = {
		method: 'POST',
		url: `${config.fusionAuth.BASEURL}/api/jwt/refresh`,
		data: {
			refreshToken,
		},
	};

	// @ts-ignore
	const response = await axios(requestConfig);
	const { token, refreshToken: rToken } = response.data;

	return {
		accessToken: token,
		refreshToken: rToken,
	};
}
