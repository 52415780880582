import React from 'react';
import { Flex, Tooltip } from '@chakra-ui/react';
import useAnalytics from '../../../../util/Analytics/useAnalytics';

/**
 *
 * @param {{
 * 	icon?: React.ReactNode,
 *		tooltip?: React.ReactNode,
 *		isHighlighted?: boolean,
 *		onClick?: () => void,
 *		containerProps?: import('@chakra-ui/react').FlexProps,
 *	}} param0
 */
const ControlIconButton = ({
	icon,
	tooltip,
	isHighlighted,
	onClick,
	containerProps,
}) => {
	const track = useAnalytics();
	// delete props['icon']

	return (
		<Tooltip label={tooltip}>
			<Flex
				width="30px"
				height="30px"
				justifyContent="center"
				alignItems="center"
				borderRadius="4px"
				cursor="pointer"
				_hover={{
					backgroundColor: 'light.theme.gray.300',
				}}
				onClick={() => {
					track(`[Editor] Synth Panel > Button`, {
						tooltip: tooltip,
					});
					if (onClick) onClick();
				}}
				mr="2px"
				{...containerProps}
				color="#626262"
				bg={isHighlighted ? 'light.theme.gray.300' : 'none'}
			>
				{icon}
			</Flex>
		</Tooltip>
	);
};

export default ControlIconButton;
