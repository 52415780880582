import create from 'zustand/vanilla';
import { subscribeWithSelector, devtools } from 'zustand/middleware';
import { immer } from './immerMiddleware';
import { logger } from './loggerMiddleware';
import flow from 'lodash/flow';

/**
 * # Zustand Create (Vanilla) Store
 *
 * - Zustand Create Store function including middleware(s).
 * - Uses the loadash prov `flow` function to pipe the middleware one into another
 */
const createStore = flow([
	devtools,
	subscribeWithSelector,
	immer,
	logger,
	create,
]) as typeof create;

export default createStore;
