import React, { useCallback } from 'react';
import { Flex, Box, Center } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/react';
import CopyPopOver from './CopyPopOver';
import theme from '../../../../../../../../../library/theme';

const toast = createStandaloneToast({ theme: theme });

// function to copy text to the clipboard
const handleClipboardCopy = async (text) => {
	try {
		if (!text) throw new Error('data invalid!');
		await navigator.clipboard.writeText(text);
		toast({
			title: 'Copied to clipboard!',
			status: 'success',
		});
	} catch (error) {
		console.error(error);
	}
};

// function to generate cURL string
const getCURLString = (url, method) => {
	return `curl -X ${method.toUpperCase()} ${url}`;
};

const EntryPointRep = ({ workspaceUrl, url, method }) => {
	const urlCopyHandler = useCallback(async () => {
		await handleClipboardCopy(workspaceUrl + url);
	}, [url, workspaceUrl]);

	const curlCopyHandler = useCallback(async () => {
		await handleClipboardCopy(getCURLString(workspaceUrl + url, method));
	}, [method, url, workspaceUrl]);

	return (
		<Flex
			justify="space-between"
			align="center"
			bg="#fff"
			color="light.font.gray.300"
			paddingY={2.5}
			paddingX={3.5}
			mb="12px"
			borderRadius="2px"
			border="1px solid"
			borderColor="light.border.gray.200"
		>
			<Flex justify="flex-start">
				<Box marginRight={6} w={100}>
					<Center
						bg="light.theme.gray.200"
						color="light.font.gray.300"
						border="1px solid"
						borderColor="light.border.gray.100"
						borderRadius="2px"
						paddingX={4}
						paddingY={0.5}
					>
						{method.toUpperCase()}
					</Center>
				</Box>
				<Center fontSize="medium">{url}</Center>
			</Flex>
			<CopyPopOver onURLCopy={urlCopyHandler} onCURLCopy={curlCopyHandler} />
		</Flex>
	);
};

export default EntryPointRep;
