import React, { useState, useEffect, useCallback } from 'react';
import { Flex, Box, Spinner } from '@chakra-ui/react';
import useStepperStore from '../../../StepperStore';
import installModuleToBrain from '../../../../../functions/modules/installModuleToBrain';
import { CheckCircleIcon, WarningTwoIcon } from '@chakra-ui/icons';
import MayaFilledButton from '../../../../../library/buttons/FilledButton';
import { addTab } from '../../../../../redux/actions';
import { useTabStore } from '../../../../Electron/Tabs/zustand';
import { useSelector } from 'react-redux';
import { HistoryWithSlug } from '../../../../../util/History';
import installExternalSkillpack from '../../../../../functions/publishedSkillPack/installExternalSkillpack';
import useStepperAnalytics from '../../../hooks/useStepperAnalytics';
import useChecklistItems from '../../../../Checklist/hooks/useChecklistItems';
import allChecklistItems from '../../../../Checklist/registry';
import useProfileSlug from '../../../../../hooks/useProfileSlug';
import setReferenceIdToConfigMap from '../../../../../functions/brain/setReferenceIdToConfigMap';
import { stepperFlowTypes } from '../../registry';
import { useStore } from '../../../../Editor/zustand';
import getBrainById from '../../../../../functions/brain/getBrainById';

const installStates = {
	INIT: 'init',
	INSTALLING_MODULES: 'installing_modules',
	INSTALLING_COLLECTION: 'installing_collection',
	SUCCESS: 'success',
	FAILED: 'failed',
};

const StateIcon = ({ installState }) => {
	switch (installState) {
		case installStates.SUCCESS:
			return (
				<CheckCircleIcon w={10} h={10} color="light.theme.purple.200" />
			);
		case installStates.FAILED:
			return <WarningTwoIcon w={10} h={10} color="light.theme.purple.200" />;
		default:
			return <Spinner size="lg" color="light.theme.purple.200" />;
	}
};

const InstallCollection = () => {
	useStepperAnalytics();
	const [installState, setInstallState] = useState(installStates.INIT);
	const { addTab } = useTabStore((state) => {
		return {
			addTab: state.addTab,
		};
	});
	const slug = useProfileSlug();
	const workspaces = useSelector(
		/**
		 *
		 * @param {{
		 *  brains: import('../../../../../redux/reducers/types/brains').Brains
		 * }} state
		 */
		(state) => state.brains
	);

	const selectedProfile = useSelector(
		/**
		 *
		 * @param {import('../../../../../redux/reducers/types/profileReducer').ProfilesState} state
		 * @returns
		 */
		(state) => state.profiles.profiles[state.profiles.selected]
	);

	const { checklistItems, updateChecklistItem } = useChecklistItems();

	const { steps, closeStepper, clearSteps, clearStore } = useStepperStore(
		(state) => {
			return {
				steps: state.steps,
				closeStepper: state.closeStepper,
				clearSteps: state.clearSteps,
				clearStore: state.clearStore,
			};
		}
	);

	const setInstalledModules = useStore(
		useCallback((state) => state.setInstalledModules, [])
	);
	const setMissingModules = useStore(
		useCallback((state) => state.setMissingModules, [])
	);

	const currentStep = steps.byId['collection.install'];
	const currentState = currentStep.state;
	const { stepperFlowType } = currentState;

	useEffect(() => {
		if (!steps) {
			console.log('ifl Step::InstallCollection - No steps found');
			return setInstallState(installStates.FAILED);
		}

		setInstallState(installStates.INSTALLING_MODULES);

		const currentStep = steps.byId['collection.install'];
		const currentState = currentStep.state;
		const {
			moduleList: modules,
			selectedWorkspaceId: workspaceId,
			flowDetails,
			configProfiles: configMap,
		} = currentState;

		if (!workspaceId) {
			console.log('ifl no workspace selected');
			return setInstallState(installStates.FAILED);
		}

		async function installModulesSequentially() {
			const moduleList = Object.values(modules);

			for (let i = 0; i < moduleList.length; i++) {
				const module = moduleList[i];
				console.log('imp installing module', module);
				let referenceId = null;
				if (module.configurationType) {
					referenceId = configMap[module._id].referenceId;
				}
				const formData = {
					brainId: workspaceId,
					moduleId: module._id,
					packageName: module.packageName,
					profileSlug: slug,
					version: module.currentVersion,
					// referenceId: referenceId // Setting referenceId here in this function only now, no need to pass it further
				};

				console.log('imp installing module');
				// Save the referenceId first
				await setReferenceIdToConfigMap({
					referenceId,
					runtimeId: workspaceId,
					modulePackageName: module.packageName,
				});
				const res = await installModuleToBrain(formData, module);
				console.log('imp installed module', res);
			}
		}

		installModulesSequentially()
			.then(async (res) => {
				console.log('ifl found flowDetails', flowDetails);
				if (!flowDetails) {
					const brain = await getBrainById({
						brainId: workspaceId,
						slug: slug,
					});
					setInstallState(installStates.SUCCESS);
					setInstalledModules(brain.modules);
					setMissingModules();
					if (
						stepperFlowType === stepperFlowTypes.workspace.INSTALL_MODULE
					) {
						// finishStepperInstall();
						finishStepper();
					}
					return;
				}

				console.log('ifl constructing form data');
				setInstallState(installStates.INSTALLING_COLLECTION);

				const moduleList = Object.values(modules);
				const requiredConfigsArePresent = moduleList.every(
					(mod) => !mod.configurationType || configMap[mod._id]
				);
				if (!requiredConfigsArePresent) {
					setInstallState(installStates.FAILED);
					console.log(
						'ifl Some modules that require configuration, are not configured.'
					);
				}

				const moduleRefs = {};
				Object.values(modules).forEach((module) => {
					if (module.configurationType) {
						moduleRefs[module._id] = {
							...module,
							referenceId: configMap[module._id].referenceId,
						};
					} else {
						moduleRefs[module._id] = module;
					}
				});

				const formData = {
					profileSlug: slug,
					moduleRefs: moduleRefs,
					brainId: workspaceId,
					ExternalSkillpackToInstall: flowDetails,
				};

				console.log('ifl installing collection');
				installExternalSkillpack(formData)
					.then((res) => {
						console.log('ifl installed collection successfully:', res);
						// Update the 'Install your first automation' checklist item if not already updated
						if (
							selectedProfile.isTeam &&
							!checklistItems[allChecklistItems.installFirstAutomation]
								?.done
						) {
							updateChecklistItem(
								allChecklistItems.installFirstAutomation,
								true
							);
						}
						setInstallState(installStates.SUCCESS);
					})
					.catch((e) => {
						setInstallState(installStates.FAILED);
						console.error('flow installation failed', e);
					});
			})
			.catch((e) => {
				setInstallState(installStates.FAILED);
				console.error('module install failed:', e);
			});
	}, [steps]);

	function finishStepper() {
		clearSteps();
		clearStore();
		closeStepper();
	}

	function finishStepperInstall() {
		clearStore();
		closeStepper();
	}

	function openWorkspace() {
		finishStepper();
		const currentStep = steps.byId['collection.install'];

		let workspaceId = currentStep.state.selectedWorkspaceId;
		const workspace = workspaces.byId[workspaceId];
		addTab(workspaceId, workspace.name);
		HistoryWithSlug.push(`/edit?id=${workspaceId}`);
	}

	let text = '';
	switch (installState) {
		case installStates.SUCCESS: {
			text = 'Installation successful';
			break;
		}
		case installStates.FAILED: {
			text = 'Something went wrong. Please try again :(';
			break;
		}
		case installStates.INSTALLING_MODULES: {
			text = 'Installing modules';
			break;
		}
		case installStates.INSTALLING_COLLECTION: {
			text = 'Installing Collection';
			break;
		}
	}

	return (
		<Flex
			height="100%"
			flex="1 1 auto"
			// alignItems='center'
			justifyContent="center"
			direction="column"
		>
			<Flex justifyContent="center" mb="16px">
				<StateIcon installState={installState} />
			</Flex>
			<Flex
				textStyle="sans.md"
				color="light.font.gray.400"
				justifyContent="center"
				textAlign="center"
				mb="64px"
			>
				{text}
			</Flex>
			<Flex justifyContent="center" mb="80px">
				<MayaFilledButton
					text="Open workspace"
					colorScheme="purple"
					showDotPattern={true}
					onClick={openWorkspace}
				/>
				<MayaFilledButton
					text="Finish"
					colorScheme="gray"
					buttonProps={{
						ml: '12px',
					}}
					onClick={finishStepper}
				/>
			</Flex>
		</Flex>
	);
};

const InstallCollectionObject = {
	heading: 'install collection',
	component: <InstallCollection />,
};

export default InstallCollectionObject;
