import React from 'react';

import {
	Box,
	Stack,
	Input,
	InputGroup,
	InputRightElement,
	Button,
	Alert,
	AlertDescription,
	AlertIcon,
	useColorMode,
	useToast,
} from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/react';
import theme from '../../../../library/theme';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import isElectron from 'is-electron';
// API
import APIFetch from '../../../../util/APIFetch';
import ResetAPI from './ResetAPI';

//Redux
import { connect } from 'react-redux';
import { setUser, setStatus } from '../../../../redux/actions';

// Config
import { config } from '../../../../config';

//Utils
import validate_password from '../../../../util/Validate/validate_password';

// Components
import Container from '../Container';
import MayaInput from '../../../../library/input';
import MayaFilledButton from '../../../../library/buttons/FilledButton';
import History from '../../../../util/History';

const PasswordInput = ({
	name,
	placeholder,
	onChange,
	onKeyDown,
	isInvalid,
	isDisabled,
}) => {
	const [show, setShow] = React.useState(false);

	const handleClick = () => setShow(!show);

	return (
		<InputGroup size="md" fontFamily="body">
			<MayaInput
				name={name}
				type={show ? 'text' : 'password'}
				onChange={onChange}
				placeholder={placeholder}
				onKeyDown={onKeyDown}
				isDisabled={isDisabled}
				isInvalid={isInvalid}
			/>
			<InputRightElement width="3rem">
				<Box
					as={show ? MdVisibilityOff : MdVisibility}
					size="32px"
					color="gray.300"
					onClick={handleClick}
				/>
			</InputRightElement>
		</InputGroup>
	);
};

const ResetCard = ({ setUser, setStatus }) => {
	const toast = createStandaloneToast({ theme: theme });
	const [formData, setFormData] = React.useState({});
	const [formAlert, setFormAlert] = React.useState({ type: null });
	const [isSubmitting, setSubmitting] = React.useState(false);
	const [newPwd, setNewPwd] = React.useState('');
	const [confirmNewPwd, setConfirmNewPwd] = React.useState('');
	const [pwdErr, setPwdErr] = React.useState(false);
	const [match, setMatch] = React.useState(false);
	// Setup the API Fetch utility for the Login View.
	// console.log(formData);
	const [{ fetchResults }] = APIFetch({
		BASEURL: config.fusionAuth.BASEURL,
		PATH_SEARCH:
			ResetAPI.fusionAuth.changePassword.PATH_SEARCH +
			window.location.pathname.substring(6),
		PATH_METHOD: ResetAPI.fusionAuth.changePassword.PATH_METHOD,
	});

	React.useEffect(() => {
		if (newPwd && confirmNewPwd) {
			if (newPwd === confirmNewPwd) {
				setMatch(true);
			} else {
				setMatch(false);
			}
		} else {
			setMatch(false);
		}
	}, [newPwd, confirmNewPwd]);

	//insert reset password logic here
	const handleResetSubmit = () => {
		setSubmitting(true);
		let formData = {
			password: newPwd,
		};
		fetchResults(formData)
			.then((res) => {
				setSubmitting(false);
				toast({
					title: 'Success!',
					description: 'Password reset successfully. Now login.',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
				setTimeout(() => {
					History.push('/login');
				}, 1000);
			})
			.catch((err) => {
				setSubmitting(false);
				toast({
					title: 'Failed!',
					description: 'Change password failed. Try again!',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			});
	};

	return (
		<Container
			heading="Reset password"
			subheading={`Enter and confirm a new password.`}
		>
			<Stack>
				<PasswordInput
					name="password"
					placeholder="Enter new password"
					onChange={(e) => {
						setNewPwd(e.target.value);
						if (validate_password(e.target.value)) {
							setPwdErr(false);
						} else if (e.target.value) {
							setPwdErr(true);
						} else {
							setPwdErr(false);
						}
					}}
				/>
				<PasswordInput
					name="password"
					placeholder="Confirm password"
					onChange={(e) => {
						setConfirmNewPwd(e.target.value);
					}}
					isInvalid={confirmNewPwd && !match}
					isDisabled={pwdErr}
				/>

				<MayaFilledButton
					size="sm"
					onClick={handleResetSubmit}
					isLoading={isSubmitting}
					text={'Reset Password'}
					rightIcon={<ArrowForwardIcon />}
					buttonProps={{
						marginBottom: '10px !important',
					}}
					isDisabled={!match}
				/>
				{pwdErr && (
					<Box
						height="50px"
						width="250px"
						textStyle="sans.xs"
						color="light.font.gray.200"
						textAlign="left"
					>
						{pwdErr
							? 'Must have at least 1 special character, 1 capital letter, 1 number, and be at least 8 characters long.'
							: null}
					</Box>
				)}
			</Stack>
		</Container>
	);
};

// Export the Login View.
export default connect(null, { setUser, setStatus })(ResetCard);

// <Box
//           display="flex"
//           justifyContent="center"
//           color="gray.600"
//           fontSize="0.8rem"
//           fontFamily="body"
//         >
//           or
//         </Box>
//         <Button leftIcon={FaGoogle} variantColor="gray" size="md">
//           Sign in with Google
//         </Button>
