import React from 'react';
import { Box, useColorModeValue, useColorMode } from '@chakra-ui/react';

//Components
import IconContainer from '../../../../util/React_Icon';

/**
 *
 * @param {{
 * text: string,
 * description : string,
 * icon: any,
 * rightItem: React.ReactNode,
 * } & import('@chakra-ui/react').BoxProps} param0
 * @returns
 */
const SettingsItem = ({ text, description, icon, rightItem, ...rest }) => {
	const { colorMode } = useColorMode();
	const color = {
		light: '#6C6C6C',
		dark: '#FFFEFE',
	};
	return (
		<Box
			height="70px"
			width="100%"
			display="flex"
			alignItems="center"
			bg="white"
			border="solid 1px"
			justifyContent="space-between"
			borderColor={'light.border.gray.200'}
			marginBottom="10px"
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			cursor="pointer"
			{...rest}
		>
			<Box paddingLeft="1rem">
				<Box textStyle="sans.sm">{text}</Box>
				<Box textStyle="sans.xs" color="light.font.gray.300">
					{description}
				</Box>
			</Box>
			<Box display="flex" alignItems="center">
				{rightItem}
			</Box>
		</Box>
	);
};

export default SettingsItem;
