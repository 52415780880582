import React from 'react';
import { Box, Icon, Stack, Tooltip } from '@chakra-ui/react';
import { StorePlatform } from '../types/ModuleById';
import { AiFillApple, AiFillWindows } from 'react-icons/ai';
import { DiLinux } from 'react-icons/di';
import { FaCloud } from 'react-icons/fa';

export interface PlatformListProps {
	platforms: Pick<StorePlatform, 'label' | 'id'>[];
}

export const PlatformList = ({ platforms }: PlatformListProps) => {
	return (
		<>
			<Stack direction="row" alignItems="center">
				{platforms.map(platform => (
					<Tooltip
						key={platform.id}
						label={platform.label}
						placement="bottom"
					>
						<Box as="span">
							{platform.label === 'MacOS' ? (
								<Icon as={AiFillApple} boxSize="4" />
							) : null}
							{platform.label === 'Linux' ? <Icon as={DiLinux} /> : null}
							{platform.label === 'Windows' ? (
								<Icon as={AiFillWindows} />
							) : null}
							{platform.label === 'Maya Cloud' ? (
								<Icon as={FaCloud} />
							) : null}
						</Box>
					</Tooltip>
				))}
			</Stack>
		</>
	);
};
