import { Box, SimpleGrid } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import StoreContext from '../StoreContext';
import { StoreApp } from '../types/Apps';
import { StoreCategory } from '../types/Categories';
import { StoreSkillPack } from '../types/SkillPacks';
import { StoreEntity } from '../zustand';
import CollectionCardSkeleton from './CollectionCardSkeleton';
interface CollectionListProps {
	apps: { ids: string[]; byId: { [key: string]: StoreEntity<StoreApp> } };
	categories: {
		ids: string[];
		byId: { [key: string]: StoreEntity<StoreCategory> };
	};
	searchQuery: string;
}

const CollectionList = ({
	apps,
	categories,
	searchQuery,
}: CollectionListProps) => {
	const { onStoreSkillPacksGet, onCollectionCardRender } =
		useContext(StoreContext);
	const [loading, setLoading] = useState(true);
	const [collections, setCollections] = useState<any[]>([]);

	useEffect(() => {
		if (apps === null || categories === null) {
			return;
		}
		setLoading(true);
		const selectedCategories = Object.values(categories.byId).filter(
			(c) => c._selected
		);
		const selectedApps = Object.values(apps.byId).filter((a) => a._selected);
		onStoreSkillPacksGet({
			categories: selectedCategories.map((c) => c.id),
			apps: selectedApps.map((a) => a.id),
			query: searchQuery ? searchQuery : undefined,
		})
			.then((skillpacks) => {
				setCollections(skillpacks);
				setLoading(false);
			})
			.catch((e) => console.error(e));
	}, [apps, categories, onStoreSkillPacksGet, searchQuery]);
	return (
		<SimpleGrid
			gap="2"
			columns={{
				sm: 1,
				md: 2,
				lg: 3,
			}}
			w="full"
			h="full"
			// ref: https://stackoverflow.com/questions/49701408/css-grid-auto-height-rows-sizing-to-content
			gridAutoRows="minmax(min-content, max-content)"
		>
			{loading
				? [1, 2, 3].map((idx) => <CollectionCardSkeleton key={idx} />)
				: collections.map((collection) =>
						onCollectionCardRender({ collection })
				  )}
		</SimpleGrid>
	);
};

export default CollectionList;
