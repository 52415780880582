import config from '../../config/config';
import axios from 'axios'


async function getStoreSkills({
    query = '',
    skip = 0,
    limit = 20,
    apps = [],
    categories = []
} = {}) {
    const params = new URLSearchParams()
    params.set('q', query)
    if (skip !== undefined) {
        params.set('skip', skip.toString())
    }
    if (limit !== undefined) {
        params.set('limit', limit.toString())
    }
    if (apps.length > 0) {
        params.set('apps', apps.join(','))
    }
    if (categories.length > 0) {
        params.set('categories', categories.join(','))
    }

    const request = {
        url: `${config.app.PAYLOAD_CMS_BACKEND}/api/skills/search?${params.toString()}`,
        method: 'get'
    }

    // @ts-ignore
    const response = await axios(request)

    // const response = await getStoreSkillsFn()
    console.log('got skills', response.data)
    return response.data
}

export async function getStoreSkillById(id) {
    const request = {
        url: `${config.app.PAYLOAD_CMS_BACKEND}/api/skills/${id}?depth=10`,
        method: 'get'
    }

    // @ts-ignore
    const response = await axios(request)
    return response.data
}

export async function getStoreSkillBySlug(slug) {
    const request = {
        url: `${config.app.PAYLOAD_CMS_BACKEND}/api/skills?where[slug][equals]=${slug}&depth=10`,
        method: 'get'
    }

    // @ts-ignore
    const response = await axios(request)
    return response.data
}

export default getStoreSkills