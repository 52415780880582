import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { useStore } from '../zustand';

const DeployListener = ({ setLoading }) => {
	const onDeployComplete = useStore(
		useCallback((state) => state.onDeployComplete, [])
	);
	const setDirty = useStore(useCallback((state) => state.setDirty, []));

	// listens to deploy events
	useEffect(() => {
		const onDeployHandler = () => {
			console.log('deploy handler triggered');
			onDeployComplete();
			setLoading(false);
		};
		console.log('deploy event added');
		window.RED.events.on('maya:deploy', onDeployHandler);

		return () => {
			console.log('deploy listener destroyed');
			// clean up
			window.RED.events.off('maya:deploy', onDeployHandler);
		};
	}, [onDeployComplete, setLoading]);

	// listens to workspace dirty event
	useEffect(() => {
		const onWorkspaceDirtyHandler = ({ dirty }) => {
			console.log('workspace dirty handler triggered');
			setDirty(dirty);
		};
		console.log('workspace dirty event added');
		window.RED.events.on('maya:workspace:dirty', onWorkspaceDirtyHandler);

		return () => {
			console.log('workspace dirty listener destroyed');
			// clean up
			window.RED.events.off('maya:workspace:dirty', onWorkspaceDirtyHandler);
		};
	}, [setDirty]);

	return null;
};

export default React.memo(DeployListener);
