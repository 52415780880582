import { newTabIdGenerator } from '../../../../Editor/Workspace/functions/FlowMethods/index';
/**
 * @param {any[]} mayaJson
 */

function rectifyJsonWithTabs(mayaJson) {
	const isTabPresent = mayaJson.some((node) => node.type === 'tab');
	if (isTabPresent) {
		return mayaJson;
	} else {
		const tabId = newTabIdGenerator();
		let rectifiedJSON = [
			{
				id: tabId,
				type: 'tab',
				label: 'Flow 1',
				disabled: false,
				info: '',
				module: null,
				version: null,
			},
		];
		let zUpdatedFlow = mayaJson.map((node) => {
			if (node.z) return { ...node, z: tabId };
			return node;
		});
		return [...rectifiedJSON, ...zUpdatedFlow];
	}
}

export default rectifyJsonWithTabs;
