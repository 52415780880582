import React, { useCallback, useEffect } from 'react';
import { CollectionCard, ModuleCard, StoreSection } from '@mayahq/ui';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import getStoreModules from '../../../../functions/store/getStoreModules';
import getStoreCategories from '../../../../functions/store/getStoreCategories';
import getStoreApps from '../../../../functions/store/getStoreApps';
import getStoreSkillPacks from '../../../../functions/store/getStoreSkillPacks';
import useChecklistItems from '../../../Checklist/hooks/useChecklistItems';
import allChecklistItems from '../../../Checklist/registry';
import { useSelector } from 'react-redux';
import useAnalytics from '../../../../util/Analytics/useAnalytics';
import getStoreSkills from '../../../../functions/store/getStoreSkills';

export const itemTypes = {
	MODULE: 'modules',
	COLLECTION: 'collections',
	COMMAND: 'commands',
};

const Store = () => {
	const history = useHistory();
	const location = useLocation();
	const track = useAnalytics();

	const { checklistItems, updateChecklistItem } = useChecklistItems();

	const selectedProfile = useSelector(
		/**
		 *
		 * @param {import('../../../../redux/reducers/types/profileReducer').ProfilesState} state
		 * @returns
		 */
		(state) => state.profiles.profiles[state.profiles.selected]
	);

	const updateQueryParam = useCallback(
		(vals) => {
			const search = new URLSearchParams(location.search);
			Object.keys(vals).forEach((key) => {
				search.set(key, vals[key]);
			});
			history.replace({
				pathname: location.pathname,
				search: search.toString(),
			});
		},
		[history, location]
	);

	const getQueryParam = useCallback(
		(key) => {
			const search = new URLSearchParams(location.search);
			return search.get(key);
		},
		[location]
	);
	useEffect(() => {
		if (
			!selectedProfile.isTeam &&
			!checklistItems[allChecklistItems.exploreStore]?.done
		) {
			updateChecklistItem(allChecklistItems.exploreStore, true);
		}
	}, []);

	useEffect(() => {
		getStoreSkills()
			.then(console.log)
	}, [])

	return (
		<Flex h="full" w="full" bg="light.theme.yellow.200">
			<StoreSection
				onQueryParamGet={getQueryParam}
				onQueryParamUpdate={updateQueryParam}
				onStoreAppsGet={getStoreApps}
				onStoreCategoriesGet={getStoreCategories}
				onStoreModulesGet={getStoreModules}
				onStoreSkillPacksGet={getStoreSkills}
				onModuleCardRender={({ module }) => (
					<Link key={module._id} to={`/store/module/${module._id}`}>
						<ModuleCard
							module={module}
							containerProps={{
								onClick: () =>
									track('[Store] Module Click', {
										moduleId: module._id,
										modulePackageName: module.packageName,
									}),
							}}
						/>
					</Link>
				)}
				onCollectionCardRender={({ collection }) => (
					<Link
						key={collection.id}
						to={`/store/collection/${collection.data.slug}`}
					>
						<CollectionCard
							collection={collection}
							containerProps={{
								h: 'full',
								onClick: () =>
									track('[Store] Collection Click', {
										collectionId: collection.id,
										collectionName: collection.name,
									}),
							}}
						/>
					</Link>
				)}
				containerProps={{
					w: 'full',
					h: '85vh',
				}}
			/>
		</Flex>
	);
};

export default Store;
