import React, { useContext, useMemo } from 'react';
import { Flex, Box, Img, Grid, SimpleGrid } from '@chakra-ui/react';
import { ImNpm, ImGithub, ImWindows8, ImAppleinc } from 'react-icons/im';
import { DiLinux } from 'react-icons/di';
import NodeCard from './NodeCard';
import { InfoTag } from './InfoTag';
import { MayaFilledButton } from '../../../buttons';
import { ModuleByIdResponse } from '../../types/ModuleById';
import ModuleHeading from './ModuleHeading';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ModuleSectionContext from '../Context';

dayjs.extend(relativeTime);

const ModuleSourceIcon = (props: { type: string }) => {
	const { type } = props;
	if (type === 'git') {
		return <ImGithub {...props} />;
	} else {
		return <ImNpm {...props} />;
	}
};

const PlatformIcon = (props: { platform: string }) => {
	const { platform } = props;
	switch (platform) {
		case 'macos':
			return <ImAppleinc size="16px" />;
		case 'windows':
			return <ImWindows8 />;
		case 'linux':
			return <DiLinux size="16px" />;
		default:
			return null;
	}
};

export interface ModuleDetailsProps {
	module: ModuleByIdResponse;
}

export const ModuleDetails = ({ module }: ModuleDetailsProps) => {
	const { onInstallButtonClick, onConfigProfilesSectionRender } = useContext(
		ModuleSectionContext
	);
	const { currentVersion, versions } = module;
	const versionDetails = useMemo(
		() =>
			versions
				? versions.find(v => v.version === currentVersion)
				: undefined,
		[currentVersion, versions]
	);

	return (
		<Flex direction="column" flex="1" minHeight="0" w="full">
			<Flex // Top section with module name and action buttons
				flex="1"
				minHeight="0"
				justifyContent="space-between"
				flexWrap="wrap"
			>
				<ModuleHeading name={module.name} thumbnail={module.thumbnail} />
				<Flex alignItems="center">
					<MayaFilledButton
						size="sm"
						text={'Install'}
						buttonProps={{
							mr: '16px',
							width: { sm: '10rem', md: '180px' },
						}}
						colorScheme="purple"
						patternProps={{
							top: '3px',
							left: '2px',
						}}
						onClick={onInstallButtonClick}
					/>
					<MayaFilledButton
						size="sm"
						text={'View Docs'}
						colorScheme="gray"
						buttonProps={{
							width: { sm: '10rem', md: '180px' },
							mr: '16px',
						}}
						patternProps={{
							top: '3px',
							left: '2px',
						}}
					/>
				</Flex>
			</Flex>
			<Flex direction="column" flex="1" minHeight="0">
				<Flex
					direction="column"
					minHeight="0"
					maxHeight="50vh"
					w="full"
					overflow="auto"
					mt="24px"
				>
					<SimpleGrid
						gap="1rem"
						columns={{
							sm: 2,
							md: 3,
							lg: 4,
						}}
						w="full"
						h="full"
						gridAutoRows="minmax(min-content, max-content)"
					>
						{versionDetails?.nodes
							? versionDetails.nodes.map(node => (
									<NodeCard key={node.id} node={node} />
							  ))
							: null}
					</SimpleGrid>
				</Flex>
				<Flex // Additional data section
					mt="48px"
					direction={{
						sm: 'column',
						md: 'row',
					}}
					justifyContent="space-between"
				>
					<Flex direction="column">
						<Flex flexWrap="wrap" mx="-24px" my="-12px">
							<Box mx="24px" my="12px">
								<InfoTag label="Platforms">
									<Flex alignItems="center" mt="4px">
										{module.platforms
											? module.platforms.map(platform => (
													<Box key={platform.id} mr="4px">
														<PlatformIcon
															platform={platform.value}
														/>
													</Box>
											  ))
											: null}
									</Flex>
								</InfoTag>
							</Box>
							<Box mx="24px" my="12px">
								<InfoTag label="Version">
									<Box textStyle="sans.xs">
										{module.currentVersion}
									</Box>
								</InfoTag>
							</Box>
							<Box mx="24px" my="12px">
								<InfoTag label="Updated">
									<Box textStyle="sans.xs">
										{dayjs(module.updatedAt).fromNow()}
									</Box>
								</InfoTag>
							</Box>
							<Box mx="24px" my="12px">
								<InfoTag label="Source">
									<Flex alignItems="center">
										<ModuleSourceIcon type={module.type} />
										<Box ml="4px" transform="translateY(1px)">
											{module.type === 'git' ? 'Github' : 'npm'}
										</Box>
									</Flex>
								</InfoTag>
							</Box>
						</Flex>
						<Box mt="32px">
							<InfoTag label="Description">{module.description}</InfoTag>
						</Box>
					</Flex>
					{module.configurationType && onConfigProfilesSectionRender ? (
						<Box
							// bg='tomato'
							width={{
								sm: '100%',
								md: '40%',
							}}
							borderLeft={{
								md: '1px solid #D8D8D8',
								sm: undefined,
							}}
							borderTop={{
								md: 'none',
								sm: '1px solid #D8D8D8',
							}}
							paddingLeft={{
								sm: undefined,
								md: '32px',
							}}
							paddingTop={{
								sm: '32px',
								md: '0px',
							}}
							pb="16px"
							// borderColor='light.border.gray.200' Theme value was not working somehow
						>
							{onConfigProfilesSectionRender({ module })}
						</Box>
					) : null}
				</Flex>
			</Flex>
		</Flex>
	);
};
