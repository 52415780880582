import React, { useEffect, useState } from 'react';
import { useStore } from '../../zustand';
import { useDispatch, useSelector } from 'react-redux';
import {
	Box,
	Button,
	createStandaloneToast,
	Flex,
	Input,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { truncate } from 'lodash';
import theme from '../../../../library/theme';
import updateWorkspace from '../../../../functions/brain/updateBrain';
import { updateBrainById } from '../../../../redux/actions';
import useAnalytics from '../../../../util/Analytics/useAnalytics';
import useProfileSlug from '../../../../hooks/useProfileSlug';
import useEditableText from '../../../Dashboard/Sections/Workspaces/WorkspaceList/WorkspaceCard/Hooks/useEditableText';
import { FiEdit2 } from 'react-icons/fi';

const WorkspaceName = () => {
	const brainId = useStore(
		React.useCallback((state) => {
			return state.brainId;
		}, [])
	);
	const workspaces = useSelector(
		/**
		 * @param {{
		 *  brains: import('../../../../redux/reducers/types/brains').Brains
		 * }} state
		 */
		(state) => state.brains
	);
	const brainName = useSelector(
		React.useCallback(
			(state) => {
				if (brainId) return state.brains.byId[brainId]?.name;
			},
			[brainId]
		)
	);

	const workspace = workspaces.byId[brainId];
	const toast = createStandaloneToast({ theme: theme });
	const dispatch = useDispatch();
	const track = useAnalytics();
	const slug = useProfileSlug();

	const [loading, setLoading] = useState(false);
	const [isHovered, setIsHovered] = useState(false);

	useEffect(() => {
		if (brainName) document.title = `${brainName} :: Maya  `;
	}, [brainName]);

	const handleRenameWorkspace = (newWorkspaceName) => {
		// to stop the user from entering invalid workspace names
		if (newWorkspaceName.trim() === '') {
			toast({
				title: 'Error!',
				description: 'Please enter a valid worker name.',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
			return;
			// to avoid updating the name if the workspace name is not edited
		} else if (newWorkspaceName === brainName) {
			return;
		}

		setLoading(true);
		updateWorkspace({
			...workspace,
			slug,
			name: newWorkspaceName,
		})
			.then(() => {
				setLoading(false);
				toast({
					title: 'Success!',
					description: 'The worker has been renamed.',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
				dispatch(
					updateBrainById(workspace._id, {
						...workspace,
						name: newWorkspaceName,
					})
				);
			})
			.catch(() => {
				setLoading(false);
				toast({
					title: 'Error!',
					description: 'This worker could not be renamed.',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			});
		setIsHovered(false);
		track('[Workspace Card] Rename Workspace', {
			workspaceId: workspace._id,
			workspaceDeviceName: workspace?.device?.name,
			workspacePlatform: workspace?.device?.platform,
			workspaceName: workspace.name,
		});
	};

	const {
		textInput: workspaceNameInput,
		textInputRef: workspaceNameInputRef,
		handleTextInputKeyDown: handleWorkspaceNameInputKeyDown,
		handleTextInputChange: handleWorkspaceNameInputChange,
		handleTextInputSubmit: handleWorkspaceNameInputSubmit,
		handleTextRenameClick: handleWorkspaceRenameClick,
	} = useEditableText(workspace.name, handleRenameWorkspace);

	return (
		<>
			<Flex alignItems="center">
				{workspaceNameInput.isEditing ? (
					<Input
						variant="unstyled"
						w="100%"
						h="100%"
						textAlign="left"
						my="0 !important"
						_placeholder={{
							fontSize: '2rem !important',
						}}
						fontWeight="500 !important"
						_focus={{ boxShadow: 'none' }}
						borderRadius="0"
						display="flex"
						alignItems="center"
						fontSize="14.5px"
						textStyle="sans.xs"
						position="absolute"
						left="1.5rem"
						ref={workspaceNameInputRef}
						value={workspaceNameInput.inputValue}
						onChange={handleWorkspaceNameInputChange}
						onBlur={handleWorkspaceNameInputSubmit}
						onKeyDown={handleWorkspaceNameInputKeyDown}
					/>
				) : (
					<>
						<Tooltip
							label={workspaceNameInput.inputValue}
							placement="right"
							openDelay={1000}
							offset={[0, 100]}
						>
							<Flex
								alignItems="center"
								onMouseEnter={() => setIsHovered(true)}
								onMouseLeave={() => setIsHovered(false)}
							>
								<Box
									cursor="pointer"
									onClick={handleWorkspaceRenameClick}
									position="absolute"
									left="0.5rem"
									opacity={isHovered ? '1' : '0'}
								>
									<FiEdit2 size={12} color="inherit" />
								</Box>
								<Box
									whiteSpace="nowrap"
									overflow="hidden"
									textOverflow="ellipsis"
									display="flex"
									alignItems="center"
									fontSize="14.5px"
									textAlign="left"
									textStyle="sans.xs"
									fontWeight="500 !important"
									position="absolute"
									left="1.5rem"
									pr="1rem"
									cursor="text"
									onClick={handleWorkspaceRenameClick}
								>
									{truncate(workspaceNameInput.inputValue, {
										length: 18,
									})}
								</Box>
							</Flex>
						</Tooltip>
					</>
				)}
			</Flex>
		</>
	);
};

export default WorkspaceName;
