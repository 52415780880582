import React from 'react';
import Background from '../Dashboard/Background';
import {
	Box,
	Drawer,
	DrawerContent,
	useDisclosure,
	DrawerOverlay,
} from '@chakra-ui/react';
import registry from './Sections/registry';
import _ from 'lodash';
import useStepperStore from './StepperStore';
import isElectron from 'is-electron';

/**
 * This a drawer that can be used globally to
 * guide the user through any multi step process.
 * @returns
 */
const StepperDrawer = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const {
		currentStep,
		clearSteps,
		previousStep,
		popLeadingSteps,
		steps,
		open,
		closeStepper
	} = useStepperStore(state => {
		return {
			clearSteps: state.clearSteps,
			previousStep: state.previousStep,
			popLeadingSteps: state.popLeadingSteps,
			currentStep: state.currentStep,
			open: state.open,
			steps: state.steps,
			closeStepper: state.closeStepper
		};
	});

	React.useEffect(() => {
		if (open) {
			onOpen();
		} else {
			onClose();
		}
	}, [open, onOpen, onClose]);

	return (
		<>
			<Drawer
				onClose={onClose}
				isOpen={isOpen}
				size={'full'}
				placement="bottom"
				closeOnEsc={false}
			>
				<DrawerOverlay bg='transparent' />
				<DrawerContent
					bg="none"
					paddingTop={isElectron() ? '45px' : undefined} // Equal to the tab bar height. TODO: Find better way
				>
					<Background
						topBarOption="stepper"
						backBtnClick={() => {
							if (steps.allIds.length === 1) {
								clearSteps()
								closeStepper()
								return
							}
							if (steps.allIds[currentStep] === 'workspace.new') {
								popLeadingSteps();
							} else {
								previousStep();
							}
						}}
						closeBtnClick={() => {
							clearSteps();
						}}
						topBarStepperHeading={
							steps.allIds[currentStep]
								? _.get(
										registry,
										steps.allIds[currentStep] + '.heading'
								  )
								: 'no step selected'
						}
					>
						{steps.allIds[currentStep] ? (
							_.get(registry, steps.allIds[currentStep] + '.component')
						) : (
							<Box textStyle="sans.sm">Oops, something went wrong.</Box>
						)}
					</Background>
				</DrawerContent>
			</Drawer>
		</>
	);
};

export default StepperDrawer;
