import React, { useCallback, useState } from 'react';
import {
	Flex,
	Box,
	Button as ChakraButton,
	GridItem,
	Textarea,
	Button,
} from '@chakra-ui/react';
import { useWorkspaceDashboardStore } from '../zustand';
import { marked } from 'marked';
import AceEditor from 'react-ace';
import Mustache from 'mustache';
// import CodeEditor from "@uiw/react-textarea-code-editor"
// import "@uiw/react-textarea-code-editor/dist.css";

import 'ace-builds/src-noconflict/mode-markdown';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';
import shallow from 'zustand/shallow';
import { FiCheck, FiEdit, FiEdit2, FiSave } from 'react-icons/fi';
import './styles.css';

const TemplateEditor = ({ templateBody, onChange }) => {
	return (
		<Flex width="100%" border="1px solid #eee" mt="8px">
			<AceEditor
				wrapEnabled
				width="100%"
				minLines={4}
				maxLines={Infinity}
				mode="markdown"
				theme="github"
				value={templateBody}
				onChange={(val) => onChange(val)}
				name="UNIQUE_ID_OF_DIV"
				editorProps={{ $blockScrolling: true }}
				showPrintMargin={false}
				// showGutter={false}
			/>
		</Flex>
	);
};

const TemplateView = ({
	title,
	body,
	variables,
	onTemplateBodyChange,
	editMode,
}) => {
	const [showEditor, setShowEditor] = useState(false);

	let textToDisplay = body;
	try {
		textToDisplay = Mustache.render(body, variables);
	} catch (e) {
		console.error('Invalid mustache template:', e);
	}

	let isMarkdown = false;
	try {
		isMarkdown = true;
		textToDisplay = marked.parse(textToDisplay);
	} catch (e) {}

	return (
		<>
			{editMode && (
				<Box mb="4px">
					<Button
						// width='100px'
						borderRadius="3px"
						textStyle="sans.xs"
						variant="solid"
						fontWeight="400 !important"
						size="xs"
						mb="4px"
						// px='0px'
						// transform='translateX(-2px)'
						onClick={() => setShowEditor(!showEditor)}
						_focus={{ outline: 'none', border: 'none' }}
						_active={{ outline: 'none', border: 'none' }}
						leftIcon={
							showEditor ? (
								<FiCheck size="10px" />
							) : (
								<FiEdit2 size="10px" />
							)
						}
					>
						{showEditor ? 'Done' : 'Edit'}
					</Button>
				</Box>
			)}
			<Box
				// mt='16px'
				textStyle="sans.sm"
				color="light.font.gray.400"
			>
				{title}
			</Box>
			{showEditor && (
				<TemplateEditor
					templateBody={body}
					onChange={onTemplateBodyChange}
				/>
			)}
			{!showEditor && (
				<Flex
					mt="4px"
					flex="1 1 auto"
					fontWeight="400"
					lineHeight="25px"
					direction="column"
					textStyle="sans.xs"
					color="light.font.gray.300"
					className="dashboardTemplate"
					dangerouslySetInnerHTML={
						isMarkdown ? { __html: textToDisplay } : undefined
					}
				>
					{isMarkdown ? null : textToDisplay}
				</Flex>
			)}
		</>
	);
};

export const Template = ({ workspaceId, componentId, width, title }) => {
	const body = useWorkspaceDashboardStore(
		(state) => state.dashboardData[workspaceId][componentId]?.body
	);
	const variables = useWorkspaceDashboardStore(
		(state) => state.dashboardData[workspaceId][componentId]?.variables,
		shallow
	);
	const editMode = useWorkspaceDashboardStore((state) => state.editMode);
	const metaBody = useWorkspaceDashboardStore((state) => {
		const templates = state.dashboardComponentTempData.templates;
		if (!templates) {
			return '';
		}

		return templates[componentId]?.body || '';
	});

	const setTemplateBody = useWorkspaceDashboardStore(
		(state) => state.setTemplateBody,
		shallow
	);

	console.log('zus metabod', metaBody);
	return (
		<GridItem colSpan={parseInt(width)}>
			<Flex direction="column" flex="1 1 auto" height="100%">
				<TemplateView
					title={title}
					body={editMode ? metaBody : body}
					// body={body}
					variables={variables}
					onTemplateBodyChange={(val) => setTemplateBody(componentId, val)}
					editMode={editMode}
				/>
			</Flex>
		</GridItem>
	);
};
