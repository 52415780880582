import { InstructTaskNew } from '../../components/Editor/util/pacEngine';

const instruct_new = ({
	sessionId,
	instruction,
	fromScratch,
	fromEditor,
	onReceivingInstructMessage,
	count,
}) =>
	new Promise((resolve, reject) => {
		const task = new InstructTaskNew({
			sessionId,
			instruction,
			fromScratch,
		});

		task.on('instructDoneNew', (result) => {
			if (result?.metadata?.status === 'complete') {
				resolve(result);
				task.done();
			}

			// This handler has the logic to handle error (stopping spinners, resetting state etc.) This is why it is called first before raising an error next
			onReceivingInstructMessage({
				message: result,
				fromScratch,
				fromEditor,
				count,
			});

			count++;

			if (result === 'error') {
				reject(new Error('Instruct WebSocket failed')); // Reject the promise with the error
			}
		});

		task.execute();
	});

export default instruct_new;
