import React from 'react';
import ModuleRep from './ModuleRep';
import { Accordion, Box } from '@chakra-ui/react';

const Modules = ({ workspaceModules, workspaceId }) => {
	const reps =  workspaceModules.map((module, idx) => {
		return (
			<Box mb="12px">
				<ModuleRep module={module} key={idx} workspaceId={workspaceId} />
			</Box>
		);
	});

	return (
		<Accordion allowToggle>
			{reps}
		</Accordion>
	)
};

export default Modules;
