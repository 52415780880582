import React from 'react';
import { Box, Flex, Skeleton } from '@chakra-ui/react';
import MayaSkeleton from '../../../../../../library/skeleton';

const CardSkeleton = ({ cardHeight }) => {
	return (
		<Flex
			direction="column"
			height={cardHeight}
			border="1px solid"
			borderColor={'light.border.gray.100'}
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			bg="light.theme.gray.50"
			borderRadius="2px"
			overflow="hidden"
			position="relative"
		>
			<Box
				flex="1 1 auto"
				display="flex"
				bg="white"
				height="80px"
				overflow="hidden"
			></Box>
			<Box
				// height='50px'
				textStyle="sans.sm"
				// display='flex'
				padding="12px 16px 10px 16px"
				borderTop="1.25px solid"
				borderColor="light.border.gray.200"
				display="flex"
				justifyContent="space-between"
				color="light.font.gray.400"
			>
				<Box
					// bg='green'
					width="80%"
					paddingY="3px"
				>
					<Flex
						alignItems="center"
						// bg='tomato'
					>
						<MayaSkeleton
							height="20px"
							width="140px"
							borderRadius="2px"
						/>
					</Flex>
					<Flex mt="8px">
						<MayaSkeleton height="20px" width="80px" borderRadius="2px" />
						<MayaSkeleton
							height="20px"
							width="80px"
							borderRadius="2px"
							ml="8px"
						/>
					</Flex>
				</Box>
			</Box>
		</Flex>
	);
};

export default CardSkeleton;
