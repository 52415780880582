import React, { useCallback, useState, useEffect } from 'react';
import {
	Flex,
	Box,
	Image,
	Kbd,
	DarkMode,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	MenuItemOption,
	MenuGroup,
	MenuOptionGroup,
	MenuDivider,
	Button,
	Code,
	Tooltip,
} from '@chakra-ui/react';
import { ChevronDownIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
import SectionHeader from '../../../../library/text/SectionHeader';
import MayaFilledButton from '../../../../library/buttons/FilledButton';
import _ from 'lodash';
import produce from 'immer';
import ActionBtn from '../../../../library/buttons/ActionBtn';
import useStepperStore from '../../StepperStore';
import { ArrowForwardIcon, SearchIcon } from '@chakra-ui/icons';
import updateMetadata from '../../../../functions/profile/updateMetadata';
import MayaInput from '../../../../library/input';
import useStepperAnalytics from '../../hooks/useStepperAnalytics';
import { stepperFlowTypes } from '../registry';
import useProfileSlug from '../../../../hooks/useProfileSlug';
import isElectron from 'is-electron';
import setProfileRegion from '../../../../functions/profile/setRegion';

const linkStyle = {
	textDecoration: 'underline',
};

const regions = {
	NULL: 'null',
	USA: 'us-east-2',
	INDIA: 'ap-south-1',
};

/**
 * Select all the apps that are relevant to
 * your automation.
 *
 * Design reference : https://www.figma.com/file/KAF1ostHf3VBeZsctGl3ZA/MayaDesign?node-id=2424%3A1240
 * @returns
 */
const WelcomeSelection = () => {
	useStepperAnalytics();
	const userSlug = useProfileSlug();
	const {
		updateStepState,
		addStep,
		nextStep,
		currentStep,
		steps,
		closeStepper,
		clearSteps,
	} = useStepperStore((state) => {
		return {
			updateStepState: state.updateStepState,
			addStep: state.addStep,
			nextStep: state.nextStep,
			currentStep: state.currentStep,
			steps: state.steps,
			closeStepper: state.closeStepper,
			clearSteps: state.clearSteps,
		};
	});

	const [metaLoading, setMetaLoading] = useState(false);
	const [region, setRegion] = useState(regions.NULL);

	useEffect(() => {
		const currentStep = steps.byId['onboarding.welcome'];
		if (!currentStep) {
			return;
		}

		const currentState = currentStep?.state;
		const { stepperFlowType, currentProfileRegion } = currentState;
		if (currentProfileRegion) {
			setRegion(currentProfileRegion);
		}

		/**
		 * User might click on the store link from the subtitle text
		 * and we don't want this thing in their face again when they
		 * do that, so gotta mark the user's onboarding journey complete
		 * if they came in the normal way.
		 */
		// if (stepperFlowType === stepperFlowTypes.onboarding.NORMAL) {
		// 	updateMetadata({
		// 		slug: userSlug,
		// 		metadata: {
		// 			onboarded: true,
		// 		},
		// 	});
		// }
	}, [steps]);

	const goToNextStep = useCallback(() => {
		const currentStep = steps.byId['onboarding.welcome'];
		const currentState = currentStep.state;
		const { stepperFlowType, signupMetadata } = currentState;

		const nextStepState = { ...currentState };
		switch (stepperFlowType) {
			case stepperFlowTypes.onboarding.NORMAL: {
				setMetaLoading(true);
				updateMetadata({
					slug: userSlug,
					metadata: {
						onboarded: true,
					},
				})
					.then(() => {
						closeStepper();
						clearSteps();
						setMetaLoading(false);
						// nextStepState.platformOption = isElectron() ? 'On Desktop' : 'On Cloud'
						// addStep('new.workspace', nextStepState)
						// nextStep()
					})
					.catch((e) => {
						setMetaLoading(false);
						console.error('Error updating metadata:', e);
					});
				break;
			}
			case stepperFlowTypes.onboarding.INSTALL_FROM_QUICK_SHARE: {
				nextStepState.flowToInstall = signupMetadata?.data?.flow;
				nextStepState.quickshareLink = signupMetadata?.data?.link;
				addStep('onboarding.quickShareInstall', nextStepState);
				nextStep();
				break;
			}
			default:
				throw new Error(`Invalid stepperFlowType: ${stepperFlowType}`);
		}
	}, [steps]);

	function handleContinueClick() {
		setMetaLoading(true);
		setProfileRegion({
			profileSlug: userSlug,
			region: region,
		}).then(() => {
			goToNextStep();
		});
	}

	return (
		<Box
			display="flex"
			flexDir="column"
			// justifyContent="center"
			alignItems="center"
		>
			<Box paddingBottom="40px">
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					marginTop="40px"
				>
					<Box textStyle="serif.lg" fontSize="50px">
						Welcome onboard Maya.
					</Box>
					<Box
						textStyle="sans.sm"
						color="light.font.gray.400"
						mt="30px"
						fontSize="19px"
						width="650px"
						textAlign="center"
						fontWeight="400"
					>
						{/* {'The following few steps will help us understand the task you wish to automate.'} */}
						<Box>
							This is a research preview for <b>PAC-1</b>, a new
							autonomous system that programs itself to perform
							multi-step tasks across apps and APIs, in response to
							natural language instruction.
						</Box>
						<Box mt="42px">
							Since this is an early release, we recommend <b>not</b>{' '}
							using PAC-1 for any critical functions or data.{' '}
							<a
								style={linkStyle}
								href="https://mayalabs.io/docs"
								target="_blank"
							>
								Here
							</a>{' '}
							is a full list of capabilities and limitations.
						</Box>

						<Box mt="42px">
							To get started, install our{' '}
							<a
								href="https://github.com/mayahq/mayalabs-sdk-python"
								style={linkStyle}
							>
								SDK
							</a>{' '}
							by running{' '}
							<Code colorScheme="purple"> pip install mayalabs </Code>{' '}
							and grab your API key from the developers section on the
							settings page to try out some examples.
						</Box>
					</Box>
				</Box>
			</Box>
			<Flex alignItems="center" textAlign="center" mt="15px">
				<Flex
					mb="2px"
					mr="8px"
					textStyle="sans.sm"
					fontSize="19px"
					// fontWeight='400'
					color="light.font.gray.300"
					// bg='green'
					// bg='tomato'
				>
					Select the region closest to you:
				</Flex>
				<Flex>
					<Menu size="xs">
						<MenuButton>
							<Flex
								textStyle="sans.sm"
								fontSize="19px"
								color="light.font.gray.300"
								borderBottom="1px dashed #ADADAD"
							>
								{region === regions.NULL && (
									<Flex>No region selected</Flex>
								)}
								{region === regions.USA && <Flex>🇺🇸 USA</Flex>}
								{region === regions.INDIA && <Flex>🇮🇳 India</Flex>}
							</Flex>
						</MenuButton>
						<MenuList py="0px" borderRadius="2px">
							<MenuItem
								onClick={() => setRegion(regions.USA)}
								textStyle="sans.sm"
								fontSize="19px"
								color="light.font.gray.300"
							>
								🇺🇸 USA
							</MenuItem>
							<MenuItem
								onClick={() => setRegion(regions.INDIA)}
								textStyle="sans.sm"
								fontSize="19px"
								color="light.font.gray.300"
							>
								🇮🇳 India
							</MenuItem>
						</MenuList>
					</Menu>
				</Flex>
				<Flex ml="8px" mt="2px">
					<Tooltip
						label="All resources are created in this region for fast access. You can always change this later."
						placement="right"
					>
						<QuestionOutlineIcon boxSize="12px" />
					</Tooltip>
				</Flex>
			</Flex>
			<Box mt="35px">
				<Tooltip
					label={
						region === regions.NULL ? 'Select a region to continue' : ''
					}
					// placement='left'
				>
					<Box width="200px" height="40px">
						<MayaFilledButton
							text="Continue"
							buttonProps={{
								width: '200px',
							}}
							isDisabled={region === regions.NULL}
							onClick={handleContinueClick}
							rightIcon={<ArrowForwardIcon />}
							isLoading={metaLoading}
						/>
					</Box>
				</Tooltip>
			</Box>
		</Box>
	);
};

const sectionObject = {
	heading: 'welcome',
	component: <WelcomeSelection />,
};

export default sectionObject;
