import React, { useState } from 'react';
import {
	Flex,
	Box,
} from '@chakra-ui/react';
import { MayaSkeleton } from '@mayahq/ui';

const ConfigProfileSkeleton = ({
	selected = false
}) => {
	const [deleteLoading, setDeleteLoading] = useState(false);

	return (
		<Box
			display="flex"
			textStyle="sans.xs"
			bg={selected ? "#555" : "#fff"}
			border="1px solid"
			borderColor={
				selected ? "light.theme.gray.500" : 'light.border.gray.100'
			}
			padding="0px 16px"
			color={selected ? "#fff" : "light.font.gray.400"}
			borderRadius="2px"
			height="40px"
			alignItems="center"
			cursor="pointer"
			// justifyContent='space-between'
		>
			<Flex
				textStyle="sans.xs"
				fontSize="sm"
				height="30px"
				pl="4px"
				alignItems="center"
				flex="1 1 auto"
			>
				<MayaSkeleton 
                    width='140px'
                    height='20px'
                />
			</Flex>
			<Flex>
                <MayaSkeleton
                    height="25px"
                    width="25px"
                    mr='8px'
                />
                <MayaSkeleton
                    height='25px'
                    width='25px'
                />
			</Flex>
		</Box>
	);
};

export default ConfigProfileSkeleton;
