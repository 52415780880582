import React from 'react';
import { Tag as ChakraTag, TagLeftIcon, TagLabel, Box } from '@chakra-ui/react';

export const Tag = (props) => {
	const {
		label,
		icon,
		size,
		variant,
		color = 'light.theme.gray.400',
		bg = '#e1e1e1',
		border = '0.5px solid',
		borderColor = 'light.border.gray.100',
	} = props;

	return (
		<ChakraTag
			variant={variant}
			size={size}
			bg={bg}
			color={color}
			borderRadius="2px"
			border={border}
			paddingLeft="4px"
			borderColor={borderColor}
			// paddingY='1px'
			// paddingX='5px'
			{...props}
			display="flex"
			alignContent="center"
		>
			{icon ? icon : null}
			<TagLabel ml="4px">{label}</TagLabel>
		</ChakraTag>
	);
};
