import React, { useRef } from 'react'
import {
    Flex,
    Center,
    Button
} from '@chakra-ui/react'
import { RepeatIcon } from '@chakra-ui/icons'
import MayaFilledButton from '../../../../../library/buttons/FilledButton'
import ActionBtn from '../../../../../library/buttons/ActionBtn'

const LibraryNav = ({
    value,
    onClick,
    isSelected
}) => {
    return (
        <Center
            height='100%'
            px='8px'
            variant='link'
            textStyle='sans.md'
            size='lg'
            // fontWeight='400'
            fontSize='18px'
            color={isSelected ? 'light.font.gray.400' : 'light.font.gray.200'}
            mr='12px'
            cursor='pointer'
            _hover={{
                color: 'light.font.gray.400'
            }}
            onClick={onClick}
        >
            {value}
        </Center>
    )
}

const LibraryTopBar = ({
    onTypeChange,
    currentType,
    handleTeachClick
}) => {
    const teachInputRef = useRef()
    return (
        <Flex
            // bg='tomato'
            width='100%'
            justifyContent='space-between'
        >
            <Flex
                height='100%'
                alignItems='center'
                // bg='green'
            >
                <MayaFilledButton
                    text="Teach Skill"
                    onClick={handleTeachClick}
                    // leftIcon={<AddIcon fontSize="10px" />}
                />
                <Flex
                    height='100%'
                    ml='24px'
                >
                    <LibraryNav
                        value='skills'
                        onClick={() => onTypeChange('skills')}
                        isSelected={currentType === 'skills'}
                    />
                    {/* <Flex
                        width='2px'
                        bg='#aaa'
                        height='30%'
                        my='auto'
                        mr='20px'
                        ml='8px'
                        transform={'translateY(1px)'}
                    /> */}
                    {/* <LibraryNav
                        value='recipe'
                        onClick={() => onTypeChange('recipe')}
                        isSelected={currentType === 'recipe'}
                    />
                    <LibraryNav
                        value='step' 
                        onClick={() => onTypeChange('step')}
                        isSelected={currentType === 'step'}
                    />
                    <LibraryNav
                        value='documents'
                        onClick={() => onTypeChange('document')}
                        isSelected={currentType === 'document'}
                    /> */}
                </Flex>
            </Flex>
            {/* <ActionBtn
                text="REFRESH"
                icon={
                    <RepeatIcon
                        w={5}
                        h={5}
                        mr="1"
                        color="light.theme.gray.500"
                    />
                }
                onClick={() => {
                }}
            /> */}
        </Flex>
    )
}

export default LibraryTopBar