import {
	Circle,
	Flex,
	FlexProps,
	Img,
	Spacer,
	Stack,
	Tag,
	TagLabel,
	TagLeftIcon,
	Text,
} from '@chakra-ui/react';
import React from 'react';
import { StoreSkillPack } from '../types/SkillPacks';
import { ImDrawer2 } from 'react-icons/im';
import { BsCheckCircle } from 'react-icons/bs';

export interface CollectionItemProps {
	collection: any;
	containerProps?: FlexProps;
}

/**
 * # Collection Item
 */
export const CollectionCard = ({
	collection: skill,
	containerProps,
}: CollectionItemProps) => {
	return (
		<Flex
			direction="column"
			borderRadius="2px"
			bg="#fefefe"
			border="1.5px solid #ddd"
			textStyle={'sans.sm'}
			cursor="pointer"
			{...containerProps}
		>
			<Flex px="4" py="2.5" bg="#F3F3F3">
				{skill.data.apps ? (
					<Stack direction="row" spacing="5px" alignItems="center">
						{skill.data.apps.slice(0, 2).map((app: any) => (
							<Img
								key={app.id}
								src={app?.thumbnail?.url}
								alt={app.label + 'thumbnail'}
								// w="6"
								h="6"
							/>
						))}
						{skill.data.apps.length > 2 ? (
							<Circle
								size="6"
								bg="#DDDDDD"
								color="#585858"
								fontSize="x-small"
							>
								{skill.data.apps.length - 2}+
							</Circle>
						) : null}
					</Stack>
				) : null}
				<Spacer />
				<Tag
					variant="subtle"
					colorScheme="gray"
					bg="#E1E1E1"
					alignItems="center"
					color="#6C6C6C"
					borderRadius="sm"
					border="1.5px solid #d3d3d3"
					h="7"
				>
					<TagLeftIcon boxSize="3" mb="3px" as={ImDrawer2} />
					<TagLabel fontSize="xs">SKILL</TagLabel>
				</Tag>
			</Flex>
			<Flex direction="column" p="4" alignItems="flex-start" h="full">
				<Text textStyle="sans.sm" color="#585858" userSelect="none">
					{skill.data.title}
				</Text>
				<Text
					textStyle="sans.xs"
					color="#888888"
					userSelect="none"
					mt="2"
					mb="4"
					noOfLines={2}
					h="full"
				>
					{skill.data.shortDescription}
				</Text>
				<Spacer />
				<Tag
					variant="subtle"
					colorScheme="gray"
					bg="#EEEEEE"
					alignItems="center"
					color="#6C6C6C"
					borderRadius="sm"
					border="1.5px solid #d3d3d3"
					minHeight="7"
					minWidth="6rem"
				>
					<TagLeftIcon boxSize="3" as={BsCheckCircle} />
					<TagLabel fontSize="xs">Official</TagLabel>
				</Tag>
			</Flex>
		</Flex>
	);
};
