import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { store } from '../redux/store';
import Iframe from 'react-iframe';
import getDashboardData from '../functions/dashboard/getDashboard';
import { setDashboardInfo } from '../redux/actions';
import useProfileSlug from '../hooks/useProfileSlug';
import useQueryParams from '../util/useQueryParams';
import WorkspaceDashboardWrapper from './Dashboard/Sections/WorkspaceDashboards';

/**
 *
 * @param {{
 * 	brainId?: string,
 * 	old: boolean
 * }} param0
 * @returns
 */
const DashboardUI = ({
	brainId,
	old = false,
	// width,
	// height
}) => {
	const dispatch = useDispatch();
	const slug = useProfileSlug();
	const query = useQueryParams();
	const workspaceId = useMemo(
		() => (brainId ? brainId : query.get('id')),
		[brainId, query]
	);
	const frameRef = useRef();
	const [workspaceUrl, setWorkspaceUrl] = useState(``);
	const version = query.get('version') === '2' ? '2' : '1';

	useEffect(() => {
		getDashboardData({ slug })
			.then(async (res) => {
				if (res['error']) {
					return;
				} else {
					//status = "offline"
					dispatch(setDashboardInfo(res));
					setWorkspaceUrl(res['byId'][workspaceId].url + '/ui');
				}
			})
			.catch((e) => {
				console.error('Error fetching profile data', e);
			});
	}, [dispatch, slug, workspaceId]);

	useEffect(() => {
		const frame = frameRef.current;
		if (!frame) {
			return;
		}
	}, [frameRef.current]);

	if (old || version === '1') {
		return (
			<div style={{ width: '100%', height: '100%' }}>
				<iframe
					title={`Dashboard UI: ${workspaceId}`}
					src={workspaceUrl}
					style={{
						width: '100%',
						height: '100%',
						display: 'block',
						position: 'relative',
					}}
					ref={frameRef}
				></iframe>
			</div>
		);
	}

	return (
		<Flex width="100%" height="100%" bg="light.theme.yellow.200">
			<WorkspaceDashboardWrapper
				workspaceId={workspaceId}
				fullScreen
				// expanded
			/>
		</Flex>
	);
};

export default DashboardUI;
