import { Box, createStandaloneToast, Flex } from '@chakra-ui/react';
import {
	SkillVisualizer,
	segregateFiles,
	SkillVisualizerSkeleton,
} from '@mayahq/ui';
import JSZip from 'jszip';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import shallow from 'zustand/shallow';
import { updateRecipe } from '../../../../../functions/pac-engine/recipe';
import { getSkill } from '../../../../../functions/pac-engine/skills';
import skillTeach from '../../../../../functions/pac-engine/skillTeach';
import { updateStep } from '../../../../../functions/pac-engine/step';
import verifySkill from '../../../../../functions/pac-engine/verifySkill';
import MayaFilledButton from '../../../../../library/buttons/FilledButton';
import SectionHeader from '../../../../../library/text/SectionHeader';
import theme from '../../../../../library/theme';
import { useStore } from '../../../../Editor/zustand';
import useStepperStore from '../../../StepperStore';

const toast = createStandaloneToast({ theme: theme });

const ModifySkillStepper = () => {
	const [initialSkillData, setInitialSkillData] = useState(null);
	const [skillData, setSkillData] = useState({
		name: '',
		steps: [],
		recipes: [],
		docs: [],
	});
	const [teachLoading, setTeachLoading] = useState(false);
	const [getSkillLoading, setGetSkillLoading] = useState(false);

	const currentStepState = useStepperStore(
		(state) => state.steps.byId['teach.modifySkill']?.state,
		shallow
	);
	const closeStepper = useStepperStore((state) => state.closeStepper, shallow);
	const clearSteps = useStepperStore((state) => state.clearSteps, shallow);

	useEffect(() => {
		const { skillId, stepperFlowType } = currentStepState;

		setGetSkillLoading(true);
		getSkill(skillId)
			.then((res) => {
				setInitialSkillData(res);
				// @ts-ignore
				setSkillData(res);
				console.log('got skill response', res);
			})
			.catch((e) => {
				console.error('got skill error', e);
				toast({
					title: 'Error fetching skill',
					description:
						'An unknown error occured while getting the skill. Please refresh and try again.',
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			})
			.finally(() => {
				setGetSkillLoading(false);
			});
	}, [currentStepState]);

	const handleModify = async () => {
		const recipesToModify = {};
		const stepsToModify = {};

		const currentRecipePublicData = {};
		const originalRecipePublicData = {};
		skillData.recipes.forEach(
			(recipe) => (currentRecipePublicData[recipe.id] = recipe.public)
		);
		initialSkillData.recipes.forEach(
			(recipe) => (originalRecipePublicData[recipe.id] = recipe.public)
		);
		Object.keys(currentRecipePublicData).forEach((recipeId) => {
			if (
				currentRecipePublicData[recipeId] !==
				originalRecipePublicData[recipeId]
			) {
				recipesToModify[recipeId] = currentRecipePublicData[recipeId];
			}
		});

		const currentStepPublicData = {};
		const originalStepPublicData = {};
		skillData.steps.forEach(
			(step) => (currentStepPublicData[step.id] = step.public)
		);
		initialSkillData.steps.forEach(
			(step) => (originalStepPublicData[step.id] = step.public)
		);
		Object.keys(currentStepPublicData).forEach((stepId) => {
			if (currentStepPublicData[stepId] !== originalStepPublicData[stepId]) {
				stepsToModify[stepId] = currentStepPublicData[stepId];
			}
		});

		const recipePromises = Object.keys(recipesToModify).map((recipeId) =>
			updateRecipe(recipeId, {
				public: recipesToModify[recipeId],
				description: 'ooga booga eegaaa bruh',
			})
		);
		const stepPromises = Object.keys(stepsToModify).map((stepId) =>
			updateStep(stepId, { public: stepsToModify[stepId] })
		);

		Promise.all([...recipePromises, ...stepPromises])
			.then(() => {
				toast({
					title: 'Skill successfully updated!',
					status: 'success',
					duration: 5000,
					isClosable: true,
				});
			})
			.catch(() => {
				toast({
					title: 'Failed to update some components of the skill.',
					description: 'Please refresh the page.',
					status: 'error',
					duration: 5000,
					isClosable: true,
				});
			});
	};

	return (
		<>
			<Flex direction="column" height="100%">
				<Box
					display="flex"
					flexDir="column"
					justifyContent="center"
					alignItems="center"
				>
					<Box paddingTop="20px" paddingBottom="20px">
						<SectionHeader
							heading="Review the skill."
							subheading="The following artifacts will be taught and become available to all your workers."
						/>
					</Box>
				</Box>
				<Flex
					flex="1 1 auto"
					position="relative"
					width="100%"
					overflow="auto"
					css={{
						'&::-webkit-scrollbar': { display: 'none' },
					}}
				>
					<Flex
						position="absolute"
						width="100%"
						flex="1 1 auto"
						direction="column"
						marginBottom="1rem"
						height="100%"
						overflow="auto"
						css={{
							'&::-webkit-scrollbar': { display: 'none' },
						}}
					>
						{!getSkillLoading && (
							<SkillVisualizer
								skillData={skillData}
								setSkillData={setSkillData}
								showPreview={true}
								allowToggle={true}
							/>
						)}
						{getSkillLoading && (
							<SkillVisualizerSkeleton showPreview={true} />
						)}
					</Flex>
				</Flex>
				<Box
					display="flex"
					flexDir="column"
					justifyContent="center"
					alignItems="center"
					mt="32px"
					mb="32px"
				>
					<MayaFilledButton
						text="Save"
						onClick={handleModify}
						buttonProps={{
							width: '160px',
							height: '3rem',
						}}
						colorScheme="purple"
						isLoading={teachLoading}
					/>
				</Box>
			</Flex>
		</>
	);
};

const sectionObject = {
	heading: 'modify skill',
	component: <ModifySkillStepper />,
};

export default sectionObject;
