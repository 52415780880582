import React from 'react';
import { Flex, Box, Image } from '@chakra-ui/react';
import SectionHeader from '../../../../../library/text/SectionHeader';
import Button from '../../../../../library/buttons/FilledButton';
import _ from 'lodash';
import produce from 'immer';
import ActionBtn from '../../../../../library/buttons/ActionBtn';
import TallOption from './TallOption';
import tailorMade from '../../../../../library/assets/tailormade.svg';
import buildFromScratch from '../../../../../library/assets/build-from-scratch.svg';
import useATemplate from '../../../../../library/assets/use-a-template.svg';
import isElectron from 'is-electron';
import useStepperAnalytics from '../../../hooks/useStepperAnalytics';
import MayaFilledButton from '../../../../../library/buttons/FilledButton';
import Avatar from '../../../../Dashboard/Background/TopBar/TopBarRight/ProfileButton/Avatar';
import switchProfile from '../../../../../functions/profile/switchProfile';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { config } from '../../../../../config';
import { stepperFlowTypes } from '../../registry';
import { v4 } from 'uuid';
import useStepperStore from '../../../StepperStore';

var buildOptions = [
	{
		heading: 'Build from scratch',
		subheading:
			'Assemble 200+ Maya blocks together in minutes using our drag-and-drop editor.',
		image: useATemplate,
		primaryBtnText: 'New Workspace',
		secondaryBtnText: 'Use Existing',
		bottomTextOne: 'max effort',
		bottomTextTwo: 'most affordable',
		link: {
			step: 'new.workspace',
			initState: isElectron()
				? { platformOption: 'On Desktop', device: '' }
				: { platformOption: 'On Cloud', device: '' },
		},
		secondaryLink: {
			route: '/workers',
		},
	},
	{
		heading: 'Use a template.',
		subheading:
			'Mix and match pre-made templates and collections from the Maya store.',
		image: buildFromScratch,
		primaryBtnText: 'Explore',
		secondaryBtnText: '',
		bottomTextOne: 'medium effort',
		bottomTextTwo: 'pay as you go',
		link: {
			route: '/store',
		},
	},
	{
		heading: 'Get it tailormade',
		subheading:
			'We use custom code-writing AI to quickly build an automation to order. ',
		image: tailorMade,
		primaryBtnText: 'New Order',
		secondaryBtnText: '',
		bottomTextOne: 'least effort',
		bottomTextTwo: 'premium cost',
		link: {
			step: 'new.order',
			initState: {},
		},
	},
];

export const TeamDialog = () => {
	const { selectedProfile, teams } = useSelector(
		/**
		 *
		 * @param {import('../../../../../redux/reducers/types/profileReducer').ProfilesState} state
		 * @returns
		 */
		(state) => ({
			selectedProfile: state.profiles.selected,
			teams: Object.values(state.profiles.profiles).filter(
				(profile) => profile.isTeam
			),
		})
	);
	const location = useLocation();
	const { addStep, openStepper, nextStep } = useStepperStore((state) => {
		return {
			updateStepState: state.updateStepState,
			addStep: state.addStep,
			nextStep: state.nextStep,
			currentStep: state.currentStep,
			steps: state.steps,
			openStepper: state.openStepper,
		};
	});

	function onCreateTeam() {
		const initState = {
			stepperFlowType: stepperFlowTypes.planWall.CREATE_TEAM,
			stepperSessionId: v4(),
		};

		addStep('onboarding.createTeam', initState);
		return nextStep();
		// console.log('slos onCreate done', addStep, openStepper)
	}

	return (
		<Flex direction="column" alignItems="center" height="100%">
			<Box paddingY="20px">
				<Flex
					textStyle="serif.lg"
					textAlign="center"
					justifyContent="center"
				>
					Solo plan is coming soon.
				</Flex>
				<Flex
					textStyle="sans.sm"
					justifyContent="center"
					textAlign="center"
					mt="5px"
					color="light.font.gray.400"
					mb="32px"
				>
					{teams.length === 0
						? 'We are still working on our solo plan. Till then, please create a new team to continue.'
						: 'We are still working on our solo plan. Till then, please switch to your team to continue.'}
				</Flex>
			</Box>
			<Flex>
				{teams.length === 0 && (
					<MayaFilledButton
						text="Create new team"
						onClick={onCreateTeam}
						colorScheme={'purple'}
						size="sm"
					/>
				)}

				{teams.length > 0 && (
					<Flex
						direction="column"
						bg="#fff"
						padding="16px 16px 16px 8px"
						alignItems="center"
						borderRadius="2px"
						border="1px solid"
						borderColor="light.border.gray.200"
					>
						<Flex width="100%">
							<Box mr="16px">
								<Avatar name={teams[0].name} size="40" />
							</Box>
							<Box textStyle="sans.sm" color="light.font.gray.400">
								<Box>{teams[0].name}</Box>
								<Box textStyle="sans.xs" color="light.font.gray.300">
									{teams[0].users.length} members
								</Box>
							</Box>
						</Flex>

						<MayaFilledButton
							text="Switch to this team"
							colorScheme="gray"
							onClick={() =>
								switchProfile(teams[0].profileSlug, location)
							}
							size="sm"
							buttonProps={{
								width: '100%',
								ml: '2.5',
								mt: '16px',
							}}
						/>
					</Flex>
				)}
			</Flex>
		</Flex>
	);
};

/**
 * Options to build new automation.
 *
 * Design reference : https://www.figma.com/file/KAF1ostHf3VBeZsctGl3ZA/MayaDesign?node-id=2753%3A3612
 * @returns
 */
const NewAutomation = () => {
	useStepperAnalytics();

	const selectedProfile = useSelector(
		/**
		 *
		 * @param {import('../../../../../redux/reducers/types/profileReducer').ProfilesState} state
		 * @returns
		 */
		(state) => state.profiles.profiles[state.profiles.selected]
	);

	// if (!selectedProfile.isTeam && !config.settings.soloPlanAllowed) {
	// 	return <TeamDialog />;
	// }

	return (
		<Box
			display="flex"
			flexDir="column"
			justifyContent="center"
			alignItems="center"
			height={{ sm: 'auto', md: 'auto', lg: '75vh', xl: 'auto' }}
		>
			<Box paddingTop="20px" paddingBottom="20px">
				<SectionHeader
					heading="How do you want to get this built?"
					subheading="Choose the option that suits your time and budget."
				/>
			</Box>
			<Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gridGap="10px">
				{buildOptions.map((option, i) => {
					return (
						<TallOption
							key={i}
							heading={option.heading}
							subheading={option.subheading}
							img={option.image}
							primaryBtnText={option.primaryBtnText}
							secondaryBtnText={option.secondaryBtnText}
							bottomTextOne={option.bottomTextOne}
							bottomTextTwo={option.bottomTextTwo}
							link={option.link}
							secondaryLink={option.secondaryLink}
						/>
					);
				})}
			</Box>
		</Box>
	);
};

const sectionObject = {
	heading: 'new application',
	component: <NewAutomation />,
};

export default sectionObject;
