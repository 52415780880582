import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { MayaFilledButton, StoreSection } from '@mayahq/ui';
import { StoreSectionNew } from './Dashboard/Sections/Store/StoreNew';

const UiTest = () => {
	return (
		<Flex direction="column" flex="1">
			<Text as="h1"> Ui Test</Text>
			<StoreSectionNew />
		</Flex>
	);
};

export default UiTest;
