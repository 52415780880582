import { config } from '../../config';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';
import GetProfileSlug from '../../util/ProfileSlug';

/**
 * # Stripe Create Checkout Session
 *
 * - Creates a checkout session on stripe and returns the url for the checkout page
 */
const stripeCreateCheckoutSession = APIFunction({
	BASEURL: config.apiServer.BASEURL,
	PATH_SEARCH: APIRoutes.apiService.stripeCreateCheckoutSession.PATH_SEARCH,
	PATH_METHOD: APIRoutes.apiService.stripeCreateCheckoutSession.PATH_METHOD,
});

/**
 * # Create Checkout Session
 *
 * - Creates a payment checkout session and returns its url
 *
 * @param {{
 * 	successUrl: string,
 * 	cancelUrl: string,
 * }} param0
 * @returns
 */
const createCheckoutSession = async ({ successUrl, cancelUrl }) => {
	const { slug: profileSlug } = GetProfileSlug();
	const res = await stripeCreateCheckoutSession({
		profileSlug,
		successUrl,
		cancelUrl,
	});
	return res.data;
};

export default createCheckoutSession;
