import React from 'react';
import { Box, useMediaQuery } from '@chakra-ui/react';
import { SmallCloseIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import ActionBtn from '../../../../library/buttons/ActionBtn';

/**
 * Hollow Outline Button
 *
 * @param { import('./types/body').Body } props
 * @returns
 */
const Body = ({ bodyContent, backBtnClick, closeBtnClick }) => {
	const [isSmallerThan900] = useMediaQuery('(max-width: 900px)');
	return (
		<Box
			width="100vw"
			paddingX={isSmallerThan900 ? '40px' : '100px'}
			display="flex"
			flexDirection="column"
			flex="1 1 auto"
			overflow="auto"
		>
			<Box
				display="flex"
				width="100%"
				justifyContent="space-between"
				height="40px"
			>
				{typeof backBtnClick === 'function' ? (
					<ActionBtn
						text={'BACK'}
						icon={<ChevronLeftIcon />}
						onClick={backBtnClick}
					/>
				) : null}
				{typeof closeBtnClick === 'function' ? (
					<ActionBtn
						text={'CLOSE'}
						icon={<SmallCloseIcon />}
						onClick={closeBtnClick}
					/>
				) : null}
			</Box>
			<Box flex="1 1 auto">{bodyContent}</Box>
		</Box>
	);
};

export default Body;
