import React, { useCallback, useEffect, useState } from 'react'
import {
    Flex
} from '@chakra-ui/react'
import { useHistory, useLocation } from 'react-router-dom'
import LibraryTopBar from './TopBar/TopBar'
import RecipeSection from './Recipes/RecipeSection'
import StepSection from './Steps/StepSection'
import DocumentSection from './Documents/DocumentSection'
import SkillsSection from './Skills/SkillsSection'
import { stepperFlowTypes } from '../../../Stepper/Sections/registry'
import useStepperStore from '../../../Stepper/StepperStore'
import { v4 } from 'uuid'
import useProfileSlug from '../../../../hooks/useProfileSlug'
import { getSkills } from '../../../../functions/pac-engine/skills'
import { useLibraryStore } from './zustand/libraryStore'
import shallow from 'zustand/shallow'

const Library = () => {
    const { addStep, openStepper } = useStepperStore()
    const history = useHistory()
    const location = useLocation()
    
    const query = new URLSearchParams(location.search)
    const type = query.get('type')
    const addSkill = useLibraryStore(state => state.addSkill, shallow)
    const setSelectedSkill = useLibraryStore(state => state.setSelectedSkill)
    

    function handleTeachButtonClick() {
        const stepperSessionId = v4()
        const initState = {
            stepperSessionId: stepperSessionId,
            stepperFlowType: stepperFlowTypes.teach.TEACH_SKILL,
            selecterWorkspaceId: null,
            onAddSkill: (skill) => {
                addSkill(skill)
                setSelectedSkill(skill.skill_id)
            }
        }
        addStep('teach.uploadSkillZip', initState)
        openStepper()
    }


    const updateQueryParam = useCallback(
		(vals) => {
			const search = new URLSearchParams(location.search);
			Object.keys(vals).forEach((key) => {
				search.set(key, vals[key]);
			});
			history.replace({
				pathname: location.pathname,
				search: search.toString(),
			});
		},
		[history, location]
	);

    useEffect(() => {
        const query = new URLSearchParams(location.search)
        const type = query.get('type')
        if (!type) {
            updateQueryParam({ type: 'skills' })
        }
    }, [location])


    return (
        <Flex
            direction='column'
            height='100%'
        >
            <Flex
                mb='32px'
            >
                <LibraryTopBar
                    onTypeChange={(type) => {updateQueryParam({ type: type })}}
                    currentType={type}
                    handleTeachClick={handleTeachButtonClick}
                />
            </Flex>
            <Flex
                height='100%'
                flex='1 1 auto'
            >
                    {type === 'skills' && (
                        <SkillsSection/>
                    )}
                    {type === 'recipe' && (
                        <RecipeSection />
                    )}
                    {type === 'step' && (
                        <StepSection />
                    )}
                    {type === 'document' && (
                        <DocumentSection />
                    )}
            </Flex>
        </Flex>
    )
}

export default Library