import create from 'zustand';
import produce, { current } from 'immer';

// Log every time state is changed
const log = (config) => (set, get, api) =>
	config(
		(args) => {
			console.log('Zustand  applying : useDashboardStore');
			set(args);
			console.log('Zustand  new state : useDashboardStore', get());
		},
		get,
		api
	);

export const useDashboardStore = create(
	log((set, get) => ({
		// STORE
		isLoading: true,
		refreshToggle: true,
		setLoading: (value) => {
			set(
				produce(
					/**
					 * @param { import('./types').DashboardState} draft
					 * @returns
					 */
					(draft) => {
						draft.isLoading = value;
					}
				)
			);
		},
		/**
		 * Refreshes the dashboard
		 */
		refreshDashboard: () => {
			set(
				produce(
					/**
					 * @param { import('./types').DashboardState} draft
					 * @returns
					 */
					(draft) => {
						var value = current(draft).refreshToggle;
						draft.refreshToggle = !value;
					}
				)
			);
		},
	}))
);

// // Setup initial state with an fleet info object.
// const initialState = {
// 	isLoading: true,
// 	refreshToggle: true,
// 	showTrialExpiredModal: false,
// };

// // Export the Device Reducer.
// export default (state = initialState, action) => {
// 	switch (action.type) {
// 		case actionTypes.SET_LOADING: {
// 			const { info } = action.payload;
// 			return { ...state, isLoading: info };
// 		}
// 		case actionTypes.SET_REFRESH_TOGGLE: {
// 			const { info } = action.payload;
// 			return { ...state, refreshToggle: info };
// 		}
// 		case actionTypes.SET_TRIAL_EXPIRY_MODAL_DISPLAY: {
// 			const { info } = action.payload;
// 			return { ...state, showTrialExpiredModal: info };
// 		}
// 		default:
// 			return state;
// 	}
// };
