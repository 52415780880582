import axios from 'axios';
import { config } from '../../config';
import APIRoutes from '../../util/APIRoutes';

const skillTeach = async ({ formData }) => {
	const url =
		config.pacEngine.baseUrl + APIRoutes.apiService.skillTeach.PATH_SEARCH;
	const method = APIRoutes.apiService.skillTeach.PATH_METHOD;
	// @ts-ignore
	const res = await axios({
		url,
		method,
		data: formData,
		headers: {
			'content-type': 'multipart/form-data',
		},
	});
	return res;
};

export default skillTeach;
