import { useSelector } from 'react-redux';

const useSessionId = (brainId) => {
	const sessionId = useSelector(
		/**
		 * @param {{
		 *  brains: import('../../../redux/reducers/types/brains').Brains
		 * }} state
		 */
		(state) => state.brains?.byId[brainId]?.sessionId
	);

	return sessionId;
};

export default useSessionId;
