import React from 'react';
import { Box } from '@chakra-ui/react';
import { BsFillPlayFill } from 'react-icons/bs';
import IconContainer from '../../../../../util/React_Icon';

const TriggerElement = ({
	align = 'left',
	onClick,
	icon = <BsFillPlayFill />,
}) => {
	return (
		<Box
			height="100%"
			width="33px"
			justifyContent="center"
			alignItems="center"
			position="absolute"
			display="flex"
			left={align === 'left' ? '-30px' : 'none'}
			right={align === 'right' ? '-30px' : 'none'}
			top="0"
			zIndex="-5"
		>
			<Box
				transition="all 0.1s cubic-bezier(.08,.52,.52,1)"
				bg="#f5f5f5"
				height="90%"
				width="33px"
				borderRadius="2px"
				display="flex"
				justifyContent="center"
				alignItems="center"
				outline="solid 1.7px #a3a3a3"
				cursor="pointer"
				_hover={{ bg: '#f9f9f9' }}
				onClick={onClick}
			>
				<IconContainer
					icon={icon}
					value={{
						color: '#525151',
						className: 'global-class-name',
						size: '17px',
					}}
				/>
			</Box>
		</Box>
	);
};

export default React.memo(TriggerElement);
