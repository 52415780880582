import React, { useCallback, useEffect } from 'react';
import { Icon, Spinner } from '@chakra-ui/react';

import { createStandaloneToast, Flex, Box, Button } from '@chakra-ui/react';
import { useStore } from '../zustand';
// import MayaFilledButton from '../../../library/buttons/FilledButton';
import { MayaFilledButton } from '@mayahq/ui';
import { BsCheckCircle, BsPlay } from 'react-icons/bs';
import DeployListener from './DeployListener';
import useChecklistItems from '../../Checklist/hooks/useChecklistItems';
import allChecklistItems from '../../Checklist/registry';
import { useSelector } from 'react-redux';
import useInstallMissingModules, {
	testState,
} from '../hooks/useInstallMissingModules';
import InterstitialModuleInstallDrawer from './InterstitialModuleInstallDrawer';
import analytics from '../../../util/Analytics';
import theme from '../../../library/theme';
import { CheckIcon } from '@chakra-ui/icons';
import { FiCheck } from 'react-icons/fi';
import IssuesSidebar from './IssuesSidebar';
import useSessionId from '../hooks/useSessionId';
import { usePacEngineStore } from '../Tools/pac-engine/zustand';

const toast = createStandaloneToast({ theme: theme });

/**
 * The DeployButton function is a React component that renders a button to deploy the current brain.
 */
const DeployButton = () => {
	const onDeploy = useStore(useCallback((state) => state.onDeploy, []));
	const getDeployableMayaFlow = useStore(
		useCallback((state) => state.getDeployableMayaFlow, [])
	);
	const dirty = useStore(useCallback((state) => state.dirty, []));
	const { installMissingModules, modulesInstalling, modulesToInstall } =
		useInstallMissingModules();

	const isModuleInstallationDrawerVisible = useStore(
		(state) => state.isModuleInstallationDrawerVisible
	);

	const setIsModuleInstallationDrawerVisible = useStore(
		(state) => state.setIsModuleInstallationDrawerVisible
	);

	const selectedProfile = useSelector(
		/**
		 *
		 * @param {import('../../../redux/reducers/types/profileReducer').ProfilesState} state
		 * @returns
		 */
		(state) => state.profiles.profiles[state.profiles.selected]
	);
	const { checklistItems, updateChecklistItem } = useChecklistItems();
	const deploying = useStore(useCallback((state) => state.autoDeploying, []));
	const setDeploying = useStore(
		useCallback((state) => state.setAutoDeploying, [])
	);
	const workspaceId = useStore(
		useCallback(
			/** @param {import('../../../../src/components/Editor/zustand/types').EditorStore} state */
			(state) => state.brainId,
			[]
		)
	);
	const setDirty = useStore(
		useCallback(
			/** @param {import('../../../../src/components/Editor/zustand/types').EditorStore} state */
			(state) => state.setDirty,
			[]
		)
	);

	const isGenerateLoading = usePacEngineStore(
		useCallback((state) => state.isGenerateLoading, [])
	);

	const sessionId = useSessionId(workspaceId);

	useEffect(() => {
		if (isGenerateLoading) {
			setDirty(false);
		} else {
			setDirty(true);
		}
	}, [isGenerateLoading, setDirty]);

	const handleDeploy = async () => {
		analytics.track(`Editor :: Deploy Button Clicked`, {});
		try {
			setDeploying(true);
			await installMissingModules();
			await onDeploy(sessionId);
			toast({
				title: 'Deployed',
				duration: 2000,
				isClosable: true,
				position: 'top',
				status: 'info',
			});
			analytics.track(`Editor :: Deploy Button Response`, {
				status: 'success',
			});
			if (
				selectedProfile.isTeam &&
				!checklistItems[allChecklistItems.installFirstAutomation]?.done
			) {
				updateChecklistItem(allChecklistItems.installFirstAutomation, true);
			}
		} catch (e) {
			console.error(e);
			if (e?.message === 'Request to changeSession failed') {
				setDirty(true);
			}
			toast({
				title:
					e?.message === 'Request to changeSession failed'
						? 'Sync Error'
						: 'Deploy Error',
				description:
					e?.message === 'exportedFlow is invalid!'
						? 'Invalid node configurations'
						: e?.message === 'Request to changeSession failed'
						? 'Failed to sync session. Please Deploy again.'
						: 'Unknown error occurred',
				status:
					e?.message === 'Request to changeSession failed'
						? 'warning'
						: 'error',
				duration: 3000,
				isClosable: true,
			});
			// setIsLoading(false);
			setDeploying(false);
			analytics.track(`Editor :: Deploy Button Response`, {
				status: 'failed',
			});
		}
	};

	let leftIcon = undefined;
	if (!deploying && dirty) {
		leftIcon = <BsPlay size="18px" />;
	} else if (!deploying && !dirty) {
		leftIcon = <FiCheck size="18px" />;
	}

	React.useEffect(() => {
		window.addEventListener('maya:deployevent', handleDeploy);

		return () => {
			window.removeEventListener('maya:deployevent', handleDeploy);
		};
	}, []);

	return (
		<>
			{modulesInstalling && (
				<InterstitialModuleInstallDrawer
					installState={modulesToInstall}
					isOpen={isModuleInstallationDrawerVisible}
					onClose={() => setIsModuleInstallationDrawerVisible(false)}
				/>
			)}
			<Box mr="7px" mb="1px">
				<Button
					leftIcon={leftIcon}
					bg="light.theme.purple.300"
					color="#fff"
					borderRadius="2px"
					fontFamily="ubuntu, sans-serif"
					fontWeight="400"
					fontSize="14.5px"
					mr="8px"
					width="150px"
					_hover={{
						bg:
							!deploying && !dirty
								? 'light.theme.gray.400'
								: 'light.theme.purple.400',
					}}
					_active={{
						bg:
							!deploying && !dirty
								? 'light.theme.gray.400'
								: 'light.theme.purple.400',
					}}
					_focus={{}}
					_loading={{ opacity: 1 }}
					_disabled={{
						bg: 'light.theme.gray.400',
					}}
					height="42px"
					isLoading={deploying}
					isDisabled={!deploying && !dirty}
					loadingText="Deploying"
					onClick={handleDeploy}
				>
					{deploying && <Box>Deploying</Box>}
					{!deploying && !dirty && <Box>Deployed</Box>}
					{!deploying && dirty && <Box>Deploy</Box>}
				</Button>
			</Box>
			<DeployListener setLoading={setDeploying} />
		</>
	);
};

export default DeployButton;
