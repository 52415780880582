import React, { useCallback, useEffect, useState } from 'react'
import {
    Flex, Box
} from '@chakra-ui/react'
import FlowVisualizer from '../../../../library/FlowVisualizer'
import useStepperStore from '../../StepperStore'
import MayaFilledButton from '../../../../library/buttons/FilledButton'
import getInstallerFlow from '../../../../functions/store/getInstallerFlow'
import { getModuleById } from '../../../../functions/store/getStoreModules'
import { getLongestTimeElapsedString } from '../../../../util/time'
import useStepperAnalytics from '../../hooks/useStepperAnalytics'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { useSelector } from 'react-redux'
import { config } from '../../../../config'
import { TeamDialog } from '../New/NewAutomation'

const QuickShareInstall = () => {
    useStepperAnalytics()
    const { updateStepState, addStep, nextStep, currentStep, steps } = useStepperStore((state) => {
        return {
            updateStepState: state.updateStepState,
            addStep: state.addStep,
            nextStep: state.nextStep,
            currentStep: state.currentStep,
            steps: state.steps,
        };
    });
    const [flow, setFlow] = useState([])
    const [buttonLoading, setButtonLoading] = useState(false)
    const { selectedProfile, teams} = useSelector(
		/**
		 * 
		 * @param {import('../../../../redux/reducers/types/profileReducer').ProfilesState} state 
		 * @returns 
		 */
		state => ({
			selectedProfile: state.profiles.profiles[state.profiles.selected],
			teams: Object.values(state.profiles.profiles).filter(profile => profile.isTeam)
		})
	)

    const getModulesFromFlowJSON = async mayaJson => {
        const flowDetails = await getInstallerFlow(mayaJson);
    
        const mods = {};
        const result = await Promise.all(
            flowDetails.currentFlow.modules.map(mod => getModuleById(mod._id))
        );
        result.forEach(mod => (mods[mod._id] = mod));
    
        // return mods;
        return { modules: mods, flowDetails }
    };

    const getFlowFromStepState = useCallback(() => {
        const currentStep = steps.byId['onboarding.quickShareInstall']
        const currentState = currentStep.state
        return currentState.flowToInstall
    }, [steps])

    const getFlowDetailsFromJson = useCallback(() => {
        const flow = getFlowFromStepState()
        const botInfoNode = flow.find(node => node.type === 'bot-info')
		const { created, title } = botInfoNode
        let elapsed = ''
        if (created) {
            elapsed = getLongestTimeElapsedString(created)
        }
        return { created, title, elapsed }
    }, [steps])

    useEffect(() => {
        setFlow(getFlowFromStepState())
    }, [steps])

    const goToNextStep = useCallback(async () => {
        const currentStep = steps.byId['onboarding.quickShareInstall']
        const currentState = currentStep.state

        const { flowToInstall } = currentState
        setButtonLoading(true)
        const { modules, flowDetails } = await getModulesFromFlowJSON(flowToInstall)
        const nextStepState = {
            ...currentState,
            flowModules: modules,
            moduleList: modules,
            flowDetails: flowDetails
        }
        addStep('workspace.select', nextStepState)
        
        setButtonLoading(false)
        nextStep()
    }, [steps])

    const flowDetails = getFlowDetailsFromJson(flow)
    const stepperFlowType = steps.byId['onboarding.quickShareInstall']?.state?.stepperFlowType

    const subheadings = {
        'onboarding.installFromQuickShare': "Thank you for answering our questions. Let's continue with the installation now.",
        'external.installFromQuickShare': "This will only take a few steps"
    }

    // if (!config.settings.soloPlanAllowed && !selectedProfile.isTeam) {
    //     return <TeamDialog />
    // }

    return (
        <Flex
            direction='column'
            alignItems='center'
            height='100%'
        >
            <Box paddingY='20px'>
                <Flex
                    textStyle='serif.lg'
                    textAlign='center'
                    justifyContent='center'
                >
                    Installing: "{flowDetails.title || ''}"
                </Flex>
                <Flex
                    textStyle='sans.sm'
                    justifyContent='center'
                    textAlign='center'
                    mt='5px'
                    color='light.font.gray.400'
                >
                    {subheadings[stepperFlowType]}
                </Flex>
            </Box>
            <Box
                position='relative'
                maxWidth='1000px'
                width='100%'
                mt='16px'
                overflow='auto'
                display='flex'
                flex='1 1 auto'
            >
                <Box
                    display='flex'
                    flexDirection='column'
                    position='absolute'
                    width='100%'
                    height='100%'
                >
                    {/* <Flex
                        // height='80px'
                        justifyContent='space-between'
                        // justifyContent='center'
                        alignItems='center'
                        mb='16px'
                    >
                        <Flex
                            textStyle='sans.md'
                            color='light.font.gray.400'
                        >
                            {flowDetails?.title || ''}
                        </Flex>
                        <Flex
                            textStyle='sans.sm'
                            color='light.font.gray.300'
                        >
                            Created {flowDetails.elapsed || ''} ago
                        </Flex>
                    </Flex> */}
                    {/* <Flex

                        border='1px solid'
                        borderColor='light.border.gray.200'
                        transform='translateY(-12px)' // Margin not working
                        borderRadius='2px'
                        // width='200px'
                        float='left'
                        mt='16px'
                        direction='column'
                    >
                        <Flex
                            justifyContent='center'
                            textAlign='center'
                            textStyle='sans.sm'
                            paddingX='20px'
                            // bg='light.theme.yellow.200'
                            bg='#fff'
                            color='light.font.gray.400'
                            height='48px'
                            alignItems='center'
                        >
                            {flowDetails?.title || ''}
                        </Flex>
                        <Flex
                            justifyContent='center'
                            textAlign='center'
                            textStyle='sans.xs'
                            paddingX='20px'
                            bg='#ffffff'
                            color='light.font.gray.300'
                            height='32px'
                            alignItems='center'
                        >
                            Created {flowDetails.elapsed || ''} ago
                        </Flex>
                    </Flex> */}
                    <FlowVisualizer
                        mayaFlowJson={flow} 
                        height='50vh'
                    />
                    <Flex
                        mt='32px'
                        pb='48px'
                        justifyContent='center'
                    >
                        <MayaFilledButton
                            text='Continue'
                            isLoading={buttonLoading}
                            isDisabled={buttonLoading}
                            onClick={goToNextStep}
                            rightIcon={<ArrowForwardIcon />}
                        />
                    </Flex>
                </Box>
            </Box>

        </Flex>
    )
}


const QuickShareInstallObject = {
    heading: 'install flow',
    component: <QuickShareInstall />
}

export default QuickShareInstallObject