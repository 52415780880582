import React from 'react';
import { FlexboxProps, Flex, Img } from '@chakra-ui/react';

type ModuleHeadingProps = {
	thumbnail: string;
	name: string;
	containerProps?: FlexboxProps;
};

const ModuleHeading = ({
	thumbnail,
	name,
	containerProps,
}: ModuleHeadingProps) => {
	return (
		<Flex {...containerProps}>
			<Flex
				// bg='tomato'
				alignItems="center"
			>
				<Img src={thumbnail} maxWidth="30px" />
			</Flex>
			<Flex
				textStyle="sans.lg"
				alignItems="center"
				color="light.font.gray.400"
				ml="12px"
			>
				{name}
			</Flex>
		</Flex>
	);
};

export default ModuleHeading;
