import _ from 'lodash';

/**
 * The newTabIdGenerator function generates a 16 digit tab id.
 * @return A string of 16 random alphanumeric characters.
 */
export const newTabIdGenerator = () => {
	const charsToBeUsed =
		'1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
	let ans = '';
	for (let i = 16; i > 0; i--) {
		ans += charsToBeUsed[Math.floor(Math.random() * charsToBeUsed.length)];
	}
	return ans;
};

const newTab = (tabId, nextTabLabelNum) => {
	return {
		disabled: false,
		mayaFlowByTab: [],
		reactFlowByTab: [],
		id: tabId || newTabIdGenerator(),
		info: '',
		label: `Flow ${nextTabLabelNum || 1}`,
		type: 'tab',
	};
};

export const getFlowByTabs = (raw) => {
	const tabs = [];
	if (raw.length === 0) {
		tabs.push(newTab());
		return tabs;
	}

	_.forEach(raw, function (o) {
		if (o.type === 'tab') {
			tabs.push({
				...o,
				mayaFlowByTab: [],
				reactFlowByTab: [],
			});
		} else if (o.type === 'subflow') {
			tabs.push({
				...o,
				mayaFlowByTab: [],
				reactFlowByTab: [],
				editing: false,
			});
		}
	});

	_.forEach(raw, function (node) {
		if (node.type !== 'tab') {
			_.forEach(tabs, (tab) => {
				if (tab.id === node.z) {
					const nodeIndex = tab.mayaFlowByTab.findIndex(
						(i) => i.id === node.id
					);
					if (nodeIndex !== -1) {
						tab.mayaFlowByTab[nodeIndex] = node;
					} else {
						tab.mayaFlowByTab.push(node);
					}
				}
			});
		}
	});
	return tabs;
};

export const getMayaFlowByTab = (flow, tabId) => {
	return _.filter(flow, (n) => n.z === tabId);
};

/** Converting the MayaLang graph to a react-flow graph. */
export const parseMayaLangToReactFlow = (
	nodeRedFlowDef,
	scalePosition = 1,
	type = 'special'
) => {
	// console.log('✨ : nodeRedFlowDef', nodeRedFlowDef);
	const nodes = [],
		edges = [],
		configNodes = [];
	_.forEach(nodeRedFlowDef, (curr) => {
		// Filters the config nodes
		if (typeof curr.x === 'number' && typeof curr.y === 'number') {
			var currColors = {};
			if (!curr.color) {
				var color = getColorsRandom();

				currColors['color'] = color.color;
				currColors['darkColor'] = color.darkColor;
			}
			nodes.push({
				id: curr.id,
				_id: curr._id ? curr._id : curr.id,
				type: type,
				data: {
					type: curr.type,
					node: { ...curr, ...currColors },
				},
				position: {
					x: curr.x * scalePosition,
					y: curr.y * scalePosition,
				},
				sourcePosition: 'right',
				targetPosition: 'left',
			});
			if (Array.isArray(curr.wires) && curr.wires.length > 0) {
				curr.wires.forEach((outputArray, idx) => {
					if (Array.isArray(outputArray) && outputArray.length > 0) {
						outputArray.forEach((wireId) => {
							edges.push({
								id: `e${curr.id}-${wireId}`,
								source: curr.id,
								target: wireId,
								sourceHandle: idx.toString(),
								type: 'custom',
								// targetHandle: Not required because target handle will always be 1
							});
						});
					}
				});
			}
		} else {
			configNodes.push(curr);
		}
	});
	return { nodes, edges, configNodes };
};

/** Converting the react-flow graph to a MayaLang graph. */
export const parseReactFlowtoMayaLang = ({ nodes, edges }) => {
	var flows = [];

	for (let node of nodes) {
		if (node.type === 'subflowIO') {
			continue;
		}
		const wires = [];
		const outputs = node.data.node.wires
			? node.data.node.wires.length
			: node.data.node.outputs || 1;
		for (let i = 0; i < outputs; i++) {
			wires.push([]);
		}
		const curr = {
			...node.data.node,
			x: node.position.x,
			y: node.position.y,
			id: node.id,
			_id: node._id ? node._id : node.id,
			infoEditor: undefined,
			wires: wires,
		};

		for (let edge of edges) {
			if (edge.source && edge.target && edge.source === curr.id) {
				if (
					edge.source.substring(0, 3) === 'in-' ||
					edge.target.substring(0, 4) === 'out'
				) {
					continue;
				}
				const sourceId = edge.sourceHandle || 0;
				curr.wires[parseInt(sourceId)].push(edge.target);
			}
		}

		flows.push(curr);
	}
	return flows;
};

/**
 * Converts subflow I/O nodes to React flow
 */
export const parseSubfowIOToReactFlow = (subflow, scalePosition = 1) => {
	// console.log('✨ : subflow', subflow);
	const nodes = [],
		edges = [];
	const utilFunc = (arr, type, subflowInst) => {
		arr.forEach((node, idx) => {
			const nodeId = node.id || `${type}-${idx.toString()}`;
			nodes.push({
				id: nodeId,
				data: {
					name: idx.toString(),
					type: 'subflowIO',
					io: type,
					direction: node.direction,
				},
				type: 'subflowIO',
				position: {
					// Check if NaN and reset it to 0
					x: (Number.isFinite(node.x) ? node.x : 0) * scalePosition,
					y: (Number.isFinite(node.y) ? node.y : 0) * scalePosition,
				},
				sourcePosition: 'right',
				targetPosition: 'left',
			});
			node.wires.forEach((wire) => {
				if (type === 'in') {
					edges.push({
						id: `e${nodeId}-${wire.id}`,
						source: nodeId,
						target: wire.id,
						sourceHandle: '0',
						type: 'custom',
					});
				} else if (type === 'out') {
					edges.push({
						id: `e${wire.id}-${nodeId}`,
						source: wire.id,
						target: nodeId,
						sourceHandle: wire.port.toString(),
						type: 'custom',
					});
				}
			});
		});
	};

	utilFunc(subflow.in, 'in', subflow);
	utilFunc(subflow.out, 'out', subflow);
	// console.log('returning', nodes, edges);
	return { nodes, edges };
};

/**
 * Converts subflow I/O nodes from react flow to subflow in and out
 */
export const parseReactFlowToSubflowIO = ({ nodes, edges }) => {
	const outNodes = [],
		inNodes = [];
	for (let node of nodes) {
		if (node.type === 'subflowIO') {
			const wires = [];
			for (let edge of edges) {
				if (edge.source === node.id) {
					wires.push({
						id: edge.target,
					});
				} else if (edge.target === node.id) {
					wires.push({
						id: edge.source,
						port: parseInt(edge.sourceHandle),
					});
				}
			}
			const newNode = {
				x: node.position.x,
				y: node.position.y,
				wires,
			};
			if (node.data.io === 'in') {
				inNodes.push(newNode);
			} else {
				outNodes.push(newNode);
			}
		}
	}
	return { in: inNodes, out: outNodes };
};

/**
 * Check if flow is valid
 * @param {*} flow
 */
export const isFlowValid = (flow) => {
	return !_.some(flow, { valid: false });
};

export const colorNames = [
	'green',
	'blue',
	'red',
	'orange',
	'yellow',
	'gray',
	'white',
	'purple',
];

export const getColors = (colorName) => {
	const colorMap = {
		green: {
			color: '#619259',
			darkColor: '#4F7C48',
		}, // dark : {r: 0.5, g: 0.7, b: 0.5}, light : {r: 0.8, g: 0.9, b: 0.8}
		blue: {
			color: '#6F7F99',
			darkColor: '#526788',
		}, // {r: 0.7, g: 0.7, b: 0.8} {r: 0.6, g: 0.8, b: 0.8}
		red: {
			color: '#996F6F',
			darkColor: '#905454',
		}, // {r: 0.9, g: 0.6, b: 0.6}
		yellow: {
			color: '#AE9554',
			darkColor: '#9E8647',
		}, // rgb(255,255,0) -> rgb(255,255,204) (light) {r: 0.9, g: 0.9, b: 0.4} {r: 0.9, g: 0.9, b: 0.7}
		orange: {
			color: '#9F4E2A',
			darkColor: '#884324',
		}, // 	rgb(255,165,0) {r: 0.9, g: 0.7, b: 0.5}
		gray: {
			color: '#808080',
			darkColor: '#6A6A6A',
		}, // rgb(211,211,211) {r: 0.8, g: 0.8, b: 0.8}
		white: {
			color: '#adadad',
			darkColor: '#808080',
		}, // rgb(255,255,255) {r: 1, g: 1, b: 1}
		purple: {
			color: '#674C80',
			darkColor: '#553C6B',
		}, // purple : rgb(138,43,226) {r: 0.8, g: 0.7, b: 0.8} {r: 0.9, g: 0.9, b: 1}
	};
	return { ...colorMap[colorName], colorName };
};

/**
 * returns a random light and dark color
 * @returns
 */
export const getColorsRandom = () => {
	const colorName =
		colorNames[Math.trunc(Math.random() * 100) % colorNames.length];
	return getColors(colorName);
};

/**
 *	Get all selected nodes/edges as maya flow
 * @param {import('../../../zustand/types').ReactFlowByTab} reactFlow
 * @returns {import('../../../zustand/types').MayaFlowEntity[]}
 */
export const getSelectedMayaFlow = (reactFlow) => {
	const selectedNodes = reactFlow.nodes.filter((n) => n.selected);
	const selectedEdges = reactFlow.edges.filter((e) => e.selected);
	return parseReactFlowtoMayaLang({
		nodes: selectedNodes,
		edges: selectedEdges,
	});
};
