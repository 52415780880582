import {
	Box,
	Button,
	CircularProgress,
	Flex,
	useMediaQuery,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { AiOutlineRedo, AiOutlineUndo } from 'react-icons/ai';
import { BiGitBranch } from 'react-icons/bi';
import { FiCheck, FiCopy, FiFileText, FiPlay } from 'react-icons/fi';
import useInstallMissingModules from '../../../hooks/useInstallMissingModules';
import InterstitialModuleInstallDrawer from '../../../Workspace/InterstitialModuleInstallDrawer';
import { useStore } from '../../../zustand';

import { createStandaloneToast } from '@chakra-ui/react';
import { FiTrash } from 'react-icons/fi';
import shallow from 'zustand/shallow';
import clearSession from '../../../../../functions/pac-engine/clearSession';
import parseRecipe from '../../../../../functions/pac-engine/parseRecipe';
import redoSession from '../../../../../functions/pac-engine/redoSession';
import undoSession from '../../../../../functions/pac-engine/undoSession';
import theme from '../../../../../library/theme';
import { parseMayaLangToReactFlow } from '../../../Workspace/functions/FlowMethods';
import getRedInstance from '../../../Workspace/functions/getRedInstance';
import ControlIconButton from '../../GenerateDAG/ControlIconButton';
import { createRecipeWithEmptyStep } from '../../GenerateDAG/utils';
import { checkIfAllStepsAreGenerated, overwriteTabProperty } from '../utils';
import { usePacEngineStore } from '../zustand';

const toast = createStandaloneToast({ theme: theme });

const SynthesisSidebarHeader = ({
	sessionId,
	handleGenerate,
	isGenerateLoading,
}) => {
	// LOCAL STATES
	const [deployLoading, setDeployLoading] = useState(false);
	const [isCopied, setIsCopied] = useState(false);
	const [isClearing, setIsClearing] = useState(false);
	const [isSmallerThan800] = useMediaQuery('(max-width: 800px)');

	const { installMissingModules, modulesInstalling, modulesToInstall } =
		useInstallMissingModules();

	const tabs = useStore((state) => state.tabs);
	const tabId = useStore((state) => state.tabs.activeTab);
	const setDirty = useStore((state) => state.setDirty);
	const setFlowToTab = useStore((state) => state.setFlowToTab);
	const onDeploy = useStore((state) => state.onDeploy, shallow);
	const renameTab = useStore((state) => state.renameTab, shallow);

	const recipe = usePacEngineStore((state) => state.recipe);
	const serverRecipe = usePacEngineStore((state) => state.serverRecipe);
	const setRecipe = usePacEngineStore((state) => state.setRecipe);
	const setServerRecipe = usePacEngineStore((state) => state.setServerRecipe);
	const setServerFlow = usePacEngineStore((state) => state.setServerFlow);

	const areAllStepsGenerated = checkIfAllStepsAreGenerated({
		recipe: usePacEngineStore.getState().recipe,
	});

	const handleDeploy = async () => {
		setDeployLoading(true);
		try {
			await installMissingModules();
			await onDeploy(sessionId);
			setDeployLoading(false);
			toast({
				title: 'Deployed',
				duration: 2000,
				isClosable: true,
				position: 'top',
				status: 'info',
			});
		} catch (e) {
			console.error(e);
			toast({
				title: 'Deploy Error!',
				description:
					e?.message === 'exportedFlow is invalid!'
						? 'Invalid node configurations'
						: 'Unknown error occurred',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
			setDeployLoading(false);
		}
	};

	const handleClear = async () => {
		try {
			setIsClearing(true);
			await new Promise((r) => setTimeout(r, 1000));
			await clearSession({ sessionId }).then(async (res) => {
				const newRecipe = res.data.response.steps;
				const mayaFlow = res.data.response.stitched_flow;
				const modifiedFlow = overwriteTabProperty({ mayaFlow, tabId });
				const clientRecipe = createRecipeWithEmptyStep();
				setRecipe({
					newRecipe: clientRecipe,
					diff: res.data.response.diff,
				});
				setServerRecipe({ newRecipe });
				setServerFlow({ newFlow: modifiedFlow });
				const RED = getRedInstance();
				RED.nodes.clear();
				const { nodes, edges } = parseMayaLangToReactFlow(mayaFlow);
				setFlowToTab(tabId, { nodes, edges });
				renameTab(tabId, `Flow ${tabs.allIds.length}`);
				await onDeploy(sessionId);
				toast({
					title: 'Cleared',
					duration: 2000,
					isClosable: true,
					position: 'top',
					status: 'info',
				});
			});
		} catch (err) {
			console.error(err);
			toast({
				title: 'Clear Error!',
				description: 'Unknown error occurred',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		} finally {
			setIsClearing(false);
		}
	};

	const handleCopy = async () => {
		try {
			const recipeObject = { recipe };
			const res = await parseRecipe({
				toParseItem: recipeObject,
				fromFormat: 'obj',
				toFormat: 'str',
			});
			let parsedText = res.data.replaceAll('(*)', '');
			if (parsedText.endsWith('\n')) {
				const lastNewline = parsedText.lastIndexOf('\n');
				if (lastNewline !== -1) {
					parsedText = parsedText.slice(0, lastNewline);
				}
			}
			await navigator.clipboard.writeText(parsedText);
			setIsCopied(true);
			setTimeout(() => {
				setIsCopied(false);
			}, 1500);
			toast({
				title: 'Copied',
				duration: 2000,
				isClosable: true,
				position: 'top',
				status: 'info',
			});
		} catch (err) {
			console.error(err);
			toast({
				title: 'Copy Error!',
				description: 'Failed to copy the Recipe. Please try again',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	const handleUndo = async () => {
		try {
			const res = await undoSession({ sessionId });
			console.log(res);
			const mayaFlow = res.data.response.stitched_flow;
			const allSteps = res.data.response.steps;
			const modifiedFlow = overwriteTabProperty({
				mayaFlow,
				tabId,
			});
			setRecipe({
				newRecipe: allSteps,
				diff: null,
				renderIncrementally: false,
			});
			setServerRecipe({ newRecipe: allSteps });
			setServerFlow({ newFlow: modifiedFlow });
			const { nodes, edges } = parseMayaLangToReactFlow(
				modifiedFlow,
				1,
				'transient'
			);
			setFlowToTab(tabId, {
				nodes,
				edges,
			});
		} catch (err) {
			console.error(err);
			toast({
				title: 'Undo Error!',
				description: 'Undo failed. Please try again',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	const handleRedo = async () => {
		try {
			const res = await redoSession({ sessionId });
			console.log(res);
			const mayaFlow = res.data.response.stitched_flow;
			const allSteps = res.data.response.steps;
			const modifiedFlow = overwriteTabProperty({
				mayaFlow,
				tabId,
			});
			setRecipe({
				newRecipe: allSteps,
				diff: null,
				renderIncrementally: false,
			});
			setServerRecipe({ newRecipe: allSteps });
			setServerFlow({ newFlow: modifiedFlow });
			const { nodes, edges } = parseMayaLangToReactFlow(
				modifiedFlow,
				1,
				'transient'
			);
			setFlowToTab(tabId, {
				nodes,
				edges,
			});
		} catch (err) {
			console.error(err);
			toast({
				title: 'Redo Error!',
				description: 'Redo failed. Please try again',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	let isGenerateDisabled = false;
	if (
		Object.keys(recipe)?.length === 1 &&
		recipe[Object.keys(recipe)[0]]?.text === ''
	) {
		isGenerateDisabled = true;
	} else {
		isGenerateDisabled =
			areAllStepsGenerated &&
			Object.keys(recipe)?.length === Object.keys(serverRecipe)?.length;
	}

	return (
		<Box
			bg="light.theme.yellow.200"
			borderBottom="1px solid"
			borderColor="light.border.gray.100"
		>
			<Flex
				height="42px"
				borderBottom="1px solid"
				borderColor="light.border.gray.100"
				padding="8px 16px"
				justifyContent="space-between"
				alignItems="center"
			>
				<Flex>
					<ControlIconButton
						icon={
							isCopied ? <FiCheck size="15px" /> : <FiCopy size="15px" />
						}
						tooltip="Copy"
						onClick={handleCopy}
					/>
					<ControlIconButton
						icon={
							isClearing ? (
								<CircularProgress
									isIndeterminate
									color="gray.500"
									size="13px"
								/>
							) : (
								<FiTrash size="15px" />
							)
						}
						tooltip="Clear"
						onClick={async () => await handleClear()}
					/>
					<ControlIconButton
						icon={<AiOutlineUndo size="15px" />}
						tooltip="Undo"
						onClick={async () => await handleUndo()}
					/>
					<ControlIconButton
						icon={<AiOutlineRedo size="15px" />}
						tooltip="Redo"
						onClick={async () => await handleRedo()}
					/>
					{isSmallerThan800 ? (
						<ControlIconButton
							icon={
								deployLoading ? (
									<CircularProgress
										size="15px"
										isIndeterminate
										color="light.theme.gray.400"
									/>
								) : (
									<FiPlay size="15px" onClick={handleDeploy} />
								)
							}
							tooltip="Deploy (Shift + Enter)"
						/>
					) : null}
					<a
						href="https://mayalabs.io/docs"
						target="_blank"
						rel="noreferrer"
					>
						<ControlIconButton
							icon={<FiFileText size="15px" />}
							tooltip="Docs"
						/>
					</a>
				</Flex>
				<Flex>
					<Button
						// bg='light.theme.gray.500'
						borderRadius="2px"
						bg="light.theme.gray.500"
						_hover={{
							backgroundColor: 'light.theme.gray.600',
						}}
						color="#fff"
						size="sm"
						leftIcon={<BiGitBranch />}
						isLoading={isGenerateLoading}
						// isDisabled={isGenerateDisabled}
						loadingText="Generating..."
						spinner={null}
						onClick={handleGenerate}
						_focus={{ border: 'none' }}
					>
						Generate
					</Button>
				</Flex>
			</Flex>
			{modulesInstalling && (
				<InterstitialModuleInstallDrawer
					installState={modulesToInstall}
					isOpen={modulesInstalling}
				/>
			)}
		</Box>
	);
};

export default React.memo(SynthesisSidebarHeader);
