import AsyncLock from 'async-lock';
import axios from 'axios';
import { config } from '../config';
import { refreshTokens } from '../functions/Auth/login';
import { logoutUser, setAuthTokens } from '../redux/actions/user';
import { store } from '../redux/store';
import {
	requestIsForAppBackend,
	requestIsToApiGateway,
	requestIsToAuthService,
	requestIsToWorkspaceServer,
} from '../util/URL';

export function registerInterceptors() {
	const lock = new AsyncLock();
	/**
	 * This interceptor takes any outgoing request and adds the Authorization header
	 * to it if its going to the app backend server.
	 */
	// @ts-ignore
	axios.interceptors.request.use((requestConfig) => {
		if (
			requestIsForAppBackend(requestConfig) ||
			requestIsToWorkspaceServer(requestConfig) ||
			requestIsToAuthService(requestConfig) ||
			requestIsToApiGateway(requestConfig)
		) {
			const state = store.getState();
			const { accessToken, refreshToken } = state.user.tokens;
			const selectedProfileSlug = state.profiles.selected;
			const status = state.user.status;

			if (status === 'online') {
				requestConfig.headers.Authorization = `Bearer ${accessToken}`;
			}

			if (!requestConfig.headers.profileSlug) {
				requestConfig.headers.profileSlug = selectedProfileSlug;
			}
		}

		// requestConfig.headers.user = JSON.stringify({ profileSlug: 'mayahq' })
		return requestConfig;
	});

	/**
	 * This interceptor takes any incoming response and checks if it has status 401.
	 * If it does, it attempts to try the request again with updated tokens. It gets
	 * these tokens either from the redux state (given another request caused the
	 * update recently), or by refreshing it via the fusionAuth API.
	 */
	axios.interceptors.response.use(
		(response) => response,
		async (error) => {
			if (!error.response) {
				return;
			}

			// Return immediately if this response is not coming from
			// the application server
			if (!requestIsForAppBackend(error.response.config)) {
				return;
			}

			if (error.response.status !== 401) {
				return Promise.reject(error);
			}

			if (!error.response.data.loginAgain) {
				return Promise.reject(error);
			}

			return await new Promise((resolve, reject) => {
				/**
				 * Parallel requests could fail at the same time. If they fail with 401,
				 * we only want one of them to update the token and the rest to use the new
				 * value. So, the below code needs to be protected with a lock.
				 */
				console.log('login :: Waiting for lock');
				lock.acquire(
					'refresh',
					async (done) => {
						console.log('login :: Lock acquired');
						await new Promise((resolve) => setTimeout(resolve, 5000));
						try {
							const state = store.getState();
							const { accessToken, refreshToken, lastUpdated } =
								state.user.tokens;

							let newAccessToken;

							// Don't refresh if tokens were refreshed within last 5 mins
							if (Date.now() - lastUpdated < 5 * 60 * 1000) {
								console.log('login :: Tokens already refreshed');
								newAccessToken = accessToken;
							} else {
								console.log(
									'login :: Refreshed tokens not found locally, attempting global refresh:'
								);

								const { accessToken: aToken, refreshToken: rToken } =
									await refreshTokens({ refreshToken });

								console.log('login :: Tokens refreshed');

								store.dispatch(
									setAuthTokens({
										accessToken: aToken,
										refreshToken: rToken,
										lastUpdated: Date.now(),
									})
								);

								newAccessToken = aToken;
							}

							error.response.config.headers[
								'Authorization'
							] = `Bearer ${newAccessToken}`;

							const uninterceptedAxiosInstance = axios.create();
							const response = uninterceptedAxiosInstance(
								error.response.config
							);
							done(null, response);
						} catch (e) {
							done(e, null);
						}
					},
					(err, result) => {
						console.log('login :: Lock released');
						if (err) {
							console.error('login :: There was an error', err);
							// We could not refresh the user's tokens, so we log them out.
							store.dispatch(logoutUser());
							return reject(err);
						}
						return resolve(result);
					}
				);
			});
		}
	);
}
