import React from 'react';
import { Box, CircularProgress, Img } from '@chakra-ui/react';
// @ts-ignore
import rightArrow from '../assets/right-arrow.png';

/**
 * Hollow Outline Button
 *
 * @param { import('./types/buttonProps').HollowButton } props
 * @returns
 */
const MayaHollowButton = (props) => {
	const {
		text,
		onClick,
		rightIcon,
		leftIcon,
		size,
		isLoading,
		isDisabled,
		buttonProps,
	} = props;

	const sizes = {
		sm: {
			textStyle: 'sans.xs',
			height: '40px',
		},
		md: {
			textStyle: 'sans.md',
			height: '60px',
		},
	};

	return (
		<Box
			border="solid 1.5px"
			borderColor="light.theme.gray.400"
			borderRadius="2px"
			textStyle={sizes[size] ? sizes[size].textStyle : 'sans.xs'}
			rightIcon={rightIcon}
			leftIcon={leftIcon}
			_hover={{ bg: isDisabled ? 'none' : 'light.theme.gray.200' }}
			transition="all 0.02s cubic-bezier(.08,.52,.52,1)"
			onClick={(event) => {
				if (isDisabled) {
				} else {
					onClick();
				}
			}}
			position="relative"
			display="flex"
			flexDirection="row"
			justifyContent="space-between"
			{...buttonProps}
			cursor="pointer"
			height={sizes[size] ? sizes[size].height : '40px'}
		>
			<Box
				height="100%"
				display="flex"
				flexDirection="row"
				justifyContent="center"
				alignItems="center"
				width="30px"
			/>

			<Box
				height="100%"
				display="flex"
				flexDirection="row"
				justifyContent="center"
				alignItems="center"
				color={'light.theme.gray.500'}
			>
				{text}
			</Box>
			{isLoading ? (
				<Box
					height="100%"
					display="flex"
					flexDirection="row"
					justifyContent="center"
					alignItems="center"
					paddingLeft="5px"
					paddingRight="15px"
				>
					<CircularProgress
						isIndeterminate
						color="white"
						size="15px"
						trackColor="none"
						capIsRound
					/>
				</Box>
			) : (
				<Box
					height="100%"
					display="flex"
					flexDirection="row"
					justifyContent="center"
					alignItems="center"
				>
					<Img
						src={rightArrow}
						top="0"
						left="0"
						margin="3px 0px 0px 2px"
						height="40%"
						paddingX="15px"
					/>
				</Box>
			)}
		</Box>
	);
};

export default MayaHollowButton;
