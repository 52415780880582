import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

// store
import { store } from '../../redux/store';
import APIFunction from '../../util/APIFetch/function';
import { config } from '../../config';
import APIRoutes from '../../util/APIRoutes';

const getMayaJson = async (slug, flowjson, brainId) => {
	const { user, brains } = store.getState();
	const profileSlug = slug ? slug : user.info.id;
	const brainDevice = brains.byId[brainId]['device']['platform'];

	let getMayaJsonAPIFn, getMayaJsonIPCFn, getMayaJsonResponse;
	if (brainDevice === 'cloud') {
		getMayaJsonAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.getMayaJson.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.getMayaJson.PATH_METHOD,
		});
		getMayaJsonResponse = await getMayaJsonAPIFn({
			profileSlug,
			flowjson,
			brainId,
		});
		if (getMayaJsonResponse['data']['error']) {
			return getMayaJsonResponse['data'];
		}
	} else {
		getMayaJsonIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.getMayaJson.EVENT,
		});
		getMayaJsonResponse = await getMayaJsonIPCFn({
			profileSlug,
			flowjson,
			brainId,
		});
	}

	/**
	 * Check/Modify config values in bot-config node.
	 * Reset config values with exportVar = `false`, otherwise keep the value as is.
	 */
	const flowsWithModules = getMayaJsonResponse?.data?.results.map((node) => {
		let newNode = node;
		if (newNode.type === 'bot-config') {
			const botConfigNode = newNode;
			if (Array.isArray(botConfigNode.rules)) {
				botConfigNode.rules = botConfigNode.rules.map((rule) => {
					if (!rule.exportVar) return { ...rule, to: '' }; // reset if not true
					console.log(rule);
					return rule; // allow export
				});
			}
			newNode = botConfigNode;
		}
		return newNode;
	});
	getMayaJsonResponse['data']['results'] = flowsWithModules;

	return getMayaJsonResponse;
};

export default getMayaJson;
