import React from 'react';
import {
	Box,
	CircularProgress,
	Flex,
	IconProps,
	ButtonProps,
	FlexProps,
} from '@chakra-ui/react';
import PatternIcon from './PatternIcon';

export type FilledButtonProps = {
	// not a traditional button, so no ButtonProps Click : ButtonProps['onClick']
	onClick?: () => void;
	rightIcon?: IconProps;
	leftIcon?: IconProps;
	colorScheme: 'purple' | 'gray';
	size: 'sm' | 'md';
	isLoading?: boolean;
	isDisabled?: boolean;
	showDots?: boolean;
	patternProps?: IconProps;
	buttonProps?: FlexProps;
	text?: string;
	showDotPattern?: boolean;
};

/**
 * Dark Filled Button
 */
export const MayaFilledButton = (props: FilledButtonProps) => {
	const {
		text,
		onClick = () => {},
		rightIcon,
		leftIcon,
		colorScheme,
		size,
		isLoading,
		isDisabled,
		buttonProps,
		showDotPattern = true,
		patternProps,
		showDots = true,
	} = props;

	const sizes = {
		sm: {
			textStyle: 'sans.xs',
			height: '40px',
		},
		md: {
			textStyle: 'sans.md',
			height: '60px',
		},
	};

	const colorSchemes = {
		purple: 'light.theme.purple.300',
		gray: 'light.theme.gray.500',
	};

	const colorSchemesOnHover = {
		purple: 'light.theme.purple.500',
		gray: 'light.theme.gray.600',
	};

	return (
		<Flex
			color="white"
			bg={
				colorSchemes[colorScheme]
					? colorSchemes[colorScheme]
					: 'light.theme.purple.300'
			}
			borderRadius="2px"
			textStyle={sizes[size] ? sizes[size].textStyle : 'sans.xs'}
			rightIcon={rightIcon}
			leftIcon={leftIcon}
			transition="all 0.02s cubic-bezier(.08,.52,.52,1)"
			_hover={{
				bg: isDisabled
					? colorSchemes[colorScheme]
						? colorSchemes[colorScheme]
						: colorSchemes['purple']
					: colorSchemesOnHover[colorScheme]
					? colorSchemesOnHover[colorScheme]
					: 'light.theme.purple.500',
			}}
			onClick={() => {
				if (isDisabled) {
				} else {
					onClick();
				}
			}}
			position="relative"
			display="flex"
			opacity={isDisabled ? '0.8' : '1'}
			flexDirection="row"
			justifyContent="center"
			// justifyContent="space-between"
			{...buttonProps}
			cursor="pointer"
			height={sizes[size] ? sizes[size].height : '40px'}
			pl="24px"
			pr={isLoading || rightIcon ? '12px' : '24px'}
			// pr="12px"
			boxSizing="border-box"
			float="left"
			// bg='yellow'
		>
			{showDots ? <PatternIcon patternProps={patternProps} /> : null}
			{leftIcon ? (
				<Box
					height="100%"
					display="flex"
					flexDirection="row"
					justifyContent="center"
					alignItems="center"
					// paddingX="10px"
					ml="10px"
					// mr='10px'
				>
					{leftIcon}
				</Box>
			) : null}

			<Box // Button label
				height="100%"
				display="flex"
				flexDirection="row"
				justifyContent="center"
				alignItems="center"
				ml="10px"
				mr="10px"
			>
				{text}
			</Box>

			{isLoading ? ( // Button loading
				<Box
					height="100%"
					display="flex"
					flexDirection="row"
					justifyContent="center"
					alignItems="center"
					paddingLeft="5px"
					paddingRight="15px"
				>
					<CircularProgress
						isIndeterminate
						color="white"
						size="15px"
						trackColor="none"
						capIsRound
					/>
				</Box>
			) : rightIcon ? ( // Right icon
				<Box
					height="100%"
					display="flex"
					flexDirection="row"
					justifyContent="center"
					alignItems="center"
					paddingLeft="5px"
					// ml='10px'
					// paddingRight="15px"
				>
					{rightIcon}
				</Box>
			) : // <Box width="2px" />
			null}
		</Flex>
	);
};
