import isElectron from 'is-electron';

import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

import getMachineDetails from '../devices/getMachineDetails';
// config
import { config } from '../../config';
import { store } from '../../redux/store';

/**
 * Function to get all configuration profiles of a certain module for user
 */
const saveConfigProfile = async ({
	configurationType,
	formData,
	slug = null,
	moduleId,
	modulePackageName,
	mode,
	referenceId,
	device
}) => {
	let data = {
		profileName: formData.name,
		config: {
			resource: configurationType.resource,
			type: configurationType.type,
			id: configurationType._id,
			values: {},
		},
		moduleName: modulePackageName,
		mode,
		referenceId,
		device
	};
	for (let key in formData) {
		if (key !== 'name') {
			data['config']['values'][key] = formData[key];
		}
	}
	if (window.navigator.onLine) {
		// There's no need to send native browser automation creds to cloud,
		// since its so device-specific. However, for simplicity, we should.
		// Let it be a TODO.
		let saveConfigProfileAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH:
				APIRoutes.apiService.saveConfigProfileTokens.PATH_SEARCH,
			PATH_METHOD:
				APIRoutes.apiService.saveConfigProfileTokens.PATH_METHOD,
		});
		const saveConfigProfileResponse = await saveConfigProfileAPIFn({
			...data,
			moduleId,
		});

		return saveConfigProfileResponse
	} else {
		return {
			error: true,
			message: 'You need to be online to verify and save config profile',
		};
	}
};

export default saveConfigProfile;
