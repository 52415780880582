/**
 * Logout Module
 *
 * Contains functions related to logging out of the application.
 */

// Dependencies
import axios from 'axios';

// Config
import { config } from '../../config';
import { googleLogout } from '../AuthProviders/Google';

// Logout API endpoints
import LogoutAPI from './LogoutAPI';

// Declare the Logout Module.
const LogoutController = {};

/**
 * Logout the current user
 *
 * Performs a logout by requesting the API service to remove the access token
 * and refresh token cookies that were set to keep the user logged in.
 *
 * @param {String} locale The current locale of the application.
 * @param {Object} languageData Current language information for the app. Language data object.
 */
LogoutController.logout = async () => {
	try {
		// logout via google (if google sign in was used, does nothing otherwise)
		// await googleLogout();
		// Make the request to the API service.
		// @ts-ignore
		await axios({
			baseURL: config.apiServer.BASEURL,
			url: LogoutAPI.apiService.getLogout.PATH_SEARCH,
			method: LogoutAPI.apiService.getLogout.PATH_METHOD,
			withCredentials: true,
			headers: {
				locale: 'en',
			},
		});
	} catch (error) {
		console.error(error);
		throw new Error('There was an issue logging you out.'); // throw custom error
	}
};

// Export the Logout Module.
export default LogoutController;
