import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import {
	Flex,
	Box,
	InputGroup,
	Input,
	InputRightElement,
	useToast,
} from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/react';
import MayaFilledButton from '../../../../../../library/buttons/FilledButton';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import validator from 'validator';
import PermSelect, { permLevels } from './PermSelect';
import TeamMember, { TeamMemberSkeleton } from './TeamMember';
import { useSelector } from 'react-redux';
import getTeamMemberProfiles from '../../../../../../functions/profile/getTeamMemberProfiles';
import changeTeamMemberPermission from '../../../../../../functions/profile/changeTeamMemberPermission';
import removeUserFromTeam from '../../../../../../functions/profile/removeUserFromTeam';
import inviteUserToTeam from '../../../../../../functions/profile/inviteUserToTeam';
import useChecklistItems from '../../../../../Checklist/hooks/useChecklistItems';
import allChecklistItems from '../../../../../Checklist/registry';
import getAllTeamInvites from '../../../../../../functions/profile/getAllTeamInvites';
import TeamInvite from './TeamInvite';
import theme from '../../../../../../library/theme';

const EditTeam = () => {
	const [invitePerm, setInvitePerm] = useState(permLevels.admin);
	const [emailValue, setEmailValue] = useState('');
	const [teamMemberProfiles, setTeamMemberProfiles] = useState({});
	const [teamProfilesLoading, setTeamProfilesLoading] = useState(false);
	const [invites, setInvites] = useState({});
	const [inviteLoading, setInviteLoading] = useState(false);

	const { checklistItems, updateChecklistItem } = useChecklistItems();

	const emailFieldIsInvalid =
		emailValue.length > 0 && !validator.isEmail(emailValue);

	const selectedProfile = useSelector(
		/**
		 *
		 * @param {import("../../../../../../redux/reducers/types/profileReducer").ProfilesState} state
		 * @returns
		 */
		(state) => state.profiles.profiles[state.profiles.selected]
	);

	useEffect(() => {
		setTeamProfilesLoading(true);
		Promise.all([
			getTeamMemberProfiles({ profileSlug: selectedProfile.profileSlug }),
			getAllTeamInvites(selectedProfile.profileSlug),
		]).then((res) => {
			const [teamMemberRes, inviteRes] = res;

			// Setting the team members to state
			const teamMemberProfilesObj = {};
			const teamHasMember = {};
			teamMemberRes.forEach((member) => {
				teamMemberProfilesObj[member.profile.profileSlug] = member;
				teamHasMember[member.profile.email] = true;
			});
			setTeamMemberProfiles(teamMemberProfilesObj);

			// Setting the pending invites to state
			const invitesObj = {};
			console.log('invs', inviteRes);
			inviteRes.forEach((invite) => {
				if (!teamHasMember[invite.email]) {
					invitesObj[invite._id] = invite;
				}
			});
			setInvites(invitesObj);

			setTeamProfilesLoading(false);
		});
	}, [selectedProfile.profileSlug]);

	async function handleInviteUserToTeam() {
		setInviteLoading(true);
		inviteUserToTeam({
			email: emailValue,
			teamProfileSlug: selectedProfile.profileSlug,
			permission: invitePerm,
		})
			.then((res) => {
				const invite = res.invite;

				if (!checklistItems[allChecklistItems.addTeamMembersToTeam].done) {
					updateChecklistItem(
						allChecklistItems.addTeamMembersToTeam,
						true
					);
				}

				// Add new invite to component invite state
				const newInvites = { ...invites };
				newInvites[invite._id] = invite;
				setInvites(newInvites);
				setInviteLoading(false);
			})
			.catch((e) => {
				console.error('invite error', e);
				setInviteLoading(false);
			});
	}

	async function handlePermChange(profileSlug, newPerm) {
		const oldPerm = teamMemberProfiles[profileSlug]?.permissions;

		// Reflect perm change in UI before it succeeds at the backend.
		// Makes it feel snappy
		setTeamMemberProfiles(
			update(teamMemberProfiles, {
				[profileSlug]: {
					permissions: { $set: newPerm },
				},
			})
		);
		changeTeamMemberPermission({
			teamProfileSlug: selectedProfile.profileSlug,
			userProfileSlug: profileSlug,
			newPermission: newPerm,
		})
			.then((res) => {
				console.log('inv perm change', res);
				if (res.status !== 200) {
					// Revert to old permission and show an error message if request
					// fails
					setTeamMemberProfiles(
						update(teamMemberProfiles, {
							[profileSlug]: {
								permissions: { $set: oldPerm },
							},
						})
					);
					// TODO: Get this toast working
					// toast({
					//     title: 'Error changing permission',
					//     // description: res.message,
					//     description: 'heyo',
					//     status: 'error',
					//     duration: 60000,
					//     isClosable: true,
					// })
				}
			})
			.catch((e) => {
				setTeamMemberProfiles(
					update(teamMemberProfiles, {
						[profileSlug]: {
							permissions: { $set: oldPerm },
						},
					})
				);
			});
	}

	async function handleUserRemove(userProfileSlug) {
		const userToRemove = teamMemberProfiles[userProfileSlug];

		const newTeamMemberProfiles = { ...teamMemberProfiles };
		delete newTeamMemberProfiles[userProfileSlug];
		setTeamMemberProfiles(newTeamMemberProfiles);

		removeUserFromTeam({
			teamProfileSlug: selectedProfile.profileSlug,
			userProfileSlug,
		})
			.then((res) => {
				if (res.status !== 200) {
					// Revert to old list of users if deletion request failed
					setTeamMemberProfiles(
						update(teamMemberProfiles, {
							[userProfileSlug]: { $set: userToRemove },
						})
					);

					// Put a toast notification informing the user about failure, here
				}
			})
			.catch((e) => {
				setTeamMemberProfiles(
					update(teamMemberProfiles, {
						[userProfileSlug]: { $set: userToRemove },
					})
				);
			});
	}

	const teamMemberSkeletons = [...Array(5).keys()].map((i) => (
		<Box mb="8px">
			<TeamMemberSkeleton key={i} />
		</Box>
	));

	// Some px heights and margins stuff going on here because the input's light
	// border makes it hard to visually align the button with the input field. Only
	// change if you know exactly what you're doing.
	return (
		<Box
			width="70%"
			minWidth="400px"
			height="100%"
			display="flex"
			flexDirection="column"
		>
			<Flex
				alignItems="center"
				// direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }} // TODO: Fix breakpoints?
			>
				<InputGroup
					height="40px"
					overflow="visible"
					padding="1px" // Otherwise the top focus border of the input component is hidden. Chakra bug.
					boxSizing="border-box"
				>
					<Input
						bg="#fff"
						borderRadius="2px"
						focusBorderColor={emailFieldIsInvalid ? 'red.200' : '#ddd'}
						errorBorderColor="red.100"
						value={emailValue}
						onChange={(e) => setEmailValue(e.target.value)}
						isInvalid={emailFieldIsInvalid}
						autoFocus
						pl="16px"
						textStyle="sans.xs"
						color="light.font.gray.300"
					/>
					<InputRightElement
						width="115px"
						mt="1px" // It'll look misaligned otherwise. Idk why.
						padding="4px 8px"
					>
						<PermSelect
							selectedPerm={invitePerm}
							onPermChange={(perm) => setInvitePerm(perm)}
						/>
					</InputRightElement>
				</InputGroup>
				<Box height="38px" boxSizing="border-box">
					<MayaFilledButton
						text="Invite"
						showDotPattern={true}
						rightIcon={<ArrowForwardIcon />}
						colorScheme="gray"
						buttonProps={{
							ml: '16px',
							height: '38px',
							mt: '1px',
						}}
						isDisabled={emailFieldIsInvalid || emailValue.length === 0}
						isLoading={inviteLoading}
						onClick={handleInviteUserToTeam}
					/>
				</Box>
			</Flex>
			<Box
				position="relative"
				maxWidth="1000px"
				width="100%"
				mt="20px"
				overflow="auto"
				display="flex"
				flex="1 1 auto"
			>
				<Box
					display="flex"
					flexDirection="column"
					position="absolute"
					width="100%"
					height="100%"
					alignItems="center"
				>
					<Box width="100%" flex="1 1 auto">
						{!teamProfilesLoading &&
							Object.values(invites).map((invite, idx) => (
								<Box mb="8px" key={idx}>
									<TeamInvite email={invite.email} />
								</Box>
							))}
						{!teamProfilesLoading
							? Object.values(teamMemberProfiles).map((member, idx) => (
									<Box mb="8px" key={idx}>
										<TeamMember
											teamSlug={selectedProfile.profileSlug}
											key={member.profile.profileSlug}
											member={member}
											onPermChange={(profileSlug, newPerm) =>
												handlePermChange(profileSlug, newPerm)
											}
											onMemberDelete={handleUserRemove}
										/>
									</Box>
							  ))
							: null}
						{teamProfilesLoading ? teamMemberSkeletons : null}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default EditTeam;
