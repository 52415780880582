import React, { useEffect, useState, useMemo } from 'react';
import isElectron from 'is-electron';
import { CopyIcon, CheckIcon } from '@chakra-ui/icons';
// Drawer Accordion Items
import Tasks from './DrawerItems/Tasks';
import Triggers from './DrawerItems/Triggers';
import Modules from './DrawerItems/Modules';
import DashboardLink from './DrawerItems/DashboardLink';

import {
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerContent,
	Box,
	Flex,
	Accordion,
	AccordionItem,
	AccordionPanel,
	DrawerOverlay,
	Icon,
	useClipboard,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import AccordionButton from '../../../../../../../library/Accordion';

import getMayaJson from '../../../../../../../functions/skillPack/getMayaJson';
import useProfileSlug from '../../../../../../../hooks/useProfileSlug';
import getBrainFlows from '../../../../../../../functions/brain/getBrainFlows';
import _ from 'lodash';
import getWorkspaceConfigMap from '../../../../../../../functions/brain/getWorkspaceConfigMap';

// utility functions
const containsDashboardNodes = (module) => {
	return (
		module === 'node-red-dashboard' ||
		module === 'maya-red-dashboard' ||
		module === '@mayahq/maya-red-dashboard'
	);
};
const isTriggerNode = (type) => {
	return type === 'http in';
};

/**
 *
 * @param {{
 * 	isOpen: boolean,
 * 	onClose: () => void,
 * 	workspace: any,
 *    runtime:any,
 * 	drawerContentProps?: import('@chakra-ui/react').DrawerContentProps,
 * }} param0
 * @returns
 */
const WorkspaceDetailsDrawer = ({
	isOpen,
	onClose,
	workspace,
	runtime,
	drawerContentProps,
}) => {
	// console.log('WORKSAPCE', workspace);

	const slug = useProfileSlug();

	const [hasDashboard, setHasDashboard] = useState(null);
	const [triggerNodes, setTriggerNodes] = useState([]);
	const [configMap, setConfigMap] = useState({});

	useEffect(() => {
		if (isOpen) {
			getWorkspaceConfigMap({ workspaceId: workspace._id })
				.then((map) => {
					setConfigMap(map);
				})
				.catch((e) => {
					console.error('error getting configmap', e);
				});

			if (workspace.status === 'STARTED') {
				getBrainFlows({
					brainId: workspace._id,
					device: workspace.device,
					slug,
				})
					.then((flowJSON) => {
						// console.log('flowJSON', flowJSON);
						return getMayaJson(slug, flowJSON, workspace._id);
					})
					.then((res) => {
						const mayaJSON = res?.data?.results;
						// console.log('mayaJSON ', mayaJSON);

						let hasDashboardFlag = false;
						const foundTriggerNodes = [];
						for (let node of mayaJSON) {
							// console.log('node - ', node);

							// checking for dashboard nodes
							if (!hasDashboardFlag) {
								hasDashboardFlag = containsDashboardNodes(node.module);
							}

							// console.log('hasDashboardFlag - ', hasDashboardFlag);

							// checking for trigger nodes
							if (isTriggerNode(node?.type)) {
								foundTriggerNodes.push({
									url: node?.url,
									method: node?.method,
								});
							}
						}

						setHasDashboard(hasDashboardFlag);
						setTriggerNodes(foundTriggerNodes);
					});
			}
		}
	}, [isOpen, slug, workspace._id, workspace.device, workspace.status]);

	const offset = isElectron() ? '115px' : '70px';

	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const { hasCopied, onCopy } = useClipboard(workspace.url);

	const modulesWithReferenceId = workspace.modules.map((module) => {
		return { ...module, referenceId: configMap[module.packageName] };
	});

	console.log('pass', modulesWithReferenceId);

	return (
		<Drawer
			isOpen={isOpen}
			// isOpen={true}
			placement="right"
			onClose={onClose}
			size="md"
			closeOnOverlayClick={true}
			closeOnEsc={true}
			// trapFocus={false}
		>
			{
				isOpen ? (
					<DrawerOverlay bg="transparent" width="0px" display="none" />
				) : null /* For smoother drawer opening? */
			}
			<DrawerContent
				marginTop={offset}
				height={`calc(100vh - ${offset})`}
				paddingX="20px"
				position="relative"
				width="900px"
				bg="light.theme.yellow.200"
				boxShadow="none"
				borderLeft="1px solid"
				borderColor="light.border.gray.200"
				color="black"
				display="flex"
				flexDirection="column"
				{...drawerContentProps}
			>
				<DrawerHeader
					paddingX="0px"
					display="flex"
					justifyContent="space-between"
					color="#444"
				>
					<Flex
						textStyle="sans.xs"
						alignItems="center"
						cursor="pointer"
						onClick={() => {
							onClose();
						}}
					>
						<CloseIcon fontSize="10px" mr="6px" mb="2px" />
						<Box>CLOSE</Box>
					</Flex>
					<Flex textStyle="sans.sm" mb="4px">
						{workspace.name}
					</Flex>
				</DrawerHeader>

				<DrawerBody
					paddingX="0px"
					// mt='32px'
					// mt='24px'
				>
					<Accordion allowToggle textStyle="sans.sm">
						<AccordionItem mb="10px" border="none">
							<AccordionButton>Details</AccordionButton>
							<AccordionPanel paddingX="8px">
								<Box display={'flex'}>
									<Box width={'90px'}>{'Name: '}</Box>{' '}
									<Box color="light.theme.gray.500">
										{_.truncate(workspace.name, {
											length: 25,
											separator: ' ',
										})}
									</Box>
								</Box>
								<Box display={'flex'}>
									<Box width={'90px'}>{'Endpoint: '}</Box>{' '}
									<Box color="light.theme.gray.500">
										{_.truncate(workspace.url, {
											length: 25,
											separator: ' ',
										})}
									</Box>
									{hasCopied ? (
										<CheckIcon marginLeft={'5px'} cursor="pointer" />
									) : (
										<CopyIcon
											marginLeft={'5px'}
											cursor="pointer"
											onClick={onCopy}
										/>
									)}
								</Box>
								<Box display={'flex'}>
									<Box width={'90px'}>{'Device: '}</Box>{' '}
									<Box color="light.theme.gray.500">
										{_.truncate(workspace?.device?.name, {
											length: 25,
											separator: ' ',
										})}
									</Box>
								</Box>
								<Box display={'flex'}>
									<Box width={'90px'}>{'Platform: '}</Box>{' '}
									<Box color="light.theme.gray.500">
										{_.truncate(
											capitalizeFirstLetter(
												workspace?.device?.platform
											),
											{
												length: 25,
												separator: ' ',
											}
										)}
									</Box>
								</Box>
							</AccordionPanel>
						</AccordionItem>
						<AccordionItem mb="10px" border="none">
							<AccordionButton>Tasks</AccordionButton>
							<AccordionPanel paddingX="8px">
								<Tasks
									workspaceStatus={workspace.status}
									workspaceId={workspace._id}
								/>
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem mb="10px" border="none">
							<AccordionButton>Triggers</AccordionButton>
							<AccordionPanel paddingX="0px">
								<Triggers
									workspaceUrl={workspace.url}
									workspaceIntents={workspace.intents}
									workspaceTriggersNodes={triggerNodes}
								/>
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem mb="10px" border="none">
							<AccordionButton>Modules</AccordionButton>
							<AccordionPanel paddingX="8px">
								<Accordion></Accordion>

								<Modules
									workspaceModules={modulesWithReferenceId}
									workspaceId={workspace._id}
								/>
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem mb="10px" border="none">
							<AccordionButton>Dashboard</AccordionButton>
							<AccordionPanel paddingX="8px">
								<DashboardLink
									workspaceId={workspace._id}
									hasDashboard={hasDashboard}
								/>
							</AccordionPanel>
						</AccordionItem>
					</Accordion>
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

// const WorkspaceDetailsDrawer2 = ({ isOpen, onClose, workspace }) => {
// 	return (
// 		<Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
// 			{/* <DrawerOverlay bg='transparent' /> */}
// 			<DrawerContent
// 				// marginTop='115px'
// 				// height='calc(100vh - 115px)'
// 				position="relative"
// 				width="900px"
// 				bg="light.theme.yellow.200"
// 				// bg='tomato'
// 				boxShadow="none"
// 				borderLeft="1px solid"
// 				borderColor="light.border.gray.200"
// 				color="black"
// 			>
// 				{/* <DrawerCloseButton onClick={onClose}/> */}
// 				Runtime Drawer
// 				{workspace?.name}
// 			</DrawerContent>
// 		</Drawer>
// 	);
// };

export default WorkspaceDetailsDrawer;
