import React, { useEffect, useMemo, useState } from 'react';
import { Box, Input, Image, Text, Divider } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useStore } from '../zustand';
import fuzzysort from 'fuzzysort';
import { truncate } from 'lodash';
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Tooltip,
} from '@chakra-ui/react';
import { getNodeDescription } from './functions/Misc';
import {
	offMayaToggleNodePalette,
	onMayaToggleNodePalette,
} from './functions/redEventListeners';

export const DndNode = ({ nodeType, nodeStyle }) => {
	const nodeDetails = useStore((state) => state.nodeConfigs.byType[nodeType]);
	const runtime = useStore((state) => state.runtime);
	const onDragStart = (event) => {
		event.dataTransfer.setData('application/reactflow', nodeStyle);
		event.dataTransfer.setData('application/type', nodeType);
		event.dataTransfer.effectAllowed = 'move';
	};

	return (
		<Box
			bg={nodeDetails.color}
			borderRadius="4px"
			boxShadow="none !important"
			// outline={`solid 1px ${nodeDetails.color}`}
			onDragStart={(event) => onDragStart(event)}
			draggable
			marginX="10px"
			marginY="7px"
			cursor="pointer"
			width="150px"
			border="solid 1px"
			borderColor="#727272"
			minH="36px"
		>
			<Box
				fontWeight="500"
				color="#323232"
				display="flex"
				justifyContent="flex-start"
				alignItems="center"
				height="100%"
			>
				<Box
					bg={nodeDetails.darkColor}
					paddingX="9px"
					paddingY="7px"
					height="100%"
					borderLeftRadius="3px"
				>
					<Image
						src={`${runtime}/icons/${
							nodeDetails.set.module || 'node-red'
						}/${nodeDetails.icon}`}
						boxSize="15px"
					/>
				</Box>
				<Box
					paddingLeft="20px"
					fontWeight="500"
					color="white"
					height="100%"
				>
					{typeof nodeDetails.paletteLabel === 'function'
						? nodeDetails.paletteLabel() || nodeDetails.type
						: nodeDetails.paletteLabel || nodeDetails.type}
				</Box>
			</Box>
		</Box>
	);
};

const NodeSearch = ({ value, handleChange }) => {
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			borderRadius="2px"
			p="2"
			px="4"
		>
			<SearchIcon mr="1" color="light.font.gray.200" />
			<Input
				value={value}
				onChange={handleChange}
				placeholder="type to search..."
				_placeholder={{
					color: 'gray !important',
				}}
				_focus={{
					border: 'none',
				}}
				bg="none"
				border="none"
				outline="none"
				px="2"
				h="6"
				textStyle="sans.xs"
			/>
		</Box>
	);
};

/** Filtering the nodes according to the search query and storing them in an array */
export const getFilteredNodeTypes = (allTypes, searchValue) => {
	let filteredTypes = [...allTypes];
	if (searchValue !== '') {
		filteredTypes = fuzzysort.go(searchValue, allTypes).map((i) => i.target);
	}
	return filteredTypes;
};

/** Dividing the nodes according to their categories. */
const getCategorizedNodeTypes = (categories, byType, nodeTypes) => {
	const categorizedNodeTypes = {};

	// initializing the category arrays
	for (let categoryName of categories) {
		categorizedNodeTypes[categoryName] = [];
	}

	// adding the node types to their category array
	for (let nodeType of nodeTypes) {
		const currNodeCategory = byType[nodeType].category;
		categorizedNodeTypes[currNodeCategory]?.push(nodeType);
	}

	return categorizedNodeTypes;
};

/**Tooltip to display the node's details */
const CustomTooltip = ({ nodeType, nodeStyle }) => {
	const nodeDetails = useStore((state) => state.nodeConfigs.byType[nodeType]);
	const runtime = useStore((state) => state.runtime);
	const onDragStart = (event) => {
		event.dataTransfer.setData('application/reactflow', nodeStyle);
		event.dataTransfer.setData('application/type', nodeType);
		event.dataTransfer.effectAllowed = 'move';
	};

	return nodeDetails?.category ? (
		<Tooltip
			label={truncate(getNodeDescription(nodeDetails.category, nodeType), {
				length: 200,
			})}
			openDelay={600}
			placement="right"
			closeOnMouseDown={true}
			hasArrow
		>
			<SidebarNode
				nodeDetails={nodeDetails}
				runtime={runtime}
				onDragStart={onDragStart}
			/>
		</Tooltip>
	) : null;
};

const SidebarNode = React.forwardRef(
	({ children, nodeDetails, onDragStart, runtime, ...rest }, ref) => {
		const nodeName = useMemo(() => {
			const name =
				typeof nodeDetails?.paletteLabel === 'function'
					? nodeDetails?.paletteLabel() || nodeDetails?.type
					: nodeDetails?.paletteLabel || nodeDetails?.type;
			const truncatedName = truncate(name, { length: 18 });
			return truncatedName;
		}, [nodeDetails]);

		return (
			<Box
				ref={ref}
				{...rest}
				bg={nodeDetails.color}
				borderRadius="4px"
				boxShadow="none !important"
				// outline={`solid 1px ${nodeDetails.color}`}
				onDragStart={(event) => onDragStart(event)}
				draggable
				marginX="10px"
				marginY="7px"
				cursor="pointer"
				width="150px"
				border="solid 1px"
				borderColor="#727272"
				minH="36px"
			>
				<Box
					fontWeight="500"
					color="#323232"
					display="flex"
					justifyContent="flex-start"
					alignItems="center"
					height="100%"
				>
					<Box
						bg={nodeDetails.darkColor}
						paddingX="9px"
						paddingY="7px"
						height="100%"
						borderLeftRadius="3px"
					>
						<Image
							src={`${runtime}/icons/${
								nodeDetails.set.module || 'node-red'
							}/${nodeDetails.icon}`}
							boxSize="15px"
						/>
					</Box>
					<Box
						paddingLeft="20px"
						fontWeight="500"
						color="white"
						height="100%"
					>
						{nodeName}
					</Box>
				</Box>
			</Box>
		);
	}
);

// red-ui-palette-closed

const SideBar = () => {
	const [searchValue, setSearchValue] = React.useState('');
	const handleChange = (event) => setSearchValue(event.target.value);
	const [sideBarIsVisible, setSideBarIsVisible] = useState(true);

	const allTypes = useStore((state) => state.nodeConfigs.allTypes);
	const byType = useStore((state) => state.nodeConfigs.byType);
	const categories = useStore((state) => state.palette.categories);

	// filtering the nodes according to the search query and storing them in an array
	const filteredNodeTypes = getFilteredNodeTypes(allTypes, searchValue);

	// dividing the nodes according to their categories
	const categorizedNodeTypes = getCategorizedNodeTypes(
		categories,
		byType,
		filteredNodeTypes
	);

	useEffect(() => {
		const handleTogglePalette = (state) => setSideBarIsVisible(state);
		onMayaToggleNodePalette(handleTogglePalette);
		return () => offMayaToggleNodePalette(handleTogglePalette);
	}, []);

	return (
		<Box
			height="100%"
			bg="light.theme.yellow.200"
			color="light.font.gray.400"
			overflow="auto"
			sx={{
				'&::-webkit-scrollbar': {
					w: '0.1rem',
				},
				'&::-webkit-scrollbar-track': {
					w: '0.1rem',
				},
				'&::-webkit-scrollbar-thumb': {
					bg: 'light.borders.gray.300',
					borderRadius: '5px',
				},
			}}
			display={sideBarIsVisible ? undefined : 'none'}
		>
			<NodeSearch value={searchValue} handleChange={handleChange} />
			<Accordion defaultIndex={[0, 1, 2, 3, 4, 5, 6, 7]} allowMultiple>
				{categories
					.filter((c) => c !== 'config')
					.map((categoryName, idx) => (
						<AccordionItem
							key={categoryName}
							borderTopColor="light.border.gray.200"
							borderTopWidth="thin"
							borderTopStyle="solid"
							_first={{
								borderTopWidth: '2px',
							}}
						>
							<h2>
								<AccordionButton
									display="flex"
									alignItems="center"
									borderBottomColor="light.border.gray.200"
									borderBottomWidth={
										idx === categories.length - 1 ? '2px' : 'thin'
									}
									borderBottomStyle="solid"
									bg="white"
								>
									<AccordionIcon mr="2" />
									<Box flex="1" textAlign="left" textStyle="sans.xs">
										{categoryName}
									</Box>
								</AccordionButton>
							</h2>
							<AccordionPanel padding="0">
								{categorizedNodeTypes[categoryName].map(
									(nodeType, idx) => {
										if (nodeType !== 'unknown')
											return (
												<CustomTooltip
													nodeType={nodeType}
													nodeStyle={'special'}
													key={nodeType}
												/>
											);
										return null;
									}
								)}
							</AccordionPanel>
						</AccordionItem>
					))}
			</Accordion>
		</Box>
	);
};

export default SideBar;
