import React from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { Box } from '@chakra-ui/react';

const SubflowIONodeComponent = ({ id, data, selected }) => {
	return (
		<Box
			bg={"gray"}
			borderRadius="2px"
			boxShadow="rgba(0, 0, 0, 0.1) 0px -2px 4px"
			outline={`${selected ? `solid 2px #727272` : 'solid 1px #727272'}`}
			transition="all 0.1s cubic-bezier(.08,.52,.52,1)"
			cursor="pointer"
			height="35px"
		>
			{data?.io === "out" && <Box
				width="9px"
				display="flex"
				left="-2px"
				position="absolute"
				height="100%"
			>
				<Handle
					type="target"
					position={Position.Left}
					style={{
						background: 'white',
						borderRadius: '2.5px',
						width: '100%',
						height: '9.5px',
						border: 'none',
						outline: selected
							? `solid 2px #727272`
							: 'solid 1.6px #727272',
					}}
				/>
			</Box>}

			<Box
				paddingX="23px"
				color="white"
				fontWeight="500 !important"
				fontFamily="Ubuntu"
				fontSize="14px"
				height="100%"
				display="flex"
				justifyContent="center"
				alignItems="center"
			>
				{data?.name || "0"}
			</Box>

			{data?.io === "in" && <Box
				width="9px"
				display="flex"
				right="-1px"
				position="absolute"
				top="0"
				height="100%"
			>
				<Handle
					type="source"
					position={Position.Right}
					style={{
						background: 'white',
						borderRadius: '2.5px',
						width: '100%',
						height: '9.5px',
						outline: selected
							? `solid 2px #727272`
							: 'solid 1.6px #727272',
					}}
				/>
			</Box>}

		</Box>
	);
};
export default SubflowIONodeComponent;
