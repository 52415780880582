import React from 'react';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';

/**
 * Standard input element in Maya styles.
 *
 * @param {*} icon
 * @param {*} name
 * @param {*} type
 * @param {*} placeholder
 * @param {*} onChange
 * @param {*} onIconClick
 * @param {*} iconPointerEvents whether to allow pointer events or not
 * @param {*} onKeyDown
 * @param {*} onKeyUp
 * @param {*} isInvalid
 * @param {*} isDisabled
 * @param {*} inputProps
 * @returns
 */
const MayaInput = ({
	name,
	icon,
	type,
	placeholder,
	onChange,
	onIconClick,
	iconPointerEvents,
	onKeyDown,
	onKeyUp,
	isInvalid,
	isDisabled,
	inputProps,
	value
}) => {
	return (
		<InputGroup>
			{icon && (
				<InputLeftElement
					children={icon}
					onClick={onIconClick}
					pointerEvents={iconPointerEvents || 'none'}
				/>
			)}
			<Input
				type={type}
				name={name}
				value={value}
				placeholder={placeholder}
				bg="white"
				width="100%"
				borderRadius="2px"
				border="solid 1px"
				borderColor={isInvalid ? 'red.500' : 'light.border.gray.200'}
				_focus={{
					border: 'solid 1px',
					borderColor: 'light.border.gray.300',
				}}
				_hover={{
					border: 'solid 1px',
					borderColor: isInvalid ? 'red.500' : 'light.border.gray.300',
				}}
				textStyle="sans.xs"
				color="light.font.gray.400"
				_placeholder={{
					color: 'light.font.gray.200',
				}}
				onChange={onChange}
				onKeyDown={onKeyDown}
				onKeyUp={onKeyUp}
				isDisabled={isDisabled}
				{...inputProps}
			/>
		</InputGroup>
	);
};

export default MayaInput;
