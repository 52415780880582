import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/react';
import SectionHeader from '../../../../../library/text/SectionHeader';
import MayaFilledButton from '../../../../../library/buttons/FilledButton';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import OptionBox from '../../../../../library/select/OptionBox/Box';
import FlowVisualizer from '../../../../../library/FlowVisualizer';
import { useStore } from '../../../../Editor/zustand';
import { saveAs } from 'file-saver';
import FlowJsonView from './FlowJsonView';
import { parseReactFlowtoMayaLang } from '../../../../../util/FlowMethods';
import getMayaJson from '../../../../../functions/skillPack/getMayaJson';
import useStepperStore from '../../../StepperStore';
import useStepperAnalytics from '../../../hooks/useStepperAnalytics';
import useProfileSlug from '../../../../../hooks/useProfileSlug';
import theme from '../../../../../library/theme';

const toast = createStandaloneToast({ theme: theme });

const exportOptions = [
	{
		label: 'Selected Nodes',
		id: 'selected',
	},
	{
		label: 'Current Flow',
		id: 'current',
	},
	{
		label: 'All Flows',
		id: 'all',
	},
];

const ExportStepper = () => {
	useStepperAnalytics();
	const [selectedMode, setSelectedMode] = useState(exportOptions[0].id);
	/** @type {import('../../../../Editor/zustand/types').EditorStore['onFlowExport']} */
	const onFlowExport = useStore(
		useCallback((state) => state.onFlowExport, [])
	);
	const profileSlug = useProfileSlug();
	const { steps } = useStepperStore(
		useCallback((state) => {
			return {
				steps: state.steps,
			};
		}, [])
	);
	const [exportedFlow, setExportedFlow] = useState(null);

	const getWorkspaceId = useCallback(() => {
		const currentStep = steps.byId['editor.export'];
		if (!currentStep) return null;
		return currentStep?.state?.workspaceId;
	}, [steps.byId]);

	const handleDownload = async (event) => {
		try {
			let mayaJson = getMayaJson(
				profileSlug,
				exportedFlow,
				getWorkspaceId()
			);
			const jsonString = JSON.stringify(exportedFlow, null, 2);
			const blob = new Blob([jsonString], {
				type: 'application/json',
			}); // create blob
			saveAs(blob, 'maya-flow.json'); // download(save as)
		} catch (error) {
			console.error(error);
		}
	};

	const handleCopyToClipboard = async (event) => {
		try {
			await navigator.clipboard.writeText(
				JSON.stringify(exportedFlow, null, 2)
			);
			toast({
				status: 'success',
				title: 'Copied to clipboard!',
			});
		} catch (error) {
			console.error(error);
		}
	};

	const handleModeSwitch = useCallback(
		async (mode) => {
			try {
				const brainId = getWorkspaceId();
				const flow = await onFlowExport({
					mode,
					brainId,
					profileSlug,
				});
				setExportedFlow(flow);
			} catch (error) {
				console.error(error);
			}
		},
		[getWorkspaceId, onFlowExport, profileSlug]
	);

	useEffect(() => {
		handleModeSwitch(selectedMode);
	}, [selectedMode]);

	return (
		<Box
			display="flex"
			flexDir="column"
			justifyContent="center"
			alignItems="center"
		>
			<Box paddingTop="20px" paddingBottom="20px">
				<SectionHeader
					heading="Export the flow."
					subheading="Copy or download the exported flow to share with others."
				/>
			</Box>
			<Box
				display="grid"
				gridTemplateColumns="1fr 1fr"
				gridGap="1rem"
				height="40vh"
				width="55vw"
				margin="1rem"
			>
				<FlowVisualizer mayaFlowJson={exportedFlow || []} />
				<FlowJsonView
					value={JSON.stringify(exportedFlow || [], null, 2)}
					editable={false}
				/>
			</Box>
			<Box
				display="grid"
				gridTemplateColumns="1fr 1fr 1fr"
				gridGap="1rem"
				width="55vw"
				margin="1rem"
			>
				{exportOptions.map((item, key) => {
					return (
						<OptionBox
							selectMode="single"
							text={item.label}
							key={key}
							selected={item.id === selectedMode}
							onClickFn={() => {
								setSelectedMode(item.id);
								// @ts-ignore
								// handleModeSwitch(item.id);
							}}
						/>
					);
				})}
			</Box>
			<Box
				display="flex"
				flexDir="row"
				justifyContent="center"
				width="300px"
				marginTop="1rem"
			>
				<Box marginRight="1rem">
					<MayaFilledButton
						text={'Copy To Clipboard'}
						onClick={handleCopyToClipboard}
						buttonProps={{
							width: '240px',
							height: '3rem',
						}}
						rightIcon={<ArrowForwardIcon />}
						colorScheme="purple"
					/>
				</Box>
				<Box marginRight="1rem">
					<MayaFilledButton
						text={'Download File'}
						onClick={handleDownload}
						buttonProps={{
							width: '240px',
							height: '3rem',
						}}
						rightIcon={<ArrowForwardIcon />}
						colorScheme="purple"
					/>
				</Box>
			</Box>
		</Box>
	);
};

const sectionObject = {
	heading: 'export flow',
	component: <ExportStepper />,
};

export default sectionObject;
