import dotProp from 'dot-prop-immutable';
import { debounce, forEach } from 'lodash';
import semver from 'semver';
import getInstallerFlow from '../../../../../functions/store/getInstallerFlow';
import { getModuleList } from '../NodeRed';

/**
 * Add utility function
 *
 * Adds a normalized array to another normalized array.
 */
export const Add = (sourceArray, targetArray) => {
	forEach(sourceArray.allIds, (sourceId) => {
		if (!targetArray.byId[sourceId]) {
			targetArray = dotProp.set(
				targetArray,
				`byId.${sourceId}`,
				sourceArray.byId[sourceId]
			);
			targetArray = dotProp.set(targetArray, 'allIds', (list) => [
				...list,
				sourceId,
			]);
		} else {
			targetArray = dotProp.set(
				targetArray,
				`byId.${sourceId}`,
				sourceArray.byId[sourceId]
			);
		}
	});

	return targetArray;
};

export const dataURItoBlob = (dataURI) => {
	// convert base64/URLEncoded data component to raw binary data held in a string
	var byteString;
	if (dataURI.split(',')[0].indexOf('base64') >= 0)
		byteString = atob(dataURI.split(',')[1]);
	else byteString = unescape(dataURI.split(',')[1]);

	// separate out the mime component
	var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// write the bytes of the string to a typed array
	var ia = new Uint8Array(byteString.length);
	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new Blob([ia], { type: mimeString });
};

export const normalize = (array) => {
	const newArray = {
		byId: {},
		allIds: [],
	};
	array.forEach((item) => {
		newArray.byId[item._id ? item._id : item.id] = item;
		newArray.allIds.push(item._id ? item._id : item.id);
	});

	return newArray;
};

export const normalizeNew = (array) => {
	const newArray = {
		byId: {},
		allIds: [],
	};
	array.forEach((item) => {
		newArray.byId[item.id] = item;
		newArray.allIds.push(item.id);
	});

	return newArray;
};

export const isPublishedSkillPackInstalled = (brains, publishedSkillPacId) => {
	let count = 0;
	for (let id of brains.allIds) {
		for (let obj of brains.byId[id].publishedSkillPacks) {
			if (obj.publishedSkillPack === publishedSkillPacId) {
				count = count + 1;
			}
		}
	}
	return count > 0;
};

export const isSkillPackInstalled = (brains, skillPackId) => {
	let count = 0;
	for (let id of brains.allIds) {
		for (let obj of brains.byId[id].skillPacks) {
			if (obj.skillPack === skillPackId) {
				count = count + 1;
			}
		}
	}
	return count > 0;
};
export const isModuleInstalled = (brains, moduleId) => {
	let count = 0;
	for (let id of brains.allIds) {
		for (let obj of brains.byId[id].modules) {
			if (obj.module === moduleId) {
				count = count + 1;
			}
		}
	}
	return count > 0;
};

export const getFlowFromSkillPack = (skillPack, version) => {
	for (let sp of skillPack.versions) {
		if (sp.version === version) return sp.flow.flowJSON;
	}
};

export const getEditorMode = () => {
	if (!window.localStorage.getItem('editor')) {
		window.localStorage.setItem('editor', 'new');
	}

	return window.localStorage.getItem('editor');
};

export const getNodeDescription = (category, type) => {
	try {
		const data = window.RED.nodes.getNodeHelp(type);
		const parser = new DOMParser();
		const parsedData = parser.parseFromString(data, 'text/html');
		const helpDesc = parsedData.querySelector('p');
		return helpDesc?.textContent;
	} catch (e) {
		console.error(e);
	}
};

export const findDashboardNodes = (reactFlow) => {
	const dashboardNodes = [];
	for (let node of reactFlow.nodes) {
		if (
			node?.data?.node?.type?.substring(0, 3) === 'ui_' &&
			node?.data?.node?.valid
		) {
			dashboardNodes.push(node);
		}
	}
	return dashboardNodes;
};

export const splitIntoThree = (string) => {
	const splitString = [];
	const firstBreak = Math.round(string.length / 3);
	const secondBreak = firstBreak * 2;

	splitString.push(string.substring(0, firstBreak));
	splitString.push(string.substring(firstBreak, secondBreak));
	splitString.push(string.substring(secondBreak));

	return splitString;
};

export const debouncedGetMissingModules = debounce(
	async (flow) => {
		const details = await getInstallerFlow(flow);
		const internalModuleMap = {};

		const neededModules = flow
			.filter((node) => !!node.module)
			.map((node) => ({
				module: node.module,
				version: semver.clean(node.version, { loose: true }),
			}));

		details?.currentFlow?.modules
			.map((mod) => mod.module)
			.forEach((module) => {
				const version = semver.clean(module.currentVersion, {
					loose: true,
				});
				neededModules.push({
					module: module.packageName,
					version: semver.clean(module.currentVersion, {
						loose: true,
					}),
				});
				internalModuleMap[module.packageName] = module;
			});

		const availableModules = getModuleList();

		/**
		 * @type {import('../../../Workspace/InterstitialModuleInstallDrawer/types').IntModuleInstallProps}
		 */
		const modules = {};
		neededModules.forEach(({ module: modName, version }) => {
			if (!availableModules[modName]) {
				if (internalModuleMap[modName]) {
					const privateModule = internalModuleMap[modName];
					const requiresConfig =
						privateModule.userProfile &&
						!!privateModule.configurationType;
					modules[modName] = {
						moduleName: privateModule.name,
						version,
						status: 'QUEUED',
						configProfileReferenceId: '',
						isInternalModule: true,
						privateModule: privateModule,
						requiresConfig: requiresConfig,
					};
				} else {
					modules[modName] = {
						moduleName: modName,
						version,
						status: 'QUEUED',
					};
				}
			}
		});

		const missingModules = [];
		Object.keys(modules).forEach((module) => {
			missingModules.push({
				...modules[module],
				packageName: module,
			});
		});
		return missingModules;
	},
	500,
	{
		leading: true,
	}
);
