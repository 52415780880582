import { isEqual, omit } from 'lodash';

export const overwriteTabProperty = ({ mayaFlow, tabId }) => {
	const modifiedFlow = mayaFlow.map((n) => {
		return { ...n, z: tabId };
	});
	return modifiedFlow;
};

const findNearestNode = ({ node, flow }) => {
	let nearestNode;
	let proximity = Infinity;

	for (let i = 0; i < flow.length; i++) {
		const n = flow[i];
		// prettier-ignore
		let currentProximity = Math.abs((n.x + n.y) - (node.x + node.y))
		if (currentProximity < proximity) {
			proximity = currentProximity;
			nearestNode = n;
		}
	}
	return nearestNode;
};

const findAttachedNode = ({ node, flow }) => {
	for (let n = 0; n < flow.length; n++) {
		if (
			Object.values(flow[n]).includes(node['id']) &&
			node['id'] !== flow[n]['id']
		) {
			return flow[n];
		}
	}
};

export const compareRecipes = ({ recipe, serverRecipe }) => {
	const diff = {
		added: [],
		changed: [],
		deleted: [],
	};

	// Loop through each step in recipe
	for (const stepId in recipe) {
		const step = recipe[stepId];

		// If step exists in recipe but not in serverRecipe, add it to added steps
		if (!serverRecipe[stepId]) {
			if (step.text !== '') {
				diff.added.push(step);
			}
		} else {
			// Check if any of the step's properties have changed
			const serverStep = serverRecipe[stepId];
			let hasChanged = false;
			for (const prop in step) {
				if (typeof step[prop] === 'object') {
					if (!isEqual(step[prop], serverStep[prop])) {
						hasChanged = true;
						break;
					}
				} else {
					if (step[prop] !== serverStep[prop]) {
						hasChanged = true;
						break;
					}
				}
			}

			// If step has changed, add it to changed steps
			if (hasChanged) {
				diff.changed.push(step);
			}
		}
	}

	// Loop through each step in serverRecipe
	for (const stepId in serverRecipe) {
		const step = serverRecipe[stepId];

		// If step exists in serverRecipe but not in recipe, add it to deleted steps
		if (!recipe[stepId]) {
			diff.deleted.push(step);
		}
	}

	return diff;
};

/** @returns {import ('../pac-engine/types').DiffPatch} */
export const compareFlows = ({ flow, serverFlow }) => {
	flow = JSON.parse(flow);
	serverFlow = JSON.parse(serverFlow);

	const diff = {
		added: [],
		changed: [],
		deleted: [],
	};

	// Checking if a node has been added or changed
	for (let node of flow) {
		const serverNode = serverFlow.find((n) => n.id === node.id);

		if (!serverNode) {
			if (node.x && node.y) {
				const nearestNode = findNearestNode({ node, flow: serverFlow });
				const nodeWithStepId = { ...node, _step_id: nearestNode?._step_id };
				if (nearestNode) {
					diff.added.push(nodeWithStepId);
				}
			} else if (
				node.type !== 'tab' &&
				node.type !== 'subflow' &&
				node.type !== 'group'
			) {
				let attachedNode = findAttachedNode({ node, flow });
				let configNodeWithStepId = {
					...node,
					_step_id: attachedNode?._step_id,
					_id: node.id,
				};
				if (attachedNode) {
					diff.added.push(configNodeWithStepId);
				}
			}
		} else {
			const propertiesToExclude = [
				'valid',
				'color',
				'darkColor',
				'module',
				'modules',
				'version',
				'z',
				'w',
				'h',
				'changed_by_user',
			];
			let cleanNode = omit(node, propertiesToExclude);
			cleanNode = Object.keys(omit(cleanNode))
				.sort()
				.reduce((objEntries, key) => {
					objEntries[key] = cleanNode[key];
					return objEntries;
				}, {});
			let cleanServerNode = omit(serverNode, propertiesToExclude);
			cleanServerNode = Object.keys(omit(cleanServerNode))
				.sort()
				.reduce((objEntries, key) => {
					objEntries[key] = cleanServerNode[key];
					return objEntries;
				}, {});
			const equal = isEqual(cleanNode, cleanServerNode);
			if (!equal) {
				diff.changed.push(node);
			}
		}
	}

	// Checking if a node has been deleted
	for (let serverNode of serverFlow) {
		const found = flow.some((node) => node.id === serverNode.id);
		if (!found) {
			diff.deleted.push(serverNode);
		}
	}
	return diff;
};
/**
 * @param {Object} obj
 * @param {import ('../pac-engine/types').Diff} obj.diff
 * @returns {boolean}
 */
export const diffExists = ({ diff }) => {
	if (
		diff.recipe.steps.added.length === 0 &&
		diff.recipe.steps.changed.length === 0 &&
		diff.recipe.steps.deleted.length === 0 &&
		diff.flow.nodes.added.length === 0 &&
		diff.flow.nodes.changed.length === 0 &&
		diff.flow.nodes.deleted.length === 0
	) {
		return false;
	} else {
		return true;
	}
};

export const checkIfAllStepsAreGenerated = ({ recipe }) => {
	const result = Object.keys(recipe).every((id) => {
		return recipe[id].generated === true;
	});
	return result;
};

export const sanitizePastedText = ({ pastedText }) => {
	const sanitizedText = pastedText.replaceAll(/(\r\n)|\n|\r/g, '');
	return sanitizedText;
};

/**
 * @param {Object} obj
 * @param {import ('../pac-engine/types').Recipe} obj.recipe
 * @returns {number}
 */
export const nonEmptyStepCount = ({ recipe }) => {
	const nonEmptySteps = Object.keys(recipe).filter((id) => {
		if (recipe[id].text !== '') {
			return true;
		} else {
			return false;
		}
	});
	return nonEmptySteps.length;
};

export const calculateWaitTimes = ({ totalStepsToRender }) => {
	let timeMultiplier = 0;
	if (totalStepsToRender <= 5) {
		timeMultiplier = 5;
	} else if (totalStepsToRender <= 10) {
		timeMultiplier = 4;
	} else if (totalStepsToRender <= 15) {
		timeMultiplier = 3;
	} else {
		timeMultiplier = 3;
	}

	const stepWaitTime = 70 * timeMultiplier;
	const subStepWaitTime = 9 * timeMultiplier;

	return [stepWaitTime, subStepWaitTime];
};
