import React from 'react'
import {
    Flex, Box
} from '@chakra-ui/react'
import EditTeam from './Form/EditTeam'
import useStepperAnalytics from '../../../hooks/useStepperAnalytics'

const EditTeamStep = () => {
    useStepperAnalytics()
    
    return (
        <Flex
            direction='column'
            alignItems='center'
            height='100%'
        >
            <Box
                paddingY='20px'
            >
                <Flex
                    textStyle='serif.lg'
                    textAlign='center'
                    justifyContent='center'
                >
                    Edit your team.
                </Flex>
                <Flex
                    textStyle='sans.sm'
                    justifyContent='center'
                    textAlign='center'
                    mt='5px'
                    color='light.font.gray.400'
                >
                    Add, remove or modify your team members.
                </Flex>
            </Box>
            <EditTeam />
        </Flex>
    )
}

const EditTeamStepObject = {
    heading: 'edit team',
    component: <EditTeamStep />
}

export default EditTeamStepObject