import React from 'react';
import { Box, useMediaQuery } from '@chakra-ui/react';
import Profile from './ProfileButton/Profile';
import FeedbackBtn from './Feedback';
import EntryPointHeadingRight from './EntryPointHeaderRight';

const TopBarRight = ({ topBarOption }) => {
	const [isSmallerThan500] = useMediaQuery('(max-width: 500px)');
	return (
		<Box display="flex" justifyContent="center" alignItems="center">
			{topBarOption === 'entrypoint' ? (
				<EntryPointHeadingRight />
			) : (
				<>
					{isSmallerThan500 ? null : <FeedbackBtn mode={topBarOption}/>}
					<Profile />
				</>
			)}
		</Box>
	);
};

export default TopBarRight;
