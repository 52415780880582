import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	CircularProgress,
	Flex,
	Switch,
	Text,
	Tooltip,
	Grid,
	GridItem,
	Spinner,
} from '@chakra-ui/react';
import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { MayaSkeleton } from '../skeleton';
import { FileContentDisplayer } from './FileContentDisplayer';

export type SkillVisualizerSkeletonProps = {
    showPreview: boolean;
}

const FileItemSkeleton = () => {
    return (
        <Flex
            // bg='tomato'
            width='100%'
            height='42px'
            mb='10px !important'
        >
            <MayaSkeleton h='100%' w='100%' />
        </Flex>
    )
}

export type FileSectionSkeletonProps = {
    numFiles: number;
    section: string;
}

const FileSectionSkeleton = ({
    numFiles,
    section
}: FileSectionSkeletonProps) => {
    return (
        <>
            <Flex
                // bg='tomato'
                width={`${section.length*16}px`}
                height='26px'
                mb='8px'
                mt='24px'
            >
                <MayaSkeleton h='100%' w='100%' />
            </Flex>
            {[...Array(numFiles).keys()].map(idx => (
                <FileItemSkeleton />
            ))}
        </>
    )
}

export const SkillVisualizerSkeleton = ({ 
    showPreview
}: SkillVisualizerSkeletonProps) => {
	return (
		<>
			<Grid
				display="grid"
				templateColumns={
					showPreview ? 'minmax(0, 2fr) minmax(0, 1fr)' : '1fr'
				}
				gridGap="1rem"
				position="relative"
				width="100%"
				pb="20px"
				// bg='pink'
			>
				<GridItem>
					<FileSectionSkeleton numFiles={1} section='recipes' />
					<FileSectionSkeleton numFiles={3} section='steps' />
					<FileSectionSkeleton numFiles={1} section='documents' />
				</GridItem>
				{showPreview ? (
					<GridItem>
						<Box
							display="flex"
							flexDirection="column"
							flexShrink={0}
							height="400px"
							position="sticky"
							top="0px"
						>
							<Box
								display="flex"
								alignItems="center"
								justifyContent="space-between"
								marginBottom="8px"
								textStyle="sans.xs"
								color="light.font.gray.400"
							>
								<Text fontSize="lg" pt="8px">
									Preview:
								</Text>
							</Box>
							<FileContentDisplayer
								value={''}
								editable={false}
								textAreaProps={{
									resize: 'none',
									color: '#484848',
									fontFamily: 'mono',
									padding: '1rem 1.5rem',
								}}
							/>
						</Box>
					</GridItem>
				) : null}
			</Grid>
		</>
	);
};
