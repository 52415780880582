import React from 'react';
import ReactDOM from 'react-dom';
import isElectron from 'is-electron';
import './index.css';

import App from './App';

import * as serviceWorker from './serviceWorker';

console.log =
	process.env.REACT_APP_MAYA_ENV === 'production'
		? function () {}
		: console.log;

const render = () => {
	ReactDOM.render(<App />, document.getElementById('root'));
};

render();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

if (module.hot) {
	module.hot.accept('./App', () => {
		render();
	});
}

if (isElectron()) serviceWorker.unregister();
else serviceWorker.register();
