import axios from 'axios'

export async function updateFlow(runtimeUrl, accessToken, newFlow) {
    await axios({
        method: 'POST',
        url: `${runtimeUrl}/flows`,
        data: newFlow,
    })

    // return response.data
}