import React from 'react';
import { Box, Image } from '@chakra-ui/react';
// @ts-ignore
import purpleIcon from '../../../../../assets/mayaIcons/purple.svg';
import MenuItems from './MenuItems';
import StepperHeading from './StepperHeading';
import EntryPointHeading from './EntryPoint';

import useStepperStore from '../../../../Stepper/StepperStore';

/**
 *
 * @param {import ("../types/index").TopBarLeft} props
 * @returns
 */

const TopBarLeft = ({
	topBarStepperHeading = 'add text here',
	topBarOption,
	stepCount,
	currentStep,
}) => {
	// const { openStepper, stepperState, setStepperState, addStep } = useStepper();
	const { addStep, openStepper } = useStepperStore((state) => {
		return {
			addStep: state.addStep,
			nextStep: state.nextStep,
			openStepper: state.openStepper,
		};
	});
	return (
		<Box width="100%" display="flex" alignItems="center">
			<Image src={purpleIcon} boxSize="24px" userSelect="none" />
			{topBarOption === 'stepper' ? (
				<StepperHeading
					stepCount={stepCount}
					currentStep={currentStep}
					topBarStepperHeading={topBarStepperHeading}
				/>
			) : null}
			{topBarOption === 'main' ? <MenuItems /> : null}
			{topBarOption === 'entrypoint' ? <EntryPointHeading /> : null}

			{/* <StepperHeading /> */}
		</Box>
	);
};

export default TopBarLeft;
