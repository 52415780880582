import React from 'react';
import styled from '@emotion/styled';
import { Box, useColorMode, IconButton } from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import Background from '../Background';

const LandingPage = ({ children }) => {
	return (
		<Background topBarOption="entrypoint">
			{
				<Box display="flex" justifyContent="center" marginTop="4rem">
					{children}
				</Box>
			}
		</Background>
	);
};

export default LandingPage;
