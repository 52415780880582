import { HamburgerIcon } from '@chakra-ui/icons';
import {
	createStandaloneToast,
	Icon,
	IconButton,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	useDisclosure,
} from '@chakra-ui/react';
import isElectron from 'is-electron';
import React, { useCallback } from 'react';
import { BiHomeAlt } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import analytics from '../../../../util/Analytics';
import { HistoryWithSlug } from '../../../../util/History';
import WorkspaceDetailsDrawer from '../../../Dashboard/Sections/Workspaces/WorkspaceList/WorkspaceCard/WorkspaceDetailsDrawer';
import { stepperFlowTypes } from '../../../Stepper/Sections/registry';
import useStepperStore from '../../../Stepper/StepperStore';
import { editorStore, useStore } from '../../zustand';
import customTheme from '../../../../../src/library/theme';
import { instructBarStore } from '../../Tools/pac-engine/InstructBar/zustand';
import resetBrain from '../../../../functions/brain/resetBrain';
import clearSession from '../../../../functions/pac-engine/clearSession';
import useSessionId from '../../hooks/useSessionId';

const EditorMenu = ({ workspaceId, forSkeleton = false }) => {
	const { addStep, openStepper, setStore } = useStepperStore();
	const track = analytics.track;
	const sessionId = useSessionId(workspaceId);
	const runtime = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.runtime,
			[]
		)
	);
	const tabId = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.tabs.activeTab,
			[]
		)
	);
	const onPasteFlow = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.onPasteFlow,
			[]
		)
	);
	const workspace = useSelector(
		useCallback((state) => state.brains.byId[workspaceId], [workspaceId])
	);
	const {
		isOpen: workspaceDetailsDrawerIsOpen,
		onClose: workspaceDetailsDrawerOnClose,
		onOpen: workspaceDetailsDrawerOnOpen,
	} = useDisclosure();

	const toast = createStandaloneToast({ theme: customTheme });

	function handleImportClick() {
		const stepperSessionId = v4();
		const initState = {
			stepperSessionId,
			selectedWorkspaceId: workspaceId,
			stepperFlowType: stepperFlowTypes.workspace.IMPORT_NEW_FLOW,
		};
		addStep('editor.import', initState);
		setStore({
			selectedWorkspace: runtime, // Keeping this around for backward compatibility
			selectedWorkspaceId: workspaceId,
		});
		openStepper();
	}

	function handleExportClick() {
		const stepperSessionId = v4();
		const initState = {
			stepperSessionId,
			stepperFlowType: stepperFlowTypes.workspace.EXPORT_FLOW,
			workspaceId: workspaceId,
		};
		addStep('editor.export', initState);
		openStepper();
	}

	function handleSearchModules() {
		HistoryWithSlug.push('/store?type=modules');
	}

	function handlePublicModules() {
		window.RED.actions.invoke('core:manage-palette');
	}

	async function handlePasteFlow() {
		try {
			const res = await navigator.clipboard.readText();
			onPasteFlow({
				mayaFlow: JSON.parse(res),
				tabId: tabId,
			});
		} catch (error) {
			console.error(error);
			toast({
				title:
					error.message === 'subflow reference error'
						? 'Missing subflow reference'
						: 'Invalid flow pasted!',
				description:
					error.message === 'subflow reference error'
						? 'Found an unreferenced subflow node. Exclude the subflow node from your copied flow and try again.'
						: 'Make sure you are pasting a valid flow which has been exported from a Maya workspace.',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	}

	const textColor = '#555252';

	const handleBackToHome = () => {
		instructBarStore.setState({ tempSessionId: '' });
		instructBarStore.setState({ recipePreview: '' });
		editorStore.setState({ brainId: '' });
		HistoryWithSlug.push('/workers');
		track(`[Editor] Hamburger Menu Btn > Back To Home `);
	};

	const handleResetWorker = async () => {
		// Resets the flow on PAC-Engine
		await clearSession({ sessionId });
		// Resets the flow on Node-RED
		await resetBrain({ workerId: workspaceId });
		// The flow on the server has been reset but it's still the same on the client side. Node-RED thus asks to compare the differences between the two. Reloading the page avoids that and loads the flow from the server
		window.location.reload();
	};

	return (
		<Menu gutter={0}>
			<MenuButton
				as={IconButton}
				aria-label="Options"
				icon={<HamburgerIcon fontSize="22px" />}
				// icon={<ControlButton></ControlButton>}
				variant="ghost"
				_focus={{ boxShadow: 'none' }}
				borderRadius="0"
				onClick={() => {
					track(`[Editor] Hamburger Menu Button`);
				}}
			></MenuButton>
			<MenuList
				bg="light.theme.gray.50"
				borderRadius="sm"
				zIndex="dropdown"
				minWidth="44"
				py="0.5"
				boxShadow="lg"
				borderWidth="1.5px"
				borderStyle="solid"
				borderColor="light.border.gray.200"
			>
				<MenuItem
					px="2.5"
					icon={<Icon as={BiHomeAlt} boxSize="3" fontWeight="bold" />}
					iconSpacing="1.5"
					fontWeight="500 !important"
					onClick={handleBackToHome}
					fontSize="14px"
					color={textColor}
				>
					  back to home
				</MenuItem>
				{!forSkeleton && (
					<>
						<MenuDivider my="0 !important" />
						<MenuItem
							// px="2.5"
							pl="9"
							fontWeight="500 !important"
							onClick={() => {
								track(`[Editor] Hamburger Menu Button > Import Flow`);
								handleImportClick();
							}}
							fontSize="14px"
							color={textColor}
						>
							import
						</MenuItem>
						<MenuItem
							px="2.5"
							pl="9"
							fontWeight="500 !important"
							onClick={() => {
								track(`[Editor] Hamburger Menu Button > Export Flow`);
								handleExportClick();
							}}
							fontSize="14px"
							color={textColor}
						>
							export
						</MenuItem>
						{/* <MenuItem
							pl="9"
							fontWeight="500 !important"
							onClick={() => {
								track(`[Editor] Hamburger Menu Button > Paste Flow`);
								handlePasteFlow();
							}}
							fontSize="14px"
							color={textColor}
						>
							paste flow
						</MenuItem> */}
						<MenuDivider my="0 !important" />
						<MenuItem
							px="2.5"
							pl="9"
							fontWeight="500 !important"
							onClick={() => {
								track(
									`[Editor] Hamburger Menu Button > Search Modules`
								);
								handleSearchModules();
							}}
							fontSize="14px"
							color={textColor}
						>
							search modules
						</MenuItem>
						<MenuItem
							px="2.5"
							pl="9"
							fontWeight="500 !important"
							onClick={() => {
								track(
									`[Editor] Hamburger Menu Button > Public Modules`
								);
								handlePublicModules();
							}}
							fontSize="14px"
							color={textColor}
						>
							public modules
						</MenuItem>
					</>
				)}
				<MenuDivider my="0 !important" />
				<MenuItem
					pl="9"
					fontWeight="500 !important"
					onClick={handleResetWorker}
					fontSize="14px"
					color={textColor}
				>
					reset worker
				</MenuItem>
				<MenuItem
					px="2.5"
					pl="9"
					fontWeight="500 !important"
					onClick={() => {
						track(`[Editor] Hamburger Menu Button > Workspace Details`);
						workspaceDetailsDrawerOnOpen();
					}}
					fontSize="14px"
					color={textColor}
				>
					worker details
				</MenuItem>
				<MenuItem
					px="2.5"
					pl="9"
					fontWeight="500 !important"
					onClick={() => {
						HistoryWithSlug.push('/settings/editor');
						track(`[Editor] Hamburger Menu Btn > Settings `);
					}}
					fontSize="14px"
					color={textColor}
				>
					settings
				</MenuItem>
			</MenuList>
			{workspaceDetailsDrawerIsOpen && (
				<WorkspaceDetailsDrawer
					workspace={workspace}
					runtime={runtime}
					isOpen={workspaceDetailsDrawerIsOpen}
					onClose={workspaceDetailsDrawerOnClose}
					drawerContentProps={{
						marginTop: isElectron() ? '45px' : '1px',
						height: '100vh',
					}}
				/>
			)}
		</Menu>
	);
};

export default React.memo(EditorMenu);
