// Dependency imports

import isElectron from 'is-electron';

import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';

//redux
import { store } from '../../redux/store';

/**
 *
 * @param {{ runtimeId: string, modulePackageName: string, referenceId: string}} param0
 * @returns
 */
const setReferenceIdToConfigMap = async ({ runtimeId, modulePackageName, referenceId }) => {
    // API CALL
    const setReferenceIdFn = APIFunction({
        BASEURL: config.apiServer.BASEURL,
        PATH_SEARCH: APIRoutes.apiService.setReferenceIdToConfigMap.PATH_SEARCH,
        PATH_METHOD: APIRoutes.apiService.setReferenceIdToConfigMap.PATH_METHOD,
    });

    const formData = { workspaceId: runtimeId, modulePackageName, referenceId }
    try {
        await setReferenceIdFn(formData)
    } catch (e) {
        return
    }
};

export default setReferenceIdToConfigMap;
