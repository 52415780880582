import React from 'react';
import { Box } from '@chakra-ui/react';

const UndeployedElement = ({ alignment = 'right' }) => {
	return (
		<Box
			right={alignment === 'right' ? '6px' : 'none'}
			left={alignment === 'left' ? '6px' : 'none'}
			height="9px"
			width="9px"
			outline="solid 1.2px #474747"
			position="absolute"
			top="-5px"
			borderRadius="10px"
			bg="#6FA3F8"
		/>
	);
};

export default React.memo(UndeployedElement);
