import axios from 'axios'

export async function getFlow(runtimeUrl, accessToken) {
    console.log('daflow acc', accessToken)
    const response = await axios({
        method: 'GET',
        url: `${runtimeUrl}/flows`,
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    })

    return response.data
}