import React, { useState, useCallback } from 'react';
import { Box, Button, Icon, Text } from '@chakra-ui/react';
import DashboardUI from '../../DashboardUI';
import { FiExternalLink } from 'react-icons/fi';
import { CgArrowsMergeAltH, CgArrowsBreakeH } from 'react-icons/cg';
import { useStore } from '../zustand';
import WorkspaceDashboardWrapper from '../../Dashboard/Sections/WorkspaceDashboards';
import shallow from 'zustand/shallow';

const DashboardPanelView = ({ dashboardUIUrl, workspaceId }) => {
	const [width, setWidth] = useState('45vw'); // initialise to 20px

	const nodePaletteIsOpen = useStore(state => state.nodePaletteIsOpen);
	const synthesisSidebarVisible = useStore(state => state.synthesisSidebarVisible);
	const getDeployableMayaFlow = useStore(state => state.getDeployableMayaFlow, shallow)

	const expandPanelWidth = () => {
		if (nodePaletteIsOpen && !synthesisSidebarVisible) {
			setWidth('90vw');
		} else if (!nodePaletteIsOpen && synthesisSidebarVisible) {
			setWidth('80vw');
		} else if (nodePaletteIsOpen && synthesisSidebarVisible) {
			setWidth('65vw');
		} else if (!nodePaletteIsOpen && !synthesisSidebarVisible) {
			setWidth('99vw');
		}
	};

	React.useEffect(() => {
		if (width !== '45vw') {
			expandPanelWidth();
		}
	}, [nodePaletteIsOpen, synthesisSidebarVisible]);

	/**
	 * Show new dashboard only if there is at least one new dashboard
	 * node in the flow. Else, show old dashboard
	 */
	const flow = getDeployableMayaFlow()
	const showNewDashboard = flow.some(node => node.type.startsWith('dashboard-'))
	const showOldDashboard = flow.some(node => node.type.startsWith('ui_'))

	return (
		<Box
			zIndex="0"
			height="95vh"
			borderLeft="1px solid"
			borderColor="light.border.gray.200"
			backgroundColor="#F7F6F3"
			display={'flex'}
		>
			<Box
				width="5px"
				height="100vh"
				// borderRight="1px solid"
				borderColor="light.border.gray.200"
			/>
			<Box width={width}>
				{showNewDashboard && (
					<WorkspaceDashboardWrapper
						workspaceId={workspaceId}
					/>
				)}
				{!showNewDashboard && showOldDashboard && (
					<>
						<DashboardUI
							old
						/>
						<Button
							size="sm"
							position="absolute"
							top="2"
							right="110"
							_focus={{
								boxShadow: 'none',
								outline: 'none !important',
							}}
							bg="#efefef"
							borderBottomRadius="0"
							borderTopRadius="2px"
							minHeight="0"
							height="23px"
							h="auto"
							zIndex="4"
							display="flex"
							alignItems="center"
							onClick={() => {
								if (width === '45vw') {
									expandPanelWidth();
								} else {
									setWidth('45vw');
								}
							}}
						>
							<Icon
								as={width === '45vw' ? CgArrowsBreakeH : CgArrowsMergeAltH}
								boxSize="3.5"
								mr="1"
							/>
							<Text as="span" textStyle="sans.xs" fontSize="xs">
								{width === '45vw' ? 'Expand' : 'Shrink'}
							</Text>
						</Button>
						<Button
							size="sm"
							aria-label="View Dashboard"
							position="absolute"
							top="2"
							right="2"
							_focus={{
								boxShadow: 'none',
								outline: 'none !important',
							}}
							bg="#efefef"
							borderBottomRadius="0"
							borderTopRadius="2px"
							minHeight="0"
							h="auto"
							p="1"
							zIndex="4"
							display="flex"
							alignItems="center"
							as="a"
							href={dashboardUIUrl}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Icon as={FiExternalLink} boxSize="3.5" mr="1" />
							<Text as="span" textStyle="sans.xs" fontSize="xs">
								Open in tab
							</Text>
						</Button>
					</>
				)}
			</Box>
		</Box>
	);
};

export default React.memo(DashboardPanelView)
