import React, { useCallback, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import {
	Flex,
	Box,
	Button as ChakraButton,
	GridItem,
	Tooltip,
	Button,
} from '@chakra-ui/react';
import TurndownService from 'turndown';

import 'react-quill/dist/quill.snow.css';
import './styles.css';
import { useWorkspaceDashboardStore } from '../zustand';
import shallow from 'zustand/shallow';
import _ from 'lodash';

const RichTextComp = ({ 
	workspaceId, 
	width, 
	componentId, 
	handleValChange, 
	debounceBy 
}) => {
	const body = useWorkspaceDashboardStore(
		(state) => state.dashboardData[workspaceId][componentId]?.body || ''
	);
	const handleRichTextFieldChange = useWorkspaceDashboardStore(
		(state) => state.handleRichTextFieldChange,
		shallow
	);

	const debounceDuration = debounceBy >= 0 ? debounceBy : 400;
	const sendToSocket = useCallback(
		_.debounce((val) => {
			handleValChange({ body: val, format: 'html' });
		}, parseInt(debounceDuration)),
		[handleValChange]
	);

	const onChange = useCallback(
		(val) => {
			handleRichTextFieldChange(workspaceId, componentId, val);
			sendToSocket(val);
		},
		[handleRichTextFieldChange, componentId, sendToSocket]
	);

	return (
		<GridItem colSpan={parseInt(width)}>
			<Flex>
				<ReactQuill
					onChange={onChange}
					value={body}
					style={{
						width: '100%',
					}}
				/>
			</Flex>
		</GridItem>
	);
};

export const RichTextEditor = React.memo(RichTextComp);
