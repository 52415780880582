import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Heading,
	Input,
	LightMode,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spacer,
	Stack,
	useColorModeValue,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { connect } from 'react-redux';
import { setCurrentDevice, setDevices } from '../../../redux/actions';
import IPCFetch from '../../../util/IPCFetch';
import registerDevice from '../../../functions/devices/registerDevice';
import getMachineDetails from '../../../functions/devices/getMachineDetails';
import checkDevice from '../../../functions/devices/checkDevice';
import isElectron from 'is-electron';
import getMachineName from '../../../functions/devices/getMachineName';
import truncate from 'lodash/truncate';
import IconContainer from '../../../util/React_Icon';
import { IoAdd } from 'react-icons/io5';
import { MayaFilledButton } from '@mayahq/ui';

// import CustomModal from '../Reusable/ChakraCustom/CustomModal';

/**
 *
 * @param {{
 *  	onClick: () => void
 * }} param0
 * @returns
 */
const AddNewDeviceItem = ({ onClick }) => {
	var iconColor = useColorModeValue('#656565', 'white');

	return (
		<Button
			fontWeight="normal"
			p="0"
			height="3.5rem"
			width="15.5rem"
			display="flex"
			flexDirection="row"
			alignItems="center"
			justifyContent="space-between"
			fontFamily="body"
			borderRadius="5px"
			cursor="pointer"
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			onClick={onClick}
		>
			<Box display="flex" alignItems="center">
				<Box marginLeft="1rem">
					<IconContainer
						icon={<IoAdd />}
						value={{
							color: iconColor,
							className: 'global-class-name',
							size: '1.8rem',
						}}
					/>
				</Box>
				<Flex direction="column" align="flex-start" marginLeft="1rem">
					<Box
						fontSize="0.9rem"
						color={useColorModeValue('#444444', 'white')}
					>
						Add new device
					</Box>
					<Box
						fontSize="0.6rem"
						color={useColorModeValue('#888383', '#c3c3c3')}
					>
						Create a new device
					</Box>
				</Flex>
			</Box>
		</Button>
	);
};

/**
 * New Device Registration
 */
const NewDevice = ({ setCurrentDevice, onCreate }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const [deviceName, setDeviceName] = useState('');
	const [deviceNameIsCustomized, setDeviceNameIsCustomized] = useState(false);
	const [machineDetails, setMachineDetails] = React.useState({});
	console.log('machineDetails', machineDetails);

	const handleConfirm = async () => {
		try {
			const res = await registerDevice({
				name: deviceName,
			});
			if (res.error) throw new Error(res.message);
			await getMachineDetails().then(async (machineDetailsResponse) => {
				if (machineDetailsResponse) {
					let machineId = machineDetailsResponse.machineID;
					setMachineDetails(machineId);
					const deviceResponse = await checkDevice({
						machineID: machineId,
					});
					setCurrentDevice(deviceResponse.results.device);
				}
			});
			toast({
				title: 'Device Created',
				description: res.message,
				status: 'success',
				duration: 3000,
				isClosable: false,
			});
			onCreate();
			onClose();
		} catch (error) {
			console.error(error);
			toast({
				title: 'Error Occurred.',
				description: error.message,
				status: 'error',
				duration: 3000,
				isClosable: false,
			});
		}
	};

	useEffect(() => {
		if (isOpen)
			getMachineName().then((res) => {
				setDeviceName(res);
			});
	}, [isOpen]);

	return (
		<>
			<AddNewDeviceItem onClick={onOpen} />
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				size="lg"
				// {...modalProps}
			>
				<ModalOverlay />
				<ModalContent height="xs">
					<ModalCloseButton />
					<ModalBody
						flex="1"
						minHeight="0"
						display="flex"
						flexDirection="column"
						p="0"
						borderRadius={'2px'}
					>
						<Flex direction="column" flex="1" minHeight="0" p="4">
							<Box textStyle="serif.md" marginBottom="0.5rem">
								Create New Device
							</Box>
							<Input
								bg="#fff"
								borderRadius="2px"
								errorBorderColor="red.100"
								value={deviceName}
								onChange={(e) => {
									setDeviceNameIsCustomized(true);
									setDeviceName(e.target.value);
								}}
								autoFocus
								pl="16px"
								textStyle="sans.xs"
								color="light.font.gray.300"
								_focus={{
									borderColor: 'light.theme.border.200',
								}}
								spellCheck={false}
							/>
							<Box
								fontWeight="medium"
								fontSize="0.9rem"
								opacity="0.6"
								marginTop={'1rem'}
								bg="light.theme.yellow.200"
								padding="0.5rem"
								borderRadius="2px"
							>
								<span role="img">💡</span> You can build automations
								once and run them across Windows, Mac, Linux & our
								cloud.
							</Box>
						</Flex>
						<Flex py="3" px="4" borderBottomRadius="xl">
							<Spacer />
							<Stack direction="row" spacing="3">
								<MayaFilledButton
									size="sm"
									onClick={onClose}
									text="Close"
									colorScheme="gray"
								/>
								<MayaFilledButton
									size="sm"
									onClick={handleConfirm}
									text="Confirm"
								/>
							</Stack>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => {
	const { devices } = state;
	return { devices };
};

export default connect(mapStateToProps, { setCurrentDevice, setDevices })(
	NewDevice
);
