import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentProfile } from '../../redux/actions'

const useSetSlugFromParams = () => {
    // /**
    //  * @type {{ slug: String }}
    //  */
    // const params = useParams()
    // const dispatch = useDispatch()

    // const user = useSelector(
	// 	/**
	// 	 *
	// 	 * @param {import("../../redux/reducers/types/userReducer").UserState} state
	// 	 * @returns
	// 	 */
	// 	(state) => state.user
	// );

    // useEffect(() => {
    //     if (params.slug) {
    //         dispatch(setCurrentProfile(params.slug))
    //     } else {
    //         dispatch(setCurrentProfile(user.info.id))
    //     }
    // }, [params.slug])
}

export default useSetSlugFromParams