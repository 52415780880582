import React from 'react';
import { Box } from '@chakra-ui/react';
import { FiCheckCircle, FiCheckSquare } from 'react-icons/fi';
import IconContainer from '../../../util/React_Icon';

const OptionBox = ({
	text,
	selected,
	selectMode,
	onClickFn,
	textElement = undefined,
}) => {
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			bg={selected ? 'light.theme.gray.200' : 'white'}
			paddingX="12px"
			paddingY="18px"
			borderRadius="2px"
			border="solid 1px"
			borderColor="light.border.gray.200"
			cursor="pointer"
			onClick={() => {
				onClickFn(text);
			}}
		>
			<Box
				width="20px"
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				{selectMode === 'single' ? (
					selected ? (
						<IconContainer
							icon={<FiCheckCircle />}
							value={{
								color: '#7D7D7D',
								className: 'global-class-name',
								size: '16px',
							}}
						/>
					) : (
						<Box
							border="solid 1.5px"
							borderColor="light.border.gray.200"
							width="15px"
							height="15px"
							bg="none"
							borderRadius="10px"
						/>
					)
				) : null}
				{selectMode === 'multiple' ? (
					selected ? (
						<IconContainer
							icon={<FiCheckSquare />}
							value={{
								color: '#7D7D7D',
								className: 'global-class-name',
								size: '16px',
							}}
						/>
					) : (
						<Box
							border="solid 1.5px"
							borderColor="light.border.gray.200"
							width="14px"
							height="14px"
							bg="none"
							borderRadius="2px"
						/>
					)
				) : null}
			</Box>
			{textElement ? (
				textElement
			) : (
				<Box
					textStyle="sans.xs"
					ml="20px"
					width="70%"
					color="light.font.gray.400"
				>
					{text}
				</Box>
			)}
		</Box>
	);
};

export default OptionBox;
