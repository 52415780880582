import React, { useState, useEffect } from 'react'
import update from 'immutability-helper'
import { useSelector, useDispatch } from 'react-redux'
import { updateProfile } from '../../../redux/actions/profile'
import updateChecklistItem from '../../../functions/profile/updateChecklistItem'

const useChecklistItems = () => {
    const selectedProfile = useSelector(
        /**
         * 
         * @param {import("../../../redux/reducers/types/profileReducer").ProfilesState} state 
         * @returns 
         */
        state => {
            return state.profiles.profiles[state.profiles.selected]
        }
    )
    const dispatch = useDispatch()

    if (!selectedProfile.metadata) {
        return {
            checklistItems: [],
            updateChecklistItem: async (key, done) => null
        }
    }

    const setItem = async (key, done) => {
        await updateChecklistItem({
            profileSlug: selectedProfile.profileSlug,
            itemName: key,
            done: done
        })

        const newProfile = update(selectedProfile, {
            metadata: {
                checklistItems: {
                    [key]: {
                        done: { $set: done }
                    }
                }
            }
        })

        dispatch(updateProfile(selectedProfile.profileSlug, newProfile))
    }

    // const ret = [selectedProfile.metadata.checklistItems, setItem]
    return {
        checklistItems: selectedProfile.metadata.checklistItems,
        updateChecklistItem: setItem
    }
    // return ret
}

export default useChecklistItems