import * as React from 'react';
import { Skeleton, useColorMode, Box } from '@chakra-ui/react';

const MayaSkeleton = (props) => {
	const { colorMode } = useColorMode();

	return (
		<Box {...props} opacity="0.7">
			<Skeleton
				width="100%"
				height="100%"
				borderRadius={props.borderRadius || '2px'}
				startColor={'gray.200'}
				endColor={'gray.300'}
			/>
		</Box>
	);
};

export default MayaSkeleton;
