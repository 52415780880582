import { config } from '../../config';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';
import GetProfileSlug from '../../util/ProfileSlug';

/**
 * # Gets subscription details
 *
 * - Gets the subscription details from Stripe via server api
 */
const getStripeSubscriptionDetails = APIFunction({
	BASEURL: config.apiServer.BASEURL,
	PATH_SEARCH: APIRoutes.apiService.getStripeSubscriptionDetails.PATH_SEARCH,
	PATH_METHOD: APIRoutes.apiService.getStripeSubscriptionDetails.PATH_METHOD,
});

/**
 * # Get subscription details
 *
 * - Gets the current user/team's subscription details
 */
const getSubscriptionDetails = async () => {
	const { slug: profileSlug } = GetProfileSlug();
	const res = await getStripeSubscriptionDetails({ profileSlug });
	return res.data;
};

export default getSubscriptionDetails;
