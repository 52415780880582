import React from 'react';
import { Skeleton, Box, BoxProps } from '@chakra-ui/react';

export type MayaSkeletonProps = BoxProps;

export const MayaSkeleton = (props: MayaSkeletonProps) => {
	return (
		<Box opacity="0.7" {...props}>
			<Skeleton
				width="100%"
				height="100%"
				borderRadius={props.borderRadius || '2px'}
				startColor={'gray.200'}
				endColor={'gray.300'}
			/>
		</Box>
	);
};
