import React from 'react';
import { useReactFlow } from 'react-flow-renderer';

const CenterFlow = ({ x, y, zoom }) => {
	const { setCenter } = useReactFlow();
	setCenter(x, y, { zoom, duration: 1000 });
	return <div></div>;
};

export default CenterFlow;
