import isElectron from 'is-electron';

import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';
import getAuthUrl from './getAuthUrl';
/**
 * @param {import('./types/configurations').GetAuthUrlArgs} param0
 * @param {string} moduleId module identifier for which the configuration is being set up
 * Function to get all configuration profiles of a certain module for user
 */
const authenticateConfiguration = async ({ configurationType, formData }, moduleId = null) => {
	var authenticateConfigurationResponse, authenticateConfigurationIPCFn;
	let data = {
		config: {
			resource: configurationType.resource,
			type: configurationType.type,
			meta: configurationType.config.variables[0],
			redirectUrl: configurationType.redirectUrl,
			clientId: configurationType.clientId,
			authUrl: configurationType.authUrl,
			responseType: configurationType.responseType,
			extraAuthParams: configurationType.extraAuthParams,
			values: {},
		},
	};

	for (let key in formData) {
		if (key !== 'name') {
			data['config']['values'][key] = formData[key];
		}
	}
	switch(configurationType.type) {
		case 'maya-auth': {
			if(isElectron()) {
				authenticateConfigurationIPCFn = IPCFunction({
					EVENT: IPCRoutes.ipcService.authenticateConfiguration.EVENT,
				});
				try {
					authenticateConfigurationResponse =
						await authenticateConfigurationIPCFn(data);
					return authenticateConfigurationResponse['data'];
				} catch (err) {
					console.log('Error authenticating configuration');
					return {
						error: true,
						results: null,
					};
				}
			} else {
				return {
					error: true,
					message: 'This service is available only for Maya Desktop Application',
				};
			}
		}
		case 'user-oauth': {
			return getAuthUrl({ configurationType, formData}).then(async res => {
				if(res.status === 200) {
					return res;
				} else {
					return {
						error: true,
						results: null
					}
				}
			});
		}
		case 'app-oauth':{
			return {
				error: false,
				results: null
			};
		}
		default: {
			break;
		}
	}
	if (isElectron()) {
		if (window.navigator.onLine) {
		}
	} else {

	}
};

export default authenticateConfiguration;
