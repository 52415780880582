import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Flex, Box, Spinner } from '@chakra-ui/react';
import { Redirect } from 'react-router-dom';
import useQueryParams from '../../../../util/useQueryParams';
import useStepperStore from '../../../Stepper/StepperStore';
import { v4 } from 'uuid';
import MayaFilledButton from '../../../../library/buttons/FilledButton';
import { HistoryWithSlug } from '../../../../util/History';
import { stepperFlowTypes } from '../../../Stepper/Sections/registry';
import { useSelector } from 'react-redux';

const Install = () => {
	const queryParams = useQueryParams();
	const { addStep, openStepper, steps } = useStepperStore((state) => {
		return {
			openStepper: state.openStepper,
			addStep: state.addStep,
			steps: state.steps,
		};
	});
	const [loading, setLoading] = useState(true);

	const { profilesLoading, selectedProfile } = useSelector(
		/**
		 *
		 * @param {import('../../../../redux/reducers/types/profileReducer').ProfilesState} state
		 * @returns
		 */
		(state) => ({
			profilesLoading: state.profiles.profilesLoading,
			selectedProfile: state.profiles.profiles[state.profiles.selected],
		})
	);

	console.log('ifq', 'yo');

	useEffect(() => {
		/**
		 * @type {import("../../../../redux/reducers/types/profileReducer").SignUpMode}
		 */
		const mode = queryParams.get('mode');

		if (profilesLoading) {
			return;
		}

		console.log('ifq', 'yo');

		setLoading(true);
		if (mode === 'installFromQuickShare') {
			const link = queryParams.get('link');
			axios
				.get(link)
				.then((res) => {
					let linkres = res.data;
					if (typeof linkres === 'string') {
						linkres = JSON.parse(linkres);
					}

					const stepperSessionId = v4();
					const stepState = {
						stepperSessionId,
						flowToInstall: linkres,
						stepperFlowType:
							stepperFlowTypes.external.INSTALL_FROM_QUICK_SHARE,
					};
					addStep('onboarding.quickShareInstall', stepState);
					openStepper();
					setTimeout(() => setLoading(false), 1000);
				})
				.catch((e) => {
					console.error('Error fetching flow from quickshare link:', e);
					setLoading(false);
				});
		}
	}, [profilesLoading, selectedProfile.profileSlug]);

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			textStyle="sans.md"
			color="light.font.gray.400"
			height="100%"
			transform="translateY(-64px)"
		>
			{loading ? (
				<>
					<Spinner mr="12px" />
					Preparing
				</>
			) : null}
			{!loading ? (
				<MayaFilledButton
					text="Return to dashboard"
					showDotPattern={true}
					onClick={() => HistoryWithSlug.push('/workers')}
					buttonProps={{
						float: 'left',
					}}
				/>
			) : null}
		</Flex>
	);
};

export default Install;
