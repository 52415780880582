import IPCFunction from '../../util/IPCFetch/function';
import APIFunction from '../../util/APIFetch/function';
import IPCRoutes from '../../util/IPCRoutes';
import APIRoutes from '../../util/APIRoutes';

import isElectron from 'is-electron';

// config
import { config } from '../../config';

//redux
import { store } from '../../redux/store';

const getBrainFlows = async ({ brainId, device, slug }) => {
	const { user } = store.getState();
	const profileSlug = slug ? slug : user.info.id;

	let getBrainFlowsFn;

	if (device.platform !== 'cloud') {
		// For app, call the IPC backend
		getBrainFlowsFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.getCurrentBrainById.EVENT,
			PATH_QUERY: brainId,
			URL_PARAM: {
				profileSlug,
			},
		});
	} else {
		// For web, call the API
		getBrainFlowsFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.getCurrentBrainById.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.getCurrentBrainById.PATH_METHOD,
			PATH_QUERY: brainId,
			URL_PARAM: {
				profileSlug: profileSlug,
			},
		});
	}

	const getBrainFlowsResponse = await getBrainFlowsFn();
	// console.log('getBrainFlowsResponse', getBrainFlowsResponse);

	if (getBrainFlowsResponse['data']['error']) {
		return getBrainFlowsResponse['data'];
	} else {
		getBrainFlowsResponse['data']['results']['_id'] = brainId;
		return getBrainFlowsResponse['data']['results'];
	}
};

export default getBrainFlows;
