import { Box, Container } from '@chakra-ui/react'
import React from 'react'
import styled from 'styled-components'

const CallBackSection = () => {
  const [number, setNumber] = React.useState(3)

//   React.useEffect(() => {
//     function redirect() {
//       let args = {
//         event: 'code',
//         args: window.location.search,
//         callbackType: 'prod',
//       }
//       window.location = 'maya://data?=' + JSON.stringify(args)
//     }

//     setTimeout(redirect, 2000)
//   })
  return (
    <Box
      py={{ sm: '3rem', md: '12rem' }}
      px={5}
      display="flex"
      alignItems="center"
      flexDirection="column"
      textStyle="sans.md"
      fontWeight="400"
    >
      <Container maxW="container.xl">
        <Box
          style={{
            margin: 'auto',
            fontSize: '28px',
            // backgroundColor: 'red'
          }}
        >
          <Box
            fontSize='40px'
            textAlign='center'
            color='light.font.gray.400'
            width='100%'
            fontWeight='600'
            marginTop='40px'
            
          >{`Success!`}</Box>
          <Box
            fontSize='24px'
            textAlign='center'
            width='100%'
            marginTop='30px'
            color='light.font.gray.400'
          >{`You will be shortly redirected back to the main application.`}</Box>
        </Box>
      </Container>
    </Box>
  )
}

export default CallBackSection