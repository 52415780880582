import React, { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import analytics from '../../../util/Analytics';
import useStepperStore from '../StepperStore';

const useStepperAnalytics = () => {
	const { selectedProfile, userProfile } = useSelector(
		/**
		 *
		 * @param {import("../../../redux/reducers/types/profileReducer").ProfilesState} state
		 * @returns
		 */
		(state) => ({
			selectedProfile: state.profiles?.profiles?.[state?.profiles?.selected],
			userProfile: state?.profiles?.profiles?.[state?.profiles?.userSlug],
		})
	);

	const email = useSelector(
		/**
		 *
		 * @param {import('../../../redux/reducers/types/userReducer').UserState} state
		 * @returns
		 */
		(state) => state.user?.info?.email
	);

	const { currentStep: currentStepIndex, steps } = useStepperStore((state) => {
		return {
			currentStep: state.currentStep,
			steps: state.steps,
		};
	});

	useEffect(() => {
		const currentStepId = steps.allIds[currentStepIndex];
		const currentStep = steps.byId[currentStepId];
		const { stepperFlowType, stepperSessionId = 'abc' } = currentStep.state;

		if (email && selectedProfile) {
			analytics.track('[Stepper] Step Open', {
				profileSlug: selectedProfile.profileSlug,
				name: selectedProfile.name,
				userSlug: userProfile.profileSlug,
				userEmail: email,
				id: selectedProfile._id,
	
				step: currentStepId,
				stepperFlowType: stepperFlowType,
				stepperSessionId: stepperSessionId,
			});
		}
	}, []);
};

export default useStepperAnalytics;
