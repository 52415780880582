// @ts-nocheck
import React from 'react';
import WorkspaceSection from './Workspaces';
import StoreSection from './Store';
import ModulePage from './Store/ModulePage/ModulePage';
import SettingsSection from './Settings';
import CallbackSection from './Callback';
import Install from './Install/Install';
import { AcceptInvite } from './AcceptInvite/AcceptInvite';
import CollectionPage from './Store/CollectionPage';
import { WorkspaceDashboard } from './WorkspaceDashboards/WorkspaceDashboard';
import Library from './Library/Library';
import Configure from './Configure/Configure';

const SectionList = {
	workspaces: (currentWorkspaceId) => (
		<WorkspaceSection currentWorkspaceId={currentWorkspaceId} />
	),
	install: () => <Install />,
	acceptInvite: () => <AcceptInvite />,
	store: (currentWorkspaceId) => (
		<StoreSection currentWorkspaceId={currentWorkspaceId} />
	),
	storeModulePage: (currentWorkspaceId) => (
		<ModulePage currentWorkspaceId={currentWorkspaceId} />
	),
	storeCollectionPage: (currentWorkspaceId) => (
		<CollectionPage currentWorkspaceId={currentWorkspaceId} />
	),
	orders: (currentWorkspaceId) => (
		<StoreSection currentWorkspaceId={currentWorkspaceId} />
	),
	tasks: (currentWorkspaceId) => (
		<StoreSection currentWorkspaceId={currentWorkspaceId} />
	),
	settings: () => <SettingsSection />,
	library: () => <Library />,
	configure: () => <Configure/>,
	callback: () => <CallbackSection />,
	workspaceDashboards: () => <WorkspaceDashboard />
};

export default SectionList;
