import getRedInstance from '../getRedInstance';
import RED_EVENTS from './events';

/**
 * Attach a listener to the runtime deploy notification event.
 *
 * @param {(...args: any[]) => void} handler
 */
export const onRedRuntimeDeployNotification = (handler) => {
	const RED = getRedInstance();
	RED.events.on(RED_EVENTS.MAYA_NOTIFICATION_RUNTIME_DEPLOY, handler);
};

/**
 * Detach listener from the runtime deploy notification event.
 *
 * @param {(...args: any[]) => void} handler
 */
export const offRedRuntimeDeployNotification = (handler) => {
	const RED = getRedInstance();
	RED.events.off(RED_EVENTS.MAYA_NOTIFICATION_RUNTIME_DEPLOY, handler);
};

/**
 * Attach a listener to the resolve conflicts merge event.
 * This event is fired after merging remote flows with the
 * editor current flow.
 *
 * @param {(...args: any[]) => void} handler
 */
export const onRemoteFlowsResolveConflictMerge = (handler) => {
	const RED = getRedInstance();
	RED.events.on(RED_EVENTS.MAYA_RESOLVE_CONFLICT_MERGE, handler);
};

/**
 * Detach listener from the resolve conflicts merge event.
 * This event is fired after merging remote flows with the
 * editor current flow.
 *
 * @param {(...args: any[]) => void} handler
 */
export const offRemoteFlowsResolveConflictMerge = (handler) => {
	const RED = getRedInstance();
	RED.events.off(RED_EVENTS.MAYA_RESOLVE_CONFLICT_MERGE, handler);
};

/**
 * Attach a listener to the public module install complete event.
 * This event is fired after installing public modules completes.
 *
 * @param {(arg: { error: any }) => void} handler
 */
export const onPublicModuleInstallComplete = (handler) => {
	const RED = getRedInstance();
	RED.events.on(RED_EVENTS.MAYA_PUBLIC_MODULE_INSTALL_COMPLETE, handler);
};

/**
 * Detach listener from the public module install complete event.
 * This event is fired after installing public modules completes.
 *
 * @param {(...args: any[]) => void} handler
 */
export const offPublicModuleInstallComplete = (handler) => {
	const RED = getRedInstance();
	RED.events.off(RED_EVENTS.MAYA_PUBLIC_MODULE_INSTALL_COMPLETE, handler);
};

/**
 * listener for `registry:node-type-added`
 *
 * @param {(arg: any) => void} handler
 */
export const onNodeRedNodeTypeAdded = (handler) => {
	const RED = getRedInstance();
	RED.events.on(RED_EVENTS.NODE_RED_REGISTRY_NODE_TYPE_ADDED, handler);
};

/**
 * un-listen: `registry:node-type-added`
 *
 * @param {(...args: any[]) => void} handler
 */
export const offNodeRedNodeTypeAdded = (handler) => {
	const RED = getRedInstance();
	RED.events.off(RED_EVENTS.NODE_RED_REGISTRY_NODE_TYPE_ADDED, handler);
};

/**
 * listener for `maya:core:toggle-palette`
 * - receives the new state of the palette
 *
 * @param {(state: boolean) => void} handler
 */
export const onMayaToggleNodePalette = (handler) => {
	const RED = getRedInstance();
	RED.events.on(RED_EVENTS.MAYA_TOGGLE_PALETTE, handler);
};

/**
 * un-listen: `maya:core:toggle-palette`
 *
 * @param {(state: boolean) => void} handler
 */
export const offMayaToggleNodePalette = (handler) => {
	const RED = getRedInstance();
	RED.events.off(RED_EVENTS.MAYA_TOGGLE_PALETTE, handler);
};

/**
 * listener for `nodes:change`
 *
 * @param {(arg: any) => void} handler
 */
export const onNodeRedNodeChange = (handler) => {
	const RED = getRedInstance();
	RED.events.on(RED_EVENTS.NODE_RED_NODE_CHANGE, handler);
};

/**
 * un-listen: `nodes:change`
 *
 * @param {(...args: any[]) => void} handler
 */
export const offNodeRedNodeChange = (handler) => {
	const RED = getRedInstance();
	RED.events.off(RED_EVENTS.NODE_RED_NODE_CHANGE, handler);
};

/**
 * listener for `debug`
 *
 * @param {(arg: any) => void} handler
 */
 export const onNodeRedDebugMessage = (handler) => {
	const RED = getRedInstance()
	RED.comms.subscribe(RED_EVENTS.NODE_RED_DEBUG_MESSAGE, handler)
}

/**
 * un-listen: `debug`
 *
 * @param {(arg: any) => void} handler
 */
export const offNodeRedDebugMessage = (handler) => {
	const RED = getRedInstance()
	RED.comms.unsubscribe(RED_EVENTS.NODE_RED_DEBUG_MESSAGE, handler)
}

/**
 * listener for `node.error` events
 *
 * @param {(arg: any) => void} handler
 */
 export const onNodeRedNodeError = (handler) => {
	const RED = getRedInstance()
	RED.comms.subscribe(RED_EVENTS.NODE_RED_NODE_ERROR, handler)
}

/**
 * un-listen: `node.error` events
 *
 * @param {(arg: any) => void} handler
 */
export const offNodeRedNodeError = (handler) => {
	const RED = getRedInstance()
	RED.comms.unsubscribe(RED_EVENTS.NODE_RED_NODE_ERROR, handler)
}

/**
 * listener for `node.warn` events
 *
 * @param {(arg: any) => void} handler
 */
 export const onNodeRedNodeWarning = (handler) => {
	const RED = getRedInstance()
	RED.comms.subscribe(RED_EVENTS.NODE_RED_NODE_WARNING, handler)
}

/**
 * un-listen: `node.warn` events
 *
 * @param {(arg: any) => void} handler
 */
export const offNodeRedNodeWarning = (handler) => {
	const RED = getRedInstance()
	RED.comms.unsubscribe(RED_EVENTS.NODE_RED_NODE_WARNING, handler)
}

/**
 * listener for `node.warn` events
 *
 * @param {(arg: any) => void} handler
 */
export const onNodeExecStatusNotification = (handler) => {
	const RED = getRedInstance()
	RED.comms.subscribe(RED_EVENTS.NODE_RED_NODE_EXECUTION_STATUS, handler)
}

/**
 * un-listen: `node.warn` events
 *
 * @param {(arg: any) => void} handler
 */
export const offNodeExecStatusNotification = (handler) => {
	const RED = getRedInstance()
	RED.comms.unsubscribe(RED_EVENTS.NODE_RED_NODE_EXECUTION_STATUS, handler)
}