import React from 'react';
import { getBezierPath, getMarkerEnd } from 'react-flow-renderer';

export default function CustomEdge({
	id,
	sourceX,
	sourceY,
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	style = {},
	data,
	markerEnd,
}) {
	const edgePath = getBezierPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition,
	});

	return (
		<>
			<path
				id={id}
				style={{ ...style, strokeWidth: '4' }}
				className="react-flow__edge-path"
				d={edgePath}
				markerEnd={markerEnd}
			/>
			<text>
				<textPath
					href={`#${id}`}
					style={{ fontSize: '12px' }}
					startOffset="50%"
					textAnchor="middle"
				>
					{data.text}
				</textPath>
			</text>
		</>
	);
}

export const edgeTypes = {
	custom: CustomEdge,
};
