/**
 * Redux Store
 *
 * Creates a Redux store for the application and passes the root reducer
 * to the store. Also applies the thunk middleware so that actions can
 * be dispatched asynchronously.
 */

// Dependencies
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';
import isElectron from 'is-electron';
//import storage from "redux-persist/lib/storage/session";
// Reducers
import rootReducer from '../reducers';
import electronReduxMiddleware from './redux-electron-window';
// Redux Persistence Config.
const persistConfig = {
	key: 'MayaLabsStudio',
	storage,
	whitelist: ['user', 'devices', 'commandHistory'],
};

const selectStore = () => {
	if (process.env.NODE_ENV === 'production') {
		if (isElectron()) {
			const electronRedux = electronReduxMiddleware();
			return createStore(
				persistedReducer,
				applyMiddleware(thunk, electronRedux)
			);
		} else {
			return createStore(persistedReducer, applyMiddleware(thunk));
		}
	} else {
		if (isElectron()) {
			const electronRedux = electronReduxMiddleware();
			return createStore(
				persistedReducer,
				applyMiddleware(thunk, electronRedux, logger)
			);
		} else {
			return createStore(persistedReducer, applyMiddleware(thunk, logger));
		}
	}
};

// Reducer for persisted config.
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store.
const store = selectStore();
const persistor = persistStore(store);
store.dispatch({ type: 'IPC::CONNECT', payload: '' });

// Export the Redux store.
export { store, persistor };
