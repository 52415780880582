import React, { useEffect, useState, useRef, useContext } from 'react';
import { Box, Grid } from '@chakra-ui/react';
import { ModuleCard } from './ModuleCard';
import ModuleCardSkeleton from './ModuleCardSkeleton';
import { StoreApp } from '../types/Apps';
import { StoreCategory } from '../types/Categories';
import StoreContext from '../StoreContext';
import { StoreModule } from '../types/Modules';
import { StoreEntity } from '../zustand';

function getColumns(width: number) {
	if (!width) {
		return 3;
	}
	if (width > 1200) {
		return 4;
	} else if (width > 900) {
		return 3;
	} else if (width > 600) {
		return 2;
	} else if (width > 300) {
		return 1;
	}
}

export interface ModuleListProps {
	apps: { ids: string[]; byId: { [key: string]: StoreEntity<StoreApp> } };
	categories: {
		ids: string[];
		byId: { [key: string]: StoreEntity<StoreCategory> };
	};
	searchQuery: string;
}

const ModuleList = ({ apps, categories, searchQuery }: ModuleListProps) => {
	const { onStoreModulesGet, onModuleCardRender } = useContext(StoreContext);
	const [loading, setLoading] = useState(true);
	const [modules, setModules] = useState<StoreModule[]>([]);
	const [gridColumns, setGridColumns] = useState<number | undefined>(3);

	const gridRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (apps === null || categories === null) {
			return;
		}
		setLoading(true);

		const selectedCategories = Object.values(categories.byId).filter(
			c => c._selected
		);
		const selectedApps = Object.values(apps.byId).filter(a => a._selected);
		onStoreModulesGet({
			categories: Object.values(selectedCategories),
			apps: Object.values(selectedApps),
			search: searchQuery ? searchQuery : undefined,
		}).then(modules => {
			const sortedByTotalInstallsDesc = modules.sort(
				(a, b) => b.totalInstalls - a.totalInstalls
			);
			setModules(sortedByTotalInstallsDesc);
			// setTimeout(() => setLoading(false), 200) // This might not be such a bad idea. Makes UI look stable
			setLoading(false);
		});
	}, [apps, categories, onStoreModulesGet, searchQuery]);

	useEffect(() => {
		/**
		 * Listening to changes in the grid container's width to make the grid columns
		 * respond to changes in the same. Doing it like this because the theme breakpoints
		 * were not working and I'm no CSS ninja. It's dirty but it works
		 */
		function adjustModuleColumns() {
			if (!gridRef || !gridRef?.current) {
				return;
			}
			const currentWidth = gridRef?.current?.clientWidth;
			setGridColumns(getColumns(currentWidth));
		}
		adjustModuleColumns();
		window.addEventListener('resize', adjustModuleColumns);
		return () => {
			window.removeEventListener('resize', adjustModuleColumns);
		};
	}, []);

	return (
		<Grid
			ref={gridRef}
			minChildWidth={'320px'}
			gap="8px"
			columns={3}
			mb="16px"
			// autoColumns={true}
			templateColumns={`repeat(${gridColumns}, minmax(0, 1fr))`}
		>
			{loading
				? [1, 2].map(idx => <ModuleCardSkeleton key={idx} />)
				: modules.map(module => onModuleCardRender({ module }))}
		</Grid>
	);
};

export default ModuleList;
