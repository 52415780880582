import React from 'react';
import { Flex, Box, BoxProps } from '@chakra-ui/react';

type InfoTagProps = {
	label: string;
	align?: BoxProps['textAlign'];
	containerProps?: BoxProps;
};

export const InfoTag: React.FC<InfoTagProps> = ({
	label,
	align = 'left',
	children,
	containerProps,
}) => {
	return (
		<Box
			textAlign={align}
			// textAlign='right'
			{...containerProps}
		>
			<Box textStyle="sans.sm" color="light.font.gray.400">
				{label}
			</Box>
			<Box
				// mt='4px'
				color="light.font.gray.200"
				textStyle="sans.xs"
				pl="1px"
			>
				{children}
			</Box>
		</Box>
	);
};
