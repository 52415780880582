import React, { useCallback, useMemo } from 'react';
import {
	Input,
	FormLabel,
	Box,
	FormControl,
	FormErrorMessage,
	Grid,
} from '@chakra-ui/react';
import { Flex } from '@chakra-ui/layout';
import MayaFilledButton from '../../../../../library/buttons/FilledButton';
import { ArrowForwardIcon, DownloadIcon } from '@chakra-ui/icons';
import { useFormik } from 'formik';
import FlowJsonView from '../Export/FlowJsonView';
import ActionBtn from '../../../../../library/buttons/ActionBtn';
import FlowVisualizer from '../../../../../library/FlowVisualizer';
import rectifyJsonWithTabs from './rectifyJsonWithTabs';

/**
 *
 * @param {{
 * 	mayaFlowJson: [],
 * 	onContinue: (arg: { mayaFlowJson: any[]}) => Promise<void>,
 * }} param0
 * @returns
 */
const Form = ({ mayaFlowJson, onContinue }) => {
	const formikInitValues = useMemo(
		() => ({
			/**
			 * @type {'clipboard' | 'library' | 'examples'}
			 */
			selectedImportOption: 'clipboard',
			textAreaValue: JSON.stringify(mayaFlowJson, null, 2),
		}),
		[mayaFlowJson]
	);

	/** formik instance for managing the import flow json form state */
	const formik = useFormik({
		initialValues: formikInitValues,
		validate: (values) => {
			const errors = {};
			try {
				if (!values.textAreaValue) {
					throw new Error('Please add a valid MayaJSON');
				}
				const jsonObj = JSON.parse(values.textAreaValue);
				if (Array.isArray(jsonObj) && jsonObj.length > 0) {
					if (jsonObj.some((obj) => !obj.id || !obj.type)) {
						throw new Error(`Please check your JSON for valid nodes!`);
					}
				} else throw new Error(`Please add a valid MayaJSON`);
			} catch (error) {
				errors.textAreaValue = error.message;
			}
			return errors;
		},
		validateOnMount: true,
		validateOnChange: true,
		isInitialValid: false,
		onSubmit: async (values, actions) => {
			try {
				actions.setSubmitting(true);

				await onContinue({
					mayaFlowJson: rectifyJsonWithTabs(mayaJson),
				});
				actions.setSubmitting(false);
			} catch (error) {
				console.error(error);
			}
		},
	});

	const mayaJson = useMemo(() => {
		try {
			const parsedJson = JSON.parse(formik.values.textAreaValue);
			return parsedJson;
		} catch (error) {
			return [];
		}
	}, [formik.values.textAreaValue]);

	/**
	 *
	 * @param {import('react').ChangeEvent<HTMLInputElement>} event
	 */
	const handleFileSelectorChange = (event) => {
		try {
			if (!event.target.files.length) throw new Error(`no file selected!`);
			const file = event.target.files[0];
			const fr = new FileReader();
			fr.onload = async (e) => {
				try {
					formik.setFieldTouched('textAreaValue');
					const parsedJson = JSON.parse(e.target.result.toString());
					const rectifiedJson = rectifyJsonWithTabs(parsedJson);
					formik.setValues((values) => ({
						...values,
						// prettify json text
						textAreaValue: JSON.stringify(rectifiedJson, null, 2),
					}));
				} catch (error) {
					console.error(error);
					formik.setValues((values) => ({
						...values,
						textAreaValue: '',
					}));
					// @ts-ignore
					formik.setErrors((errors) => ({
						...errors,
						textAreaValue: error.message,
					}));
				}
			};
			fr.readAsText(file);
		} catch (error) {
			// @ts-ignore
			formik.setErrors((errors) => ({
				...errors,
				textAreaValue: error.message,
			}));
		}
	};

	const fetchClipboardContents = async () => {
		try {
			const res = await navigator.clipboard.readText();
			const parsedJson = JSON.parse(res);
			const rectifiedJson = rectifyJsonWithTabs(parsedJson);
			formik.setValues((values) => ({
				...values,
				// prettify json text
				textAreaValue: JSON.stringify(rectifiedJson, null, 2),
			}));
		} catch (error) {
			console.log('Fetch Error', error);
			formik.setFieldError('textAreaValue', error.message);
		}
	};
	/**
	 *
	 * @param {import('react').ChangeEvent<HTMLTextAreaElement>} event
	 */
	const handleTextAreaChange = (event) => {
		formik.setFieldTouched('textAreaValue');
		formik.setValues((values) => ({
			...values,
			textAreaValue: event.target.value,
		}));
	};

	return (
		<>
			<Grid
				gridTemplateColumns="repeat(2, minmax(0,1fr))"
				gridGap="1rem"
				height="40vh"
				width="55vw"
				margin="2rem"
			>
				<FlowVisualizer mayaFlowJson={mayaJson} />
				<Flex
					flex="1"
					minHeight="0"
					flexDirection="column"
					bg="white"
					borderColor="light.border.gray.200"
				>
					<FormControl
						flex="1"
						minHeight="0"
						display="flex"
						flexDirection="column"
						isInvalid={
							!!formik.errors.textAreaValue &&
							formik.touched.textAreaValue
						}
					>
						<FlowJsonView
							value={formik.values.textAreaValue}
							onChange={handleTextAreaChange}
							onClick={fetchClipboardContents}
							editable
						/>
						<FormErrorMessage
							textStyle="sans.xs"
							p="1"
							m="0"
							justifyContent="center"
							textAlign="center"
							color="light.theme.gray.500"
						>
							{formik.errors.textAreaValue}
						</FormErrorMessage>
					</FormControl>
				</Flex>
			</Grid>
			<Box
				display="flex"
				flexDir="row"
				justifyContent="space-around"
				mb="2rem"
			>
				<Box
					textStyle="sans.xs"
					color="light.font.gray.400"
					mr="2rem"
					height="3rem"
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					or upload JSON file
				</Box>
				<FormLabel htmlFor="jsonFile">
					<MayaFilledButton
						text={'select file to import'}
						onClick={() => {}}
						buttonProps={{
							width: '230px',
							// height: '3rem',
						}}
						leftIcon={<DownloadIcon />}
						colorScheme="gray"
						showDots={false}
					/>
					<Input
						id="jsonFile"
						type="file"
						position="absolute"
						w="1px"
						height="1px"
						p="0"
						overflow="hidden"
						border="0"
						_focusWithin={{
							boxShadow: 'none',
						}}
						onChange={handleFileSelectorChange}
					/>
				</FormLabel>
			</Box>
			<MayaFilledButton
				text={'Continue'}
				onClick={formik.handleSubmit}
				isDisabled={!formik.isValid}
				buttonProps={{
					width: '250px',
				}}
				rightIcon={<ArrowForwardIcon />}
				colorScheme="purple"
				isLoading={formik.isSubmitting}
			/>
			{mayaJson.length >= 1 ? (
				<Box mt="15px">
					<ActionBtn text="CLEAR" onClick={() => formik.resetForm()} />
				</Box>
			) : null}
		</>
	);
};

export default Form;
