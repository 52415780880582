import React from 'react';
import useTasks from '../../../Hooks/useTasks';
import TaskCardList from './TaskCard';
import { Flex, Box } from '@chakra-ui/react';

const Tasks = ({ workspaceStatus, workspaceId }) => {
	const tasks = useTasks(workspaceId);
	return (
		<>
			{workspaceStatus === 'STARTED' ? (
				<Box mt="8px">
					<TaskCardList tasks={tasks} />
				</Box>
			) : (
				<Flex
					textStyle="sans.sm"
					color="light.font.gray.400"
					justifyContent="center"
					mt="8px"
					mb="16px"
				>
					Start workspace to view tasks
				</Flex>
			)}
		</>
	);
};

export default Tasks;
