import React from 'react';
import { Box } from '@chakra-ui/react';

const Avatar = ({ name, size }) => {
	return (
		<Box
			width={`${size}px`}
			// width={`50px`}
			height={`${size}px`}
			borderRadius="10px"
			bg="light.theme.purple.400"
			display="flex"
			alignItems="center"
			justifyContent="center"
			ml="2"
		>
			<Box 
				textStyle="sans.xs" 
				// width="100%" 
				textAlign="center" 
				color="white"
			>
				{name ? name.charAt(0).toUpperCase() : 'M'}
			</Box>
		</Box>
	);
};

export default Avatar;
