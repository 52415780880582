import { Box, createStandaloneToast, Tooltip } from '@chakra-ui/react';
import React, { useCallback, useRef, useMemo } from 'react';
import { useStore } from '../../zustand';
import MissingFieldCard from './MissingFieldCard';
import { cloneDeep, unionBy, uniq } from 'lodash';
import { BiCode } from 'react-icons/bi';
import { MdCallMerge } from 'react-icons/md';
import useSessionId from '../../../../components/Editor/hooks/useSessionId';
import customTheme from '../../../../../src/library/theme/index';
import getRedInstance from '../functions/getRedInstance';
import { deployEditor } from '../functions/NodeRed';
import ConfiguredNodeCard from './ConfiguredNodeCard';

const CardContainer = ({ nodes, validNode, tabId }) => {
	const toast = createStandaloneToast({ theme: customTheme });
	const mergeToastRef = useRef(null);
	const separateToastRef = useRef(null);

	const setFlowToTab = useStore(
		useCallback((state) => state.setFlowToTab, [])
	);
	const getReactFlowByTab = useStore(
		useCallback((state) => state.getReactFlowByTab, [])
	);
	const onDeploy = useStore(useCallback((state) => state.onDeploy, []));
	const brainId = useStore(useCallback((state) => state.brainId, []));
	const sessionId = useSessionId(brainId);
	const toggleIssuesSidebar = useStore(
		useCallback((state) => state.toggleIssuesSidebar, [])
	);
	const setNodesWithIssues = useStore(
		useCallback((state) => state.setNodesWithIssues, [])
	);

	const commonMissingRequirement = useMemo(() => {
		const nodeRequirementsByType = {};
		nodes.forEach((node) => {
			const nodeType = node?.data?.type;
			const defaults = node?.nodeModule?.defaults;
			for (let prop in defaults) {
				if (defaults[prop]?.required) {
					if (!nodeRequirementsByType[nodeType]) {
						nodeRequirementsByType[nodeType] = [prop];
					} else if (nodeRequirementsByType[nodeType]) {
						nodeRequirementsByType[nodeType].push(prop);
					}
				}
			}
		});

		// start

		const arrOfRequirementsArr = Object.keys(nodeRequirementsByType).map(
			(req) => {
				return nodeRequirementsByType[req];
			}
		);

		const uniqueArrOfRequirementsArr = arrOfRequirementsArr.map((arrReq) => {
			const newArr = uniq(arrReq);
			return newArr;
		});

		function findCommonElements(arrays) {
			// check if arrays is empty
			if (!arrays || arrays.length === 0) {
				return [];
			}

			// use the first array as a starting point
			const firstArray = arrays[0];

			// use reduce() to iterate over the first array and compare each element to the other arrays
			const commonElements = firstArray.reduce(
				(accumulator, currentElement) => {
					// check if currentElement is in all other arrays using every()
					const isCommon = arrays.every((array) =>
						array.includes(currentElement)
					);
					// add currentElement to the accumulator if it's in all other arrays
					if (isCommon && !accumulator.includes(currentElement)) {
						accumulator.push(currentElement);
					}
					return accumulator;
				},
				[]
			);

			return commonElements;
		}

		const commonRequirements = findCommonElements(uniqueArrOfRequirementsArr);
		const commonRequirement = commonRequirements[0];
		// end

		if (commonRequirement) {
			return commonRequirement;
		} else {
			return null;
		}
	}, [nodes]);

	const autoConfigPossible =
		validNode &&
		commonMissingRequirement &&
		Object.keys(validNode.data.node).includes(commonMissingRequirement);

	const handleMergeClick = async () => {
		try {
			mergeToastRef.current = toast({
				title: 'Reusing existing configuration...',
				duration: 9000,
				isClosable: false,
				position: 'top',
				status: 'info',
			});

			const commonFieldObj = {
				[commonMissingRequirement]:
					validNode.data.node[commonMissingRequirement],
			};

			// 3. set the values taken in step 2, to all the nodes
			const overwrittenNodes = nodes.map((n) => {
				return {
					...n,
					data: {
						...n.data,
						node: { ...n.data.node, ...commonFieldObj },
					},
				};
			});

			// 4. call a function to persist the change
			const existingReactFlow = getReactFlowByTab({ tabId });
			setFlowToTab(tabId, {
				nodes: [...existingReactFlow.nodes, ...overwrittenNodes],
				edges: existingReactFlow.edges,
			});
			await new Promise((r) => setTimeout(r, 500));
			toggleIssuesSidebar();
			await onDeploy(sessionId);
			await new Promise((r) => setTimeout(r, 500));
			setNodesWithIssues(getReactFlowByTab({ tabId }).nodes);

			if (mergeToastRef.current) {
				toast.update(mergeToastRef.current, {
					title: 'Configurations updated',
					duration: 2000,
					isClosable: true,
					position: 'top',
					status: 'success',
				});
			}
		} catch (err) {
			console.error(err);
			if (mergeToastRef.current) {
				toast.close(mergeToastRef.current);
			}
			toast({
				title: 'Error! Please try again.',
				duration: 2000,
				isClosable: true,
				position: 'bottom',
				status: 'error',
			});
		}
	};

	const handleSeparateClick = async () => {
		try {
			separateToastRef.current = toast({
				title: 'Creating configurations...',
				duration: 9000,
				isClosable: false,
				position: 'top',
				status: 'info',
			});

			const commonFieldObj = {
				[commonMissingRequirement]:
					validNode.data.node[commonMissingRequirement],
			};

			// 3. create missing fields
			const RED = getRedInstance();
			const existingRequiredFieldValue = RED.nodes
				.createCompleteNodeSet()
				.find((n) => n.id === commonFieldObj[commonMissingRequirement]);

			// 4. add the newly created node to the missing field property
			const nodeIds = nodes.map((n) => n.id);
			const mayaFlowNodes = RED.nodes
				.createCompleteNodeSet()
				.filter((n) => nodeIds.includes(n.id));
			let flowToDeploy = [];
			mayaFlowNodes.forEach(async (n, index) => {
				const newId = RED.nodes.id();
				const missingRequiredNode = cloneDeep(existingRequiredFieldValue);
				missingRequiredNode.id = newId;
				missingRequiredNode.name = mayaFlowNodes[index]['name']
					? mayaFlowNodes[index]['name'] + '-' + index
					: mayaFlowNodes[index]['label']
					? mayaFlowNodes[index]['label'] + '-' + index
					: mayaFlowNodes[index]['type'] + '-' + index;
				mayaFlowNodes[index][commonMissingRequirement] = newId;
				// mayaFlowNodes[index]['name'] = n.name + '-' + makeid(5);
				const redNodes = RED.nodes.createCompleteNodeSet();
				const newMayaFlow = unionBy(mayaFlowNodes, redNodes, 'id');
				flowToDeploy.push(...newMayaFlow);
				flowToDeploy.push(missingRequiredNode);
			});

			// 5. save the changes
			toggleIssuesSidebar();
			await deployEditor(true, true, flowToDeploy);
			await new Promise((r) => setTimeout(r, 500));
			setNodesWithIssues(getReactFlowByTab({ tabId }).nodes);

			if (separateToastRef.current) {
				toast.update(separateToastRef.current, {
					title: 'Configurations updated',
					duration: 2000,
					isClosable: true,
					position: 'top',
					status: 'success',
				});
			}
		} catch (err) {
			console.error(err);
			if (separateToastRef.current) {
				toast.close(separateToastRef.current);
			}
			toast({
				title: 'Error! Please try again.',
				duration: 2000,
				isClosable: true,
				position: 'bottom',
				status: 'error',
			});
		}
	};

	return (
		<>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				borderBottom="1px solid #D8D8D8"
				textStyle="sans.sm"
				paddingBottom="8px"
				marginTop="16px"
			>
				<Box>{nodes[0]?.nodeModule?.set?.module}</Box>
				<Tooltip
					label={
						!validNode
							? 'Configure a missing requirement to enable auto-configure options'
							: !commonMissingRequirement
							? 'Nodes have different required fields. Auto-configuration is not available'
							: null
					}
				>
					<Box display="flex">
						<Tooltip
							label={
								autoConfigPossible
									? 'Create individual config for each'
									: null
							}
							openDelay={300}
						>
							<Box
								cursor={autoConfigPossible ? 'pointer' : 'default'}
								color={autoConfigPossible ? '#707070' : '#ABABAB'}
								onClick={
									autoConfigPossible ? handleSeparateClick : null
								}
							>
								<BiCode />
							</Box>
						</Tooltip>

						<Tooltip
							label={
								autoConfigPossible
									? 'Create matching config for all'
									: null
							}
							openDelay={300}
						>
							<Box
								marginLeft="14px"
								cursor={autoConfigPossible ? 'pointer' : 'default'}
								color={autoConfigPossible ? '#707070' : '#ABABAB'}
								onClick={autoConfigPossible ? handleMergeClick : null}
							>
								<MdCallMerge />
							</Box>
						</Tooltip>
					</Box>
				</Tooltip>
			</Box>
			<Box marginTop="14px">
				{autoConfigPossible ? (
					<ConfiguredNodeCard node={validNode} />
				) : null}
				{nodes.map((n) => {
					return <MissingFieldCard key={n.id} node={n} />;
				})}
			</Box>
		</>
	);
};

export default CardContainer;
