import React from 'react';

//Libs
import { Box, useColorMode } from '@chakra-ui/react';

export const IntentInputItemDisplay = ({ item, highlighted, sizeMultiple }) => {
	const bgColor = {
		light: 'card_ui.placeholder_light',
		dark: 'card_ui.placeholder_dark',
	};

	const color = {
		light: 'card_ui.font_light.200',
		dark: 'card_ui.font_dark.200',
	};

	const { colorMode } = useColorMode();

	return item.type === 'text' ? (
		<Box
			fontWeight="400"
			color={color[colorMode]}
			fontSize={`${1 * sizeMultiple}rem`}
			mr={2}
			fontFamily="body"
			whiteSpace="nowrap"
		>
			{item.value}
		</Box>
	) : (
		<Box
			height={`${1.4 * sizeMultiple}rem`}
			bg={bgColor[colorMode]}
			borderRadius="0.2rem"
			display="flex"
			justifyContent="center"
			alignItems="center"
			paddingX="0.4rem"
			marginTop="0.1rem"
			mr={2}
			color={color[colorMode]}
			_focus={{ outline: 'none' }}
			whiteSpace="nowrap"
		>
			<Box
				color={color[colorMode]}
				whiteSpace="nowrap"
				bg={'transparent'}
				isdisabled={'true'}
				fontFamily="body"
				padding="0"
				border="none"
				borderRadius="none"
				textAlign={'center'}
				fontSize={`${0.8 * sizeMultiple}rem`}
				width={`${item.key.length * 0.55}rem`}
			>
				{item.key}
			</Box>
		</Box>
	);
};

export const IntentDisplay = ({ intentArray, highlighted, sizeMultiple }) => {
	return intentArray.map((intent, i) => {
		return (
			<IntentInputItemDisplay
				key={i}
				item={intent}
				highlighted={highlighted}
				sizeMultiple={sizeMultiple || 1}
			/>
		);
	});
};
