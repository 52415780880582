import * as React from 'react';

import { Box, Flex, Img, FlexProps, Text } from '@chakra-ui/react';
import { StoreModule } from '../types/Modules';

export type ModuleCardProps = {
	module: StoreModule;
	containerProps?: FlexProps;
};

export const ModuleCard = ({ module, containerProps }: ModuleCardProps) => {
	return (
		<Flex
			borderRadius="2px"
			bg="#fefefe"
			border="1.5px solid #ddd"
			height="110px"
			alignItems="center"
			pt="0px"
			pr="16px"
			pb="0px"
			cursor="pointer"
			{...containerProps}
		>
			<Flex alignItems="center" justifyContent="center" width="15%" mx="4">
				<Img src={module.thumbnail} width="45px" />
			</Flex>
			<Flex direction="column" width="85%">
				<Box textStyle={'sans.sm'} color="#585858" userSelect="none">
					{module.name}
				</Box>
				<Box mt="4px" textStyle="sans.xs" color="#888888" userSelect="none">
					<Text noOfLines={2} marginBottom="0 !important">
						{module.description}
					</Text>
				</Box>
			</Flex>
		</Flex>
	);
};
