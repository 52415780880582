import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
const breakpoints = createBreakpoints({
	xs: '320px',
	sm: '580px',
	md: '900px',
	lg: '1300px',
	xl: '1800px',
	xxl: '2500px',
});

const customTheme = {
	breakpoints: breakpoints,
	components: {
		Switch: {
			baseStyle: {
				track: {
					_focus: {
						boxShadow: 'none',
					},
				},
			},
		},
	},
	colors: {
		// Light Theme Colors
		// reference : https://www.figma.com/file/KAF1ostHf3VBeZsctGl3ZA/MayaDesign?node-id=2436%3A6284
		light: {
			theme: {
				gray: {
					50: '#FDFDFD',
					100: '#F9F9F9',
					200: '#F3F3F3',
					300: '#E7E7E7',
					400: '#7D7D7D',
					500: '#4B4B4B',
					600: '#282828',
				},
				yellow: {
					200: '#F7F6F3',
				},
				purple: {
					500: '#39223a',
					400: '#3E1E3F',
					300: '#4A304B',
					200: '#5A445B',
					100: '#a99da9',
				},
				white: '#FFFFFF',
				black: '#000000',
			},
			font: {
				gray: {
					200: '#787575',
					300: '#656565',
					400: '#4B4B4B',
				},
			},
			// Border Colors
			border: {
				gray: {
					100: '#E7E7E7',
					200: '#D8D8D8',
					300: '#BBBBBB',
					400: '#656565',
				},
			},
		},
		button: {
			primary: {
				900: '#39223a',
				700: '#3E1E3F',
				600: '#4A304B',
				500: '#5A445B',
				200: '#a99da9',
				50: '#efecef'
			},
			secondary: {
				500: '#F7F6F3',
				600: '#f0efe9',
				700: 'red',
				800: 'green',
				400: 'pink',
				300: 'teal',
				200: 'tomato',
				100: '#ccc',
				50: '#f8f7f4'
				// 700: '#282828',
				// 900: 'green'
			},
			dashboardTableAction: {
				500: '#fff',
				600: '#fafafa',
			},
			danger: {
				50: '#FFF5F5',
				500: '#9B2C2C',
				600: '#822727',
				900: '#63171B'
			}
		},
		// Font Colors

		card_ui: {
			bg_light: '#ffffff',
			focus_light: '#F9F9F9',
			font_light: { 100: '#6C6C6C', 200: '#6C6C6C' },
			placeholder_light: '#E0EEF3',
			divider_light: '#F2F2F2',
			bg_dark: '#343434',
			focus_dark: '#434343',
			font_dark: { 100: '#E8E8E8', 200: '#FFFEFE' },
			divider_dark: '#3E3E3E',
			placeholder_dark: '#5A5959',
			purple: '#9B51E0',
		},
	},
	textStyles: {
		serif: {
			xs: {
				fontSize: '18px',
				fontFamily: 'Lora, serif',
			},
			sm: {
				fontSize: '20px',
				fontFamily: 'Lora, serif',
			},
			md: {
				fontSize: '22px',
				fontFamily: 'Lora, serif',
			},
			lg: {
				fontSize: '30px',
				fontFamily: 'Lora, serif',
			},
			xxl: {
				fontSize: '40px',
				fontFamily: 'Lora, serif',
			},
		},
		sans: {
			xs: {
				fontSize: '14.5px',
				fontFamily: 'Ubuntu, sans-serif',
				fontWeight: 500,
			},
			sm: {
				fontSize: '17px',
				fontFamily: 'Ubuntu, sans-serif',
				fontWeight: 500,
			},
			md: {
				fontSize: '23px',
				fontFamily: 'Ubuntu, sans-serif',
				fontWeight: 500,
			},
			lg: {
				fontSize: '35px',
				fontFamily: 'Ubuntu, sans-serif',
				fontWeight: 500,
			},
			xl: {
				fontSize: '45px',
				fontFamily: 'Ubuntu, sans-serif',
				fontWeight: 500,
			},
			xxl: {
				fontSize: '50px',
				fontFamily: 'Ubuntu, sans-serif',
				fontWeight: 500,
			},
		},
	},
	fonts: {
		heading: 'Lora, serif',
		body: 'Ubuntu, sans-serif',
	},

	config: {
		useSystemColorMode: false,
		initialColorMode: 'light',
	},
	styles: {
		global: {
			html: {
				backgroundColor: 'transparent',
				fontFamily: 'Ubuntu',
				height: '100%',
			},
			body: {
				backgroundColor: 'transparent',
				fontFamily: 'body',
				fontWeight: 400,
				display: 'flex',
				flexDirection: 'column',
				height: '100vh',
				overflow: 'hidden',
			},
			img: {
				pointerEvents: 'none',
			},
		},
	},
};

export default extendTheme(customTheme);
