import React, { useCallback, useState, useRef, useMemo } from 'react';
import _ from 'lodash';
import Grid from './Grid';
import {
	Box,
	createStandaloneToast,
	Flex,
	Icon,
	IconButton,
	useDisclosure,
	Text,
	Button,
	useQuery,
} from '@chakra-ui/react';
import { useStore } from '../zustand';
import DashboardSplitView from './DashboardSplitView';
import { AiOutlineLayout } from 'react-icons/ai';
import { config } from '../../../config';
import SynthesisSidebar from '../Tools/pac-engine/SynthesisSidebar';
import { useEffect } from 'react';
// import useAnalytics from '../../../util/Analytics/useAnalytics';
import analytics from '../../../util/Analytics';
import { toggleLeftNodePalette, toggleRightSideBar } from './functions/layout';
import useInstallMissingModules from '../hooks/useInstallMissingModules';
import InterstitialModuleInstallDrawer from './InterstitialModuleInstallDrawer';
import theme from '../../../library/theme';
import { useSelector } from 'react-redux';
import ModuleSidebar from './ModuleSidebar';
import IssuesSidebar from './IssuesSidebar';
import InstructBar from '../Tools/pac-engine/InstructBar';
import DashboardUI from '../../DashboardUI';
import { FiExternalLink } from 'react-icons/fi';
import DashboardPanelView from './DashboardPanelView';
import shallow from 'zustand/shallow';
import useSessionId from '../hooks/useSessionId';
import useQueryParams from '../../../util/useQueryParams';
import { useLocation, useHistory } from 'react-router-dom';
// import { toggleLeftNodePalette, toggleRightSideBar } from './functions/layout';

const toast = createStandaloneToast({ theme: theme });
const SYNTHESIS_SIDEBAR_TOGGLE_KEY = 'KeyG';
const COMMAND_BAR_TOGGLE_KEY = 'KeyK';
const PALLETE_TOGGLE_KEY = 'KeyP';

/**
 * # Workspace Viewport
 *
 * - Main working area of the workspace editor.
 *
 * @param {{
 * 	brainId: string,
 * 	token: any,
 * 	viewportGridRef: any
 * }} param0
 * @returns
 */
const Viewport = ({ brainId, token, viewportGridRef }) => {

	const dashboardUIUrl = useMemo(
		() => `/workers/ui?id=${brainId}&version=1`,
		[brainId]
	);
	const tabIds = useStore(
		useCallback((state) => state.tabs.allIds, []),
		shallow
	);
	const currentFlowState = useStore(
		useCallback((state) => {
			const tabsById = state.tabs.byId;
			const currentTabId = state.tabs.activeTab;
			return tabsById[currentTabId].reactFlowByTab;
		}, []),
		shallow
	);
	const activeTab = useStore(useCallback((state) => state.tabs.activeTab, []));
	const onDeploy = useStore(
		useCallback((state) => state.onDeploy, []),
		shallow
	);
	const debugPanelOpen = useStore(
		useCallback((state) => state.debugPanelOpen, [])
	);
	const nodePaletteIsOpen = useStore(
		useCallback((state) => state.nodePaletteIsOpen, [])
	);
	const infoPanelOpen = useStore(
		useCallback((state) => state.infoPanelOpen, [])
	);

	const query = useQueryParams();

	const toggleDashboard = useStore((state) => state.toggleDashboard, shallow);
	const toggleSynthSidebar = useStore(
		(state) => state.toggleSynthSidebar,
		shallow
	);
	const toggleNodePalette = useStore(
		(state) => state.toggleNodePalette,
		shallow
	);
	const toggleIssuesSidebar = useStore(state => state.toggleIssuesSidebar, shallow)
	const synthesisSidebarVisible = useStore(
		(state) => state.synthesisSidebarVisible
	);
	const dashboardIsOpen = useStore((state) => state.dashboardIsOpen);
	const moduleSidebarIsOpen = useStore((state) => state.moduleSidebarIsOpen);
	const toggleDebugPanel = useStore(
		(state) => state.toggleDebugPanel,
		shallow
	);
	const issuesSidebarIsOpen = useStore((state) => state.issuesSidebarIsOpen);
	const toggleInstructBar = useStore(
		(state) => state.toggleInstructBar,
		shallow
	);

	const setAutoDeploying = useStore(
		useCallback((state) => state.setAutoDeploying, [])
	);
	const gridRef = useRef();

	const track = analytics.track;
	const { installMissingModules, modulesInstalling, modulesToInstall } =
		useInstallMissingModules();

	const isModuleInstallationDrawerVisible = useStore(
		(state) => state.isModuleInstallationDrawerVisible
	);

	const setIsModuleInstallationDrawerVisible = useStore(
		(state) => state.setIsModuleInstallationDrawerVisible
	);
	const instructBarIsOpen = useStore((state) => state.instructBarIsOpen);

	const sessionId = useSessionId(brainId);

	useEffect(() => {
		/**
		 *
		 * @param {KeyboardEvent} e
		 */
		const shortcutHandler = (e) => {
			if (e.code === SYNTHESIS_SIDEBAR_TOGGLE_KEY) {
				if (e.metaKey || e.ctrlKey) {
					track('[Editor] Synth Panel Shortcut', {
						visible: synthesisSidebarVisible,
					});
					e.stopPropagation();
					e.preventDefault();
					toggleSynthSidebar();
				}
			} else if (e.code === COMMAND_BAR_TOGGLE_KEY) {
				if (e.metaKey || e.ctrlKey) {
					track(
						`[Editor] Cmd + K > ${
							!useStore.getState().instructBarIsOpen ? 'Open' : 'Close'
						}`
					);
					e.stopPropagation();
					e.preventDefault();
					toggleInstructBar();
				}
			} else if (e.code === PALLETE_TOGGLE_KEY) {
				if (e.metaKey && e.code) {
					e.stopPropagation();
					e.preventDefault();
					toggleNodePalette();
				}
			} else if (e.ctrlKey && e.code === 'Space') {
				e.stopPropagation();
				e.preventDefault();
				toggleDebugPanel();
				// setRightSideBarIsOpen((old) => !old);
			} else if (e.ctrlKey && e.code === 'KeyD') {
				e.stopPropagation();
				e.preventDefault();
				toggleDashboard();
			}
		};

		document.addEventListener('keydown', shortcutHandler);
		return () => {
			document.removeEventListener('keydown', shortcutHandler);
		};
	}, [track]);

	/**
	 * Close node palette and right-sidebar when editor loads.
	 * This happens fast enough for the user to not notice that they were ever open.
	 * Easy hack till we find a better solution to do this.
	 *
	 * Also registering the shift+enter deploy listener here. Not adding it to a higher level
	 * parent because the useInstallMissingModules hook causes the component to re-render,
	 * which then causes the editor to blank out. A rerender to this component does not cause
	 * that problem
	 */
	useEffect(() => {
		if (!debugPanelOpen && !infoPanelOpen) toggleRightSideBar(false);
		if (!nodePaletteIsOpen) toggleLeftNodePalette(false);

		const handleKeydown = async (e) => {
			if (e.shiftKey && e.key === 'Enter') {
				e.preventDefault();
				await installMissingModules();
				await onDeploy(sessionId);
				toast({
					title: 'Deployed',
					duration: 2000,
					isClosable: true,
					position: 'top',
					status: 'info',
				});
			}
		};

		window.addEventListener('keydown', handleKeydown);

		return () => {
			window.removeEventListener('keydown', handleKeydown);
		};
	}, []);

	useEffect(() => {
		// console.log('Bee::useEffect fired', gridRef);
		if (gridRef.current) {
			// console.log('Bee::Event fired');
			const viewportLoadedEvent = new CustomEvent('maya::viewportLoaded');
			document.dispatchEvent(viewportLoadedEvent);
		}
	}, [gridRef.current]);

	const allowAutoDeploy = useSelector(
		/**
		 *
		 * @param {{ brains: import('../../../redux/reducers/types/brains').Brains }} state
		 * @returns
		 */
		(state) => state.brains.byId[brainId].editorAutoDeploy
	);

	const debounceDeploy = useCallback(
		_.debounce(() => {
			try {
				// Taking no chances here, this is a best-effort thing
				if (!allowAutoDeploy) {
					return;
				}

				if (
					currentFlowState.nodes.some((node) => node.type === 'transient')
				) {
					console.log('there are transient nodes here, not deploying');
					return;
				}

				setAutoDeploying(true);
				onDeploy(sessionId)
					.then(() => {
						setTimeout(() => {
							setAutoDeploying(false);
						}, 500);
					})
					.catch((e) => {
						setAutoDeploying(false);
						console.error('Error autodeploying:', e);
					});
			} catch (e) {}
		}, 500),
		[allowAutoDeploy, currentFlowState]
	);

	const edgeHash = currentFlowState.edges
		.map((edge) => edge.id)
		.sort()
		.join('');
	const nodeHash = currentFlowState.nodes
		.map((node) => node.id)
		.sort()
		.join('');

	useEffect(() => {
		debounceDeploy();
	}, [edgeHash, nodeHash]);

	useEffect(() => {
		const showIssues = query.get('showIssues')
		if (showIssues) {
			toggleIssuesSidebar()
		} 
	}, [])

	return (
		<>
			{instructBarIsOpen ? <InstructBar /> : null}
			<Flex direction="column" h="full" bg="light.theme.yellow.200">
				{tabIds.length > 0
					? tabIds.map((tabId) => (
							<Box
								key={tabId}
								flex="1"
								display={activeTab === tabId ? 'flex' : 'none'}
								ref={gridRef}
							>
								{activeTab === tabId ? ( // Mount only the active tab. If other tabs are mounted, causes weird events/data fired from react flow
									<>
										<Flex
											display={
												moduleSidebarIsOpen ? undefined : 'none'
											}
											height="100%"
										>
											<ModuleSidebar />
										</Flex>
										<Flex
											display={
												synthesisSidebarVisible ? undefined : 'none'
											}
											height="100%"
										>
											<SynthesisSidebar sessionId={sessionId} />
										</Flex>
										<Grid
											sessionId={sessionId}
											tabId={tabId}
											debounceDeploy={debounceDeploy}
										/>
										<Flex
											display={
												issuesSidebarIsOpen ? undefined : 'none'
											}
											height="100%"
										>
											<IssuesSidebar />
										</Flex>
										{dashboardIsOpen && (
											<DashboardPanelView
												workspaceId={brainId}
												dashboardUIUrl={dashboardUIUrl}
											/>
										)}
									</>
								) : null}
							</Box>
					  ))
					: null}
				{modulesInstalling && (
					<InterstitialModuleInstallDrawer
						installState={modulesToInstall}
						isOpen={isModuleInstallationDrawerVisible}
						onClose={() => setIsModuleInstallationDrawerVisible(false)}
					/>
				)}
				{config.editor.enableDashboardSplitView ? (
					<>
						{/* <IconButton
							aria-label="View Dashboard"
							icon={<Icon as={AiOutlineLayout} boxSize="5" />}
							position="absolute"
							bottom="5"
							right={issuesSidebarIsOpen ? '354' : '4'}
							_focus={{ boxShadow: 'none' }}
							bg="#efefef"
							borderBottomRadius="0"
							borderTopRadius="2"
							size="md"
							h="39px"
							zIndex="4"
							onClick={toggleDashboard}
						/> */}
						{/* {dashboardIsOpen ? (
							<DashboardSplitView brainId={brainId} />
						) : null} */}
					</>
				) : null}
			</Flex>
		</>
	);
};

export default Viewport;
