import React, { useCallback } from 'react';
import { ModuleCard, StoreSection } from '@mayahq/ui';
import { useHistory, useLocation, Link } from 'react-router-dom';

// @chakra-ui
import { Flex } from '@chakra-ui/react';

// Store functions
import getStoreModules from '../../../../functions/store/getStoreModules';
import getStoreCategories from '../../../../functions/store/getStoreCategories';
import getStoreApps from '../../../../functions/store/getStoreApps';

export const itemTypes = {
	MODULE: 'modules',
	COLLECTION: 'collections',
	COMMAND: 'commands',
};

export const StoreSectionNew = () => {
	const history = useHistory();
	const location = useLocation();

	const updateQueryParam = useCallback(
		vals => {
			const search = new URLSearchParams(location.search);
			Object.keys(vals).forEach(key => {
				search.set(key, vals[key]);
			});
			history.replace({
				pathname: location.pathname,
				search: search.toString(),
			});
		},
		[history, location]
	);

	const getQueryParam = useCallback(
		key => {
			const search = new URLSearchParams(location.search);
			return search.get(key);
		},
		[location]
	);

	return (
		<Flex h="full" w="full" bg="light.theme.yellow.200" paddingTop="2.5rem" paddingX="6rem">
			<StoreSection
				onQueryParamGet={getQueryParam}
				onQueryParamUpdate={updateQueryParam}
				onStoreAppsGet={getStoreApps}
				onStoreCategoriesGet={getStoreCategories}
				onStoreModulesGet={getStoreModules}
				onModuleCardRender={({ module }) => (
					<Link key={module._id} to={`/ui-test/module/${module._id}`}>
						<ModuleCard module={module} />
					</Link>
				)}
				containerProps={{
					w: 'full',
				}}
			/>
		</Flex>
	);
};
