// import WebSocket from 'ws';
import EventEmitter from 'events';
import { v4 } from 'uuid';
import { config } from '../../../config';
import { store } from '../../../redux/store';
import GetProfileSlug from '../../../util/ProfileSlug';

const COMMS_URL = config.pacEngine.baseWSURl;

class PacTask extends EventEmitter {
	constructor({ type, opts, eventName, connId }) {
		super();
		this.type = type;
		this.opts = opts;
		this.eventName = eventName;
		this.closeEventName = `close::${v4()}`;
		this.connId = connId;
	}

	_connectToSocket() {
		const connectionId = this.connId || v4();
		const state = store.getState();
		const { accessToken } = state.user.tokens;
		const profileSlug = GetProfileSlug().slug;
		const socket = new WebSocket(
			`${COMMS_URL}?connId=${connectionId}&accessToken=${accessToken}&slug=${profileSlug}`
		);

		socket.addEventListener('error', (err) => {
			console.error(err);
			this.emit(this.eventName, 'error');
		});

		this.on(this.closeEventName, () => {
			console.log('Disconnnecting socket');
			socket.close();
		});

		return socket;
	}

	execute() {
		const socket = this._connectToSocket();
		const taskId = v4();

		console.log('TaskID:', taskId);
		socket.addEventListener('open', () => {
			const message = {
				task_id: taskId,
				type: this.type,
				data: this.opts,
			};

			socket.send(JSON.stringify(message));
		});

		socket.addEventListener('message', (message) => {
			const msgObject = JSON.parse(JSON.parse(message.data)); // What the fuck?
			console.log('nbi Received from websocket:', msgObject);
			if (msgObject.error === true) {
				this.emit(this.eventName, 'error');
			} else if (msgObject.task_id === taskId) {
				let data = msgObject.data;
				if (typeof data === 'string') {
					data = JSON.parse(data);
				}
				this.emit(this.eventName, data);
			}
		});

		return taskId;
	}

	listenForResults({ taskId }) {
		const socket = this._connectToSocket();
		socket.addEventListener('open', () => {
			console.log('nbi Connected to comms. Listening for results');
		});

		socket.addEventListener('message', (message) => {
			const msgObject = JSON.parse(JSON.parse(message.data)); // lmao
			if (msgObject.error === true) {
				this.emit(this.eventName, 'error');
			} else if (msgObject.task_id === taskId) {
				let data = msgObject.data;
				if (typeof data === 'string') {
					data = JSON.parse(data);
				}
				this.emit(this.eventName, data);
			}
		});
	}

	done() {
		this.emit(this.closeEventName);
	}
}

export class GenerateTask extends PacTask {
	constructor({ sessionId, connId }) {
		super({
			type: 'GENERATE',
			eventName: 'stepGenerated',
			opts: {
				session_id: sessionId,
				repositories: [],
			},
			connId,
		});
	}
}

export class InstructTask extends PacTask {
	constructor({ sessionId, instruction, fromScratch }) {
		super({
			type: 'INSTRUCT',
			eventName: 'instructDone',
			opts: {
				session_id: sessionId,
				instruction,
				from_scratch: fromScratch,
				repositories: [],
			},
			connId: null,
		});
	}
}

export class InstructTaskNew extends PacTask {
	constructor({ sessionId, instruction, fromScratch }) {
		super({
			type: 'TALK',
			eventName: 'instructDoneNew',
			opts: {
				session_id: sessionId,
				instruction,
				from_scratch: fromScratch,
				repositories: [],
			},
			connId: null,
		});
	}
}
