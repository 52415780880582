/**
 * Selects all the input text in content editable
 *
 * - ref: https://stackoverflow.com/a/3806004
 *
 * @param {*} inputRef
 */
export const contentEditableInputTextSelectAll = (inputRef) => {
	let sel, range;
	if (window.getSelection && document.createRange) {
		range = document.createRange();
		range.selectNodeContents(inputRef);
		sel = window.getSelection();
		sel.removeAllRanges();
		sel.addRange(range);
	} else if (document.body.createTextRange) {
		range = document.body.createTextRange();
		range.moveToElementText(inputRef);
		range.select();
	}
};

/**
 * Sets the cursor to the end of content-editable.
 *
 * - ref: https://stackoverflow.com/a/3806004
 * - ref2: https://stackoverflow.com/a/6249440
 *
 * @param {*} inputRef
 */
export const contentEditableInputMoveCursorToEnd = (inputRef) => {
	let sel, range;
	if (window.getSelection && document.createRange) {
		range = document.createRange();
		range.selectNodeContents(inputRef);
		range.collapse(false);
		sel = window.getSelection();
		sel.removeAllRanges();
		sel.addRange(range);
	} else if (document.body.createTextRange) {
		range = document.body.createTextRange();
		range.moveToElementText(inputRef);
		range.select();
	}
};
