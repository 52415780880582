import isElectron from 'is-electron';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import actionTypes from '../actionTypes';

const actionForwardBlackList = [
	actionTypes.CUI_SET_TOP_INTENT_ARRAY,
	actionTypes.SET_CURRENT_PROFILE,
];

class ElectronWindowClient {
	constructor() {
		// @ts-ignore
		const electron = window.electron;
		const { ipcRenderer } = electron;
		this.ipc = ipcRenderer;
	}
	connect = (store, action) => {
		const { dispatch } = store;

		if (isElectron()) {
			console.log('connecting to ipc...');

			this.ipc.removeAllListeners('/api/redux/syncRenderer');
			this.ipc.handle('/api/redux/syncRenderer', (event, arg) => {
				let randomId = uuidv4();
				console.log('syncing renderer:', {
					...event.payload,
					dontForward: true,
					_id: randomId,
				});
				dispatch({ ...event.payload, dontForward: true, _id: randomId });
			});
		}
	};
	forward = (action) => {
		if (this.ipc && !_.includes(actionForwardBlackList, action.type)) {
			console.log('Type of : ', typeof action, action);

			console.log('syncing main:', action);

			this.ipc.invoke('/api/redux/syncMain', JSON.stringify(action));
		}
	};
	handleDispatch = (dispatch, action) => {};
}

//createMiddleware
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
	const electronWindowClient = new ElectronWindowClient();

	const handlers = {
		'IPC::CONNECT': electronWindowClient.connect,
	};

	//
	return (store) => (next) => (action) => {
		// console.log("dispatching", action);
		const { dispatch } = store;
		// ipc.invoke("/api/redux/syncMain", action);
		if (action.type.includes('IPC::')) {
			const handler = Reflect.get(handlers, action.type);

			if (handler) {
				try {
					handler(store, action);
				} catch (err) {
					dispatch({ type: 'IPC::ERROR', payload: action });
				}
			}
		} else if (!action.dontForward) {
			electronWindowClient.forward(action);
		}
		let result = next(action);
		// console.log("next state", store.getState());
		return result;
	};
};
