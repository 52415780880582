import React, { useCallback } from 'react'
import {
    Flex, Box,
    Button as ChakraButton,
    GridItem,
    Tooltip,
    Text
} from '@chakra-ui/react'
import _ from 'lodash'
import { useWorkspaceDashboardStore } from '../zustand'

const buttonColorOpts = [
    'primary',
    'secondary',
    'danger'
]

const ButtonComp = ({
    componentId,
    width,
    color,
    label,
    tooltip,
    variant,
    onClick,
    debounce
}) => {
    const debouncedOnClick = _.debounce(useCallback((e) => {
        onClick(e)
    }, [onClick]), debounce)
    const loading = useWorkspaceDashboardStore((state) => state.componentMeta.buttons[componentId]?.loading || false)

    // Default button color to blue if color value is invalid
    if (!buttonColorOpts.includes(color)) {
        color = 'primary'
    }

    return (
        <GridItem
            colSpan={parseInt(width)}
        >
            <Tooltip
                label={tooltip || label}
                openDelay={500}
                hasArrow
                placement='top'
            >
                <ChakraButton
                    width='100%'
                    bg={color ? undefined : 'light.theme.purple.300'}
                    colorScheme={`button.${color}`}
                    color={color === 'secondary' ? '#666' : undefined}
                    variant={variant}
                    borderRadius='3px'
                    onClick={debouncedOnClick}
                    textStyle='sans.sm'
                    fontWeight='500 !important'
                    fontSize='16px !important'
                    isLoading={loading}
                    textAlign='left'
                    border={color === 'secondary' && variant !== 'ghost' ? '1px solid' : undefined}
                    borderColor={color === 'secondary' && variant !== 'ghost' ? 'light.border.gray.100' : undefined }
                    // py='8px'
                >
                    <p
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            direction: 'ltr',
                            marginTop: 'auto',
                            marginBottom: 'auto'
                        }}
                    >
                        {label}
                    </p>
                </ChakraButton>
            </Tooltip>
        </GridItem>
    )
}

export const Button = React.memo(ButtonComp)