// Dependency imports

// import worker from 'workerize-loader!../../../src/maya-service-worker'; // eslint-disable-line import/no-webpack-loader-syntax

import APIFunction from '../../util/APIFetch/function';

import APIRoutes from '../../util/APIRoutes';

// config
import { config } from '../../config';

/**
 * 
 * @param {String} teamSlug profileSlug of team for which to fetch all invites
 * @returns 
 */
const getAllTeamInvites = async (teamSlug) => {
	// Create an instance of your worker
	var getAllTeamInvitesFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.getAllTeamInvites.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.getAllTeamInvites.PATH_METHOD,
        PATH_QUERY: teamSlug
	});

	var getAllTeamInvitesResponse = await getAllTeamInvitesFn();
	// console.log('chl profresult', getUserProfilesResponse)
	return getAllTeamInvitesResponse?.data?.results?.invites;
};

export default getAllTeamInvites;
