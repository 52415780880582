import { Button } from '@chakra-ui/react';
import React from 'react';

/**
 * # Button to Manage Billing
 *
 * @param {{
 *  children: React.ReactNode,
 *  onClick?: import('@chakra-ui/react').ButtonProps['onClick'],
 *  buttonProps?: import('@chakra-ui/react').ButtonProps
 * }} param0
 * @returns
 */
const ManageButton = ({ onClick, children, buttonProps }) => {
	return (
		<Button
			textStyle="sans.sm"
			border="2px solid"
			borderColor="light.border.gray.200"
			bg="light.theme.gray.200"
			borderRadius="2px"
			color="#5F5E5E"
			alignItems="center"
			justifyContent="center"
			_focus={{
				boxShadow: 'none',
			}}
			_hover={{
				bg: 'light.theme.gray.300',
			}}
			_active={{
				bg: 'light.theme.gray.200',
			}}
			{...buttonProps}
			onClick={onClick}
		>
			{children}
		</Button>
	);
};

export default ManageButton;
