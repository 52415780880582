import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';
import axios from 'axios';

// config
import { config } from '../../config';

import isElectron from 'is-electron';
//redux
import { store } from '../../redux/store';
import { updateBrainById } from '../../redux/actions';

/**
 * Function to Create a New Brain, given some parameters
 */
const sendStartSignal = async ({ brainId, device, slug }) => {
	var { user } = store.getState();
	var profileSlug = slug ? slug : user.info.id;
	const formData = {
		_id: brainId,
		device: {
			id: device.id,
			deviceName: device.deviceName,
		},
		profileSlug: profileSlug,
	};
	var startBrainAPIFn, startBrainIPCFn;
	/**
	 * @type {import('./types/startBrain').StartBrainResponse}
	 */
	let startBrainResponse;
	if (device.platform === 'cloud') {
		// API CALL
		startBrainAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.startBrain.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.startBrain.PATH_METHOD,
		});
		startBrainResponse = await startBrainAPIFn(formData);
		if (!startBrainResponse['data']['error']) {
			console.log('br here 3');

			let fetchStatus = async () => {
				try {
					// @ts-ignore
					let healthState = await axios({
						url:
							startBrainResponse['data']['results']['url'] +
							`/health?timestamp=${Date.now()}`,
						method: 'get',
						validateStatus: function (status) {
							return status;
						},
						timeout: 2000,
					});
					return healthState;
				} catch (err) {
					console.error('Runtime not available yet: [error]-', err);
					return err;
				}
			};
			let validate = (result) => {
				if (result) {
					return result;
				} else {
					return {
						status: 500,
					};
				}
			};
			let response = await poll(fetchStatus, validate, 3000);
			console.log('br here 6');

			if (response.status === 200) {
				console.log('br here 7');
				return startBrainResponse['data']['results'];
			}
		} else {
			console.log('br here 8');
			return startBrainResponse['data'];
		}
	} else if (isElectron()) {
		// IPC CALL
		startBrainIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.startBrain.EVENT,
		});
		startBrainResponse = await startBrainIPCFn(formData);
		console.log('Start brain response from IPC Fetch');
		if (!startBrainResponse['data']['error']) {
			return startBrainResponse['data']['results'];
		} else {
			return startBrainResponse['data'];
		}
	} else
		console.error(
			`local device cannot be accessed on the browser! Please use desktop app`
		);
};

/**
 *
 * @param {import('./types/startBrain').StartBrainArgs} args
 */
const startBrain = ({ brain, slug, onDone, onError, onLoading }) => {
	const bId = brain._id;
	let device = {
		id: brain.deviceId,
		deviceName: brain.deviceName,
		platform: brain?.device?.platform,
		release: brain?.device?.release,
	};
	onDone = typeof onDone === 'function' ? onDone : () => {};
	onError = typeof onError === 'function' ? onError : () => {};
	onLoading = typeof onLoading === 'function' ? onLoading : () => {};

	if (brain.status === 'STARTED') {
		onDone();
	} else {
		onLoading();
		sendStartSignal({ brainId: bId, device: device, slug: slug }).then(
			// Resolves when brain has started
			(res) => {
				if (res['error']) {
					onError();
				} else {
					// if (isElectron()) {
					store.dispatch(
						updateBrainById(bId, {
							...res,
							status: 'STARTED',
						})
					);
					onDone();
					// }
				}
			}
		);
	}
};

// polling system
const poll = async function (fn, fnCondition, ms) {
	let result = await fn();
	console.log('Poll result:', result);
	while (fnCondition(result).status !== 200) {
		await wait(ms);
		result = await fn();
	}
	return result;
};

const wait = function (ms = 1000) {
	return new Promise((resolve) => {
		setTimeout(resolve, ms);
	});
};

export default startBrain;
