import React, { useCallback, useEffect, useState } from 'react';
import update from 'immutability-helper';
import installExternalModuleToBrain from '../../../functions/modules/installExternalModuleToBrain';
import { useStore } from '../zustand';
import useProfileSlug from '../../../hooks/useProfileSlug';
import { getModuleList } from '../Workspace/functions/NodeRed';
import InterstitialModuleInstallDrawer from '../Workspace/InterstitialModuleInstallDrawer';
import getInstallerFlow from '../../../functions/store/getInstallerFlow';
import installModuleToBrain from '../../../functions/modules/installModuleToBrain';
import setReferenceIdToConfigMap from '../../../functions/brain/setReferenceIdToConfigMap';
import getRedInstance from '../Workspace/functions/getRedInstance';
import RED_EVENTS from '../Workspace/functions/redEventListeners/events';
import {
	offNodeRedNodeTypeAdded,
	onNodeRedNodeTypeAdded,
} from '../Workspace/functions/redEventListeners';
import { resolve } from 'path';
import semver from 'semver';
import notionModule from './notionModule.json';
import getBrainById from '../../../functions/brain/getBrainById';
import shallow from 'zustand/shallow';

// Modules
export const testState = {
	'node-red-node-mysql': {
		moduleName: 'node-red-node-mysql',
		status: 'QUEUED',
		privateModule: false,
		requiresConfig: false,
		version: '1.7.1',
	},
	'maya-red-notion': {
		moduleName: 'Notion Module',
		status: 'INSTALLING',
		// configProfileReferenceId: '',
		// isInternalModule: true,
		privateModule: notionModule,
		version: '1.0.4',
		// requiresConfig: true
	},
	// 'maya-red-spotify': {
	// 	moduleName: 'Spotify Module',
	// 	status: 'INSTALLED'
	// },
	// 'maya-red-gcalendar': {
	// 	moduleName: 'Google Calendar',
	// 	status: 'QUEUED'
	// }
};

const useInstallModules = () => {
	const [modulesToInstall, setModulesToInstall] = useState({});
	const [modulesInstalling, setModulesInstalling] = useState(false);

	const workspaceUrl = useStore((state) => state.runtime);
	const workspaceId = useStore((state) => state.brainId);

	const setInstalledModules = useStore(
		(state) => state.setInstalledModules,
		shallow
	);
	const setMissingModules = useStore(
		(state) => state.setMissingModules,
		shallow
	);
	const setAreModuleInstalling = useStore(
		(state) => state.setAreModuleInstalling,
		shallow
	);

	const setIsModuleInstallationDrawerVisible = useStore(
		(state) => state.setIsModuleInstallationDrawerVisible,
		shallow
	);
	const setSelectedMissingModule = useStore(
		(state) => state.setSelectedMissingModule
	);

	const slug = useProfileSlug();

	const installModules = async (modules) => {
		if (Object.keys(modules).length > 0) {
			setModulesInstalling(true);
			setAreModuleInstalling(true);
			setIsModuleInstallationDrawerVisible(true);
			setModulesToInstall(modules);
			let profileMap = {};
			const configsRequired = Object.values(modules).some(
				(module) => module.isInternalModule && module.requiresConfig
			);
			if (configsRequired) {
				profileMap = await new Promise((res) => {
					document.addEventListener(
						'Maya::ConfigProfilesSelected',
						(e) => {
							// @ts-ignore
							res(e?.detail?.profileMap);
						},
						{ once: true } // Remove this event listener after running
					);
				});

				Object.keys(profileMap).forEach((modulePackageName) => {
					modules[modulePackageName].configProfileReferenceId =
						profileMap[modulePackageName];
				});
			}

			// console.log('bool event mods', modules);
			// return console.log('boo event final', profileMap)

			const moduleList = Object.keys(modules);

			// This is where the actual module installation starts
			for (let i = 0; i < moduleList.length; i++) {
				const moduleToInstall = moduleList[i];
				setModulesToInstall((prevMods) => {
					return update(prevMods, {
						[moduleToInstall]: {
							status: { $set: 'INSTALLING' },
						},
					});
				});

				const module = modules[moduleToInstall];
				if (module.privateModule) {
					// Set referenceId if the module has one set
					if (module.requiresConfig && module.configProfileReferenceId) {
						await setReferenceIdToConfigMap({
							referenceId: module.configProfileReferenceId,
							runtimeId: workspaceId,
							modulePackageName: module.privateModule.packageName,
						});
					}
					await installModuleToBrain(
						{
							brainId: workspaceId,
							moduleId: module.privateModule.id,
							moduleToInstall: module.privateModule,
							packageName: module.privateModule.packageName,
							profileSlug: slug,
							version: module.privateModule.currentVersion,
							// @ts-ignore
							referenceId: module.configProfileReferenceId,
						},
						module.privateModule
					);
				} else {
					await installExternalModuleToBrain({
						profileSlug: slug,
						modulePackageName: moduleToInstall,
						workspaceUrl,
						version: module.version,
						workspaceId: workspaceId,
					});
				}

				// @ts-ignore
				setModulesToInstall((prevMods) => {
					return update(prevMods, {
						[moduleToInstall]: {
							status: { $set: 'INSTALLED' },
						},
					});
				});
			}
			setModulesToInstall({});
			setModulesInstalling(false);
			setAreModuleInstalling(false);
			setIsModuleInstallationDrawerVisible(false);
			const brain = await getBrainById({
				brainId: workspaceId,
				slug: slug,
			});
			setInstalledModules(brain.modules);
			setMissingModules();
			setSelectedMissingModule({});
		}
	};

	return {
		modulesToInstall,
		modulesInstalling,
		installModules,
	};
};

export default useInstallModules;
