import { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import useSchedulers from './useSchedulers';

const useTasks = (workspaceId = null) => {
	const [tasks, setTasks] = useState([]);
	const allWorkspaces = useSelector(
		/**
		 * @param {{
		 *  brains: import('../../../../../../../redux/reducers/types/brains').Brains
		 * }} state
		 */
		(state) => state.brains
	);
	const { schedulerConfigs } = useSchedulers();
	useEffect(() => {
		const schIds = schedulerConfigs.ids.filter((sid) => {
			const { brainId } = schedulerConfigs.byId[sid];
			return brainId === workspaceId;
		});

		if (schIds.length === 0) {
			return setTasks([]);
		}

		const apiRequests = schIds.map((sid) => {
			return new Promise((resolve) => {
				const { brainId, endpointFullUrl, brainBaseURL } =
					schedulerConfigs.byId[sid];
				return axios
					.get(endpointFullUrl)
					.then((response) => {
						if (!Array.isArray(response.data)) {
							throw new Error(`response.data is not an Array!`);
						}
						const tasks = response.data.map((data) => ({
							...data,
							brainId,
							brainBaseURL,
							endpointFullUrl,
						}));
						resolve(tasks);
					})
					.catch((e) => {
						console.error(
							`Unable to get tasks: ${brainId} : ${endpointFullUrl} :`,
							e
						);
						resolve([]);
					});
			});
		});

		Promise.all(apiRequests)
			.then((response) => {
				const tasks = response.flatMap((r) => r);
				setTasks(tasks);
			})
			.catch((e) => {
				console.error('task Error getting tasks:', e);
			});
	}, [allWorkspaces.allIds, allWorkspaces.byId]);

	return tasks;
};

export default useTasks;
