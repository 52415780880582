import React, { useState, useCallback } from 'react';
import update from 'immutability-helper';
import {
	Flex,
	Box,
	Accordion,
	Tooltip,
} from '@chakra-ui/react';
import useStepperStore from '../../../StepperStore';
import ModuleConfig from '../../../../Dashboard/Sections/Store/ModuleConfig/ModuleConfig';
import MayaFilledButton from '../../../../../library/buttons/FilledButton';
import useStepperAnalytics from '../../../hooks/useStepperAnalytics';
import { stepperFlowTypes } from '../../registry';
import setReferenceIdToConfigMap from '../../../../../functions/brain/setReferenceIdToConfigMap';
import useQueryParams from '../../../../../util/useQueryParams';
import getBrainById from '../../../../../functions/brain/getBrainById';
import { HistoryWithSlug } from '../../../../../util/History';
import shallow from 'zustand/shallow';

const SetupConfigProfiles = () => {
	useStepperAnalytics();
	const query = useQueryParams()

	const [configProfileMap, setConfigProfileMap] = useState({})
	const [buttonLoading, setButtonLoading] = useState(false)

	const { steps } =
		useStepperStore((state) => {
			return {
				steps: state.steps,
				updateStepState: state.updateStepState,
				addStep: state.addStep,
				nextStep: state.nextStep,
				updateStore: state.updateStore,
			};
		});

	const addStep = useStepperStore(state => state.addStep, shallow);
	const nextStep = useStepperStore(state => state.nextStep, shallow);
	const closeStepper = useStepperStore(state => state.closeStepper, shallow);
	const clearSteps = useStepperStore(state => state.clearSteps, shallow);

	const currentStep = steps.byId['module.configProfile'];

	const modules = currentStep.state.modules;
	const workerId = currentStep.state.workerId;

	function selectConfigProfileForModule(moduleId, configProfile) {
		const newConfigProfileMap = {
			...configProfileMap,
			[moduleId]: configProfile,
		};
		setConfigProfileMap(newConfigProfileMap);
	}

	const moduleConfigCards = Object.values(modules)
		.filter((module) => !!module.configurationType)
		.map((module) => {
			return (
				<Box mb="16px">
					<ModuleConfig
						selectedProfile={configProfileMap[module._id]}
						module={module}
						onSelectProfile={(profile) =>
							selectConfigProfileForModule(module._id, profile)
						}
					/>
				</Box>
			);
		});

	const goToNextStep = useCallback(async () => {
		// Add a step to install the collection and modules if this stepper
		// started from the import step. This is terrible.
		const currentStep = steps.byId['module.configProfile'];
		const currentState = currentStep.state;
		const { stepperFlowType } = currentState;
		const nextStepState = {
			...currentState,
			configProfiles: currentStep.configProfiles,
		};

		const progIssues = query.get('progIssues')

		switch (stepperFlowType) {
			case stepperFlowTypes.workspace.CONFIGURE:
				const moduleMap = {}
				modules.forEach(m => moduleMap[m._id] = m)
				setButtonLoading(true)
				await Promise.all(Object.values(configProfileMap).map(configProfile => setReferenceIdToConfigMap({
					runtimeId: workerId,
					modulePackageName: moduleMap[configProfile.moduleId].packageName,
					referenceId: configProfile.referenceId
				})))
				setButtonLoading(false)
				break
			default:
				return
		}

		if (!!progIssues) {
			HistoryWithSlug.push(`/edit?id=${workerId}&showIssues=1`);
			clearSteps()
			closeStepper()
		} else {
			addStep('module.goodToGo', {})
			nextStep()
		}
	}, [steps, configProfileMap, workerId]);

	const allowContinue = Object
		.values(modules)
		.every((mod) => !!configProfileMap[mod._id])

	return (
		<Flex direction="column" alignItems="center" height="100%">
			<Box paddingY="20px">
				<Flex
					textStyle="serif.lg"
					textAlign="center"
					justifyContent="center"
				>
					Configure missing integrations.
				</Flex>
				<Flex
					textStyle="sans.sm"
					justifyContent="center"
					textAlign="center"
					mt="5px"
					color="light.font.gray.400"
				>
					Authenticate Maya with these services to run your program. This only
					needs to be done once.
				</Flex>
			</Box>
			<Box
				position="relative"
				maxWidth="1000px"
				width="100%"
				mt="32px"
				overflow="auto"
				display="flex"
				flex="1 1 auto"
			>
				<Box
					display="flex"
					flexDirection="column"
					position="absolute"
					width="100%"
					height="100%"
				>
					<Accordion allowToggle={true}>{moduleConfigCards}</Accordion>
					<Flex mt="32px" pb="48px" justifyContent="center">
						<Tooltip
							label="Select profiles for all modules before continuing."
							bg="light.theme.gray.500"
							isDisabled={allowContinue}
							placement="right-start"
						>
							<Box>
								<MayaFilledButton
									text="Continue"
									isDisabled={!allowContinue}
									onClick={goToNextStep}
									isLoading={buttonLoading}
								/>
							</Box>
						</Tooltip>
					</Flex>
				</Box>
			</Box>
		</Flex>
	);
};

const SetupConfigProfilesObject = {
	heading: 'setup profiles',
	component: <SetupConfigProfiles />,
};

export default SetupConfigProfilesObject;
