import {
	Box,
	CircularProgress,
	createStandaloneToast,
	Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { BsDot, BsExclamationCircleFill } from 'react-icons/bs';
import theme from '../../../../../library/theme';

const toast = createStandaloneToast({ theme: theme });

const StepStateIndicator = ({
	step,
	stepId,
	stepIdBeingGenerated,
	stepIdBeingRun,
}) => {
	const errorMessage = `Error: ${step?.error}`;

	const handleErrorClick = async () => {
		try {
			await navigator.clipboard.writeText(errorMessage);
			toast({
				title: 'Copied error message to clipboard',
				duration: 2000,
				isClosable: true,
				position: 'top',
				status: 'info',
			});
		} catch (err) {
			console.error(err);
			toast({
				title: 'Copy Error!',
				description: 'Failed to copy the error. Please try again',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	return (
		<>
			{step?.error && stepId !== stepIdBeingGenerated ? (
				<Tooltip label={errorMessage} placement="bottom-end">
					<Box
						marginTop="6px"
						cursor="pointer"
						marginLeft="2px"
						onClick={handleErrorClick}
					>
						<BsExclamationCircleFill size={10} color="#9b2c2c" />
					</Box>
				</Tooltip>
			) : stepId === stepIdBeingGenerated ? (
				<CircularProgress
					isIndeterminate
					color="gray.500"
					size="12px"
					marginLeft="3px"
					marginBottom="2px"
				/>
			) : step.generated === false ? (
				<Box color="#636262" opacity="0.7">
					<BsDot size={20} />
				</Box>
			) : stepId === stepIdBeingRun ? (
				<Box color="#009948" opacity="0.7">
					<BsDot size={20} />
				</Box>
			) : null}
		</>
	);
};

export default React.memo(StepStateIndicator);
