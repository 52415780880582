import DescribeAutomation from './Onboarding/DescriptionSelection';
import AppSelection from './Onboarding/AppSelection';
import NewAutomation from './New/NewAutomation';
import NewWorkspace from './New/Workspace';
import SelectWorkspace from './Workspace/SelectWorkspace';
import Import from './Editor/Import';
import Export from './Editor/Export';
import SetupConfigProfiles from './Module/SetupConfigProfiles/SetupConfigProfiles';
import InstallCollection from './Collection/Install/InstallCollection';
import WelcomeSection from './Onboarding/Welcome';
import NewOrder from './New/Order/';
import QuickShareInstall from './Onboarding/QuickShareInstall';
import CreateTeam from './Onboarding/CreateTeam';
import SelectUsernameStep from './Onboarding/SelectUsername';
import EditTeamStepObject from './Settings/EditTeam/EditTeamStep';
import WorkspaceDashboardStep from './Editor/WorkspaceDashboard/WorkspaceDashboardStep';
import TeachSkill from './Teach/Skill';
import UploadSkillZipStep from './Teach/UploadZip'
import ModifySkillStep from './Teach/ModifySkill'
import NoAccessSection from './Onboarding/NoAccess'
import GoodToGoObject from './Module/GoodToGo/GoodToGo';

const registry = {
	onboarding: {
		describeAutomation: DescribeAutomation,
		appSelection: AppSelection,
		welcome: WelcomeSection,
		quickShareInstall: QuickShareInstall,
		createTeam: CreateTeam,
		selectUsername: SelectUsernameStep,
		noAccess: NoAccessSection
	},
	new: {
		automation: NewAutomation,
		workspace: NewWorkspace,
		order: NewOrder,
	},
	editor: {
		import: Import,
		export: Export,
	},
	workspace: {
		select: SelectWorkspace,
		new: NewWorkspace,
		dashboard: WorkspaceDashboardStep,
	},
	module: {
		configProfile: SetupConfigProfiles,
		goodToGo: GoodToGoObject
	},
	collection: {
		install: InstallCollection,
	},
	settings: {
		editTeam: EditTeamStepObject,
	},
	teach: {
		skill: TeachSkill,
		uploadSkillZip: UploadSkillZipStep,
		modifySkill: ModifySkillStep
	},
};

export const stepperFlowTypes = {
	external: {
		INSTALL_FROM_QUICK_SHARE: 'external.installFromQuickShare',
	},
	settings: {
		EDIT_TEAM: 'settings.editTeam',
	},
	onboarding: {
		NORMAL: 'onboarding.normal',
		INSTALL_FROM_QUICK_SHARE: 'onboarding.installFromQuickShare',
		NO_ACCESS: 'onboarding.noAccess'
	},
	store: {
		INSTALL_MODULE_FLOW: 'store.installModuleFlow',
	},
	home: {
		NEW_AUTOMATION: 'home.newAutomation',
		IMPORT_NEW_FLOW: 'home.importNewFlow',
	},
	workspace: {
		IMPORT_NEW_FLOW: 'workspace.importNewFlow',
		EXPORT_FLOW: 'workspace.exportFlow',
		INSTALL_MODULE: 'workspace.installModule',
		VIEW_DASHBOARD: 'workspace.viewDashboard',
		CONFIGURE: 'workspace.configure'
	},
	checklist: {
		CREATE_TEAM: 'checklist.createTeam',
		ADD_TEAM_MEMBERS: 'checklist.addTeamMembers',
		CREATE_WORKSPACE: 'checklist.createWorkspace',
	},
	misc: {
		SELECT_USERNAME: 'misc.selectUsername',
	},
	planWall: {
		CREATE_TEAM: 'planWall.createTeam',
	},
	teach: {
		TEACH_SKILL: 'teach.skill',
		MODIFY_SKILL: 'teach.modifySkill'
	},
};

export default registry;
