import { setCurrentProfile } from '../../redux/actions';
import { store } from '../../redux/store';
import History from '../../util/History';
import path from 'path';
import { setSlugInLocalStorage } from '../../util/ProfileSlug/localStorageSlug';
import { getSlugFromUrl } from '../../util/ProfileSlug/getSlugFromUrl';

export const unSwitchableRoutes = ['/store/skills', '/store/modules'];

/**
 *
 * @param {String} locationPath URL with or without slug
 */
export const getPathWithoutSlug = (locationPath) => {
	const containsSlug = locationPath.includes('/_/');
	if (containsSlug) {
		const slug = getSlugFromUrl(locationPath);
		const barePath = locationPath.replace(`/_/${slug}`, '');
		return path.join('/', barePath);
	}

	return locationPath;
};

export const routesWithSlug = [
	'/workers',
	'/settings',
	'/tasks',
	'/orders',
	'/edit',
	'/install',
];

const switchProfile = (profileSlug, location) => {
	const state = store.getState();
	const profile = state.profiles.profiles[profileSlug];
	if (!profile) {
		return; // lmao
	}

	updateProfileSlugInUrl(profile, location);
	store.dispatch(setCurrentProfile(profileSlug));
};

export const updateProfileSlugInUrl = (profile, location) => {
	const appendSlug = routesWithSlug.some((route) =>
		getPathWithoutSlug(location.pathname).startsWith(route)
	);

	if (appendSlug) {
		let newPath = getPathWithoutSlug(location.pathname + location.search);
		if (unSwitchableRoutes.indexOf(location.pathname) === -1) {
			if (profile.isTeam) {
				newPath = path.join('/_/', profile.profileSlug, newPath);
			}

			History.push(newPath);
		}
	}

	setSlugInLocalStorage(profile.profileSlug);
};

export default switchProfile;
