import { Box, createStandaloneToast } from '@chakra-ui/react';
import { debounce, findKey } from 'lodash';
import { customAlphabet } from 'nanoid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import changeSession from '../../../../../functions/pac-engine/changeSession';
import generate from '../../../../../functions/pac-engine/generate';
import parseRecipe from '../../../../../functions/pac-engine/parseRecipe';
import stepSearch from '../../../../../functions/pac-engine/stepSearch';
import theme from '../../../../../library/theme';
import { useStore } from '../../../zustand';
import { contentEditableInputMoveCursorToEnd } from '../../GenerateDAG/contentEditableUtils';
import {
	compareFlows,
	compareRecipes,
	diffExists,
	nonEmptyStepCount,
	sanitizePastedText,
} from '../utils';
import { usePacEngineStore } from '../zustand';
import styles from './Step.module.css';
import StepFeedbackCollection from './StepFeedbackCollection';
import StepStateIndicator from './StepStateIndicator';

const Step = ({
	sessionId,
	stepId,
	step,
	stepRefs,
	mayaFlow,
	allowInteraction = true,
}) => {
	const selectedProfile = useSelector((state) => {
		return state.profiles.profiles[state.profiles.selected];
	});

	const toast = createStandaloneToast({ theme: theme });

	const pasteToastRef = useRef(null);

	// Main Zustand store imports
	const tabId = useStore((state) => state.tabs.activeTab);
	const getMayaFlowByTab = useStore(
		useCallback((state) => state.getMayaFlowByTab, [])
	);
	const workerId = useStore(useCallback((state) => state.brainId, []));

	// PAC-Engine Zustand store imports
	const recipe = usePacEngineStore((state) => state.recipe);
	const setRecipe = usePacEngineStore((state) => state.setRecipe);
	const updateStep = usePacEngineStore((state) => state.updateStep);
	const serverRecipe = usePacEngineStore((state) => state.serverRecipe);
	const serverFlow = usePacEngineStore((state) => state.serverFlow);
	const setIsGenerateLoading = usePacEngineStore(
		(state) => state.setIsGenerateLoading
	);
	const setStepIdBeingGenerated = usePacEngineStore(
		(state) => state.setStepIdBeingGenerated
	);
	const onReceivingGenerateMessage = usePacEngineStore(
		(state) => state.onReceivingGenerateMessage
	);
	const setActionTakenOnceFromEditor = usePacEngineStore(
		(state) => state.setActionTakenOnceFromEditor
	);
	const stepIdBeingRun = usePacEngineStore((state) => state.stepIdBeingRun);

	const INDENTATION = step.indent_lvl * 22;
	const isIndentedStep = step.indent_lvl === 0 ? false : true;
	const isTypeBranch = step.prefix.includes('-');

	const addToRef = (element) => {
		if (element) {
			stepRefs.current = { ...stepRefs.current, [stepId]: element };
		}
	};

	const zoomInOnNodes = usePacEngineStore((state) => state.zoomInOnNodes);
	const isGenerateLoading = usePacEngineStore(
		(state) => state.isGenerateLoading
	);
	const stepIdBeingGenerated = usePacEngineStore(
		(state) => state.stepIdBeingGenerated
	);
	const instructGenerationId = usePacEngineStore(
		(state) => state.instructGenerationId
	);
	const stepIdInFocus = usePacEngineStore((state) => state.stepIdInFocus);
	const setStepIdInFocus = usePacEngineStore(
		(state) => state.setStepIdInFocus
	);

	const [isHovered, setIsHovered] = useState(false);
	const [suggestions, setSuggestions] = useState([]);
	const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);

	const suggestionContainerRef = useRef(null);
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				suggestionContainerRef.current &&
				!suggestionContainerRef.current.contains(event.target)
			) {
				setSuggestions([]);
				setSelectedSuggestionIndex(-1);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [suggestionContainerRef]);

	const onStepFocus = ({ stepId }) => {
		if (!allowInteraction) {
			return;
		}

		setStepIdInFocus(stepId);

		if (recipe[stepId].generated || recipe[stepId].error) {
			const stepFlow = mayaFlow?.filter((node) => node._step_id === stepId);
			if (stepFlow.length > 0) {
				zoomInOnNodes({ nodes: stepFlow });
			}
		}
	};

	const handleKeyDown = (e) => {
		e.stopPropagation();
		if (e.key === 'Enter' && selectedSuggestionIndex === -1) {
			e.preventDefault();
			const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyz', 5);
			const newStepId = `step-instance-${nanoid()}`;
			const currentLevel = 0;
			const numberOfStepsOnCurrentLevel = Object.keys(recipe).filter(
				(stepId) => recipe[stepId].indent_lvl === currentLevel
			).length;
			const newRecipe = {
				...recipe,
				[newStepId]: {
					id: newStepId,
					type: 'continue',
					indent_lvl: currentLevel,
					lvl_idx: numberOfStepsOnCurrentLevel + 1,
					from_node: '',
					prefix: `${numberOfStepsOnCurrentLevel + 1}.`,
					parent_id: '',
					generated: false,
					scrambled: false,
					transformed: false,
					deployed: false,
					children: [],
					text: '',
				},
			};
			setRecipe({ newRecipe, diff: null });
			setTimeout(() => {
				stepRefs.current[newStepId].focus();
			}, 10);
		} else if (
			e.key === 'Backspace' &&
			stepRefs.current[stepId].innerText === '' &&
			Object.keys(recipe).length > 1
		) {
			const newRecipe = { ...recipe };
			const allStepIds = Object.keys(recipe);
			delete newRecipe[stepId];
			setRecipe({ newRecipe, diff: null });

			const deletedStepId = stepId;
			const deletedStepIdIndex = allStepIds.findIndex(
				(stepId) => stepId === deletedStepId
			);
			const previousStepId = allStepIds[deletedStepIdIndex - 1];
			setTimeout(() => {
				stepRefs.current[previousStepId].focus();
				contentEditableInputMoveCursorToEnd(
					stepRefs.current[previousStepId]
				);
			}, 10);
		} else if (e.key === 'Tab' && !e.shiftKey) {
			e.preventDefault();
			const currentIndentLvl = recipe[stepId].indent_lvl;
			const updatedStep = {
				...recipe[stepId],
				indent_lvl:
					currentIndentLvl === 7 ? currentIndentLvl : currentIndentLvl + 1,
				generated: false,
			};
			updateStep({ stepId, updatedStep });
		} else if (e.key === 'Tab' && e.shiftKey) {
			e.preventDefault();
			const currentIndentLvl = recipe[stepId].indent_lvl;
			const updatedStep = {
				...recipe[stepId],
				indent_lvl:
					currentIndentLvl === 0 ? currentIndentLvl : currentIndentLvl - 1,
				generated: false,
			};
			updateStep({ stepId, updatedStep });
		} else if (e.key === 'ArrowUp' && suggestions.length === 0) {
			e.preventDefault();
			const allStepIds = Object.keys(recipe);
			const currentStepId = stepId;
			const currentStepIdIndex = allStepIds.findIndex(
				(stepId) => stepId === currentStepId
			);
			const previousStepId = allStepIds[currentStepIdIndex - 1];
			if (previousStepId) {
				setTimeout(() => {
					stepRefs.current[previousStepId].focus();
					contentEditableInputMoveCursorToEnd(
						stepRefs.current[previousStepId]
					);
				}, 10);
			}
		} else if (e.key === 'ArrowDown' && suggestions.length === 0) {
			e.preventDefault();
			const allStepIds = Object.keys(recipe);
			const currentStepId = stepId;
			const currentStepIdIndex = allStepIds.findIndex(
				(stepId) => stepId === currentStepId
			);
			const nextStepId = allStepIds[currentStepIdIndex + 1];
			if (nextStepId) {
				setTimeout(() => {
					stepRefs.current[nextStepId].focus();
					contentEditableInputMoveCursorToEnd(
						stepRefs.current[nextStepId]
					);
				}, 10);
			}
		} else if (e.key === 'ArrowUp' && suggestions.length > 0) {
			setSelectedSuggestionIndex((prevIndex) =>
				prevIndex <= 0 ? suggestions.length - 1 : prevIndex - 1
			);
		} else if (e.key === 'ArrowDown' && suggestions.length > 0) {
			setSelectedSuggestionIndex((prevIndex) =>
				prevIndex === suggestions.length - 1 ? 0 : prevIndex + 1
			);
		} else if (
			e.key === 'Enter' &&
			suggestions.length > 0 &&
			selectedSuggestionIndex !== -1
		) {
			e.preventDefault();
			handleSelectSuggestion();
		} else if (e.key === 'Escape' && suggestions.length > 0) {
			setSuggestions([]);
			setSelectedSuggestionIndex(-1);
		}
	};

	const handleBlur = () => {
		if (!allowInteraction) {
			return;
		}

		const text = stepRefs.current[stepId]?.innerText;
		const isTextSame = text === recipe[stepId].text;
		const updatedStep = {
			...recipe[stepId],
			text,
			generated: isTextSame && recipe[stepId].generated ? true : false,
		};
		updateStep({ stepId, updatedStep });
	};

	const handlePaste = async (e) => {
		try {
			setActionTakenOnceFromEditor({
				profile: selectedProfile.name,
				workerId,
				value: true,
			});
			e.preventDefault();
			pasteToastRef.current = toast({
				title: 'Pasting...',
				duration: 9000,
				isClosable: true,
				position: 'top',
				status: 'info',
			});
			let pasted = false;
			const pastedText = e.clipboardData.getData('Text');
			if (pastedText.includes('\r') || pastedText.includes('\n')) {
				let modifiedPastedText = '';
				if (pastedText.includes('\r\n')) {
					modifiedPastedText = pastedText.replaceAll('\r\n', ' (*)\r\n');
				} else if (
					pastedText.includes('\n') &&
					!pastedText.includes('\r')
				) {
					modifiedPastedText = pastedText.replaceAll('\n', ' (*)\n');
				} else if (
					pastedText.includes('\r') &&
					!pastedText.includes('\n')
				) {
					modifiedPastedText = pastedText.replaceAll('\r', ' (*)\r');
				}
				// Ensures the last step has the '(*)' character at the end
				modifiedPastedText = modifiedPastedText + '(*)';
				modifiedPastedText = modifiedPastedText.replaceAll('(!)', '');
				const res = await parseRecipe({
					toParseItem: modifiedPastedText,
					fromFormat: 'str',
					toFormat: 'obj',
				});
				const parsedText = res.data;
				if (
					!Object.keys(parsedText).includes('status_code') &&
					Object.keys(parsedText).length !== 0
				) {
					setRecipe({ newRecipe: parsedText, diff: null });
					pasted = true;
					if (pasteToastRef.current) {
						toast.update(pasteToastRef.current, {
							title: 'Pasted',
							duration: 2000,
							isClosable: true,
							position: 'top',
							status: 'success',
						});
					}
					return;
				}

				const text = sanitizePastedText({ pastedText });
				const updatedStep = {
					...recipe[stepId],
					text,
				};
				updateStep({ stepId, updatedStep });
				pasted = true;
			} else {
				const updatedStep = {
					...recipe[stepId],
					text: pastedText,
				};
				updateStep({ stepId, updatedStep });
				contentEditableInputMoveCursorToEnd(stepRefs.current[stepId]);
				pasted = true;
			}

			if (!pasted) {
				throw new Error();
			}
			if (pasteToastRef.current) {
				toast.update(pasteToastRef.current, {
					title: 'Pasted',
					duration: 2000,
					isClosable: true,
					position: 'top',
					status: 'success',
				});
			}
		} catch (err) {
			console.error(err);
			if (pasteToastRef) {
				toast.closeAll();
			}
			toast({
				title: 'Invalid Recipe',
				description: 'Please paste a valid recipe',
				duration: 2000,
				isClosable: true,
				position: 'bottom',
				status: 'error',
			});
		}
	};

	const handlePrefixClick = () => {
		if (isIndentedStep) {
			const branchPrefixes = {
				continue: '',
				branch: '-',
			};
			if (recipe[stepId].type === 'continue') {
				const updatedStep = {
					...recipe[stepId],
					type: 'branch',
					prefix: recipe[stepId].prefix.replace(
						branchPrefixes.continue,
						branchPrefixes.branch
					),
					generated: false,
				};
				updateStep({ stepId, updatedStep });
			} else if (recipe[stepId].type === 'branch') {
				const updatedStep = {
					...recipe[stepId],
					type: 'continue',
					prefix: recipe[stepId].prefix.replace(
						branchPrefixes.branch,
						branchPrefixes.continue
					),
					generated: false,
				};
				updateStep({ stepId, updatedStep });
			}
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSuggestions = useCallback(
		debounce(async (query) => {
			try {
				if (query.length > 1) {
					const response = await stepSearch(query);
					const suggestions = [];
					response?.data.forEach((r) => {
						r?.[0]?.step.forEach((s) => {
							suggestions.push(s);
						});
					});
					setSuggestions(suggestions);
					setSelectedSuggestionIndex(-1);
				} else {
					setSuggestions([]);
					setSelectedSuggestionIndex(-1);
				}
			} catch (error) {
				setSuggestions([]);
				setSelectedSuggestionIndex(-1);
				console.error('Fetching suggestions failed:', error);
			}
		}, 120),
		[]
	);

	const handleInput = async (e) => {
		try {
			const query = e.target.innerText;
			await handleSuggestions(query);
		} catch (err) {
			console.error(err);
		}
	};

	const handleGenerate = async () => {
		if (!allowInteraction) {
			return;
		}

		try {
			setActionTakenOnceFromEditor({
				profile: selectedProfile.name,
				workerId,
				value: true,
			});
			setIsGenerateLoading(true);
			const updatedStep = {
				...recipe[stepId],
				error: null,
				generated: false,
				use_cache: false,
			};
			updateStep({ stepId, updatedStep });
			const recipeDiff = compareRecipes({
				// Using the `getState` function because `recipe` is leading to stale state - it doesn't reflect the change made by the `updateStep` function above
				recipe: usePacEngineStore.getState().recipe,
				serverRecipe: serverRecipe,
			});
			const flowDiff = compareFlows({
				flow: JSON.stringify([
					...getMayaFlowByTab({ tabId: tabId }),
					...window.RED.nodes
						.createCompleteNodeSet()
						.filter(
							(n) =>
								!n.x &&
								!n.y &&
								n.type !== 'tab' &&
								n.type !== 'subflow' &&
								n.type !== 'group'
						),
				]),
				serverFlow: JSON.stringify(serverFlow),
			});
			/** @type {import ('../zustand/types').Diff} */
			const diff = {
				recipe: { steps: recipeDiff },
				flow: { nodes: flowDiff },
			};
			if (diffExists({ diff })) {
				console.log('diff', diff);
				const sessionRes = await changeSession({ sessionId, diff });
				if (!sessionRes) {
					throw new Error('Request to changeSession failed');
				}
			}
			const firstNonGeneratedStepId = findKey(
				// Just using `recipe` is leading to stale state. Figure out why, fix the problem and use `recipe` everywhere
				usePacEngineStore.getState().recipe,
				(step) => step.generated === false
			);
			setStepIdBeingGenerated(firstNonGeneratedStepId);
			generate({
				sessionId,
				onReceivingGenerateMessage,
				tabId,
				setIsGenerateLoading,
				setStepIdBeingGenerated,
				stepIdToFocusOn: stepId,
				fromEditor: true,
				connId: null,
			});
		} catch (err) {
			console.error(err);
			toast({
				title: 'Request Logged!',
				description: `We're still teaching Maya how to do this. We'll reach out to you as soon as it's ready.`,
				status: 'info',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	const handleSelectSuggestion = async () => {
		const selectedSuggestion = suggestions[selectedSuggestionIndex];
		const updatedStep = {
			...recipe[stepId],
			text: selectedSuggestion,
		};
		updateStep({ stepId, updatedStep });
		setSuggestions([]);
		setSelectedSuggestionIndex(-1);
		contentEditableInputMoveCursorToEnd(stepRefs.current[stepId]);
	};

	return (
		<Box
			// marginBottom="8px !important"
			padding="4px 0px"
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<Box display="flex" alignItems="start" marginRight={4}>
				<Box minWidth={5} marginLeft={2} flexShrink={0}>
					<StepStateIndicator
						step={step}
						stepId={stepId}
						stepIdBeingGenerated={stepIdBeingGenerated}
						stepIdBeingRun={stepIdBeingRun}
					/>
				</Box>
				{isIndentedStep ? (
					<Box
						marginRight="9px"
						fontSize="16px"
						color="light.font.gray.400"
						marginLeft={`${INDENTATION}px !important`}
						flexShrink={0}
						userSelect="none"
						onClick={handlePrefixClick}
						cursor={isIndentedStep ? 'pointer' : 'auto'}
						// Shows the '-' if step is of type branch
						opacity={isTypeBranch ? 100 : 0}
					>
						-
					</Box>
				) : null}
				<Box
					marginRight="9px"
					fontSize="16px"
					color="light.font.gray.400"
					flexShrink={0}
					userSelect="none"
				>
					{/* Excluding the - from the prefix, rendering only the number
						If the prefix's length (the number part) is longer or equal to 8, which means it has 4 digits, then it's shown as two dots + the last 4 characters
					*/}
					{step.prefix.replaceAll('-', '').length >= 8
						? `..${step.prefix
								.replaceAll('-', '')
								.slice(
									step.prefix.replaceAll('-', '').length - 4,
									step.prefix.replaceAll('-', '').length
								)}`
						: step.prefix.replaceAll('-', '')}
				</Box>

				<Box
					// bg="yellow"
					marginRight="10px"
					className={styles.editableStep}
					contentEditable
					suppressContentEditableWarning
					as="span"
					display="block"
					role="textbox"
					cursor="text"
					_focus={{
						outline: 'none',
					}}
					spellCheck={false}
					flexGrow={1}
					fontSize="16px"
					color="light.font.gray.400"
					opacity={
						isGenerateLoading && stepId === stepIdBeingGenerated ? 0.7 : 1
					}
					ref={addToRef}
					onFocus={() => onStepFocus({ stepId })}
					onKeyDown={handleKeyDown}
					onBlur={handleBlur}
					onPaste={handlePaste}
					onInput={handleInput}
					position="relative"
				>
					{step.text}
				</Box>

				{allowInteraction ? (
					<StepFeedbackCollection
						step={step}
						isHovered={isHovered}
						handleGenerate={handleGenerate}
						instructGenerationId={instructGenerationId}
					/>
				) : null}
			</Box>
			{suggestions.length > 0 && stepId === stepIdInFocus ? (
				<Box
					ref={suggestionContainerRef}
					marginTop="12px"
					marginLeft="48px"
					marginRight="24px"
				>
					{suggestions.map((s, index) => {
						return (
							<Box
								padding="6px 12px"
								borderRadius="1px"
								borderTop="1px solid #d8d8d8"
								borderInline="1px solid #d8d8d8"
								borderBottom={
									index === suggestions.length - 1
										? '1px solid #d8d8d8'
										: undefined
								}
								fontSize="14px"
								bg={
									index === selectedSuggestionIndex
										? '#e2e8f0'
										: '#f7f6f3'
								}
								cursor="pointer"
								onClick={handleSelectSuggestion}
								onMouseEnter={() => setSelectedSuggestionIndex(index)}
							>
								{s}
							</Box>
						);
					})}
				</Box>
			) : null}
		</Box>
	);
};

export default React.memo(Step);
