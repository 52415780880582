import { Box, Flex } from '@chakra-ui/react';
import startCase from 'lodash/startCase';
import React, { useContext } from 'react';
import { itemTypes } from '../itemTypes';
import FilterItem from './FilterItem';

export interface EntityFilterProps {
	selectedEntity: string | null;
	onEntityChange: (selectedEntity: string) => void;
}

/**
 * # Entity Filter component
 *
 * - Used to select/change entity filter. entities: `modules`, `collections` etc
 */
const EntityFilter = ({
	selectedEntity,
	onEntityChange,
}: EntityFilterProps) => {
	return (
		<Flex direction="column" minHeight="0" overflow="auto">
			{Object.values(itemTypes).map((type, idx) => (
				<Box mb="8px">
					<FilterItem
						label={startCase(type)}
						onClick={() => onEntityChange(type)}
						selected={selectedEntity === type}
						key={idx}
					/>
				</Box>
			))}
		</Flex>
	);
};

export default EntityFilter;
