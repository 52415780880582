import React, { useEffect, useState } from 'react';
import {
	Box,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	MenuGroup,
	MenuDivider,
	useColorModeValue,
	useDisclosure,
	useToast,
	useColorMode,
} from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/react';
import theme from '../../../../../../library/theme';
import { connect, useSelector } from 'react-redux';
import { DoubleChevron } from '../../../../../../assets/icons';
import { FiSettings } from 'react-icons/fi';
import { AiOutlineTeam, AiOutlinePlus, AiOutlineLogout } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

// Redux Actions
import {
	logoutUser,
	setProfiles,
	setCurrentProfile,
	setLoggedIn,
} from '../../../../../../redux/actions';

// Controllers
import LogoutController from '../../../../../../util/Logout';

//Images
// import dummy from "./dummy.png";
// import maya_icon from "../../../assets/icon.png";

//Components
import IconContainer from '../../../../../../util/React_Icon';
// import CreateTeamModal from '../../Teams/CreateTeam';
import Avatar from './Avatar';

//Utils
import History from '../../../../../../util/History';

//Functions
import getUserProfiles from '../../../../../../functions/profile/getUserProfiles';
import switchProfile from '../../../../../../functions/profile/switchProfile';

const CreateTeam = () => {
	const toast = createStandaloneToast({ theme: theme });
	var iconColor = '#656565';

	return (
		<Box
			height="3rem"
			width="100%"
			display="flex"
			flexDirection="row"
			alignItems="center"
			justifyContent="space-between"
			borderRadius="5px"
			cursor="pointer"
			onClick={() => {
				toast({
					title: 'Coming soon!',
					description: 'Share workflows with your team & friends.',
					status: 'success',
					duration: 9000,
					isClosable: true,
				});

				// History.push('/onboarding');
			}}
			_hover={{
				bg: 'light.theme.gray.200',
			}}
			transition="all 0.1s cubic-bezier(.08,.52,.52,1)"
		>
			<Box display="flex" alignItems="center">
				<Box ml="1rem">
					<IconContainer
						icon={<AiOutlineTeam />}
						value={{
							color: iconColor,
							className: 'global-class-name',
							size: '1rem',
						}}
					/>
				</Box>

				<Box marginLeft="19px">
					<Box textStyle="sans.xs" color="light.font.gray.400">
						{'Create Team'}
					</Box>
				</Box>
			</Box>
			<Box mr="5">
				<IconContainer
					icon={<AiOutlinePlus />}
					value={{
						color: iconColor,
						className: 'global-class-name',
						size: '1rem',
					}}
				/>
			</Box>
			{/* <CreateTeamModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} /> */}
		</Box>
	);
};

const Logout = ({ setLoggedIn, logoutUser }) => {
	var iconColor = '#656565';
	const toast = createStandaloneToast({ theme: theme });
	const [googleAuth2, setGoogleAuth2] = useState(null);

	useEffect(() => {
		// This useEffect inits the Google auth2 instance/client
		window.gapi.load('auth2', () => {
			// Retrieve the singleton for the GoogleAuth library and set up the client.
			const redirect_uri = `${
				window.location.origin
			}/login?googleSigningIn=true&desktopRedirect=false`;
			const auth2 = window.gapi.auth2.init({
				// ref: https://developers.google.com/identity/sign-in/web/reference#gapiauth2clientconfig
				client_id: process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID,
				// cookiepolicy: 'single_host_origin',
				ux_mode: 'redirect',
				redirect_uri,
				// Request scopes in addition to 'profile' and 'email'
				scope: 'profile email openid',
			});
			setGoogleAuth2(auth2);
		});
	}, []);

	const logout = (e) => {
		// Prevent the link from executing.
		// e.preventDefault();

		LogoutController.logout()
			.then(() => {
				setLoggedIn(false);
				logoutUser();
				// window.location.reload();
				History.replace('/login');
			})
			.catch((error) =>
				// Display the error from the API server on logout.
				toast({
					title: 'An Error Occurred',
					description: "We're working on it.",
					position: 'bottom',
					status: 'error',
					duration: 3000,
					isClosable: false,
				})
			);

		// Use the LogoutController to handle the logout.
	};

	return (
		<Box
			height="3rem"
			width="100%"
			display="flex"
			flexDirection="row"
			alignItems="center"
			justifyContent="space-between"
			fontFamily="body"
			borderRadius="2px"
			cursor="pointer"
			onClick={logout}
			_hover={{
				bg: 'light.theme.gray.200',
			}}
			transition="all 0.1s cubic-bezier(.08,.52,.52,1)"
		>
			<Box display="flex" alignItems="center">
				<Box ml="1rem">
					<IconContainer
						icon={<AiOutlineLogout />}
						value={{
							color: iconColor,
							className: 'global-class-name',
							size: '1rem',
						}}
					/>
				</Box>
				<Box marginLeft="19px">
					<Box textStyle="sans.xs" color={'light.font.gray.400'}>
						{'Log Out'}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

const ConnectedLogout = connect(null, { logoutUser, setLoggedIn })(Logout);

// const ProfileItem = ({ name, img }) => {
//   var iconColor = useColorModeValue("#656565", "white");

//   return (
//     <Box
//       height="3rem"
//       width="100%"
//       display="flex"
//       flexDirection="row"
//       alignItems="center"
//       justifyContent="space-between"
//       fontFamily="body"
//       borderRadius="5px"
//       cursor="pointer"
//     >
//       <Box display="flex" alignItems="center">
//         <Image
//           boxSize="1.5rem"
//           borderRadius="full"
//           src={img}
//           alt="Fluffybuns the destroyer"
//           ml="0.8rem"
//         />
//         <Box marginLeft="1rem">
//           <Box
//             fontSize="0.8rem"
//             fontWeight="600"
//             color={useColorModeValue("#444444", "white")}
//           >
//             {name}
//           </Box>
//         </Box>
//       </Box>
//       <Box mr="5">
//         <IconContainer
//           icon={<FiSettings />}
//           value={{
//             color: iconColor,
//             className: "global-class-name",
//             size: "1rem",
//           }}
//         />
//       </Box>
//     </Box>
//   );
// };

export const unSwitchableRoutes = ['/store/skills', '/store/modules'];

const ProfileItem = ({ details }) => {
	var iconColor = '#656565';
	let location = useLocation();

	// dusnat pls do edits here
	return (
		<Box
			height="3rem"
			width="100%"
			display="flex"
			flexDirection="row"
			alignItems="center"
			justifyContent="space-between"
			borderRadius="5px"
			cursor="pointer"
			onClick={() => switchProfile(details.profileSlug, location)}
		>
			<Box display="flex" alignItems="center" paddingLeft="0.4rem">
				<Avatar name={details.name} size="21" />
				<Box marginLeft="1rem">
					<Box textStyle="sans.xs" color={'light.font.gray.400'}>
						{details.name}
					</Box>
				</Box>
			</Box>
			<Box mr="5">
				<IconContainer
					icon={<FiSettings />}
					value={{
						color: iconColor,
						className: 'global-class-name',
						size: '1rem',
					}}
				/>
			</Box>
		</Box>
	);
};

const SelectProfile = (props) => {
	//const [teams, setTeams] = React.useState([]);
	var { setProfiles, setCurrentProfile } = props;

	// React.useEffect(() => {
	// 	getUserProfiles().then((res) => {
	// 		setProfiles(res);
	// 		// res.map((item) => {
	// 		//   if (!item.isTeam) {
	// 		//     setUser(item);
	// 		//   } else {
	// 		//     var newTeams = [...teams, item];
	// 		//     setTeams(newTeams);
	// 		//   }
	// 		// });
	// 		// console.log('chl Profiles :', res);
	// 	});
	// }, []);

	const { user, teams, selected } = useSelector(
		/**
		 *
		 * @param {import("../../../../../../redux/reducers/types/profileReducer").ProfilesState} state
		 * @returns
		 */
		(state) => {
			const allProfiles = Object.values(state.profiles.profiles);
			const teams = allProfiles.filter((profile) => profile.isTeam);
			let user = state.profiles.profiles[state.profiles.userSlug];
			// let user = allProfiles.find(profile => profile.isTeam === false) || {}
			const selected = state.profiles.profiles[state.profiles.selected];
			return { user, teams, selected };
		}
	);

	console.log('ifq', user, teams, selected);

	return (
		<Box>
			<Menu placement="top">
				<MenuButton
					as={Box}
					width="100%"
					bottom="0"
					height="50px"
					display="flex"
					alignItems="center"
					cursor="pointer"
				>
					<Box
						alignItems="center"
						justifyContent="space-between"
						display="flex"
						userSelect="none"
					>
						{selected.name ? (
							<Box
								display="flex"
								alignItems="center"
								mt="1"
								marginRight="5px"
							>
								<Avatar name={selected.name} size="20" />
								<Box
									color={'light.font.gray.400'}
									textStyle="sans.sm"
									ml="3"
									whiteSpace="nowrap"
								>
									{_.truncate(selected.name, {
										length: 15,
										separator: ' ',
									})}
								</Box>
							</Box>
						) : null}
						<Box mr="2" mt="1">
							<DoubleChevron color={'#4B4B4B'} />
						</Box>
					</Box>
				</MenuButton>
				<MenuList
					bg={'white'}
					boxShadow="rgba(0, 0, 0, 0.05) 1px 2px 2px"
					minWidth="210px"
					borderRadius="2px"
					border="solid 1px"
					borderColor="light.border.gray.200"
				>
					<MenuGroup title="Personal" textStyle="sans.sm">
						<MenuItem
							minH="48px"
							padding="0"
							transition="all 0.1s cubic-bezier(.08,.52,.52,1)"
							onClick={() => {
								setCurrentProfile(user.profileSlug);
							}}
							_hover={{
								bg: 'light.theme.gray.200',
							}}
						>
							<ProfileItem details={user} />
						</MenuItem>
					</MenuGroup>
					<MenuDivider />
					<MenuGroup title="Teams" textStyle="sans.sm">
						{teams.map((team) => {
							return (
								<MenuItem
									minH="48px"
									padding="0"
									key={team.name}
									transition="all 0.1s cubic-bezier(.08,.52,.52,1)"
									onClick={() => {
										setCurrentProfile(team.profileSlug);
									}}
									_hover={{
										bg: 'light.theme.gray.200',
									}}
								>
									<ProfileItem details={team} />
								</MenuItem>
							);
						})}
					</MenuGroup>
					<MenuDivider />

					{/* Don't want to let users create more than one team */}
					{/* <MenuItem
						minH="48px"
						padding="0"
						transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
					>
						<CreateTeam />
					</MenuItem> */}
					<MenuItem
						minH="48px"
						padding="0"
						transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
					>
						<ConnectedLogout />
					</MenuItem>
				</MenuList>
			</Menu>
		</Box>
	);
};

export default connect(null, { setProfiles, setCurrentProfile })(SelectProfile);
