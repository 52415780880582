import React from 'react';
import MayaFilledButton from '../../../../../library/buttons/FilledButton';
import { Flex } from '@chakra-ui/react';

const KeyGenerationButton = ({
	keyExists = false,
	developerAccess,
	onClick,
	isLoading,
}) => {
	return (
		<Flex alignItems="start">
			<MayaFilledButton
				isLoading={isLoading}
				text={keyExists ? 'Regenerate Key' : 'Generate Key'}
				showDots={false}
				onClick={onClick}
			></MayaFilledButton>
		</Flex>
	);
};

export default KeyGenerationButton;
