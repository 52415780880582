import { Flex, Spinner } from '@chakra-ui/react';
import {
	SkillVisualizer,
	getSkillData,
	segregateFiles,
	CollectionSection,
	fetchZipFile,
} from '@mayahq/ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import getStoreSkillPacks from '../../../../../functions/store/getStoreSkillPacks';
import { createStandaloneToast } from '@chakra-ui/react';
import theme from '../../../../../library/theme';
import { v4 as uuidv4 } from 'uuid';
import useStepperStore from '../../../../Stepper/StepperStore';
import { stepperFlowTypes } from '../../../../Stepper/Sections/registry';
import { useStore } from '../../../../Editor/zustand';
import {
	getStoreSkillById,
	getStoreSkillBySlug,
} from '../../../../../functions/store/getStoreSkills';
import analytics from '../../../../../util/Analytics';

/**
 *
 * # Store Collection/SkillPack Page
 *
 * @param {{
 *  currentWorkspaceId: string
 * }} param0
 * @returns
 */
const CollectionPage = ({ currentWorkspaceId }) => {
	const track = analytics.track;
	/** @type {{ collectionId: string }} */
	const { org, slug } = useParams();
	const history = useHistory();
	const [collection, setCollection] = useState(null);
	const [loading, setLoading] = useState(true);
	const [hasCopied, setCopied] = useState(false);
	const [zipFileName, setZipFileName] = useState('');
	const toast = createStandaloneToast({ theme: theme });
	const { addStep, openStepper } = useStepperStore();
	const [skillData, setSkillData] = useState({
		name: '',
		recipes: [],
		steps: [],
		docs: [],
	});

	const collectionSlug = `${org}/${slug}`;

	const setTeachingZipFile = useStore((state) => state.setTeachingZipFile);

	const handleCollectionCopy = useCallback(async (flowJson) => {
		try {
			if (!flowJson) throw new Error('flowJson invalid!');
			await navigator.clipboard.writeText(JSON.stringify(flowJson, null, 4));
			setCopied(true);
			toast({
				title: 'Copied to clipboard!',
				status: 'success',
			});
			setTimeout(() => {
				setCopied(false);
			}, 2000);
		} catch (error) {
			console.error(error);
		}
	}, []);

	const getCollectionBySlug = useCallback(
		/** @param {string} collectionSlug */
		async (collectionSlug) => {
			try {
				/**
				 * @type {import('../../../../../functions/store/types/SkillPacks').StoreSkillPack}
				 */
				const res = await getStoreSkillBySlug(collectionSlug);
				return res.docs[0];
			} catch (error) {
				console.error(error);
			}
			return null;
		},
		[]
	);

	const getCollectionById = useCallback(
		/** @param {string} collectionId */
		async (collectionId) => {
			try {
				/**
				 * @type {import('../../../../../functions/store/types/SkillPacks').StoreSkillPack}
				 */
				const res = await getStoreSkillById(collectionId);
				return res;
			} catch (error) {
				console.error(error);
			}
			return null;
		},
		[]
	);

	console.log('ncms we here');

	useEffect(() => {
		setLoading(true);
		console.log('ncms we here');

		getCollectionBySlug(collectionSlug)
			.then((res) => {
				setCollection(res);
				console.log('ncms bruh', res);
				// @ts-ignore
				const activePackage = res.packages.find(
					(p) => p.version === res.currentVersion
				);
				setZipFileName(activePackage.compressed.url);
				// setZipFileName(res?.zip?.url);
				setLoading(false);
			})
			.catch((e) => {
				console.error(e);
				setLoading(false);
			});
	}, [collectionSlug, getCollectionBySlug]);

	useEffect(() => {
		const handler = async () => {
			if (zipFileName) {
				const url = zipFileName;
				const remoteZipFile = await fetchZipFile(url);
				setTeachingZipFile(remoteZipFile);
				const data = await segregateFiles(remoteZipFile);
				const skillData = getSkillData('bruh', data);
				setSkillData(skillData);
			}
		};
		handler();
	}, [setTeachingZipFile, zipFileName]);

	return loading ? (
		<Flex
			justifyContent="center"
			alignItems="center"
			flex="1"
			minHeight="full"
		>
			<Spinner bg="light.theme.purple.400" />
		</Flex>
	) : !collection ? (
		<Flex justifyContent="center" alignItems="center" flex="1">
			404
		</Flex>
	) : (
		<Flex direction="column" alignItems="center">
			<Flex w="100%">
				<CollectionSection
					collection={collection}
					onBackButtonClick={history.goBack}
					onCopyFlowClick={handleCollectionCopy}
					containerProps={{
						w: 'full',
						h: 'full',
					}}
					hasCopied={hasCopied}
					onTeachButtonClick={() => {
						track(`[Store] ${collection.title} > Teach button clicked`);
						const stepperSessionId = uuidv4();
						const initState = {
							stepperSessionId,
							stepperFlowType: stepperFlowTypes.teach.TEACH_SKILL,
							skillData: { ...skillData },
							selectedWorkspaceId: null,
							name: collection.title,
							metadata: {
								source: 'store',
								id: collection.id,
								name: collection.title,
								version: collection.currentVersion,
							},
						};
						addStep('teach.skill', initState);
						openStepper();
					}}
				/>
			</Flex>
			{zipFileName ? (
				<SkillVisualizer
					skillData={skillData}
					setSkillData={setSkillData}
					showPreview={false}
					allowToggle={false}
				/>
			) : null}
		</Flex>
	);
};

export default CollectionPage;
