import React from 'react';
import { Box, useColorMode, Image } from '@chakra-ui/react';

//Assets
import enterIconDark from '../../../../../../assets/cardui/entericon_dark.svg';
import enterIconLight from '../../../../../../assets/cardui/entericon_light.svg';

//Components
import IconContainer from '../../../../../../util/React_Icon';
import chooseIcon from '../../../../../../util/Icons';
import { setVibrateInstructions } from '../../../../../../redux/actions/cardui';
import { connect } from 'react-redux';
import ItemRightInstructions from '../ItemInstructions';

//Functions
import getCurrentPlaceholdersDetails from '../../../../../../functions/commandBar/getCurrentPlaceholdersDetails';
import getIconPng from '../../../../../../functions/misc/getIconPng';

function isRemoteUrl(urlString) {
	try {
		new URL(urlString);
		return true;
	} catch (e) {
		return false;
	}
}

const SearchItem = ({
	selectedIndex,
	i,
	searchItemMainText,
	searchItemSubText,
	reactIcon,
}) => {
	const [path, setPath] = React.useState(chooseIcon(reactIcon));
	const [isIcns, setIcns] = React.useState(false);
	const [isRemote, setIsRemote] = React.useState(false);

	const setIconPath = async () => {
		let res = await getIconPng({ path: reactIcon });
		if (!res.error) {
			setPath(res.results);
			setIcns(true);
		} else {
			setPath(chooseIcon('app-store'));
			setIcns(false);
		}
	};

	React.useEffect(() => {
		if (typeof reactIcon === 'string') {
			if (reactIcon.indexOf('.icns') > -1) {
				setIconPath();
			} else {
				if (isRemoteUrl(reactIcon)) {
					setPath(reactIcon);
					setIsRemote(true);
				} else {
					setIsRemote(false);
					setPath(chooseIcon(reactIcon));
				}
				setIcns(false);
			}
		}
	}, [reactIcon]);
	const { colorMode } = useColorMode();
	const bgColorSelected = {
		light: 'card_ui.focus_light',
		dark: 'card_ui.focus_dark',
	};
	const color = {
		light: '#6C6C6C',
		dark: '#FFFEFE',
	};
	const lighterColor = {
		light: 'card_ui.font_light.100',
		dark: 'card_ui.font_dark.100',
	};

	const dividerColor = {
		light: 'card_ui.divider_light',
		dark: 'card_ui.divider_dark',
	};

	const enterIcon = {
		light: enterIconLight,
		dark: enterIconDark,
	};
	var ifMultiplePlaceholders = getCurrentPlaceholdersDetails().multiple;
	return (
		<Box
			paddingY="1.5rem"
			//paddingLeft="0.5rem"
			height="2.8rem"
			display="flex"
			key={i}
			pl={4}
			alignItems="center"
			bg={selectedIndex === i + 1 ? bgColorSelected[colorMode] : 'none'}
			borderRight="solid 3px"
			borderRightColor={selectedIndex === i + 1 ? '#9B51E0' : 'transparent'}
			borderTop={'solid 1px'}
			justifyContent="space-between"
			borderTopColor={dividerColor[colorMode]}
		>
			<Box display="flex" alignItems="center">
				{isIcns || isRemote ? (
					<Image src={path} boxSize="1.5rem" />
				) : (
					<Box paddingLeft="0.2rem">
						<IconContainer
							icon={path}
							value={{
								color: color[colorMode],
								className: 'global-class-name',
								size: '1.2rem',
							}}
						/>
					</Box>
				)}
				<Box>
					<Box
						display="flex"
						alignItems="center"
						ml={3}
						fontSize="0.9rem"
						fontFamily="body"
						flexWrap={'wrap'}
						color={color[colorMode]}
					>
						{searchItemMainText}
					</Box>
					<Box
						display="flex"
						alignItems="center"
						ml={3}
						fontSize="0.7rem"
						fontFamily="body"
						fontWeight="500"
						flexWrap={'wrap'}
						color={lighterColor[colorMode]}
					>
						{`${searchItemSubText}`}
					</Box>
				</Box>
			</Box>
			{selectedIndex === i + 1 ? (
				<ItemRightInstructions
					text={ifMultiplePlaceholders ? 'Shift + Enter' : 'Enter'}
				/>
			) : null}
		</Box>
	);
};

export default SearchItem;
