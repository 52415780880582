import React from 'react';
import { Flex, FlexProps, Stack, Tag } from '@chakra-ui/react';
import { InfoTag } from './InfoTag';

export interface TagsSectionProps {
	tags: string[];
	containerProps?: FlexProps;
}

const TagsSection = ({ tags, containerProps }: TagsSectionProps) => {
	return (
		<Flex {...containerProps}>
			<InfoTag label="Tags">
				<Stack direction="row" mt="2">
					{tags.map(tag => (
						<Tag
							key={tag}
							bg="white"
							border="1.5px solid #ddd"
							color="#5B5B5B"
							borderRadius="2px"
							fontSize="xs"
						>
							{tag}
						</Tag>
					))}
				</Stack>
			</InfoTag>
		</Flex>
	);
};

export default TagsSection;
