import { cloneDeep } from "lodash";

function duplicateNode(node, x, y) {
	const RED = window.RED;
	var m = /^subflow:(.+)$/.exec(node.type);
	var node_width = 100;
	var node_height = 30;

	let nn = cloneDeep(node);
	nn.id = RED.nodes.id();

	nn._def = RED.nodes.getType(nn.type);
	if (!m) {
		nn.inputs = nn._def.inputs || 0;
		nn.outputs = nn._def.outputs;

		// for (var d in nn._def.defaults) {
		// 	if (nn._def.defaults.hasOwnProperty(d)) {
		// 		if (nn._def.defaults[d].value !== undefined) {
		// 			nn[d] = JSON.parse(JSON.stringify(nn._def.defaults[d].value));
		// 		}
		// 	}
		// }
		if (nn._def.onadd) {
			try {
				nn._def.onadd.call(nn);
			} catch (err) {
				console.log('Definition error: ' + nn.type + '.onadd:', err);
			}
		}
	} else {
		var subflow = RED.nodes.subflow(m[1]);
		// nn.name = '';
		nn.inputs = subflow.in.length;
		nn.outputs = subflow.out.length;
	}

	nn.changed = true;
	nn.moved = true;

	nn.w = node_width;
	nn.h = Math.max(node_height, (nn.outputs || 0) * 15);
	nn.resize = true;

	var historyEvent = {
		t: 'add',
		nodes: [nn.id],
		dirty: RED.nodes.dirty(),
	};

	var showLabel = RED.utils.getMessageProperty(
		RED.settings.get('editor'),
		'view.view-node-show-label'
	);
	if (
		showLabel !== undefined &&
		!/^link (in|out)$/.test(nn._def.type) &&
		!nn._def.defaults.hasOwnProperty('l')
	) {
		nn.l = showLabel;
	}

	nn.x = x;
	nn.y = y;


	RED.nodes.add(nn);

	RED.history.push(historyEvent);
	RED.editor.validateNode(nn);
	RED.nodes.dirty(true);

	if (nn._def.autoedit) {
		RED.editor.edit(nn);
	}

	return {
		node: nn,
		historyEvent: historyEvent,
	};
}

export default duplicateNode;
