import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';

// config
import { config } from '../../config';
//redux
import { store } from '../../redux/store';
/**
 * Function to Create a New Brain, given some parameters
 */
const getWorkspaceConfigMap = async ({ workspaceId }) => {
	var getWorkspaceConfigMap;

    getWorkspaceConfigMap = APIFunction({
        BASEURL: config.apiServer.BASEURL,
        PATH_SEARCH: APIRoutes.apiService.getBrainConfigMap.PATH_SEARCH,
        PATH_METHOD: APIRoutes.apiService.getBrainConfigMap.PATH_METHOD,
        PATH_QUERY: workspaceId,
    });

	var getWorkspaceConfigMapResponse = await getWorkspaceConfigMap();
	if (getWorkspaceConfigMapResponse['data']['error']) {
		return getWorkspaceConfigMapResponse['data'];
	} else {
		return getWorkspaceConfigMapResponse['data']['results']
	}
};

export default getWorkspaceConfigMap;
