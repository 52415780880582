import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	CircularProgress,
	Flex,
	Switch,
	Text,
	Tooltip,
	Grid,
	GridItem,
	Spinner,
} from '@chakra-ui/react';
import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { FileContentDisplayer } from './FileContentDisplayer';

export type Sample = {
	content: string;
	id: string;
	public: boolean;
};

export type RecipeData = {
	name?: string;
	id: string;
	public: boolean;
	description: string;
	samples: Sample[];
	meanCollisionScore?: number;
};

export type StepData = {
	name?: string;
	id: string;
	public: boolean;
	description: string;
	samples: Sample[];
	meanCollisionScore?: number;
};

export type DocumentData = {
	id: string;
	public: boolean;
	name: string;
	description: string;
	content: string;
	meanCollisionScore?: number;
};

export type SkillData = {
	name?: string;
	recipes: RecipeData[];
	steps: StepData[];
	docs: DocumentData[];
};

type CollisionDataMap = {
	[key: string]: number;
};

type CollisionData = {
	recipes: CollisionDataMap;
	steps: CollisionDataMap;
	docs: number[];
};

type SkillVisualizerProps = {
	skillData: SkillData;
	collisionData?: CollisionData;
	verifyLoading?: boolean;
	setSkillData: Function;
	allowToggle: boolean;
	showPreview: boolean;
	showCollisionData?: boolean;
};

type ListSectionProps = {
	data: RecipeData[] | StepData[] | DocumentData[];
	category: 'recipe' | 'step' | 'document';
	title: string;
	selectedFile: string;
	handleItemClick: Function;
	handleToggleClick: Function;
	allowToggle: boolean;
	showCollisionData: boolean;
	verifyLoading: boolean;
};

type ItemSectionProps = {
	data: RecipeData | StepData | DocumentData;
	category: 'recipe' | 'step' | 'document';
	isHighlighted: boolean;
	handleItemClick: MouseEventHandler;
	handleToggleClick: Function;
	allowToggle: boolean;
	showCollisionData: boolean;
	verifyLoading: boolean;
};

type ItemInfoBoxProps = {
	text?: string;
	icon?: any;
	width?: string;
	isHighlighted?: boolean;
	tooltipMessage?: string;
};

type FileListProps = {
	recipes: RecipeData[];
	steps: StepData[];
	docs: DocumentData[];
	selectedFile: string;
	handleItemClick: Function;
	handleToggleClick: Function;
	allowToggle: boolean;
	collisionData?: CollisionData;
	showCollisionData: boolean;
	verifyLoading: boolean;
};

const ItemInfoBox = ({
	text,
	icon,
	width,
	isHighlighted,
	tooltipMessage = '',
}: ItemInfoBoxProps) => {
	return (
		<Tooltip label={tooltipMessage} openDelay={300}>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				backgroundColor="#f7f6f3"
				width={width}
				height="1.8rem"
				border="1px solid"
				borderRadius="2px"
				textStyle="sans.xs"
				fontSize="0.8rem"
				marginRight="1rem"
				marginBottom="0 !important"
				color={isHighlighted ? '#fff' : 'light.font.gray.400'}
				bg={isHighlighted ? '#666' : 'light.theme.gray.200'}
				borderColor={isHighlighted ? '#6a6a6a' : 'light.border.gray.200'}
			>
				{/* {icon ? icon() : null} */}
				{icon ? (
					<Box paddingRight="5px" display="flex" alignItems="center">
						{icon()}
					</Box>
				) : null}
				<Box>{text}</Box>
			</Box>
		</Tooltip>
	);
};

const Item = ({
	data,
	category,
	isHighlighted,
	handleItemClick,
	handleToggleClick,
	allowToggle,
	showCollisionData,
	verifyLoading,
}: ItemSectionProps) => {
	const textRef = useRef<HTMLDivElement | null>(null);
	const [isOverflown, setIsOverflown] = useState(false);
	useEffect(() => {
		const element = textRef.current;
		if (element) {
			setIsOverflown(element.scrollWidth! > element.clientWidth!);
		}
	}, []);

	const collisionScore = data.meanCollisionScore
		? Math.round(Math.abs(data.meanCollisionScore) * 100)
		: 0;

	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="space-between"
			border="1px solid"
			borderRadius="2px"
			backgroundColor="#fff"
			marginBottom="10px !important"
			onClick={handleItemClick}
			// onClick={e => handleItemClick(e, category, file)}
			opacity={data.public ? 1 : 0.5}
			// New
			cursor={data.public ? 'pointer' : 'default'}
			color={isHighlighted ? '#fff' : 'light.font.gray.400'}
			bg={isHighlighted ? '#555' : '#fff'}
			borderColor={isHighlighted ? '#6a6a6a' : 'light.border.gray.200'}
		>
			<Tooltip
				label={
					Array.isArray(data.description)
						? data.description[0]
						: data.description
				}
				placement="right"
				openDelay={700}
				isDisabled={!isOverflown}
			>
				<Text
					ref={textRef}
					isTruncated
					textStyle="sans.xs"
					padding="10px 20px"
					// maxWidth="60%"
				>
					{Array.isArray(data.description)
						? data.description[0]
						: data.description}
				</Text>
			</Tooltip>

			<Box display="flex" alignItems="center">
				{'samples' in data && data?.samples?.length > 0 ? (
					<ItemInfoBox
						text={`${data?.samples.length} Samples`}
						isHighlighted={isHighlighted}
						width="6rem"
					/>
				) : null}
				{showCollisionData ? (
					<ItemInfoBox
						text={
							verifyLoading ? `Verifying` : `${collisionScore}% Clash`
						}
						isHighlighted={isHighlighted}
						width="7rem"
						tooltipMessage="How similar this is to what you've already taught before."
						icon={() => {
							return verifyLoading ? (
								<Spinner
									size="xs"
									color={
										isHighlighted ? '#fff' : 'light.font.gray.400'
									}
								/>
							) : (
								<CircularProgress
									// value={file.collisionScore}
									value={collisionScore}
									size="14px"
									trackColor={
										isHighlighted
											? '#7a7a7a'
											: 'light.border.gray.200'
									}
									color={collisionScore > 80 ? '#B90000' : '#059D42'}
								/>
							);
						}}
					/>
				) : null}
				{allowToggle ? (
					<Switch
						size="sm"
						isChecked={data.public}
						colorScheme="gray"
						marginBottom="0 !important"
						marginRight="1rem"
						onChange={(e) => {
							handleToggleClick(e);
						}}
					/>
				) : null}
			</Box>
		</Box>
	);
};

const ListSection = ({
	data,
	title,
	category,
	selectedFile,
	handleItemClick,
	handleToggleClick,
	allowToggle,
	showCollisionData,
	verifyLoading,
}: ListSectionProps) => {
	return (
		<>
			{data.length > 0 ? (
				<AccordionItem border="none !important" paddingInline={0}>
					<h2>
						<AccordionButton
							_focus={{
								boxShadow: 'none !important',
								borderColor: 'transparent',
							}}
							paddingInline={0}
							color="light.font.gray.400"
						>
							<Box
								as="span"
								flex="1"
								textAlign="left"
								textStyle="sans.xs"
								color="light.font.gray.400"
								fontSize="lg"
							>
								{title}
							</Box>
							<AccordionIcon />
						</AccordionButton>
					</h2>
					<AccordionPanel p={0}>
						{data.map((d) => {
							return (
								<React.Fragment key={d.id}>
									<Item
										category={category}
										data={d}
										isHighlighted={selectedFile === d.id}
										handleItemClick={(e: any) => {
											// Only way I can figure rn to stop toggle event from triggering this as well.
											// Thanks Alabhya
											if (
												['SPAN', 'INPUT'].includes(
													(e.target as HTMLElement).tagName
												)
											) {
												return;
											}
											handleItemClick(d.id);
										}}
										handleToggleClick={(e: any) => {
											handleToggleClick(d.id);
										}}
										allowToggle={allowToggle}
										showCollisionData={showCollisionData}
										verifyLoading={verifyLoading}
									/>
								</React.Fragment>
							);
						})}
					</AccordionPanel>
				</AccordionItem>
			) : null}
		</>
	);
};

const FilesList = ({
	recipes,
	steps,
	docs,
	selectedFile,
	handleItemClick,
	handleToggleClick,
	allowToggle,
	collisionData,
	showCollisionData,
	verifyLoading,
}: FileListProps) => {
	return (
		<>
			<Accordion
				allowToggle
				allowMultiple
				border="none"
				defaultIndex={[0, 1, 2]}
				outline="none"
			>
				<ListSection
					data={recipes.map((r) => {
						if (!collisionData || !showCollisionData) return r;
						return {
							...r,
							meanCollisionScore: collisionData.recipes[r.id],
						};
					})}
					title="Recipes:"
					category="recipe"
					selectedFile={selectedFile}
					handleItemClick={(id: string) => handleItemClick('recipe', id)}
					handleToggleClick={(id: string) =>
						handleToggleClick('recipe', id)
					}
					allowToggle={allowToggle}
					showCollisionData={showCollisionData}
					verifyLoading={verifyLoading}
				/>
				<ListSection
					data={steps.map((s) => {
						if (!collisionData || !showCollisionData) return s;
						return {
							...s,
							meanCollisionScore: collisionData?.steps[s.id],
						};
					})}
					title="Steps:"
					category="step"
					selectedFile={selectedFile}
					handleItemClick={(id: string) => handleItemClick('step', id)}
					handleToggleClick={(id: string) => handleToggleClick('step', id)}
					allowToggle={allowToggle}
					showCollisionData={showCollisionData}
					verifyLoading={verifyLoading}
				/>
				<ListSection
					data={docs.map((d, i) => {
						if (!collisionData || !showCollisionData) return d;
						return { ...d, meanCollisionScore: collisionData.docs[i] };
					})}
					title="Documents:"
					category="document"
					selectedFile={selectedFile}
					handleItemClick={(id: string) => handleItemClick('document', id)}
					handleToggleClick={(id: string) =>
						handleToggleClick('document', id)
					}
					allowToggle={allowToggle}
					showCollisionData={showCollisionData}
					verifyLoading={verifyLoading}
				/>
			</Accordion>
		</>
	);
};

export const SkillVisualizer = ({
	skillData,
	collisionData,
	verifyLoading = false,
	setSkillData,
	allowToggle,
	showPreview,
	showCollisionData = false,
}: SkillVisualizerProps) => {
	const [selectedFile, setSelectedFile] = useState<string>('');

	const [viewerData, setViewerData] = useState<any>({
		samples: [],
		index: 0,
		type: '',
	});

	const { recipes, steps, docs } = skillData;

	const handleItemClick = (category: string, id: string) => {
		setSelectedFile(id);

		switch (category) {
			case 'recipe': {
				setViewerData({
					samples: skillData['recipes']
						.find((r) => r.id === id)
						?.samples.map((s) => s.content),
					index: 0,
					type: 'recipe',
				});
				break;
			}
			case 'step': {
				setViewerData({
					samples: skillData['steps']
						.find((s) => s.id === id)
						?.samples.map((s) => s.content),
					index: 0,
					type: 'step',
				});
				break;
			}
			case 'document': {
				setViewerData({
					samples: [
						skillData['docs'].find((d) => d.id === id)?.content || '',
					],
					index: 0,
					type: 'document',
				});
			}
		}
	};

	const handleToggleClick = (category: string, id: string) => {
		// If the switch is toggled on an item which is selected, then deselect the item. Don't need to check if the switch is in state false because the item cannot be selected in that state. Meaning this will only apply for the case where the switch is in true state and the user toggles it to false

		setSkillData((data: SkillData) => {
			let key: 'recipes' | 'steps' | 'docs';
			switch (category) {
				case 'recipe':
					key = 'recipes';
					break;
				case 'step':
					key = 'steps';
					break;
				case 'document':
					key = 'docs';
					break;
				default:
					key = 'recipes';
			}

			const items = data[key];
			const newItems = items.map((item) => {
				if (item.id === id) {
					return {
						...item,
						public: !item.public,
					};
				}

				return item;
			});

			return {
				...data,
				[key]: newItems,
			};
		});
	};

	const handlePreviouSampleClick = () => {
		setViewerData((data: any) => {
			const s = data.index;
			return {
				...data,
				index: s !== 0 ? s - 1 : s,
			};
		});
	};

	const handleNextSampleClick = () => {
		setViewerData((data: any) => {
			const s = data.index;
			const newData = {
				...data,
				index: s + 1 < viewerData.samples.length ? s + 1 : s,
			};
			return newData;
		});
	};

	return (
		<>
			<Grid
				display="grid"
				templateColumns={
					showPreview ? 'minmax(0, 2fr) minmax(0, 1fr)' : '1fr'
				}
				gridGap="1rem"
				position="relative"
				width="100%"
				pb="20px"
				// bg='pink'
			>
				<GridItem>
					<FilesList
						recipes={recipes}
						steps={steps}
						docs={docs}
						collisionData={collisionData}
						showCollisionData={showCollisionData}
						verifyLoading={verifyLoading}
						selectedFile={selectedFile}
						handleItemClick={handleItemClick}
						handleToggleClick={handleToggleClick}
						allowToggle={allowToggle}
					/>
				</GridItem>
				{showPreview ? (
					<GridItem>
						<Box
							display="flex"
							flexDirection="column"
							flexShrink={0}
							height="400px"
							position="sticky"
							top="0px"
						>
							<Box
								display="flex"
								alignItems="center"
								justifyContent="space-between"
								marginBottom="8px"
								textStyle="sans.xs"
								color="light.font.gray.400"
							>
								<Text fontSize="lg" pt="8px">
									Preview:
								</Text>
								{viewerData.samples.length > 1 ? (
									<Box userSelect="none">
										<ChevronLeftIcon
											opacity={viewerData.index === 0 ? 0.4 : 1}
											cursor={
												viewerData.index === 0
													? 'default'
													: 'pointer'
											}
											onClick={handlePreviouSampleClick}
										/>{' '}
										{viewerData.index + 1} /{' '}
										{viewerData.samples.length}
										<ChevronRightIcon
											opacity={
												viewerData.index ===
												viewerData.samples.length - 1
													? 0.4
													: 1
											}
											cursor={
												viewerData.index ===
												viewerData.samples.length - 1
													? 'default'
													: 'pointer'
											}
											onClick={handleNextSampleClick}
										/>
									</Box>
								) : null}
							</Box>
							<FileContentDisplayer
								value={
									viewerData.type === 'step'
										? JSON.stringify(
												JSON.parse(
													viewerData.samples[viewerData.index]
												),
												null,
												2
										  )
										: viewerData.samples[viewerData.index] || '[]'
								}
								editable={false}
								textAreaProps={{
									resize: 'none',
									color: '#484848',
									fontFamily: 'mono',
									padding: '1rem 1.5rem',
								}}
							/>
						</Box>
					</GridItem>
				) : null}
			</Grid>
		</>
	);
};
