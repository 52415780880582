import React from 'react';
import { Box } from '@chakra-ui/react';
import VerticalMenu from '../../../../library/menus/Vertical';
import { useParams } from 'react-router-dom';
import GeneralSettings from './General';
import DesktopSettings from './Desktop';
import EditorSettings from './Editor';
import IntegrationsSettings from './Integrations';
import Developers from './Developers';
import Team from './Team/Team';
import { useSelector } from 'react-redux';
import useSetSlugFromParams from '../../../../util/ProfileSlug/useSetSlugFromParams';
import Billing from './Billing/Billing';
import isElectron from 'is-electron';
import AboutSection from './About/About';

const SettingsList = {
	general: <GeneralSettings />,
	desktop: <DesktopSettings />,
	// editor: <EditorSettings />,
	integrations: <IntegrationsSettings />,
	developers: <Developers />,
	team: <Team />,
	billing: <Billing />,
	about: <AboutSection />
};

const SettingsSection = () => {
	useSetSlugFromParams();
	// run function on click

	const [selectedItem, setSelected] = React.useState('general');
	const { id } = useParams();

	const { selectedProfile } = useSelector(
		/**
		 *
		 * @param {import("../../../../redux/reducers/types/profileReducer").ProfilesState} state
		 * @returns
		 */
		(state) => ({
			selectedProfile: state.profiles.profiles[state.profiles.selected],
		})
	);

	const settingsMenu = {
		general: {
			label: 'general',
			redirect: '/settings/general',
		},
		desktop: {
			label: 'desktop',
			redirect: '/settings/desktop',
		},
		// editor: {
		// 	label: 'editor',
		// 	redirect: '/settings/editor',
		// },
		integrations: {
			label: 'integrations',
			redirect: '/settings/integrations',
		},
		billing: {
			label: 'billing',
			redirect: '/settings/billing',
		},
		developers: {
			label: 'developers',
			redirect: '/settings/developers',
		},
		about: {
			label: 'about',
			redirect: '/settings/about'
		}
	};

	if (!isElectron()) {
		delete settingsMenu['desktop'];
		delete settingsMenu['general'];
	}

	if (selectedProfile.isTeam) {
		settingsMenu['team'] = {
			label: 'team',
			redirect: '/settings/team',
		};
	}

	const settingsMenuList = Object.values(settingsMenu);

	React.useEffect(() => {
		setSelected(id);
	}, [id]);

	return (
		<Box display="flex" height="100%">
			<Box
				borderRight="solid 1px"
				paddingRight="30px"
				borderColor="light.border.gray.200"
				alignSelf="flex-start"
			>
				<Box
					textStyle="sans.md"
					paddingBottom="20px"
					color="light.font.gray.400"
				>
					Personal Settings
				</Box>
				<VerticalMenu
					menuItemList={settingsMenuList}
					setSelected={setSelected}
					selectedItem={selectedItem}
				/>
			</Box>
			<Box paddingX="20px" width="800px" borderRadius="10px">
				{SettingsList[selectedItem]}
			</Box>
		</Box>
	);
};

export default SettingsSection;
