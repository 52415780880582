import React from 'react';
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverBody,
	PopoverArrow,
	IconButton,
	Tooltip,
	Button,
} from '@chakra-ui/react';

import { LinkIcon, CopyIcon } from '@chakra-ui/icons';
import { BsCode } from 'react-icons/bs';

const CopyPopOver = ({ onURLCopy, onCURLCopy }) => {
	return (
		<Popover arrowPadding={0}>
			{({ onClose }) => (
				<>
					<PopoverTrigger>
						<IconButton
							aria-label="copy"
							icon={
								<Tooltip arrowPadding={15} label="Copy">
									<CopyIcon />
								</Tooltip>
							}
							size="lg"
							color="light.font.gray.300"
							height="fit-content"
							style={{
								background: 'none',
							}}
							_focus={{ border: 'none' }}
						/>
					</PopoverTrigger>

					<PopoverContent
						width="78px"
						style={{
							border: '1px solid',
							borderColor: '#D8D8D8',
							borderRadius: '2px',
						}}
						_focus={{ border: 'none' }}
					>
						<PopoverArrow
							style={{
								borderTop: '1px solid',
								borderLeft: '1px solid',
								borderColor: '#D8D8D8',
							}}
						/>
						<PopoverBody
							style={{
								display: 'flex',
								flexDirection: 'column',
								padding: '5px',
							}}
						>
							<Button
								variant="unstyled"
								height="fit-content"
								fontSize={12}
								paddingY="4%"
								marginBottom={1.5}
								bg="light.theme.gray.200"
								style={{
									display: 'flex',
									border: '1px solid',
									borderColor: '#D8D8D8',
									borderRadius: '2px',
								}}
								leftIcon={<LinkIcon />}
								_focus={{ border: 'none' }}
								onClick={() => {
									onURLCopy();
									onClose();
								}}
							>
								URL
							</Button>
							<Button
								variant="unstyled"
								height="fit-content"
								fontSize={12}
								paddingY="4%"
								bg="light.theme.gray.200"
								style={{
									display: 'flex',
									border: '1px solid',
									borderColor: '#D8D8D8',
									borderRadius: '2px',
								}}
								leftIcon={<BsCode />}
								_focus={{ border: 'none' }}
								onClick={() => {
									onCURLCopy();
									onClose();
								}}
							>
								cURL
							</Button>
						</PopoverBody>
					</PopoverContent>
				</>
			)}
		</Popover>
	);
};

export default CopyPopOver;
