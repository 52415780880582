export const adjectives = [
	'Adoring',
	'Amazing',
	'Awesome',
	'Beautiful',
	'Blissful',
	'Bold',
	'Brave',
	'Busy',
	'Charming',
	'Clever',
	'Compassionate',
	'Competent',
	'Condescending',
	'Confident',
	'Cool',
	'Cranky',
	'Crazy',
	'Dazzling',
	'Determined',
	'Distracted',
	'Dreamy',
	'Eager',
	'Ecstatic',
	'Elastic',
	'Elated',
	'Elegant',
	'Eloquent',
	'Epic',
	'Exciting',
	'Fervent',
	'Festive',
	'Flamboyant',
	'Focused',
	'Friendly',
	'Frosty',
	'Funny',
	'Gallant',
	'Gifted',
	'Goofy',
	'Gracious',
	'Great',
	'Happy',
	'Hardcore',
	'Heuristic',
	'Hopeful',
	'Hungry',
	'Infallible',
	'Inspiring',
	'Intelligent',
	'Interesting',
	'Jolly',
	'Jovial',
	'Keen',
	'Kind',
	'Laughing',
	'Lucid',
	'Magical',
	'Modest',
	'Musing',
	'Mystifying',
	'Nervous',
	'Nice',
	'Nifty',
	'Nostalgic',
	'Objective',
	'Optimistic',
	'Peaceful',
	'Pedantic',
	'Pensive',
	'Practical',
	'Priceless',
	'Quirky',
	'Quizzical',
	'Recursing',
	'Relaxed',
	'Reverent',
	'Serene',
	'Sharp',
	'Silly',
	'Sleepy',
	'Stoic',
	'Strange',
	'Stupefied',
	'Suspicious',
	'Sweet',
	'Trusting',
	'Unruffled',
	'Upbeat',
	'Vibrant',
	'Vigilant',
	'Vigorous',
	'Wizardly',
	'Wonderful',
	'Zealous',
	'Zen',
];

export const scientists = [
	'Agnesi',
	'Albattani',
	'Allen',
	'Almeida',
	'Antonelli',
	'Archimedes',
	'Aryabhata',
	'Austin',
	'Babbage',
	'Banach',
	'Banzai',
	'Bardeen',
	'Bartik',
	'Bassi',
	'Beaver',
	'Bell',
	'Benz',
	'Bhabha',
	'Bhaskara',
	'Black',
	'Blackburn',
	'Blackwell',
	'Bohr',
	'Bohm',
	'Booth',
	'Borg',
	'Bose',
	'Bouman',
	'Boyd',
	'Brahmagupta',
	'Brattain',
	'Brown',
	'Buck',
	'Burnell',
	'Cannon',
	'Carson',
	'Cartwright',
	'Carver',
	'Cerf',
	'Chandrasekhar',
	'Chatelet',
	'Chatterjee',
	'Chaum',
	'Clarke',
	'Cohen',
	'Colden',
	'Cori',
	'Cray',
	'Curie',
	'Curran',
	'Darwin',
	'Davinci',
	'Dewdney',
	'Dhawan',
	'Diffie',
	'Dirac',
	'Driscoll',
	'Dubinsky',
	'Easley',
	'Edison',
	'Einstein',
	'Elion',
	'Ellis',
	'Engelbart',
	'Euclid',
	'Euler',
	'Faraday',
	'Feistel',
	'Fermat',
	'Fermi',
	'Feynman',
	'Franklin',
	'Gagarin',
	'Galileo',
	'Galois',
	'Ganguly',
	'Gates',
	'Gauss',
	'Germain',
	'Goldberg',
	'Goldstine',
	'Goldwasser',
	'Golick',
	'Goodall',
	'Gould',
	'Haibt',
	'Hamilton',
	'Haslett',
	'Hawking',
	'Heisenberg',
	'Hellman',
	'Hermann',
	'Herschel',
	'Hertz',
	'Hofstadter',
	'Hoover',
	'Hopper',
	'Hugle',
	'Ishizaka',
	'Jackson',
	'Jang',
	'Jemison',
	'Jennings',
	'Jepsen',
	'Joliot',
	'Jones',
	'Kalam',
	'Kapitsa',
	'Kare',
	'Keldysh',
	'Keller',
	'Kepler',
	'Khayyam',
	'Khorana',
	'Kilby',
	'Kirch',
	'Knuth',
	'Lalande',
	'Lamarr',
	'Lamport',
	'Leakey',
	'Leavitt',
	'Lederberg',
	'Lehmann',
	'Lewin',
	'Lichterman',
	'Liskov',
	'Lovelace',
	'Lumiere',
	'Mahavira',
	'Margulis',
	'Matsumoto',
	'Maxwell',
	'Mayer',
	'Mccarthy',
	'Mcclintock',
	'Mclaren',
	'Mclean',
	'Mcnulty',
	'Meitner',
	'Mendel',
	'Mendeleev',
	'Meninsky',
	'Merkle',
	'Mestorf',
	'Mirzakhani',
	'Montalcini',
	'Moore',
	'Morse',
	'Moser',
	'Murdock',
	'Napier',
	'Nash',
	'Neumann',
	'Newton',
	'Nightingale',
	'Nobel',
	'Noether',
	'Northcutt',
	'Noyce',
	'Panini',
	'Pare',
	'Pascal',
	'Pasteur',
	'Payne',
	'Perlman',
	'Pike',
	'Poincare',
	'Poitras',
	'Ptolemy',
	'Raman',
	'Ramanujan',
	'Rhodes',
	'Ride',
	'Ritchie',
	'Robinson',
	'Roentgen',
	'Rosalind',
	'Rubin',
	'Saha',
	'Sammet',
	'Sanderson',
	'Satoshi',
	'Shamir',
	'Shannon',
	'Shaw',
	'Shirley',
	'Shockley',
	'Shtern',
	'Sinoussi',
	'Snyder',
	'Solomon',
	'Spence',
	'Stonebraker',
	'Sutherland',
	'Swanson',
	'Swartz',
	'Swirles',
	'Taussig',
	'Tesla',
	'Tharp',
	'Thompson',
	'Torvalds',
	'Turing',
	'Varahamihira',
	'Vaughan',
	'Villani',
	'Visvesvaraya',
	'Volhard',
	'Wescoff',
	'Wilbur',
	'Wiles',
	'Williams',
	'Williamson',
	'Wilson',
	'Wing',
	'Wozniak',
	'Wright',
	'Wu',
	'Yalow',
	'Yonath',
];
