import React from 'react'
import {
    Flex
} from '@chakra-ui/react'

const StepSection = () => {
    return (
        <Flex>
            Steps
        </Flex>
    )
}

export default StepSection