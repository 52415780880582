import React, { useState, useEffect, useCallback } from 'react';
import update from 'immutability-helper';
import {
	Flex,
	Box,
	GridItem,
	Table as ChakraTable,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Checkbox,
	Input,
	InputGroup,
	InputLeftElement,
	Text,
	Button,
	Select,
	IconButton,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import _ from 'lodash';
import { useWorkspaceDashboardStore } from '../zustand';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import shallow from 'zustand/shallow';
import Fuse from 'fuse.js'

const PAGE_SIZE_OPTIONS = [5, 10, 20, 50, 100];

const TableCell = ({ value, truncateAfter }) => {
	return (
		<Td
			py="12px"
			alignItems="flex-start"
			verticalAlign="baseline"
            zIndex={200}
            borderTop='2px solid #EDF2F6'
		>
			<Text
				textStyle="sans.sm"
				fontSize="14px"
				fontWeight="400"
				minWidth="100px"
				noOfLines={truncateAfter > 0 ? truncateAfter : undefined}
				whiteSpace="normal"
				lineHeight="22px"
				mb="0px"
			>
				{value || ''}
			</Text>
		</Td>
	);
};

const TableComp = ({
    workspaceId,
	width,
	truncateAfter,
	componentId,
	handleTableRowClick,
    handleTableRowSelectToggle,
    actionButtonLabel,
}) => {
	// For dealing with row selection
	const [selected, setSelected] = useState({});
	const [allChecked, setAllChecked] = useState(false);

	// For fuzzy search
	const [searchInput, setSearchInput] = useState('');
	const [fuzzySearch, setFuzzySearch] = useState(null);

	// For pagination
	const [pageSize, setPageSize] = useState(10);
	const [pageIndex, setPageIndex] = useState(0);

	// Getting table data from zustand
	const rows = useWorkspaceDashboardStore(
		(state) => state?.dashboardData[workspaceId][componentId]?.rows || [],
		shallow
	);
	const columns = useWorkspaceDashboardStore(
		(state) => state?.dashboardData[workspaceId][componentId]?.columns || {},
		shallow
	);
	const [dataToDisplay, setDataToDisplay] = useState(rows);

	console.log('dashsock got new data', rows);

    const doSearch = useCallback((query) => {
        const results = fuzzySearch.search(query).map(x => x.item);
        setDataToDisplay(Object.values(results));
    }, [fuzzySearch])

	/**
	 * Performs a fuzzy search within the table data and sets
	 * the result to be displayed in the table
	 *
	 * @param {string} query
	 */

	const doSearchDebounced = useCallback(_.debounce(doSearch, 1000), [fuzzySearch]);

	function handleRowSelect(e, id, checked) {
		e.stopPropagation();
		e.preventDefault();
		setSelected(
			update(selected, {
				[id]: { $set: checked },
			})
		);
        handleTableRowSelectToggle(id, checked)
	}

	function handleUniversalCheckboxClick(checked) {
        handleTableRowSelectToggle('all', checked)
		setAllChecked(checked);
		if (!checked) {
			setSelected({});
		}
	}

	/**
	 * Whenever the table data changes, re-index it for
	 * fuzzy search.
	 */
	useEffect(() => {
		const fuzzyKeys = Object.keys(columns).map(
			(field) => `fields.${field}.value`
		);
        const fuzz = new Fuse(rows, {
            keys: fuzzyKeys
        })
		setFuzzySearch(fuzz);
	}, [rows]);

	/**
	 * Re-run the current search query if the table data
	 * changes.
	 */
	useEffect(() => {
        if (searchInput === '' || !fuzzySearch) {
            if (Array.isArray(rows)) {
                setDataToDisplay([...rows]);
            } else {
                setDataToDisplay([]);
            }
        } else {
            doSearchDebounced(searchInput);
        }

	}, [rows, searchInput, fuzzySearch]);

	if (!rows) {
		return null;
	}
	let fields = Object.keys(columns);

	// Variables to deal with pagination
	const firstVisibleEntryIdx = pageIndex * pageSize + 1;
	const lastVisibleEntryIdx = Math.min(
		(pageIndex + 1) * pageSize,
		dataToDisplay?.length || 0
	);
	const pagedEntries = dataToDisplay.slice(
		firstVisibleEntryIdx - 1,
		lastVisibleEntryIdx
	);

	return (
		<GridItem
			maxHeight="600px"
			// overflow='auto'
			colSpan={width}
			textStyle="sans.sm"
			display="flex"
			flexDir="column"
			height="100%"
			flex="1 1 auto"
            // bg='pink'
            // overflowX='auto'
		>
			{/* The search input */}
			<InputGroup 
                mb="24px"
                maxWidth="400px !important"
                position='sticky'
                inset='left'
                left='0'
            >
				<InputLeftElement
					pointerEvents="none"
					children={<SearchIcon color="gray.300" />}
				/>
				<Input
					onChange={(e) => setSearchInput(e.target.value)}
					placeholder="Search"
					variant="filled"
				/>
			</InputGroup>

			{/* The table */}
			<Flex>
				<TableContainer
                    overflowX='unset'
					// bg='tomato'
					width='100%'
                    // overflowY='unset'
                >
					<ChakraTable size="sm" variant='unstyled'>
						<Thead>
							<Tr
                                position='sticky'
                                top='0'
                            >
								<Th
									position='sticky'
									left='0'
									bg='#fff'
								>
									<Checkbox
										onChange={(e) =>
											handleUniversalCheckboxClick(e.target.checked)
										}
										isChecked={allChecked}
									/>
								</Th>
								{fields.map((field, idx) => (
									<Th 
										textTransform={'none'}
										fontSize=''
									>
										{field}
									</Th>
								))}
                                {actionButtonLabel && (
                                    <Th 
                                        textTransform={'none'}
                                        position='sticky'
                                        right='0'
                                        bg='#fff'
                                    >
                                        Action
                                    </Th>
                                )}
							</Tr>
						</Thead>

						<Tbody color="light.font.gray.400" mb="80px">
							{pagedEntries &&
								pagedEntries.map((rowData, idx) => (
									<Tr
                                        position='relative'
									>
										<Td 
                                            width="20px" 
                                            verticalAlign="baseline" 
                                            borderTop='2px solid #EDF2F6'
											position='sticky'
											left='0'
											bg='#fff'
                                        >
											<Checkbox
												onChange={(e) =>
													handleRowSelect(
														e,
														rowData._identifier.value,
														e.target.checked
													)
												}
												isChecked={
													allChecked ||
													selected[rowData._identifier.value]
												}
											/>
										</Td>
										{Object.keys(columns).map((col) => {
											const val = rowData.fields[col];
											if (!val) {
												return (
													<TableCell
														value=""
														truncateAfter={-1}
														// onClick={() =>
														// 	handleTableRowClick(rowData)
														// }
													/>
												);
											}
											return (
												<TableCell
													truncateAfter={truncateAfter}
													value={val?.value}
													// onClick={() =>
													// 	handleTableRowClick(rowData)
													// }
												/>
											);
										})}

                                        {actionButtonLabel && (
                                            <Td
                                                position='sticky'
                                                right='0'
                                                zIndex={500}
                                                boxShadow='xs'
                                                borderLeft='2px solid #EDF2F6'
                                                borderTop='2px solid #EDF2F6'
                                                bg='#F9F9F9'
                                                height='100% !important'
                                                borderBottom='none'
                                                verticalAlign='baseline'
                                            >
                                                <Flex
                                                    height='100%'
                                                    width='100%'
													// justifyContent='center'
                                                >
                                                    <Button
                                                        colorScheme='button.dashboardTableAction'
														variant='solid'
														color='#666'
														border='1px solid #ddd'
                                                        size='sm'
                                                        borderRadius='3px'
                                                        onClick={() => handleTableRowClick(actionButtonLabel, rowData)}
                                                    >
                                                        {actionButtonLabel}
                                                        {/* Bruh */}
                                                    </Button>
                                                </Flex>
                                            </Td>
                                        )}
									</Tr>
								))}
						</Tbody>
					</ChakraTable>
				</TableContainer>
			</Flex>

			{/* Pagination controls */}
			<Flex
				mt="16px"
				pb="16px"
				alignItems="center"
				justifyContent="space-between"
				px="8px"
                position='sticky'
                inset='left'
                left='0'
                bottom='0'
                bg='white'
                // bg='pink' 
                zIndex={1000}
                transform='translateY(1px)' // Trying to hide table content under this component
			>
				<Flex alignItems="center">
					<Box>
						<Select
							defaultValue={10}
							size="xs"
							borderRadius="3px"
							onChange={(e) => setPageSize(parseInt(e.target.value))}
						>
							{PAGE_SIZE_OPTIONS.map((size) => (
								<option value={size}>{size}</option>
							))}
						</Select>
					</Box>
					<Box
						fontSize="14px"
						fontWeight="400"
						color="light.font.gray.300"
						ml="4px"
						mt="2px"
					>
						<i>entries per page</i>
					</Box>
				</Flex>
				<Flex>
					<Box
						fontSize="14px"
						fontWeight="400"
						color="light.font.gray.300"
						mr="12px"
						mt="2px"
					>
						Showing entries <b>{firstVisibleEntryIdx}</b> -{' '}
						<b>{lastVisibleEntryIdx}</b> of{' '}
						<b>{dataToDisplay?.length || 0}</b>
					</Box>
					<IconButton
						icon={<FiChevronLeft />}
						aria-label="Previous"
						size="xs"
						borderRadius="3px"
						mr="4px"
						onClick={() => setPageIndex(pageIndex - 1)}
						isDisabled={pageIndex === 0}
					/>
					<IconButton
						icon={<FiChevronRight />}
						aria-label="Next"
						size="xs"
						borderRadius="3px"
						onClick={() => setPageIndex(pageIndex + 1)}
						isDisabled={lastVisibleEntryIdx === dataToDisplay?.length}
					/>
				</Flex>
			</Flex>
		</GridItem>
	);
};

export const Table = React.memo(TableComp);
