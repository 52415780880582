import { Flex, Img } from '@chakra-ui/react';
import React from 'react';
import { FiPackage } from 'react-icons/fi';

/**
 *
 * @param {{ thumbnailUrl?: string }} param0
 */
const ModuleIcon = ({ thumbnailUrl }) => {
	if (thumbnailUrl) {
		return (
			<Flex justifyContent="start" alignItems="center">
				<Img
					src={thumbnailUrl}
					maxHeight="30px"
					maxWidth="30px"
					marginRight="16px"
				/>
			</Flex>
		);
	} else {
		return (
			<Flex justifyContent="start" alignItems="center" marginRight="16px">
				<FiPackage size="30px" color="#656565" />
			</Flex>
		);
	}
};

export default ModuleIcon;
