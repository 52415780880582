import React, { useEffect, useState } from 'react';
import {
	Flex,
	Box,
	Img,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
} from '@chakra-ui/react';
import { Tag } from '../../Workspaces/WorkspaceList/WorkspaceCard/Tag';
import ConfigureButton from './ConfigureButton';
import getConfigProfiles from '../../../../../functions/configuration/getConfigProfiles';
import ConfigProfileList from '../ConfigProfile/ConfigProfileList';
import InfoTag from '../ModulePage/ModuleDetails/InfoTag';
import useProfileSlug from '../../../../../hooks/useProfileSlug';

const ModuleConfig = ({
	module,
	onSelectProfile,
	selectedProfile,
	selectMode = true,
}) => {
	const slug = useProfileSlug();
	const [configProfiles, setConfigProfiles] = useState([]);
	const [configProfilesLoading, setConfigProfilesLoading] = useState(false);
	// const [selectedProfile, setSelectedProfile] = useState(null)

	useEffect(() => {
		setConfigProfilesLoading(true);
		getConfigProfiles({
			slug: slug,
			moduleId: module._id,
			moduleName: module.packageName,
			brainId: '',
		})
			.then((res) => {
				setConfigProfilesLoading(false);
				setConfigProfiles(res);
			})
			.catch((e) => {
				console.error('error fetching config profiles:', e);
			});
	}, [slug]);

	function handleProfileSelect(profile) {
		console.log('cps ptofileSelect', profile);
		// setSelectedProfile(profile)
		onSelectProfile(profile);
	}

	return (
		<AccordionItem
			border="1.5px solid #e7e7e7"
			// borderColor='light.border.gray.100' Not working for some reason???
			outline="none"
			_focus={{
				outline: 'none',
			}}
			bg="light.theme.gray.50"
		>
			<Flex width="100%" alignItems="center" padding="10px 20px">
				<Flex ml="6px">
					<Img src={module.thumbnail} width="40px" />
				</Flex>
				<Box flex="1 1 auto" ml="16px">
					<Box
						// bg='yellow'
						textAlign="left"
						color="light.font.gray.400"
						textStyle="sans.sm"
					>
						{module.name}
					</Box>
					<Box>
						<Tag
							bg="light.theme.gray.200"
							label={module.currentVersion}
							// maxWidth="60px"
							float="left"
							mt="5px"
							fontFamily="Ubuntu, sans-serif"
							fontSize="12px"
						/>
					</Box>
				</Box>
				<Flex mr="16px">
					<AccordionButton
						// padding='20px 20px'
						border="none"
						outline="none"
						_focus={{
							outline: 'none',
						}}
						padding="0px"
					>
						<ConfigureButton
							configured={!!selectedProfile}
							showState={selectMode}
						/>
					</AccordionButton>
				</Flex>
			</Flex>
			<AccordionPanel
				bg="light.theme.gray.100"
				// padding='20px 32px'
				// pl='176px'
				pl="0px"
				pt="20px"
				pb="20px"
				pr="60px"
				// pr='225px'
				// pr='300px'
				// bg='yellow'
				display="flex"
			>
				<Box
					// bg='tomato'
					width="176px"
					paddingX="24px"
					// transform='translateY(-4px)'
				>
					{/* asd */}
					<InfoTag label="" align="right">
						{/* Authenticate this module to interact with the app */}
						Click '<b>+ Connect</b>' to authenticate with this service
					</InfoTag>
				</Box>
				<Box flex="1 1 auto">
					<ConfigProfileList
						selectMode={selectMode}
						configProfiles={configProfiles}
						module={module}
						onSelectProfile={(profile) => handleProfileSelect(profile)}
						selectedProfile={selectedProfile}
						loading={configProfilesLoading}
					/>
				</Box>
			</AccordionPanel>
		</AccordionItem>
	);
};

export default ModuleConfig;
