import React from 'react';

//Libs
import {
	Box,
	useColorMode,
	CircularProgress,
	Icon,
	Tooltip,
} from '@chakra-ui/react';
import dotProp from 'dot-prop-immutable';

import { v4 as uuidv4 } from 'uuid';

//Redux
import { connect } from 'react-redux';
import {
	setSearchBarLoadingStatus,
	setSearchBarSubmitProgress,
	setMiddleSearchModeAndType,
	toggleSearchDisplayMode,
	setMiddlePlaceholderText,
	toggleCardsVisibility,
	setTopIntentArray,
	setTopCurrentPlaceholderId,
} from '../../../redux/actions';
//Components
import IntentPlaceholderInput from './IntentPlaceholderInput';

const insertRefsAndNormalize = (intentArray) => {
	let intents = { byId: {}, allIds: [] };
	intentArray.map((intentObject, i) => {
		let randomId = uuidv4();
		intents.byId[randomId] = intentObject;
		intents.byId[randomId].ref = React.createRef();
		intents.allIds.push(randomId);
	});

	return intents;
};

const IntentInputItem = React.forwardRef(
	({ id, item, onInputChange, onNext, onSelect, onCancel, onSubmit }, ref) => {
		const color = {
			light: 'card_ui.font_light.200',
			dark: 'card_ui.font_dark.200',
		};
		const { colorMode } = useColorMode();

		return item.type === 'text' ? (
			<Box
				color={color[colorMode]}
				fontSize="1.1rem"
				mr={2}
				whiteSpace="nowrap"
				mb={1}
				fontFamily="body"
				mt={1}
			>
				{item.value}
			</Box>
		) : (
			<IntentPlaceholderInput
				ref={ref}
				item={item}
				onSelect={onSelect}
				onNext={onNext}
				placeholderId={id}
				onCancel={onCancel}
				onSubmit={onSubmit}
				onInputChange={onInputChange}
			/>
		);
	}
);

/**
 * Intent Input for inputting in search bar
 */

const IntentInput = ({
	intentArray,
	onCancel,
	onSubmit,
	submitProgress,
	intentObjectArrayRedux,
	loadingStatus,
	setSearchBarLoadingStatus,
	setSearchBarSubmitProgress,
	setMiddleSearchModeAndType,
	setMiddlePlaceholderText,
	toggleSearchDisplayMode,
	toggleCardsVisibility,
	setTopIntentArray,
	setTopCurrentPlaceholderId,
}) => {
	const [current, setCurrent] = React.useState('');
	let [intentObjectArray, setArray] = React.useState(
		insertRefsAndNormalize(intentArray || [])
	);

	const handleSubmit = () => {
		// setSubmitted(true);
		// setSearchBarLoadingStatus(null);
		onSubmit(intentArraytoJSON(intentObjectArrayRedux.byId));
	};

	React.useEffect(() => {
		intentObjectArrayRedux.allIds.map((id) => {
			if (intentObjectArray.byId[id]) {
				intentObjectArray.byId[id].value =
					intentObjectArrayRedux.byId[id].value;
				intentObjectArray.byId[id].meta =
					intentObjectArrayRedux.byId[id].meta;
			}
		});
	}, [intentObjectArrayRedux]);

	React.useEffect(() => {
		if (numberOfInputs(intentObjectArray) === 0) {
			handleSubmit();
		} else {
			onNext();
		}
	}, []);

	const intentArraytoJSON = (intentArray) => {
		let newArray = {};
		for (let key in intentArray) {
			if (intentArray[key].type === 'placeholder') {
				console.log(intentArray, intentArray[key]);
				newArray[intentArray[key].key] = {
					value: intentArray[key].value,
					meta: intentArray[key].meta,
				};
			}
		}
		// console.log(newArray, intentArray);
		return newArray;
	};

	const numberOfInputs = (theArray) => {
		var i = 0;
		theArray.allIds.map((intent) => {
			if (theArray.byId[intent].type === 'placeholder') {
				i++;
			}
		});

		return i;
	};

	//   }, [current]);
	const onNext = () => {
		//This helps cycle through the intents array and select the next input
		// console.log(numberOfInputs(intentObjectArray));
		var i;
		if (numberOfInputs(intentObjectArray) > 1) {
			if (current === '') {
				i = 0;
				while (i < intentObjectArray.allIds.length) {
					if (
						intentObjectArray.byId[intentObjectArray.allIds[i]].type ===
						'placeholder'
					) {
						break;
					}
					i++;
				}
			} else {
				i =
					intentObjectArray.allIds.indexOf(current) === -1
						? 0
						: intentObjectArray.allIds.indexOf(current);

				while (i < intentObjectArray.allIds.length) {
					if (
						intentObjectArray.byId[intentObjectArray.allIds[i]].type ===
							'placeholder' &&
						i !== intentObjectArray.allIds.indexOf(current)
					) {
						break;
					} else if (
						intentObjectArray.byId[intentObjectArray.allIds[i]].type !==
							'placeholder' &&
						i === intentObjectArray.allIds.length - 1
					) {
						console.log(i);
						i = -1;
					} else if (
						i === intentObjectArray.allIds.indexOf(current) &&
						i === intentObjectArray.allIds.length - 1
					) {
						i = -1;
					}
					i++;
				}
			}
		} else {
			i = 0;
			while (i < intentObjectArray.allIds.length) {
				if (
					intentObjectArray.byId[intentObjectArray.allIds[i]].type ===
					'placeholder'
				) {
					break;
				}
				i++;
			}
		}
		intentObjectArray.byId[intentObjectArray.allIds[i]].ref.current.select();

		setMiddlePlaceholderText(
			intentObjectArray.byId[intentObjectArray.allIds[i]].value
		);
		setTopCurrentPlaceholderId(intentObjectArray.allIds[i]);
		setMiddleSearchModeAndType(
			'placeholder',
			intentObjectArray.byId[intentObjectArray.allIds[i]].keyType
		);
		toggleSearchDisplayMode('expanded');
		setTopIntentArray(intentObjectArray);
		setCurrent(intentObjectArray.allIds[i]);
	};

	const onInputChange = (id) => (e) => {
		let state = dotProp.set(intentObjectArray, `byId.${id}.value`, e);
		setTopIntentArray(state);
		setArray(state);
	};

	return (
		<>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				width={intentObjectArray.allIds.length > 4 ? '40vw' : '100%'}
				pl={1}
				onClick={() => {
					onNext();
				}}
			>
				<Box
					mt={1}
					display="flex"
					alignItems="center"
					flexWrap={intentObjectArray.allIds.length > 4 ? 'wrap' : 'wrap'}
				>
					{intentObjectArray.allIds.map((item, i) => {
						return (
							<IntentInputItem
								item={intentObjectArray.byId[item]}
								key={i}
								id={item}
								onNext={onNext}
								onCancel={() => {
									onCancel();
									setSearchBarLoadingStatus(null);
									setMiddleSearchModeAndType('intents', 'commands');
									toggleSearchDisplayMode('collapsed');
									toggleCardsVisibility(true);
								}}
								onSelect={() => setCurrent(item)}
								onInputChange={onInputChange(item)}
								ref={intentObjectArray.byId[item].ref}
								onSubmit={handleSubmit}
								lastItem={i === intentObjectArray.allIds.length - 1}
							/>
						);
					})}
				</Box>
			</Box>
		</>
	);
};

// <SubmitTimer
//   onCancel={onCancel}
//   timerProgress={timerProgress}
//   progressVisible={progressVisible}
//   submitted={submitted}
// />

/**
 * Map State To Progress
 * @param {state} state
 */

const mapStateToProps = (state) => {
	let { submitProgress, loadingStatus, intentObjectArray } =
		state.cardUI.centerSearch.top;
	return {
		submitProgress,
		loadingStatus,
		intentObjectArrayRedux: intentObjectArray,
	};
};

export default connect(mapStateToProps, {
	setSearchBarLoadingStatus,
	setSearchBarSubmitProgress,
	setMiddleSearchModeAndType,
	toggleSearchDisplayMode,
	setMiddlePlaceholderText,
	toggleCardsVisibility,
	setTopIntentArray,
	setTopCurrentPlaceholderId,
})(IntentInput);
