import React, { useCallback } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { Box, createStandaloneToast } from '@chakra-ui/react';
import theme from '../../../../library/theme';
import { useStore } from '../../zustand';

// const handleStyles = {
// 	outline: 'solid 2px #545454',
// 	borderRadius: '10px',
// 	background: '#fff',
// 	width: '8px',
// 	height: '8px',
// };

const handleStyles = {
	outline: 'solid 1.6px #727272',
	background: 'white',
	borderRadius: '2.5px',
	width: '100%',
	height: '9.5px',
};

// const hoverHandleStyles = {
// 	outline: 'solid 2px #545454',
// 	borderRadius: '10px',
// 	width: '8px',
// 	height: '8px',
// };
const colorNames = [
	'green',
	'blue',
	'red',
	'orange',
	'yellow',
	'gray',
	'white',
	'purple',
];
const getColors = (colorName) => {
	const colorMap = {
		green: {
			color: '#619259',
			darkColor: '#4F7C48',
		}, // dark : {r: 0.5, g: 0.7, b: 0.5}, light : {r: 0.8, g: 0.9, b: 0.8}
		blue: {
			color: '#6F7F99',
			darkColor: '#526788',
		}, // {r: 0.7, g: 0.7, b: 0.8} {r: 0.6, g: 0.8, b: 0.8}
		red: {
			color: '#996F6F',
			darkColor: '#905454',
		}, // {r: 0.9, g: 0.6, b: 0.6}
		yellow: {
			color: '#AE9554',
			darkColor: '#9E8647',
		}, // rgb(255,255,0) -> rgb(255,255,204) (light) {r: 0.9, g: 0.9, b: 0.4} {r: 0.9, g: 0.9, b: 0.7}
		orange: {
			color: '#9F4E2A',
			darkColor: '#884324',
		}, // 	rgb(255,165,0) {r: 0.9, g: 0.7, b: 0.5}
		gray: {
			color: '#808080',
			darkColor: '#6A6A6A',
		}, // rgb(211,211,211) {r: 0.8, g: 0.8, b: 0.8}
		white: {
			color: '#adadad',
			darkColor: '#808080',
		}, // rgb(255,255,255) {r: 1, g: 1, b: 1}
		purple: {
			color: '#674C80',
			darkColor: '#553C6B',
		}, // purple : rgb(138,43,226) {r: 0.8, g: 0.7, b: 0.8} {r: 0.9, g: 0.9, b: 1}
	};
	return { ...colorMap[colorName], colorName };
};

const toast = createStandaloneToast({ theme: theme });

const TransientNodeComponent = ({ id, data, selected }) => {
	const focusedStepNodeIds = useStore(
		useCallback((state) => state.focusedStepNodeIds, [])
	);

	// template values for that particular node type. Not specific to node-id
	const colorName =
		colorNames[Math.trunc(Math.random() * 100) % colorNames.length];
	const color = getColors(colorName);
	const nodeTemplate = {
		color:
			data?.node?.color && data?.node?.darkColor
				? data?.node?.color
				: '#808080',
		darkColor:
			data?.node?.darkColor && data?.node?.color
				? data?.node?.darkColor
				: '#6A6A6A',
		inputs: 1,
		numberOfOutputs: data.node?.wires?.length || 1,
	};

	const PurpleElement = ({ alignment = 'right' }) => {
		return (
			<Box
				right={alignment === 'right' ? '6px' : 'none'}
				left={alignment === 'left' ? '6px' : 'none'}
				height="9px"
				width="9px"
				outline="solid 1.2px #474747"
				position="absolute"
				top="-5px"
				borderRadius="10px"
				bg="purple.400"
			/>
		);
	};

	function handleDoubleClick(e) {
		if (e?.detail === 2) {
			toast({
				title: 'Deploy first!',
				description:
					'This is a transient node. To modify it, please deploy this flow first by pressing the Deploy button',
				status: 'warning',
				duration: 5000,
				isClosable: true,
			});
		}
	}

	// const nodeIsHighlighted = focusedStepNodeIds?.includes(id);
	const nodeIsHighlighted = false;

	return nodeTemplate ? (
		<Box
			bg={nodeTemplate.color}
			maxWidth="200px"
			borderRadius="2px"
			boxShadow={
				nodeIsHighlighted
					? '0px 0px 8px 2px #6c757d'
					: 'rgba(0, 0, 0, 0.1) 0px -2px 4px'
			}
			outline={`${
				selected
					? `solid 3px #fb8500`
					: nodeIsHighlighted
					? 'solid 1px #6c757d'
					: 'solid 1px #727272'
			}`}
			transition="all 0.1s cubic-bezier(.08,.52,.52,1)"
			cursor="pointer"
			height="35px"
			onClick={handleDoubleClick}
		>
			<PurpleElement alignment="right" />
			<Box
				width="9px"
				display="flex"
				left="-2px"
				position="absolute"
				height="100%"
			>
				{Array.from(Array(nodeTemplate.inputs).keys()).map((item, i) => {
					return (
						<Handle
							type="target"
							position={Position.Left}
							key={i}
							style={{
								...handleStyles,
								border: 'none',
								outline: selected
									? `solid 2px #727272`
									: 'solid 1.6px #727272',
							}}
						/>
					);
				})}
			</Box>
			<Box
				fontWeight="700"
				color="#323232"
				display="flex"
				justifyContent="flex-start"
				alignItems="center"
				height="100%"
			>
				<Box
					bg={nodeTemplate.darkColor}
					paddingX="7px"
					height="100%"
					width="30px"
					borderLeftRadius="2px"
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					{/* <Image
						src={`${runtime}/icons/node-red/${nodeTemplate.icon}`}
						boxSize="22px !important"
					/> */}
				</Box>
				<Box
					paddingX="23px"
					color="white"
					fontWeight="500 !important"
					fontFamily="Ubuntu"
					fontSize="14px"
				>
					{data?.node?.name || data?.node?.type || ''}
				</Box>
			</Box>
			<Box
				width="9px"
				display="flex"
				right="-1px"
				position="absolute"
				top="0"
				height="100%"
			>
				{Array.from(Array(nodeTemplate.numberOfOutputs).keys()).map(
					(item, i) => {
						return (
							<Handle
								type="source"
								position={Position.Right}
								id={i}
								key={i}
								style={{
									top: `${
										((i + 1) * 100) /
										(nodeTemplate.numberOfOutputs + 1) //get percentage of port shift from top
									}%`,

									...handleStyles,
									outline: selected
										? `solid 2px #727272`
										: 'solid 1.6px #727272',
								}}
							/>
						);
					}
				)}
			</Box>
		</Box>
	) : null;
};
export default TransientNodeComponent;
