import React, { useRef } from 'react';
import { FiFile } from 'react-icons/fi';

import './FileUpload.css';

import {
	Box,
	Flex,
	Input,
	Stack,
} from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';

export const FileUpload = (props) => {
	const { onChange, value } = props

	const controls = useAnimation();
	const startAnimation = () => controls.start('hover');
	const stopAnimation = () => controls.stop();

	const inputRef = useRef(null)

	function handleUpload(e) {
		const files = inputRef.current.files
		onChange(files)
	}

	console.log('lolmao the file', value)

	return (
		<Box
			borderColor="gray.300"
			borderStyle="dashed"
			borderWidth="2px"
			borderRadius='5px'
			height={props.height || '100px'}
			role="group"
			transition="all 150ms ease-in-out"
			as={motion.div}
			initial="rest"
			animate="rest"
			whileHover="hover"
			cursor='pointer'
            width='100%'
            maxWidth='1000px'
            bg={props.bg || 'transparent'}
		>
			<Box position="relative" height="100%" width="100%">
				<Box
					position="absolute"
					top="0"
					left="0"
					height="100%"
					width="100%"
					display="flex"
					flexDirection="column"
				>
					<Stack
						height="100%"
						width="100%"
						display="flex"
						alignItems="center"
						justify="center"
						spacing="4"
					>
						<Stack p="8" textAlign="center" spacing="1">
							{!(value && value[0] && value[0].name) && (
								<>
									<Flex
										textStyle='sans.xs'
										justifyContent='center'
										fontWeight='700'
										color='light.font.gray.300'
										fontSize={props.fontSize || '14px'}
									>
										Drop files here
									</Flex>
									<Flex
										textStyle='sans.xs'
										justifyContent='center'
										fontWeight='400'
										color='light.font.gray.300'
                                        fontSize={props.fontSize || '14px'}
									>
										or click to upload
									</Flex>
								</>
							)}
							{value && value[0] && value[0].name && (
								<Flex
									alignItems='center'
								>
									<Box
										mr='4px'
										transform='translateY(-2px)'
									>
										<FiFile />
									</Box>
									<Box>
										{value[0].name}
									</Box>
								</Flex>
							)}
						</Stack>
					</Stack>
				</Box>
				<Input
					ref={inputRef}
					type="file"
					height="100% !important"
					width="100% !important"
					position="absolute"
					top="0"
					left="0"
					opacity="0"
					aria-hidden="true"
					accept="*"
					onDragEnter={startAnimation}
					onDragLeave={stopAnimation}
					onChange={handleUpload}
					cursor='pointer'
				/>
			</Box>
		</Box>
	);
};

export default FileUpload;
