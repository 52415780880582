/**
 * IPC function utility
 *
 * This will mimic the way axios works.
 */

import isElectron from 'is-electron';

const IPC = async ({ event, data }) => {
	// console.log("Event:", event, data);
	let ipc = null;
	if (isElectron()) {
		// @ts-ignore
		const electron = window.electron;
		const { ipcRenderer } = electron;
		ipc = ipcRenderer;
	} else {
		return;
	}

	return new Promise((resolve, reject) => {
		ipc.invoke(event, data)
			.then((result) => {
				resolve(result);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export default IPC;
