import React, { useState, useCallback } from 'react';
import {
	Flex,
	Box,
} from '@chakra-ui/react';
import useStepperAnalytics from '../../../hooks/useStepperAnalytics';

const GoodToGo = () => {
	useStepperAnalytics();

	return (
		<Flex direction="column" alignItems="center" height="100%">
			<Box paddingY="20px">
				<Flex
					textStyle="serif.lg"
					textAlign="center"
					justifyContent="center"
				>
					You're all set.
				</Flex>
				<Flex
					textStyle="sans.sm"
					justifyContent="center"
					textAlign="center"
					mt="5px"
					color="light.font.gray.400"
				>
					All requirements are configured, and you can run your script again now.
				</Flex>
			</Box>
		</Flex>
	);
};

const GoodToGoObject = {
	heading: 'good to go',
	component: <GoodToGo />,
};

export default GoodToGoObject;
