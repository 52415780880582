import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import redTriangle from '../assets/redtriangle.svg';

const ReqMissingElement = ({ alignment = 'right' }) => {
	return (
		<Box
			right={alignment === 'right' ? '6px' : 'none'}
			left={alignment === 'left' ? '6px' : 'none'}
			height="13px"
			width="13px"
			position="absolute"
			top="-5px"
		>
			<Image src={redTriangle} boxSize="15px" />
		</Box>
	);
};

export default React.memo(ReqMissingElement);
