import { SearchIcon } from '@chakra-ui/icons';
import { Flex, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import React, { useCallback, useMemo, useState } from 'react';
import { useStore } from '../zustand';
import addNode from './functions/addNode';
import { DndNode, getFilteredNodeTypes } from './SideBar';

/**
 *
 * @param {{
 * 	insertPosition: { x: number, y: number },
 * 	onClose: () => void,
 * }} param0
 * @returns
 */
const SearchContextMenu = ({ insertPosition, onClose }) => {
	const [searchValue, setSearchValue] = useState('');
	const allTypes = useStore(
		useCallback((state) => state.nodeConfigs.allTypes, [])
	);
	const filteredNodes = useMemo(
		() => getFilteredNodeTypes(allTypes, searchValue),
		[allTypes, searchValue]
	);
	const { setNodesToTab, setDirty } = useStore(
		useCallback((state) => {
			return {
				setNodesToTab: state.setNodesToTab,
				setDirty: state.setDirty,
			};
		}, [])
	);
	const activeTab = useStore(useCallback((state) => state.tabs.activeTab, []));
	const tab = useStore(
		useCallback((state) => state.tabs.byId[activeTab], [activeTab])
	);

	const handleInsertNode = useCallback(
		(event, nodeType, nodeStyle) => {
			const tabId = activeTab;
			const results = addNode(
				nodeType,
				insertPosition.x,
				insertPosition.y,
				tabId
			);
			const newNode = {
				id: results.node.id,
				type: nodeStyle,
				position: insertPosition,
				data: {
					type: nodeType ? nodeType : 'NA',
					...results,
				},
			};
			setNodesToTab([...tab.reactFlowByTab.nodes, newNode], tabId);
			setDirty(true);
		},
		[
			activeTab,
			insertPosition,
			setDirty,
			setNodesToTab,
			tab.reactFlowByTab.nodes,
		]
	);

	return (
		<Flex
			direction="column"
			h="16rem"
			bg="light.theme.gray.200"
			borderRadius="sm"
			py="0.5"
			boxShadow="lg"
			borderWidth="1.5px"
			borderStyle="solid"
			borderColor="light.border.gray.200"
		>
			<InputGroup display="flex" alignItems="center">
				<InputLeftElement pointerEvents="none">
					<SearchIcon color="gray" />
				</InputLeftElement>
				<Input
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
					placeholder="type to search..."
					bg="none"
					border="none"
					outline="none"
					textStyle="sans.xs"
					width="0 !important"
					minWidth="10rem"
					_placeholder={{
						color: 'gray !important',
					}}
					_focus={{
						border: 'none',
					}}
				/>
			</InputGroup>
			<Flex
				direction="column"
				flex="1"
				minHeight="10rem"
				overflow="auto"
				bg="white"
			>
				{filteredNodes.map((nodeType, idx) => {
					if (nodeType !== 'unknown')
						return (
							<Flex
								key={nodeType}
								alignItems="center"
								onClick={(e) => {
									handleInsertNode(e, nodeType, 'special');
									onClose();
								}}
							>
								<DndNode
									nodeType={nodeType}
									nodeStyle={'special'}
									key={nodeType}
								/>
							</Flex>
						);
					return null;
				})}
			</Flex>
		</Flex>
	);
};

export default SearchContextMenu;
