import React, { useCallback, useMemo, useRef, useState } from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
	IconButton,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Input,
	Button,
	Portal,
	Text,
	Box,
	createStandaloneToast,
} from '@chakra-ui/react';
import { GoKebabVertical } from 'react-icons/go';
import { useStore } from '../../zustand';
import theme from '../../../../library/theme';

/**
 *	Tab Button Component
 * @param {{
 *  tabId: string,
 * }} param0
 * @returns
 */
const TabButton = ({ tabId }) => {
	const toast = createStandaloneToast({ theme: theme });
	/** @type {import('../../zustand/types').Tab} */
	const tab = useStore(
		useCallback((state) => state.tabs.byId[tabId], [tabId])
	);
	const tabIndex = useStore(
		useCallback(
			/** @param {import('../../zustand/types').EditorStore} state */
			(state) => state.tabs.allIds.indexOf(tab.id),
			[tab.id]
		)
	);
	const activeTab = useStore(useCallback((state) => state.tabs.activeTab, []));
	const onTabDelete = useStore(useCallback((state) => state.onTabDelete, []));
	const renameTab = useStore(useCallback((state) => state.renameTab, []));
	const setActiveTab = useStore(
		useCallback((state) => state.setActiveTab, [])
	);
	const isTabActive = useMemo(() => tab.id === activeTab, [activeTab, tab.id]);
	// tab input/rename states
	const [tabInput, setTabInput] = useState(
		/**
		 * @returns {{
		 * 	isEditing: boolean,
		 * 	initialValue: string,
		 * 	inputValue: string,
		 * }}
		 */
		() => ({
			isEditing: false,
			initialValue: tab.label,
			inputValue: tab.label,
		})
	);
	const tabInputRef = useRef(null);

	const handleTabInputSubmit = useCallback(() => {
		renameTab(tabId, tabInput.inputValue);
		setTabInput((old) => ({
			...old,
			isEditing: false,
		}));
	}, [renameTab, tabId, tabInput.inputValue]);

	const handleTabInputReset = useCallback(() => {
		setTabInput((old) => ({
			...old,
			inputValue: old.initialValue,
			isEditing: false,
		}));
	}, []);

	const handleRenameClick = useCallback(() => {
		setTabInput((old) => ({
			...old,
			isEditing: true,
		}));
		setTimeout(() => {
			if (tabInputRef.current) {
				tabInputRef.current.focus();
				tabInputRef.current.select();
			}
		}, 100);
	}, []);

	const handleTabInputChange = useCallback((e) => {
		e.persist();
		setTabInput((old) => ({
			...old,
			inputValue: e.target.value,
		}));
	}, []);

	const handleTabInputKeyDown = useCallback(
		/**
		 * @param {import('react').KeyboardEvent<HTMLInputElement>} e
		 */
		(e) => {
			if (e.key === 'Enter') handleTabInputSubmit();
			if (e.key === 'Escape') handleTabInputReset();
		},
		[handleTabInputReset, handleTabInputSubmit]
	);

	const handleDelete = (e) => {
		try {
			e.stopPropagation();
			onTabDelete(tabId);
		} catch (err) {
			console.error(err);
			toast({
				title: err.message,
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	return (
		<Box
			_focus={{ boxShadow: 'none' }}
			minW="10rem"
			borderRadius="sm"
			p="0"
			bg={isTabActive ? '#e2e2e2' : '#efefef'}
			mx="2px !important"
			onClick={() => setActiveTab(tabId)}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			pl="4"
			textStyle="sans.xs"
			cursor="pointer"
			_hover={{ backgroundColor: '#e2e8f0' }}
		>
			{tabInput.isEditing ? (
				<Input
					ref={tabInputRef}
					p="0"
					textAlign="left"
					mb="0 !important"
					_placeholder={{
						fontSize: '1rem !important',
					}}
					_focus={{ boxShadow: 'none' }}
					variant="flushed"
					maxW="5vw"
					fontWeight="500 !important"
					value={tabInput.inputValue}
					onChange={handleTabInputChange}
					onBlur={handleTabInputSubmit}
					onKeyDown={handleTabInputKeyDown}
				/>
			) : (
				<Text
					as="span"
					display="flex"
					alignItems="center"
					isTruncated
					fontSize="14.5px"
					h="60%"
					maxWidth="100px"
					textAlign="left"
					textStyle="sans.xs"
					fontWeight="500 !important"
				>
					{tab.label || tab.name}
				</Text>
			)}
			{isTabActive ? (
				<Menu gutter={1} isLazy>
					<MenuButton
						as={IconButton}
						icon={<GoKebabVertical size="1rem" />}
						size="sm"
						bg="transparent"
						_focus={{
							boxShadow: 'none',
						}}
						_hover={{
							bg: 'light.theme.gray.300',
						}}
						borderRadius="sm"
					/>
					<Portal>
						<MenuList
							color="light.font.gray.300"
							bg="light.theme.gray.50"
							borderRadius="sm"
							zIndex="dropdown"
							minWidth="44"
							py="0.5"
							boxShadow="lg"
							borderWidth="1.5px"
							borderStyle="solid"
							borderColor="light.border.gray.200"
							textStyle="sans.xs"
						>
							<MenuItem
								px="2.5"
								icon={<EditIcon mb="1" />}
								iconSpacing="1.5"
								fontWeight="500 !important"
								onClick={(e) => {
									e.stopPropagation();
									handleRenameClick();
								}}
								isDisabled={tab.type === 'subflow'}
							>
								rename
							</MenuItem>
							<MenuItem
								px="2.5"
								icon={<DeleteIcon mb="1" />}
								iconSpacing="1.5"
								fontWeight="500 !important"
								isDisabled={
									// do not allow deleting if subflow tab
									tab.type === 'subflow'
								}
								onClick={handleDelete}
							>
								delete
							</MenuItem>
						</MenuList>
					</Portal>
				</Menu>
			) : null}
		</Box>
	);
};

export default TabButton;
