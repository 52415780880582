/**
 * Gets the valid RED instance (if exists) on the `window` object.
 * Its includes the native types on RED provided by `@types/node-red`.
 */
const getRedInstance = () => {
	if (typeof window === 'undefined') throw new Error('window is not defined!');
	/**
	 * @type {import('@types/node-red').EditorRED}
	 */
	const RED = window.RED;
	if (!RED) throw new Error('RED object is not defined on window!');
	return RED;
};

export default getRedInstance;
