import chroma from 'chroma-js';
import _, { cloneDeep } from 'lodash';
import getRedInstance from '../getRedInstance';

export const validateRED = () => {
	if (window.RED) return true;
	else return false;
};

export const deployEditor = async (
	skipValidation = false,
	force = false,
	mayaFlow
) => {
	const RED = getRedInstance();
	await RED.deploy.save(skipValidation, force, mayaFlow);
};

export const getMovingSet = () => window.RED.view.movingSet.nodes();

export const addMovingSet = (node) => window.RED.view.movingSet.add(node);

export const clearMovingSet = () => window.RED.view.movingSet.clear();

export const getNodeTypes = () => {
	const types = cloneDeep(window.RED.nodes.registry.getNodeTypes());
	return types.filter(
		(type) => type !== 'tab' && type !== 'subflow' && type !== 'unknown'
	);
};

/**
 *
 * @returns Gets nodes by id
 */
export const getNodeById = (id: String) => {
	const result = window.RED.nodes.filterNodes({
		id: id,
	});
	return result[0];
};

/**
 *
 * @returns Gets subflow by id
 */
export const getSubflowById = (id: String) => {
	return getCompleteFlow().filter((n) => n.id === id);
};

/**
 * This converts any RGB to one of 8 main node colours as per the design
 * @param {*} color
 * @param {*} node
 * @returns
 */

const convertRgbToColor = (color, node) => {
	const roundOff = (num) => Math.round(num * 10) / 10;
	const red = roundOff(color[0] / 255);
	const green = roundOff(color[1] / 255);
	const blue = roundOff(color[2] / 255);

	// const map = {
	// 	green: '', // dark : {r: 0.5, g: 0.7, b: 0.5}, light : {r: 0.8, g: 0.9, b: 0.8}
	// 	blue: '', // {r: 0.7, g: 0.7, b: 0.8} {r: 0.6, g: 0.8, b: 0.8}
	// 	red: '', // {r: 0.9, g: 0.6, b: 0.6}
	// 	yellow: '', // rgb(255,255,0) -> rgb(255,255,204) (light) {r: 0.9, g: 0.9, b: 0.4} {r: 0.9, g: 0.9, b: 0.7}
	// 	orange: '', // 	rgb(255,165,0) {r: 0.9, g: 0.7, b: 0.5}
	// 	gray: '', // rgb(211,211,211) {r: 0.8, g: 0.8, b: 0.8}
	// 	white: '', // rgb(255,255,255) {r: 1, g: 1, b: 1}
	// 	purple: '', // purple : rgb(138,43,226) {r: 0.8, g: 0.7, b: 0.8} {r: 0.9, g: 0.9, b: 1}
	// };

	var colorName = 'null';
	if (red === green && green === blue) {
		if (red === 1) {
			colorName = 'white';
		} else {
			colorName = 'gray';
		}
	} else if (red === green && red >= blue) {
		colorName = 'yellow';
		// gray
		// white
		// purple, sometimes
	} else if (red === blue && red !== green) {
		if (green > red && green > blue) {
			colorName = 'green';
		} else {
			colorName = 'purple';
		}
		// purple
	} else if (blue >= red && blue >= green) {
		colorName = 'blue';
	} else if (red > green && red > blue) {
		if (green + 0.2 > blue) {
			colorName = 'orange';
		} else {
			colorName = 'red';
		}
	} else if (green > red && green > blue) {
		colorName = 'green';
	} else {
		colorName = 'gray';
	}

	return colorName;
};

/**
 * obtained from the design
 * @param {*} colorName
 * @returns
 */

const getColors = (colorName) => {
	const colorMap = {
		green: {
			color: '#619259',
			darkColor: '#4F7C48',
		}, // dark : {r: 0.5, g: 0.7, b: 0.5}, light : {r: 0.8, g: 0.9, b: 0.8}
		blue: {
			color: '#6F7F99',
			darkColor: '#526788',
		}, // {r: 0.7, g: 0.7, b: 0.8} {r: 0.6, g: 0.8, b: 0.8}
		red: {
			color: '#996F6F',
			darkColor: '#905454',
		}, // {r: 0.9, g: 0.6, b: 0.6}
		yellow: {
			color: '#AE9554',
			darkColor: '#9E8647',
		}, // rgb(255,255,0) -> rgb(255,255,204) (light) {r: 0.9, g: 0.9, b: 0.4} {r: 0.9, g: 0.9, b: 0.7}
		orange: {
			color: '#9F4E2A',
			darkColor: '#884324',
		}, // 	rgb(255,165,0) {r: 0.9, g: 0.7, b: 0.5}
		gray: {
			color: '#808080',
			darkColor: '#6A6A6A',
		}, // rgb(211,211,211) {r: 0.8, g: 0.8, b: 0.8}
		white: {
			color: '#adadad',
			darkColor: '#808080',
		}, // rgb(255,255,255) {r: 1, g: 1, b: 1}
		purple: {
			color: '#674C80',
			darkColor: '#553C6B',
		}, // purple : rgb(138,43,226) {r: 0.8, g: 0.7, b: 0.8} {r: 0.9, g: 0.9, b: 1}
	};
	return { ...colorMap[colorName], colorName };
};

export const getNodeByType = (type) => {
	const node = cloneDeep(window.RED.nodes.getType(type));
	let getColorsResponse;
	if (node.color) {
		const raw = node.color;
		// node.color = chroma(raw).saturate(20).brighten(2).toString();
		var rawRGB = chroma(raw).rgb();
		getColorsResponse = getColors(convertRgbToColor(rawRGB, node));
	} else {
		getColorsResponse = getColors('gray');
	}
	return {
		...node,
		color: getColorsResponse.color,
		darkColor: getColorsResponse.darkColor,
	};
};

export const getCompleteFlow = () => {
	return cloneDeep(
		window.RED.nodes.createCompleteNodeSet({
			complete: true,
		})
	);
};

export const getConfigNodes = () => {
	const RED = getRedInstance();
	let flow = cloneDeep(
		RED.nodes.createCompleteNodeSet({
			complete: true,
		})
	);
	return flow.filter(
		(n) =>
			n.x === undefined &&
			n.y === undefined &&
			n.type !== 'tab' &&
			n.type !== 'subflow' &&
			n.type !== 'group'
	);
};

/**
 * Get Module List from Node Red.
 */
export const getModuleList = () => {
	const RED = getRedInstance();
	/**
	 * @type {import('../../../zustand/types').ModuleListOrByType}
	 */
	// @ts-ignore
	const moduleList = cloneDeep(RED.nodes.registry.getModuleList());
	return moduleList;
};
