import React from 'react';

import {
	Box,
	Stack,
	Input,
	InputGroup,
	InputRightElement,
	Button,
	useColorMode,
	useToast,
} from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import isElectron from 'is-electron';
import { ArrowForwardIcon } from '@chakra-ui/icons';
// API
import APIFetch from '../../../../util/APIFetch';
import ResetAPI from './ForgotAPI';

//Redux
import { connect } from 'react-redux';
import { setUser, setStatus } from '../../../../redux/actions';

// Config
import { config } from '../../../../config';

import validate_email from '../../../../util/Validate/validate_email';

// Components
import Container from '../Container';
import MayaInput from '../../../../library/input';
import MayaFilledButton from '../../../../library/buttons/FilledButton';
import theme from '../../../../library/theme';

const ForgotCard = ({ setUser, setStatus }) => {
	const toast = createStandaloneToast({ theme: theme });
	const [formData, setFormData] = React.useState({});
	const [formAlert, setFormAlert] = React.useState({ type: null });
	const [isSubmitting, setSubmitting] = React.useState(false);
	const [email, setEmail] = React.useState('');
	const [emailErr, setEmailErr] = React.useState(false);
	// Setup the API Fetch utility for the Login View.
	// console.log(formData);
	const [{ fetchResults }] = APIFetch({
		BASEURL: config.fusionAuth.BASEURL,
		PATH_SEARCH: ResetAPI.fusionAuth.forgotPassword.PATH_SEARCH,
		PATH_METHOD: ResetAPI.fusionAuth.forgotPassword.PATH_METHOD,
	});

	//insert reset password logic here
	const handleForgotSubmit = () => {
		let formData = {
			loginId: email,
			sendForgotPasswordEmail: true,
		};
		setSubmitting(true);
		fetchResults(formData)
			.then((res) => {
				console.log(res);
				setSubmitting(false);
				toast({
					title: 'Submitted',
					description: 'Reset link has been sent to your email.',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
			})
			.catch((err) => {
				console.error(err);
				setSubmitting(false);
				if (err.status === 404) {
					toast({
						title: 'Error',
						description:
							'This email/username is not registered with Maya',
						status: 'error',
						duration: 3000,
						isClosable: true,
					});
				} else {
					toast({
						title: 'Error',
						description:
							'Error initiating a password reset. Contact humans@mayalabs.io',
						status: 'error',
						duration: 3000,
						isClosable: true,
					});
				}
			});
	};

	const { colorMode } = useColorMode();

	const fontColor = { light: 'maya_light.400', dark: 'white' };
	const bgColor = { light: '#f9f9f9', dark: '#424242' };
	const borderColor = { light: '#f2f2f2', dark: '#494949' };

	const inputBgColor = { light: 'white', dark: '#4c4c4c' };
	const inputFontColor = { light: 'gray.800', dark: 'white' };

	return (
		<Container
			heading="Submit Reset Request"
			subheading="Enter your registered email ID to get a reset password link."
		>
			<Stack>
				<InputGroup size="md" style={{ marginBottom: '0rem' }}>
					<MayaInput
						name="email"
						placeholder="Enter your email"
						onChange={(e) => {
							if (validate_email(e.target.value)) {
								setEmailErr(false);
							} else if (e.target.value) {
								setEmailErr(true);
							} else {
								setEmailErr(false);
							}
							setEmail(e.target.value);
						}}
						isInvalid={emailErr}
					/>
				</InputGroup>
				<MayaFilledButton
					size="sm"
					onClick={handleForgotSubmit}
					isLoading={isSubmitting}
					text={'Submit'}
					rightIcon={<ArrowForwardIcon />}
					isDisabled={emailErr || email === ''}
				/>
			</Stack>
		</Container>
	);
};

// Export the Login View.
export default connect(null, { setUser, setStatus })(ForgotCard);

// <Box
//           display="flex"
//           justifyContent="center"
//           color="gray.600"
//           fontSize="0.8rem"
//           fontFamily="body"
//         >
//           or
//         </Box>
//         <Button leftIcon={FaGoogle} variantColor="gray" size="md">
//           Sign in with Google
//         </Button>
