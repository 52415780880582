import { config } from '../../config';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

const getSession = async ({ sessionId }) => {
	const getSessionFn = APIFunction({
		BASEURL: config.pacEngine.baseUrl,
		PATH_SEARCH: `${APIRoutes.apiService.getSession.PATH_SEARCH}/${sessionId}`,
		PATH_METHOD: APIRoutes.apiService.getSession.PATH_METHOD,
	});

	const response = await getSessionFn();
	return response;
};

export default getSession;
