import React, { useCallback } from 'react';
import { Box, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { AiFillApple, AiFillWindows } from 'react-icons/ai';
import { FaCloud } from 'react-icons/fa';
import { DiLinux } from 'react-icons/di';

//Components
import IconContainer from '../../../util/React_Icon';
//Functions
import getDevices from '../../../functions/devices/getDevices';
import { normalize } from '../../../util/Misc';
//Redux
import { connect, useSelector } from 'react-redux';
import { setDevices } from '../../../redux/actions';

import truncate from 'lodash/truncate';
import NewDevice from './NewDevice';
import isElectron from 'is-electron';

const DeviceItem = ({ deviceName, release, platform, rightIcon }) => {
	var iconColor = '#4B4B4B';

	const platformLogos = {
		win: (
			<IconContainer
				icon={<AiFillWindows />}
				value={{
					color: iconColor,
					className: 'global-class-name',
					size: '1.8rem',
				}}
			/>
		),
		darwin: (
			<IconContainer
				icon={<AiFillApple />}
				value={{
					color: iconColor,
					className: 'global-class-name',
					size: '2rem',
				}}
			/>
		),
		linux: (
			<IconContainer
				icon={<DiLinux />}
				value={{
					color: iconColor,
					className: 'global-class-name',
					size: '1.7rem',
				}}
			/>
		),
		cloud: (
			<IconContainer
				icon={<FaCloud />}
				value={{
					color: iconColor,
					className: 'global-class-name',
					size: '1.7rem',
				}}
			/>
		),
	};
	return (
		<Box
			height="4.2rem"
			display="flex"
			flexDirection="row"
			alignItems="center"
			justifyContent="space-between"
			fontFamily="body"
			borderRadius="2px"
			width="100%"
			cursor="pointer"
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
		>
			<Box display="flex" alignItems="center">
				<Box marginLeft="1rem">{platformLogos[platform]}</Box>
				<Box marginLeft="1rem">
					<Box textStyle="sans.sm" color={'light.font.gray.400'}>
						{truncate(deviceName, { length: 16 })}
					</Box>
					<Box textStyle="sans.xs" color={'light.font.gray.300'}>
						{release}
					</Box>
				</Box>
			</Box>
			{rightIcon && <ChevronDownIcon marginLeft="1rem" marginRight="1rem" />}
		</Box>
	);
};

const SelectDevice = (props) => {
	const [selectedDevice, selectDevice] = React.useState();
	const { setDevices, onChange, platformOption } = props;

	const devices = useSelector((state) => {
		return state.devices;
	});

	const deviceDisplayList = React.useMemo(() => {
		var list = {
			allIds: [],
			byId: {},
		};

		if (isElectron()) {
			if (platformOption === 'On Cloud') {
				for (var key in devices.list.byId) {
					if (devices.list.byId[key].platform === 'cloud') {
						list.byId[key] = devices.list.byId[key];
						list.allIds.push(key);
					}
				}

				selectDevice(list.byId[list.allIds[0]]);
				onChange(list.byId[list.allIds[0]]);
			} else if (platformOption === 'On Desktop') {
				for (key in devices.list.byId) {
					if (devices.list.byId[key].platform !== 'cloud') {
						list.byId[key] = devices.list.byId[key];
						list.allIds.push(key);
					}
				}
				selectDevice(list.byId[list.allIds[0]]);
				onChange(list.byId[list.allIds[0]]);
			}
		} else {
			if (platformOption === 'On Cloud') {
				for (key in devices.list.byId) {
					if (devices.list.byId[key].platform === 'cloud') {
						list.byId[key] = devices.list.byId[key];
						list.allIds.push(key);
					}
				}
				selectDevice(list.byId[list.allIds[0]]);
				onChange(list.byId[list.allIds[0]]);
			} else if (platformOption === 'On Desktop') {
				return devices.list;
			}
		}
		return list;
	}, [devices, platformOption]);

	const refreshDevices = useCallback(async () => {
		const res = await getDevices();
		if (Array.isArray(res.results)) {
			setDevices(normalize(res.results));
			selectDevice(res.results[0]);
			onChange(res.results[0]);
		}
	}, []);

	React.useEffect(() => {
		refreshDevices();
	}, [refreshDevices]);

	return (
		<Box {...props}>
			{deviceDisplayList.allIds.length ? (
				<Menu
					border="solid 1px"
					placement="right"
					borderRadius="2px"
					cursor="pointer"
					bg={'white'}
					borderColor={'light.border.gray.200'}
				>
					<MenuButton
						as={Box}
						borderRadius="2px"
						cursor="pointer"
						border="solid 1px"
						bg={'white'}
						borderColor={'light.border.gray.200'}
						textStyle="sans.sm"
						paddingY="10px"
						paddingX="10px"
					>
						{selectedDevice ? (
							<DeviceItem
								deviceName={selectedDevice.name}
								release={selectedDevice.release}
								platform={selectedDevice.platform}
								rightIcon={true}
							/>
						) : null}
					</MenuButton>
					<MenuList
						bg={'white'}
						borderRadius="2px"
						cursor="pointer"
						border="solid 1px"
						boxShadow="rgba(0, 0, 0, 0.05) 1px 2px 2px"
						borderColor={'light.border.gray.200'}
					>
						{deviceDisplayList.allIds.map((deviceId) => {
							return (
								<MenuItem
									minH="48px"
									padding="0"
									key={deviceId}
									transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
									onClick={() => {
										selectDevice(props.devices.list.byId[deviceId]);
										props.onChange(props.devices.list.byId[deviceId]);
									}}
									_hover={{
										bg: 'light.theme.gray.100',
									}}
								>
									<DeviceItem
										deviceName={
											props.devices.list.byId[deviceId].name
										}
										release={
											props.devices.list.byId[deviceId].release
										}
										platform={
											props.devices.list.byId[deviceId].platform
										}
										rightIcon={false}
									/>
								</MenuItem>
							);
						})}
					</MenuList>
				</Menu>
			) : (
				<NewDevice onCreate={() => refreshDevices()} />
			)}
		</Box>
	);
};

const mapStateToProps = (state) => {
	const { devices } = state;
	return { devices };
};

export default connect(mapStateToProps, { setDevices })(SelectDevice);
