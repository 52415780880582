import React, { useCallback } from 'react';
import { Box, Image } from '@chakra-ui/react';
import fuzzysort from 'fuzzysort';
import SectionHeader from '../../../../library/text/SectionHeader';
import MayaFilledButton from '../../../../library/buttons/FilledButton';
import _ from 'lodash';
import produce from 'immer';
import ActionBtn from '../../../../library/buttons/ActionBtn';
import useStepperStore from '../../StepperStore';
import { ArrowForwardIcon, SearchIcon } from '@chakra-ui/icons';
import updateMetadata from '../../../../functions/profile/updateMetadata';
import MayaInput from '../../../../library/input';

//Functions
import getStoreApps from '../../../../functions/store/getStoreApps';
import useStepperAnalytics from '../../hooks/useStepperAnalytics';
import { useSelector } from 'react-redux';

var appOptions = [
	{
		icon: 'https://maya-public-assets.s3.ap-south-1.amazonaws.com/modules/notionlogo.png',
		label: 'Notion',
	},
	{
		icon: 'https://maya-public-assets.s3.ap-south-1.amazonaws.com/modules/gdrive.svg',
		label: 'Drive',
	},
	{
		icon: 'https://maya-public-assets.s3.ap-south-1.amazonaws.com/modules/chrome.svg',
		label: 'Chrome',
	},
	{
		icon: 'https://maya-public-assets.s3.ap-south-1.amazonaws.com/modules/reddit.svg',
		label: 'Reddit',
	},
	{
		icon: 'https://maya-public-assets.s3.ap-south-1.amazonaws.com/modules/reddit.svg',
		label: 'Random',
	},
	{
		icon: 'https://maya-public-assets.s3.ap-south-1.amazonaws.com/modules/spotify.svg',
		label: 'Spotify',
	},
	{
		icon: 'https://maya-public-assets.s3.ap-south-1.amazonaws.com/modules/twitter.svg',
		label: 'Twitter',
	},
	{
		icon: 'https://maya-public-assets.s3.ap-south-1.amazonaws.com/modules/zoom.svg',
		label: 'Zoom',
	},
];

const AppOption = ({ text, icon, selected, onClickFn }) => {
	return (
		<Box
			bg={selected ? 'white' : 'none'}
			display="flex"
			flexDirection="column"
			alignItems="center"
			borderRadius="2px"
			border="solid 1px"
			borderColor={selected ? 'light.border.gray.200' : 'transparent'}
			paddingX="10px"
			paddingY="20px"
			cursor="pointer"
			onClick={() => {
				onClickFn(text);
			}}
		>
			<Image src={icon} boxSize="60px" borderRadius="5px" />
			<Box textStyle="sans.xs" color="light.font.gray.400" mt="10px">
				{text}
			</Box>
		</Box>
	);
};

// variable to store the data of all the apps available in the store
// if allApps is null means apps havent been fetched yet
let allApps = null;

/** Filtering apps according to the search query using fuzzysort package */
export const getFilteredApps = (searchValue) => {
	let filteredApps = [...allApps];
	if (searchValue !== '') {
		filteredApps = fuzzysort
			.go(searchValue, allApps, { key: 'label' })
			.map((i) => i.obj);
	}
	return filteredApps;
};

/**
 * Select all the apps that are relevant to
 * your automation.
 *
 * Design reference : https://www.figma.com/file/KAF1ostHf3VBeZsctGl3ZA/MayaDesign?node-id=2424%3A1240
 * @returns
 */
const AppSelection = () => {
	useStepperAnalytics();
	const { updateStepState, addStep, nextStep, currentStep, steps } =
		useStepperStore((state) => {
			return {
				updateStepState: state.updateStepState,
				addStep: state.addStep,
				nextStep: state.nextStep,
				currentStep: state.currentStep,
				steps: state.steps,
			};
		});
	const currentSectionId = useStepperStore(
		useCallback((state) => state.steps.allIds[currentStep], [currentStep])
	);
	const currentStepState = useStepperStore(
		useCallback(
			(state) =>
				state.steps.byId[currentSectionId]
					? state.steps.byId[currentSectionId].state
					: {},
			[currentSectionId]
		)
	);

	const { userSlug } = useSelector(
		/**
		 *
		 * @param {import('../../../../redux/reducers/types/profileReducer').ProfilesState} state
		 * @returns
		 */
		(state) => ({
			userSlug: state.profiles.userSlug,
		})
	);

	const [filteredApps, setFilteredApps] = React.useState([]);

	const [searchVal, setSearchVal] = React.useState('');
	const searchValChangeHandler = (event) => setSearchVal(event.target.value);

	/**
	 * Get Apps
	 */
	React.useEffect(() => {
		getStoreApps().then((res) => {
			allApps = res; // storing the apps for future use in filtering
			setFilteredApps(res); // init the filteredApps with all the apps
		});
	}, []);

	/**
	 * Filtering apps based on the search query
	 */
	React.useEffect(() => {
		if (allApps !== null) {
			setFilteredApps(getFilteredApps(searchVal));
		}
	}, [searchVal]);

	const [items, setItems] = React.useState({ state: currentStepState });

	const findItem = (label) => {
		var value = _.get(items, `state.${label}`);
		return value;
	};

	const addItem = (label) => {
		var newState = produce((draft) => {
			draft.state[label] = true;
			return draft;
		}, items);
		setItems(newState);
	};

	const removeItem = (label) => {
		var newState = produce((draft) => {
			delete draft.state[label];
			return draft;
		}, items);

		setItems(newState);
	};

	const changeState = () => {
		updateStepState(items);
	};

	const goToNextStep = useCallback(() => {
		const currentStep = steps.byId['onboarding.appSelection'];
		const currentState = currentStep.state;

		changeState();
		updateMetadata({
			slug: userSlug,
			metadata: {
				onboarded: true,
				onboardingData: {
					appSelection: items.state,
				},
			},
		});

		const nextStepState = {
			...currentState,
			appSelection: items.state,
		};

		addStep('new.automation', nextStepState);
		return nextStep();
	}, [steps]);

	console.log('STATE', items, steps);
	return (
		<Box
			display="flex"
			flexDir="column"
			justifyContent="center"
			alignItems="center"
		>
			<Box paddingTop="20px" paddingBottom="40px">
				<SectionHeader
					heading="Which of these are relevant to your automation?"
					subheading="Choose the ones related to your task, or just try something for fun."
				/>
			</Box>
			<Box display="flex" flexDirection="column">
				<Box margin="auto">
					<MayaInput
						inputProps={{ width: '700px', value: searchVal }}
						placeholder="Type to search through apps..."
						icon={<SearchIcon color="light.theme.gray.400" />}
						value={searchVal}
						onChange={searchValChangeHandler}
					/>
				</Box>
				<Box
					display="grid"
					gridTemplateColumns="1fr 1fr 1fr 1fr 1fr 1fr"
					gridGap="7px"
					marginTop="15px"
					height="300px"
					overflow="scroll"
				>
					{filteredApps.map((item) => {
						return (
							<AppOption
								text={item.label}
								icon={item.thumbnail}
								key={item.label}
								selected={findItem(item.label)}
								onClickFn={(label) => {
									if (findItem(label)) {
										removeItem(label);
									} else {
										addItem(label);
									}
								}}
							/>
						);
					})}
				</Box>
			</Box>
			<Box mt="50px">
				<MayaFilledButton
					text="Continue"
					buttonProps={{
						width: '200px',
					}}
					onClick={goToNextStep}
					rightIcon={<ArrowForwardIcon />}
				/>
			</Box>

			{Object.keys(items.state).length > 0 ? (
				<Box mt="40px">
					<ActionBtn
						text="CLEAR"
						onClick={() => {
							setItems({ state: {} });
						}}
					/>
				</Box>
			) : null}
		</Box>
	);
};

const sectionObject = {
	heading: 'onboarding',
	component: <AppSelection />,
};

export default sectionObject;
