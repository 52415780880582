import React from 'react';
import { Box } from '@chakra-ui/layout';
import { HistoryWithSlug } from '../../util/History';
// import useAnalytics from '../../util/Analytics/useAnalytics';
import analytics from '../../util/Analytics';
/**
 *
 * @param {import("./types/index").HorizontalMenu} param0
 * @returns
 */

const HorizontalMenu = ({ menuItemList, selectedItem, setSelected }) => {
	// const track = useAnalytics();
	const track = analytics.track;
	return (
		<Box marginLeft="30px" display="flex" userSelect="none">
			{menuItemList.map((item) => {
				return (
					<Box key={item.label} cursor="pointer">
						<Box
							textStyle="sans.sm"
							marginRight="50px"
							color={
								item.label === selectedItem
									? 'light.font.gray.400'
									: 'light.font.gray.200'
							}
							onClick={() => {
								track(`Horizontal Menu :: ${item.label}`, {});
								setSelected(item.label);
								HistoryWithSlug.push(item.redirect);
							}}
							transition="all 0.02s cubic-bezier(.08,.52,.52,1)"
							_hover={{
								color: 'light.font.gray.400',
							}}
						>
							{item.label}
						</Box>
						{item.label === selectedItem && (
							<Box
								height="2px"
								bg="light.theme.gray.400"
								width="14px"
								mt="-2px"
								borderRadius="1px"
							/>
						)}
					</Box>
				);
			})}
		</Box>
	);
};

export default HorizontalMenu;

/* <Box marginLeft="30px" display="flex">
			{menuItemList.map((item) => {
				return (
					<Box cursor="pointer">
						<Box
							textStyle="sans.sm"
							marginRight="50px"
							color={
								item.label === selected
									? 'light.font.gray.400'
									: 'light.font.gray.200'
							}
							onClick={() => {
								setSelected(item.label);
								HistoryWithSlug.push(item.redirect);
							}}
							transition="all 0.02s cubic-bezier(.08,.52,.52,1)"
							_hover={{
								color: 'light.font.gray.400',
							}}
						>
							{item.label}
						</Box>
						{item.label === selected && (
							<Box
								height="2px"
								bg="light.theme.gray.400"
								width="14px"
								mt="-2px"
								borderRadius="1px"
							/>
						)}
					</Box>
				);
			})}
		</Box> */
